import React, { Component } from "react";
import {
  DKLabel,
  DKButton,
  DKInput,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKIcons,
  showAlert,
  DKSearchBar
} from "deskera-ui-library";
import { connect, ConnectedProps } from "react-redux";
import { getFullName } from "../../../model/User";
import { Team } from "../../../services/team";
import { store } from "../../../redux/store";
import { fetchTeams } from "../../../redux/slices/tenantSlice";
import { fetchLoggedInUserRole } from "../../../redux/slices/rolesPermissionSlice";
import UserManager from "../../../managers/UserManager";
import Utility from "../../../utility/Utility";
import AddMemberPopup from "./AddMemberPopup";
import UserPermissionRow from "./UserPermissionRow";
import {
  NO_TEAM_LEAD,
  NO_TEAM_LEAD_MSG,
  TEAMS,
  TEAM_MEMBER_ERROR_MSG,
  TEAM_MEMBER_ERROR_MSG_AT_LEAST_ONE
} from "../../../constants/Permission";
import { isViewportMobile } from "../../../utility/GetViewportSize";
export interface IAddTeamPopupProps extends PropsFromRedux {
  permittedActions?: any;
  onClose?: () => void;
  selectedTeam?: any;
}

export interface IAddTeamPopupState {
  canValidate: boolean;
  name?: string;
  userIds?: any;
  selectedUserIds?: any;
  filterByName: string;
  buttonDisable: boolean;
  needAddMemberPopup: boolean;
  usersList?: any;
}
class AddTeamPopup extends Component<IAddTeamPopupProps, IAddTeamPopupState> {
  constructor(props) {
    super(props);
    this.state = {
      canValidate: false,
      name: this.props.selectedTeam ? this.props.selectedTeam.name : "",
      selectedUserIds: !Utility.isEmptyObject(this.props.selectedTeam)
        ? this.props.selectedTeam.users
        : [],
      filterByName: "",
      buttonDisable: false,
      needAddMemberPopup: false,
      usersList: []
    };
  }
  componentDidMount() {
    if (!Utility.isEmptyObject(this.props.selectedTeam)) {
      let usersList = [];
      this.props.selectedTeam?.users?.forEach((selectedUser) => {
        let editUser = this.props.users.find(
          (user) => user.id === selectedUser.iamUserId
        );

        if (Utility.isEmptyObject(editUser)) return;

        usersList.push({
          ...editUser,
          ...{
            teamDesignation:
              selectedUser.designation === TEAMS.TEAM_LEAD ? true : false
          }
        });
      });
      this.setState({ usersList });
    }
  }

  render() {
    const width = isViewportMobile() ? "90%" : "100%";

    return (
      <div className="transparent-background-light">
        <div
          className="p-h-r pt-r pb-xl popup-window parent-width "
          style={{ overflow: "visible", height: 500, maxHeight: "90vh", width }}
        >
          {this.getHeader()}
          {this.getInputField()}
          {this.state.selectedUserIds?.length === 0 &&
            this.getMemberEmptyState()}
          {this.state.selectedUserIds?.length !== 0 && this.getUsersSection()}
          {this.state.needAddMemberPopup && this.getAddMemberPopup()}
        </div>
      </div>
    );
  }

  getAddMemberPopup() {
    return (
      <AddMemberPopup
        selectedUserIds={this.state.selectedUserIds}
        onClose={() => {
          this.setState({ needAddMemberPopup: false });
        }}
        onSubmit={(data) => {
          this.setState(
            { selectedUserIds: data, needAddMemberPopup: false },
            () => {
              let usersList = [];
              data?.forEach((selectedUser) => {
                let editUser = this.props.users.find(
                  (user) => user.id === selectedUser.iamUserId
                );
                usersList.push({
                  ...editUser,
                  ...{
                    teamDesignation:
                      selectedUser.designation === TEAMS.TEAM_LEAD
                        ? true
                        : false
                  }
                });
              });
              this.setState({ usersList });
            }
          );
        }}
      />
    );
  }

  getHeader() {
    return (
      <div className="row justify-content-between">
        <DKLabel
          text={this.props.selectedTeam._id ? "Edit Team" : "New Team"}
          className="fw-b fs-m"
        />
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m"
            onClick={() => {
              this.removePopUp();
            }}
          />
          <DKButton
            title="Save"
            disabled={this.state.buttonDisable}
            className="bg-button text-white ml-r"
            onClick={() => {
              this.setState({
                canValidate: true
              });
              if (this.validateName()) {
                this.onSubmit();
              }
            }}
          />
        </div>
      </div>
    );
  }

  getMemberEmptyState() {
    return (
      <div
        className="row justify-content-center border-s border-radius-s mt-m"
        style={{ height: "90%" }}
      >
        <div className="column align-items-center">
          <DKLabel className="text-gray fs-m fw-m" text="No team member" />
          <DKLabel
            className="text-gray mt-s"
            text="Please add members by clicking on '+ Add member' button"
          />
          <DKButton
            title={`+ Add member`}
            className="text-white bg-button mt-r"
            onClick={() => {
              this.setState({ needAddMemberPopup: true });
            }}
          />
        </div>
      </div>
    );
  }

  getInputField() {
    return (
      <div className="row parent-width justify-content-between align-items-start position-relative mt-m">
        {this.getInputSection()}
      </div>
    );
  }
  getInputSection() {
    return (
      <div className="column parent-width">
        <DKInput
          title="Team Name"
          autoFocus
          type={INPUT_TYPE.TEXT}
          value={this.state.name}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required
          canValidate={this.state.canValidate}
          onChange={(value) => {
            this.setState({ name: value });
          }}
        />
      </div>
    );
  }

  getUsersSection() {
    return (
      <>
        <div className="row justify-content-between parent-width p-v-s pl-r mt-xl border-box bg-gray1 z-index-2 ">
          <DKLabel
            text={"Team members"}
            className="parent-width p-v-s border-box bg-gray1 fw-m z-index-2"
            style={{
              top: 0,
              left: 0,
              position: "sticky"
            }}
          />
          <DKButton
            title={`Edit`}
            icon={DKIcons.ic_edit}
            className="mr-m bg-white border-m"
            onClick={() => {
              this.setState({ needAddMemberPopup: true });
            }}
          />
          {/* <DKSearchBar
            className=""
            onSearch={(searchText) => this.setState({ filterByName: searchText?.toLowerCase() })}
            searchText={this.state.filterByName}
          /> */}
        </div>
        <div
          className="scroll-y-only-web hide-scroll-bar parent-width border-s parent-width border-radius-s border-box  flex-wrap justify-content-start"
          style={{ overflowX: "hidden" }}
        >
          {this.state.usersList.length > 0 && this.getAllUsers()}
        </div>
      </>
    );
  }
  getAllUsers() {
    return this.state.usersList
      ?.filter((user) => {
        const fullName = getFullName(user)?.toLowerCase();
        const userEmail = user.email?.toLowerCase();
        return (
          fullName?.includes(this.state.filterByName) ||
          userEmail.includes(this.state.filterByName)
        );
      })
      ?.map((user, index) => {
        return (
          <UserPermissionRow
            isLoading={false}
            user={user}
            needRoleSelector={false}
            needUserSelector={false}
            needTeamLeadSelector={true}
            tenantOwnerId={this.props.tenantDetails?.userId}
            isCurrentUser={user.userId === UserManager.getUserIamID()}
            onTeamLeadSelected={(user) => this.changeDesignation(user)}
          />
        );
      });
  }

  changeDesignation = (user) => {
    let selectedUser = [...this.state.selectedUserIds];
    selectedUser?.forEach((roleUsers, index) => {
      if (roleUsers.iamUserId === user.iamUserId) {
        selectedUser[index] = {
          ...roleUsers,
          ...{ designation: TEAMS.TEAM_LEAD }
        };
      } else {
        selectedUser[index] = {
          ...roleUsers,
          ...{ designation: TEAMS.MEMBER }
        };
      }
    });
    let usersRow = [...this.state.usersList];
    usersRow?.forEach((list, index) => {
      if (list.iamUserId === user.iamUserId) {
        usersRow[index] = { ...list, ...{ teamDesignation: true } };
      } else {
        usersRow[index] = { ...list, ...{ teamDesignation: false } };
      }
    });
    this.setState({ selectedUserIds: selectedUser, usersList: usersRow });
  };

  validateName() {
    let valid = true;
    if (
      this.state.name === null ||
      this.state.name === undefined ||
      this.state.name === "" ||
      (typeof this.state.name === "string" && this.state.name.trim() === "")
    ) {
      valid = false;
    }
    return valid;
  }

  validateTeamLead() {
    let valid = false;
    this.state.selectedUserIds?.forEach((user) => {
      if (user.designation === TEAMS.TEAM_LEAD) {
        valid = true;
      }
    });
    return valid;
  }

  onSubmit() {
    let dataToSend = {};
    if (this.state.selectedUserIds && this.state.selectedUserIds.length === 0) {
      showAlert(TEAM_MEMBER_ERROR_MSG, TEAM_MEMBER_ERROR_MSG_AT_LEAST_ONE);
      return;
    }
    if (!this.validateTeamLead()) {
      showAlert(NO_TEAM_LEAD, NO_TEAM_LEAD_MSG);
      return;
    }
    this.setState({ buttonDisable: true });
    dataToSend = {
      name: this.state.name,
      users: this.state.selectedUserIds
    };
    (this.props.selectedTeam && this.props.selectedTeam._id
      ? Team.updateTeam({
          ...dataToSend,
          id: this.props.selectedTeam._id
        })
      : Team.addTeam(dataToSend)
    )
      .then((response: any) => {
        store.dispatch(fetchTeams());
        let currentOwner = this.state.userIds?.filter(
          (user) => user.iamUserId === UserManager.getUserIamID()
        );
        if (!Utility.isEmptyObject(currentOwner)) {
          this.props.fetchLoggedInUserRole({});
        }
        this.removePopUp();
        this.setState({ buttonDisable: false });
      })
      .catch((error) => {
        if (error?.statusCode === 400) {
          showAlert(`Error`, `${error?.responseBody?.error[0]}`);
        }
        this.setState({ buttonDisable: false });
      });
  }
  removePopUp = () => {
    this.props.onClose?.();
  };
}
const mapStateToProps = (state) => ({
  users: state.tenant.users,
  tenantDetails: state.books.tenantsDetails
});
const mapDispatchToProps = {
  fetchLoggedInUserRole
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddTeamPopup);
