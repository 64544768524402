import { Component } from "react";
import { INPUT_TYPE } from "deskera-ui-library";
import {
  COLUMN_CODE,
  TableManger,
  TABLES
} from "../../../managers/TableManger";
import { URL_V1 } from "../../../constants/Constant";
import ApiConstants from "../../../constants/ApiConstants";
/*
PROPS
- data
- borderStyle
- isEditing
- hash
*/

class Form extends Component {
  render() {
    return (
      <div
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle,
          borderSpacing: "" + this.props.data.style.cellSpacing + "px 0px",
          maxWidth: "100%"
        }}
        onDoubleClick={this.props.isEditing ? this.props.onDoubleClick : null}
      >
        <form
          className="dk-crm-form-submission"
          name={this.getFormData()}
          onSubmit={this.handleSubmit}
        >
          {this.getFormFields(this.props.data.value, this.props.data.style)}
          <input
            type="submit"
            name="dksubmit"
            value={this.props.data.button.value}
            style={{
              fontSize: this.props.data.button.style.buttonSize,
              backgroundColor: this.props.data.button.style.buttonBackground,
              color: this.props.data.button.style.buttonColor,
              marginTop: 15,
              marginBottom: 15,
              padding: 8,
              width: "98%",
              borderColor: this.props.data.button.style.buttonBackground,
              border: "solid",
              borderRadius: 10,
              pointerEvents: this.props.isEditing === true ? "none" : "auto",
              cursor: this.props.isEditing === true ? "none" : "pointer",
              fontWeight: 600
            }}
          />
        </form>
      </div>
    );
  }

  getFormData() {
    let defaultData = {};
    defaultData[
      TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.SEGMENT)
    ] = [parseInt(this.props.data.segmentId || 1)];
    defaultData[
      TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.STATUS)
    ] = [1];
    defaultData[
      TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.TYPE)
    ] = [1];
    let obj = {
      baseURL: ApiConstants.URL.BASE + URL_V1,
      defaultData: defaultData,
      hash: this.props.hash,
      thankYouMsg: this.props.data.thankYouMsg,
      redirectURL: this.props.data.redirectURL
    };
    return JSON.stringify(obj);
  }

  getFormFields(items, style) {
    let arr = [];
    items.forEach((item, index) => {
      arr.push(this.getFieldContainer(index, item, style));
    });
    return arr;
  }

  getFieldContainer(index, data, style) {
    return (
      <div
        key={data.key}
        style={{
          display: "block",
          padding: style.fieldSpacing,
          pointerEvents: this.props.isEditing ? "none" : "auto"
        }}
      >
        <div
          key={index}
          style={{
            width: "100%",
            display: "block",
            textAlign: "left",
            marginBottom: 5,
            fontSize: style.labelSize,
            color: style.labelColor,
            float: "left"
          }}
        >
          {data.name} {data.required ? " *" : ""}
        </div>
        {this.addInputFields(data, style)}
      </div>
    );
  }

  addInputFields(data, style) {
    switch (data.type) {
      case INPUT_TYPE.SELECT:
        return this.getSelectField(data, style);
      case INPUT_TYPE.MULTI_SELECT:
        return this.getMultiSelectField(data, style);
      default:
        return this.getInputField(data, style);
    }
  }

  getInputField(data, style) {
    return (
      <input
        style={{
          WebkitAppearance: "none",
          MozAppearance: "none",
          appearance: "none",
          width: "100%",
          border: "solid",
          borderWidth: 2,
          borderRadius: style.fieldRadius,
          borderColor: style.fieldBorder,
          backgroundColor: style.fieldBackground,
          boxSizing: "border-box",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 10,
          paddingRight: 10,
          outline: "none",
          boxShadow: "none !important",
          backgroundImage: "none"
        }}
        className={`input-${data.type}-type`}
        key={data.key}
        type={data.type}
        name={data.key}
        id={data.key}
        required={data.required}
      />
    );
  }

  getSelectField(data, style) {
    return (
      <select
        style={{
          WebkitAppearance: "none",
          MozAppearance: "none",
          appearance: "none",
          width: "100%",
          border: "solid",
          borderWidth: 2,
          borderRadius: style.fieldRadius,
          borderColor: style.fieldBorder,
          backgroundColor: style.fieldBackground,
          boxSizing: "border-box",
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 10,
          paddingRight: 10,
          outline: "none",
          boxShadow: "none !important",
          backgroundImage: "none"
        }}
        key={data.key}
        name={data.key}
        id={data.key}
      >
        {(data.options || []).map((item, index) => (
          <option key={index} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    );
  }

  getMultiSelectField(data, style) {
    return (
      <div>
        <select
          multiple
          style={{
            WebkitAppearance: "none",
            MozAppearance: "none",
            appearance: "none",
            width: "100%",
            border: "solid",
            borderWidth: 2,
            borderRadius: style.fieldRadius,
            borderColor: style.fieldBorder,
            backgroundColor: style.fieldBackground,
            boxSizing: "border-box",
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 10,
            paddingRight: 10,
            outline: "none",
            boxShadow: "none !important",
            backgroundImage: "none"
          }}
          key={data.key}
          name={data.key}
          id={data.key}
        >
          {(data.options || []).map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <div
          style={{
            color: "gray",
            textAlign: "left",
            marginLeft: "4px",
            marginTop: "5px"
          }}
        >
          (Press Shift to select multiple options)
        </div>
      </div>
    );
  }
}

export default Form;
