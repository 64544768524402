import React, { Component } from "react";
import { DKLabel } from "deskera-ui-library";

/*
PROPS
- columnStyle
- data {style, title, type}
*/

class ReportTableCell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data
    });
  }
  render() {
    var title = this.state.data.title;
    if (this.props.index === 0 && this.props.noOfChildren > 0) {
      title =
        (this.props.expanded
          ? "<span style='color: gray; font-size: 11px'>▼ </span>"
          : "<span style='color: gray; font-size: 11px'>▲ </span>") + title;
    }
    return (
      <div
        style={{
          width: this.props.columnStyle.width
        }}
        onClick={() => this.props.onClick?.()}
      >
        <DKLabel
          className="p-v-xs"
          style={{
            ...{
              textAlign: this.props.columnStyle.textAlign,
              fontWeight:
                this.props.data.type === "bold" ||
                this.props.data.type === "bold-line" ||
                this.props.data.type === "bold-lines"
                  ? "600"
                  : "normal",
              borderTop:
                this.props.data.type === "bold-line" ||
                this.props.data.type === "bold-lines"
                  ? "1px solid lightGray"
                  : "none",
              borderBottom:
                this.props.data.type === "bold-lines"
                  ? "1px solid lightGray"
                  : "none"
            },
            ...this.state.data.style
          }}
          text={title}
        />
      </div>
    );
  }
}

export default ReportTableCell;
