import React, { useEffect, useRef, useState } from "react";
import * as ReactDOM from "react-dom";
import {
  DKButton,
  DKInput,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKListPicker2,
  DKLabel,
  showLoader,
  removeLoader,
  DKIcon,
  DKIcons,
  showAlert
} from "deskera-ui-library";
import ICurrency from "../../model/Currency";
import { useAppDispatch } from "../../redux/hooks";
import { fetchCurrencies, getCurrencies } from "../../redux/slices/tenantSlice";
import { Provider, useSelector } from "react-redux";
import Utility from "../../utility/Utility";
import Tenant from "../../services/tenant";
import DataParser from "../../Helper/DataParser";
import UserManager from "../../managers/UserManager";
import IAM from "../../services/iam";
import { COUNTRIES_WITH_CURRENCIES } from "../../constants/Currencies";
import { DEFAULT_CURRENCY_CODE } from "../../constants/Constant";
import { DEFAULT_FINANCIAL_START_DATE } from "../../services/books";
import Popup from "./Popup";
import { store } from "../../redux/store";
import AppManager from "../../managers/AppManager";
import { DATE_FORMATS, DateUtil } from "../../utility/Date";

export default function AddNewTenant(props) {
  const tenantEditData = props?.tenantEdit;
  const [showCurrencyPicker, setShowCurrencyPicker] = useState(false);
  const [tenantName, setTenantName] = useState(tenantEditData?.name || "");
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const [triggerValidation, setTriggerValidation] = useState(false);
  const currencyInput = useRef(null);
  const dispatch = useAppDispatch();
  const currencies = useSelector(getCurrencies());
  const [selectedCountry, setSelectedCountry] = useState(null);
  useEffect(() => {
    if (Utility.isEmptyObject(currencies)) {
      dispatch(fetchCurrencies());
    }
    currencyInput.current.onclick = () => {
      setShowCurrencyPicker(true);
    };

    return () => {};
  }, []);

  const getFinancialStartDate = () => {
    let todayDate = new Date();
    todayDate.setDate(DEFAULT_FINANCIAL_START_DATE);
    return todayDate;
  };

  const [bookBeginningStartDate, setBookBeginningStartDate] = useState(
    getFinancialStartDate()
  );
  const [financialStartDate, setFinancialStartDate] = useState(
    getFinancialStartDate()
  );

  useEffect(() => {
    const defaultCurrency = currencies.find(
      (currency) => currency.currencyCode === DEFAULT_CURRENCY_CODE
    );
    setSelectedCurrency(defaultCurrency);
    const country = COUNTRIES_WITH_CURRENCIES.find(
      (country) => country.country === "United States of America"
    );
    setSelectedCountry(country);
  }, [currencies]);

  useEffect(() => {
    if (
      !Utility.isEmptyObject(tenantEditData?.financialStartDate) &&
      !Utility.isEmptyObject(tenantEditData?.bookBeginningStartDate)
    ) {
      try {
        setFinancialStartDate(
          DateUtil.getDateFromString(
            tenantEditData?.financialStartDate,
            DATE_FORMATS["YYYY-MM-DD"]
          )
        );
        setBookBeginningStartDate(
          DateUtil.getDateFromString(
            tenantEditData?.bookBeginningStartDate,
            DATE_FORMATS["YYYY-MM-DD"]
          )
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [tenantEditData]);

  const onSave = (edit = false) => {
    if (Utility.isEmptyObject(tenantName)) {
      setTriggerValidation(true);
      return;
    } else {
      if (edit) {
        callUpdateApi();
      } else {
        callAddApi();
      }
    }
  };

  const callAddApi = () => {
    showLoader("Adding organization...");
    Tenant.addTenant({
      tenantName,
      currencyLookUpCode: Utility.isEmptyObject(selectedCurrency)
        ? DEFAULT_CURRENCY_CODE
        : selectedCurrency.currencyCode,
      countryLookupCode: Utility.isEmptyObject(selectedCountry)
        ? "US"
        : selectedCountry.countryCode,
      userId: UserManager.getUserIamID(),
      bookBeginningStartDate: Utility.isEmptyObject(bookBeginningStartDate)
        ? DataParser.formatDate(bookBeginningStartDate)
        : DataParser.formatDate(getFinancialStartDate()),
      financialStartDate: Utility.isEmptyObject(financialStartDate)
        ? DataParser.formatDate(financialStartDate)
        : DataParser.formatDate(getFinancialStartDate()),
      dateFormatLookUpCode: "dd-mm-yyyy",
      isBookkeeper: false
    })
      .then((res: any) => {
        if (res && res.tenantId) {
          props?.onSave?.({ tenantName, selectedCurrency });
          IAM.switchTenant({ tenantId: res.tenantId }).then((res) => {
            window.location.reload();
          });
        }
      })
      .finally(() => {
        removeLoader();
      });
  };

  const callUpdateApi = () => {
    showLoader("Updating organization...");
    const payload = {
      tenantName,
      currencyLookUpCode: Utility.isEmptyObject(selectedCurrency)
        ? DEFAULT_CURRENCY_CODE
        : selectedCurrency.currencyCode,
      countryLookupCode: Utility.isEmptyObject(selectedCountry)
        ? "US"
        : selectedCountry.countryCode,
      userId: UserManager.getUserIamID().toString(),
      bookBeginningStartDate: Utility.isEmptyObject(bookBeginningStartDate)
        ? DataParser.formatDate(bookBeginningStartDate)
        : DataParser.formatDate(getFinancialStartDate()),
      financialStartDate: Utility.isEmptyObject(financialStartDate)
        ? DataParser.formatDate(financialStartDate)
        : DataParser.formatDate(getFinancialStartDate()),
      dateFormatLookUpCode: "dd-mm-yyyy",
      isBookkeeper: false,
      complianceEnabled: false,
      numberFormat: "us"
    };
    Tenant.updateTenant(payload)
      .then((res: any) => {
        if (res?.tenantId) {
          IAM.switchTenant({ tenantId: res.tenantId })
            .then((res) => {
              if (res) {
                const buttons = [
                  {
                    title: "Ok",
                    className: "bg-button text-white ml-r",
                    onClick: () => AppManager.reloadApp()
                  }
                ];
                showAlert(
                  "Updated successfully",
                  "Your organisation setup is complete. You can now continue using the app.",
                  buttons
                );
              }
            })
            .finally(() => removeLoader());
        }
      })
      .catch(() => removeLoader());
  };

  const onClose = () => {
    if (props?.id) {
      const popup = document.getElementById(props.id);
      if (!Utility.isEmptyObject(popup)) {
        ReactDOM.unmountComponentAtNode(popup);
        popup?.remove();
      }
    }
    props?.onClose?.();
  };
  return (
    <div className="column parent-width">
      <div className="row parent-width">
        <div className="row">
          <DKLabel
            text={`${!Utility.isEmptyObject(tenantEditData) ? "Complete org setup" : "New Organization"}`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row justify-content-end">
          {Utility.isEmptyObject(props.id) && (
            <DKButton
              title="Cancel"
              className="bg-gray1 border-m fw-m"
              onClick={onClose}
            />
          )}
          <DKButton
            title={tenantEditData ? "Save" : "Add"}
            className="bg-button ml-r text-white fw-m"
            onClick={() => onSave(tenantEditData ? true : false)}
          />
        </div>
      </div>
      <div className="row mt-l">
        <DKInput
          autoFocus={Utility.isEmptyObject(tenantEditData)}
          type={INPUT_TYPE.TEXT}
          className="parent-width"
          title="Company Name"
          readOnly={!Utility.isEmptyObject(tenantEditData)}
          required
          value={tenantName}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(text) => {
            setTriggerValidation(!!text);
            setTenantName(text);
          }}
          canValidate={triggerValidation && Utility.isEmptyObject(tenantName)}
        />
      </div>
      <div className="row mt-l mb-m" ref={currencyInput}>
        <DKInput
          type={INPUT_TYPE.TEXT}
          className="parent-width"
          title="Currency"
          required
          readOnly
          value={
            selectedCurrency?.currencyName
              ? `${selectedCurrency?.currencyName} (${selectedCurrency?.currencyCode})`
              : "United States Dollar (USD)"
          }
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          invalid={triggerValidation && Utility.isEmptyObject(selectedCurrency)}
        />
        <DKIcon
          src={DKIcons.ic_arrow_down2}
          style={{
            right: 35
          }}
          className="ic-s position-absolute mt-m"
        />
        {showCurrencyPicker && (
          <DKListPicker2
            title="Select Currency"
            className="position-absolute z-index-3 shadow-m border-s"
            style={{
              top: currencyInput.current.offsetTop,
              width: currencyInput.current.clientWidth
            }}
            searchableKey={"currencyName"}
            allowSearch={true}
            data={currencies}
            renderer={(index, currency: ICurrency) => {
              return (
                <div className="row justify-content-between p-v-xs">{`${currency.currencyName} (${currency.currencyCode})`}</div>
              );
            }}
            onSelect={(index, obj) => {
              setShowCurrencyPicker(false);
              setSelectedCurrency(obj);
              setTriggerValidation(!!obj);
            }}
            onClose={() => setShowCurrencyPicker(false)}
          />
        )}
      </div>
      <div className="row mb-m">
        <DKInput
          title="Country"
          type={INPUT_TYPE.DROPDOWN}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={true}
          className="mt-m"
          value={selectedCountry}
          canValidate={false}
          onChange={(value) => {}}
          formatter={(obj) => {
            return obj.country;
          }}
          dropdownConfig={{
            title: "Select Country",
            allowSearch: true,
            searchableKey: "country",
            style: { minWidth: 230 },
            className: "shadow-m",
            data: COUNTRIES_WITH_CURRENCIES,
            renderer: (index, obj) => {
              return <DKLabel text={`${obj.country}`} />;
            },
            onSelect: (index, obj, rowIndex) => {
              setSelectedCountry(obj);
            }
          }}
        />
      </div>
      <div className="row mt-l mb-m">
        <DKInput
          className="mr-m"
          value={financialStartDate}
          title={`Financial Start Date`}
          placeholder=""
          required={false}
          type={INPUT_TYPE.DATE}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(data) => {
            setFinancialStartDate(data);
          }}
        />
        <DKInput
          className="mr-m1"
          value={bookBeginningStartDate}
          title={`Beginning Start Date`}
          placeholder=""
          required={false}
          type={INPUT_TYPE.DATE}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(data) => {
            setBookBeginningStartDate(data);
          }}
        />
      </div>
    </div>
  );
}
export const showAddNewTenantPopup = (
  config: {
    tenantEdit: any;
  },
  onSave?: (data) => void,
  onClose?: () => void
) => {
  const id = `add-new-tenant-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <Provider store={store}>
      <Popup popupWindowStyles={{ overflow: "visible" }}>
        <AddNewTenant id={id} {...config} onSave={onSave} onClose={onClose} />
      </Popup>
    </Provider>,
    document.body.appendChild(div)
  );
};
