import { DKLabel, Toggle, DKButton } from "deskera-ui-library";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateTenantSettings } from "../../services/tenant";
import Utility from "../../utility/Utility";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";

export function AccountLinking(props) {
  const tenantDetails = useAppSelector(selectCRMSettings());
  const [toggle, setToggle] = useState(
    tenantDetails?.isDealRequiresAccount || false
  );
  const dispatch = useAppDispatch();

  const onToggle = async () => {
    try {
      setToggle(!toggle);
      let accountLinkingPayload = { isDealRequiresAccount: !toggle };
      const response = await updateTenantSettings(accountLinkingPayload);
      if (response) {
        dispatch(fetchCRMSettings({}));
        // props.onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSave = async () => {
    try {
      let accountLinkingPayload = { isDealRequiresAccount: toggle };
      const response = await updateTenantSettings(accountLinkingPayload);
      if (!Utility.isEmptyObject(response)) {
        dispatch(fetchCRMSettings({}));
        props.onClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return (
    <div className="row justify-content-between border-m border-radius-m position-relative p-r mt-l">
      <div className="column">
        <DKLabel text={`Enable Account Linkage`} className="mr-m fw-m pb-xs" />
        <DKLabel
          className="text-gray text-ellipsis"
          text="Account linkage needed with contact for Deal creation"
        />
      </div>
      <div className="row justify-content-end ml-l" style={{ width: 75 }}>
        <Toggle isOn={toggle} onChange={onToggle} color="bg-green" />
      </div>
    </div>
  );
}
export default AccountLinking;
