import { DKIcon, DKIcons, DKLabel, DKButton } from "deskera-ui-library";
import { useEffect } from "react";
import { connect } from "react-redux";
import ic_access_denied from "../../assets/icons/ic_access_denied.png";
import ApiConstants from "../../constants/ApiConstants";
import { PRODUCTS, USER_ROLE } from "../../constants/Enum";
import AppManager from "../../managers/AppManager";
import RouteManager from "../../managers/RouteManager";
import UserManager from "../../managers/UserManager";
import Utility from "../../utility/Utility";

const AccessDeniedAlert = (props) => {
  useEffect(() => {
    RouteManager.setPresenter({ props });
    checkProductAccessForUser();
  }, []);

  useEffect(() => {
    checkProductAccessForUser();
  }, [props.usersInfo]);

  const getLoggedInUserInfo = () => {
    return props.usersInfo?.find((user) => user.id === UserManager.getUserID());
  };

  const checkProductAccessForUser = () => {
    /* Checking if user has access to CRM+ product */
    const loggedInUserInfo = getLoggedInUserInfo();

    if (
      UserManager.hasProductAccess(loggedInUserInfo) ||
      AppManager.needSubscriptionApiCall
    ) {
      RouteManager.navigateToHome();
    }
  };

  let isOrgOwner =
    getLoggedInUserInfo()?.roleShortInfo?.find(
      (role) => PRODUCTS.ORGANISATION === role.appName?.toLowerCase()
    )?.shortCode === USER_ROLE.OWNER.toLowerCase();

  return (
    <div className="transparent-background">
      <div
        className="column popup-window align-items-center"
        style={{ padding: 24, maxWidth: 400 }}
      >
        <DKIcon
          src={ic_access_denied}
          className="ic-m"
          style={{ opacity: 0.6 }}
        />
        <DKLabel className="mt-r fw-m fs-m" text="Access Denied!" />
        <DKLabel
          className="mt-r text-align-center"
          text={`Looks like you don't have access to CRM+. ${
            isOrgOwner
              ? "Please go to user management to get access"
              : "Please contact your organization owner to provide access"
          }.`}
        />
        <div className="row-reverse mt-l width-auto">
          {isOrgOwner && (
            <DKButton
              title="Allow Access"
              className="bg-button text-white"
              onClick={() =>
                Utility.openInNewTab(ApiConstants.PRODUCT_URL_GO + "users")
              }
            />
          )}
          <DKButton
            title="Refresh Page"
            className="bg-gray2 border-m mr-r"
            onClick={() => AppManager.reloadApp()}
          />
          {!isOrgOwner && (
            <DKButton
              title="Logout"
              onClick={() => AppManager.logout()}
              icon={DKIcons.ic_logout}
              className="bg-gray2 border-m mr-r"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  usersInfo: state.tenant.usersInfo?.content
});

export default connect(mapStateToProps, null)(AccessDeniedAlert);
