import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { addRecord, setRecords } from "../../../redux/slices/recordSlice";
import { DKLabel, isEmpty } from "deskera-ui-library";
import {
  COLUMN_CODE,
  TableManger,
  TABLES
} from "../../../managers/TableManger";
import { getDashboardWidgetLoading } from "../../dashboard/Dashboard";
import { toCurrencyFormat } from "../../../utility/Utility";
import RouteManager, {
  PAGE_ROUTES,
  ROUTE_ID_IDENTIFIER
} from "../../../managers/RouteManager";
import { store } from "../../../redux/store";
import { fetchRecordsByTable } from "../../../redux/slices/recordSlice";

interface IDealCardProps extends PropsFromRedux {
  onCountUpdated: (count: number) => void;
}
interface IDealCardState {
  data: any[];
  showLoading: boolean;
}
class DealCard extends Component<IDealCardProps, IDealCardState> {
  columnsIds = {
    name: "",
    status: "",
    amount: ""
  };
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      showLoading: false
    };
  }
  componentDidMount() {
    this.setState({
      showLoading: true
    });
    this.setColumnsIds();
    this.fetchRecords();
  }
  componentWillUnmount() {}
  UNSAFE_componentWillReceiveProps(props) {
    if (props.data) {
      this.onDataReceived(props.data);
    }
  }
  render() {
    return (
      <>
        {this.state.showLoading && getDashboardWidgetLoading()}
        {
          <>
            {!this.state.showLoading &&
              this.state.data &&
              this.state.data.length !== 0 && (
                <>{this.state.data.map((record) => this.getRow(record))}</>
              )}
          </>
        }
        {!this.state.showLoading &&
          this.state.data &&
          this.state.data.length === 0 &&
          this.getEmptyState()}
      </>
    );
  }

  getRow(data) {
    return (
      <div
        className="row p-v-s p-h-m mb-s justify-content-between listPickerBG border-radius-s cursor-hand"
        onClick={() =>
          RouteManager.navigateToPage(
            PAGE_ROUTES.DEALS_UI.replace(ROUTE_ID_IDENTIFIER, data.id)
          )
        }
      >
        <div>
          <div className="row">
            <div className="column">
              <div className="row">
                <DKLabel
                  text={`${data[this.columnsIds.name]}`}
                  className="fw-m"
                />
                {!isEmpty(data[this.columnsIds.status]) &&
                  data[this.columnsIds.status] !== 0 && (
                    <DKLabel
                      className={`ml-r p-v-xs p-h-s border-radius-l
                    ${
                      data[this.columnsIds.status] === 1
                        ? "bg-chip-green text-green border-green"
                        : "bg-chip-red text-red border-red"
                    }`}
                      text={data[this.columnsIds.status] === 1 ? "Won" : "Lost"}
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
        <DKLabel
          text={`${
            data[this.columnsIds.amount]
              ? toCurrencyFormat(data[this.columnsIds.amount])
              : toCurrencyFormat(0)
          }`}
          className="fw-m fs-m text-dark-gray"
        />
      </div>
    );
  }
  fetchRecords(refresh = false) {
    let data = this.props.data;
    if (refresh || isEmpty(data)) {
      store.dispatch(fetchRecordsByTable({ tableName: TABLES.DEAL }));
    } else {
      this.onDataReceived(data);
      this.fetchRecords(true);
    }
  }
  onDataReceived = (data) => {
    if (this.props.onCountUpdated) this.props.onCountUpdated(data.totalCount);
    this.setState({
      data: data.data.map((record) => {
        return { ...record.cells, id: record._id };
      }),
      showLoading: false
    });
  };
  setColumnsIds = () => {
    this.columnsIds.name = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.NAME
    );
    this.columnsIds.amount = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.AMOUNT
    );
    this.columnsIds.status = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.STATUS
    );
  };
  getEmptyState = () => {
    return (
      <div
        className={`column parent-width parent-height align-items-center justify-content-center`}
      >
        <DKLabel text="No data found" className="fw-m fs-m text-gray" />
        <DKLabel
          text="Create your first deal now"
          className="text-gray mt-xs"
        />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  data: state.records.data[TABLES.DEAL]
});
const mapDispatchToProps = { addRecord, setRecords };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(DealCard);
