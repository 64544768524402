import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import "./InactivityTimer.css";
import { useIdleTimer } from "react-idle-timer";
import ic_recent from "../../assets/icons/ic_recent.png";
import TimeoutManager from "../../managers/TimeoutManager";
import { DKIcon, DKButton, showToast, TOAST_TYPE } from "deskera-ui-library";
import Cookies from "universal-cookie";
import ApiConstants from "../../constants/ApiConstants";
import AppManager from "../../managers/AppManager";

/**
 * PROPS
 *  -inactivityTimeoutEnabled   - boolean
 *  -inactivityTimeout          - number
 *  -tokenExpiryNotifyEnabled   - boolean
 *  -tokenExpiryTime            - number
 *  -autoLogoutTime             - number
 *  -refreshTokenUrl            - string
 *  -logoutUrl                  - string
 *  -redirectUrl                - string
 *  -onTokenRefresh             - function
 */

function InactivityTimer(props) {
  const cookie = new Cookies();

  const idleTimeout = useCallback(() => {
    const idleTimeout =
      (props.inactivityTimeout - props.autoLogoutTime) * 60 * 1000;
    return idleTimeout > 0 ? idleTimeout : 1;
  }, [props.inactivityTimeout, props.autoLogoutTime]);

  const autoLogoutTime = useMemo(() => {
    return props.autoLogoutTime * 60 * 1000;
  }, [props.autoLogoutTime]);

  const [showTimer, setShowTimer] = useState(false);

  const [tokenExpiryTime, setTokenExpiryTime] = useState(props.tokenExpiryTime);
  const [remainingTime, setRemainingTime] = useState(autoLogoutTime);
  const [startInterval, setStartInterval] = useState(false);

  const [timerData, setTimerData] = useState(null);
  const [workerMessageData, setWorkerMessageData] = useState(null);

  const timeoutWorker = TimeoutManager.getTimeoutWorker();

  const onIdle = useCallback(() => {
    setShowTimer((showTimer) => {
      if (!showTimer) {
        setTimerData({
          title: "Inactivity Detected",
          message: "You will be auto logged out in"
        });
        setRemainingTime(autoLogoutTime);
        setStartInterval(true);
        return true;
      }

      return showTimer;
    });
  });

  const tokenExpiryNotify = useCallback(() => {
    setShowTimer((showTimer) => {
      if (!showTimer) {
        setTimerData({
          title: "Session Expiry",
          message: "Your current session will expire in"
        });

        const remainingTime =
          tokenExpiryTime * 1000 - Date.now() < autoLogoutTime
            ? tokenExpiryTime * 1000 - Date.now()
            : autoLogoutTime;
        setRemainingTime(remainingTime);
        setStartInterval(true);
        return true;
      }

      return showTimer;
    });
  });

  const onAction = useCallback(() => {
    setCookieData(Date.now(), null, null);
  });

  const { start } = useIdleTimer({
    timeout: idleTimeout(),
    element: document,
    startManually: true,
    crossTab: true,
    name: props.name,
    syncTimers: 1,
    onIdle: onIdle,
    onAction: onAction
  });

  const setCookieData = (userActionTime, tokenRefreshTime, logOutTime) => {
    const cookieData =
      cookie.get(props.name) || TimeoutManager.DEFAULT_COOKIE_DATA;
    if (userActionTime) {
      cookieData.userActionTime = userActionTime;
    }

    if (tokenRefreshTime) {
      cookieData.tokenRefreshTime = tokenRefreshTime;
    }

    if (logOutTime) {
      cookieData.logOutTime = logOutTime;
    }

    cookie.set(props.name, cookieData, {
      path: "/",
      domain: ApiConstants.COOKIE_DOMAIN
    });
    return cookieData;
  };

  const crossTabCookieChangeListener = () => {
    const cookieData = cookie.get(props.name);
    if (
      props.inactivityTimeoutEnabled &&
      cookieData?.userActionTime !== TimeoutManager.COOKIE_DATA?.userActionTime
    ) {
      TimeoutManager.COOKIE_DATA.userActionTime = cookieData?.userActionTime;
      start();
    }

    if (
      cookieData?.tokenRefreshTime !==
      TimeoutManager.COOKIE_DATA?.tokenRefreshTime
    ) {
      TimeoutManager.COOKIE_DATA.tokenRefreshTime =
        cookieData?.tokenRefreshTime;
      setShowTimer(false);
      refreshToken();
    }

    if (cookieData?.logOutTime !== TimeoutManager.COOKIE_DATA?.logOutTime) {
      TimeoutManager.COOKIE_DATA.logOutTime = cookieData?.logOutTime;
      logout();
    }
  };

  useEffect(() => {
    if (props.inactivityTimeoutEnabled || props.tokenExpiryNotifyEnabled) {
      // set cookie with default value
      const cookieData = setCookieData();
      TimeoutManager.COOKIE_DATA = cookieData;

      timeoutWorker.postMessage({ module: "COOKIE", event: "START" });
    }

    if (props.inactivityTimeoutEnabled) {
      start();
    }

    timeoutWorker.onmessage = (e) => {
      setWorkerMessageData(e.data);
    };

    return () => {
      timeoutWorker.postMessage({ module: "INACTIVITY", event: "STOP" });
      timeoutWorker.postMessage({ module: "SESSION", event: "STOP" });
      timeoutWorker.postMessage({ module: "COOKIE", event: "STOP" });
    };
  }, []);

  useEffect(() => {
    if (startInterval) {
      timeoutWorker.postMessage({
        module: "INACTIVITY",
        event: "START",
        autoLogoutTime: remainingTime
      });
    }
  }, [startInterval]);

  useEffect(() => {
    if (remainingTime <= 0) {
      setCookieData(null, null, Date.now());
      setStartInterval(false);
      timeoutWorker.postMessage({ module: "INACTIVITY", event: "STOP" });
      timeoutWorker.postMessage({ module: "SESSION", event: "STOP" });
    }
  }, [remainingTime]);

  useEffect(() => {
    if (props.tokenExpiryNotifyEnabled && tokenExpiryTime) {
      let timer =
        Date.now() - autoLogoutTime < tokenExpiryTime * 1000
          ? tokenExpiryTime * 1000 - Date.now() - autoLogoutTime
          : 0;
      if (timer > TimeoutManager.MAX_SESSION_TIMER) {
        timer = TimeoutManager.MAX_SESSION_TIMER;
      }

      // To stop previous timer
      timeoutWorker.postMessage({ module: "SESSION", event: "STOP" });
      timeoutWorker.postMessage({
        module: "SESSION",
        event: "START",
        tokenExpiryTime: timer
      });
    }
  }, [tokenExpiryTime]);

  useEffect(() => {
    if (!showTimer) {
      setStartInterval(false);
      timeoutWorker.postMessage({ module: "INACTIVITY", event: "STOP" });
    }
  }, [showTimer]);

  useEffect(() => {
    if (workerMessageData) {
      const { module, remainingTime } = workerMessageData;
      switch (module) {
        case "INACTIVITY":
          setRemainingTime(remainingTime);
          break;
        case "SESSION":
          tokenExpiryNotify();
          break;
        case "COOKIE":
          crossTabCookieChangeListener();
          break;
        default:
          break;
      }
    }
  }, [workerMessageData]);

  const refreshToken = () => {
    const body = {
      tenantId: -1,
      userName: "+65-1234-5678",
      refreshToken: "eyJjdHkiOiJKV1QiLCJlbmMiOiJ.."
    };

    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      withCredentials: true,
      mode: "cors",
      body: JSON.stringify(body)
    };

    fetch(props.refreshTokenUrl, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((response) => {
        if (response?.accessToken) {
          const token = TimeoutManager.parseJwt(response.accessToken);
          TimeoutManager.setTokenExpiryTime(token.exp);

          setTokenExpiryTime(token.exp);
        }
      })
      .catch(() => {
        showToast("Failed to continue current session.", TOAST_TYPE.FAILURE);
        window.open(props.redirectUrl + window.location.href, "_self");
      });
  };

  const logout = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      withCredentials: true,
      mode: "cors"
    };

    fetch(props.logoutUrl, requestOptions).finally(() => {
      window.open(props.redirectUrl + window.location.href, "_self");
    });
  };

  const getRemainingTime = (remainingTime) => {
    const milliseconds = remainingTime > 0 ? remainingTime : 0;

    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes} min ${seconds} secs`;
  };

  return (
    <Fragment>
      {showTimer && (
        <div className="timeout-background">
          <div className="timeout-popup border-bottom">
            <div className="bg-red text-white fw-b fs-l p-v-l p-h-xl border-top">
              {timerData.title}
            </div>
            <div className="p-v-l p-h-xl">
              <div className="row mt-m">
                <DKIcon src={ic_recent} className="ic-s-2 mr-m" />
                <div>{timerData.message}</div>
              </div>
              <div className="row justify-content-center text-red timer-fs mt-xxl ">
                {getRemainingTime(remainingTime)}
              </div>
              <div className="mt-xxl">
                <div>
                  Please click <b>Continue</b> to keep working,
                </div>
                <div>
                  or click <b>Log Out</b> to end your session now.
                </div>
              </div>
              <div className="row justify-content-center mt-xxl mb-s">
                <DKButton
                  title="Continue"
                  className="bg-button text-white mr-xl"
                  onClick={() => {
                    setShowTimer(false);
                    setCookieData(null, Date.now(), null);
                  }}
                />
                <DKButton
                  title="Log Out"
                  className="bg-red text-white"
                  onClick={() => {
                    setCookieData(null, null, Date.now());
                    AppManager.logout();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default InactivityTimer;
