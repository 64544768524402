import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IRole } from "../../model/Roles";
import RolesService from "../../services/common/roles";
import PermissionService from "../../services/common/permission";
import Utility from "../../utility/Utility";
import { TABLES } from "../../managers/TableManger";
export interface RolePermissionState {
  roles: IRole[];
  currentUsersRole: any;
  currentUserModuleWisePermissions: any;
}
const initialState: RolePermissionState = {
  roles: [],
  currentUsersRole: {},
  currentUserModuleWisePermissions: {}
};
export const fetchRoles = createAsyncThunk(
  "rolePermission/fetchRoles",
  async (data: any, thunkAPI) => {
    const roleService: RolesService = RolesService.getInstance();
    const response = await roleService.fetchRoles();
    return response;
  }
);
export const fetchRolesById = createAsyncThunk(
  "rolePermission/fetchRolesById",
  async (data: any, thunkAPI) => {
    const roleService: RolesService = RolesService.getInstance();
    const response = await roleService.fetchRoleById(data.roleId);
    return response;
  }
);
export const getRolesById = createAsyncThunk(
  "rolePermission/fetchRolesById",
  async (data: any, thunkAPI: any) => {
    return thunkAPI
      .getState()
      .rolePermission.roles.find((role: any) => role._id === data.roleId);
  }
);
export const createRole = createAsyncThunk(
  "rolePermission/createRole",
  async (data: any, thunkAPI) => {
    const roleService: RolesService = RolesService.getInstance();
    const response = await roleService.addRole(data);
    return response;
  }
);
export const updateRole = createAsyncThunk(
  "rolePermission/updateRole",
  async (data: any, thunkAPI) => {
    const roleService: RolesService = RolesService.getInstance();
    const response = await roleService.updateRole(data);
    return response;
  }
);
export const deleteRole = createAsyncThunk(
  "rolePermission/deleteRole",
  async (data: any, thunkAPI) => {
    const roleService: RolesService = RolesService.getInstance();
    const response = await roleService.deleteRole(data);
    return response;
  }
);
export const updateUserPermission = createAsyncThunk(
  "rolePermission/updateUserPermission",
  async (data: any, thunkAPI) => {
    const permissionService: PermissionService =
      PermissionService.getInstance();
    const response = await permissionService.updateUserPermission(data);
    return response;
  }
);
export const fetchLoggedInUserRole = createAsyncThunk(
  "rolePermission/fetchLoggedInUserRole",
  async (data: any, thunkAPI) => {
    const roleService: RolesService = RolesService.getInstance();
    const response = await roleService.fetchLoggedInUserRole();
    return response;
  }
);
export const rolePermissionSlice = createSlice({
  name: "rolePermission",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.fulfilled, (state, action: any) => {
        state.roles = action.payload?.data || [];
      })
      .addCase(createRole.fulfilled, (state, action: any) => {
        let roles = Utility.isEmptyObject(state.roles) ? [] : state.roles;
        roles.push(action.payload);
        state.roles = roles;
      })
      .addCase(updateRole.fulfilled, (state, action: any) => {
        let roles = Utility.isEmptyObject(state.roles) ? [] : state.roles;
        const index = roles.findIndex(
          (role: any) => role._id === action.payload._id
        );
        if (index !== -1) {
          roles[index] = action.payload;
        }
        state.roles = roles;
      })
      .addCase(deleteRole.fulfilled, (state, action: any) => {
        let roles = Utility.isEmptyObject(state.roles) ? [] : state.roles;
        const index = roles.findIndex(
          (role: any) => role._id === action.payload._id
        );
        if (index !== -1) {
          roles.splice(index, 1);
        }
        state.roles = roles;
      })
      .addCase(fetchLoggedInUserRole.fulfilled, (state, action: any) => {
        state.currentUsersRole = action.payload || {};
        const permissionsData: any[] = action.payload?.permissions?.data || [];
        const permittedActionsObject = {};
        permissionsData.map((data) => {
          let permissionObject = {};
          data.permissions.map(
            (permission) => (permissionObject[permission] = true)
          );
          permittedActionsObject[data.objectType.toLowerCase()] =
            permissionObject;

          if (data.objectType.toLowerCase() === TABLES.CONTACT) {
            permittedActionsObject[TABLES.SEGMENT] = permissionObject;
          }
        });
        state.currentUserModuleWisePermissions = permittedActionsObject;
      });
  }
});

export const {} = rolePermissionSlice.actions;

export const selectCurrentUsersRole = (state: any) =>
  state.rolesPermission.currentUsersRole || {};
export const selectRoleById = (state: any, id: string) =>
  state.rolesPermission.roles.find((role: any) => role._id === id);
export const selectCurrentUsersPermission = (state: any) =>
  state.rolesPermission?.currentUsersRole?.permissions?.data || [];
export const selectPermissionsByModule = (state: any, module: string) =>
  state.rolesPermission?.currentUserModuleWisePermissions?.[module] || {};
export const selectRoles = (state: any) => state.rolesPermission.roles;
export default rolePermissionSlice.reducer;
