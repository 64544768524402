import { TableManger as TableManager, TABLES } from "../managers/TableManger";
import Table from "./table";
import { showLoader, removeLoader } from "deskera-ui-library";

import ApiConstants from "../constants/ApiConstants";
import http from "../http";
import { getRandomAlphaNumericString } from "../utility/Utility";
import AutomationManager from "../components/automation/AutomationManager";
import UserManager from "../managers/UserManager";
import { CRM_CONNECTION_APP_CODE } from "../components/automation/AutomationConstants";

class AutomationService {
  static save(data: any, onSuccess: any, onError: any) {
    showLoader("Saving... Please wait");
    const record = {
      cells: data
    };
    Table.addRecord(record, TableManager.getTableId(TABLES.AUTOMATION)).then(
      (response) => {
        removeLoader();
        onSuccess(response);
      },
      (error) => {
        removeLoader();
        onError(error);
      }
    );
  }

  static update(data: any, recordId: any, onSuccess: any, onError: any) {
    showLoader("Updating... Please wait");
    Table.updateRecord(
      data,
      recordId,
      TableManager.getTableId(TABLES.AUTOMATION)
    ).then(
      (response) => {
        removeLoader();
        onSuccess(response);
      },
      (error) => {
        removeLoader();
        onError(error);
      }
    );
  }

  static getData(recordId: string, onSuccess: any, onError: any) {
    showLoader("Fetching... Please wait");
    Table.getRecordById(
      recordId,
      TableManager.getTableId(TABLES.AUTOMATION)
    ).then(
      (response) => {
        removeLoader();
        onSuccess(response);
      },
      (error) => {
        removeLoader();
        onError(error);
      }
    );
  }

  static getAllConnections() {
    return http
      .get(ApiConstants.URL.WORKFLOW.GET_CONNECTIONS, {
        params: {
          app_code: CRM_CONNECTION_APP_CODE
        }
      })
      .then(
        (res: any) => {
          if (res && res.data) {
            if (res.data.length === 0) {
              AutomationService.createConnection();
            } else {
              const connectionIds = res.data;
              let tenant_connection_id_arr = [];
              if (UserManager.getUserDetails()) {
                tenant_connection_id_arr = connectionIds.filter(
                  (connection) =>
                    connection.tenant_id ===
                    UserManager.getUserDetails().tenantID
                );
              }
              if (tenant_connection_id_arr.length > 0) {
                AutomationManager.setConnectionId(
                  tenant_connection_id_arr[0].id
                );
              } else {
                AutomationService.createConnection();
              }
            }
          }
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static createConnection() {
    return http
      .post(ApiConstants.URL.WORKFLOW.CREATE_CONNECTION, {
        app_code: CRM_CONNECTION_APP_CODE,
        authorization_code: "",
        name: `CRM Connection ${getRandomAlphaNumericString(4)}`
      })
      .then(
        (res: any) => {
          if (res && res.id) {
            AutomationManager.setConnectionId(res.id);
          }
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static getFacebookPages(appId) {
    return http.get(ApiConstants.URL.FACEBOOK.GET_PAGE_LIST(appId)).then(
      (res: any) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getFacebookForms(appId, pageId) {
    return http
      .get(ApiConstants.URL.FACEBOOK.GET_FORM_LIST(appId, pageId))
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
}

export default AutomationService;
