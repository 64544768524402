import { showAlert } from "deskera-ui-library";
import UserManager from "../managers/UserManager";
import { store } from "../redux/store";
import Utility from "../utility/Utility";
import { COLUMN_CODE, TABLES, TableManger } from "../managers/TableManger";

export class DealApprovalHelper {
  static isDealStageApprovalFlowsPresent() {
    const flowNodes = store.getState().deal.dealStageChangeApprovalFlows;
    return !Utility.isEmptyObject(flowNodes);
  }
  static getDealApprovalFlowsForLoggedInUser(requiredFor?: string) {
    if (!requiredFor) {
      requiredFor = UserManager.getUserEmail();
    }

    const flowNodes = store.getState().deal.dealStageChangeApprovalFlows;

    const approvalFlowsForCurrentUser = flowNodes.filter((approvalNode) =>
      approvalNode.fields[0].approvalFor.split(",").includes(requiredFor)
    );

    return approvalFlowsForCurrentUser;
  }
  static getDealApproverFlowsForLoggedInUser(
    documentApprovalDetails: any,
    approver = UserManager.getUserEmail()
  ) {
    const flowNodes = store.getState().deal.dealStageChangeApprovalFlows;

    const approverFlowsForCurrentUser = flowNodes.filter((flowNodeData) => {
      const approvalDetails = flowNodeData.fields[0];
      let isApproverPresent: boolean;

      if (Utility.isEmptyObject(approvalDetails.approver)) {
        isApproverPresent = !Utility.isEmptyObject(
          approvalDetails.multiApprovalDetails?.filter(
            (approvalLevelDetail) =>
              approvalLevelDetail.level ===
                (documentApprovalDetails?.currentLevel || 1) &&
              approvalLevelDetail.approver?.includes(approver)
          )
        );
      } else {
        isApproverPresent = approvalDetails.approver.includes(approver);
      }

      return isApproverPresent;
    });

    return approverFlowsForCurrentUser;
  }
  static isDealApprovalRequired(payload?: any, requiredFor?: string) {
    const approvalFlowsForCurrentUser =
      DealApprovalHelper.getDealApprovalFlowsForLoggedInUser(requiredFor);

    if (Utility.isEmptyObject(approvalFlowsForCurrentUser)) {
      return false;
    }
    return approvalFlowsForCurrentUser.some((approvalFlow) =>
      DealApprovalHelper.documentMatchesApprovalNodeConditions(
        approvalFlow?.fields?.[0]?.triggerConfiguration?.fields,
        payload
      )
    );
  }
  static documentMatchesApprovalNodeConditions(
    approvalDetails: any,
    payload: any
  ) {
    const pipeline = approvalDetails?.find(
      (pipeline) => pipeline.name === "pipelineId"
    );
    const oldStage = approvalDetails.find(
      (pipeline) => pipeline.name === "oldStageId"
    );
    // const newStage = approvalDetails.find(pipeline => pipeline.name === 'newStageId');
    // if (pipeline.value === 'any' && oldStage.value === 'any' && newStage.value === 'any_previous_stage') {
    //   return true;
    // } else if(pipeline.value === payload?.pipeline?.[0] && (oldStage?.value === payload?.stage?.[0] || oldStage?.value === 'any') && newStage.value === 'any_previous_stage' ) {
    //   return true;
    // } else {
    //   return false
    // }
    if (pipeline?.value === "any") {
      return true;
    } else if (
      pipeline?.value === payload?.pipeline?.[0] &&
      (oldStage?.value === payload?.stage?.[0] || oldStage?.value === "any")
    ) {
      return true;
    } else {
      return false;
    }
  }

  static getApproverEmail() {
    let email = new Map();

    let allApprovalFlows = store.getState().deal.dealStageChangeApprovalFlows;

    let loginUserEmail = UserManager.getUserEmail();

    if (Utility.isEmptyObject(allApprovalFlows)) {
      return email;
    }

    let currentApprovalLevel = 1;

    let userSpecificList =
      !Utility.isEmptyObject(allApprovalFlows) &&
      allApprovalFlows.filter((ele: any) =>
        ele.fields[0].approvalFor.split(",").includes(loginUserEmail)
      );
    if (Utility.isEmptyObject(userSpecificList)) {
      return email;
    }

    let filteredListWoConditions = userSpecificList.filter(
      (ele: any) =>
        ele.fields[0] &&
        (Utility.isEmptyObject(ele.fields[0].conditions) ||
          ele.fields[0].conditions.length === 0)
    );
    filteredListWoConditions &&
      filteredListWoConditions.length > 0 &&
      filteredListWoConditions.forEach((field: any) => {
        email.set(
          field["wf_graph_id"].toString(),
          Utility.isEmptyObject(field.fields[0].approver)
            ? field.fields[0].multiApprovalDetails
                .filter((ele: any) => ele.level === currentApprovalLevel)[0]
                .approver?.toString()
            : field.fields[0].approver
        );
      });

    let filteredList = userSpecificList.filter(
      (ele: any) => ele.fields[0] && ele.fields[0].conditions.length > 0
    );

    filteredList &&
      filteredList.length > 0 &&
      filteredList.forEach((approvalFlowNode: any) => {
        const approvalDetails = approvalFlowNode.fields[0];
        if (approvalDetails.logicalOperator === "and") {
          email.set(
            approvalFlowNode.wf_graph_id.toString(),
            Utility.isEmptyObject(approvalDetails.approver)
              ? approvalDetails.multiApprovalDetails
                  .filter((ele: any) => ele.level === currentApprovalLevel)[0]
                  .approver?.toString()
              : approvalDetails.approver
          );
        } else {
          email.set(
            approvalFlowNode.wf_graph_id.toString(),
            Utility.isEmptyObject(approvalDetails.approver)
              ? approvalDetails.multiApprovalDetails
                  .filter((ele: any) => ele.level === currentApprovalLevel)[0]
                  .approver?.toString()
              : approvalDetails.approver
          );
        }
      });

    return email;
  }
  static showViewApprovalsButton = () => {
    const flowNodes = store.getState().deal.dealStageChangeApprovalFlows;
    const approver = UserManager.getUserEmail();
    let emails = [];
    flowNodes.forEach((flowNodeData) => {
      const approvalDetails = flowNodeData.fields[0];
      const approvalForEmails = approvalDetails.approvalFor.split(",");
      if (Utility.isEmptyObject(approvalDetails.approver)) {
        approvalDetails.multiApprovalDetails?.filter((approvalLevelDetail) => {
          emails = approvalLevelDetail.approver.concat(approvalForEmails);
        });
      } else {
        emails = approvalDetails.approver.concat(approvalForEmails);
      }
    });
    if (emails.includes(approver)) {
      return true;
    } else {
      return false;
    }
  };
}

export const getDealApprovalAlert = (callback: any) => {
  const buttons = [
    {
      title: "Cancel",
      className: "bg-gray1 border-m"
    },
    {
      title: "Send for approval",
      className: "bg-button border-m text-white",
      onClick: callback
    }
  ];
  showAlert(
    "Approval required!",
    "This deal stage change needs approval.",
    buttons
  );
};

export const buildPayloadForDealApproval = (deal, data, sourceStageId) => {
  const dealTableId = TableManger.getTableId(TABLES.DEAL);
  let emails = DealApprovalHelper.getApproverEmail();
  const stageColumn = TableManger.getColumn(
    TABLES.DEAL,
    COLUMN_CODE.DEAL.STAGE_ID
  );

  return {
    tableId: dealTableId,
    objectType: TABLES.DEAL.toUpperCase(),
    recordId: deal?.id,
    columnId: stageColumn.id,
    columnCode: stageColumn.columnCode,
    opName: "u",
    cells: data,
    oldCells: { ...data, [stageColumn.id]: [sourceStageId] },
    approverMap: Object.fromEntries(emails)
  };
};

export const showApprovalTriggerSuccessAlert = () => {
  showAlert(
    "Approval sent",
    "Deal stage changes sent for approval, once approved changes will reflect here"
  );
};
export const showApprovalTriggerFailedAlert = () => {
  showAlert("Approval request failed!", `Couldn't send the deal for approval`);
};
