import { DKLabel, Toggle, showAlert } from "deskera-ui-library";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchTenantDetails,
  getTenantsDetails
} from "../../redux/slices/booksSlice";
import Tenant from "../../services/tenant";
import BooksService from "../../services/books";
export function UpliftDownLiftSetting(props) {
  const tenantDetails = useAppSelector(getTenantsDetails);
  const [toggle, setToggle] = useState(
    tenantDetails?.additionalSettings?.UPLIFT_DOWNLIFT_QUOTE_PRICE_ENABLED ||
      false
  );
  const dispatch = useAppDispatch();
  const isCountryUS = BooksService.getTenantCountry() === "US";
  const quoteLabel = isCountryUS ? "Estimate" : "Quote";

  const onToggle = async () => {
    try {
      setToggle(!toggle);
      let additionalSettings = { UPLIFT_DOWNLIFT_QUOTE_PRICE_ENABLED: !toggle };
      await Tenant.updateTenantDetails({ additionalSettings });
      dispatch(fetchTenantDetails({}));
      props?.onClose?.();
    } catch (error) {
      console.error(error);
      // Handle error appropriately, such as displaying an error message
    }
  };
  const onSave = () => {
    let additionalSettings = { UPLIFT_DOWNLIFT_QUOTE_PRICE_ENABLED: toggle };
    Tenant.updateTenantDetails({ additionalSettings })
      .then((response) => {
        props?.onClose?.();
        dispatch(fetchTenantDetails({}));
      })
      .catch((error) => {
        showAlert("Error", error?.errorMessage);
      });
  };
  return (
    <div>
      <div className="row justify-content-between border-m border-radius-m position-relative p-r mt-l">
        <div className="column">
          <DKLabel
            text={`Uplift/DownLift ${quoteLabel} Price`}
            className="mr-m fw-m pb-xs"
          />
          <DKLabel
            className="text-gray text-ellipsis"
            text={`Uplift/DownLift ${quoteLabel?.toLowerCase()} product price while creating ${quoteLabel?.toLowerCase()}`}
          />
        </div>
        <div className="row justify-content-end ml-l" style={{ width: 75 }}>
          <Toggle isOn={toggle} onChange={onToggle} color="bg-green" />
        </div>
      </div>
    </div>
  );
}
export default UpliftDownLiftSetting;
