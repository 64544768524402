import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ContactService from "../../services/contact";
import Dashboard from "../../services/dashboard";
import NotificationService from "../../services/notification";
import PaymentService from "../../services/payment";
import { Stripe } from "../../services/stripe";
import { isMobileAppWebView } from "../../utility/GetViewportSize";
import SupportTicketService from "../../services/supportTicket";

import Utility from "../../utility/Utility";
import Chat from "../../services/chat";
import ActivityService from "../../services/activityService";
import WhatsAppService from "../../services/whatsApp";
import {
  IWhatsAppAccountListResponse,
  IWhatsAppRecord,
  IWhatsAppTemplateResponseData
} from "../../model/WhatsAppCampaign";
import LeadSyncService, { LEAD_APP_NAME } from "../../services/indiamart";
import MixpanelService from "../../services/mixpanelService";
import { RootState } from "../store";
export const DEFAULT_DASHBOARD_CARD_ORDER = [
  {
    name: "CONTACT_BY_LABEL",
    visible: true,
    chartType: "DOUGHNUT"
  },
  {
    name: "DEALS_BY_PIPELINE",
    visible: true,
    chartType: "BAR_HORIZONTAL"
  },
  {
    name: "DEAL_WON_LOST",
    visible: true,
    chartType: "LINE"
  },
  {
    name: "CONTACT_TREND",
    visible: true,
    chartType: "LINE"
  },
  {
    name: "CAMPAIGN",
    visible: !isMobileAppWebView()
  },
  {
    name: "LANDING_PAGE",
    visible: !isMobileAppWebView()
  },
  {
    name: "FORM",
    visible: !isMobileAppWebView()
  },
  /* {
  name: 'FUNNEL',
  visible: !isMobileAppWebView()
}, */
  {
    name: "AUTOMATION",
    visible: !isMobileAppWebView()
  },
  {
    name: "ACTIVITY",
    visible: !isMobileAppWebView()
  },
  {
    name: "TICKET",
    visible: !isMobileAppWebView()
  }
];
export interface UserPrefState {
  isStripeConnected: boolean;
  paymentAccounts: {
    razorpay?: [];
    paytm?: [];
    cashfree?: [];
  };
  dashboardCardOrder: any[];
  notifications: any;
  supportEmails: any;
  isNewNotification: boolean;
  isSettingPopup: boolean;
  selectedSettingg: any;
  chatSettings: any;
  contactEmailSupport: {};
  hiddenColumns: {
    [key: string]: string[];
  };
  freezedColumns: {
    [key: string]: string[];
  };
  acknowledgedVersion?: string;
  activePipelineId?: string;
  sendEmailAlerts?: boolean;
  activityIcalUrl: string;
  whatsappAccounts: IWhatsAppAccountListResponse;
  whatsappTemplates: { [key: number]: IWhatsAppTemplateResponseData[] };
  indiaMartAccounts: any[];
  justDialAccounts: any[];
  mixpanelToken: string;
  viewDocScreenFullSize: boolean;
}

const initialState: UserPrefState = {
  isStripeConnected: false,
  paymentAccounts: {
    razorpay: [],
    paytm: [],
    cashfree: []
  },
  dashboardCardOrder: DEFAULT_DASHBOARD_CARD_ORDER,
  notifications: {},
  supportEmails: null,
  isNewNotification: false,
  isSettingPopup: false,
  // selectedSettingg:NEW_SETTINGS_SECTION.ORGANISATION_SETTINGS,
  selectedSettingg: null,
  chatSettings: {},
  contactEmailSupport: {},
  hiddenColumns: {},
  freezedColumns: {},
  acknowledgedVersion: null,
  activePipelineId: null,
  activityIcalUrl: "",
  whatsappAccounts: {},
  whatsappTemplates: {},
  indiaMartAccounts: [],
  justDialAccounts: [],
  mixpanelToken: null,
  viewDocScreenFullSize: true
};

export const fetchStripeAccount = createAsyncThunk(
  "userPref/fetchStripeAccount",
  async (data, thunkAPI) => {
    const response = await Stripe.getStripeAccounts();
    return response.data;
  }
);
export const addStripeAccount = createAsyncThunk(
  "userPref/addStripeAccount",
  async (data, thunkAPI) => {
    const response = await Stripe.addStripeAccount(data);
    return response;
  }
);
export const getUserPreferences = createAsyncThunk(
  "userPref/getUserPreferences",
  async (data, thunkAPI) => {
    const response = await Dashboard.getPreference();
    return response;
  }
);
export const setUserPreferences = createAsyncThunk(
  "userPref/setUserPreferences",
  async (data: any, thunkAPI) => {
    const response = await Dashboard.setPreference(data);
    thunkAPI.dispatch(getUserPreferences());
    return response;
  }
);
export const getAccountsByGateway = createAsyncThunk(
  "userPref/getAccountsByGateway",
  async (data: any, thunkAPI) => {
    const response = await PaymentService.getConnectedAccountByGateway(data);
    return response;
  }
);
export const getNotification = createAsyncThunk(
  "userPref/getNotification",
  async (data: any, thunkAPI) => {
    const response = await NotificationService.getNotifications(data);
    return response;
  }
);
export const fetchSupportEmails = createAsyncThunk(
  "userPref/fetchSupportEmails",
  async (data, thunkAPI) => {
    const response = await SupportTicketService.getEmails();
    return response;
  }
);

export const fetchEmailSetup = createAsyncThunk(
  "userPref/emailSetup",
  async (data: any, thunkAPI) => {
    const response: any = await ContactService.checkEmailSetup();
    return response;
  }
);
export const fetchChatSettings = createAsyncThunk(
  "userPref/chatSettings",
  async (data: any, thunkAPI) => {
    const response: any = await Chat.getChatSetting();
    return response;
  }
);
export const fetchIcalUrlSettings = createAsyncThunk(
  "userPref/icalUrlSetting",
  async (data: any, thunkAPI) => {
    const response: any = await ActivityService.generateIcalUrl();
    return response.link;
  }
);

/* ************* WhatsApp Account Actions ************* */
export const linkWhatsappAccount = createAsyncThunk(
  "userPref/linkWhatsappAccount",
  async (data: any, thunkAPI) => {
    const response: IWhatsAppRecord = await WhatsAppService.linkWhatsAppAccount(
      data.accountData
    );
    return response;
  }
);
export const unlinkWhatsappAccount = createAsyncThunk(
  "userPref/unlinkWhatsappAccount",
  async (data: any, thunkAPI) => {
    const response: any = await WhatsAppService.unlinkWhatsappAccount(
      data.accountId
    );
    return response;
  }
);
export const fetchWhatsappAccounts = createAsyncThunk(
  "userPref/fetchWhatsappAccounts",
  async (data: any, thunkAPI) => {
    const response: IWhatsAppAccountListResponse =
      await WhatsAppService.getWhatsappAccounts();
    return response;
  }
);
export const fetchWhatsappTemplatesByAccount = createAsyncThunk(
  "userPref/fetchWhatsappTemplatesByAccount",
  async (data: any, thunkAPI) => {
    const response: IWhatsAppTemplateResponseData[] =
      await WhatsAppService.getWhatsappTemplatesByAccount(data.accountId);
    return response;
  }
);

/* ****************** LEAD SYNC INTEGRATIONS ************** */
export const fetchIndiaMartAccounts = createAsyncThunk(
  "userPref/fetchIndiaMartAccounts",
  async (data: any, thunkAPI) => {
    const response = await LeadSyncService.getAccountConfigs(
      LEAD_APP_NAME.INDIAMART
    );
    return response;
  }
);

export const fetchJustDialAccounts = createAsyncThunk(
  "userPref/fetchJustDialAccounts",
  async (data: any, thunkAPI) => {
    const response = await LeadSyncService.getAccountConfigs(
      LEAD_APP_NAME.JUSTDIAL
    );
    return response;
  }
);

//mixpanel
export const fetchMixpanelToken = createAsyncThunk(
  "userPref/mixpanelToken",
  async (data: any, thunkAPI) => {
    const response: any = await MixpanelService.getMixpanelToken();
    return response.token;
  }
);

export const userPrefSlice = createSlice({
  name: "userPref",
  initialState,
  reducers: {
    updateDashboardOrder: (
      state: UserPrefState,
      action: PayloadAction<any>
    ) => {
      state.dashboardCardOrder = action.payload?.filter(
        (card) => card.name !== "FUNNEL"
      );
    },
    setNewNotificationFlag: (
      state: UserPrefState,
      action: PayloadAction<any>
    ) => {
      state.isNewNotification = action.payload;
    },
    setSettingPopup: (state: UserPrefState, action: PayloadAction<any>) => {
      state.isSettingPopup = action.payload;
    },
    setSelectedSetting: (state: UserPrefState, action: PayloadAction<any>) => {
      state.selectedSettingg = action.payload;
    },
    updateHiddenColumns: (state: UserPrefState, action: PayloadAction<any>) => {
      state.hiddenColumns = action.payload;
    },
    updateNotifications: (state: UserPrefState, action: PayloadAction<any>) => {
      /* Pushing new notification in store, & removing the last notification */
      const newNotifications = [
        action.payload.notification,
        ...(state.notifications.data?.slice(0, -1) || [])
      ];
      newNotifications.sort((notification1, notification2) => {
        if (notification1.createdAt > notification2.createdAt) {
          return -1;
        } else if (notification1.createdAt < notification2.createdAt) {
          return 1;
        } else {
          return 0;
        }
      });

      state.notifications.data = newNotifications;
      state.notifications.totalCount =
        (state.notifications.totalCount || 0) + 1;
    },
    setViewDocScreenFullSize: (
      state: UserPrefState,
      action: PayloadAction<boolean>
    ) => {
      state.viewDocScreenFullSize = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStripeAccount.fulfilled, (state, action) => {
      state.isStripeConnected = action.payload.length !== 0;
    });
    builder.addCase(addStripeAccount.fulfilled, (state, action) => {
      state.isStripeConnected = true;
    });
    builder.addCase(getUserPreferences.fulfilled, (state, action: any) => {
      state.dashboardCardOrder = (
        action.payload?.dashboardSettings?.order || DEFAULT_DASHBOARD_CARD_ORDER
      ).filter((card) => card.name !== "FUNNEL");
      state.hiddenColumns = action.payload?.hiddenColumns || {};
      state.acknowledgedVersion = action.payload?.acknowledgedVersion || "1";
      state.activePipelineId = action.payload?.activePipelineId;
      state.sendEmailAlerts = !!action.payload?.sendEmailAlerts;
      state.freezedColumns = action.payload.freezedColumns || {};
    });
    builder.addCase(getAccountsByGateway.fulfilled, (state, action: any) => {
      state.paymentAccounts[action.meta.arg] = action.payload;
    });
    builder.addCase(getNotification.fulfilled, (state, action: any) => {
      state.notifications = action.payload || {};
    });
    builder.addCase(fetchSupportEmails.fulfilled, (state, action: any) => {
      state.supportEmails = action.payload;
    });
    builder.addCase(fetchEmailSetup.fulfilled, (state, action) => {
      state.contactEmailSupport = Utility.isEmptyObject(action.payload)
        ? {}
        : action.payload;
    });
    builder.addCase(fetchChatSettings.fulfilled, (state, action) => {
      state.chatSettings = action.payload;
    });
    builder.addCase(fetchIcalUrlSettings.fulfilled, (state, action) => {
      state.activityIcalUrl = action.payload;
    });
    builder.addCase(linkWhatsappAccount.fulfilled, (state, action) => {
      const whatsAppAccounts = state.whatsappAccounts.data || [];
      whatsAppAccounts.push(action.payload);
      state.whatsappAccounts.data = whatsAppAccounts;
    });
    builder.addCase(unlinkWhatsappAccount.fulfilled, (state, action) => {
      const whatsAppAccounts = state.whatsappAccounts.data || [];
      state.whatsappAccounts.data = whatsAppAccounts.filter(
        (accountData: IWhatsAppRecord) =>
          accountData.id !== action.meta.arg.accountId
      );
    });
    builder.addCase(fetchWhatsappAccounts.fulfilled, (state, action) => {
      state.whatsappAccounts = action.payload;
    });
    builder.addCase(
      fetchWhatsappTemplatesByAccount.fulfilled,
      (state, action) => {
        state.whatsappTemplates[action.meta.arg.accountId] = action.payload;
      }
    );
    builder.addCase(fetchIndiaMartAccounts.fulfilled, (state, action) => {
      state.indiaMartAccounts = action.payload;
    });
    builder.addCase(fetchJustDialAccounts.fulfilled, (state, action) => {
      state.justDialAccounts = action.payload;
    });
    builder.addCase(fetchMixpanelToken.fulfilled, (state, action) => {
      state.mixpanelToken = action.payload;
    });
  }
});

export const {
  updateDashboardOrder,
  setNewNotificationFlag,
  updateNotifications,
  updateHiddenColumns,
  setSettingPopup,
  setSelectedSetting,
  setViewDocScreenFullSize
} = userPrefSlice.actions;
export const selectUserPreference = (state: RootState) => state.userPref;
export const getIndiaMartAccounts = (state: RootState) =>
  state.userPref.indiaMartAccounts;
export const getJustDialAccounts = (state: RootState) =>
  state.userPref.justDialAccounts;
export const selectSelectedSetting = (state: RootState) =>
  state.userPref.selectedSettingg;
export const selectViewDocScreenFullSize = (state: RootState) =>
  state.userPref.viewDocScreenFullSize;
export default userPrefSlice.reducer;
