import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  DKInput,
  DKButton,
  INPUT_VIEW_DIRECTION,
  showAlert
} from "deskera-ui-library";
import RoleSelector from "./RoleSelector";

import Utility from "../../../utility/Utility";
import ApiConstants from "../../../constants/ApiConstants";

import { fetchUsers } from "../../../redux/slices/tenantSlice";
import InviteService from "../../../services/inviteService";
import { IPermissionPayload } from "../../../services/common/permission";

interface InviteUserProps extends PropsFromRedux {
  defaultRole?: any;
  canInvite?: boolean;
  allowRoleAdd?: boolean;
  isLoading?: boolean;
  inviteUserTapped?: (data: any) => Promise<any>;
}
interface InviteUserState {
  selectedRoleId: any;
  inviteEmail?: any;
  inviteTapped?: any;
}
class InviteUser extends Component<InviteUserProps, InviteUserState> {
  roles = [];

  constructor(props: InviteUserProps) {
    super(props);
    this.state = {
      inviteEmail: "",
      selectedRoleId: this.props.defaultRole || null,
      inviteTapped: false
    };
  }
  componentDidMount(): void {
    if (Utility.isEmptyObject(this.props.users)) {
      this.props.fetchUsers();
    }
  }
  componentWillReceiveProps(nextProps: InviteUserProps) {
    this.setState({
      selectedRoleId: nextProps.defaultRole || null
    });
  }
  render() {
    return (
      <div className="row invite-user-section parent-width border-radius-s">
        {this.getInputSection()}
        <div className="display-flex row-responsive width-auto justify-content-start align-items-end position-relative">
          {this.getRoleSelectorSection()}
          {this.getInviteButton()}
        </div>
      </div>
    );
  }
  getInputSection() {
    return (
      <DKInput
        title="Add user with email"
        errorMessage="Please enter a valid email address"
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={true}
        autoComplete="off"
        value={this.state.inviteEmail}
        onChange={(text: string) => {
          this.setState({ inviteEmail: text });
        }}
        canValidate={this.state.inviteTapped}
        type="email"
      />
    );
  }
  getRoleSelectorSection() {
    return (
      <div className="column mr-s">
        <RoleSelector
          selectedRoleId={this.state.selectedRoleId}
          labelClassName="border-m"
          defaultLabel={this.props.isLoading ? "Loading..." : "Select role"}
          allowRoleAdd={this.props.allowRoleAdd}
          allowRoleAssign={
            true
          } /* Roles only get assigned after submitting invite user request, hence allowing select role action here without alert */
          onChangeRole={(newRole) => {
            this.setState({
              selectedRoleId: newRole._id
            });
          }}
        />
      </div>
    );
  }
  getInviteButton() {
    return (
      <DKButton
        title="Invite user"
        className="bg-button text-white fw-m"
        style={{ paddingTop: 9, paddingBottom: 9 }}
        onClick={() => {
          this.onInvite();
        }}
      />
    );
  }

  onInvite = () => {
    if (!this.props.canInvite) {
      showAlert(
        "Operation not allowed!",
        "You don't have permission to invite users, please contact your organization owner."
      );
      return;
    }

    const { inviteEmail } = this.state;
    if (
      Utility.isEmptyObject(inviteEmail) ||
      !Utility.isValidEmail(inviteEmail)
    ) {
      this.setState({ inviteTapped: true });
    } else {
      let dataToSend = {
        appName: ApiConstants.APP_NAME,
        email: inviteEmail,
        role: "paid_user"
      };
      if (this.checkDuplicateEmail(inviteEmail)) {
        showAlert("User Exists!", "User with same email already exists");
        return;
      }

      InviteService.invite(dataToSend)
        .then((res) => {
          this.setUserPermissions(inviteEmail);
          showAlert(
            `Invitation sent!`,
            `Invitation has been sent to <strong>${inviteEmail}</strong>. Please ask user to check the mail inbox for invitation email and accept it.`
          );
          this.setState({ inviteEmail: "" }, () => this.forceUpdate());
        })
        .catch((error) => {
          if (error?.code === 403) {
            showAlert(
              `Not Allowed!`,
              `Looks like you are not authorize to invite user please contact your organization owner.`
            );
          }
        });
    }
  };
  setUserPermissions = (email: string) => {
    let permissionPayload: IPermissionPayload = {
      data: [
        {
          email: email,
          roleId: this.state.selectedRoleId,
          invitationStatus: 0
        }
      ]
    };
    this.props
      .inviteUserTapped?.(permissionPayload)
      .then((res) => this.props.fetchUsers())
      .catch(() => {});
  };
  checkDuplicateEmail = (email) => {
    return this.props.users?.some((user) => user?.email === email?.trim());
  };
}
const mapStateToProps = (state) => ({
  users: state.tenant.users || []
});
const mapDispatchToProps = { fetchUsers };
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(InviteUser);
