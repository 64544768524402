import "./permission/permission.scss";
import { useState } from "react";
import { DKLabel, DKSearchBar, DKButton } from "deskera-ui-library";

import { useAppSelector } from "../../redux/hooks";

import TeamUserRow from "./permission/TeamUserRow";
import ITeam from "../../model/Team";
import AddTeamPopup from "./permission/AddTeamPopup";

const TeamPermission = ({ permittedActions }) => {
  const [filterByName, setFilterByName] = useState("");
  const [needAddTeamPopup, setNeedAddTeamPopup] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState({});
  const team: ITeam[] = useAppSelector((state) => state.tenant.teams);

  function toggleTeamCreationPopup(value = false) {
    setNeedAddTeamPopup(value);
  }

  function getTeamList() {
    return team
      ?.filter((team) => {
        const teamName = team.name?.toLowerCase();
        return teamName?.includes(filterByName);
      })
      ?.map((team, index) => {
        return (
          <TeamUserRow
            showLoading={false}
            user={team}
            onPermissionSelect={(permission) => {}}
            isTeam={true}
            onTeamEdit={(team) => {
              setSelectedTeam(team);
              setNeedAddTeamPopup(true);
            }}
          />
        );
      });
  }

  function getTeamEmptyState() {
    return (
      <div
        className="row justify-content-center"
        style={{ height: "auto", margin: "auto" }}
      >
        <div className="column align-items-center">
          <DKLabel className="text-gray fs-m fw-m" text="No team found" />
          <DKLabel
            className="text-gray mt-s p-xs text-align-center"
            text="Create new team by clicking on '+ Create Team' button"
          />
          <DKButton
            title={`+ Create team`}
            className="text-white bg-button mt-r"
            onClick={() => {
              toggleTeamCreationPopup(true);
            }}
          />
        </div>
      </div>
    );
  }

  function getUsersPermissionSection() {
    return (
      <div
        className="position-relative column border-s border-radius-s parent-size"
        style={{ overflow: "hidden" }}
      >
        <div className="row justify-content-between parent-width p-v-s border-box bg-gray1 z-index-2 ">
          <DKLabel
            text={"Teams"}
            className="parent-width p-v-s border-box bg-gray1 pl-s fw-m z-index-2"
            style={{
              top: 0,
              left: 0,
              position: "sticky"
            }}
          />
          {team.length !== 0 && (
            <DKButton
              title={`+ Create team`}
              className="text-white bg-button justify-content-end align-self-start mr-r"
              onClick={() => {
                toggleTeamCreationPopup(true);
              }}
            />
          )}
          {/* <DKSearchBar className="" onSearch={(searchText) => setFilterByName(searchText?.toLowerCase())} searchText={filterByName} /> */}
        </div>
        <div
          style={{
            minHeight: 400,
            maxHeight: "73vh",
            overflow: "hidden auto"
          }}
          className="column parent-size hide-scroll-bar"
        >
          {team.length !== 0 && getTeamList()}
          {team.length === 0 && getTeamEmptyState()}
        </div>
      </div>
    );
  }

  function getAddTeamPopup() {
    return (
      <AddTeamPopup
        onClose={() => {
          setSelectedTeam({});
          toggleTeamCreationPopup(false);
        }}
        permittedActions={permittedActions}
        selectedTeam={selectedTeam}
      />
    );
  }

  return (
    <div className="mt-l parent-width column flex-1">
      {getUsersPermissionSection()}
      {needAddTeamPopup && getAddTeamPopup()}
    </div>
  );
};

export default TeamPermission;
