import { Component, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { PAGE_ROUTES, ROUTE_ID_IDENTIFIER } from "./managers/RouteManager";
import {
  showAlert,
  removeLoader,
  showLoader,
  DKSpinner
} from "deskera-ui-library";

import Empty from "./components/common/Empty";
import TopMenu from "./components/menu/TopMenu";
import LeftMenu from "./components/menu/LeftMenu";

import Utility, { isMacintosh } from "./utility/Utility";

import IAM from "./services/iam";
import SideBarService from "./services/sidebar";

import InitialApiManager from "./managers/InitialApiManager";
import AppManager from "./managers/AppManager";
import FormManager from "./components/forms/FormManager";
import CampaignManager from "./components/campaign/CampaignManager";
import LandingPageManager from "./components/landing_pages/LandingPageManager";
import { lazyWithPreload } from "./utility/LazyPreload";

import TagManager from "react-gtm-module";
import {
  HIDE_CHAT_BUBBLE_CLASS,
  KEY_SESSION_CHUNK_LOAD_FAILED
} from "./constants/Constant";
import ErrorBoundary from "./components/common/ErrorBoundary";
import { isMobileAppWebView } from "./utility/GetViewportSize";
import { LOCAL_STORAGE_KEYS } from "./constants/Enum";
import AccessDeniedAlert from "./components/common/AccessDeniedAlert";
import DeskeraHomePage from "./components/website/DeskeraHomePage";
import PricingHome from "./components/website/PricingHome";
import ReleaseNotificationPopup from "./components/release_note/ReleaseNotificationPopup";
import Logs from "./components/settings/Logs";
import PriceBookList from "./components/price_book/PriceBookList";
import Products from "./components/products/Products";
import TimeoutManager from "./managers/TimeoutManager";
import InactivityTimer from "./components/idle-timer‎/InactivityTimer";
import ApiConstants from "./constants/ApiConstants";
/* Left Menu Modules */
const Dashboard = lazyWithPreload(
  () => import("./components/dashboard/Dashboard")
);
const Contacts = lazyWithPreload(
  () => import("./components/contacts/Contacts")
);
const Deals = lazyWithPreload(() => import("./components/deals/Deals"));
const List = lazyWithPreload(() => import("./components/list/List"));
const Campaign = lazyWithPreload(
  () => import("./components/campaign/Campaign")
);
const LandingPages = lazyWithPreload(
  () => import("./components/landing_pages/LandingPage")
);
const Forms = lazyWithPreload(() => import("./components/forms/Forms"));
/* const Funnel = lazyWithPreload(() => import("./components/funnel/Funnel")); */
const Automation = lazyWithPreload(
  () => import("./components/automation/Automation")
);
const Activity = lazyWithPreload(() => import("./components/common/Activity"));
const ChatUI = lazyWithPreload(() => import("./components/chat/ChatUI"));
const SupportTicket = lazyWithPreload(() => import("./pages/SupportTicket"));
const OrderList = lazyWithPreload(() => import("./pages/orderList"));
const Accounts = lazyWithPreload(() => import("./pages/Accounts"));

/* Report Modules */
const ActivityReport = lazyWithPreload(() => import("./pages/ActivityReport"));
const UnsubscribeReport = lazyWithPreload(
  () => import("./pages/UnsubscribeReport")
);
const ActivityByContactReport = lazyWithPreload(
  () => import("./pages/ActivityByContactReport")
);
const TicketByContactReport = lazyWithPreload(
  () => import("./pages/TicketByContactReport")
);
const Reports = lazyWithPreload(
  () => import("./components/report_builder/Reports"),
  true
);
const CustomReport = lazyWithPreload(
  () => import("./components/report_builder/CustomReport"),
  true
);

/* Internal Modules */
const ContactDetail = lazyWithPreload(
  () => import("./components/contacts/contactDetails/ContactDetail"),
  true
);
const ImportPortal = lazyWithPreload(
  () => import("./components/import_export/import"),
  true
);

const LeadScore = lazyWithPreload(() => import("./pages/LeadSocre"));

const AccountDetails = lazyWithPreload(
  () => import("./pages/AccountDetails"),
  true
);

const DealsUI = lazyWithPreload(
  () => import("./components/deals/deal_ui/DealDetails"),
  true
);

const TemplateSelection = lazyWithPreload(
  () => import("./components/common/TemplateSelection"),
  true
);

const LandingPageBuilder = lazyWithPreload(
  () => import("./components/landing_pages/LandingPageBuilder"),
  true
);
const LandingPageDomain = lazyWithPreload(
  () => import("./components/landing_pages/LandingPageDomain"),
  true
);

const FormBuilder = lazyWithPreload(
  () => import("./components/forms/FormBuilder"),
  true
);
const FormDomain = lazyWithPreload(
  () => import("./components/forms/FormDomain"),
  true
);

const CampaignBuilder = lazyWithPreload(
  () => import("./components/campaign/CampaignBuilder"),
  true
);
const CreateCampaign = lazyWithPreload(
  () => import("./components/campaign/CreateCampaign"),
  true
);
const CampaignSummary = lazyWithPreload(
  () => import("./components/campaign/CampaignSummary"),
  true
);
const CampaignSuccess = lazyWithPreload(
  () => import("./components/campaign/CampaignSuccess"),
  true
);
const CampaignDetails = lazyWithPreload(
  () => import("./components/campaign/CampaignDetails"),
  true
);
const WACampaign = lazyWithPreload(
  () => import("./components/campaign/whatsapp/WACampaign"),
  true
);

/* const FunnelBuilder = lazyWithPreload(() =>
  import("./components/funnel/FunnelBuilder"),
  true
);
const FunnelTypeSelection = lazyWithPreload(() =>
  import("./components/funnel/FunnelTypeSelection"),
  true
);
const FunnelDomain = lazyWithPreload(() =>
  import("./components/funnel/FunnelDomain"),
  true
); */

const AutomationBuilder = lazyWithPreload(
  () => import("./components/automation/AutomationBuilder"),
  true
);

const PaymentIntegration = lazyWithPreload(
  () => import("./components/settings/PaymentIntegration"),
  true
);
const Notifications = lazyWithPreload(
  () => import("./components/notification/Notifications"),
  true
);

const Quotes = lazyWithPreload(() => import("./pages/QuoteList"), true);

const ImportLog = lazyWithPreload(() => import("./pages/ImportLog"));
const AutomationLogs = lazyWithPreload(() => import("./pages/AutomationLogs"));
const AuditLogs = lazyWithPreload(() => import("./pages/AuditLogs"));
const IndiaMartLeadSyncLogs = lazyWithPreload(
  () => import("./pages/IndiaMartLeadSyncLogs")
);
const EmailVerificationPage = lazyWithPreload(
  () => import("./pages/EmailVerification")
);
const NoShopView = lazyWithPreload(() => import("./pages/NoShopView"));
const ShopDashboard = lazyWithPreload(
  () => import("./pages/ShopDashboard"),
  true
);
const ShopLeads = lazyWithPreload(() => import("./pages/ShopLeads"), true);
const DemoWrapper = lazyWithPreload(
  () => import("./components/book_a_demo/DemoWrapper"),
  true
);
const Invoices = lazyWithPreload(
  () => import("./components/common/Invoices"),
  true
);
const SalesRepresentatives = lazyWithPreload(
  () => import("./pages/SalesRepresentatives")
);

const MobileLandingScreen = lazyWithPreload(
  () => import("./components/mobile/MobileLandingScreen")
);

const Currency = lazyWithPreload(
  () => import("./components/settings/MultiCurrency/Currency")
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      didInitialApiResponseReceived: false,
      timeoutData: null
    };

    SideBarService.setIsSideBarExpanded();
  }

  componentDidMount() {
    window.addEventListener("error", this.handleChunkLoadError);

    AppManager.redirectPageGroupRoute();

    this.initializeTagManger();
    if (AppManager.isPublicURL() || AppManager.isBookADemoURL()) {
      this.onSuccessInitialCalls(null, true);
    } else {
      this.setupAppData();
    }

    Utility.setPersistentValue(LOCAL_STORAGE_KEYS.SELECTED_ACTIVITY_VIEW, 0);
    Utility.setPersistentValue(LOCAL_STORAGE_KEYS.SELECTED_DEAL_VIEW, 0);

    this.preFetchLeftMenuModules().then(this.preFetchInternalModules);

    if (isMobileAppWebView()) {
      document.body.classList.add(HIDE_CHAT_BUBBLE_CLASS);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("error", this.handleChunkLoadError);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  preFetchLeftMenuModules() {
    return Promise.all([
      Dashboard.preload(),
      Contacts.preload(),
      List.preload(),
      Deals.preload(),
      Campaign.preload(),
      Forms.preload(),
      LandingPages.preload(),
      /* Funnel.preload(), */
      Automation.preload(),
      Activity.preload(),
      ChatUI.preload(),
      SupportTicket.preload(),
      OrderList.preload(),
      Accounts.preload()
    ]);
  }
  preFetchInternalModules() {
    AppManager.chunksToPreloadOnPageLoad?.forEach((chunk) => chunk.preload?.());
    AppManager.chunksToPreloadOnPageLoad = [];
  }
  onSuccessInitialCalls = async (
    res = null,
    isCurrentRouteDataReceived = false
  ) => {
    if (isCurrentRouteDataReceived) {
      await TimeoutManager.getIdleTimeoutTokenExpiry();
      this.setState(
        {
          didInitialApiResponseReceived: true
        },
        this.onInitialApiResponseReceived
      );
      removeLoader();
    }
  };

  /** ************* CHUNK LOAD ERROR HANDLER ***********
   * @description reload page in case of chunk load failure,
   * within a session
   */
  handleChunkLoadError = (e) => {
    if (
      /Loading chunk [\d]+ failed/.test(e.message) &&
      !Utility.getPersistentSessionValue(KEY_SESSION_CHUNK_LOAD_FAILED)
    ) {
      Utility.setPersistentSessionValue(KEY_SESSION_CHUNK_LOAD_FAILED, true);
      window.location.reload();
    }
  };
  onUserAuthorizationSuccess = () => {
    InitialApiManager.getTableData()
      .then((data) => {
        this.onSuccessInitialCalls(
          null,
          !AppManager.isCurrentRoute(PAGE_ROUTES.DEALS) &&
            !AppManager.isCurrentRoute(PAGE_ROUTES.DEALS_UI)
        );

        if (
          !AppManager.isCurrentRoute(PAGE_ROUTES.DASHBOARD) &&
          !isMobileAppWebView()
        ) {
          InitialApiManager.makeInitialCalls();
        }

        InitialApiManager.makeMandatoryCalls(this.onSuccessInitialCalls);
      })
      .catch((err) => {
        showAlert(
          "Problem While Loading!",
          "Unable to load view information. Please try again!"
        );
        removeLoader();
      });
  };

  onUserAuthorizationFailure = () => {
    removeLoader();
  };
  setupAppData() {
    showLoader();
    IAM.checkIfUserLoggedIn()
      .then((res) => this.onUserAuthorizationSuccess())
      .catch((err) => this.onUserAuthorizationFailure());
  }
  initializeTagManger = () => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
  };

  onInitialApiResponseReceived() {
    this.setState({
      timeoutData: TimeoutManager.getTimeoutData()
    });
  }

  render() {
    if (window.location.hash.startsWith("#/")) {
      window.location = window.location.hash.replace("#", "");
    }

    return (
      <>
        {this.state.didInitialApiResponseReceived && this.state.timeoutData && (
          <InactivityTimer
            name={ApiConstants.IDLE_TIMER_ID}
            inactivityTimeoutEnabled={this.state.timeoutData.idleTimeoutEnabled}
            inactivityTimeout={this.state.timeoutData.idleTimeoutValue}
            tokenExpiryNotifyEnabled={this.state.timeoutData.tokenExpiryEnabled}
            tokenExpiryTime={this.state.timeoutData.tokenExpiryTime}
            autoLogoutTime={ApiConstants.AUTO_LOGOUT_TIME}
            refreshTokenUrl={
              ApiConstants.URL.BASE + ApiConstants.URL.IAM.REFRESH_TOKEN
            }
            logoutUrl={ApiConstants.URL.BASE + ApiConstants.URL.IAM.LOG_OUT}
            redirectUrl={ApiConstants.URL.IAM.REDIRECT}
          />
        )}
        <Router>
          <div className="main-holder bg-gray1 row align-items-start app-font">
            {/* LEFT MENU */}
            <Switch>
              <Suspense
                fallback={
                  <div className="row flex-1 justify-content-center">
                    <DKSpinner />
                  </div>
                }
              >
                <Route
                  exact
                  path={PAGE_ROUTES.EMAIL_VERIFICATION}
                  component={EmailVerificationPage}
                />
                <Route exact path={PAGE_ROUTES.PUBLIC_PAGE} component={Empty} />
                {this.state.didInitialApiResponseReceived &&
                  !AppManager.isPublicURL() &&
                  !AppManager.isBookADemoURL() && (
                    <Route path="/" component={LeftMenu} />
                  )}
                {this.state.didInitialApiResponseReceived && (
                  <Route
                    path={PAGE_ROUTES.MOBILE_APP_LANDING}
                    component={MobileLandingScreen}
                  />
                )}
              </Suspense>
            </Switch>
            {/* END - LEFT MENU */}

            <div
              className="parent-width parent-height column "
              style={{
                flex: 1,
                maxWidth: SideBarService.getContainerWidth(true)
                /* backgroundColor: "rgb(235, 239, 243)" */
              }}
            >
              {/* TOP MENU */}
              <Switch>
                <Route exact path={PAGE_ROUTES.PUBLIC_PAGE} component={Empty} />
                {this.state.didInitialApiResponseReceived &&
                  !AppManager.isPublicURL() &&
                  !AppManager.isBookADemoURL() && (
                    <Route path="/" component={TopMenu} />
                  )}
              </Switch>
              {/* END - TOP MENU */}

              <div
                id="containerDiv"
                className={
                  "parent-width column position-relative" +
                  (AppManager.isPublicURL() ? "" : " main-holder-padding ") +
                  (isMacintosh() ? "" : " show-scroll-bar")
                }
                style={{
                  overflowY: "auto",
                  flexGrow: 1,
                  paddingBottom: 0
                }}
              >
                <ErrorBoundary>
                  <Suspense
                    fallback={
                      <div className="row flex-1 justify-content-center">
                        <DKSpinner />
                      </div>
                    }
                  >
                    {this.state.didInitialApiResponseReceived && (
                      <Switch>
                        <Route
                          exact
                          path={PAGE_ROUTES.NO_ACCESS}
                          component={AccessDeniedAlert}
                        />

                        {/* ////////////////////// */}
                        {/* ///  GRID HOLDERS  /// */}
                        {/* ////////////////////// */}

                        <Route
                          exact
                          path={PAGE_ROUTES.CONTACTS}
                          component={Contacts}
                        />

                        <Route
                          exact
                          path={PAGE_ROUTES.QUOTES}
                          component={Quotes}
                        />

                        <Route
                          exact
                          path={PAGE_ROUTES.QUOTE}
                          component={Quotes}
                        />

                        <Route exact path={PAGE_ROUTES.LIST} component={List} />

                        <Route
                          exact
                          path={PAGE_ROUTES.CAMPAIGN}
                          component={Campaign}
                        />

                        <Route
                          exact
                          path={PAGE_ROUTES.FORMS}
                          component={Forms}
                        />

                        <Route
                          exact
                          path={PAGE_ROUTES.LANDING_PAGE}
                          component={LandingPages}
                        />

                        {/* <Route exact path={PAGE_ROUTES.FUNNEL} component={Funnel} /> */}

                        <Route
                          exact
                          path={PAGE_ROUTES.AUTOMATION}
                          component={Automation}
                        />

                        {/* ////////////////////// */}
                        {/* /////  DASHBOARD  ///// */}
                        {/* ////////////////////// */}

                        <Route
                          exact
                          path={PAGE_ROUTES.DASHBOARD}
                          component={Dashboard}
                        />

                        {/* ////////////////////// */}
                        {/* /////  CONTACTS  ///// */}
                        {/* ////////////////////// */}

                        <Route
                          exact
                          path={PAGE_ROUTES.CONTACTS_UI}
                          component={ContactDetail}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.IMPORT_PORTAL}
                          component={ImportPortal}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.CONTACT_LEAD_SCORE_UI}
                          component={LeadScore}
                        />
                        {/* ////////////////////// */}
                        {/* /////  DEALS  ///// */}
                        {/* ////////////////////// */}

                        <Route
                          exact
                          path={PAGE_ROUTES.DEALS}
                          component={Deals}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.DEALS_UI}
                          component={DealsUI}
                        />

                        {/* ////////////////////// */}
                        {/* /////  CAMPAIGN  ///// */}
                        {/* ////////////////////// */}
                        <Route
                          exact
                          path={PAGE_ROUTES.CREATE_CAMPAIGN}
                          component={CreateCampaign}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.WA_CAMPAIGN}
                          component={WACampaign}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.WA_CAMPAIGN + "/" + ROUTE_ID_IDENTIFIER
                          }
                          component={WACampaign}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.SELECT_CAMPAIGN_TEMPLATE}
                          render={(props) => (
                            <TemplateSelection
                              manager={CampaignManager}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.CAMPAIGN_SUMMARY}
                          component={CampaignSummary}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.CAMPAIGN_SUCCESS}
                          component={CampaignSuccess}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.CAMPAIGN_BUILDER}
                          component={CampaignBuilder}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.CAMPAIGN_DETAILS}
                          component={CampaignDetails}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.SELECT_CAMPAIGN_TEMPLATE +
                            "/" +
                            ROUTE_ID_IDENTIFIER
                          }
                          render={(props) => (
                            <TemplateSelection
                              manager={CampaignManager}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.CAMPAIGN_SUMMARY +
                            "/" +
                            ROUTE_ID_IDENTIFIER
                          }
                          component={CampaignSummary}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.CAMPAIGN_SUCCESS +
                            "/" +
                            ROUTE_ID_IDENTIFIER
                          }
                          component={CampaignSuccess}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.CAMPAIGN_BUILDER +
                            "/" +
                            ROUTE_ID_IDENTIFIER
                          }
                          component={CampaignBuilder}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.CAMPAIGN_DETAILS +
                            "/" +
                            ROUTE_ID_IDENTIFIER
                          }
                          component={CampaignDetails}
                        />

                        {/* ////////////////////// */}
                        {/* /////  FORMS  ///// */}
                        {/* ////////////////////// */}
                        <Route
                          exact
                          path={PAGE_ROUTES.SELECT_FORM_TEMPLATE}
                          render={(props) => (
                            <TemplateSelection
                              manager={FormManager}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.FORM_BUILDER}
                          component={FormBuilder}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.FORM_DOMAIN}
                          component={FormDomain}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.SELECT_FORM_TEMPLATE +
                            "/" +
                            ROUTE_ID_IDENTIFIER
                          }
                          render={(props) => (
                            <TemplateSelection
                              manager={FormManager}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.FORM_BUILDER + "/" + ROUTE_ID_IDENTIFIER
                          }
                          component={FormBuilder}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.FORM_DOMAIN + "/" + ROUTE_ID_IDENTIFIER
                          }
                          component={FormDomain}
                        />

                        {/* ////////////////////// */}
                        {/* /////  LANDING PAGE  ///// */}
                        {/* ////////////////////// */}
                        <Route
                          exact
                          path={PAGE_ROUTES.SELECT_LANDING_PAGE_TEMPLATE}
                          render={(props) => (
                            <TemplateSelection
                              manager={LandingPageManager}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.LANDING_PAGE_BUILDER}
                          component={LandingPageBuilder}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.LANDING_PAGE_DOMAIN}
                          component={LandingPageDomain}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.SELECT_LANDING_PAGE_TEMPLATE +
                            "/" +
                            ROUTE_ID_IDENTIFIER
                          }
                          render={(props) => (
                            <TemplateSelection
                              manager={LandingPageManager}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.LANDING_PAGE_BUILDER +
                            "/" +
                            ROUTE_ID_IDENTIFIER
                          }
                          component={LandingPageBuilder}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.LANDING_PAGE_DOMAIN +
                            "/" +
                            ROUTE_ID_IDENTIFIER
                          }
                          component={LandingPageDomain}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.SETTINGS}
                          component={Dashboard}
                        />

                        <Route exact path={PAGE_ROUTES.LOGS} component={Logs} />
                        <Route
                          exact
                          path={PAGE_ROUTES.PRICE_BOOK}
                          component={PriceBookList}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.PRODUCTS}
                          component={Products}
                        />
                        {/* ////////////////////// */}
                        {/* /////  FUNNEL  ///// */}
                        {/* ////////////////////// */}
                        {/* <Route
                        exact
                        path={PAGE_ROUTES.FUNNEL_BUILDER}
                        component={FunnelBuilder}
                      />
                      <Route
                        exact
                        path={PAGE_ROUTES.FUNNEL_SELECTION}
                        component={FunnelTypeSelection}
                      />
                      <Route
                        exact
                        path={PAGE_ROUTES.FUNNEL_DOMAIN}
                        component={FunnelDomain}
                      />
                      <Route
                        exact
                        path={PAGE_ROUTES.FUNNEL_BUILDER + "/" +  ROUTE_ID_IDENTIFIER}
                        component={FunnelBuilder}
                      />
                      <Route
                        exact
                        path={PAGE_ROUTES.FUNNEL_DOMAIN + "/" +  ROUTE_ID_IDENTIFIER}
                        component={FunnelDomain}
                      /> */}

                        {/* ////////////////////// */}
                        {/* /////  AUTOMATION  ///// */}
                        {/* ////////////////////// */}
                        <Route
                          exact
                          path={PAGE_ROUTES.AUTOMATION_BUILDER}
                          component={AutomationBuilder}
                        />
                        <Route
                          exact
                          path={
                            PAGE_ROUTES.AUTOMATION_BUILDER +
                            "/" +
                            ROUTE_ID_IDENTIFIER
                          }
                          component={AutomationBuilder}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.ORDER}
                          component={OrderList}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.PAYMENT_INTEGRATION}
                          component={PaymentIntegration}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.ACTIVITY}
                          component={Activity}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.CHAT}
                          component={ChatUI}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.IMPORT_LOGS}
                          component={ImportLog}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.AUTOMATION_LOGS}
                          component={AutomationLogs}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.AUDIT_LOGS}
                          component={AuditLogs}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.INDIA_MART_LEAD_SYNC_LOGS}
                          component={IndiaMartLeadSyncLogs}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.SUPPORT_TICKET}
                          component={SupportTicket}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.MULTI_CURRENCY}
                          component={Currency}
                        />

                        <Route
                          exact
                          path={PAGE_ROUTES.ACTIVITY_REPORT}
                          component={ActivityReport}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.UNSUBSCRIBE_REPORT}
                          component={UnsubscribeReport}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.ACTIVITY_BY_CONTACT_REPORT}
                          component={ActivityByContactReport}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.TICKET_BY_CONTACT_REPORT}
                          component={TicketByContactReport}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.REPORTS}
                          component={Reports}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.CUSTOM_REPORT}
                          component={CustomReport}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.NEW_SHOP}
                          component={NoShopView}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.SHOP_DASHBOARD}
                          component={ShopDashboard}
                        />
                        <Route
                          path={PAGE_ROUTES.NOTIFICATIONS}
                          component={Notifications}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.ACCOUNTS}
                          component={Accounts}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.ACCOUNT_DETAILS}
                          component={AccountDetails}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.SHOP_LEADS}
                          component={ShopLeads}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.BOOK_A_DEMO}
                          component={DemoWrapper}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.INVOICES}
                          component={Invoices}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.SALES_REPRESENTATIVE_SETTING}
                          component={SalesRepresentatives}
                        />
                        {/* ////////////////////// */}
                        {/* /////  PUBLIC  ///// */}
                        {/* ////////////////////// */}
                        <Route
                          exact
                          path={PAGE_ROUTES.DESKERA_HOME}
                          component={DeskeraHomePage}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.PRICING_HOME}
                          component={PricingHome}
                        />
                        <Route
                          exact
                          path={PAGE_ROUTES.PUBLIC_PAGE}
                          component={Empty}
                        />

                        <Redirect
                          from={PAGE_ROUTES.OLD_CONTACT_ROUTE}
                          to={PAGE_ROUTES.CONTACTS}
                        />
                        <Redirect
                          from={PAGE_ROUTES.OLD_LIST_ROUTE}
                          to={PAGE_ROUTES.LIST}
                        />
                        <Redirect
                          from={PAGE_ROUTES.OLD_DEAL_ROUTE}
                          to={PAGE_ROUTES.DEALS}
                        />
                        <Redirect
                          from={PAGE_ROUTES.OLD_ACTIVITY_ROUTE}
                          to={PAGE_ROUTES.ACTIVITY}
                        />
                      </Switch>
                    )}
                    {this.state.didInitialApiResponseReceived ? (
                      <ReleaseNotificationPopup />
                    ) : null}
                  </Suspense>
                </ErrorBoundary>
              </div>
            </div>
          </div>
        </Router>
      </>
    );
  }
}

export default App;
