import React, { useState } from "react";
import { DKIcons } from "deskera-ui-library";
import "../pageStyles.css";
import ContextMenuPopup from "../ContextMenuPopup";

const ALL_PRODUCT = [
  "Marketing",
  "Sales",
  "Customer Service",
  "CMS",
  "Operations"
];

export const PlanBand = (props) => {
  const [selectedItem, setSelectedItem] = useState("Marketing");
  const [selectedTab, setSelectedTab] = useState(0);
  const [popupVisibility, setPopupVisibility] = useState(false);
  return (
    <>
      <div
        className="row bg-blue justify-content-center p-v-xl text-white p-h-xl"
        style={{}}
        id="pricing_section"
      >
        <div className="">
          <div className="fs-xxl fw-h">The Right Plan to Run Your Business</div>
          <div className="p-m text-align-center">
            Get enterprise-level features at a small business price
          </div>
        </div>
      </div>
      <div
        className={`row bg-blue justify-content-center plan-button-group display-only-web ${props.isDarkTheme && "dark"}`}
        style={{}}
      >
        {["Product & Plans", "Bundles"].map((item, index) => (
          <div
            className={`plan-button fs-l ${props?.isDarkTheme ? "bg-dark" : "bg-white"} ${index === selectedTab && "active"}`}
            onClick={() => setSelectedTab(index)}
          >
            {item}
          </div>
        ))}
      </div>
      <div
        className={`row justify-content-center product-button-group fs-l display-only-web ${props.isDarkTheme && "dark"}`}
        style={{}}
      >
        {ALL_PRODUCT.map((prod) => (
          <div
            className={`product-button ${prod === selectedItem && "active"}`}
            onClick={() => {
              setSelectedItem(prod);
              props.onPlanChange(prod);
            }}
          >
            {prod}
          </div>
        ))}
      </div>
      <div
        className={`row align-items-center parent-width justify-content-center plan-button-mobile-group display-only-mobile p-v-xl position-relative ${props.isDarkTheme && "dark"}`}
        style={{ backgroundColor: "#40608b" }}
      >
        <div>
          <div className="text-white pb-xs">
            {["Product & Plans", "Bundles"][selectedTab]}
          </div>
          <div className="text-white fs-xl">{selectedItem}</div>
        </div>
        <img
          className="ic-s position-absolute cursor-hand"
          src={DKIcons.white.ic_arrow_down}
          style={{ right: 15 }}
          onClick={() => setPopupVisibility(true)}
        />
      </div>
      {popupVisibility && (
        <ContextMenuPopup
          selectedItem={selectedItem}
          onItemClick={(item) => {
            setSelectedItem(item);
            props.onPlanChange(item);
            setPopupVisibility(false);
          }}
          onClose={() => setPopupVisibility(false)}
        />
      )}
    </>
  );
};
