import { PAGE_ELEMENT, PAGE_TYPE } from "./ComponentList";
import FormService from "../../services/form";
import ContactService from "../../services/contact";
import HtmlGenerator from "./HtmlGenerator";
import Utility, { getRandomAlphaNumericString } from "../../utility/Utility";
import { STRIPE_COUNTRIES } from "../../constants/Currencies";
import ic_x from "../../assets/social_icons/ic_twitter.png";

export default class ComponentManager {
  static getDateForCountTime() {
    let newDate = new Date();
    let data = newDate;
    return new Date(data.setDate(data.getDate() + 1)).toISOString();
  }

  static getCurrentComponentBorder(currentComponentIndex, index, isEditing) {
    return {
      border: "solid",
      borderWidth: 1,
      borderStyle: "dashed",
      borderColor:
        currentComponentIndex === index && isEditing ? "blue" : "transparent"
    };
  }

  static getComponentDefaultData(type) {
    switch (type) {
      case PAGE_ELEMENT.TEXT:
        return this.getTextDefaultData(type);
      case PAGE_ELEMENT.BUTTON:
        return this.getButtonDefaultData(type);
      case PAGE_ELEMENT.IMAGE:
        return this.getImageDefaultData(type);
      case PAGE_ELEMENT.VIDEO:
        return this.getVideoDefaultData(type);
      case PAGE_ELEMENT.SOCIAL_BUTTONS:
        return this.getSocialDefaultData(type);
      case PAGE_ELEMENT.COLLECTION:
        return this.getCollectionDefaultData(type);
      case PAGE_ELEMENT.FORM:
        return this.getFormDefaultData(type);
      case PAGE_ELEMENT.ORDER_FORM:
        return this.getOrderFormDefaultData(type);
      case PAGE_ELEMENT.LOGO:
        return this.getLogoDefaultData(type);
      case PAGE_ELEMENT.IMAGE_TEXT:
        return this.getImageTextDefaultData(type);
      case PAGE_ELEMENT.CUSTOM_HTML:
        return this.getCustomHtmlDefaultData(type);
      case PAGE_ELEMENT.IMAGE_GALLERY:
        return this.getImageGalleryDefaultData(type);
      case PAGE_ELEMENT.COLUMN_SECTION:
        return this.getColumnSectionDefaultData(type);
      case PAGE_ELEMENT.COUNTDOWN_TIMER:
        return this.getCountDownTimerDefaultData(type);
      case PAGE_ELEMENT.V_STACK:
        return this.getVStackDefaultData(type);
      default:
        return this.getTextDefaultData(type);
    }
  }
  static getTextDefaultData(type, data = null) {
    return {
      type: type,
      value:
        data !== null && data.value !== undefined
          ? data.value
          : "Your custom text",
      style: {
        width: "100%",
        boxSizing: "border-box",
        fontSize:
          data !== null && data.fontSize !== undefined ? data.fontSize : 16,
        color:
          data !== null && data.color !== undefined ? data.color : "#000000",
        textAlign:
          data !== null && data.textAlign !== undefined
            ? data.textAlign
            : "center",
        fontWeight:
          data !== null && data.fontWeight !== undefined
            ? data.fontWeight
            : "normal",
        paddingTop:
          data !== null && data.paddingTop !== undefined ? data.paddingTop : 6,
        paddingBottom:
          data !== null && data.paddingBottom !== undefined
            ? data.paddingBottom
            : 6,
        paddingLeft: 6,
        paddingRight: 6,
        marginTop: 0,
        marginBottom: 0,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 0,
        backgroundColor: "transparent",
        overflow: "hidden",
        alignSelf: "center",
        whiteSpace: "pre-wrap"
      }
    };
  }

  static getButtonDefaultData(type, data = null) {
    return {
      type: type,
      value: "Button Title",
      url: "",
      visibility:
        data !== null && data.visibility !== undefined ? data.visibility : true,
      style: {
        width:
          data !== null && data.width !== undefined
            ? data.width
            : "fit-content",
        boxSizing: "border-box",
        fontSize:
          data !== null && data.fontSize !== undefined ? data.fontSize : 16,
        color: "#ffffff",
        textAlign:
          data !== null && data.textAlign !== undefined
            ? data.textAlign
            : "center",
        fontWeight: "bold",
        paddingTop:
          data !== null && data.paddingTop !== undefined ? data.paddingTop : 10,
        paddingBottom:
          data !== null && data.paddingBottom !== undefined
            ? data.paddingBottom
            : 10,
        paddingLeft:
          data !== null && data.paddingLeft !== undefined
            ? data.paddingLeft
            : 15,
        paddingRight:
          data !== null && data.paddingRight !== undefined
            ? data.paddingRight
            : 15,
        marginTop:
          data !== null && data.marginTop !== undefined ? data.marginTop : 10,
        marginBottom:
          data !== null && data.marginBottom !== undefined
            ? data.marginBottom
            : 10,
        marginLeft:
          data !== null && data.marginLeft !== undefined
            ? data.marginLeft
            : "auto",
        marginRight:
          data !== null && data.marginRight !== undefined
            ? data.marginRight
            : "auto",
        borderRadius: 4,
        backgroundColor: "#323232",
        alignSelf:
          data !== null && data.alignSelf !== undefined
            ? data.alignSelf
            : "center",
        overflow: "hidden",
        cursor: "pointer",
        outline: "none",
        border: "none",
        textDecoration: "none"
      }
    };
  }

  static getImageDefaultData(type, data = null) {
    return {
      type: type,
      value:
        data !== null && data.value !== undefined
          ? data.value
          : "https://i.ibb.co/RD02XgX/Screenshot-2021-05-28-at-7-09-16-PM.png",
      style: {
        width: "100%",
        // height: "min-intrinsic",
        boxSizing: "border-box",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop:
          data !== null && data.marginTop !== undefined ? data.marginTop : 10,
        marginBottom:
          data !== null && data.marginBottom !== undefined
            ? data.marginBottom
            : 10,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 0,
        backgroundColor: "transparent",
        alignSelf: "center",
        overflow: "hidden",
        textAlign: "center"
      }
    };
  }

  static getVideoDefaultData(type) {
    return {
      type: type,
      value: {
        videoURL: "",
        videoThumbnail: "",
        autoplayEnabled: false
      },
      style: {
        width: "100%",
        boxSizing: "border-box",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 0,
        backgroundColor: "transparent",
        alignSelf: "center",
        overflow: "hidden",
        textAlign: "center"
      }
    };
  }

  static getSocialDefaultData(type) {
    return {
      type: type,
      value: [
        {
          isRequired: true,
          title: "Facebook",
          url: "",
          icon: "https://i.ibb.co/FX4mz7J/ic-facebook.png"
        },
        {
          isRequired: true,
          title: "Instagram",
          url: "",
          icon: "https://i.ibb.co/VNvh8BL/ic-instagram.png"
        },
        {
          isRequired: true,
          title: "Twitter",
          url: "",
          icon: "https://i.ibb.co/qR8Q8PV/ic-twitter.png"
        },
        {
          isRequired: false,
          title: "LinkedIn",
          url: "",
          icon: "https://i.ibb.co/BzLpvD2/linkedin-2.png"
        },
        {
          isRequired: false,
          title: "Youtube",
          url: "",
          icon: "https://i.ibb.co/tJ6rf4x/youtube.png"
        },
        {
          isRequired: false,
          title: "WhatsApp",
          url: "",
          icon: "https://i.ibb.co/nn931VS/whatsapp-2.png"
        }
      ],
      style: {
        width: "fit-content",
        boxSizing: "border-box",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 0,
        backgroundColor: "transparent",
        alignSelf: "center",
        overflow: "hidden",
        iconSize: 26,
        cellSpacing: 10
      }
    };
  }

  static getCollectionDefaultData(type) {
    return {
      type: type,
      value: [
        {
          title: this.getTextDefaultData(PAGE_ELEMENT.TEXT, {
            value: "Title",
            fontSize: 14,
            color: "#000000",
            fontWeight: "bold",
            paddingTop: 3,
            paddingBottom: 3
          }),
          description: this.getTextDefaultData(PAGE_ELEMENT.TEXT, {
            value: "Description",
            fontSize: 14,
            color: "#837c7c",
            paddingTop: 3,
            paddingBottom: 3
          }),
          icon: this.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
            value: "https://i.ibb.co/h93Ztf7/image-8.png",
            marginBottom: 0
          }),
          button: this.getButtonDefaultData(PAGE_ELEMENT.BUTTON, {
            fontSize: 12,
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 7,
            paddingRight: 7,
            marginTop: 2
          })
        },
        {
          title: this.getTextDefaultData(PAGE_ELEMENT.TEXT, {
            value: "Title",
            fontSize: 14,
            color: "#000000",
            fontWeight: "bold",
            paddingTop: 3,
            paddingBottom: 3
          }),
          description: this.getTextDefaultData(PAGE_ELEMENT.TEXT, {
            value: "Description",
            fontSize: 14,
            color: "#837c7c",
            paddingTop: 3,
            paddingBottom: 3
          }),
          icon: this.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
            value: "https://i.ibb.co/h93Ztf7/image-8.png",
            marginBottom: 0
          }),
          button: this.getButtonDefaultData(PAGE_ELEMENT.BUTTON, {
            fontSize: 12,
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 7,
            paddingRight: 7,
            marginTop: 2
          })
        },
        {
          title: this.getTextDefaultData(PAGE_ELEMENT.TEXT, {
            value: "Title",
            fontSize: 14,
            color: "#000000",
            fontWeight: "bold",
            paddingTop: 3,
            paddingBottom: 3
          }),
          description: this.getTextDefaultData(PAGE_ELEMENT.TEXT, {
            value: "Description",
            fontSize: 14,
            color: "#837c7c",
            paddingTop: 3,
            paddingBottom: 3
          }),
          icon: this.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
            value: "https://i.ibb.co/h93Ztf7/image-8.png",
            marginBottom: 0
          }),
          button: this.getButtonDefaultData(PAGE_ELEMENT.BUTTON, {
            fontSize: 12,
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 7,
            paddingRight: 7,
            marginTop: 2
          })
        }
      ],
      style: {
        width: "100%",
        boxSizing: "border-box",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 0,
        backgroundColor: "transparent",
        alignSelf: "center",
        overflow: "hidden",
        iconSize: 110,
        cellSpacing: 12,
        iconRadius: 0,
        gridSize: 25
      }
    };
  }

  static getLogoDefaultData(type, data = null) {
    return {
      type: type,
      value:
        data !== null && data.value !== undefined
          ? data.value
          : "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1622616405313_deskera-logo-512.png",
      style: {
        iconSize: 70,
        iconRadius: 0,
        width: "100%",
        boxSizing: "border-box",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop:
          data !== null && data.marginTop !== undefined ? data.marginTop : 10,
        marginBottom:
          data !== null && data.marginBottom !== undefined
            ? data.marginBottom
            : 10,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 0,
        backgroundColor: "transparent",
        alignSelf: "center",
        overflow: "hidden",
        textAlign: "center"
      }
    };
  }

  // static getIconDefaultData(type) {
  //   return {
  //     type: type,
  //     value: [
  //       {
  //         title: this.getTextDefaultData(PAGE_ELEMENT.TEXT, { value: "", fontSize: 14, color: "#000000", fontWeight: "bold" }),
  //         icon: this.getImageDefaultData(PAGE_ELEMENT.IMAGE, { value: "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1622616405313_deskera-logo-512.png" }),
  //       },
  //     ],
  //     style: {
  //       width: "100%",
  //       boxSizing: "border-box",
  //       paddingTop: 0,
  //       paddingBottom: 0,
  //       paddingLeft: 0,
  //       paddingRight: 0,
  //       marginTop: 4,
  //       marginBottom: 4,
  //       marginLeft: "auto",
  //       marginRight: "auto",
  //       borderRadius: 0,
  //       backgroundColor: "transparent",
  //       alignSelf: "center",
  //       overflow: "hidden",
  //       iconSize: 60,
  //       cellSpacing: 6,
  //       iconRadius: 0,
  //       gridSize: 25,
  //     },
  //   };
  // }

  static getImageTextDefaultData(type) {
    return {
      type: type,
      value: [
        {
          title: this.getTextDefaultData(PAGE_ELEMENT.TEXT, {
            value: "Header Text",
            fontSize: 18,
            color: "#000000",
            textAlign: "left",
            fontWeight: "bold"
          }),
          description: this.getTextDefaultData(PAGE_ELEMENT.TEXT, {
            value:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br/><br/>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            fontSize: 14,
            color: "#837c7c",
            textAlign: "left"
          }),
          image: this.getImageDefaultData(PAGE_ELEMENT.IMAGE),
          button: this.getButtonDefaultData(PAGE_ELEMENT.BUTTON, {
            fontSize: 14,
            paddingTop: 7,
            paddingBottom: 7,
            paddingLeft: 8,
            paddingRight: 8,
            marginRight: "auto",
            marginLeft: 0,
            marginTop: 8,
            marginBottom: 8,
            width: "auto",
            textAlign: "left",
            alignSelf: "flex-start"
          })
        }
      ],
      style: {
        width: "100%",
        boxSizing: "border-box",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 0,
        backgroundColor: "transparent",
        // alignSelf: "center",
        overflow: "hidden",
        imageSize: 50,
        cellSpacing: 2,
        imageRadius: 0,
        imageAlign: "left",
        // textAlign: "left",
        containerSpacing: 48
      }
    };
  }

  static getFormDefaultData(type) {
    let fieldsData = FormService.getContactData(true);
    let segmentsData = ContactService.getSegments();
    return {
      type: type,
      value: fieldsData,
      segmentId: segmentsData.length > 0 ? segmentsData[0].id : "",
      style: {
        width: "80%",
        boxSizing: "border-box",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 0,
        backgroundColor: "transparent",
        alignSelf: "center",
        overflow: "hidden",
        fieldSpacing: 10,
        labelSize: 12,
        labelColor: "#000000",
        fieldRadius: 4,
        fieldBorder: "#EBEBEB",
        fieldBackground: "#F8F7FA"
      },
      button: {
        value: "Submit",
        style: {
          buttonColor: "#ffffff",
          buttonSize: 18,
          buttonBackground: "#24bc4a"
        }
      },
      thankYouMsg: "Thank you for submitting form!",
      redirectURL: ""
    };
  }

  static getOrderFormDefaultData(type) {
    return {
      type: type,
      cartData: {
        productDetails: [],
        subTotalAmount: 0,
        totalAmount: 0,
        discount: 0,
        shipping: 0,
        tax: 0,
        totalQty: 0,
        currency: "USD"
      },
      formSections: [
        {
          sectionTitle: "Contact Details",
          formFields: [
            {
              name: "Name",
              columnCode: "name",
              type: "text",
              key: getRandomAlphaNumericString(6),
              required: true
            },
            {
              name: "Email",
              columnCode: "email",
              type: "email",
              key: getRandomAlphaNumericString(6),
              required: true
            },
            {
              name: "Phone",
              columnCode: "phone",
              type: "text",
              key: getRandomAlphaNumericString(6),
              required: true
            }
          ]
        },
        {
          sectionTitle: "Shipping Details",
          formFields: [
            {
              name: "Street Address",
              columnCode: "street_address",
              type: "text",
              key: getRandomAlphaNumericString(6),
              required: true
            },
            {
              name: "City",
              columnCode: "city",
              type: "text",
              key: getRandomAlphaNumericString(6),
              required: false,
              fieldWidth: "30%"
            },
            {
              name: "State",
              columnCode: "state",
              type: "text",
              key: getRandomAlphaNumericString(6),
              required: true,
              fieldWidth: "30%",
              marginLeft: "3%"
            },
            {
              name: "Country",
              columnCode: "country",
              type: "select",
              key: getRandomAlphaNumericString(6),
              required: true,
              defaultValue: "SG",
              options: STRIPE_COUNTRIES.map((countryData) => ({
                name: countryData.country,
                id: countryData.code
              })),
              fieldWidth: "34%",
              marginLeft: "3%"
            },
            {
              name: "Landmark",
              columnCode: "landmark",
              type: "text",
              key: getRandomAlphaNumericString(6),
              required: false,
              fieldWidth: "50%"
            },
            {
              name: "ZIP",
              columnCode: "postal_code",
              type: "text",
              key: getRandomAlphaNumericString(6),
              required: true,
              fieldWidth: "47%",
              marginLeft: "3%"
            }
          ]
        }
      ],
      style: {
        width: "100%",
        boxSizing: "border-box",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 0,
        backgroundColor: "transparent",
        alignSelf: "center",
        overflow: "hidden",
        fieldSpacing: 8,
        labelSize: 12,
        labelColor: "#000000",
        fieldRadius: 4,
        fieldBorder: "#EBEBEB",
        fieldBackground: "#F8F7FA",
        fontFamily: ""
      },
      button: {
        value: "Proceed to Pay",
        style: {
          buttonColor: "#fff",
          buttonSize: 16,
          buttonBackground: "#1c73e8"
        }
      },
      thankYouMsg:
        "We have received your order. We will contact you shortly. Thank you!",
      redirectURL: ""
    };
  }

  static getCustomHtmlDefaultData(type) {
    return {
      type: type,
      value: `<h1 style="font-size:24px;margin:0 0 20px 0;">Lorem ipsum dolor sit amet</h1><p style="margin:auto;font-size:16px;line-height:24px;width:70%;text-align: center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tempus adipiscing felis, sit amet blandit ipsum <span style="color:red; font-weight: bold">volutpat sed</span>. Morbi porttitor, eget accumsan et dictum, nisi libero ultricies ipsum, posuere neque at erat.</p><p style="margin:0;font-size:16px;line-height:24px;"></p>`,
      style: {
        width: "100%",
        boxSizing: "border-box",
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 0,
        backgroundColor: "#f5f5f5",
        alignSelf: "center",
        overflow: "hidden",
        textAlign: "center"
      }
    };
  }
  static getColumnSectionDefaultData(type) {
    return {
      type: type,
      value: [
        {
          type: null,
          value: [],
          style: {}
        },
        {
          type: null,
          value: [],
          style: {}
        },
        {
          type: null,
          value: [],
          style: {}
        }
      ],
      style: {
        width: "100%",
        boxSizing: "border-box",
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 0,
        backgroundColor: "transparent",
        alignSelf: "center",
        overflow: "visible",
        textAlign: "center"
      }
    };
  }

  static getCountDownTimerDefaultData(type) {
    return {
      type: type,
      value: {
        title: "Coming Soon",
        timer: this.getDateForCountTime()
      },
      style: {
        width: "fit-content",
        boxSizing: "border-box",
        fontSize: 16,
        color: "#000000",
        textAlign: "center",
        fontWeight: "bold",
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 15,
        marginBottom: 15,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 4,
        backgroundColor: "transparent",
        alignSelf: "center",
        overflow: "hidden",
        justifyContent: "center",
        timerSize: 23,
        timerColor: "#ffffff",
        timerBgColor: "#000000",
        timerBorderRadius: 4,
        timerBorderRadiusColor: "#000000"
      }
    };
  }
  static getImageGalleryDefaultData(type) {
    return {
      type: type,
      value: [
        {
          image:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/61053/1628069056553_LandingPage-4-Image1.png"
        },
        {
          image:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/61053/1628069063748_LandingPage-4-Image2.png"
        },
        {
          image:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/61053/1628069076884_LandingPage-5-Image1.png"
        }
      ],
      style: {
        width: "100%",
        boxSizing: "border-box",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 8,
        backgroundColor: "transparent",
        thumbnailHeight: 40,
        sildeTime: 2,
        alignSelf: "center"
      }
    };
  }

  static getVStackDefaultData(type) {
    return {
      type: type,
      value: [],
      style: {
        width: "100%",
        boxSizing: "border-box",
        paddingTop: 24,
        paddingBottom: 24,
        paddingLeft: 0,
        paddingRight: 0,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 0,
        backgroundColor: "transparent",
        alignSelf: "center",
        overflow: "visible",
        textAlign: "center"
      }
    };
  }

  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////

  static getEncodedDataToSave(pageData, pageType) {
    const htmlGenerator = new HtmlGenerator();

    if (pageType === PAGE_TYPE.CAMPAIGN) {
      htmlGenerator.campaignAddress = pageData.json.campaignAddress;
    }

    let data = { ...pageData };
    data.html = Utility.encodeHTML(
      htmlGenerator.getHTML(
        data.json.designElements,
        data.json.scripts,
        data.json.metaData,
        data.json.hash,
        pageType,
        null,
        false,
        pageType
      )
    );
    data.json = Utility.encodeJSON(data.json);
    return data;
  }
}
