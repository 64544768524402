export const commonCustomEvent = {
  on: (ev: string, cb: (data) => void) => {
    document.addEventListener(ev, (data: any) => cb(data.detail));
  },
  dispatch: (ev: string, data: any) => {
    document.dispatchEvent(new CustomEvent(ev, { detail: data }));
  },
  remove: (ev: string, cb: (data) => void) => {
    document.removeEventListener(ev, cb);
  }
};
export const newThreadCustomEvent = {
  on: (ev: string, cb: (data) => void) => {
    document.addEventListener(ev, (data: any) => cb(data.detail));
  },
  dispatch: (ev: string, data: any) => {
    document.dispatchEvent(new CustomEvent(ev, { detail: data }));
  },
  remove: (ev: string, cb: (data) => void) => {
    document.removeEventListener(ev, cb);
  }
};
export const pageDesignerCustomEvent = {
  on: (ev: string, cb: (data) => void) => {
    document.addEventListener(ev, cb);
  },
  dispatch: (ev: string, data: any) => {
    document.dispatchEvent(new CustomEvent(ev, { detail: data }));
  },
  remove: (ev: string, cb: (data) => void) => {
    document.removeEventListener(ev, cb);
  }
};

export const COMMON_EVENTS = {
  NEW_MSG: "newMsg",
  REC_PERMISSION_CHANGE: "recPermissionChange",
  THREAD_CLOSED: "threadClosed",
  NEW_THREAD: "newThread",
  NEW_TICKET: "newTicket",
  SUBSCRIPTION_CHECK: "onAuthorizationCheckStateChanged",
  NAVIGATION_TRIGGERED: "onNavigationTriggered",
  ERROR_OCCURRED: "onErrorOccurred",
  CONTACT_CHANGE: "onContactChange",
  VIEW_RELEASE_NOTE: "viewReleaseNote"
};

export const PAGE_DESIGNER_EVENTS = {
  PLACEHOLDER_SELECTED: "placeholderSelected",
  NEED_CAMPAIGN_ADDRESS: `needCampaignAddress`
};
