import ApiConstants from "../constants/ApiConstants";
import http from "../http";

export interface NotificationAPIConfig {
  pageSize?: number;
  pageNo?: number;
}

const defaultConfig: NotificationAPIConfig = {
  pageSize: 20,
  pageNo: 0
};

class NotificationService {
  static params = defaultConfig;

  static async getNotifications(params) {
    return http
      .get(ApiConstants.URL.NOTIFICATION.GET, {
        params: { ...NotificationService.params, pageSize: params?.pageSize }
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateNotification(payload: any) {
    return http
      .patch(ApiConstants.URL.NOTIFICATION.GET, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getNotificationSetting() {
    return http
      .get(ApiConstants.URL.DASHBOARD.GET_PREFERENCE)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static updateNotificationSetting(payload: any) {
    return http
      .patch(ApiConstants.URL.DASHBOARD.GET_PREFERENCE, payload)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default NotificationService;
