import { FILTER_OPERATORS } from "../../constants/Enum";

export const CRM_CONNECTION_APP_CODE = "deskera_crm3";

export enum AUTOMATION_ELEMENT {
  TRIGGER = "Trigger",
  ACTION = "Action"
}

export enum AUTOMATION_TRIGGERS {
  CAMPAIGN_PUBLISHED = "CAMPAIGN_PUBLISHED",
  FORM_SUBMITTED = "FORM_SUBMITTED",
  CONTACT_ADDED = "CONTACT_ADDED",
  VALUE_CHANGED = "CONTACT_UPDATED",
  DEAL_VALUE_CHANGED = "DEAL_UPDATED",
  ACTIVITY_VALUE_CHANGED = "ACTIVITY_UPDATED",
  FACEBOOK_LEAD_ADDED = "FACEBOOK_LEAD_ADDED",
  DEAL_STAGE_CHANGED = "DEAL_STAGE_CHANGED",
  QUOTE_CREATED = "BOOKS_CREATE_QUOTATION",
  DATE_ALERT = "CRM_DATE_ALERT_TRIGGER",
  TICKET_CREATED = "TICKET_CREATED",
  TICKET_VALUE_CHANGED = "TICKET_UPDATED"
}

export enum AUTOMATION_ACTIONS {
  SEND_EMAIL = "SEND_EMAIL",
  CREATE_CONTACT = "CREATE_CONTACT",
  UPDATE_CONTACT = "UPDATE_CONTACT",
  CREATE_DEAL = "CREATE_DEAL",
  UPDATE_DEAL = "UPDATE_DEAL",
  SPLIT_WORKFLOW = "SPLIT",
  WAIT_FOR_TIME = "WAIT_FOR_TIME",
  WAIT_TILL = "WAIT_FOR_TIME_TILL",
  SEND_SMS_MESSAGE = "SEND_SMS_MESSAGE",
  SEND_WHATSAPP_MESSAGE = "SEND_WHATSAPP_MESSAGE",
  ASSIGN_OWNER = "ASSIGN_OWNER",
  END_WORKFLOW = "END_WORKFLOW",
  CREATE_FACEBOOK_LEAD_ENTITY = "CREATE_FACEBOOK_LEAD_ENTITY",
  CRM_APPROVAL = "BOOKS_PLUS_APPROVAL",
  DEAL_UPDATE_APPROVAL = "DEAL_UPDATE_APPROVAL",
  UPDATE_TICKET = "UPDATE_TICKET"
}

export const CONDITIONAL_FIELD_MAPPER_TYPE = [
  AUTOMATION_TRIGGERS.CONTACT_ADDED,
  AUTOMATION_TRIGGERS.VALUE_CHANGED,
  AUTOMATION_TRIGGERS.DEAL_VALUE_CHANGED,
  AUTOMATION_TRIGGERS.ACTIVITY_VALUE_CHANGED,
  AUTOMATION_TRIGGERS.TICKET_CREATED,
  AUTOMATION_TRIGGERS.TICKET_VALUE_CHANGED
];

export const WAIT_INTERVALS = ["day", "hour", "minute"];

export enum SPLIT_ACTION_TYPE {
  CONTACT_CHECK = "CONTACT_CHECK",
  CONTACT_ACTIVITY_CHECK = "CONTACT_HAS_ACTIVITY_CHECK",
  // CONTACT_DEAL_CHECK= "CONTACT_HAS_DEAL_CHECK",
  DEAL_CHECK = "DEAL_CHECK",
  DEAL_ACTIVITY_CHECK = "DEAL_HAS_ACTIVITY_CHECK",
  // DEAL_CONTACT_CHECK= "DEAL_HAS_CONTACT_CHECK",
  ACTIVITY_CHECK = "ACTIVITY_CHECK",
  ACTIVITY_CONTACT_CHECK = "ACTIVITY_HAS_CONTACT_CHECK",
  ACTIVITY_DEAL_CHECK = "ACTIVITY_HAS_DEAL_CHECK",
  EMAIL_OPENED = "EMAIL_OPENED",
  EMAIL_LINK_CLICKED = "EMAIL_LINK_CLICKED",
  BOOKS_QUOTATION_CHECK = "BOOKS_QUOTATION_CHECK",
  TICKET_CHECK = "TICKET_CHECK",
  TICKET_CONTACT_CHECK = "TICKET_HAS_CONTACT_CHECK",
  TICKET_ACCOUNT_CHECK = "TICKET_HAS_ACCOUNT_CHECK"
}

export const SPLIT_ACTION_TYPE_CONTEXT = {
  [SPLIT_ACTION_TYPE.CONTACT_CHECK]: [
    AUTOMATION_ACTIONS.CREATE_CONTACT,
    AUTOMATION_ACTIONS.UPDATE_CONTACT,
    AUTOMATION_TRIGGERS.FORM_SUBMITTED,
    AUTOMATION_TRIGGERS.CONTACT_ADDED,
    AUTOMATION_TRIGGERS.VALUE_CHANGED
  ],
  [SPLIT_ACTION_TYPE.CONTACT_ACTIVITY_CHECK]: [
    AUTOMATION_ACTIONS.CREATE_CONTACT,
    AUTOMATION_ACTIONS.UPDATE_CONTACT,
    AUTOMATION_TRIGGERS.FORM_SUBMITTED,
    AUTOMATION_TRIGGERS.CONTACT_ADDED,
    AUTOMATION_TRIGGERS.VALUE_CHANGED
  ],
  // [SPLIT_ACTION_TYPE.CONTACT_DEAL_CHECK]: [
  //   AUTOMATION_ACTIONS.CREATE_CONTACT,
  //   AUTOMATION_ACTIONS.UPDATE_CONTACT,
  //   AUTOMATION_TRIGGERS.FORM_SUBMITTED,
  //   AUTOMATION_TRIGGERS.CONTACT_ADDED,
  //   AUTOMATION_TRIGGERS.VALUE_CHANGED,
  // ],
  [SPLIT_ACTION_TYPE.DEAL_CHECK]: [
    AUTOMATION_ACTIONS.CREATE_DEAL,
    AUTOMATION_ACTIONS.UPDATE_DEAL,
    AUTOMATION_TRIGGERS.DEAL_STAGE_CHANGED,
    AUTOMATION_TRIGGERS.DEAL_VALUE_CHANGED
  ],
  // [SPLIT_ACTION_TYPE.DEAL_CONTACT_CHECK]: [
  //   AUTOMATION_ACTIONS.CREATE_DEAL,
  //   AUTOMATION_ACTIONS.UPDATE_DEAL,
  //   AUTOMATION_TRIGGERS.DEAL_STAGE_CHANGED,
  //   AUTOMATION_TRIGGERS.DEAL_VALUE_CHANGED
  // ],
  [SPLIT_ACTION_TYPE.DEAL_ACTIVITY_CHECK]: [
    AUTOMATION_ACTIONS.CREATE_DEAL,
    AUTOMATION_ACTIONS.UPDATE_DEAL,
    AUTOMATION_TRIGGERS.DEAL_STAGE_CHANGED,
    AUTOMATION_TRIGGERS.DEAL_VALUE_CHANGED
  ],
  [SPLIT_ACTION_TYPE.ACTIVITY_CHECK]: [
    AUTOMATION_TRIGGERS.ACTIVITY_VALUE_CHANGED
  ],
  [SPLIT_ACTION_TYPE.ACTIVITY_CONTACT_CHECK]: [
    AUTOMATION_TRIGGERS.ACTIVITY_VALUE_CHANGED
  ],
  [SPLIT_ACTION_TYPE.ACTIVITY_DEAL_CHECK]: [
    AUTOMATION_TRIGGERS.ACTIVITY_VALUE_CHANGED
  ],
  [SPLIT_ACTION_TYPE.EMAIL_OPENED]: [AUTOMATION_ACTIONS.SEND_EMAIL],
  [SPLIT_ACTION_TYPE.EMAIL_LINK_CLICKED]: [AUTOMATION_ACTIONS.SEND_EMAIL],
  [SPLIT_ACTION_TYPE.BOOKS_QUOTATION_CHECK]: [
    AUTOMATION_TRIGGERS.QUOTE_CREATED
  ],
  [SPLIT_ACTION_TYPE.TICKET_CHECK]: [
    AUTOMATION_TRIGGERS.TICKET_CREATED,
    AUTOMATION_TRIGGERS.TICKET_VALUE_CHANGED,
    AUTOMATION_ACTIONS.UPDATE_TICKET
  ],
  [SPLIT_ACTION_TYPE.TICKET_CONTACT_CHECK]: [
    AUTOMATION_TRIGGERS.TICKET_CREATED,
    AUTOMATION_TRIGGERS.TICKET_VALUE_CHANGED,
    AUTOMATION_ACTIONS.UPDATE_TICKET
  ],
  [SPLIT_ACTION_TYPE.TICKET_ACCOUNT_CHECK]: [
    AUTOMATION_TRIGGERS.TICKET_CREATED,
    AUTOMATION_TRIGGERS.TICKET_VALUE_CHANGED,
    AUTOMATION_ACTIONS.UPDATE_TICKET
  ]
};

export const EMAIL_SPLIT_CONFIG_COLUMNS = [
  {
    id: "viewCount_1",
    key: "viewCount",
    value: 1,
    name: "Email has been opened",
    splitConfigType: SPLIT_ACTION_TYPE.EMAIL_OPENED
  },
  {
    id: "viewCount_0",
    key: "viewCount",
    value: 0,
    name: "Email has not been opened",
    splitConfigType: SPLIT_ACTION_TYPE.EMAIL_OPENED
  },
  {
    id: "clickCount_1",
    key: "clickCount",
    value: 1,
    name: "Email link has been clicked",
    splitConfigType: SPLIT_ACTION_TYPE.EMAIL_LINK_CLICKED
  },
  {
    id: "clickCount_0",
    key: "clickCount",
    value: 0,
    name: "Email link has not been clicked",
    splitConfigType: SPLIT_ACTION_TYPE.EMAIL_LINK_CLICKED
  }
];

export const LINKED_MODULE_SPLIT_CONFIG_TYPES = [
  SPLIT_ACTION_TYPE.ACTIVITY_CONTACT_CHECK,
  SPLIT_ACTION_TYPE.ACTIVITY_DEAL_CHECK,
  SPLIT_ACTION_TYPE.CONTACT_ACTIVITY_CHECK,
  SPLIT_ACTION_TYPE.DEAL_ACTIVITY_CHECK,
  SPLIT_ACTION_TYPE.TICKET_ACCOUNT_CHECK,
  SPLIT_ACTION_TYPE.TICKET_CONTACT_CHECK
];
export const LINKED_MODULE_SPLIT_CONFIG_COLUMN = Object.freeze({
  id: "exists",
  key: "exists",
  name: "Exists",
  type: "select",
  options: [
    {
      id: true,
      name: "Yes"
    },
    {
      id: false,
      name: "No"
    }
  ]
});

export const OPERATOR_LIST = {
  EQUAL: {
    displayName: "Equals to (==)",
    symbol: "==",
    token: FILTER_OPERATORS.EQUAL
  },
  CONTAINS: {
    displayName: "Contains (=>)",
    symbol: "=>",
    token: FILTER_OPERATORS.CONTAINS
  },
  GREATER: {
    displayName: "Greater than (=>)",
    symbol: "=>",
    token: FILTER_OPERATORS.GREATER_THAN
  },
  GREATER_EQUAL: {
    displayName: "Greater than or Equal (=>)",
    symbol: "=>",
    token: FILTER_OPERATORS.GREATER_EQUAL
  },
  LESSER: {
    displayName: "Less than (<=)",
    symbol: "<=",
    token: FILTER_OPERATORS.LESS_THAN
  }
};

export const STATUS_OPTIONS = {
  ACTIVE: [1],
  IN_ACTIVE: [2]
};
export enum OBJECT_TYPES {
  DEAL = "DEAL",
  CONTACT = "CONTACT",
  ACTIVITY = "ACTIVITY",
  TICKET = "TICKET"
}

export const UNSUPPORTED_SPLIT_CONTEXT_ACTIONS = [
  AUTOMATION_ACTIONS.WAIT_FOR_TIME,
  AUTOMATION_ACTIONS.SPLIT_WORKFLOW,
  AUTOMATION_ACTIONS.WAIT_TILL,
  AUTOMATION_ACTIONS.SEND_SMS_MESSAGE,
  AUTOMATION_ACTIONS.SEND_WHATSAPP_MESSAGE,
  AUTOMATION_ACTIONS.ASSIGN_OWNER,
  AUTOMATION_TRIGGERS.CAMPAIGN_PUBLISHED,
  AUTOMATION_TRIGGERS.QUOTE_CREATED,
  AUTOMATION_ACTIONS.CRM_APPROVAL,
  AUTOMATION_ACTIONS.DEAL_UPDATE_APPROVAL,
  AUTOMATION_TRIGGERS.DATE_ALERT
];

export const SUPPORTED_DEAL_STAGE_CHANGED_ACTIONS = [
  AUTOMATION_ACTIONS.SEND_EMAIL,
  AUTOMATION_ACTIONS.UPDATE_DEAL,
  AUTOMATION_ACTIONS.ASSIGN_OWNER,
  AUTOMATION_ACTIONS.WAIT_FOR_TIME,
  AUTOMATION_ACTIONS.SPLIT_WORKFLOW,
  AUTOMATION_ACTIONS.SEND_WHATSAPP_MESSAGE,
  AUTOMATION_ACTIONS.END_WORKFLOW
];
export const SUPPORTED_DEAL_STAGE_CHANGED_WITH_APPROVAL_ACTIONS = [
  AUTOMATION_ACTIONS.DEAL_UPDATE_APPROVAL,
  AUTOMATION_ACTIONS.END_WORKFLOW
];
export const SUPPORTED_QUOTE_CREATE_ACTIONS = [
  AUTOMATION_ACTIONS.CRM_APPROVAL,
  AUTOMATION_ACTIONS.END_WORKFLOW
];

export const SUPPORTED_DATE_ALERT_ACTIONS = [
  AUTOMATION_ACTIONS.ASSIGN_OWNER,
  AUTOMATION_ACTIONS.UPDATE_CONTACT,
  AUTOMATION_ACTIONS.UPDATE_DEAL,
  AUTOMATION_ACTIONS.SEND_EMAIL,
  AUTOMATION_ACTIONS.WAIT_FOR_TIME,
  AUTOMATION_ACTIONS.SPLIT_WORKFLOW,
  AUTOMATION_ACTIONS.END_WORKFLOW
];

export const SUPPORTED_TICKET_ACTIONS = [
  AUTOMATION_ACTIONS.UPDATE_TICKET,
  AUTOMATION_ACTIONS.SEND_EMAIL,
  AUTOMATION_ACTIONS.WAIT_FOR_TIME,
  AUTOMATION_ACTIONS.SPLIT_WORKFLOW,
  AUTOMATION_ACTIONS.WAIT_TILL,
  AUTOMATION_ACTIONS.END_WORKFLOW
];

/* Where Child & parent node is to be considered as one */
export const DIRECT_CHILD_ACTION_TYPES = [
  AUTOMATION_ACTIONS.SPLIT_WORKFLOW,
  AUTOMATION_ACTIONS.CREATE_FACEBOOK_LEAD_ENTITY,
  AUTOMATION_ACTIONS.CRM_APPROVAL,
  AUTOMATION_ACTIONS.DEAL_UPDATE_APPROVAL
];

/* Intermediate Steps allowed between Directly linked parent/child */
export const INTERMEDIATE_CHILD_ACTION_TYPES = [
  AUTOMATION_ACTIONS.WAIT_FOR_TIME,
  AUTOMATION_ACTIONS.SPLIT_WORKFLOW
];

export const SPLIT_TYPE_ACTIONS: AUTOMATION_ACTIONS[] = [
  AUTOMATION_ACTIONS.SPLIT_WORKFLOW,
  AUTOMATION_ACTIONS.WAIT_TILL
];

export enum DATE_ALERT_CRITERIA {
  BEFORE = "before",
  ON = "on",
  AFTER = "after"
}

/* layout configs for automation elements */
export const SPLIT_GUTTER = 30;
export const STEP_CARD_WIDTH = 250;
export const CONNECTOR_DIMENSION = { width: 3, height: 30 };

export const MAX_VISIBLE_STEP_VALUE_CHARACTERS = 40;

export const EMAIL_COMPOSER_DIMENSIONS = {
  width: 700,
  height: 635,
  fieldHeight: 45,
  editorHeight: 300
};

export const APP_NAME_FACEBOOK = "FACEBOOK";

/* Note: As per ZEN-8944.
Keeping, email recipients are same for Deal & Contact object types,
need to handle case when adding send_mail action with activity object types.  */
export const EMAIL_RECIPIENTS = {
  ORG_USER: "org_users",
  OWNER: "owner_id",
  CONTACT: "contact_id",
  DEAL_CONTACT: "contact_id",
  ACTIVITY_CONTACT: "contact_ids",
  TICKET_REQUESTOR: "requestor",
  TICKET_CONTACT: "crm3_contact_id",
  TICKET_ACCOUNT: "crm3_account_id",
  TICKET_ASSIGNEE: "assignee"
};
export const EMAIL_USER_PLACEHOLDERS = [
  { id: "user_firstName", name: "User.Firstname" },
  { id: "user_lastName", name: "User.Lastname" },
  { id: "user_email", name: "User.Email" }
];
export const DEAL_STAGE_CHANGE_PLACEHOLDERS = [
  { id: "previousStageName", name: "Former Stage" },
  { id: "currentStageName", name: "Updated Stage" }
];

export const AUTOMATION_ELEMENT_WITH_DYNAMIC_DEAL_NAME = [
  AUTOMATION_ACTIONS.CREATE_DEAL
];

export const ACTIVITY_OVERDUE_KEY = "overdue";

export const INITIAL_MAGNIFICATION_SCALE = 1;
export const MAGNIFICATION_SCALE_UNIT = 0.05;
export const MIN_MAGNIFICATION_SCALE = 0.1;

export const APPROVAL_ACTIONS = {
  [AUTOMATION_TRIGGERS.QUOTE_CREATED]: "QUOTATION",
  [AUTOMATION_TRIGGERS.DEAL_STAGE_CHANGED]: "DEAL"
};
export const DOCUMENTS = [{ id: "QUOTATION", name: "Quote" }];
export const SUPPORTED_APPROVAL_CONDITIONS_ACTIONS = [
  AUTOMATION_TRIGGERS.QUOTE_CREATED,
  AUTOMATION_TRIGGERS.DEAL_STAGE_CHANGED
];
export const APPROVAL_REQUIRED_CONDITION = ["ANY", "ALL"];
