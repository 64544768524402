import { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import Popup from "./Popup";
import {
  INPUT_TYPE,
  DKLabel,
  DKButton,
  DKListPicker,
  DKIcons,
  DKInput
} from "deskera-ui-library";
import { TableManger } from "../../managers/TableManger";
import Utility, { getCapitalized } from "../../utility/Utility";
import { IColumn, IColumnDataSource } from "../../model/Table";
import { PREFIXES } from "../../constants/Enum";

interface IDataSourcePopupProps {
  tableName: string;
  dataSource?: IColumnDataSource;
  columnName?: any;
  popupId?: string;
  onSave: (dataSource: any) => void;
  onClose?: () => void;
}

interface IDataSourcePopupState {
  formData: any;
}

class AddDataSourcePopup extends Component<
  IDataSourcePopupProps,
  IDataSourcePopupState
> {
  formFieldKeys: any = {
    COLUMN: "Column Name",
    TABLE: "Source Table",
    FIELD: "Property",
    FILTER_SOURCE: "Match Source",
    FILTER_TARGET: "Match Target"
  };
  sourceTableColumns: IColumn[] = null;
  targetTableColumns: IColumn[] = null;
  initialFormState: any;

  constructor(props: IDataSourcePopupProps) {
    super(props);
    this.initialFormState = {
      [this.formFieldKeys.COLUMN]: {
        value: this.props.columnName,
        placeholder: "Please enter new column name",
        required: true,
        errorMessage: "Please provide a column name to create webhook",
        canValidate: false
      },
      [this.formFieldKeys.TABLE]: {
        open: false,
        id: this.props.dataSource?.target || null,
        value: this.props.dataSource?.target
          ? TableManger.getTableNameFromId(this.props.dataSource.target)
          : null,
        placeholder: "Please select a table to pick data source",
        required: false,
        options: [...TableManger.tableMap.keys()],
        error: ""
      },
      [this.formFieldKeys.FIELD]: {
        open: false,
        id: null,
        value: null,
        placeholder: "Please select a data source/property",
        type: INPUT_TYPE.TEXT,
        required: false,
        options: null,
        error: ""
      },
      [this.formFieldKeys.FILTER_SOURCE]: {
        open: false,
        id: null,
        value: null,
        placeholder: "Select match source",
        required: false,
        options: this.getTableColumnNames(this.props.tableName, true, true),
        error: ""
      },
      [this.formFieldKeys.FILTER_TARGET]: {
        open: false,
        id: null,
        value: null,
        placeholder: "Select match target",
        required: false,
        options: null,
        error: ""
      }
    };

    this.state = {
      formData: Utility.makeCopyOfObject(this.initialFormState)
    };
  }

  componentDidMount() {
    this.populateExistingColumnData();
    document.addEventListener("keydown", this.bindEnter);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.bindEnter);
  }

  bindEnter = (evt: KeyboardEvent) => {
    /* to keep previous popups opened */
    evt.stopPropagation();
    if (evt.code === "Enter") {
      this.saveTapped();
    }
  };

  populateExistingColumnData = () => {
    if (Utility.isEmptyObject(this.props.dataSource)) return;

    let { formData: updatedFormData } = this.state;
    updatedFormData = Utility.makeCopyOfObject(updatedFormData);

    /* Set datasource property field */
    const property = updatedFormData[this.formFieldKeys.FIELD];
    property.id = this.props.dataSource.valueKey?.replace(PREFIXES.CELLS, "");
    if (Utility.isEmptyObject(property.options)) {
      property.options = this.getTableColumnNames(
        updatedFormData[this.formFieldKeys.TABLE].value,
        false,
        false
      );
    }
    const propertyColumn = this.targetTableColumns?.find(
      (col: IColumn) => col.id === property.id
    );
    property.value = propertyColumn?.name || "";
    property.type = propertyColumn?.type || INPUT_TYPE.TEXT;

    /* Set datasource filter fields */
    const filterSource = updatedFormData[this.formFieldKeys.FILTER_SOURCE];
    filterSource.id = this.props.dataSource.filter?.source_column?.replace(
      PREFIXES.CELLS,
      ""
    );
    if (Utility.isEmptyObject(filterSource.options)) {
      filterSource.options = this.getTableColumnNames(
        this.props.tableName,
        true,
        true
      );
    }
    const filterSourceColumnName = this.sourceTableColumns?.find(
      (col: IColumn) => col.id === filterSource.id
    )?.name;
    filterSource.value = filterSourceColumnName
      ? `${getCapitalized(this.props.tableName)}.${filterSourceColumnName}`
      : "";

    const filterTarget = updatedFormData[this.formFieldKeys.FILTER_TARGET];
    filterTarget.id = this.props.dataSource.filter?.target_column?.replace(
      PREFIXES.CELLS,
      ""
    );
    if (Utility.isEmptyObject(filterTarget.options)) {
      filterTarget.options = this.getTableColumnNames(
        updatedFormData[this.formFieldKeys.TABLE].value,
        false,
        true
      );
    }
    const filterTargetName = this.targetTableColumns?.find(
      (col: IColumn) => col.id === filterTarget.id
    )?.name;
    filterTarget.value = filterTargetName
      ? `${getCapitalized(updatedFormData[this.formFieldKeys.TABLE].value)}.${filterTargetName}`
      : "";

    this.setState({
      formData: updatedFormData
    });
  };

  /*  *********************** COLUMN FIELD UTILITIES ****************************** */
  setTableColumns = (tableName: string, isSourceField: boolean) => {
    /* If we already have source columns/ target columns set then no need to reset */
    if (
      !tableName ||
      (isSourceField && this.sourceTableColumns) ||
      (!isSourceField && this.targetTableColumns)
    )
      return;

    const defaultIDColumn: IColumn = {
      id: "_id",
      name: "Id"
    };

    if (isSourceField) {
      this.sourceTableColumns = Utility.makeCopyOfObject(
        TableManger.getTableColumns(tableName)
      );
      this.sourceTableColumns.unshift(defaultIDColumn);
    } else {
      this.targetTableColumns = Utility.makeCopyOfObject(
        TableManger.getTableColumns(tableName)
      );
      this.targetTableColumns.unshift(defaultIDColumn);
    }
  };

  getTableColumnNames = (
    tableName: string,
    isFilterField: boolean,
    isSourceField: boolean
  ) => {
    this.setTableColumns(tableName, isSourceField);

    return (
      (isSourceField ? this.sourceTableColumns : this.targetTableColumns)?.map(
        (column) =>
          isFilterField
            ? `${getCapitalized(tableName)}.${getCapitalized(column.name)}`
            : column.name
      ) || null
    );
  };

  openTableColumnPicker = (fieldKey: string) => {
    const tableName = this.state.formData[this.formFieldKeys.TABLE].value;

    const columnNames = this.getTableColumnNames(
      tableName,
      fieldKey === this.formFieldKeys.FILTER_TARGET,
      false
    );

    const columnFieldData = this.state.formData[fieldKey];
    const newColumnFieldData = Utility.makeCopyOfObject(columnFieldData);

    if (columnNames) {
      newColumnFieldData.options = columnNames;
      newColumnFieldData.open = true;
    } else {
      newColumnFieldData.error = "Please select a target table first";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [fieldKey]: newColumnFieldData
      }
    });
  };

  /*  *********************** FORM CONTROL HANDLERS ****************************** */
  toggleFormField = (fieldKey: string, isFieldOpen: boolean) => {
    if (!isFieldOpen) {
      switch (fieldKey) {
        case this.formFieldKeys.TABLE:
          break;
        case this.formFieldKeys.FILTER_SOURCE:
          break;
        case this.formFieldKeys.FILTER_TARGET:
          this.openTableColumnPicker(fieldKey);
          return;
        case this.formFieldKeys.FIELD:
          this.openTableColumnPicker(fieldKey);
          return;
        default:
          return;
      }
    }

    const newFormData = Utility.makeCopyOfObject(this.state.formData);
    newFormData[fieldKey].open = !isFieldOpen;

    this.setState({ formData: newFormData });
  };

  onFormValueChange = (key: string, value: string, selectedIndex?: number) => {
    const { COLUMN, TABLE, FIELD, FILTER_SOURCE, FILTER_TARGET } =
      this.formFieldKeys;
    const newFormData = Utility.makeCopyOfObject(
      key === this.formFieldKeys.TABLE
        ? this.initialFormState
        : this.state.formData
    );

    switch (key) {
      case COLUMN:
        newFormData[key].value = value;
        newFormData[key].canValidate = true;
        break;
      case TABLE:
        /* resetting newFormData to default data, as after changing table user will have to select related target column */
        newFormData[COLUMN] = this.state.formData[COLUMN];
        newFormData[key].id = TableManger.getTableId(value);
        this.targetTableColumns = null;
        break;
      case FILTER_SOURCE:
        const sourceColumnKey = (
          this.sourceTableColumns[selectedIndex] ||
          this.sourceTableColumns.find((column) => column.name === value)
        )?.id;
        newFormData[key].id = sourceColumnKey;
        break;
      case FILTER_TARGET:
        const targetColumnKey = (
          this.targetTableColumns[selectedIndex] ||
          this.targetTableColumns.find((column) => column.name === value)
        )?.id;
        newFormData[key].id = targetColumnKey;
        break;
      case FIELD:
        const column =
          this.targetTableColumns[selectedIndex] ||
          this.targetTableColumns.find((column) => column.name === value);
        newFormData[key].id = column?.id;
        newFormData[key].type = column?.type || INPUT_TYPE.TEXT;
        break;
      default:
    }

    newFormData[key].value = value;
    newFormData[key].open = false;
    newFormData[key].error = "";

    this.setState({
      formData: newFormData
    });
  };

  /*  *********************** FORM SUBMISSION HANDLERS ****************************** */
  isFormDataInvalid = () => {
    let foundInvalidKey = false;
    const { COLUMN, FILTER_SOURCE, FILTER_TARGET } = this.formFieldKeys;
    const newFormData = Utility.makeCopyOfObject(this.state.formData);

    Object.keys(newFormData).forEach((key) => {
      const formDataObj = newFormData[key];
      if (key === COLUMN && !formDataObj.value) {
        newFormData[key].canValidate = true;
        foundInvalidKey = true;
        return;
      } else if (key === COLUMN) {
        return;
      }

      if (!formDataObj.id) {
        const isFilterField = key === FILTER_SOURCE || key === FILTER_TARGET;
        newFormData[key].error = isFilterField
          ? `Please provide a match condition for creating webhook`
          : `Please select a ${key.toLowerCase()} for creating webhook`;
        foundInvalidKey = true;
      }
    });

    if (foundInvalidKey) {
      this.setState({ formData: newFormData });
    }

    return foundInvalidKey;
  };

  getResponseData = () => {
    const { formData } = this.state;
    const { COLUMN, TABLE, FIELD, FILTER_SOURCE, FILTER_TARGET } =
      this.formFieldKeys;

    const tableId = formData[TABLE].id;
    const sourceColumnId = formData[FILTER_SOURCE].id;
    const targetColumnId = formData[FILTER_TARGET].id;
    const valueFieldId = formData[FIELD].id;
    const valueFieldType = formData[FIELD].type;

    return {
      datasource: {
        source: "internal",
        target: tableId,
        filter: {
          source_column:
            sourceColumnId === "_id"
              ? "_id"
              : `${PREFIXES.CELLS}${sourceColumnId}`,
          target_column:
            targetColumnId === "_id"
              ? "_id"
              : `${PREFIXES.CELLS}${targetColumnId}`
        },
        valueKey:
          valueFieldId === "_id" ? "_id" : `${PREFIXES.CELLS}${valueFieldId}`
      },
      columnName: formData[COLUMN]?.value || this.props.columnName,
      dataSourceFieldType: valueFieldType
    };
  };

  saveTapped = () => {
    if (this.isFormDataInvalid()) return;

    const response = this.getResponseData();
    this.props.onSave(response);
    this.removePopUp();
  };

  onCancel = () => {
    this.removePopUp();
  };

  removePopUp = () => {
    if (this.props.popupId)
      ReactDOM.unmountComponentAtNode(
        document.getElementById(this.props.popupId)
      );
    document.getElementById(this.props.popupId)?.remove();
    if (this.props.onClose) this.props.onClose();
  };

  /*  *********************** FORM UI HANDLERS ****************************** */
  render() {
    return (
      <Popup popupWindowStyles={{ overflowY: "visible" }}>
        {this.getHeader()}
        {this.getForm()}
      </Popup>
    );
  }

  getHeader() {
    return (
      <div className="row align-items-center justify-content-between parent-width">
        <div className="row fs-xl fw-h">
          {this.props.dataSource ? "Update" : "Create"} Webhook
        </div>
        <div className="row-reverse action-btn-wrapper">
          <DKButton
            className="border-m ml-r bg-button text-white"
            title="Save"
            onClick={this.saveTapped}
          />
          <DKButton
            className="border-m bg-white"
            title="Cancel"
            onClick={this.onCancel}
          />
        </div>
        {/* Subtitle */}
      </div>
    );
  }

  getFilterRow() {
    const { formData } = this.state;
    const { FILTER_SOURCE, FILTER_TARGET } = this.formFieldKeys;
    const filterFieldError =
      formData[FILTER_SOURCE].error || formData[FILTER_TARGET].error;
    const isTargetError = filterFieldError && !formData[FILTER_SOURCE].error;

    return (
      <Fragment>
        <div className="row justify-content-between p-v-m position-relative">
          <DKLabel text="Match By" />
          <div className="row justify-content-between" style={{ width: "80%" }}>
            {this.getFormField(this.formFieldKeys.FILTER_SOURCE, false, false)}
            <DKLabel
              text="="
              className="border-m p-v-xs p-h-s m-h-r border-radius-m text-app"
            />
            {this.getFormField(this.formFieldKeys.FILTER_TARGET, false, true)}
          </div>
        </div>
        <DKLabel
          text={filterFieldError}
          className="text-red fs-s pl-xs"
          style={{
            display: filterFieldError ? "inline-block" : "none",
            marginTop: -6,
            marginLeft: isTargetError ? "auto" : "20%"
          }}
        />
      </Fragment>
    );
  }

  getFormField(fieldKey: string, needLabel: boolean, isTargetFilter: boolean) {
    const fieldData = this.state.formData[fieldKey];
    return (
      <Fragment>
        <div
          className={`row justify-content-between ${needLabel ? "p-v-m" : ""}`}
        >
          {needLabel ? <DKLabel text={fieldKey} /> : null}
          <div
            className={`position-relative border-box bg-gray1 border-radius-s ${
              fieldData.error ? " border-red" : ""
            } ${fieldData.open ? " z-index-3" : ""}`}
            style={{
              height: 35,
              width: needLabel ? "80%" : "100%"
            }}
          >
            <DKButton
              title={fieldData.value || fieldData.placeholder || ""}
              className={`parent-height webhook-field-selector justify-content-between border-s border-radius-s text-capitalize ${
                fieldData.value ? "" : "text-gray"
              }`}
              style={{
                textTransform: fieldData.value ? "capitalize" : ""
              }}
              icon={DKIcons.ic_arrow_down}
              isReverse={true}
              onClick={() => this.toggleFormField(fieldKey, fieldData.open)}
            />
            {fieldData.open ? (
              <DKListPicker
                data={fieldData.options}
                title={fieldKey}
                className="position-absolute border-m shadow-m parent-width"
                style={{ top: -60, [isTargetFilter ? "right" : "left"]: 0 }}
                onSelect={(index: number, value: string) =>
                  this.onFormValueChange(fieldKey, value, index)
                }
                onClose={() =>
                  setTimeout(() => this.toggleFormField(fieldKey, true), 10)
                }
              />
            ) : null}
          </div>
        </div>
        <DKLabel
          text={fieldData.error}
          className="text-red fs-s pl-xs"
          style={{
            display: needLabel && fieldData.error ? "inline-block" : "none",
            marginTop: -6,
            marginLeft: "20%"
          }}
        />
      </Fragment>
    );
  }

  getTextField(fieldKey: string) {
    const fieldData = this.state.formData[fieldKey];
    return (
      <div className="row pb-r">
        <DKLabel
          text={fieldKey}
          className={"mr-r"}
          style={{
            whiteSpace: "nowrap"
          }}
        />
        <DKInput
          value={fieldData.value}
          type={INPUT_TYPE.TEXT}
          required={fieldData.required}
          placeholder={fieldData.placeholder}
          canValidate={fieldData.canValidate}
          errorMessage={fieldData.errorMessage}
          onChange={(value) => this.onFormValueChange(fieldKey, value)}
        />
      </div>
    );
  }

  getForm() {
    return (
      <div className="column mt-xl" style={{ height: 250 }}>
        {this.getTextField(this.formFieldKeys.COLUMN)}
        {this.getFormField(this.formFieldKeys.TABLE, true, false)}
        {this.getFormField(this.formFieldKeys.FIELD, true, false)}
        {this.getFilterRow()}
      </div>
    );
  }
}

export const showDataSourcePopup = (
  config: {
    tableName: string;
    dataSource: IColumnDataSource;
    columnName?: string;
  },
  onSave: (response: any) => void,
  onClose?: () => void
) => {
  const id = `data-source-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "data-source-popup app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <AddDataSourcePopup
      tableName={config.tableName}
      dataSource={config.dataSource}
      columnName={config.columnName}
      popupId={id}
      onSave={onSave}
      onClose={onClose}
    />,
    document.body.appendChild(div)
  );
};

export default AddDataSourcePopup;
