import { COLUMN_CODE, TableManger, TABLES } from "./TableManger";
import RouteManager, { PAGE_ROUTES, ROUTE_ID_IDENTIFIER } from "./RouteManager";
import Utility, { toCurrencyFormat } from "../utility/Utility";
import UserManager from "../managers/UserManager";
import { PERMISSIONS } from "../constants/Permission";
import {
  NOTIFICATION_ACTION_TYPE,
  TICKET_EVENT_TYPES
} from "../constants/Enum";
import { store } from "../redux/store";
import { fetchTables } from "../redux/slices/tableSlice";
import { getPipelineStages } from "../redux/slices/recordSlice";

export default class NotificationManager {
  static navigateToRespectiveSection = (data, index) => {
    let redirectURL = "";
    switch (data.type) {
      case TABLES.CONTACT:
        if (data.action === "SEGMENT_CREATE") {
          redirectURL = PAGE_ROUTES.LIST;
        } else if (
          data.action !== "REC_DELETE" &&
          data.action !== "REC_BULK_DELETE" &&
          data.recordId !== null
        ) {
          redirectURL = PAGE_ROUTES.CONTACTS_UI.replace(
            ROUTE_ID_IDENTIFIER,
            data.recordId
          );
        } else {
          redirectURL = PAGE_ROUTES.CONTACTS;
        }
        RouteManager.navigateToPage(redirectURL);
        break;
      case TABLES.DEAL:
        if (
          data.action !== "REC_DELETE" &&
          data.action !== "REC_BULK_DELETE" &&
          data.recordId !== null
        ) {
          redirectURL = PAGE_ROUTES.DEALS_UI.replace(
            ROUTE_ID_IDENTIFIER,
            data.recordId
          );
        } else {
          redirectURL = PAGE_ROUTES.DEALS;
        }
        RouteManager.navigateToPage(redirectURL);
        break;
      case TABLES.FORM:
        if (
          data.action !== "REC_DELETE" &&
          data.action !== "REC_BULK_DELETE" &&
          data.recordId !== null
        ) {
          redirectURL = PAGE_ROUTES.FORM_BUILDER + "/" + data.recordId;
        } else {
          redirectURL = PAGE_ROUTES.FORMS;
        }
        RouteManager.navigateToPage(redirectURL);
        break;
      case TABLES.LANDING_PAGE:
        if (
          data.action !== "REC_DELETE" &&
          data.action !== "REC_BULK_DELETE" &&
          data.recordId !== null
        ) {
          redirectURL = PAGE_ROUTES.LANDING_PAGE_BUILDER + "/" + data.recordId;
        } else {
          redirectURL = PAGE_ROUTES.LANDING_PAGE;
        }
        RouteManager.navigateToPage(redirectURL);
        break;
      case TABLES.CAMPAIGN:
        if (
          data.action !== "REC_DELETE" &&
          data.action !== "REC_BULK_DELETE" &&
          data.recordId !== null
        ) {
          redirectURL = PAGE_ROUTES.CAMPAIGN_BUILDER + "/" + data.recordId;
        } else {
          redirectURL = PAGE_ROUTES.CAMPAIGN;
        }
        RouteManager.navigateToPage(redirectURL);
        break;
      case TABLES.ACTIVITY:
        RouteManager.navigateToPage(PAGE_ROUTES.ACTIVITY);
        break;
      case TABLES.STAGE:
        RouteManager.navigateToPage(PAGE_ROUTES.DEALS);
        break;
      case TABLES.PIPELINE:
        RouteManager.navigateToPage(PAGE_ROUTES.DEALS);
        break;
      case TABLES.CHAT_MESSAGE:
        RouteManager.navigateToPage(PAGE_ROUTES.CHAT);
        break;
      /* case TABLES.FUNNEL:
        RouteManager.navigateToPage(PAGE_ROUTES.FUNNEL);
        break; */
      case TABLES.AUTOMATION:
        RouteManager.navigateToPage(PAGE_ROUTES.AUTOMATION);
        break;
      case TABLES.ORDER:
        RouteManager.navigateToPage(PAGE_ROUTES.ORDER);
        break;
      case TICKET_EVENT_TYPES.TICKET.toLowerCase():
      case TICKET_EVENT_TYPES.SUPPORT_EMAIL.toLowerCase():
        RouteManager.navigateToPage(PAGE_ROUTES.SUPPORT_TICKET);
        break;
      default:
        RouteManager.navigateToPage(PAGE_ROUTES.DASHBOARD);
    }
  };

  static notificationDataParse = (data) => {
    if (Utility.isEmptyObject(data.data)) {
      return data.data;
    }
    let notifications = [];
    data?.data?.forEach((element) => {
      let record = {
        title:
          this.getNotificationTitle(element)?.charAt(0).toUpperCase() +
          this.getNotificationTitle(element)?.slice(1),
        owner: element.metaData.user.name,
        date: new Date(element.createdAt).toDateString(),
        read: element.seenBy?.includes(UserManager.getUserIamID()) || false,
        type: element.objectType.toLowerCase(),
        action: element.action,
        documentId: element._id,
        recordId:
          element.metaData?.recordsAfter &&
          element.metaData?.recordsAfter.length > 0
            ? element.metaData?.recordsAfter[0]._id
            : null,
        threadId:
          element.metaData?.recordsAfter &&
          element.metaData?.recordsAfter.length > 0
            ? element.metaData?.recordsAfter[0].threadId
            : null
      };
      notifications.push(record);
    });
    return notifications;
  };

  static getNotificationTitle = (notification) => {
    switch (notification.action) {
      case NOTIFICATION_ACTION_TYPE.REC_CREATE:
      case NOTIFICATION_ACTION_TYPE.REC_UPDATE:
        return this.getMessageByObjectType(notification);
      case NOTIFICATION_ACTION_TYPE.REC_DELETE:
        return `${notification.objectType.toLowerCase().replace("_", " ")} deleted`;
      case NOTIFICATION_ACTION_TYPE.REC_BULK_DELETE:
        return `${notification.objectType.toLowerCase().replace("_", " ")} deleted`;
      case NOTIFICATION_ACTION_TYPE.REC_IMPORT:
        return this.getImportMessage(notification);
      case NOTIFICATION_ACTION_TYPE.REC_BULK_CREATE:
        return notification.objectType.toLowerCase() === TABLES.CONTACT
          ? `Imported contacts`
          : `default ${notification.objectType.toLowerCase().replace("_", " ")}'s created`;
      case NOTIFICATION_ACTION_TYPE.REC_BULK_UPDATE:
        return `${notification.objectType.toLowerCase().replace("_", " ")} updated`;
      case NOTIFICATION_ACTION_TYPE.NEW_CHAT_MESSAGE:
        return this.getChatMessage(notification);
      case NOTIFICATION_ACTION_TYPE.REC_PERMISSION_CHANGED:
        return `Permission changed to ${this.permissionMessage(notification)} for ${notification.objectType.toLowerCase().replace("_", " ")} - ${this.getRecordData(notification, COLUMN_CODE.CONTACT.NAME)}`;
      case NOTIFICATION_ACTION_TYPE.TABLE_PERMISSION_CHANGED:
        return `Permission changed to ${this.permissionMessage(notification)} for ${notification.objectType.toLowerCase().replace("_", " ")} module`;
      case NOTIFICATION_ACTION_TYPE.SEGMENT_CREATE:
        return `Segment added - <b>${notification.metaData?.recordsAfter?.[0].name}</b>`;
      case NOTIFICATION_ACTION_TYPE.OWNER_ASSIGNED:
        return `Owner assigned to ${notification.objectType.toLowerCase().replace("_", " ")} - ${this.getRecordData(notification, COLUMN_CODE.CONTACT.NAME)}`;
      case NOTIFICATION_ACTION_TYPE.NOTE_CREATE:
      case NOTIFICATION_ACTION_TYPE.NOTE_UPDATED:
      case NOTIFICATION_ACTION_TYPE.NOTE_DELETED:
        return this.getNoteMessage(notification);
      default:
        return "";
    }
  };

  static getRecordData = (data, colCode) => {
    let tableColumns = {};
    TableManger.getTableColumns(data.objectType.toLowerCase()).forEach(
      (column) => {
        tableColumns[column.columnCode] = column;
      }
    );
    if (colCode === COLUMN_CODE.DEAL.AMOUNT) {
      return `<b>(${toCurrencyFormat(data.metaData?.recordsAfter?.[0].cells[tableColumns[colCode].id] || "0")})</b>`;
    } else if (colCode === COLUMN_CODE.DEAL.NOTE) {
      return `<b>${Utility.decodeBase64Uri(data.metaData?.recordsAfter?.[0].cells[tableColumns[colCode].id]?.[0]?.message)}</b>`;
    } else {
      return `<b>${data.metaData?.recordsAfter?.[0].cells[tableColumns[colCode].id]}</b>`;
    }
  };

  static getNoteMessage = (data) => {
    let tableColumns = {};
    TableManger.getTableColumns(data.objectType.toLowerCase()).forEach(
      (column) => {
        tableColumns[column.columnCode] = column;
      }
    );
    if (data.objectType === "CONTACT") {
      if (
        Utility.isEmptyObject(
          data.metaData?.recordsAfter?.[0].cells[
            tableColumns[COLUMN_CODE.CONTACT.NOTE].id
          ]
        )
      ) {
        return `Note has been deleted for ${data.objectType.toLowerCase().replace("_", " ")} ${this.getRecordData(data, COLUMN_CODE.CONTACT.NAME)}`;
      } else {
        return `Note has been ${data.action === NOTIFICATION_ACTION_TYPE.NOTE_CREATE ? "created" : "updated"} for ${data.objectType.toLowerCase().replace("_", " ")} ${this.getRecordData(data, COLUMN_CODE.CONTACT.NAME)}`;
      }
    } else if (data.objectType === "DEAL") {
      if (
        Utility.isEmptyObject(
          data.metaData?.recordsAfter?.[0].cells[
            tableColumns[COLUMN_CODE.DEAL.NOTE].id
          ]
        )
      ) {
        return `Note has been deleted for ${data.objectType.toLowerCase().replace("_", " ")} ${this.getRecordData(data, COLUMN_CODE.DEAL.NAME)}`;
      } else {
        return `Note has been ${data.action === NOTIFICATION_ACTION_TYPE.NOTE_CREATE ? "created" : "updated"} for ${data.objectType.toLowerCase().replace("_", " ")} ${this.getRecordData(data, COLUMN_CODE.DEAL.NAME)}`;
      }
    } else if (data.objectType === "ACTIVITY") {
      if (
        Utility.isEmptyObject(
          data.metaData?.recordsAfter?.[0].cells[
            tableColumns[COLUMN_CODE.ACTIVITY.NOTE].id
          ]
        )
      ) {
        return `Note has been deleted for ${data.objectType.toLowerCase().replace("_", " ")} ${this.getRecordData(data, COLUMN_CODE.ACTIVITY.NAME)}`;
      } else {
        return `Note has been ${data.action === NOTIFICATION_ACTION_TYPE.NOTE_CREATE ? "created" : "updated"} for ${data.objectType.toLowerCase().replace("_", " ")} ${this.getRecordData(data, COLUMN_CODE.ACTIVITY.NAME)}`;
      }
    }
    if (data.objectType === "ACCOUNT") {
      if (
        Utility.isEmptyObject(
          data.metaData?.recordsAfter?.[0].cells[
            tableColumns[COLUMN_CODE.ACCOUNT.NOTE].id
          ]
        )
      ) {
        return `Note has been deleted for ${data.objectType
          .toLowerCase()
          .replace("_", " ")} ${this.getRecordData(
          data,
          COLUMN_CODE.ACCOUNT.NAME
        )}`;
      } else {
        return `Note has been ${
          data.action === NOTIFICATION_ACTION_TYPE.NOTE_CREATE
            ? "created"
            : "updated"
        } for ${data.objectType
          .toLowerCase()
          .replace("_", " ")} ${this.getRecordData(
          data,
          COLUMN_CODE.ACCOUNT.NAME
        )}`;
      }
    }
  };
  static getChatMessage = (data) => {
    if (data.metaData.recordsAfter[0].body.stepId === "MEET_SLOT_STEP") {
      return "Meeting scheduled by user";
    } else if (data.metaData.recordsAfter[0].body.attachments.length === 0) {
      return data.metaData.recordsAfter[0].body.text;
    } else {
      return (
        data.metaData.recordsAfter[0].body.attachments[0].url
          .split("/")
          .pop() || "-"
      );
    }
  };

  static permissionMessage = (data) => {
    let permission = PERMISSIONS;
    let accessLevel = data.metaData.accessLevelAfter;
    if (data.metaData.accessLevelAfter === null) {
      accessLevel = 0;
    }
    let selectedData = permission.find(
      (element) => element.permissions_level === accessLevel
    );
    return `<b>${selectedData?.title.toLowerCase()}</b>`;
  };

  static dealMessage = (data) => {
    switch (data.action) {
      case NOTIFICATION_ACTION_TYPE.REC_CREATE:
        return `${data.objectType.toLowerCase().replace("_", " ")} - ${this.getRecordData(data, COLUMN_CODE.CONTACT.NAME)} ${this.getRecordData(data, COLUMN_CODE.DEAL.AMOUNT) || ""} created`;
      case NOTIFICATION_ACTION_TYPE.REC_UPDATE:
        return `${data.objectType.toLowerCase().replace("_", " ")} - ${this.getRecordData(data, COLUMN_CODE.CONTACT.NAME)} ${this.getRecordData(data, COLUMN_CODE.DEAL.AMOUNT) || ""} updated`;
      case NOTIFICATION_ACTION_TYPE.NOTE_CREATE:
        return `Note added - ${this.getRecordData(data, COLUMN_CODE.DEAL.NOTE)}`;
      default:
        return "";
    }
  };

  static getImportMessage = (data) => {
    const importLog = data.metaData.importLog;
    return `${data.objectType.toLowerCase()} - ${
      Utility.isEmptyObject(importLog) || importLog.failed
        ? `import failed. (<b>Total records : ${importLog?.totalCount || 0}</b>)`
        : `records imported (<b>Total : ${importLog.totalCount} | Imported : ${importLog.successCount} | Rejected : ${importLog.failedCount}</b>)`
    }`;
  };

  static onNewNotification = (notifications) => {
    const recentNotification = notifications?.data?.[0];
    if (recentNotification) {
      switch (recentNotification.action) {
        case NOTIFICATION_ACTION_TYPE.TABLE_PERMISSION_CHANGED:
          store.dispatch(fetchTables());
          return;
        case NOTIFICATION_ACTION_TYPE.REC_IMPORT:
          const objectType = recentNotification.objectType.toLowerCase();
          switch (objectType) {
            case TABLES.CONTACT:
              TableManger.refreshTable(objectType);
              break;
            case TABLES.ACCOUNT:
            case TABLES.DEAL:
              TableManger.refreshTable(objectType);
              store.dispatch(getPipelineStages());
              break;
            default:
          }
          break;
        default:
          break;
      }
    }
  };
  static getMessageByObjectType = (notification) => {
    switch (notification.objectType) {
      case "DEAL":
        return this.dealMessage(notification);
      case "TICKET":
        if (NOTIFICATION_ACTION_TYPE.REC_CREATE === notification.action) {
          return `Ticket No - <b>${notification.metaData?.recordsAfter?.[0]?.ticket_no}</b> created`;
        } else {
          return `Ticket No - <b>${notification.metaData?.recordsAfter?.[0]?.ticket_no}</b> updated`;
        }
      case "SUPPORT_EMAIL":
        return `Email received for ticket No - <b>${notification.metaData?.recordsAfter?.[0]?.ticket_no}</b>`;
      default:
        return `${notification.objectType.toLowerCase().replace("_", " ")} ${notification.action === NOTIFICATION_ACTION_TYPE.REC_CREATE ? "added" : "updated"} - ${this.getRecordData(notification, COLUMN_CODE.CONTACT.NAME)}`;
    }
  };
}
