import ApiConstants from "../constants/ApiConstants";

export default class Auth {
  private static _authInstance = null;
  private static statusCallPromise = null;

  constructor() {}

  public static getInstance(): Auth {
    if (!Auth._authInstance) {
      Auth._authInstance = new Auth();
    }
    return Auth._authInstance;
  }

  public clearStoredStatusCall() {
    Auth.statusCallPromise = null;
  }

  public async checkIfUserLoggedIn() {
    /* For avoiding parallel status calls */
    if (Auth.statusCallPromise) {
      return Auth.statusCallPromise;
    }

    Auth.statusCallPromise = fetch(
      ApiConstants.URL.BASE + ApiConstants.URL.IAM.STATUS,
      {
        method: "GET",
        credentials: "include",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Origin": "*"
        }
      }
    )
      .then((response) => {
        Auth._authInstance.clearStoredStatusCall();
        return response.json();
      })
      .catch((error) => {
        return error;
      });

    return Auth.statusCallPromise;
  }
}
