export const PRICING_DATA = {
  Marketing: [
    {
      planName: "Startup",
      inAdditionTo: "Free tools",
      icon: "",
      monthlyPrice: 50,
      haveAnnualPaymentDiscount: true,
      annualPaymentDiscount: 10,
      freeContactLimit: 1000,
      description:
        "Includes 1,000 marketing contacts. Additional marketing contacts are sold in increments of 1,000 from $45.00/month.",
      features: [
        {
          heading: "MARKETING AUTOMATION",
          subs: [
            {
              title: "✓ Form automation",
              description: "Form automation"
            },
            {
              title: "✓ Email automation",
              description: "Email automation"
            }
          ]
        },
        {
          heading: "REMOVE DESKERA BRANDING FROM",
          subs: [
            {
              title: "✓ Forms",
              description: "Forms"
            },
            {
              title: "✓ Email marketing",
              description: "Email marketing"
            },
            {
              title: "✓ Landing pages",
              description: "Landing pages"
            },
            {
              title: "✓ Live chat",
              description: "Live chat"
            }
          ]
        },
        {
          heading: "GET HELP FAST",
          subs: [
            {
              title: "✓ Email and in-app chat support",
              description: "Email and in-app chat support"
            }
          ]
        },
        {
          heading: "GROW YOUR REVENUE",
          subs: [
            {
              title: "✓ Payments",
              description: "(U.S. only)"
            }
          ]
        }
      ]
    },
    {
      planName: "Professional",
      inAdditionTo: "Startup",
      icon: "",
      monthlyPrice: 800,
      haveAnnualPaymentDiscount: true,
      annualPaymentDiscount: 10,
      freeContactLimit: 2000,
      description:
        "Includes 2,000 marketing contacts. Additional marketing contacts are sold in increments of 5,000 from $224.72/month.",
      features: [
        {
          heading: "AUTOMATE & PERSONALIZE ENGAGEMENT ACROSS CHANNELS",
          subs: [
            {
              title: "✓ Omni-channel marketing automation",
              description: "Omni-channel marketing automation"
            },
            {
              title: "✓ ABM tools and automation",
              description: "ABM tools and automation"
            },
            {
              title: "✓ Dynamic personalization",
              description: "Dynamic personalization"
            },
            {
              title: "✓ Multi-language content",
              description: "Multi-language content"
            },
            {
              title: "✓ Social media",
              description: "Social media"
            },
            {
              title: "✓ Video hosting & management",
              description: "Video hosting & management"
            },
            {
              title: "✓ Contact and company scoring",
              description: "Contact and company scoring"
            }
          ]
        },
        {
          heading: "REMOVE DESKERA BRANDING FROM",
          subs: [
            {
              title: "✓ Collaboration tools",
              description: "Collaboration tools"
            },
            {
              title: "✓ Campaign management",
              description: "Campaign management"
            },
            {
              title: "✓ Teams",
              description: "Teams"
            }
          ]
        },
        {
          heading: "OPTIMIZE CAMPAIGN CONVERSION",
          subs: [
            {
              title: "✓ A/B testing",
              description: "A/B testing"
            },
            {
              title: "✓ Ad conversion events",
              description: "Ad conversion events"
            },
            {
              title: "✓ Contact create attribution",
              description: "Contact create attribution"
            }
          ]
        },
        {
          heading: "BUILD CUSTOM REPORTS",
          subs: [
            {
              title: "✓ Website traffic analytics",
              description: "Website traffic analytics"
            },
            {
              title: "✓ Campaign reporting",
              description: "Campaign reporting"
            },
            {
              title: "✓ Custom reporting",
              description: "Custom reporting"
            },
            {
              title: "✓ Salesforce integration",
              description: "Salesforce integration"
            }
          ]
        },
        {
          heading: "BUILD CUSTOM REPORTS",
          subs: [
            {
              title: "✓ Phone support",
              description: "Phone support"
            }
          ]
        }
      ]
    },
    {
      planName: "Enterprise",
      inAdditionTo: "Professional",
      icon: "",
      monthlyPrice: 3600,
      haveAnnualPaymentDiscount: false,
      annualPaymentDiscount: 10,
      freeContactLimit: 10000,
      description:
        "Includes 10,000 marketing contacts. Additional marketing contacts are sold in increments of 10,000 from $100.00/month.",
      features: [
        {
          heading: "MANAGE YOUR TEAMS & BRANDS",
          subs: [
            {
              title: "✓ Single sign-on",
              description: "Single sign-on"
            },
            {
              title: "✓ Partitioning",
              description: "Partitioning"
            },
            {
              title: "✓ Hierarchical teams",
              description: "Hierarchical teams"
            },
            {
              title: "✓ Permission sets",
              description: "Permission sets"
            },
            {
              title: "✓ Field-level permissions",
              description: "Field-level permissions"
            },
            {
              title: "✓ Email send frequency cap",
              description: "Email send frequency cap"
            }
          ]
        },
        {
          heading: "EXTEND THE PLATFORM",
          subs: [
            {
              title: "✓ Custom objects",
              description: "Custom objects"
            },
            {
              title: "✓ Salesforce custom object sync",
              description: "Salesforce custom object sync"
            },
            {
              title: "✓ Adaptive testing",
              description: "Adaptive testing"
            },
            {
              title: "✓ Sandboxes",
              description: "Sandboxes"
            }
          ]
        },
        {
          heading: "ADVANCE YOUR REPORTING",
          subs: [
            {
              title: "✓ Multi-touch revenue attribution",
              description: "Multi-touch revenue attribution"
            },
            {
              title: "✓ Behavioral event triggers and reporting",
              description: "Behavioral event triggers and reporting"
            },
            {
              title: "✓ Predictive lead scoring",
              description: "Predictive lead scoring"
            }
          ]
        }
      ]
    }
  ],
  "Customer Service": [
    {
      planName: "Startup",
      inAdditionTo: "Free Tools",
      icon: "",
      monthlyPrice: 50,
      haveAnnualPaymentDiscount: true,
      annualPaymentDiscount: 10,
      freeContactLimit: 1000,
      description: "2 paid users included. $25/month per additional user.",
      features: [
        {
          heading: "REMOVE HUBSPOT BRANDING FROM",
          subs: [
            {
              title: "✓ Live chat",
              description: "Live chat"
            },
            {
              title: "✓ Meeting scheduling",
              description: "Meeting scheduling"
            },
            {
              title: "✓ Documents",
              description: "Documents"
            },
            {
              title: "✓ 1-to-1 email",
              description: "1-to-1 email"
            }
          ]
        },
        {
          heading: "ORGANIZE SUPPORT CHANNELS`",
          subs: [
            {
              title: "✓ Shared inbox",
              description: "Shared inbox"
            },
            {
              title: "✓ Mobile inbox",
              description: "Mobile inbox"
            },
            {
              title: "✓ Team email",
              description: "Team email"
            },
            {
              title: "✓ Ticket pipelines",
              description: "Ticket pipelines"
            },
            {
              title: "✓ Simple ticket automation",
              description: "Simple ticket automation"
            },
            {
              title: "✓ Conversational bots",
              description: "Conversational bots"
            },
            {
              title: "✓ Calling",
              description: "Calling"
            }
          ]
        },
        {
          heading: "STREAMLINE CUSTOMER COMMUNICATIONS",
          subs: [
            {
              title: "✓ Repeating tasks and task queues",
              description: "Repeating tasks and task queues"
            },
            {
              title: "✓ Email templates",
              description: "Email templates"
            },
            {
              title: "✓ Canned snippets",
              description: "Canned snippets"
            },
            {
              title: "✓ Slack integration",
              description: "Slack integration"
            }
          ]
        },
        {
          heading: "MEASURE & IMPROVE EFFICIENCY",
          subs: [
            {
              title: "✓ Email tracking & notifications",
              description: "Email tracking & notifications"
            },
            {
              title: "✓ Reporting dashboard",
              description: "Reporting dashboard"
            }
          ]
        },
        {
          heading: "GET HELP FAST",
          subs: [
            {
              title: "✓ Email and in-app chat support",
              description: "Email and in-app chat support"
            }
          ]
        },
        {
          heading: "GROW YOUR REVENUE",
          subs: [
            {
              title: "✓ Payments",
              description: "(U.S. only)"
            }
          ]
        }
      ]
    },
    {
      planName: "Professional",
      inAdditionTo: "Startup",
      icon: "",
      monthlyPrice: 500,
      haveAnnualPaymentDiscount: true,
      annualPaymentDiscount: 10,
      freeContactLimit: 2000,
      description: "5 paid users included. $100/month per additional user.",
      features: [
        {
          heading: "SCALE SUPPORT WITH AUTOMATION & SELF-SERVICE",
          subs: [
            {
              title: "✓ Help desk automation",
              description: "Help desk automation"
            },
            {
              title: "✓ Ticket routing",
              description: "Ticket routing"
            },
            {
              title: "✓ Knowledge base",
              description: "Knowledge base"
            },
            {
              title: "✓ SLAs",
              description: "SLAs"
            }
          ]
        },
        {
          heading: "CONNECT SERVICE TO THE FRONT OFFICE",
          subs: [
            {
              title: "✓ Customer feedback surveys",
              description: "Customer feedback surveys"
            },
            {
              title: "✓ Custom surveys",
              description: "Custom surveys"
            },
            {
              title: "✓ Forecasting",
              description: "Forecasting"
            }
          ]
        },
        {
          heading: "EXPAND CUSTOMER RELATIONSHIPS",
          subs: [
            {
              title: "✓ Channel switching",
              description: "Channel switching"
            },
            {
              title: "✓ Customer portal",
              description: "Customer portal"
            },
            {
              title: "✓ Standard contact scoring",
              description: "Standard contact scoring"
            },
            {
              title: "✓ Service analytics",
              description: "Service analytics"
            }
          ]
        },
        {
          heading: "GET ADVANCED SUPPORT",
          subs: [
            {
              title: "✓ Phone support",
              description: "Phone support"
            }
          ]
        }
      ]
    },
    {
      planName: "Enterprise",
      inAdditionTo: "Professional",
      icon: "",
      monthlyPrice: 1200,
      haveAnnualPaymentDiscount: false,
      annualPaymentDiscount: 10,
      freeContactLimit: 10000,
      description: "10 paid users included. $120/month per additional user.",
      features: [
        {
          heading: "MANAGE SCALING TEAMS",
          subs: [
            {
              title: "✓ Permission sets",
              description: "Permission sets"
            },
            {
              title: "✓ Hierarchical teams",
              description: "Hierarchical teams"
            },
            {
              title: "✓ Field-level permissions",
              description: "Field-level permissions"
            },
            {
              title: "✓ Single sign-on",
              description: "Single sign-on"
            }
          ]
        },
        {
          heading: "CUSTOMIZE YOUR PROCESS",
          subs: [
            {
              title: "✓ Custom objects",
              description: "Custom objects"
            },
            {
              title: "✓ Calculated properties",
              description: "Calculated properties"
            },
            {
              title: "✓ Standard CRM interface configuration",
              description: "Standard CRM interface configuration"
            },
            {
              title: "✓ Sandboxes",
              description: "Sandboxes"
            }
          ]
        },
        {
          heading: "OPTIMIZE PERFORMANCE FOR GROWTH",
          subs: [
            {
              title: "✓ Conversation intelligence",
              description: "Conversation intelligence"
            },
            {
              title: "✓ Goals",
              description: "Goals"
            },
            {
              title: "✓ Playbooks",
              description: "Playbooks"
            }
          ]
        }
      ]
    }
  ],
  CMS: [
    {
      planName: "Startup",
      inAdditionTo: "Free Tools",
      icon: "",
      monthlyPrice: 25,
      haveAnnualPaymentDiscount: true,
      annualPaymentDiscount: 10,
      freeContactLimit: 1000,
      description: "",
      features: [
        {
          heading: "SECURE YOUR WEBSITE",
          subs: [
            {
              title: "✓ Premium hosting",
              description: "Premium hosting"
            },
            {
              title: "✓ Content delivery network (CDN)",
              description: "Content delivery network (CDN)"
            },
            {
              title: "✓ Standard SSL certificate",
              description: "Standard SSL certificate"
            },
            {
              title: "✓ Web application firewall (WAF)",
              description: "Web application firewall (WAF)"
            },
            {
              title: "✓ Custom domain security settings",
              description: "Custom domain security settings"
            }
          ]
        },
        {
          heading: "BUILD YOUR WEBSITE EFFICIENTLY`",
          subs: [
            {
              title: "✓ Website themes",
              description: "Website themes"
            },
            {
              title: "✓ Drag-and-drop editor",
              description: "Drag-and-drop editor"
            },
            {
              title: "✓ Team email",
              description: "Team email"
            },
            {
              title: "✓ Local website development",
              description: "Local website development"
            },
            {
              title: "✓ App and asset marketplace",
              description: "App and asset marketplace"
            }
          ]
        },
        {
          heading: "LEVERAGE THE FULL PLATFORM",
          subs: [
            {
              title: "✓ All free tools",
              description: "All free tools"
            },
            {
              title: "✓ Live chat",
              description: "Live chat"
            },
            {
              title: "✓ Forms",
              description: "Forms"
            },
            {
              title: "✓ Reporting dashboard",
              description: "Reporting dashboard"
            }
          ]
        },
        {
          heading: "GROW YOUR REVENUE",
          subs: [
            {
              title: "✓ Payments (U.S. only)",
              description: "Payments (U.S. only)"
            }
          ]
        }
      ]
    },
    {
      planName: "Professional",
      inAdditionTo: "Startup",
      icon: "",
      monthlyPrice: 400,
      haveAnnualPaymentDiscount: true,
      annualPaymentDiscount: 10,
      freeContactLimit: 2000,
      description: "",
      features: [
        {
          heading: "PERSONALIZE CUSTOMER EXPERIENCES",
          subs: [
            {
              title: "✓ Smart content & reporting",
              description: "Smart content & reporting"
            },
            {
              title: "✓ Dynamic personalization",
              description: "Dynamic personalization"
            },
            {
              title: "✓ Password-protected pages",
              description: "Password-protected pages"
            }
          ]
        },
        {
          heading: "OPTIMIZE YOUR WEBSITE",
          subs: [
            {
              title: "✓ Contact create attribution",
              description: "Contact create attribution"
            },
            {
              title: "✓ A/B testing",
              description: "A/B testing"
            },
            {
              title: "✓ Custom reporting",
              description: "Custom reporting"
            }
          ]
        },
        {
          heading: "EXPAND YOUR CONTENT STRATEGY",
          subs: [
            {
              title: "✓ SEO recommendations & optimizations",
              description: "SEO recommendations & optimizations"
            },
            {
              title: "✓ Content strategy",
              description: "Content strategy"
            },
            {
              title: "✓ Google Search Console integration",
              description: "Google Search Console integration"
            },
            {
              title: "✓ Content staging",
              description: "Content staging"
            }
          ]
        }
      ]
    },
    {
      planName: "Enterprise",
      inAdditionTo: "Professional",
      icon: "",
      monthlyPrice: 1200,
      haveAnnualPaymentDiscount: false,
      annualPaymentDiscount: 10,
      freeContactLimit: 10000,
      description: "",
      features: [
        {
          heading: "BUILD POWERFUL DIGITAL EXPERIENCES",
          subs: [
            {
              title: "✓ Custom objects",
              description: "Custom objects"
            },
            {
              title: "✓ Memberships",
              description: "Memberships"
            },
            {
              title: "✓ Web apps",
              description: "Web apps"
            },
            {
              title: "✓ Adaptive testing",
              description: "Adaptive testing"
            }
          ]
        },
        {
          heading: "MANAGE A GROWING TEAM AND BRAND",
          subs: [
            {
              title: "✓ Content partitioning",
              description: "Content partitioning"
            },
            {
              title: "✓ Hierarchical teams",
              description: "Hierarchical teams"
            },
            {
              title: "✓ Activity logging",
              description: "Activity logging"
            },
            {
              title: "✓ Site performance monitoring",
              description: "Site performance monitoring"
            },
            {
              title: "✓ Code alerts",
              description: "Code alerts"
            },
            {
              title: "✓ Single sign-on",
              description: "Single sign-on"
            }
          ]
        },
        {
          heading: "EXTEND YOUR DIGITAL PRESENCE",
          subs: [
            {
              title: "✓ Additional root domains",
              description: "Additional root domains"
            },
            {
              title: "✓ Multi-domain traffic reporting",
              description: "Multi-domain traffic reporting"
            }
          ]
        }
      ]
    }
  ],
  Operations: [
    {
      planName: "Startup",
      inAdditionTo: "Free Tools",
      icon: "",
      monthlyPrice: 50,
      haveAnnualPaymentDiscount: true,
      annualPaymentDiscount: 10,
      freeContactLimit: 1000,
      description: "",
      features: [
        {
          heading: "CONNECT TOOLS & SYNC CUSTOMER DATA",
          subs: [
            {
              title: "✓ Custom field mappings",
              description: "Custom field mappings"
            },
            {
              title: "✓ Custom properties",
              description: "Custom properties"
            }
          ]
        },
        {
          heading: "ORGANIZE YOUR OPERATIONS EFFORTS`",
          subs: [
            {
              title: "✓ Reporting dashboard",
              description: "Reporting dashboard"
            },
            {
              title: "✓ List segmentation",
              description: "List segmentation"
            },
            {
              title: "✓ Multiple currencies",
              description: "Multiple currencies"
            }
          ]
        },
        {
          heading: "GET HELP FAST",
          subs: [
            {
              title: "✓ Email and in-app chat support",
              description: "Email and in-app chat support"
            }
          ]
        },
        {
          heading: "GROW YOUR REVENUE",
          subs: [
            {
              title: "✓ Payments (U.S. only)",
              description: "Payments (U.S. only)"
            }
          ]
        }
      ]
    },
    {
      planName: "Professional",
      inAdditionTo: "Startup",
      icon: "",
      monthlyPrice: 800,
      haveAnnualPaymentDiscount: true,
      annualPaymentDiscount: 10,
      freeContactLimit: 2000,
      description: "",
      features: [
        {
          heading: "AUTOMATE YOUR BUSINESS PROCESSES",
          subs: [
            {
              title: "✓ Programmable automation",
              description: "Programmable automation"
            },
            {
              title: "✓ Webhooks",
              description: "Webhooks"
            },
            {
              title: "✓ Scheduled workflow triggers",
              description: "Scheduled workflow triggers"
            },
            {
              title: "✓ Custom-coded bot actions",
              description: "Custom-coded bot actions"
            }
          ]
        },
        {
          heading: "MONITOR AND CLEAN YOUR DATA",
          subs: [
            {
              title: "✓ Data quality command center",
              description: "Data quality command center"
            },
            {
              title: "✓ Data quality automation",
              description: "Data quality automation"
            },
            {
              title: "✓ AI-powered data formatting recommendations",
              description: "AI-powered data formatting recommendations"
            },
            {
              title: "✓ Data health trends",
              description: "Data health trends"
            },
            {
              title: "✓ Bulk duplicate management",
              description: "Bulk duplicate management"
            }
          ]
        },
        {
          heading: "MANAGE YOUR SCALING BUSINESS",
          subs: [
            {
              title: "✓ List segmentation",
              description: "List segmentation"
            },
            {
              title: "✓ Teams",
              description: "Teams"
            },
            {
              title: "✓ Required fields",
              description: "Required fields"
            },
            {
              title: "✓ Standard CRM interface configuration",
              description: "Standard CRM interface configuration"
            }
          ]
        },
        {
          heading: "BUILD CUSTOM REPORTS",
          subs: [
            {
              title: "✓ Custom reporting",
              description: "Custom reporting"
            },
            {
              title: "✓ Calculated properties",
              description: "Calculated properties"
            },
            {
              title: "✓ Standard contact scoring",
              description: "Standard contact scoring"
            },
            {
              title: "✓ Deal and company scoring",
              description: "Deal and company scoring"
            }
          ]
        }
      ]
    },
    {
      planName: "Enterprise",
      inAdditionTo: "Professional",
      icon: "",
      monthlyPrice: 2000,
      haveAnnualPaymentDiscount: false,
      annualPaymentDiscount: 10,
      freeContactLimit: 10000,
      description: "",
      features: [
        {
          heading: "CURATE & MANAGE DATA WITH EASE",
          subs: [
            {
              title: "✓ Datasets",
              description: "Datasets"
            },
            {
              title: "✓ Advanced data calculations and prep",
              description: "Advanced data calculations and prep"
            }
          ]
        },
        {
          heading: "CUSTOMIZE & EXTEND THE PLATFORM",
          subs: [
            {
              title: "✓ Custom objects",
              description: "Custom objects"
            },
            {
              title: "✓ Snowflake data share",
              description: "Snowflake data share"
            },
            {
              title: "✓ Advanced permissions",
              description: "Advanced permissions"
            },
            {
              title: "✓ Sandboxes",
              description: "Sandboxes"
            }
          ]
        }
      ]
    }
  ],
  Sales: [
    {
      planName: "Startup",
      inAdditionTo: "Free Tools",
      icon: "",
      monthlyPrice: 50,
      haveAnnualPaymentDiscount: true,
      annualPaymentDiscount: 10,
      freeContactLimit: 1000,
      description: "2 paid users included. $23/month per additional user.",
      features: [
        {
          heading: "REMOVE HUBSPOT BRANDING FROM",
          subs: [
            {
              title: "✓ Live chat",
              description: "Live chat"
            },
            {
              title: "✓ Meeting scheduling",
              description: "Meeting scheduling"
            },
            {
              title: "✓ Documents",
              description: "Documents"
            },
            {
              title: "✓ 1-to-1 email",
              description: "1-to-1 email"
            }
          ]
        },
        {
          heading: "STREAMLINE B2B BUYING",
          subs: [
            {
              title: "✓ Payments (U.S. only)",
              description: "Payments (U.S. only)"
            }
          ]
        },
        {
          heading: "ORGANIZE YOUR SALES PROCESS",
          subs: [
            {
              title: "✓ Conversation routing",
              description: "Conversation routing"
            },
            {
              title: "✓ Repeating tasks and task queues",
              description: "Repeating tasks and task queues"
            },
            {
              title: "✓ Multiple currencies",
              description: "Multiple currencies"
            },
            {
              title: "✓ Stripe integration",
              description: "Stripe integration"
            }
          ]
        },
        {
          heading: "OPERATE WITH EASE",
          subs: [
            {
              title: "✓ Simple automation",
              description: "Simple automation"
            },
            {
              title: "✓ Goals",
              description: "Goals"
            }
          ]
        },
        {
          heading: "GET HELP FAST",
          subs: [
            {
              title: "✓ Email and in-app chat support",
              description: "Email and in-app chat support"
            }
          ]
        }
      ]
    },
    {
      planName: "Professional",
      inAdditionTo: "Startup",
      icon: "",
      monthlyPrice: 500,
      haveAnnualPaymentDiscount: true,
      annualPaymentDiscount: 10,
      freeContactLimit: 2000,
      description: "5 paid users included. $100/month per additional user.",
      features: [
        {
          heading: "MANAGE YOUR GROWING TEAM",
          subs: [
            {
              title: "✓ Sales analytics",
              description: "Sales analytics"
            },
            {
              title: "✓ Teams",
              description: "Teams"
            },
            {
              title: "✓ Sales automation",
              description: "Sales automation"
            },
            {
              title: "✓ Standard CRM interface configuration",
              description: "Standard CRM interface configuration"
            },
            {
              title: "✓ Required fields",
              description: "Required fields"
            },
            {
              title: "✓ Custom reporting",
              description: "Custom reporting"
            },
            {
              title: "✓ Forecasting",
              description: "Forecasting"
            }
          ]
        },
        {
          heading: "PERSONALIZE YOUR OUTREACH AT SCALE",
          subs: [
            {
              title: "✓ Sequences",
              description: "Sequences"
            },
            {
              title: "✓ 1:1 video messaging",
              description: "1:1 video messaging"
            },
            {
              title: "✓ Smart send times",
              description: "Smart send times"
            },
            {
              title: "✓ ABM tools and automation",
              description: "ABM tools and automation"
            }
          ]
        },
        {
          heading: "CONFIGURE, PRICE & QUOTE",
          subs: [
            {
              title: "✓ Product library",
              description: "Product library"
            },
            {
              title: "✓ Calculated properties",
              description: "Calculated properties"
            },
            {
              title: "✓ eSignature",
              description: "eSignature"
            }
          ]
        },
        {
          heading: "GET ADVANCED SUPPORT",
          subs: [
            {
              title: "✓ Phone support",
              description: "Phone support"
            }
          ]
        }
      ]
    },
    {
      planName: "Enterprise",
      inAdditionTo: "Professional",
      icon: "",
      monthlyPrice: 1200,
      haveAnnualPaymentDiscount: false,
      annualPaymentDiscount: 10,
      freeContactLimit: 10000,
      description: "10 paid users included. $120/month per additional user.",
      features: [
        {
          heading: "CUSTOMIZE TO MATCH YOUR BUSINESS",
          subs: [
            {
              title: "✓ Custom objects",
              description: "Custom objects"
            },
            {
              title: "✓ Hierarchical teams",
              description: "Hierarchical teams"
            },
            {
              title: "✓ Sandboxes",
              description: "Sandboxes"
            }
          ]
        },
        {
          heading: "EXTEND ADMINISTRATIVE CONTROLS",
          subs: [
            {
              title: "✓ Advanced permissions",
              description: "Advanced permissions"
            }
          ]
        },
        {
          heading: "COACH & ENABLE AT SCALE",
          subs: [
            {
              title: "✓ Playbooks",
              description: "Playbooks"
            },
            {
              title: "✓ Conversation intelligence",
              description: "Conversation intelligence"
            },
            {
              title: "✓ Predictive lead scoring",
              description: "Predictive lead scoring"
            }
          ]
        }
      ]
    }
  ]
};
