import {
  DKContactIcon,
  DKIcon,
  DKIcons,
  DKLabel,
  DKLine,
  DKSearchBar,
  DKTooltipWrapper
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getTenantsDetails } from "../../redux/slices/booksSlice";
import { useDebounce } from "../../utility/Debounce";
import Utility from "../../utility/Utility";
import { SettingsOption } from "./SettingView";
import { setSelectedSetting } from "../../redux/slices/userPrefSlice";
import {
  isViewportLarge,
  isViewportMobile
} from "../../utility/GetViewportSize";
import { SETTING_SCREENS } from "../../constants/Constant";
interface SettingsLeftPanelProps {
  selectedSetting: any;
  data: SettingsOption[];
  currentScreen: any;
  onSettingSelected: (setting: any) => void;
}

const SettingsLeftPanel = (props: SettingsLeftPanelProps) => {
  const tenantInfo = useAppSelector(getTenantsDetails);
  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchText, 500);
  const [menuData, setMenuData] = useState(
    props.data ? props.data.filter((menuItem: any) => !menuItem.hidden) : []
  );
  const [expandedItemsMap, setExpandedItemsMap] = useState<{
    [key: string]: boolean;
  }>({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (debouncedSearchTerm) {
      let newMenuData = [...props.data]?.filter((setting: any) => {
        return (
          !setting.hidden &&
          (setting.title
            ?.toLowerCase()
            ?.includes(debouncedSearchTerm.toLowerCase()) ||
            setting.description
              ?.toLowerCase()
              ?.includes(debouncedSearchTerm.toLowerCase()) ||
            setting?.keywords
              ?.toString()
              ?.toLowerCase()
              ?.includes(debouncedSearchTerm.toLowerCase()))
        );
      });
      setMenuData(newMenuData);
    } else {
      setMenuData(
        props.data ? props.data.filter((menuItem: any) => !menuItem.hidden) : []
      );
    }
  }, [props.data, debouncedSearchTerm]);

  const getSettingTab = (setting: any, isChild = false) => {
    const isSelected =
      props.selectedSetting &&
      setting.propertyName === props.selectedSetting.propertyName;

    return (
      <div
        className={`column parent-width position-relative ${isViewportMobile() ? "p-h-xl p-v-r" : "p-h-r p-v-m "} cursor-hand select-none ${
          isSelected && !isViewportMobile() && "setting-selected"
        }`}
        onClick={(e: any) => {
          e.stopPropagation();
          dispatch(setSelectedSetting(null));
          props.onSettingSelected(setting);
          props.currentScreen(SETTING_SCREENS.SETTINGS_RIGHT_PANEL);
        }}
      >
        <div className="row justify-content-between">
          <DKLabel
            text={setting.title}
            className={`fs-r word-break ${isChild ? "pl-r" : ""} ${
              !isViewportMobile() &&
              (setting.propertyName === props.selectedSetting?.propertyName
                ? "fw-m"
                : "")
            }`}
          />
          {/* <DKIcon src="ic_arrow_up2"/> */}
          {isViewportMobile() && (
            <DKIcon src={DKIcons.ic_arrow_right2} className="ic-xs-2" />
          )}
        </div>
      </div>
    );
  };

  const toggleParentSetting = (setting: SettingsOption) => {
    setExpandedItemsMap({
      ...expandedItemsMap,
      [setting.title]: !!!expandedItemsMap[setting.title]
    });
  };

  return (
    <div className="parent-size overflow-y-hidden">
      <div
        className={`column parent-width ${isViewportMobile() ? "p-xl" : "p-l"}`}
        style={{ height: 151 }}
      >
        <DKLabel
          text="Settings"
          className={`row ${isViewportMobile() ? "fs-l" : ""} fw-m mb-l`}
        />
        <div className="row mb-l " style={{ lineHeight: "16px" }}>
          <DKSearchBar
            className={`settings-search-bar fs-r fw-r`}
            onSearch={(value: any) => {
              setSearchText(value);
            }}
            searchText={searchText}
            placeholder="Search..."
          />
        </div>
        <div className="row">
          <div className="column" style={{ width: 30 }}>
            <DKTooltipWrapper
              content={tenantInfo ? tenantInfo.name : ""}
              tooltipClassName="bg-deskera-secondary t-10 l-10"
              className="width-auto"
              gapFromCursorX={8}
              gapFromCursorY={8}
              tooltipStyle={{ width: "auto" }}
            >
              <DKContactIcon
                className="column bg-button text-white border-m "
                title={tenantInfo ? tenantInfo.name : ""}
              />
            </DKTooltipWrapper>
          </div>

          <DKLabel
            text={tenantInfo ? tenantInfo.name : ""}
            className="fs-r fw-m ml-r word-break overflow-ellipsis overflow-hidden"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical"
            }}
          />
        </div>
      </div>
      <DKLine className="mb-0 bg-gray2" />
      <div
        className={`column parent-width overflow-y-auto pb-l ${isViewportMobile() ? "bg-white" : "bg-gray1"} ${
          !menuData.length ? " align-items-center justify-content-center" : ""
        }`}
        style={{
          height: "calc(100% - 152px)"
          // background:
          //   "linear-gradient(#f4f4f6 30%, transparent) top/100% 40px no-repeat local,radial-gradient(farthest-side at top , rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)) top/100% 5px no-repeat",
        }}
      >
        {menuData.map((setting: any, index: any) => {
          if (Utility.isEmptyObject(setting.children)) {
            return getSettingTab(setting, false);
          } else {
            return (
              <>
                <div
                  className={`row position-relative p-h-s p-v-s justify-content-between cursor-hand select-none`}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    toggleParentSetting(setting);
                  }}
                  style={{ gap: 10 }}
                >
                  <DKLabel text={setting.title} className="fs-r word-break" />
                  <DKIcon
                    src={
                      !!expandedItemsMap[setting.title]
                        ? DKIcons.ic_arrow_up2
                        : DKIcons.ic_arrow_down2
                    }
                    className="ic-s opacity-50"
                  />
                </div>
                <div
                  className={`column parent-width ${
                    !!expandedItemsMap[setting.title]
                      ? "show-children"
                      : "hide-children"
                  }`}
                >
                  {!!expandedItemsMap[setting.title] &&
                    setting.children.map((setting: any, index: any) => {
                      return getSettingTab(setting, true);
                    })}
                </div>
              </>
            );
          }
        })}
        {!menuData.length && (
          <div className="text-gray-600">No Results Found</div>
        )}
      </div>
    </div>
  );
};
export default SettingsLeftPanel;
