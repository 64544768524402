import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IColumn } from "../../model/Table";
import { RootState } from "../store";
import CurrencyService from "../../services/currency";

const initialState: any = {
  data: {} as any,
  syncConfig: {} as any,
  currencyHistory: {} as any,
  columnConfig: [],
  currencyListWithExchangeRate: []
};

export const fetchCurrency = createAsyncThunk(
  "fetchCurrencyWithExchangeRate",
  async () => {
    const response = await CurrencyService.getCurrencyListWithExchangeRates();
    return response;
  }
);

export const fetchCurrencySyncConfig = createAsyncThunk(
  "fetchCurrencySyncConfig",
  async () => {
    const response = await CurrencyService.getCurrencySyncConfig();
    return response;
  }
);

export const fetchCurrencyHistroy = createAsyncThunk(
  "fetchCurrencyHistory",
  async () => {
    const response = await CurrencyService.getCurrencyHistrory();
    return response;
  }
);

export const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    addCurrencyColumnConfig: (state, action: PayloadAction<IColumn[]>) => {
      const configs = action.payload;
      let configsToStore: any[] = [];
      configs.sort((x: any, y: any) => x.index - y.index);
      configs.forEach((config) => {
        configsToStore.push({
          ...config,
          key: config.columnCode,
          options: config.options ? config.options : []
        });
      });
      state.columnConfig = configsToStore;
    },
    addCurrency: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
    clearCurrencyHistory: (state, action: PayloadAction<any[]>) => {
      state.currencyHistory = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrency.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchCurrencySyncConfig.fulfilled, (state, action) => {
        state.syncConfig = action.payload;
      })
      .addCase(fetchCurrencyHistroy.fulfilled, (state, action) => {
        state.currencyHistory = action.payload;
      });
  }
});

export const { addCurrency, addCurrencyColumnConfig, clearCurrencyHistory } =
  currencySlice.actions;
export const selectCurrency = (state: RootState) => state.currency.data;
export const selectCurrencySyncConfig = (state: RootState) =>
  state.currency.syncConfig;
export const selectCurrencyHistory = (state: RootState) =>
  state.currency.currencyHistory;
export const selectCurrencyColumnConfig = (state: RootState) =>
  state.currency.columnConfig;
export default currencySlice.reducer;
