import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import AppManager from "../../managers/AppManager";
import Utility from "../../utility/Utility";
import LandingPageService from "../../services/landingPage";
import React from "react";
import LandingPageHeader from "./LandingPageHeader";
import TemplateManager from "./templates/TemplateManager";
import thumbnail_1 from "../../assets/landingPageTemplates/thumbnail_1.png";
import thumbnail_2 from "../../assets/landingPageTemplates/thumbnail_2.png";
import thumbnail_3 from "../../assets/landingPageTemplates/thumbnail_3.png";
import thumbnail_4 from "../../assets/landingPageTemplates/thumbnail_4.png";
import thumbnail_5 from "../../assets/landingPageTemplates/thumbnail_5.png";
import thumbnail_6 from "../../assets/landingPageTemplates/thumbnail_6.png";
import thumbnail_7 from "../../assets/landingPageTemplates/thumbnail_7.png";
import thumbnail_8 from "../../assets/landingPageTemplates/thumbnail_8.png";
import thumbnail_9 from "../../assets/landingPageTemplates/thumbnail_9.png";
import thumbnail_10 from "../../assets/landingPageTemplates/thumbnail_10.png";
import thumbnail_0 from "../../assets/landingPageTemplates/thumbnail_0.jpeg";

import { showToast, TOAST_TYPE } from "deskera-ui-library";
import { PAGE_ELEMENT } from "../page_designer/ComponentList";
import ComponentManager from "../page_designer/ComponentManager";

export default class LandingPageManager {
  static pageData;
  static directLink = null;
  static selectedTemplateId = null;

  static status = {
    ACTIVE: [1],
    IN_ACTIVE: [2]
  };

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getAllCreationSteps() {
    const pageId = LandingPageManager.getPageRecordId()
      ? "/" + LandingPageManager.getPageRecordId()
      : "";
    return [
      {
        title: LandingPageManager.isPageDataAvailable()
          ? "Change Template"
          : "Select Template",
        onClick: () => {
          RouteManager.navigateToPage(
            PAGE_ROUTES.SELECT_LANDING_PAGE_TEMPLATE + pageId
          );
        }
      },
      {
        title: LandingPageManager.isPageDataAvailable()
          ? "Edit Design"
          : "Design",
        onClick: () => {
          if (LandingPageManager.isPageDataAvailable()) {
            RouteManager.navigateToPage(
              PAGE_ROUTES.LANDING_PAGE_BUILDER + pageId
            );
          } else {
            showToast("Please select template", TOAST_TYPE.FAILURE);
          }
        }
      },
      {
        title: LandingPageManager.isPageDataAvailable()
          ? "Publish Details"
          : "Publish",
        onClick: () => {
          if (LandingPageManager.isPageDataAvailable()) {
            RouteManager.navigateToPage(
              PAGE_ROUTES.LANDING_PAGE_DOMAIN + pageId
            );
          } else {
            showToast("Please save your design", TOAST_TYPE.FAILURE);
          }
        }
      }
    ];
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static isPageDataAvailable() {
    return !(
      LandingPageManager.pageData === null ||
      LandingPageManager.pageData === undefined
    );
  }

  static setPageData(response) {
    LandingPageManager.pageData = this.dataParseResponse(response);
  }

  static getPageData() {
    return LandingPageManager.pageData;
  }

  ////////////////////////////////////////////////////////////////////////////////////

  static getPageRecordId() {
    if (!LandingPageManager.isPageDataAvailable()) {
      return null;
    }

    return LandingPageManager.pageData.data._id;
  }

  static getPageCreatedBy() {
    if (!LandingPageManager.isPageDataAvailable()) {
      return null;
    }

    return LandingPageManager.pageData.data.cells.createdBy;
  }
  ////////////////////////////////////////////////////////////////////////////////////

  static getDirectLink() {
    if (!LandingPageManager.isPageDataAvailable()) {
      return "URL not found";
    }

    let directLink =
      LandingPageManager.pageData.data.cells[
        LandingPageManager.getColumnId(COLUMN_CODE.LANDING_PAGE.DIRECT_LINK)
      ];
    return AppManager.generateDirectLink(directLink);
  }

  static getPageStatus() {
    if (!LandingPageManager.isPageDataAvailable()) {
      return false;
    }

    let status =
      LandingPageManager.pageData.data.cells[
        LandingPageManager.getColumnId(COLUMN_CODE.LANDING_PAGE.STATUS)
      ];

    status = JSON.stringify(status);

    return status === "[1]";
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getPageJSONData() {
    if (!LandingPageManager.isPageDataAvailable()) {
      return [];
    }

    let jsonData =
      LandingPageManager.pageData.data.cells[
        LandingPageManager.getColumnId(COLUMN_CODE.LANDING_PAGE.JSON_DATA)
      ];

    return Utility.decodeJSON(jsonData);
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getRequestToSavePage(pageData) {
    let data = {};
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.NAME)] = pageData.name;
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.VISITORS)] = 0;
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.HTML_DATA)] = pageData.html;
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.JSON_DATA)] = pageData.json;
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.HASH)] = pageData.hash;
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.SLUG)] = pageData.slug;
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.STATUS)] =
      LandingPageManager.status.ACTIVE;
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.PUBLISHED)] = true;
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.CUSTOM_DOMAIN)] =
      pageData.domain;
    return data;
  }

  static getRequestToUpdatePage(pageData) {
    let data = {};
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.HTML_DATA)] = pageData.html;
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.JSON_DATA)] = pageData.json;
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.PUBLISHED)] = true;
    return data;
  }

  static getRequestToUpdateStatus(isActive) {
    let data = {};
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.STATUS)] =
      isActive === true
        ? LandingPageManager.status.ACTIVE
        : LandingPageManager.status.IN_ACTIVE;
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.PUBLISHED)] = true;
    return data;
  }

  static getRequestToUpdateDomain(pageData) {
    let data = {};
    data[this.getColumnId(COLUMN_CODE.LANDING_PAGE.CUSTOM_DOMAIN)] =
      pageData.domain;
    return data;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getColumnId(columnCode) {
    return TableManger.getColumnId(TABLES.LANDING_PAGE, columnCode);
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static fetchPageDataById(id, callback) {
    LandingPageService.getData(
      id,
      (response) => {
        LandingPageManager.setPageData({ data: response });
        callback && callback();
      },
      (error) => {
        Utility.alertWithRedirection(
          "Error!",
          "Record not found / You don't have permission",
          PAGE_ROUTES.LANDING_PAGE
        );
      }
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getHeader() {
    return (
      <LandingPageHeader title={"Landing Page Templates"} stepperIndex={0} />
    );
  }

  static navigateToBuilder() {
    RouteManager.navigateToPage(
      PAGE_ROUTES.LANDING_PAGE_BUILDER +
        (LandingPageManager.isPageDataAvailable()
          ? "/" + this.getPageRecordId()
          : "")
    );
  }

  static setSelectedTemplateId(id) {
    TemplateManager.setSelectedTemplateId(id);
  }

  static getAllTemplates() {
    return [
      thumbnail_0,
      thumbnail_1,
      thumbnail_2,
      thumbnail_3,
      thumbnail_4,
      thumbnail_5,
      thumbnail_6,
      thumbnail_7,
      thumbnail_8,
      thumbnail_9,
      thumbnail_10
    ];
  }

  static getSelectedTemplateId() {
    return TemplateManager.getSelectedTemplateId();
  }

  static dataParseResponse = (data) => {
    if (data === null) {
      return data;
    }
    let jsonData =
      data.data.cells[
        LandingPageManager.getColumnId(COLUMN_CODE.LANDING_PAGE.JSON_DATA)
      ];
    let json = Utility.decodeJSON(jsonData);
    json.designElements.forEach((component) => {
      if (component.type === PAGE_ELEMENT.COLLECTION) {
        component.value.forEach((list) => {
          list = this.collectionResponse(list, component);
        });
      }
      if (component.type === PAGE_ELEMENT.ICON) {
        component.value.forEach((list) => {
          list = this.collectionResponse(list, component);
        });
      }
      if (component.type === PAGE_ELEMENT.IMAGE_TEXT) {
        component.value.forEach((list) => {
          list = this.imageTextResponse(list, component);
        });
      }
    });
    data.data.cells[
      LandingPageManager.getColumnId(COLUMN_CODE.LANDING_PAGE.JSON_DATA)
    ] = Utility.encodeJSON(json);
    return data;
  };

  static collectionResponse = (data, component) => {
    if (typeof data.title === "string") {
      data.title = ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
        value: data.title,
        fontSize: component.style.titleSize,
        color: component.style.titleColor,
        fontWeight: "bold",
        paddingTop: 3,
        paddingBottom: 3
      });
    }
    if (
      component.type !== PAGE_ELEMENT.ICON &&
      typeof data.description === "string"
    ) {
      data.description = ComponentManager.getTextDefaultData(
        PAGE_ELEMENT.TEXT,
        {
          value: data.description,
          fontSize: component.style.descriptionSize,
          color: component.style.descriptionColor,
          paddingTop: 3,
          paddingBottom: 3
        }
      );
    }
    if (typeof data.icon === "string") {
      data.icon = ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
        value: data.icon,
        marginBottom: 0
      });
    }
    if (data.button === undefined) {
      data.button = ComponentManager.getButtonDefaultData(PAGE_ELEMENT.BUTTON, {
        fontSize: 12,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 7,
        paddingRight: 7,
        marginTop: 2,
        visibility: false
      });
    }
    return data;
  };

  static imageTextResponse = (data, component) => {
    let isExistingButtonNeedUpdate = false;
    if (typeof data.title === "string") {
      isExistingButtonNeedUpdate = true;
      data.title = ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
        value: data.title,
        fontSize: component.style.titleSize,
        color: component.style.titleColor,
        textAlign: component.style.textAlign,
        fontWeight: "bold"
      });
    }
    if (typeof data.description === "string") {
      data.description = ComponentManager.getTextDefaultData(
        PAGE_ELEMENT.TEXT,
        {
          value: data.description,
          fontSize: component.style.descriptionSize,
          color: component.style.descriptionColor,
          textAlign: component.style.textAlign
        }
      );
    }
    if (typeof data.image === "string") {
      data.image = ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
        value: data.image
      });
    }
    let buttonObj = {};
    if (data.button === undefined) {
      buttonObj = {
        fontSize: 14,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        width: "auto",
        alignSelf:
          component.style.textAlign === "left" ? "flex-start" : "flex-end",
        visibility: false
      };
      data.button = ComponentManager.getButtonDefaultData(
        PAGE_ELEMENT.BUTTON,
        buttonObj
      );
    } else if (isExistingButtonNeedUpdate) {
      buttonObj = {
        value: data.button.value,
        url: data.button.url,
        fontSize: 14,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        marginRight: component.style.textAlign === "right" ? 0 : "auto",
        marginLeft: component.style.textAlign === "left" ? 0 : "auto",
        marginTop: "auto",
        marginBottom: "auto",
        width: "auto",
        alignSelf:
          component.style.textAlign === "left" ? "flex-start" : "flex-end",
        visibility: data.button.visibility
      };
      data.button = ComponentManager.getButtonDefaultData(
        PAGE_ELEMENT.BUTTON,
        buttonObj
      );
    }
    return data;
  };

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
}
