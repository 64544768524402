import React, { Component } from "react";
import { DKLabel, isEmpty, DKIcon } from "deskera-ui-library";
import {
  COLUMN_CODE,
  TableManger,
  TABLES
} from "../../../managers/TableManger";
import { getDashboardWidgetLoading } from "../../dashboard/Dashboard";
import { connect, ConnectedProps } from "react-redux";
import { addRecord, setRecords } from "../../../redux/slices/recordSlice";
import { store } from "../../../redux/store";
import { fetchRecordsByTable } from "../../../redux/slices/recordSlice";
import CampaignManager from "../../campaign/CampaignManager";
import RouteManager, { PAGE_ROUTES } from "../../../managers/RouteManager";
import { USER_ACTION_TYPES } from "../../../constants/Permission";
import { isMobileAppWebView } from "../../../utility/GetViewportSize";
import { CAMPAIGN_TYPE } from "../../../constants/Enum";

import ic_email from "../../../assets/icons/ic_email.png";
import ic_whatsapp from "../../../assets/icons/ic_whatsapp.png";

interface ICampaignCardProps extends PropsFromRedux {
  onCountUpdated: (count: number) => void;
}
interface ICampaignCardState {
  data: any[];
  showLoading: boolean;
}
class CampaignCard extends Component<ICampaignCardProps, ICampaignCardState> {
  columnsIds = {
    name: "",
    visitors: "",
    status: "",
    clicks: "",
    campaignType: ""
  };
  campaignStatus = [];
  campaignTypes = [];
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showLoading: false
    };
  }
  componentDidMount() {
    this.setState({ showLoading: true });
    this.setCampaignColumnOptions();
    this.setColumnsIds();
    this.fetchRecords();
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.data) {
      this.onDataReceived(props.data);
    }
  }
  render() {
    return (
      <>
        {this.state.showLoading && getDashboardWidgetLoading()}
        {
          <>
            {!this.state.showLoading && this.state.data.length !== 0 && (
              <>{this.state.data.map((record) => this.getRow(record))}</>
            )}
          </>
        }
        {!this.state.showLoading &&
          this.state.data.length === 0 &&
          this.getEmptyState()}
      </>
    );
  }

  getRow(record) {
    const allowRowEdit = !!this.props.permissions[USER_ACTION_TYPES.REC_UPDATE];
    return (
      <div
        className="row pt-xs pb-s  justify-content-between listPickerBG border-radius-s"
        style={{
          cursor: allowRowEdit ? "pointer" : "default"
        }}
        onClick={() =>
          !isMobileAppWebView() && allowRowEdit
            ? this.onRowClick(record)
            : undefined
        }
      >
        <div className="row" style={{ width: "60%" }}>
          <div className="column parent-width">
            <div className="row">
              <DKLabel
                text={`${record[this.columnsIds.name]}`}
                className=""
                style={{
                  overflowWrap: "break-word",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              />
            </div>
            <div className="row" style={{ gap: 8 }}>
              {record[this.columnsIds.status] !== undefined &&
                record[this.columnsIds.status].length > 0 &&
                this.getCampaignStatusBadge(record[this.columnsIds.status][0])}
              <DKIcon
                src={
                  record[this.columnsIds.campaignType]?.[0] ===
                  CAMPAIGN_TYPE.WHATSAPP
                    ? ic_whatsapp
                    : ic_email
                }
                className="ic-s ml-xs mt-xs"
              />
              {/* {this.getCampaignTypeBadge(record[this.columnsIds.campaignType]?.[0] || CAMPAIGN_TYPE.EMAIL)} */}
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <DKLabel
            text={`Visitors: ${record[this.columnsIds.visitors]}`}
            className="text-gray p-v-xs"
          />
          <DKLabel
            text={`Clicks: ${record[this.columnsIds.clicks]}`}
            className="text-orange p-v-xs ml-l"
          />
        </div>
      </div>
    );
  }

  fetchRecords(refresh = false) {
    let data = this.props.data;
    if (refresh || isEmpty(data)) {
      store.dispatch(fetchRecordsByTable({ tableName: TABLES.CAMPAIGN }));
    } else {
      this.onDataReceived(data);
    }
  }
  onDataReceived = (data) => {
    if (this.props.onCountUpdated) this.props.onCountUpdated(data.totalCount);
    this.setState({
      data: data.data.map((record) => {
        return { ...record.cells, id: record._id };
      }),
      showLoading: false
    });
  };

  setColumnsIds = () => {
    this.columnsIds.name = TableManger.getColumnId(
      TABLES.CAMPAIGN,
      COLUMN_CODE.CAMPAIGN.NAME
    );
    this.columnsIds.visitors = TableManger.getColumnId(
      TABLES.CAMPAIGN,
      COLUMN_CODE.CAMPAIGN.OPEN
    );
    this.columnsIds.clicks = TableManger.getColumnId(
      TABLES.CAMPAIGN,
      COLUMN_CODE.CAMPAIGN.CLICKS
    );
    this.columnsIds.status = TableManger.getColumnId(
      TABLES.CAMPAIGN,
      COLUMN_CODE.CAMPAIGN.STATUS
    );
    this.columnsIds.campaignType = TableManger.getColumnId(
      TABLES.CAMPAIGN,
      COLUMN_CODE.CAMPAIGN.CAMPAIGN_TYPE
    );
  };
  getEmptyState = () => {
    return (
      <div
        className={`column parent-width parent-height align-items-center justify-content-center`}
      >
        <DKLabel text="No data found" className="fw-m fs-m text-gray" />
        <DKLabel text="Start a campaign here" className="text-gray mt-xs" />
      </div>
    );
  };
  setCampaignColumnOptions = () => {
    this.campaignStatus = TableManger.getColumn(
      TABLES.CAMPAIGN,
      COLUMN_CODE.CAMPAIGN.STATUS
    ).options;

    this.campaignTypes =
      TableManger.getColumn(TABLES.CAMPAIGN, COLUMN_CODE.CAMPAIGN.CAMPAIGN_TYPE)
        ?.options || [];
  };
  getCampaignStatusBadge = (id) => {
    const status = this.campaignStatus.find((status) => status.id === id);
    return (
      <DKLabel
        className={`p-v-xs p-h-s border-radius-r mt-s ${status.color}`}
        style={{
          whiteSpace: "nowrap"
        }}
        text={status.name}
      />
    );
  };
  getCampaignTypeBadge = (id) => {
    const campaignTypeData = this.campaignTypes.find((type) => type.id === id);
    return (
      <DKLabel
        className={`p-v-xs p-h-s border-radius-r mt-s ${campaignTypeData?.color}`}
        text={campaignTypeData?.name}
      />
    );
  };
  onRowClick = (record) => {
    let data = JSON.parse(JSON.stringify(record));
    let data_formatted: any = {};
    data_formatted.data = {
      _id: data.id,
      cells: data
    };
    data_formatted.id = data.id;
    CampaignManager.setPageData(data_formatted);
    let route = PAGE_ROUTES.CAMPAIGN_BUILDER;
    if (CampaignManager.isWhatsAppCampaign()) {
      route = PAGE_ROUTES.WA_CAMPAIGN;
    } else if (CampaignManager.checkCampaignInDraft()) {
      route = PAGE_ROUTES.CAMPAIGN_SUMMARY;
    }
    RouteManager.navigateToPage(`${route}/${CampaignManager.getCampaignId()}`);
  };
}

const mapStateToProps = (state) => ({
  data: state.records.data[TABLES.CAMPAIGN],
  permissions:
    state.rolesPermission?.currentUserModuleWisePermissions?.[
      TABLES.CAMPAIGN
    ] || {}
});
const mapDispatchToProps = { addRecord, setRecords };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(CampaignCard);
