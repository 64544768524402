import { DEFAULT_CURRENCY_CODE } from "../constants/Constant";
import { CURRENCY_SYMBOLS } from "../constants/Currencies";
import { CURRENCY } from "../constants/Enum";
import { store } from "../redux/store";
import UserManager from "./UserManager";

export default class TenantManager {
  static allTenants = null;
  static tenantDetails = null;
  static currency = null;

  static setAllTenants(data) {
    TenantManager.allTenants = data;
  }
  static setTenantDetails(data) {
    TenantManager.tenantDetails = data;
    this.setCurrencySymbol();
  }
  static getTenantDetails() {
    return TenantManager.tenantDetails;
  }
  static setCurrencySymbol() {
    TenantManager.currency =
      CURRENCY_SYMBOLS[
        UserManager.getUserCurrency()
          ? UserManager.getUserCurrency()
          : DEFAULT_CURRENCY_CODE
      ];
  }
  /**
   * @description - this function gives you the currency symbol from the tenant currency selected at the time of setting up the tenant
   * @returns - currency symbol for the current tenant defaults to USD
   */
  static getCurrencySymbol() {
    return TenantManager.currency ?? CURRENCY_SYMBOLS[DEFAULT_CURRENCY_CODE];
  }
  static getCRMCurrencyCode() {
    const currencyCode =
      store.getState()?.tenant.crmSettings?.currency ||
      UserManager.getUserCurrency();
    return currencyCode;
  }
  static getCRMCurrencySymbol = () =>
    CURRENCY_SYMBOLS[this.getCRMCurrencyCode()];
  static isGTSRegistered = () =>
    !!store.getState()?.books?.tenantsDetails?.gstRegistered;
  static isComplianceEnabled = () =>
    store.getState().books?.tenantsDetails?.complianceEnabled;
}
