import { Fragment } from "react";
import ReactDOMServer from "react-dom/server";

import { store } from "../../redux/store";
import Utility from "../../utility/Utility";
import { URL_V1 } from "../../constants/Constant";
import ApiConstants from "../../constants/ApiConstants";

import { PAGE_TYPE, PAGE_ELEMENT, PAGE_SIZE } from "./ComponentList";
import Text from "./canvas_components/Text";
import Button from "./canvas_components/Button";
import Image from "./canvas_components/Image";
import VideoSection from "./canvas_components/VideoSection";
import Social from "./canvas_components/Social";
import Collection from "./canvas_components/Collection";
import ImageText from "./canvas_components/ImageText";
import Form from "./canvas_components/Form";
import OrderForm from "./canvas_components/OrderForm";
import CustomHtml from "./canvas_components/CustomHtml";
import ImageGallery from "./canvas_components/ImageGallery";
import ColumnSection from "./canvas_components/ColumnSection";
import CountDownTimer from "./canvas_components/CountDownTimer";
import CampaignFooter from "./canvas_components/CampaignFooter";
import VStack from "./canvas_components/VStack";
import Logo from "./canvas_components/Logo";
import { localFontFamily } from "./FontFamilyComponent";

export default class HtmlGenerator {
  constructor() {
    this.pageType = null;
    this.viewType = null;
    this.buttonURL = null;
    this.campaignAddress = null;
  }

  getHTML(
    components,
    script,
    metaData,
    hash,
    pageType,
    buttonURL = null,
    isTestMail = false,
    tableName
  ) {
    this.pageType = pageType;
    this.viewType = PAGE_SIZE.TABLET;
    this.buttonURL = buttonURL;

    const htmlString = ReactDOMServer.renderToStaticMarkup(
      <html>
        <head>
          <meta charSet="utf-8" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <link rel="icon" href={this.getFaviconUrl(metaData)} />
          <title>{this.getTitle(metaData)}</title>
          {this.pageType !== PAGE_TYPE.CAMPAIGN && (
            <script async src={ApiConstants.FORM_SUBMISSION_URL} />
          )}
          {this.pageType !== PAGE_TYPE.CAMPAIGN && (
            <script async src={ApiConstants.LOAD_SCRIPTS_URL} />
          )}
          {this.getOrderFormScripts(components)}
          <style>{`@media screen and (max-width: 600px) { .mobile-view { width: 100% !important; } .mobile-hide {display:none !important;} .mobile-margin-x-auto{margin-left: auto !important; margin-right: auto !important; }}`}</style>
          <style>{`
          #root {
            position: fixed;
            bottom: 10px;
            right: 10px;
          }
          .dk-crm-form-submission .input-file-type
            {
              background: transparent !important;
              border: none !important;
              padding: 4px 0px !important;
            }
          `}</style>
          {this.addScriptToHTML(script?.head)}
          {this.getFontFamilyStyleSheet(components, pageType)}
        </head>
        <body style={{ margin: 0 }}>
          <div
            id="dk-page-designer-html-div"
            data-trackinfo={
              this.pageType !== PAGE_TYPE.CAMPAIGN
                ? this.getTrackInfo(hash)
                : null
            }
            data-metadata={this.getMetadata(metaData)}
            style={{
              padding: this.getPaddingForHTML(),
              boxSizing: "border-box",
              backgroundColor: this.getBGColorForHTML(),
              margin: "auto",
              textAlign: "center",
              fontSize: 13,
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Arial", "Roboto","Helvetica Neue", sans-serif'
            }}
          >
            <div
              style={{
                boxSizing: "border-box",
                width: this.getWidthForHTMLCanvas(),
                maxWidth: this.getMaxWidthForHTMLCanvas(),
                backgroundColor: "#ffffff",
                margin: "auto",
                textAlign: "center",
                padding: this.getPaddingForHTMLCanvas()
                // backgroundImage: `url(
                //   https://i.ibb.co/RD02XgX/Screenshot-2021-05-28-at-7-09-16-PM.png
                // )`,
              }}
            >
              {this.renderAllComponents(components, hash, tableName)}
            </div>

            {this.pageType === PAGE_TYPE.CAMPAIGN && (
              <CampaignFooter
                isEditing={false}
                campaignAddress={this.campaignAddress}
                unsubscribe={isTestMail}
              />
            )}
          </div>
          <div id="root"></div>
          {this.addScriptToHTML(script?.body)}
        </body>
      </html>
    );

    return htmlString;
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getBGColorForHTML() {
    if (
      this.pageType === PAGE_TYPE.CAMPAIGN ||
      this.pageType === PAGE_TYPE.FORM
    ) {
      return "rgb(235, 239, 243)";
    }
    return "rgb(255, 255, 255)";
  }

  getPaddingForHTML() {
    if (this.pageType === PAGE_TYPE.FORM) {
      return 15;
    }

    return 0;
  }

  getPaddingForHTMLCanvas() {
    if (
      this.pageType === PAGE_TYPE.CAMPAIGN ||
      this.pageType === PAGE_TYPE.FORM
    ) {
      return 15;
    }

    return 0;
  }

  getMaxWidthForHTMLCanvas() {
    if (
      this.pageType === PAGE_TYPE.CAMPAIGN ||
      this.pageType === PAGE_TYPE.FORM
    ) {
      return 700;
    }

    return 1440;
  }

  getWidthForHTMLCanvas() {
    if (this.pageType === PAGE_TYPE.CAMPAIGN) {
      return "100%";
    } else if (this.pageType === PAGE_TYPE.FORM) {
      return "90%";
    }
    return "100%";
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getTrackInfo(hash) {
    return JSON.stringify({
      hash: hash,
      baseURL: ApiConstants.URL.BASE + URL_V1
    });
  }

  getPageScript(script) {
    if (script !== null && script !== undefined) {
      return JSON.stringify(script);
    }
    return "";
  }

  getMetadata(metaData) {
    if (metaData !== null && metaData !== undefined) {
      return JSON.stringify(metaData);
    }
    return "";
  }

  getTitle(metaData) {
    let title = "Deskera Pages & Forms";
    if (metaData && !Utility.isEmpty(metaData.title)) {
      title = metaData.title;
    }
    return title;
  }

  getFaviconUrl(metaData) {
    let faviconUrl = "https://crm-ui-dev.deskera.xyz/favicon.ico";
    if (metaData && !Utility.isEmpty(metaData.faviconUrl)) {
      faviconUrl = metaData.faviconUrl;
    }
    return faviconUrl;
  }

  getOrderFormScripts(components) {
    const hasOrderForm = components.some(
      (component) => component.type === PAGE_ELEMENT.ORDER_FORM
    );

    if (hasOrderForm) {
      return (
        <>
          <script async src={ApiConstants.STRIPE_CDN}></script>
          <script async src={ApiConstants.RAZORPAY_CDN}></script>
          <script async src={ApiConstants.CASHFREE_CDN}></script>
          <script async src={ApiConstants.ORDER_FORM_SUBMISSION_URL}></script>
        </>
      );
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  addScriptToHTML(scriptData) {
    try {
      if (scriptData?.trim().length) {
        const doc = new DOMParser().parseFromString(scriptData, "text/html");

        const scriptElements = doc.getElementsByTagName("script");
        const noScriptElements = doc.getElementsByTagName("noscript");
        const linkElements = doc.getElementsByTagName("link");

        return (
          <Fragment>
            {Array.from(scriptElements).map((scriptElement) =>
              scriptElement.src ? (
                <script
                  src={scriptElement.src}
                  async={scriptElement.async}
                  onLoad={scriptElement.onload}
                ></script>
              ) : (
                <script
                  onLoad={scriptElement.onload}
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html: scriptElement.innerHTML
                  }}
                ></script>
              )
            )}
            {Array.from(noScriptElements).map((noscriptElement) => (
              <noscript
                dangerouslySetInnerHTML={{
                  __html:
                    noscriptElement?.ownerDocument.activeElement.childNodes[0]
                      ?.textContent
                }}
              ></noscript>
            ))}
            {Array.from(linkElements).map((linkElement) => (
              <link
                href={linkElement.href}
                async={linkElement.async}
                rel={linkElement.rel}
              ></link>
            ))}
          </Fragment>
        );
      }
    } catch (err) {}
  }
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  renderAllComponents(components, hash, tableName) {
    let arr = [];
    components.forEach((data, index) => {
      arr.push(this.getComponent(data, index, hash, tableName));
    });
    return arr;
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getComponent(data, index, hash, tableName) {
    return (
      <div
        key={index}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: data.style ? data.style.alignSelf : null
        }}
      >
        {data.type === PAGE_ELEMENT.TEXT && <Text data={data} />}

        {data.type === PAGE_ELEMENT.BUTTON && (
          <Button data={this.getButtonData(data)} isEditing={false} />
        )}

        {data.type === PAGE_ELEMENT.IMAGE && <Image data={data} />}
        {data.type === PAGE_ELEMENT.VIDEO && (
          <VideoSection data={data} pageType={this.pageType} />
        )}

        {data.type === PAGE_ELEMENT.SOCIAL_BUTTONS && (
          <Social data={data} isEditing={false} />
        )}

        {(data.type === PAGE_ELEMENT.COLLECTION ||
          data.type === PAGE_ELEMENT.ICON) && (
          <Collection data={data} isEditing={false} />
        )}

        {data.type === PAGE_ELEMENT.LOGO && (
          <Logo data={data} isEditing={false} />
        )}

        {data.type === PAGE_ELEMENT.IMAGE_TEXT && (
          <ImageText data={data} viewType={this.viewType} isEditing={false} />
        )}

        {data.type === PAGE_ELEMENT.FORM && (
          <Form
            data={this.getFormData(data)}
            hash={hash}
            isEditing={false}
            viewType={this.viewType}
          />
        )}

        {data.type === PAGE_ELEMENT.ORDER_FORM && (
          <OrderForm
            data={this.getFormData(data)}
            hash={hash}
            moduleType={tableName}
            isStripeConnected={store.getState().userPref.isStripeConnected}
            paymentAccounts={store.getState().userPref.paymentAccounts}
          />
        )}

        {data.type === PAGE_ELEMENT.CUSTOM_HTML && <CustomHtml data={data} />}

        {data.type === PAGE_ELEMENT.IMAGE_GALLERY && (
          <ImageGallery index={index} data={data} />
        )}

        {data.type === PAGE_ELEMENT.COLUMN_SECTION && (
          <ColumnSection
            index={index}
            data={data}
            hash={hash}
            isEditing={false}
            viewType={this.viewType}
            pageType={this.pageType}
          />
        )}

        {data.type === PAGE_ELEMENT.COUNTDOWN_TIMER && (
          <CountDownTimer index={index} data={data} />
        )}

        {data.type === PAGE_ELEMENT.V_STACK && (
          <VStack
            index={index}
            data={data}
            hash={hash}
            isEditing={false}
            viewType={this.viewType}
            pageType={this.pageType}
          />
        )}
      </div>
    );
  }

  getFormData(data) {
    let buttonData = data;
    if (this.buttonURL) {
      buttonData.redirectURL = "." + this.buttonURL + ".html";
    }
    return buttonData;
  }

  getButtonData(data) {
    let buttonData = data;
    if (this.buttonURL) {
      buttonData.url = "." + this.buttonURL + ".html";
    }
    return buttonData;
  }

  getFontFamilyList = (componentData) => {
    if (Utility.isEmptyObject(componentData)) return [];

    let fontFamilies = new Set();

    if (Array.isArray(componentData)) {
      componentData.forEach((componentItem) => {
        const itemFontFamilies = this.getFontFamilyList(componentItem);
        itemFontFamilies.forEach((family) => fontFamilies.add(family));
      });
    } else {
      switch (componentData.type) {
        case PAGE_ELEMENT.IMAGE_TEXT:
        case PAGE_ELEMENT.COLLECTION:
          componentData.value?.forEach((item) => {
            const titleFontFamilies = this.getFontFamilyList(item?.title);
            const descriptionFontFamilies = this.getFontFamilyList(
              item?.description
            );

            titleFontFamilies.forEach((family) => fontFamilies.add(family));
            descriptionFontFamilies.forEach((family) =>
              fontFamilies.add(family)
            );
          });
          break;
        case PAGE_ELEMENT.COLUMN_SECTION:
          componentData.value?.forEach((column) => {
            const columnFontFamilies = this.getFontFamilyList(column?.value);
            columnFontFamilies.forEach((family) => fontFamilies.add(family));
          });
          break;
        case PAGE_ELEMENT.V_STACK:
          return this.getFontFamilyList(componentData.value);
        default:
          const fontFamily = componentData.style?.fontFamily;
          fontFamilies.add(fontFamily);
          break;
      }
    }

    return Array.from(fontFamilies);
  };

  getFontFamilyStyleSheet = (components, pageType) => {
    /* Only building font stylesheet url from google fonts, to avoid api errors */
    let fontListString = this.getFontFamilyList(components)
      .filter((family) => family && !localFontFamily.includes(family))
      .map((family) => family.replaceAll(" ", "+"))
      .join("|");

    return !Utility.isEmptyObject(fontListString) &&
      pageType !== PAGE_TYPE.CAMPAIGN ? (
      <link
        href={ApiConstants.URL.FONT.GOOGLE(fontListString)}
        rel="stylesheet"
      />
    ) : null;
  };
}
