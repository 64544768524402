import {
  COLUMN_CODE,
  TableManger as TableManager,
  TableManger,
  TABLES
} from "../managers/TableManger";
import Table from "./table";
import { showLoader, removeLoader } from "deskera-ui-library";

class FormService {
  static getContactData(isDefault) {
    let ignoreColumns = [
      COLUMN_CODE.CONTACT.TYPE,
      COLUMN_CODE.CONTACT.SEGMENT,
      COLUMN_CODE.CONTACT.STATUS,
      COLUMN_CODE.CONTACT.LABEL,
      COLUMN_CODE.CONTACT.PROFILE_PIC,
      COLUMN_CODE.CONTACT.CREATED_AT,
      COLUMN_CODE.CONTACT.OWNER_ID,
      COLUMN_CODE.CONTACT.SUB_OWNER_ID,
      COLUMN_CODE.CONTACT.ACCOUNT,
      COLUMN_CODE.CONTACT.DETAILED_ADDRESS
    ];
    let defaultColumns = [
      COLUMN_CODE.CONTACT.NAME,
      COLUMN_CODE.CONTACT.PHONE,
      COLUMN_CODE.CONTACT.EMAIL
    ];
    let allContactColumns = TableManger.getTableColumns(TABLES.CONTACT);
    let filteredColumns = (allContactColumns || []).filter(
      (item) => item.uiVisible === true
    );
    if (isDefault) {
      return (filteredColumns || [])
        .filter((item) => defaultColumns.includes(item.columnCode))
        .map((item) => ({
          ...item,
          required: item.requiredByUser ?? item.required
        }));
    } else {
      return (filteredColumns || []).filter(
        (item) => !ignoreColumns.includes(item.columnCode)
      );
    }
  }

  static getContactFieldsForOrder() {
    let defaultColumns = [
      COLUMN_CODE.CONTACT.NAME,
      COLUMN_CODE.CONTACT.EMAIL,
      COLUMN_CODE.CONTACT.PHONE
    ];
    let filteredColumns = TableManger.getTableFilteredColumns(
      TABLES.CONTACT,
      (column) => defaultColumns.includes(column.columnCode),
      true
    );
    return filteredColumns.map((column) => ({ ...column, required: true }));
  }

  static getContactDataForLandingPages() {
    let defaultColumns = [COLUMN_CODE.CONTACT.NAME, COLUMN_CODE.CONTACT.EMAIL];
    let allContactColumns = TableManger.getTableColumns(TABLES.CONTACT);
    let filteredColumns = (allContactColumns || []).filter(
      (item) => item.uiVisible === true
    );
    return (filteredColumns || []).filter((item) =>
      defaultColumns.includes(item.columnCode)
    );
  }

  static save(data: any, onSuccess: any, onError: any) {
    showLoader("Saving... Please wait");
    const record = {
      cells: data
    };
    Table.addRecord(record, TableManager.getTableId(TABLES.FORM)).then(
      (response) => {
        removeLoader();
        onSuccess(response);
      },
      (error) => {
        removeLoader();
        onError(error);
      }
    );
  }

  static update(data: any, recordId: any, onSuccess: any, onError: any) {
    showLoader("Updating... Please wait");
    Table.updateRecord(
      data,
      recordId,
      TableManager.getTableId(TABLES.FORM)
    ).then(
      (response) => {
        removeLoader();
        onSuccess(response);
      },
      (error) => {
        removeLoader();
        onError(error);
      }
    );
  }

  static getData(recordId: string, onSuccess: any, onError: any) {
    showLoader("Fetching... Please wait");
    Table.getRecordById(recordId, TableManager.getTableId(TABLES.FORM)).then(
      (response) => {
        removeLoader();
        onSuccess(response);
      },
      (error) => {
        removeLoader();
        onError(error);
      }
    );
  }
}

export default FormService;
