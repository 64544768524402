import React, { Component } from "react";

import { DKLabel, DKListPicker, DKIcon, DKIcons } from "deskera-ui-library";

class DKDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needListPicker: false
    };
  }
  render() {
    return (
      <div
        className="row width-auto align-items-center position-relative cursor-hand"
        onClick={() => {
          this.setState({
            needListPicker: true
          });
        }}
      >
        <DKLabel text={this.props.title} className="text-gray mr-s" />
        <DKIcon
          src={
            this.state.needListPicker
              ? DKIcons.ic_arrow_up
              : DKIcons.ic_arrow_down
          }
          className=" ic-xs"
          style={{ opacity: 0.6 }}
        />
        {this.state.needListPicker && (
          <DKListPicker
            data={this.props.data}
            className="position-absolute shadow-m border-radius-s border-s z-index-2"
            style={{
              right: this.props?.style?.right || -4,
              top: this.props?.style?.top || 0,
              width: this.props.width ? this.props.width : 110
            }}
            onSelect={(data) => {
              setTimeout(() => {
                this.setState({
                  needListPicker: false
                });
              }, 10);
              if (this.props.onSelect) {
                this.props.onSelect(data);
              }
            }}
            onClose={() => {
              this.setState({
                needListPicker: false
              });
            }}
          />
        )}
      </div>
    );
  }
}

export default DKDropdown;
