import { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  DKInput,
  DKLabel,
  DKButton,
  DKIcons,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showAlert,
  showLoader,
  removeLoader
} from "deskera-ui-library";
import Popup from "../common/Popup";

import Utility, { getCapitalized } from "../../utility/Utility";
import ApiConstants from "../../constants/ApiConstants";

import { store } from "../../redux/store";
import { INVITE_USER_STATUS } from "../../constants/Permission";
import { getFullName } from "../../model/User";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import JustdialService, { LEAD_APP_NAME } from "../../services/indiamart";
import { JUST_DIAL_LEAD_DATA_KEYS } from "../../constants/Enum";
import { showAddColumnPopup } from "../common/AddColumnPopup";
import Table from "../../services/table";

interface IJustDialLeadSyncSetting {
  justDialAccount?: any;
  popupId?: string;
  onSave: (response?: any) => void;
  onClose: () => void;
}

const RESTRICTED_CONTACT_COLUMNS_FOR_MAPPING = [
  COLUMN_CODE.CONTACT.SEGMENT,
  COLUMN_CODE.CONTACT.STATUS,
  COLUMN_CODE.CONTACT.ACCOUNT,
  COLUMN_CODE.CONTACT.OWNER_ID,
  COLUMN_CODE.CONTACT.SUB_OWNER_ID,
  COLUMN_CODE.CONTACT.ATTACHMENT,
  COLUMN_CODE.CONTACT.TYPE
];

type IFormField = {
  id: string;
  title: string;
  value: any;
  placeholder: string;
  type: string;
  required: boolean;
  payloadKey: string;
  isMappingField?: boolean;
  fieldKey?: string;
  isDynamic?: boolean;
  dynamicValue?: string;
  options?: any[];
  hidden?: boolean;
  tooltip?: {
    content: string;
    className: string;
    style: any;
  };
  allowNewField?: boolean;
};

const JustDialLeadSyncSetting = (props: IJustDialLeadSyncSetting) => {
  const [saveTapped, setSaveTapped] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const contactColumnsForMap = getContactColumnsToMap();

  const formFields = [
    {
      id: "webServiceUrl",
      title: "CRM key",
      value: "",
      payloadKey: "apiKey",
      placeholder: "Enter JustDial account CRM Key",
      type: INPUT_TYPE.TEXT,
      hidden: true,
      required: false,
      tooltip: {
        content: `If you already have an account registered with <a href="${ApiConstants.URL.JUSTDIAL.SELLER_ACCOUNT_PROVIDER}" target="_blank">JustDial</a>. Please provide this web service URL to your JustDial provider and request them to POST lead data on it. Once the JustDial team confirms the configuration at their end, you leads will start to appear in Contacts page.`,
        className: "bg-deskera-secondary",
        style: {}
      }
    },
    {
      id: "segmentId",
      title: "Segment",
      value: [],
      isMappingField: true,
      payloadKey: "fields",
      isDynamic: false,
      fieldKey: TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.SEGMENT
      ),
      placeholder: "Select segments",
      type: INPUT_TYPE.MULTI_SELECT,
      options:
        TableManger.getColumn(TABLES.CONTACT, COLUMN_CODE.CONTACT.SEGMENT)
          ?.options || [],
      required: true,
      tooltip: {
        content: `Leads synced from JustDial to CRM will get tagged to selected segments.`,
        className: "bg-deskera-secondary",
        style: {}
      }
    },
    {
      id: "ownerId",
      title: "Owners",
      value: [],
      isMappingField: true,
      payloadKey: "fields",
      isDynamic: false,
      fieldKey: TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.OWNER_ID
      ),
      placeholder: "Select owners",
      type: INPUT_TYPE.MULTI_SELECT,
      options: (store.getState().tenant.users || [])
        .filter((user) => user.status === INVITE_USER_STATUS.JOINED)
        .map((user) => ({
          id: user.iamUserId,
          name: getFullName(user)
        })),
      required: false,
      tooltip: {
        content: `One of the selected users will get assigned as an owner, to leads received from IndiaMart, in a round robin manner.`,
        className: "bg-deskera-secondary",
        style: {}
      }
    },
    ...generateFieldConfigWithJustDialKeys([
      JUST_DIAL_LEAD_DATA_KEYS.NAME,
      JUST_DIAL_LEAD_DATA_KEYS.EMAIL,
      JUST_DIAL_LEAD_DATA_KEYS.MOBILE,
      JUST_DIAL_LEAD_DATA_KEYS.CATEGORY,
      JUST_DIAL_LEAD_DATA_KEYS.AREA,
      JUST_DIAL_LEAD_DATA_KEYS.CITY,
      JUST_DIAL_LEAD_DATA_KEYS.PINCODE,
      JUST_DIAL_LEAD_DATA_KEYS.PHONE,
      JUST_DIAL_LEAD_DATA_KEYS.BRANCH_AREA,
      JUST_DIAL_LEAD_DATA_KEYS.BRANCH_PINCODE,
      JUST_DIAL_LEAD_DATA_KEYS.COMPANY,
      JUST_DIAL_LEAD_DATA_KEYS.DATE
    ])
  ];

  const [formData, setFormData] = useState<IFormField[]>(formFields);
  const [urlCopied, setUrlCopied] = useState(false);

  useEffect(() => {
    if (Utility.isEmptyObject(props.justDialAccount)) return;

    const updatedFormData = formData.map((formField) => {
      let value;

      if (formField.isDynamic) {
        const fieldData = props.justDialAccount.fields?.find(
          (field) => field.value === formField.dynamicValue
        );
        value = fieldData?.key ? [fieldData.key] : [];
      } else if (formField.isMappingField) {
        const fieldData = props.justDialAccount.fields?.find(
          (field) => field.key === formField.fieldKey
        );
        value = fieldData?.value;
      } else {
        value = props.justDialAccount[formField.payloadKey];
      }

      value = (value ?? formField.value) as boolean | string | any[];

      return {
        ...formField,
        value: value
      };
    });

    setFormData(updatedFormData);
  }, []);

  function getContactColumnsToMap() {
    return TableManger.getTableFilteredColumns(
      TABLES.CONTACT,
      (column) =>
        !column.hidden &&
        !RESTRICTED_CONTACT_COLUMNS_FOR_MAPPING.includes(column.columnCode) &&
        (column.editable ||
          [COLUMN_CODE.CONTACT.NOTE].includes(column.columnCode))
    ).map((column) => ({
      ...column,
      name: `Contact.${getCapitalized(column.name.toLowerCase())}`
    }));
  }
  /* *************** Popup save & close utils ***************** */
  const onSaveTapped = () => {
    setSaveTapped(true);

    let isValid = true;
    const accountData: any = {};

    formData.forEach((element) => {
      if (element.required && Utility.isEmptyObject(element.value)) {
        isValid = false;
      }

      if (element.isDynamic) {
        accountData.fields = accountData.fields || [];
        accountData.fields.push({
          key: (element.value || "").toString(),
          value: element.dynamicValue,
          isDynamic: true
        });
      } else if (element.isMappingField) {
        accountData.fields = accountData.fields || [];
        accountData.fields.push({
          key: element.fieldKey,
          value: element.value,
          isDynamic: false
        });
      } else {
        accountData[element.payloadKey] = element.value;
      }
    });

    if (!isValid) return;

    setFormSubmitting(true);

    showLoader("Saving connection settings...");

    JustdialService.updateAccountConfig(accountData, LEAD_APP_NAME.JUSTDIAL)
      .then((res) => {
        const { success, data } = res;

        if (!success) {
          showAlert(
            "Failed to save settings",
            data?.error?.message ||
              "Something went wrong while connecting JustDial account, please try again."
          );
          return;
        }

        removePopUp();

        showAlert(
          `Integration settings saved!`,
          `Your JustDial account integration settings has been saved.<br> Please provide below web service URL to your JustDial provider and request to POST lead data on it. Once the JustDial team confirms the configuration at their end, you leads will start to appear in Contacts page.<br><br><em>${
            ApiConstants.URL.BASE
          }${ApiConstants.URL.JUSTDIAL.WEB_SERVICE_URL(data.apiKey)}</em>`
        );
        props.onSave?.();
      })
      .catch((err) => {
        showAlert(
          "Failed to save settings",
          err?.message ||
            "Something went wrong while JustDial account integration, please try again."
        );
      })
      .finally(() => {
        setFormSubmitting(false);
        removeLoader();
      });
  };

  const onDeleteTapped = () => {
    showLoader("Deleting JustDial integration settings...");

    JustdialService.deleteAccountConfig(props.justDialAccount._id)
      .then((res) => {
        removePopUp();
        setFormData(formFields);
        showAlert(
          "JustDial settings deleted!",
          "Your JustDial settings has been removed and web service url is deactivated successfully."
        );
        props.onSave?.();
      })
      .catch((err) => {
        showAlert(
          "Failed to delete settings",
          err?.message ||
            "Something went wrong while deleting your JustDial settings, please try again."
        );
      })
      .finally(() => {
        removeLoader();
      });
  };

  const removePopUp = () => {
    if (props.popupId)
      ReactDOM.unmountComponentAtNode(document.getElementById(props.popupId));

    document.getElementById(props.popupId)?.remove();

    props.onClose && props.onClose();
  };

  const handleFormValueChange = (
    fieldId: string,
    value: string | boolean | number[]
  ) => {
    const updatedFormData = Utility.makeCopyOfObject(formData);
    const fieldToUpdate = updatedFormData.find((field) => field.id === fieldId);
    fieldToUpdate.value = value;
    setFormData(updatedFormData);
  };

  /* *********************RENDERERS******************* */
  function getJustDialFieldTitle(key: JUST_DIAL_LEAD_DATA_KEYS) {
    let title = getCapitalized(key);
    switch (key) {
      case JUST_DIAL_LEAD_DATA_KEYS.BRANCH_AREA:
        title = "Branch area";
        break;
      case JUST_DIAL_LEAD_DATA_KEYS.BRANCH_PINCODE:
        title = "Branch pincode";
        break;
      default:
    }

    return title;
  }
  function generateFieldConfigWithJustDialKeys(
    justDialPayloadKeys: JUST_DIAL_LEAD_DATA_KEYS[]
  ) {
    return justDialPayloadKeys.map((key) => ({
      id: key,
      title: getJustDialFieldTitle(key),
      value: [],
      isMappingField: true,
      payloadKey: "fields",
      isDynamic: true,
      dynamicValue: key,
      placeholder: "Select contact field",
      type: INPUT_TYPE.SELECT,
      options: contactColumnsForMap,
      required: [JUST_DIAL_LEAD_DATA_KEYS.NAME].includes(key),
      allowNewField: true
    }));
  }
  const getAddFieldConfig = (fieldId: string) => ({
    title: "+ New Field",
    className: "bg-button text-white",
    onClick: () => {
      showAddColumnPopup(
        {
          tableName: TABLES.CONTACT
        },
        async (response) => {
          const tableData = await Table.getTable(
            TableManger.getTableId(TABLES.CONTACT)
          );
          TableManger.setTableColumns(tableData, TABLES.CONTACT);

          const contactColumnsForMap = getContactColumnsToMap();
          const updatedFormData = formData.map((formField) => {
            let value = formField.value;

            if (formField.id === fieldId && response?.data?.id) {
              value = [response.data.id];
            }

            return {
              ...formField,
              value: value,
              options: formField.isDynamic
                ? contactColumnsForMap
                : formField.options
            };
          });
          setFormData(updatedFormData as any);
        }
      );
    }
  });

  function getButtons() {
    return (
      <div className="row align-items-center parent-width mt-m">
        <DKButton
          className="border-m mr-r bg-button text-white"
          title="Save"
          disabled={formSubmitting}
          onClick={onSaveTapped}
        />

        {!Utility.isEmptyObject(props.justDialAccount) && (
          <DKButton
            title="Delete Account"
            className="border-m bg-red text-white"
            onClick={() => onDeleteTapped()}
          />
        )}
      </div>
    );
  }

  function getHeader() {
    return (
      <div className="row align-items-center justify-content-between parent-width">
        <DKLabel
          text={
            Utility.isEmptyObject(props.justDialAccount?.apiKey)
              ? "Connect with JustDial"
              : "JustDial integration settings"
          }
          className="row fw-m"
        />
        <div className="row-reverse action-btn-wrapper">
          <DKButton
            className="border-m ml-r bg-button text-white"
            title="Save"
            disabled={formSubmitting}
            onClick={onSaveTapped}
          />
          <DKButton
            className="border-m bg-white"
            title="Cancel"
            onClick={removePopUp}
          />
        </div>
      </div>
    );
  }
  function getFormInput(formInput) {
    switch (formInput.type) {
      case INPUT_TYPE.SELECT:
        const selectedValue = formInput.options.find(
          (option: any) => option.id === formInput.value?.[0]
        );
        return (
          <DKInput
            title={formInput.title}
            placeholder={formInput.placeholder}
            value={selectedValue}
            type={INPUT_TYPE.DROPDOWN}
            required={formInput.required}
            displayKey={"name"}
            dropdownConfig={{
              data: formInput.options,
              allowSearch: true,
              searchableKey: "name",
              onSelect: () => {},
              renderer: (index: number, option: any) => (
                <DKLabel text={option.name} />
              ),
              button: formInput.allowNewField
                ? getAddFieldConfig(formInput.id)
                : null
            }}
            className={`p-0`}
            direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
            onChange={(newValue: any) =>
              handleFormValueChange(
                formInput.id,
                newValue?.id ? [newValue.id] : []
              )
            }
            tooltip={formInput.tooltip}
            errorMessage={" "}
            canValidate={saveTapped}
          />
        );
      case INPUT_TYPE.MULTI_SELECT:
        const selectedIndices = formInput.value
          .map((id: number) =>
            formInput.options.findIndex((option: any) => option.id === id)
          )
          .filter((index: number) => index !== -1);
        const MAX_VALUE_TO_DISPLAY = 2;
        return (
          <DKInput
            title={formInput.title}
            placeholder={formInput.placeholder}
            value={selectedIndices}
            renderer={(indices: number[]) => (
              <div className="row width-auto flex-wrap" style={{ gap: 5 }}>
                {(indices || [])
                  .slice(0, MAX_VALUE_TO_DISPLAY)
                  .map((index: number) => {
                    return (
                      <DKLabel
                        className="bg-white border-radius-s p-h-xs shadow-s-2"
                        text={formInput.options[index]?.name || ""}
                      />
                    );
                  })}
                {indices.length > MAX_VALUE_TO_DISPLAY && (
                  <DKLabel
                    className="text-app"
                    text={`+ ${indices.length - MAX_VALUE_TO_DISPLAY} more`}
                  />
                )}
              </div>
            )}
            type={INPUT_TYPE.DROPDOWN}
            required={formInput.required}
            displayKey={"name"}
            dropdownConfig={{
              data: formInput.options,
              allowSearch: true,
              searchableKey: "name",
              multiSelect: true,
              checkMarkColor: "bg-button",
              onSelect: () => {},
              renderer: (index: number, option: any) => (
                <DKLabel text={option.name} />
              )
            }}
            className={`p-0`}
            direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
            onChange={(newSelectedIndices: number[]) => {
              const newSelectedIds = [];
              newSelectedIndices.forEach((index: number) => {
                if (!formInput.options[index]) return;
                newSelectedIds.push(formInput.options[index].id);
              });
              handleFormValueChange(formInput.id, newSelectedIds);
            }}
            tooltip={formInput.tooltip}
            errorMessage={" "}
            canValidate={saveTapped}
          />
        );
      default:
        return (
          <DKInput
            title={formInput.title}
            placeholder={formInput.placeholder}
            value={formInput.value}
            type={formInput.type}
            required={formInput.required}
            className={`p-0`}
            direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
            onChange={(value: any) =>
              handleFormValueChange(formInput.id, value)
            }
            tooltip={formInput.tooltip}
            errorMessage={" "}
            canValidate={saveTapped}
            autoFocus={false}
          />
        );
    }
  }
  function getForm() {
    const mappingFields = formData.filter(
      (fields) => fields.isMappingField && !fields.isDynamic
    );
    const dynamicMappingFields = formData.filter(
      (fields) => fields.isMappingField && fields.isDynamic
    );
    return (
      <Fragment>
        <div
          className="column parent-width p-r border-radius-s bg-gray1"
          style={{ gap: 12 }}
        >
          <DKLabel
            text={"Select segments & owner for JustDial leads"}
            className="fw-m"
          />
          {mappingFields.map(getFormInput)}
        </div>
        <div
          className="column parent-width p-v-r border-radius-s bg-gray1"
          style={{ gap: 12 }}
        >
          <div className="column p-h-r parent-width" style={{ gap: 12 }}>
            <DKLabel
              text={"Map JustDial fields with Deskera contact fields"}
              className="fw-m"
            />
            <div className="row">
              <DKLabel
                text={"JustDial fields"}
                className="fw-m text-gray"
                style={{
                  width: "50%"
                }}
              />
              <DKLabel
                text={"Deskera fields"}
                className="fw-m text-gray"
                style={{
                  width: "50%"
                }}
              />
            </div>
          </div>
          <div className="column p-h-r parent-width" style={{ gap: 12 }}>
            {dynamicMappingFields.map(getFormInput)}
          </div>
        </div>
      </Fragment>
    );
  }
  function getWebServiceUrlSection() {
    if (Utility.isEmptyObject(props.justDialAccount?.apiKey)) return null;

    const webServiceUrl = `${
      ApiConstants.URL.BASE
    }${ApiConstants.URL.JUSTDIAL.WEB_SERVICE_URL(
      props.justDialAccount?.apiKey
    )}`;
    return (
      <div
        className="column parent-width p-r border-radius-s bg-gray1"
        style={{ gap: 12 }}
      >
        <DKLabel text={"Web service url"} className="fw-m" />
        <DKLabel
          text={
            "Please provide this web service URL to your JustDial provider and request them to POST lead data on it."
          }
          className="text-gray"
        />
        <DKLabel
          text={webServiceUrl}
          className={"bg-white border-m border-radius-s p-s"}
        />
        <DKButton
          title={urlCopied ? "Copied" : "Copy url"}
          icon={DKIcons.ic_copy}
          isReversed={true}
          style={{ padding: 0 }}
          className="cursor-hand bg-transparent border-none"
          onClick={() => {
            setUrlCopied(true);
            Utility.copyToClipBoard(webServiceUrl);
          }}
        />
      </div>
    );
  }
  function getBody() {
    return (
      <div className="column parent-width mt-l" style={{ gap: 16 }}>
        {getWebServiceUrlSection()}
        {getForm()}
      </div>
    );
  }
  function getFooter() {
    return Utility.isEmptyObject(props.justDialAccount) ? (
      <div className="row mt-l">
        <DKLabel text={"Don't have an account?"} />
        <DKButton
          title="Know more"
          className="text-blue text-underline"
          style={{
            padding: "0 4px"
          }}
          onClick={() =>
            Utility.openInNewTab(
              ApiConstants.URL.JUSTDIAL.SELLER_ACCOUNT_PROVIDER
            )
          }
        />
      </div>
    ) : null;
  }
  return (
    <div className="column parent-width overflow-y-auto">
      {/* {getHeader()} */}
      {getBody()}
      {getFooter()}
      {getButtons()}
    </div>
  );
};

export default JustDialLeadSyncSetting;

export const showJustDialLeadSyncSetting = (
  config: { justDialAccount?: any },
  onSave?: () => {},
  onClose?: () => {}
) => {
  const id = `data-source-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "justdial-lead-sync-popup app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <JustDialLeadSyncSetting
      {...config}
      popupId={id}
      onSave={onSave}
      onClose={onClose}
    />,
    document.body.appendChild(div)
  );
};
