import { showLoader, removeLoader } from "deskera-ui-library";
import AppManager from "../managers/AppManager";
import ApiConstants from "../constants/ApiConstants";
import UserManager from "./UserManager";
import {
  commonCustomEvent,
  COMMON_EVENTS,
  newThreadCustomEvent
} from "../services/event";
import Utility, { parseJWTToken } from "../utility/Utility";
import {
  decodeBase64Uri,
  NOTIFICATION_REFRESH_TIME,
  WEBSOCKET_READY_STATE,
  WS_EVENT_TYPE
} from "../constants/Constant";
import { store } from "../redux/store";
import {
  fetchChatSettings,
  getNotification,
  updateNotifications
} from "../redux/slices/userPrefSlice";
import { TICKET_EVENT_TYPES } from "../constants/Enum";
import {
  fetchTenantDetails,
  fetchTenantsInfo
} from "../redux/slices/booksSlice";
import { fetchLoggedInUserRole } from "../redux/slices/rolesPermissionSlice";
import User from "../services/user";
import debounce from "../utility/Debounce";
import { fetchCRMSettings, fetchUserInfo } from "../redux/slices/tenantSlice";
import { showAddNewTenantPopup } from "../components/common/AddNewTenant";
import AudioService from "../services/common/audio";
import { PAGE_ROUTES } from "./RouteManager";
import PermissionService from "../services/common/permission";
import { TABLES, TableManger } from "./TableManger";
import { USER_ACTION_TYPES } from "../constants/Permission";
import TimeoutManager from "./TimeoutManager";

export default class ApiManager {
  static audioService;
  static wsConnection;
  static tokenReceived(accessToken, emailVerified) {
    let token = parseJWTToken(accessToken);
    ApiConstants.ACCESS_TOKEN = accessToken;

    // Set token expiry time
    TimeoutManager.setTokenExpiryTime(token.exp);

    UserManager.setUserDetails({
      ...User.getUserObjectFromToken(token),
      emailVerified
    });
    store.dispatch(fetchCRMSettings({}));
    store.dispatch(fetchTenantDetails()).then((res) => {
      const response = res?.payload;
      if (
        !Utility.isEmptyObject(response) &&
        !response["isDemoOrg"] &&
        !response["orgSetupCompleted"]
      ) {
        showAddNewTenantPopup(
          { tenantEdit: response },
          () => {},
          () => {}
        );
      }
      UserManager.setUserTenantName(response?.name);
    });
    store.dispatch(fetchTenantsInfo(UserManager.getUserTenantID()));
    store.dispatch(fetchLoggedInUserRole());
    store.dispatch(fetchUserInfo({ params: { id: UserManager.getUserID() } }));
    AppManager.userLoggedIn();
    try {
      ApiManager.openWsConnection();
    } catch (error) {
      console.log("Unable to setup websocket connection", error);
    }
  }
  static getUploadFileURL(
    fileData,
    success,
    failed,
    fileEntity = "REPORT_THUMBNAIL"
  ) {
    showLoader("Uploading....");
    let url = ApiConstants.URL.BASE + ApiConstants.URL.FILES.UPLOAD;
    let inputData = new FormData();
    inputData.append("file", fileData);
    inputData.append("file-entity", fileEntity);

    let requestOptions = {
      method: "POST",
      credentials: "include",
      withCredentials: true,
      mode: "cors",
      body: inputData
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData && responseData.publicUrl) {
          success(responseData.publicUrl);
          removeLoader();
        } else {
          failed({ errorMessage: "Please try again." });
        }
      })
      .catch((error) => {
        removeLoader();
        failed({ errorMessage: "Please try again." });
      });
  }

  static getPublicPageJSONData(id, onSuccess, OnError) {
    showLoader("Loading....");
    let url =
      ApiConstants.URL.BASE + ApiConstants.URL.PUBLIC_PAGE.GET_JSON_DATA(id);

    let requestOptions = {
      method: "GET"
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData) {
          onSuccess(responseData);
        } else {
          OnError({ errorMessage: "Please try again." });
        }
      });
  }

  static openWsConnection = () => {
    ApiManager.wsConnection = new WebSocket(ApiConstants.WS_URL);
    ApiManager.wsConnection.addEventListener("open", function () {
      console.log(`[${new Date().toISOString()}] connection started`);
      if (WEBSOCKET_READY_STATE.OPEN === ApiManager.wsConnection.readyState) {
        ApiManager.wsConnection.send(
          JSON.stringify({
            wsUserId: `${UserManager.getUserIamID()}`,
            eventType: WS_EVENT_TYPE.NEW_CONNECTION
          })
        );
        ApiManager.wsConnection.addEventListener("close", function (event) {
          ApiManager.stopWsPing();
          if (event.code !== 1001) {
            ApiManager.startWsConnectionWithRetry(
              ApiConstants.WS_CONNECTION_RETRY_INTERVAL,
              1
            );
          }
        });
        ApiManager.wsConnection.addEventListener("error", function (event) {
          ApiManager.stopWsPing();
          ApiManager.startWsConnectionWithRetry(
            ApiConstants.WS_CONNECTION_RETRY_INTERVAL,
            1
          );
        });
        ApiManager.startWsPing();
        ApiManager.listenToWsMessages();
      }
    });
  };

  static startWsConnectionWithRetry = function (retryIntervalInMs, retryCount) {
    if (retryCount >= ApiConstants.WS_CONNECTION_RETRY_LIMIT) {
      return;
    }

    ApiManager.openWsConnection();
    if (
      retryCount % ApiConstants.WS_CONNECTION_RETRY_INTERVAL_DOUBLING_COUNT ===
      0
    ) {
      retryIntervalInMs *= 2;
    }
    ApiManager.retryIntervalInMs = retryIntervalInMs;
    ApiManager.retryCount = retryCount;
    setTimeout(function () {
      if (
        ApiManager.wsConnection &&
        ApiManager.wsConnection.readyState ===
          ApiConstants.WEBSOCKET_READYSTATE.CONNECTING
      ) {
        ApiManager.startWsConnectionWithRetry(
          ApiManager.retryIntervalInMs,
          ApiManager.retryCount
        );
        return;
      }
      if (
        ApiManager.wsConnection &&
        ApiManager.wsConnection.readyState ===
          ApiConstants.WEBSOCKET_READYSTATE.OPEN
      ) {
        return;
      }
      ApiManager.retryCount++;
      ApiManager.startWsConnectionWithRetry(
        ApiManager.retryIntervalInMs,
        ApiManager.retryCount
      );
    }, ApiManager.retryIntervalInMs);
  };

  static startWsPing = function () {
    ApiManager.wsPingIntervalId = setInterval(function ping() {
      if (
        ApiManager.wsConnection.readyState !==
        ApiConstants.WEBSOCKET_READYSTATE.OPEN
      ) {
        console.log(
          `[${new Date().toISOString()}] not sending ping to server because connection is not in open state`
        );
        return;
      }
      console.log(`[${new Date().toISOString()}] sending ping to server`);
      ApiManager.wsConnection.send(
        JSON.stringify({
          wsUserId: `${UserManager.getUserIamID()}`,
          eventType: "_ping_",
          message: "_ping_"
        })
      );
    }, ApiConstants.WS_PING_FREQUENCY);
  };

  static stopWsPing = function () {
    if (!ApiManager.wsPingIntervalId) {
      return;
    }
    clearInterval(ApiManager.wsPingIntervalId);
    ApiManager.wsPingIntervalId = null;
  };

  /** Check tenantId in event message,
   * if not available then will proceed AS-IS,
   * if available & not matched with current tenantId,
   * then we will discard the notification */
  static isWSMessageFromAnotherTenant = (eventMessage) => {
    try {
      if (Utility.isEmptyObject(eventMessage)) return false;

      /* Event message can be base64 encoded, or can be in stringified JSON Format */
      if (!Utility.isJson(eventMessage)) {
        eventMessage = decodeBase64Uri(eventMessage);
      }

      if (Utility.isJson(eventMessage)) {
        eventMessage = JSON.parse(eventMessage);
      }

      const eventTenantId = Number(eventMessage?.tenantId);

      if (eventTenantId && eventTenantId !== UserManager.getUserTenantID()) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  static listenToWsMessages = () => {
    try {
      ApiManager.wsConnection.addEventListener("message", function (event) {
        let eventData = decodeBase64Uri(event.data);
        if (Utility.isJson(eventData)) {
          eventData = JSON.parse(eventData);
          if (ApiManager.isWSMessageFromAnotherTenant(eventData?.message))
            return;
          switch (eventData.eventType) {
            case WS_EVENT_TYPE.PONG:
              return;
            case WS_EVENT_TYPE.NEW_CHAT_THREAD:
            case WS_EVENT_TYPE.NEW_CHAT_MESSAGE:
              try {
                const userHasChatAccess =
                  PermissionService.getInstance().isUserPermitted(
                    TABLES.CHAT_MESSAGE,
                    [USER_ACTION_TYPES.REC_VIEW]
                  );
                if (!userHasChatAccess) return;
              } catch (err) {}

              if (Utility.isEmptyObject(ApiManager.audioService)) {
                ApiManager.fetchChatSettings();
              }
              if (window.location.pathname !== PAGE_ROUTES.CHAT)
                ApiManager.audioService?.play();
              if (eventData.eventType === WS_EVENT_TYPE.NEW_CHAT_THREAD) {
                newThreadCustomEvent.dispatch(
                  COMMON_EVENTS.NEW_THREAD,
                  event.data
                );
              } else {
                commonCustomEvent.dispatch(COMMON_EVENTS.NEW_MSG, eventData);
              }
              break;
            case WS_EVENT_TYPE.CHAT_THREAD_CLOSED:
              commonCustomEvent.dispatch(
                COMMON_EVENTS.THREAD_CLOSED,
                eventData
              );
              break;
            case WS_EVENT_TYPE.BELL_NOTIFICATION:
              ApiManager.onBellNotificationReceive(eventData);
              break;
            case WS_EVENT_TYPE.SEQUENCE_COLUMN_CREATED:
              ApiManager.onSequenceColumnAdd();
              break;
            default:
              break;
          }
        }
      });
    } catch (error) {}
  };

  static refreshNotifications = debounce(() => {
    store.dispatch(getNotification());
  }, NOTIFICATION_REFRESH_TIME);

  static onBellNotificationReceive = (eventData) => {
    ApiManager.refreshNotifications();
    const data = decodeBase64Uri(eventData.message);
    if (Utility.isJson(data)) {
      const parsedData = JSON.parse(data);
      store.dispatch(
        updateNotifications({
          notification: parsedData
        })
      );
      switch (parsedData.objectType) {
        case TICKET_EVENT_TYPES.TICKET:
        case TICKET_EVENT_TYPES.SUPPORT_EMAIL:
          commonCustomEvent.dispatch(COMMON_EVENTS.NEW_TICKET, parsedData);
          break;
        default:
          break;
      }
    }
  };
  static fetchChatSettings = async () => {
    let settings;
    if (!Utility.isEmptyObject(store.getState().userPref?.chatSettings)) {
      settings = store.getState().userPref?.chatSettings;
    } else {
      let response = await store.dispatch(fetchChatSettings({}));
      settings = response.payload;
    }
    try {
      if (settings?.notificationSound) {
        const fileName = `${settings.notificationSound}.mp3`;
        const audio = await import(`../assets/audio/${fileName}`);
        ApiManager.audioService = new AudioService({ url: audio?.default });
        ApiManager.audioService?.play();
      }
      return Promise.resolve(settings);
    } catch (error) {
      return Promise.reject(error);
    }
  };
  static onSequenceColumnAdd = () => {
    TableManger.refreshTable(TABLES.CONTACT);
  };
}
