import AppManager from "../../managers/AppManager";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import { CampaignService } from "../../services/campaign";
import Utility from "../../utility/Utility";
import { showLoader, removeLoader } from "deskera-ui-library";

import CampaignPageHeader from "./CampaignPageHeader";
import TemplateManager from "./templates/TemplateManager";
import template_1 from "../../assets/campaignTemplates/Thumbnail-Newsletter-1.png";
import template_2 from "../../assets/campaignTemplates/Thumbnail-Newsletter-2.png";
import template_3 from "../../assets/campaignTemplates/Thumbnail-Newsletter-3.png";
import template_4 from "../../assets/campaignTemplates/Thumbnail-Newsletter-4.png";
import template_5 from "../../assets/campaignTemplates/Thumbnail-Newsletter-5.png";
import template_6 from "../../assets/campaignTemplates/Thumbnail-Newsletter-6.png";
import template_7 from "../../assets/campaignTemplates/Thumbnail-Newsletter-7.png";
import template_8 from "../../assets/campaignTemplates/Thumbnail-Newsletter-8.png";
import template_9 from "../../assets/campaignTemplates/Thumbnail-Newsletter-9.png";
import template_10 from "../../assets/campaignTemplates/Thumbnail-Newsletter-10.png";

import { showToast, TOAST_TYPE } from "deskera-ui-library";
import { PAGE_ELEMENT } from "../page_designer/ComponentList";
import ComponentManager from "../page_designer/ComponentManager";
import { CAMPAIGN_STATUS, CAMPAIGN_TYPE } from "../../constants/Enum";

export default class CampaignManager {
  static pageData = null;
  static templateData = null;
  static campaignId = null;
  static campaignFooter = false;
  static campaignType = CAMPAIGN_TYPE.EMAIL;

  static status = {
    DRAFT: [CAMPAIGN_STATUS.DRAFT],
    RUNNING: [CAMPAIGN_STATUS.RUNNING],
    SENT: [CAMPAIGN_STATUS.SENT],
    SCHEDULED: [CAMPAIGN_STATUS.SCHEDULED],
    CANCELED: [CAMPAIGN_STATUS.CANCELED],
    FAILED: [CAMPAIGN_STATUS.FAILED],
    SCHEDULED_NOW: [CAMPAIGN_STATUS.SCHEDULED_NOW],
    PARTIALLY_SENT: [CAMPAIGN_STATUS.PARTIALLY_SENT]
  };

  static campaignActivate = "Campaign Activated";
  static campaignSent = "Campaign Sent";
  static yourCampaignStarted = "Your campaign has started just now";
  static yourCampaignSent = "Your campaign has been sent";
  static yourCampaignActivatedAndStarted =
    "Your campaign has been activated and started successfully.";

  /* *************** Email Campaign Create/View Steps ****************** */
  static getAllCreationSteps() {
    const pageId = CampaignManager.getCampaignId()
      ? "/" + CampaignManager.getCampaignId()
      : "";
    let steps = [
      {
        title: CampaignManager.isCampaignEditable() ? "Edit Design" : "Design",
        onClick: () => {
          if (CampaignManager.isPageDataAvailable()) {
            RouteManager.navigateToPage(PAGE_ROUTES.CAMPAIGN_BUILDER + pageId);
          } else {
            showToast("Please select template", TOAST_TYPE.FAILURE);
          }
        }
      },
      {
        title: CampaignManager.isCampaignEditable()
          ? "Edit Summary"
          : "Summary",
        onClick: () => {
          if (CampaignManager.isPageDataAvailable()) {
            RouteManager.navigateToPage(PAGE_ROUTES.CAMPAIGN_SUMMARY + pageId);
          } else {
            showToast("Please save design to proceed", TOAST_TYPE.FAILURE);
          }
        }
      },
      {
        title: CampaignManager.isPageDataAvailable()
          ? "Publish Details"
          : "Send",
        onClick: () => {
          if (
            CampaignManager.isPageDataAvailable() &&
            !this.checkCampaignInDraft()
          ) {
            RouteManager.navigateToPage(PAGE_ROUTES.CAMPAIGN_SUCCESS + pageId);
          } else {
            showToast("Please save summary to proceed", TOAST_TYPE.FAILURE);
          }
        }
      }
    ];
    const templateSelectionStep = {
      title:
        CampaignManager.isPageDataAvailable() &&
        !CampaignManager.checkCampaignSent()
          ? "Change Template"
          : "Select Template",
      onClick: () => {
        RouteManager.navigateToPage(
          PAGE_ROUTES.SELECT_CAMPAIGN_TEMPLATE + pageId
        );
      }
    };

    if (CampaignManager.isCampaignEditable()) {
      steps.splice(0, 0, templateSelectionStep);
    }

    return steps;
  }

  /* *************** Email Campaign template Utils ****************** */
  static getTemplateHTMLData = () => {
    return this.templateData;
  };

  static setTemplateHTMLData = (data) => {
    this.templateData = data;
  };

  /* *************** Edit/View Campaign Utils ****************** */
  static getCampaignId = () => {
    if (!CampaignManager.isPageDataAvailable()) {
      return;
    }
    let campaignId = CampaignManager.pageData.id;
    return campaignId;
  };

  static getCampaignCreatedBy() {
    if (!CampaignManager.isPageDataAvailable()) {
      return;
    }

    return CampaignManager.pageData.data.cells.createdBy;
  }

  static setPageData(response) {
    this.pageData = this.dataParseResponse(response);

    if (!Utility.isEmptyObject(response?.data?.cells)) {
      const campaignType =
        this.pageData.data.cells[
          CampaignManager.getColumnId(COLUMN_CODE.CAMPAIGN.CAMPAIGN_TYPE)
        ]?.[0] ?? CAMPAIGN_TYPE.EMAIL;
      CampaignManager.setCampaignType(campaignType);
    }
  }

  static getPageData() {
    return this.pageData || "";
  }

  static getTemplateJSONData() {
    const campaignData = CampaignManager.getPageData();
    return Utility.decodeJSON(
      campaignData.data.cells[
        CampaignManager.getColumnId(COLUMN_CODE.CAMPAIGN.JSON_DATA)
      ]
    );
  }

  static getPublishData() {
    const campaignData = CampaignManager.getPageData();

    if (!campaignData) return {};

    const publishData = {};

    publishData.segmentId =
      campaignData.data.cells[
        CampaignManager.getColumnId(COLUMN_CODE.CAMPAIGN.SEGMENT_ID)
      ];
    publishData.publish_on =
      campaignData.data.cells[
        CampaignManager.getColumnId(COLUMN_CODE.CAMPAIGN.PUBLISHED_ON)
      ];
    publishData.send_now =
      campaignData.data.cells[
        CampaignManager.getColumnId(COLUMN_CODE.CAMPAIGN.SEND_NOW)
      ];

    return publishData;
  }

  static isPageDataAvailable() {
    return !(
      CampaignManager.pageData === null ||
      CampaignManager.pageData === undefined
    );
  }

  static fetchPageDataById(id, callback) {
    showLoader("Fetching... Please wait");
    CampaignService.getData(id, CampaignManager.getTableId())
      .then((response) => {
        CampaignManager.setPageData({ data: response, id: response._id });
        callback && callback();
        removeLoader();
      })
      .catch((err) => {
        removeLoader();
        Utility.alertWithRedirection(
          "Error!",
          "Record not found / You don't have permission",
          PAGE_ROUTES.CAMPAIGN
        );
      });
  }

  static setCampaignType(campaignType) {
    CampaignManager.campaignType = campaignType ?? CAMPAIGN_TYPE.EMAIL;
  }

  static isWhatsAppCampaign() {
    return CampaignManager.campaignType === CAMPAIGN_TYPE.WHATSAPP;
  }

  static isCampaignEditable() {
    if (!CampaignManager.isPageDataAvailable()) return true;

    const status = CampaignManager.getCampaignStatus()?.toString();
    const isDraft = CampaignManager.status.DRAFT.toString() === status;
    const isScheduled = CampaignManager.status.SCHEDULED.toString() === status;
    return isDraft || isScheduled;
  }

  /* *************** Campaign Request Data Handlers ****************** */
  static getRequestToSavePage(pageData) {
    let data = {};
    data[this.getColumnId(COLUMN_CODE.CAMPAIGN.NAME)] = pageData.name;
    data[this.getColumnId(COLUMN_CODE.CAMPAIGN.CLICKS)] = 0;
    data[this.getColumnId(COLUMN_CODE.CAMPAIGN.OPEN)] = 0;
    data[this.getColumnId(COLUMN_CODE.CAMPAIGN.DELIVERED)] = 0;
    data[this.getColumnId(COLUMN_CODE.CAMPAIGN.STATUS)] =
      CampaignManager.status.DRAFT;
    data[this.getColumnId(COLUMN_CODE.CAMPAIGN.JSON_DATA)] = pageData.json;
    data[this.getColumnId(COLUMN_CODE.CAMPAIGN.PUBLISHED)] = false;
    data[this.getColumnId(COLUMN_CODE.CAMPAIGN.CAMPAIGN_TYPE)] = [
      CampaignManager.campaignType
    ];

    if (!Utility.isEmptyObject(pageData.html)) {
      data[this.getColumnId(COLUMN_CODE.CAMPAIGN.HTML_DATA)] = pageData.html;
    }
    if (!Utility.isEmptyObject(pageData.hash)) {
      data[this.getColumnId(COLUMN_CODE.CAMPAIGN.HASH)] = pageData.hash;
    }

    return data;
  }

  static getRequestToUpdatePage(pageData) {
    let data = {};
    if (!Utility.isEmptyObject(pageData.name)) {
      data[this.getColumnId(COLUMN_CODE.CAMPAIGN.NAME)] = pageData.name;
    }

    if (!Utility.isEmptyObject(pageData.html)) {
      data[this.getColumnId(COLUMN_CODE.CAMPAIGN.HTML_DATA)] = pageData.html;
    }

    if (!Utility.isEmptyObject(pageData.json)) {
      data[this.getColumnId(COLUMN_CODE.CAMPAIGN.JSON_DATA)] = pageData.json;
    }
    return data;
  }

  static getRequestToPublishPage(pageData, needDraftData = false) {
    let data = {};

    data[this.getColumnId(COLUMN_CODE.CAMPAIGN.SEGMENT_ID)] =
      pageData.segmentId;

    data[this.getColumnId(COLUMN_CODE.CAMPAIGN.PUBLISHED_ON)] =
      pageData.publish_on;

    data[this.getColumnId(COLUMN_CODE.CAMPAIGN.SEND_NOW)] = pageData.send_now;

    if (!CampaignManager.isWhatsAppCampaign()) {
      data[this.getColumnId(COLUMN_CODE.CAMPAIGN.EMAIL_SUBJECT)] =
        pageData.subject || "";
      data[this.getColumnId(COLUMN_CODE.CAMPAIGN.EMAIL_PRE_HEADER)] =
        pageData.preHeaderText || "";
      data[this.getColumnId(COLUMN_CODE.CAMPAIGN.EMAIL_FROM)] =
        pageData.from || "";
      data[this.getColumnId(COLUMN_CODE.CAMPAIGN.SMTP_SETUP_ID)] =
        pageData.smtp_setup_id;
    }

    if (needDraftData) {
      data[this.getColumnId(COLUMN_CODE.CAMPAIGN.STATUS)] =
        CampaignManager.status.DRAFT;
    } else {
      data[this.getColumnId(COLUMN_CODE.CAMPAIGN.STATUS)] = pageData.send_now
        ? CampaignManager.status.SCHEDULED_NOW
        : CampaignManager.status.SCHEDULED;

      data[this.getColumnId(COLUMN_CODE.CAMPAIGN.PUBLISHED)] = true;
    }

    return data;
  }

  /* *************** Campaign Table & Column Utils ****************** */
  static getColumnId(columnCode) {
    return TableManger.getColumnId(TABLES.CAMPAIGN, columnCode);
  }

  static getTableId() {
    return TableManger.getTableId(TABLES.CAMPAIGN);
  }

  /* *************** Email Campaign Direct Link Utils ****************** */
  static getDirectLink() {
    if (this.pageData == null) {
      return null;
    }
    let directLink =
      this.pageData.data.cells[
        CampaignManager.getColumnId(COLUMN_CODE.CAMPAIGN.DIRECT_LINK)
      ];
    if (directLink?.includes(window.location.origin)) {
      return directLink;
    } else {
      return AppManager.generateDirectLink(directLink);
    }
  }

  static directLinkExists() {
    if (this.pageData == null) {
      return false;
    }
    let directLink =
      this.pageData.data.cells[
        CampaignManager.getColumnId(COLUMN_CODE.CAMPAIGN.DIRECT_LINK)
      ];
    return !Utility.isEmpty(directLink);
  }

  /* *************** Campaign Status Utils ****************** */
  static getCampaignStatus = () => {
    if (!CampaignManager.isPageDataAvailable()) {
      return;
    }
    let status =
      CampaignManager.pageData.data.cells[
        this.getColumnId(COLUMN_CODE.CAMPAIGN.STATUS)
      ];
    return status;
  };

  static checkCampaignInDraft = () => {
    if (!CampaignManager.isPageDataAvailable()) {
      return false;
    }
    return (
      CampaignManager.status.DRAFT.toString() ===
      CampaignManager.getCampaignStatus().toString()
    );
  };

  static checkCampaignSent = () => {
    if (!CampaignManager.isPageDataAvailable()) {
      return false;
    }
    const currentCampaignStatus =
      CampaignManager.getCampaignStatus().toString();
    return (
      CampaignManager.status.SENT.toString() === currentCampaignStatus ||
      CampaignManager.status.PARTIALLY_SENT.toString() === currentCampaignStatus
    );
  };

  static setCampaignStatus = (status) => {
    let data = CampaignManager.getPageData();
    data.data.cells[this.getColumnId(COLUMN_CODE.CAMPAIGN.STATUS)] = status;
    CampaignManager.setPageData(data);
  };

  /* *************** Email Campaign Header & Template utils ****************** */
  static getHeader() {
    return <CampaignPageHeader title={"Campaign Templates"} stepperIndex={0} />;
  }

  static navigateToBuilder() {
    RouteManager.navigateToPage(PAGE_ROUTES.CAMPAIGN_BUILDER);
  }

  static setSelectedTemplateId(id) {
    TemplateManager.setSelectedTemplateId(id);
  }

  static getAllTemplates() {
    return [
      template_1,
      template_2,
      template_3,
      template_4,
      template_5,
      template_6,
      template_7,
      template_8,
      template_9,
      template_10
    ];
  }

  static getSelectedTemplateId() {
    return TemplateManager.getSelectedTemplateId();
  }

  /* *************** Email Campaign Footer utils ****************** */

  static getCampaignFooterStatus = () => {
    return this.campaignFooter;
  };

  static setCampaignFooterStatus = (value) => {
    this.campaignFooter = value;
  };

  static getCampaignSmtpSetupId = () => {
    if (this.pageData == null) {
      return null;
    }

    let smtpSetupId =
      this.pageData.data.cells[
        CampaignManager.getColumnId(COLUMN_CODE.CAMPAIGN.SMTP_SETUP_ID)
      ];

    return smtpSetupId ? smtpSetupId : null;
  };

  /* *************** Email Campaign Response parser ****************** */
  static dataParseResponse = (data) => {
    if (data === null || CampaignManager.isWhatsAppCampaign()) {
      return data;
    }
    let jsonData =
      data.data.cells[
        CampaignManager.getColumnId(COLUMN_CODE.CAMPAIGN.JSON_DATA)
      ];
    let json = Utility.decodeJSON(jsonData);
    json.designElements?.forEach((component) => {
      if (component.type === PAGE_ELEMENT.COLLECTION) {
        component.value.forEach((list) => {
          list = this.collectionResponse(list, component);
        });
      }
      if (component.type === PAGE_ELEMENT.ICON) {
        component.value.forEach((list) => {
          list = this.collectionResponse(list, component);
        });
      }
      if (component.type === PAGE_ELEMENT.IMAGE_TEXT) {
        component.value.forEach((list) => {
          list = this.imageTextResponse(list, component);
        });
      }
    });
    data.data.cells[
      CampaignManager.getColumnId(COLUMN_CODE.CAMPAIGN.JSON_DATA)
    ] = Utility.encodeJSON(json);
    return data;
  };

  static collectionResponse = (data, component) => {
    if (typeof data.title === "string") {
      data.title = ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
        value: data.title,
        fontSize: component.style.titleSize,
        color: component.style.titleColor,
        fontWeight: "bold",
        paddingTop: 3,
        paddingBottom: 3
      });
    }
    if (
      component.type !== PAGE_ELEMENT.ICON &&
      typeof data.description === "string"
    ) {
      data.description = ComponentManager.getTextDefaultData(
        PAGE_ELEMENT.TEXT,
        {
          value: data.description,
          fontSize: component.style.descriptionSize,
          color: component.style.descriptionColor,
          paddingTop: 3,
          paddingBottom: 3
        }
      );
    }
    if (typeof data.icon === "string") {
      data.icon = ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
        value: data.icon,
        marginBottom: 0
      });
    }
    if (data.button === undefined) {
      data.button = ComponentManager.getButtonDefaultData(PAGE_ELEMENT.BUTTON, {
        fontSize: 12,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 7,
        paddingRight: 7,
        marginTop: 2,
        visibility: false
      });
    }
    return data;
  };

  static imageTextResponse = (data, component) => {
    let isExistingButtonNeedUpdate = false;
    if (typeof data.title === "string") {
      isExistingButtonNeedUpdate = true;
      data.title = ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
        value: data.title,
        fontSize: component.style.titleSize,
        color: component.style.titleColor,
        textAlign: "left",
        fontWeight: "bold"
      });
    }
    if (typeof data.description === "string") {
      data.description = ComponentManager.getTextDefaultData(
        PAGE_ELEMENT.TEXT,
        {
          value: data.description,
          fontSize: component.style.descriptionSize,
          color: component.style.descriptionColor,
          textAlign: "left"
        }
      );
    }
    if (typeof data.image === "string") {
      data.image = ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
        value: data.image
      });
    }
    let buttonObj = {};
    if (data.button === undefined) {
      buttonObj = {
        fontSize: 14,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        width: "auto",
        alignSelf:
          component.style.textAlign === "left" ? "flex-start" : "flex-end",
        visibility: false
      };
      data.button = ComponentManager.getButtonDefaultData(
        PAGE_ELEMENT.BUTTON,
        buttonObj
      );
    } else if (isExistingButtonNeedUpdate) {
      buttonObj = {
        value: data.button.value,
        url: data.button.url,
        fontSize: 14,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        marginRight: component.style.textAlign === "right" ? 0 : "auto",
        marginLeft: component.style.textAlign === "left" ? 0 : "auto",
        marginTop: "auto",
        marginBottom: "auto",
        width: "auto",
        alignSelf:
          component.style.textAlign === "left" ? "flex-start" : "flex-end",
        visibility: data.button.visibility
      };
      data.button = ComponentManager.getButtonDefaultData(
        PAGE_ELEMENT.BUTTON,
        buttonObj
      );
    }
    return data;
  };
  static setCampaignDataFromContextMenu = (rowData) => {
    const data = {
      _id: rowData?.id,
      cells: rowData
    };
    const id = rowData?.id;
    CampaignManager.setPageData({ data, id });
  };
}
