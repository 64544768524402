import { DKLabel, Toggle, DKButton, showAlert } from "deskera-ui-library";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchTenantDetails,
  getTenantsDetails
} from "../../redux/slices/booksSlice";
import Tenant from "../../services/tenant";
import { replaceCountrySpecificKeywords } from "../../utility/Utility";
export function GroupingSettings(props) {
  const tenantDetails = useAppSelector(getTenantsDetails);
  const [toggle, setToggle] = useState(
    tenantDetails?.additionalSettings?.PRODUCT_GROUP_ENABLED || false
  );
  const dispatch = useAppDispatch();

  const onToggle = async () => {
    try {
      await setToggle(!toggle);
      await Tenant.updateTenantDetails({
        additionalSettings: { PRODUCT_GROUP_ENABLED: !toggle }
      });
      props?.onClose?.();
      dispatch(fetchTenantDetails({}));
    } catch (error) {
      console.error("Error while saving:", error);
      // Handle error appropriately, such as displaying an error message
    }
  };

  const onSave = () => {
    let additionalSettings = { PRODUCT_GROUP_ENABLED: toggle };
    Tenant.updateTenantDetails({ additionalSettings })
      .then((response) => {
        props?.onClose?.();
        dispatch(fetchTenantDetails({}));
      })
      .catch((error) => {
        showAlert("Error", error?.errorMessage);
      });
  };

  return (
    <div>
      <div className="row justify-content-between border-m border-radius-m position-relative p-r mt-l">
        <div className="column">
          <DKLabel
            text={`Enable Product Grouping`}
            className="mr-m fw-m pb-xs"
          />
          <DKLabel
            className="text-gray text-ellipsis"
            text={replaceCountrySpecificKeywords(
              "This will allow you to create sub-section at product level and can be used while selecting product in quote"
            )}
          />
        </div>
        <div className="row justify-content-end ml-l" style={{ width: 75 }}>
          <Toggle isOn={toggle} onChange={onToggle} color="bg-green" />
        </div>
      </div>
    </div>
  );
}
export default GroupingSettings;
