import { COUNTRIES_WITH_CURRENCIES } from "../../constants/Currencies";
import { INPUT_TYPE } from "deskera-ui-library";
import { PAGE_ROUTES } from "../../managers/RouteManager";
import { COLUMN_CODE, TABLES } from "../../managers/TableManger";

///////////////////////////////////////
/* Import Sources Constants */
///////////////////////////////////////
const PERSISTED_IMPORT_SOURCE_KEY = `importSourceApp`;

const IMPORT_SOURCE_TYPE = {
  importFromLocal: "CRM_IMPORT_FILE_FROM_COMPUTER",
  importOffice365: "CRM_OFFICE_365",
  importGoogle: "CRM_GOOGLE",
  importFromDeskeraBooks: "DESKERA_BOOKS"
};

const IMPORT_SOURCE_APP_CONFIG = {
  [IMPORT_SOURCE_TYPE.importOffice365]: {
    appName: "OFFICE_365",
    appId: process.env.REACT_APP_OFFICE_365_APP_ID,
    redirectUrl: window.location.origin + PAGE_ROUTES.IMPORT_PORTAL,
    scopes:
      "user.read+calendars.ReadWrite+contacts.ReadWrite+offline_access+openid+profile",
    tenantId: "common",
    terminal: "WEB"
  },
  [IMPORT_SOURCE_TYPE.importGoogle]: {
    appName: "GOOGLE",
    appId: process.env.REACT_APP_GOOGLE_CONTACTS_APP_ID,
    redirectUrl: window.location.origin + PAGE_ROUTES.IMPORT_PORTAL,
    scopes:
      "email%20https://www.googleapis.com/auth/contacts.readonly%20https://www.googleapis.com/auth/contacts.other.readonly",
    terminal: "WEB"
  },
  [IMPORT_SOURCE_TYPE.importFromDeskeraBooks]: {
    appName: "DESKERA_BOOKS",
    redirectUrl: window.location.origin + PAGE_ROUTES.IMPORT_PORTAL,
    tenantId: "common",
    terminal: "WEB"
  }
};

const IMPORT_MODULE_TYPE = {
  [TABLES.CONTACT]: `CRM3_CONTACT`,
  [TABLES.DEAL]: `CRM3_DEAL`,
  [TABLES.ACCOUNT]: `CRM3_ACCOUNT`,
  [IMPORT_SOURCE_TYPE.importFromDeskeraBooks]: "CRM_CONTACT",
  [IMPORT_SOURCE_TYPE.importOffice365]: "CRM_CONTACT",
  [TABLES.BOOKS_PRICE_LIST]: "PRICE_LIST"
};

const IMPORT_FILE_MODULE = {
  [TABLES.CONTACT]: "IMPORT_CRM_CONTACT",
  [TABLES.DEAL]: "IMPORT_CRM3_DEAL",
  [TABLES.ACCOUNT]: "IMPORT_CRM3_ACCOUNT",
  [TABLES.BOOKS_PRICE_LIST]: "IMPORT_PRICE_LIST"
};

///////////////////////////////////////
/* Import Export File Type Constants */
///////////////////////////////////////
const supportedFileTypes = {
  CSV: "csv",
  XLSX: "xlsx"
};
const acceptedFileFormats = [".csv", ".xlsx"];
const supportedMimeTypes = {
  csv: "text/csv;charset=utf-8;",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
};
const bufferTypes = {
  csv: "string",
  xlsx: "array"
};
const maxFileNameLength = 100;
////////////////////////////////////////

const MODULES_WITH_NESTED_IMPORT = [TABLES.BOOKS_PRICE_LIST];

////////////////////////////////////////
/* Mapping Screen */
///////////////////////////////////////
const excludedColumnCodes = [
  COLUMN_CODE.CONTACT.STATUS,
  COLUMN_CODE.CONTACT.SEGMENT,
  COLUMN_CODE.CONTACT.DETAILED_ADDRESS
];
const excludedExternalSourceColumns = ["Source", "Source Id", "SourceId"];
const headerMapDisplaySections = [
  {
    getSourceHeaderName: (selectedSource) => {
      const importSourceType = IMPORT_SOURCE_TYPE[selectedSource];
      switch (importSourceType) {
        case IMPORT_SOURCE_TYPE.importFromDeskeraBooks:
          return `ERP Header`;
        case IMPORT_SOURCE_TYPE.importOffice365:
          return `Office 365 Header`;
        case IMPORT_SOURCE_TYPE.importGoogle:
          return `Google Header`;
        default:
          return `Imported Header`;
      }
    },
    name: "Imported Header",
    key: "importedKey"
    /* column Type => Text */
  },
  {
    name: "CRM Header",
    key: "dkColKey"
    /* column Type => Select */
  },
  {
    name: "Sample",
    key: "sampleData"
    /* column Type => Text */
  }
];

const errorInfoTypes = {
  unmapped:
    "Please either map to existing header/create new field or check the option to skip unmapped fields",
  duplicateMapping:
    "Duplicate Mappings present, please choose different header or create a new field",
  escaped: "Data for this field will be excluded from import",
  dateWarning:
    "Please ensure date is in recommended format i.e. (DD-MM-YYYY) or (MM/DD/YYYY) in uploaded file for correct results."
};

const defaultRequiredColumnsToImport = {
  [`${TABLES.CONTACT} ${COLUMN_CODE.CONTACT.TYPE}`]: {
    defaultValue: `People`,
    importedKey: `Type`
  },
  [`${TABLES.CONTACT} ${COLUMN_CODE.CONTACT.OWNER_ID}`]: {
    defaultValue: ``,
    importedKey: `Owner`
  },
  [`${TABLES.DEAL} ${COLUMN_CODE.DEAL.STAGE_ID}`]: {
    defaultValue: `New Stage`,
    importedKey: `StageName`
  },
  [`${TABLES.DEAL} ${COLUMN_CODE.DEAL.OWNER_ID}`]: {
    defaultValue: ``,
    importedKey: `Owner`
  },
  [`${TABLES.ACCOUNT} ${COLUMN_CODE.ACCOUNT.OWNER_ID}`]: {
    defaultValue: ``,
    importedKey: `Owner`
  }
};

const excludedColumnsFromValidations = [
  `${TABLES.DEAL}_${COLUMN_CODE.DEAL.CONTACT_ID}`,
  `${TABLES.DEAL}_${COLUMN_CODE.DEAL.ACCOUNT_ID}`,
  `${TABLES.CONTACT}_${COLUMN_CODE.CONTACT.ACCOUNT}`,
  `${TABLES.ACCOUNT}_${COLUMN_CODE.ACCOUNT.NAME}`,
  `${TABLES.BOOKS_PRICE_LIST}_${COLUMN_CODE.BOOKS_PRICE_LIST.NAME}`,
  `${TABLES.BOOKS_PRICE_LIST}_${COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_NAME}`,
  `${TABLES.BOOKS_PRICE_LIST}_${COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_CODE}`,
  `${TABLES.BOOKS_PRICE_LIST}_${COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_PRICE_CURRENCY}`
];

const DETAIL_ADDRESS_CUSTOM_FIELDS = [
  {
    name: "Address name",
    code: "contactName",
    type: INPUT_TYPE.TEXT
  },
  {
    name: "Street",
    code: "address1",
    type: INPUT_TYPE.TEXT
  },
  {
    name: "Address line 2",
    code: "address2",
    type: INPUT_TYPE.TEXT
  },
  {
    name: "City",
    code: "city",
    type: INPUT_TYPE.TEXT
  },
  {
    name: "State",
    code: "state",
    type: INPUT_TYPE.TEXT
  },
  {
    name: "Country",
    code: "country",
    options: COUNTRIES_WITH_CURRENCIES.map((countryData) => ({
      id: countryData.country,
      name: countryData.country,
      color: "data-grid-badge-color-1"
    })),
    type: INPUT_TYPE.SELECT
  },
  {
    name: "Postal code",
    code: "postalCode",
    type: INPUT_TYPE.TEXT
  }
];

const PRICE_BOOK_NAME_HEADER = "Price List";
const PRICE_BOOK_PRODUCT_CODE_HEADER = "Product Code";

const PRICE_BOOK_LINE_ITEM_COLUMNS = [
  {
    name: "Product Name",
    code: COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_NAME,
    editable: false,
    required: true,
    type: INPUT_TYPE.TEXT
  },
  {
    name: PRICE_BOOK_PRODUCT_CODE_HEADER,
    code: COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_CODE,
    editable: false,
    required: true,
    type: INPUT_TYPE.TEXT
  },
  {
    name: "Price",
    code: COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_PRICE,
    type: INPUT_TYPE.NUMBER
  },
  {
    name: "Price Currency",
    code: COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_PRICE_CURRENCY,
    uiVisible: false,
    type: INPUT_TYPE.SELECT
  },
  {
    name: "Discount",
    code: COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_DISCOUNT,
    textAlign: "right",
    type: INPUT_TYPE.TEXT
  },
  {
    name: "Discount in percent",
    code: COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_DISCOUNT_IN_PERCENT,
    uiVisible: false,
    type: "boolean"
  },
  {
    name: "Minimum Quantity",
    code: COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_MIN_QTY,
    required: true,
    type: INPUT_TYPE.NUMBER
  },
  {
    name: "Unit Of Measurement",
    code: COLUMN_CODE.BOOKS_PRICE_LIST.LINE_ITEM_UOM,
    type: INPUT_TYPE.SELECT
  }
];

/////////////////////////////////////////
////////////////////////////////////////
/* Import Options */
////////////////////////////////////////
const importOptions = (tableName: string) =>
  JSON.stringify([
    {
      title: "Import Options",
      options: [
        {
          value: "ADD",
          displayValue: "Add New Records",
          checked: false
        },
        {
          value: "UPDATE",
          displayValue: "Update Existing Records",
          checked: false
        },
        {
          value: "ADD_AND_UPDATE",
          displayValue: "Add New, And Update Existing Records",
          checked: true
        }
      ],
      selectedOption: tableName === TABLES.DEAL ? "ADD" : "ADD_AND_UPDATE",
      payloadKey: "importOption",
      applicableFor: [TABLES.CONTACT]
    },
    {
      title: "In case of Duplicate Records",
      options: [
        {
          value: "REJECT",
          displayValue: "Reject the duplicate",
          checked: true
        },
        {
          value: "IMPORT",
          displayValue: "Import the duplicate rows",
          checked: false
        }
      ],
      selectedOption: tableName === TABLES.DEAL ? "IMPORT" : "REJECT",
      payloadKey: "duplicateRecordOption",
      applicableFor: []
    },
    {
      title: "In case of Invalid Values",
      options: [
        {
          value: "REJECT_ROW",
          displayValue: "Reject the Entire Row",
          checked: false
        },
        {
          value: "REJECT_VALUE",
          displayValue: "Reject the Invalid Value, But Import the Row",
          checked: false
        },
        {
          value: "ADD_VALUE",
          displayValue: "Add the Value in the Dropdown",
          checked: true
        }
      ],
      selectedOption: "ADD_VALUE",
      payloadKey: "invalidValueOption",
      applicableFor: [TABLES.CONTACT]
    }
  ]);

const IMPORT_DEFAULT_DATE_FORMAT = "MM/dd/yyyy";
///////////////////////////////////////

export {
  PERSISTED_IMPORT_SOURCE_KEY,
  IMPORT_SOURCE_TYPE,
  IMPORT_MODULE_TYPE,
  IMPORT_FILE_MODULE,
  IMPORT_SOURCE_APP_CONFIG,
  /* Import Export Constants */
  supportedFileTypes,
  supportedMimeTypes,
  bufferTypes,
  acceptedFileFormats,
  maxFileNameLength,
  MODULES_WITH_NESTED_IMPORT,
  /* Column Mapper Screen */
  errorInfoTypes,
  excludedColumnCodes,
  excludedExternalSourceColumns,
  excludedColumnsFromValidations,
  headerMapDisplaySections,
  DETAIL_ADDRESS_CUSTOM_FIELDS,
  PRICE_BOOK_LINE_ITEM_COLUMNS,
  PRICE_BOOK_NAME_HEADER,
  PRICE_BOOK_PRODUCT_CODE_HEADER,
  /* Confirmation Screen */
  importOptions,
  defaultRequiredColumnsToImport,
  IMPORT_DEFAULT_DATE_FORMAT
};
