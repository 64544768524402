import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ShopService from "../../services/shop";

import { RootState } from "../store";

const initialState: any = {
  products: {} as any,
  shopCategories: {} as any,
  productCategory: {} as any,
  shopDetails: {} as any,
  shopFetched: false,
  productDataByCategory: {},
  leads: {}
};

/* ***************  SHOP API's ******************* */
export const fetchShopDetails = createAsyncThunk(
  "shopDetails",
  async (data: any, thunkAPI) => {
    const response: any = await ShopService.getShopByTenantId();
    return response;
  }
);
export const addShop = createAsyncThunk(
  "addShop",
  async (data: any, thunkAPI) => {
    const response: any = await ShopService.addShop(data.payload);
    return response;
  }
);
export const updateShop = createAsyncThunk(
  "updateShop",
  async (data: any, thunkAPI) => {
    const response: any = await ShopService.updateShop(
      data.payload,
      data.shopId
    );
    return response;
  }
);

/* ***************  SHOP PRODUCT API's ******************* */
export const fetchShopProducts = createAsyncThunk(
  "shopProducts",
  async (data: any, thunkAPI) => {
    const response: any = await ShopService.getProductsByPage(data.query);
    return response;
  }
);

/* ***************  SHOP CATEGORY API's ******************* */
export const fetchProductCategory = createAsyncThunk(
  "productCategory",
  async (data: any, thunkAPI) => {
    const response: any = await ShopService.getCategory({
      categoryType: "product",
      ...data
    });
    return response;
  }
);

export const fetchProductCountByCategory = createAsyncThunk(
  "productCountByCategory",
  async (data: any, thunkAPI) => {
    const response: any = await ShopService.getProductCountByCategory(
      data.query
    );
    return response;
  }
);

export const fetchShopCategory = createAsyncThunk(
  "shopCategory",
  async (data: any, thunkAPI) => {
    const response: any = await ShopService.getCategory({
      categoryType: "business"
    });
    return response;
  }
);

/* ***************  SHOP LEAD API's ******************* */
export const fetchShopLeadByPage = createAsyncThunk(
  "shopLeads",
  async (data: any, thunkAPI) => {
    const response: any = await ShopService.getLeadsByPage(data.query);
    return response;
  }
);

export const ShopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchShopDetails.fulfilled, (state, action) => {
      state.shopDetails = action.payload;
      state.shopFetched = true;
    });
    builder.addCase(addShop.fulfilled, (state, action) => {
      state.shopDetails = state.shopDetails || { data: [], totalCount: 0 };
      state.shopDetails.data.push(action.payload);
      state.shopDetails.totalCount++;
    });
    builder.addCase(updateShop.fulfilled, (state, action) => {
      const shops = [...state.shopDetails.data];
      shops.forEach((shop, index) => {
        if (shop._id === action.payload._id) {
          shops[index] = action.payload;
        }
      });
      state.shopDetails.data = shops;
    });
    builder.addCase(fetchShopProducts.fulfilled, (state, action) => {
      state.products = action.payload;
    });
    builder.addCase(fetchProductCategory.fulfilled, (state, action) => {
      state.productCategory = action.payload;
    });
    builder.addCase(fetchProductCountByCategory.fulfilled, (state, action) => {
      state.productDataByCategory = action.payload;
    });
    builder.addCase(fetchShopCategory.fulfilled, (state, action) => {
      state.shopCategories = action.payload;
    });
    builder.addCase(fetchShopLeadByPage.fulfilled, (state, action) => {
      state.leads = action.payload;
    });
  }
});

export const selectShopProducts = (state: RootState) => state.shop.products;

export default ShopSlice.reducer;
