import { lazy } from "react";
import AppManager from "../managers/AppManager";

export function lazyWithPreload(factory, preloadOnPageLoad = false) {
  const Component = lazy(factory);
  Component.preload = factory;
  if (preloadOnPageLoad) {
    AppManager.chunksToPreloadOnPageLoad.push(Component);
  }
  return Component;
}
