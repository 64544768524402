import { DKButton, DKIcons, DKLabel, showAlert } from "deskera-ui-library";
import ApiConstants from "../../constants/ApiConstants";
import ContactService from "../../services/contact";
import DateFormatSetting from "../settings/DateFormatSetting";
import EmailSyncSettings from "../settings/EmailSyncSettings";
import TenantSettings from "../settings/TenantSettings";
import OrgSettingComponent from "./OrgSettingComponent";
import { useAppSelector } from "../../redux/hooks";
import { TABLES } from "../../managers/TableManger";
import { selectPermissionsByModule } from "../../redux/slices/rolesPermissionSlice";
import { SETTING_MODULE } from "../../constants/Constant";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import { PAGE_ROUTES } from "../../managers/RouteManager";
import { useState } from "react";
import { isViewportMobile } from "../../utility/GetViewportSize";
interface OrganizationSettingsPageProps {
  onClose: () => void;
}
const OrganizationSettingsPage = ({
  onClose
}: OrganizationSettingsPageProps) => {
  const settingPermissions = useAppSelector((state) =>
    selectPermissionsByModule(state, SETTING_MODULE)
  );

  const contactPermissions = useAppSelector((state) =>
    selectPermissionsByModule(state, TABLES.CONTACT)
  );

  const [showBDRSettingCard, setShowBDRSettingCard] = useState(false);

  const clearContactsComponent = () => {
    return (
      <div className="column parent-width border-m border-radius-m position-relative p-r mt-l mb-l">
        <div
          className={`row justify-content-between parent-width ${isViewportMobile() ? "row-responsive" : ""} `}
        >
          <div className="column">
            <DKLabel text="Clear Contacts" className="mr-m mb-xs fw-m" />
            <DKLabel
              className="text-gray text-ellipsis"
              text="This will delete all your contacts permanently"
            />
          </div>
          <DKButton
            title="Delete Contacts"
            className={` ${isViewportMobile() ? "mt-m parent-width" : ""} align-self-end bg-red text-white border-m `}
            icon={DKIcons.white.ic_delete}
            onClick={() => showDeleteContactWarning()}
          />
        </div>
      </div>
    );
  };
  const showDeleteContactWarning = () => {
    let buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Delete",
        className: "bg-red text-white",
        onClick: () => {
          deleteAllContacts();
        }
      }
    ];
    showAlert(
      "Delete all Contacts?",
      "Deleting contacts will delete it permanently.",
      buttons
    );
  };
  const deleteAllContacts = () => {
    ContactService.clearAllContacts().then((res) => {
      // showAlert(
      //   "Contact deleted!",
      //   "All your contacts have been successfully deleted."
      // );
    });

    showAlert(
      "In-progress...",
      "Contact deletion may take sometime. Go back to Contact page and refresh."
    );
  };
  return (
    <div className="parent-size overflow-y-auto pr-r">
      <OrgSettingComponent
        title="My Profile"
        description="View your email, phone number, role, organisation and more"
        baseUrl={ApiConstants.PRODUCT_URL_GO}
        buttonName="View Details"
      />

      {settingPermissions[USER_ACTION_TYPES.SUBSCRIPTION_BILLING] && (
        //navigation components as orgSettingComponent
        <OrgSettingComponent
          title="Subscription / Billing"
          description="Manage your subscription, change plan and upgrade here"
          url="billing"
          baseUrl={ApiConstants.PRODUCT_URL_GO}
          buttonName="View Details"
        />
      )}

      <OrgSettingComponent
        title="Two-Factor Authentication (2FA)"
        description="Increase your account's security by enabling Two-Factor Authentication (2FA)"
        url="security"
        baseUrl={ApiConstants.PRODUCT_URL_GO}
        buttonName="View Details"
      />

      <OrgSettingComponent
        title="Change Password"
        description="You can change or modify your password here"
        url="security"
        baseUrl={ApiConstants.PRODUCT_URL_GO}
        buttonName="View Details"
      />

      {showBDRSettingCard && (
        <OrgSettingComponent
          title="BDR Settings"
          description="Manage your business development representatives to be assigned to your website visitors in round robin manner."
          baseUrl={PAGE_ROUTES.SALES_REPRESENTATIVE_SETTING}
          buttonName="View Details"
        />
      )}

      {settingPermissions[USER_ACTION_TYPES.CURRENCY] && (
        <TenantSettings onClose={onClose} />
      )}

      {settingPermissions[USER_ACTION_TYPES.DATE] && <DateFormatSetting />}

      {settingPermissions[USER_ACTION_TYPES.EMAIL_SYNC] && (
        <EmailSyncSettings />
      )}

      {contactPermissions[USER_ACTION_TYPES.REC_DELETE] &&
        settingPermissions[USER_ACTION_TYPES.CLEAR_CONTACTS] &&
        clearContactsComponent()}
    </div>
  );
};

export default OrganizationSettingsPage;
