/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef, useState } from "react";
import {
  DKLabel,
  DKIcon,
  DKIcons,
  DKListPicker2,
  showAlert,
  INPUT_VIEW_DIRECTION
} from "deskera-ui-library";
import { COLUMN_CODE, TABLES, TableManger } from "../../managers/TableManger";
import { IContactAddress } from "../../model/BooksContact";
import { updateRecord } from "../../redux/slices/recordSlice";
import Utility, { getCapitalized } from "../../utility/Utility";
import { GOOGLE_MAP_URL } from "../../constants/Constant";
import { showAddBooksContactAddress } from "../books/AddAddress";
import Table from "../../services/table";
import { store } from "../../redux/store";

export interface IDetailedAddressProps {
  className?: string;
  styles?: { [key: string]: any };
  readOnly?: boolean;
  recordId?: string;
  tableName: string;
  defaultFullAddress?: string;
  displayFullAddress?: string;
  pickerTitle?: string;
  isQuoteLabel?: boolean;
  selectedAddress?: IContactAddress;
  addressList: IContactAddress[];
  needLocationMarker?: boolean;
  needPickerFixedPosition?: boolean;
  isComplianceDetailsMissing?: boolean;
  needComplianceDetails?: boolean;
  complianceDetails?: { gstIn: string; gstTreatment: any };
  allowAddAddress?: boolean;
  isQuoteByAccount?: string;
  required?: boolean;
  invalid?: boolean;
  errorMsg?: boolean;
  renderer?: () => JSX.Element;
  onSelect?: (index: number, address: IContactAddress) => void;
  onAdd?: (addressList: IContactAddress[], complianceDetails?: any) => void;
  onEdit?: (
    indexToEdit: number,
    addressList: IContactAddress[],
    complianceDetails?: any
  ) => void;
  onDelete?: (indexToDelete: number, addressList: IContactAddress[]) => void;
  onUpdateSelectedAddress?: (
    address: IContactAddress,
    complianceDetails: any
  ) => void;
}

export default function DetailedAddress({
  className = "",
  styles = {},
  recordId,
  tableName,
  readOnly = false,
  defaultFullAddress = "",
  displayFullAddress = "",
  pickerTitle = "",
  isQuoteLabel = false,
  selectedAddress = null,
  addressList = [] as IContactAddress[],
  needLocationMarker = true,
  needPickerFixedPosition = false,
  isComplianceDetailsMissing = false,
  needComplianceDetails = false,
  complianceDetails = null,
  allowAddAddress = true,
  required = false,
  renderer = null,
  onSelect = null,
  onAdd = null,
  onEdit = null,
  onDelete = null,
  onUpdateSelectedAddress = null,
  isQuoteByAccount = ""
}: IDetailedAddressProps) {
  const [needAddressPicker, setNeedAddressPicker] = useState(false);
  const addressContainerRef = useRef(null);

  useEffect(() => {
    const appContainerDiv = document.getElementById("containerDiv");
    appContainerDiv?.addEventListener("scroll", onCloseListPicker);

    return () =>
      appContainerDiv?.removeEventListener("scroll", onCloseListPicker);
  }, []);

  /* ************** ADDRESS CRUD HANDLERS ************** */
  const notifyParentOnUpdate = (
    addressList: IContactAddress[],
    indexToUpdate?: number,
    indexToDelete?: number,
    complianceDetails?: any
  ) => {
    if (indexToUpdate !== null && indexToUpdate >= 0) {
      if (
        selectedAddress &&
        selectedAddress.id === addressList[indexToUpdate]?.id
      ) {
        onUpdateSelectedAddress?.(
          addressList[indexToUpdate],
          complianceDetails
        );
        return;
      }

      onEdit?.(indexToUpdate, addressList, complianceDetails);
    } else if (indexToDelete !== null && indexToDelete >= 0) {
      onDelete?.(indexToDelete, addressList);
    } else {
      if (selectedAddress && selectedAddress.id === addressList[0]?.id) {
        onUpdateSelectedAddress?.(addressList[0], complianceDetails);
        return;
      }

      onAdd?.(addressList, complianceDetails);
    }
  };

  const onAddOrUpdateAddress = async (
    newAddress: IContactAddress,
    indexToUpdate?: number,
    complianceDetails?: any
  ) => {
    let newAddressList = (addressList || []).map((address) => {
      address = { ...address };

      /* Only changing preferences for old addresses, when new address have default billing/shipping/preference */
      if (newAddress.preferred) {
        address.preferred = false;
      }

      if (newAddress.billing) {
        address.billing = false;
      }

      if (newAddress.shipping) {
        address.shipping = false;
      }

      return address;
    });

    if (indexToUpdate !== null && indexToUpdate >= 0) {
      newAddressList.splice(indexToUpdate, 1, newAddress);
    } else {
      const duplicateIndex = newAddressList.findIndex(
        (address) =>
          (!Utility.isEmptyObject(address.address1) &&
            address.address1 === newAddress.address1) ||
          (!Utility.isEmptyObject(address.address2) &&
            address.address2 === newAddress.address2)
      );
      duplicateIndex === -1
        ? newAddressList.push(newAddress)
        : newAddressList.splice(duplicateIndex, 1, newAddress);
      indexToUpdate = duplicateIndex;
    }

    if (!recordId) {
      notifyParentOnUpdate(
        newAddressList,
        indexToUpdate,
        null,
        complianceDetails
      );
      return;
    }

    try {
      const detailAddressColumnId = TableManger.getColumnId(
        tableName,
        COLUMN_CODE.CONTACT.DETAILED_ADDRESS
      );

      const addressColumnId = TableManger.getColumnId(
        tableName,
        COLUMN_CODE.CONTACT.ADDRESS
      );

      const tableId = TableManger.getTableId(tableName);

      const payload: any = { [detailAddressColumnId]: [newAddress] };
      // if (newAddress.preferred && addressColumnId) {
      //   payload[addressColumnId] = newAddress.full;
      // }

      let response = await Table.saveContactDetailedAddress(
        payload,
        recordId,
        tableId
      );

      if ([TABLES.CONTACT, TABLES.SEGMENT].includes(tableName)) {
        const updatedRecordData: any = {
          [detailAddressColumnId]:
            response?.data?.cells?.[detailAddressColumnId] ?? newAddressList
        };

        // if (newAddress.preferred && addressColumnId) {
        //   updatedRecordData[addressColumnId] = newAddress.full;
        // }

        store.dispatch(
          updateRecord({
            tableName,
            recId: recordId,
            record: updatedRecordData
          })
        );
      }

      notifyParentOnUpdate(
        newAddressList,
        indexToUpdate,
        null,
        complianceDetails
      );
    } catch (err) {}
  };

  const onDeleteAddress = async (
    newAddress: IContactAddress,
    indexToDelete?: number
  ) => {
    if (addressList?.length === 1 && required) {
      showAlert("Error", `Address cannot be empty.`);
      return;
    }

    const newAddressList = [...(addressList || [])];
    if (indexToDelete !== null && indexToDelete >= 0) {
      newAddressList.splice(indexToDelete, 1);
    }

    if (!recordId || !newAddress.id) {
      notifyParentOnUpdate(newAddressList, null, indexToDelete);
      return;
    }

    try {
      const detailAddressColumnId = TableManger.getColumnId(
        tableName,
        COLUMN_CODE.CONTACT.DETAILED_ADDRESS
      );
      // const addressColumnId = TableManger.getColumnId(
      //   tableName,
      //   COLUMN_CODE.CONTACT.ADDRESS
      // );
      const tableId = TableManger.getTableId(tableName);

      await Table.deleteContactDetailedAddress(
        newAddress.id,
        recordId,
        tableId
      );

      if ([TABLES.CONTACT, TABLES.SEGMENT].includes(tableName)) {
        const updatedRecordData: any = {
          [detailAddressColumnId]: newAddressList
        };

        // if (newAddress.preferred && addressColumnId) {
        //   updatedRecordData[addressColumnId] = newAddressList[0]?.full || "";
        // }

        store.dispatch(
          updateRecord({
            tableName,
            recId: recordId,
            record: {
              [detailAddressColumnId]: newAddressList
            }
          })
        );
      }

      notifyParentOnUpdate(newAddressList, null, indexToDelete);
    } catch (err) {}
  };

  /* **************List Picker Utils************** */
  const onRequestAddOrEditAddress = (
    address?: any,
    addressListIndex?: number
  ) => {
    showAddBooksContactAddress(
      {
        address,
        defaultAddress: defaultFullAddress || "",
        isAddressPopup: needComplianceDetails ? false : true,
        data: complianceDetails,
        needDefaultToggles: true,
        isQuotePopup: isQuoteLabel,
        canValidateOnInitialize: isComplianceDetailsMissing,
        isQuoteByAccount: isQuoteByAccount
      },
      ({ address, complianceDetails }) =>
        onAddOrUpdateAddress(address, addressListIndex, complianceDetails)
    );
  };

  const onCloseListPicker = () =>
    setTimeout(() => setNeedAddressPicker(false), 10);

  const onAddressTapped = (index: number, addressToSelect: IContactAddress) => {
    onCloseListPicker();
    if (onSelect) {
      onSelect(index, addressToSelect);
    } else {
      onAddOrUpdateAddress({ ...addressToSelect, preferred: true }, index);
    }
  };

  const onEditAddressTapped = (
    index: number,
    addressToEdit: IContactAddress
  ) => {
    onCloseListPicker();
    onRequestAddOrEditAddress(addressToEdit, index);
  };

  const onDeleteAddressTapped = (
    index: number,
    addressToEdit: IContactAddress
  ) => {
    onCloseListPicker();
    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Delete",
        className: "bg-red text-white ml-r",
        onClick: () => onDeleteAddress?.(addressToEdit, index)
      }
    ];
    showAlert(
      "Delete Address?",
      "Deleting this record will delete it permanently you will not be able to restore it.",
      buttons
    );
  };

  const getListPickerStyles = () => {
    const addressContainerRectangle =
      addressContainerRef.current?.getBoundingClientRect() || {};

    const styles = {
      left: needPickerFixedPosition ? addressContainerRectangle.left || 0 : 0,
      right: needPickerFixedPosition ? addressContainerRectangle.right || 0 : 0,
      width: addressContainerRectangle.width,
      maxWidth: 300
    };

    if (addressContainerRectangle.top + 200 > window.innerHeight) {
      return {
        top: needPickerFixedPosition ? addressContainerRectangle.top - 200 : 0,
        ...styles
      };
    }

    return {
      top: needPickerFixedPosition ? addressContainerRectangle.top || 0 : 0,
      ...styles
    };
  };

  /* ************** RENDERERS ************** */
  const preferredAddress =
    addressList?.find?.((address) => address.preferred) ||
    addressList?.[0] ||
    null;
  const addressToDisplay = displayFullAddress || preferredAddress?.full;
  return (
    <div
      className={
        "row align-items-start p-s position-relative " +
        (readOnly ? "" : " cursor-hand ") +
        (className || "")
      }
      ref={addressContainerRef}
      style={{
        inset: 0,
        maxWidth: "100%",
        ...(styles || {})
      }}
      onClick={(e) => {
        e?.stopPropagation();

        if (readOnly) {
          return false;
        }

        if (Utility.isEmptyObject(addressList) || isComplianceDetailsMissing) {
          onRequestAddOrEditAddress(
            selectedAddress?.id
              ? selectedAddress
              : addressList?.[0] || { preferred: true },
            selectedAddress?.id || Utility.isEmptyObject(addressList)
              ? undefined
              : 0
          );
        } else {
          setNeedAddressPicker(true);
        }
      }}
    >
      {renderer ? (
        renderer()
      ) : addressToDisplay ? (
        <Fragment>
          <DKLabel
            text={addressToDisplay}
            className={"width-auto " + (isQuoteLabel ? "" : " text-ellipsis ")}
            style={{
              whiteSpace: "pre-wrap",
              // maxHeight: "100%",
              WebkitLineClamp: 1,
              maxWidth: isQuoteLabel ? "80%" : "100%",
              maxHeight: isQuoteLabel ? "auto" : "100%"
            }}
          />
          {needLocationMarker ? (
            <DKIcon
              className="ic-s ml-xs mb-auto edit-icon cursor-hand "
              src={DKIcons.ic_location}
              style={{ opacity: 0.7 }}
              onClick={(e) => {
                e.stopPropagation();
                Utility.openInNewTab(
                  GOOGLE_MAP_URL + encodeURI(addressToDisplay)
                );
                return;
              }}
            />
          ) : null}
        </Fragment>
      ) : null}
      {needAddressPicker ? (
        <DKListPicker2
          title={pickerTitle || ""}
          data={addressList}
          className={`detailed-address-list-picker position-${
            needPickerFixedPosition ? "fixed" : "absolute"
          } parent-width z-index-3 border-m shadow-s`}
          style={getListPickerStyles()}
          allowSearch={true}
          searchableKey={"full"}
          displayKey={"full"}
          renderer={(index: number, addressData: IContactAddress) => (
            <div className="column parent-width flex-1">
              <DKLabel
                className="text-ellipsis"
                style={{
                  WebkitLineClamp: 1
                }}
                text={addressData.full || "-"}
              />
              {addressData.billing || addressData.shipping ? (
                <div className="row width-auto pt-xs" style={{ gap: 4 }}>
                  {addressData.billing ? (
                    <DKLabel
                      text={"Bill to"}
                      className={
                        "text-blue p-h-xs bg-chip-blue border-radius-s"
                      }
                    />
                  ) : null}
                  {addressData.shipping ? (
                    <DKLabel
                      text={"Ship to"}
                      className={
                        "text-blue p-h-xs bg-chip-blue border-radius-s"
                      }
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          )}
          onSelect={onAddressTapped}
          onClose={() => onCloseListPicker()}
          canDelete={!!onDelete}
          onDelete={onDeleteAddressTapped}
          canEdit={!!onEdit}
          onEdit={onEditAddressTapped}
          button={
            allowAddAddress
              ? {
                  title: "+ Add Address",
                  className: "bg-button text-white",
                  onClick: () => {
                    onCloseListPicker();
                    onRequestAddOrEditAddress();
                  }
                }
              : null
          }
        />
      ) : null}
    </div>
  );
}

interface IDetailedAddressInputProps extends IDetailedAddressProps {
  fieldData: {
    key?: string;
    title: string;
    direction?: string;
    className?: string;
    required?: boolean;
    invalid?: boolean;
    errorMsg?: boolean;
  };
}

export function DetailAddressInputField({
  fieldData,
  ...props
}: IDetailedAddressInputProps) {
  const isHorizontal = fieldData.direction !== INPUT_VIEW_DIRECTION.VERTICAL;
  return (
    <div
      className={
        (isHorizontal
          ? "row row-responsive justify-content-between align-items-start position-relative "
          : "column parent-width ") + (fieldData.className || "")
      }
    >
      <div
        className={
          "row-auto-width mb-xs mr-l " +
          (isHorizontal ? "dkinput-header-section mt-r flex-shrink-0" : "")
        }
      >
        <DKLabel
          text={
            getCapitalized(fieldData.title) +
            (props.required === true ? " *" : "")
          }
          className={"unselectable "}
        />
      </div>
      <div
        className={
          "column cursor-hand " +
          (isHorizontal ? " parent-width " : " dkinput-value-section ")
        }
      >
        <DetailedAddress
          {...props}
          className={
            (props.className || "") +
            " dkinput-textfield-height bg-dk-input border-radius-s"
          }
          styles={{
            ...(props.styles || {}),
            border: props.invalid
              ? "1.2px solid rgba(201, 34, 45, 0.7)"
              : "1.2px solid rgb(235, 235, 235)"
          }}
        />
        {props.errorMsg && props.invalid && (
          <div className="position-relative parent-width ">
            <DKLabel
              text={
                Utility.isEmptyObject(fieldData.title)
                  ? "Please fill out this field"
                  : `Enter a valid ${fieldData.title.toLowerCase()}`
              }
              className="text-red ml-xs mt-xs position-absolute"
              style={{ fontSize: 11 }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
