import React, { Component } from "react";
import { DKLabel, DKIcon } from "deskera-ui-library";

import ic_arrow_down from "../../assets/icons/ic_arrow_down.png";
import { getCapitalized } from "../../utility/Utility";

export const localFontFamily = [
  "Arial",
  "Arial Black",
  "Comic Sans MS",
  "Courier New",
  "georgia",
  "Luicida Sans Unicode",
  "tahoma",
  "times new roman",
  "verdana"
];
export const googlefontFamily = [
  "Roboto",
  "Open Sans",
  "Noto Sans JP",
  "Lato",
  "Montserrat",
  "Source Sans Pro",
  "Roboto Condensed",
  "Poppins",
  "Oswald",
  "Noto Sans",
  "Roboto Mono",
  "Raleway",
  "Nunito",
  "Merriweather",
  "PT Sans",
  "Ubuntu",
  "Roboto Slab",
  "Playfair Display",
  "Open Sans Condensed",
  "Rubik",
  "Lora",
  "Noto Sans KR",
  "Work Sans",
  "Inter",
  "Mukta",
  "Hind Siliguri",
  "Nunito Sans",
  "Nanum Gothic",
  "Fira Sans",
  "Quicksand",
  "PT Serif",
  "Titillium Web",
  "Noto Sans TC",
  "Noto Serif",
  "Karla",
  "Inconsolata",
  "Barlow",
  "Heebo",
  "Mulish",
  "Oxygen",
  "Anton",
  "Arimo",
  "Dosis",
  "Libre Franklin",
  "PT Sans Narrow",
  "Libre Baskerville",
  "Josefin Sans",
  "Source Code Pro",
  "Bebas Neue",
  "Bitter",
  "Crimson Text",
  "Cabin",
  "IBM Plex Sans",
  "Lobster",
  "Hind",
  "Dancing Script",
  "Noto Sans SC",
  "Prompt",
  "Source Serif Pro",
  "EB Garamond",
  "Varela Round",
  "Fjalla One",
  "Cairo",
  "Teko",
  "Yanone Kaffeesatz",
  "DM Sans",
  "Abel",
  "Comfortaa",
  "Arvo",
  "Barlow Condensed",
  "Pacifico",
  "Exo 2",
  "Zilla Slab",
  "Indie Flower",
  "Kanit",
  "Merriweather Sans",
  "Shadows Into Light",
  "Maven Pro",
  "Signika Negative",
  "Abril Fatface",
  "Assistant",
  "Asap",
  "Rajdhani",
  "Cormorant Garamond",
  "Overpass",
  "Questrial",
  "IBM Plex Serif",
  "Manrope",
  "Noto Serif JP",
  "Patrick Hand",
  "Hind Madurai",
  "Slabo 27px",
  "Padauk",
  "Archivo Narrow",
  "Noto Sans HK",
  "Fira Sans Condensed",
  "Architects Daughter",
  "Permanent Marker",
  "Tajawal",
  "Acme",
  "Catamaran",
  "Martel",
  "Caveat",
  "Domine",
  "Amatic SC",
  "Nanum Myeongjo",
  "Exo",
  "Play",
  "Bree Serif",
  "M PLUS Rounded 1c",
  "Balsamiq Sans",
  "Signika",
  "Secular One",
  "Righteous",
  "Antic Slab",
  "Sarabun",
  "Satisfy",
  "Spartan",
  "Alfa Slab One",
  "Cookie",
  "Vollkorn",
  "Fredoka One",
  "ABeeZee",
  "Archivo",
  "Cinzel",
  "Crete Round",
  "Patua One",
  "Barlow Semi Condensed",
  "Alegreya Sans",
  "Amiri",
  "Space Mono",
  "Public Sans",
  "Courgette",
  "M PLUS 1p",
  "Tinos",
  "Gothic A1",
  "Ubuntu Condensed",
  "Almarai",
  "Kaushan Script",
  "Frank Ruhl Libre",
  "Jost",
  "Alegreya",
  "Great Vibes",
  "Lobster Two",
  "PT Sans Caption",
  "Staatliches",
  "Chakra Petch",
  "Francois One",
  "Archivo Black",
  "IBM Plex Mono",
  "Space Grotesk",
  "Yantramanav",
  "Red Hat Display",
  "Parisienne",
  "Merienda",
  "Spectral",
  "Cardo",
  "Encode Sans",
  "Didact Gothic",
  "Prata",
  "Saira Condensed",
  "Cuprum",
  "Orbitron",
  "Kalam",
  "Noticia Text",
  "Baloo 2",
  "Russo One",
  "Quattrocento Sans",
  "DM Serif Display",
  "Old Standard TT",
  "Gloria Hallelujah",
  "Monda",
  "Volkhov",
  "Sacramento",
  "Concert One",
  "Rokkitt",
  "Changa",
  "Cantarell",
  "News Cycle",
  "Ropa Sans",
  "Pathway Gothic One",
  "Josefin Slab",
  "Sawarabi Mincho",
  "Chivo",
  "Luckiest Guy",
  "Passion One",
  "Special Elite",
  "Quattrocento",
  "Noto Serif TC",
  "Unna",
  "Playfair Display SC",
  "Faustina",
  "Poiret One",
  "Yellowtail",
  "Bangers",
  "Sigmar One",
  "Cormorant",
  "Asap Condensed",
  "Advent Pro",
  "Neuton",
  "Press Start 2P",
  "Sanchez",
  "Vidaloka",
  "Crimson Pro",
  "Mate SC",
  "Playball",
  "Montserrat Alternates",
  "Monoton",
  "Hammersmith One",
  "Khand",
  "Saira Semi Condensed",
  "Taviraj",
  "Economica",
  "Philosopher",
  "Istok Web",
  "Tangerine",
  "PT Mono",
  "Neucha",
  "Marcellus",
  "Ultra",
  "Handlee",
  "Karma",
  "Saira",
  "Fira Sans Extra Condensed",
  "Paytone One",
  "Alata",
  "Pragati Narrow",
  "Mitr",
  "Arapey",
  "Actor",
  "Ubuntu Mono",
  "Sawarabi Gothic",
  "El Messiri",
  "Gelasio",
  "Alice",
  "Ruda",
  "Cabin Condensed",
  "Gudea",
  "Lusitana",
  "Jura",
  "Carter One",
  "BenchNine",
  "Oleo Script",
  "Homemade Apple",
  "Bai Jamjuree",
  "Rock Salt",
  "Amaranth",
  "Allura",
  "Hind Vadodara",
  "Unica One",
  "Gentium Basic",
  "Yeseva One",
  "Mukta Malar",
  "Viga",
  "DM Serif Text",
  "Khula",
  "Adamina",
  "Mali",
  "Aleo",
  "Noto Serif SC",
  "Julius Sans One",
  "Armata",
  "Nanum Gothic Coding",
  "Markazi Text",
  "Marck Script",
  "Palanquin",
  "Bad Script",
  "Recursive",
  "Abhaya Libre",
  "Martel Sans",
  "Pontano Sans",
  "Alef",
  "Noto Serif KR",
  "Nanum Pen Script",
  "Kosugi Maru",
  "Squada One",
  "Knewave",
  "Lexend Deca",
  "Jaldi",
  "Gentium Book Basic",
  "Electrolize",
  "Changa One",
  "Tenor Sans",
  "Sarala",
  "Nothing You Could Do",
  "Quantico",
  "Gruppo",
  "Arima Madurai",
  "Mandali",
  "Mr Dafoe",
  "Bungee",
  "Sorts Mill Goudy",
  "Alex Brush",
  "Sen",
  "Cousine",
  "Gochi Hand",
  "Fugaz One",
  "Pangolin",
  "Ramabhadra",
  "Voltaire",
  "Rufina",
  "Shadows Into Light Two",
  "Boogaloo",
  "Damion",
  "Basic",
  "Aclonica",
  "Cantata One",
  "Courier Prime",
  "Syncopate",
  "Audiowide",
  "Red Hat Text",
  "Itim",
  "Baloo Tamma 2",
  "Suez One",
  "Mate",
  "Lilita One",
  "Coda",
  "Black Ops One",
  "Allerta",
  "Rancho",
  "Antic",
  "Pridi",
  "Encode Sans Condensed",
  "Share Tech Mono",
  "Varela",
  "Alegreya Sans SC",
  "Fira Mono",
  "Candal",
  "Yrsa",
  "Sriracha",
  "Lalezar",
  "VT323",
  "Reem Kufi",
  "Commissioner",
  "Scheherazade",
  "Spinnaker",
  "Hind Guntur",
  "Berkshire Swash",
  "Forum",
  "Niconne",
  "Six Caps",
  "Arsenal",
  "Mada",
  "Sintony",
  "Fredericka the Great",
  "Eczar",
  "Italianno",
  "Kreon",
  "Literata",
  "Reenie Beanie",
  "Niramit",
  "Chewy",
  "Average",
  "Rubik Mono One",
  "Days One",
  "Pinyon Script",
  "Bubblegum Sans",
  "Enriqueta",
  "Coda Caption",
  "Mukta Vaani",
  "Glegoo",
  "Telex",
  "Shrikhand",
  "Michroma",
  "Krub",
  "Anonymous Pro",
  "Caveat Brush",
  "Covered By Your Grace",
  "Laila",
  "PT Serif Caption",
  "Overlock",
  "Allan",
  "Lateef",
  "Kameron",
  "Cabin Sketch",
  "Lemonada",
  "Arizonia",
  "Bowlby One SC",
  "Capriola",
  "Bevan",
  "Black Han Sans",
  "Rambla",
  "Arbutus Slab",
  "Sansita",
  "Do Hyeon",
  "Aldrich",
  "Annie Use Your Telescope",
  "Londrina Solid",
  "Nobile",
  "Just Another Hand",
  "Saira Extra Condensed",
  "Average Sans",
  "Caudex",
  "Titan One",
  "Blinker",
  "Racing Sans One",
  "Gilda Display",
  "Cinzel Decorative",
  "Darker Grotesque",
  "Judson",
  "Holtwood One SC",
  "Mrs Saint Delafield",
  "Norican",
  "Comic Neue",
  "Corben",
  "Miriam Libre",
  "Rye",
  "Ovo",
  "Herr Von Muellerhoff",
  "Stint Ultra Condensed",
  "Trirong",
  "Rozha One",
  "Leckerli One",
  "Fauna One",
  "Yesteryear",
  "Allerta Stencil",
  "Belgrano",
  "Biryani",
  "Amita",
  "Charm",
  "Alegreya SC",
  "Delius",
  "Lustria",
  "Kristi",
  "Coming Soon",
  "Nixie One",
  "Merienda One",
  "Molengo",
  "Rochester",
  "Castoro",
  "Athiti",
  "Share",
  "Oranienbaum",
  "Alatsi",
  "GFS Didot",
  "Epilogue",
  "Scada",
  "Contrail One",
  "IBM Plex Sans Condensed",
  "Nanum Brush Script",
  "Jua",
  "Magra",
  "Seaweed Script",
  "Bungee Inline",
  "Trocchi",
  "Carme",
  "Belleza",
  "Copse",
  "Amiko",
  "Suranna",
  "Marcellus SC",
  "Libre Caslon Text",
  "Coustard",
  "Graduate",
  "K2D",
  "Maitree",
  "Thasadith",
  "Palanquin Dark",
  "Mr De Haviland",
  "Pattaya",
  "KoHo",
  "Sora",
  "Creepster",
  "Krona One",
  "Bellefair",
  "Overpass Mono",
  "Averia Serif Libre",
  "Be Vietnam",
  "Cedarville Cursive",
  "Halant",
  "Carrois Gothic",
  "Slabo 13px",
  "Schoolbell",
  "Marmelad",
  "Jockey One",
  "Grand Hotel",
  "Lexend",
  "Wallpoet",
  "Metrophobic",
  "Calligraffitti",
  "Duru Sans",
  "Sunflower",
  "Love Ya Like A Sister",
  "Baskervville",
  "La Belle Aurore",
  "Aladin",
  "Sniglet",
  "Big Shoulders Display",
  "Podkova",
  "Chonburi",
  "Kosugi",
  "Petit Formal Script",
  "Amethysta",
  "Tillana",
  "Poly",
  "Cormorant Infant",
  "Radley",
  "Spectral SC",
  "Manjari",
  "Rosario",
  "Oxygen Mono",
  "Encode Sans Semi Condensed",
  "ZCOOL QingKe HuangYou",
  "Turret Road",
  "Baloo Da 2",
  "Cutive Mono",
  "Fira Code",
  "Hanuman",
  "Kelly Slab",
  "Rammetto One",
  "Cambay",
  "Averia Libre",
  "Bungee Shade",
  "Kadwa",
  "Gugi",
  "Andada",
  "Kumbh Sans",
  "David Libre",
  "Bowlby One",
  "Lekton",
  "NTR",
  "Emilys Candy",
  "Baloo Chettan 2",
  "Sofia",
  "IM Fell DW Pica",
  "Marvel",
  "Chelsea Market",
  "Harmattan",
  "Grandstander",
  "Gravitas One",
  "Oregano",
  "Proza Libre",
  "Mirza",
  "Alike",
  "Libre Barcode 39",
  "Bentham",
  "IM Fell English",
  "Monsieur La Doulaise",
  "IM Fell Double Pica",
  "Oleo Script Swash Caps",
  "Esteban",
  "Dawning of a New Day",
  "Goudy Bookletter 1911",
  "Cambo",
  "Gabriela",
  "Hi Melody",
  "Aguafina Script",
  "Fanwood Text",
  "Buenard",
  "Limelight",
  "Cormorant SC",
  "Qwigley",
  "Quando",
  "Hepta Slab",
  "Averia Sans Libre",
  "Lemon",
  "Megrim",
  "Balthazar",
  "Sue Ellen Francisco",
  "Battambang",
  "Cutive",
  "ZCOOL XiaoWei",
  "Convergence",
  "DM Mono",
  "B612 Mono",
  "JetBrains Mono",
  "UnifrakturMaguntia",
  "Finger Paint",
  "Waiting for the Sunrise",
  "Skranji",
  "Rasa",
  "Brawler",
  "Frijole",
  "Sarpanch",
  "Original Surfer",
  "Stardos Stencil",
  "Sedgwick Ave",
  "Kurale",
  "Pompiere",
  "RocknRoll One",
  "Rouge Script",
  "Zeyada",
  "Montez",
  "Anaheim",
  "Alike Angular",
  "Grenze Gotisch",
  "Bodoni Moda",
  "Expletus Sans",
  "Baumans",
  "Give You Glory",
  "Baloo Thambi 2",
  "Meddon",
  "Galada",
  "Mansalva",
  "Ceviche One",
  "Happy Monkey",
  "McLaren",
  "B612",
  "Vast Shadow",
  "Caladea",
  "Nova Mono",
  "Montaga",
  "Doppio One",
  "Faster One",
  "Raleway Dots",
  "Rakkas",
  "Inder",
  "Calistoga",
  "Big Shoulders Text",
  "Andika",
  "Farro",
  "Antic Didone",
  "Katibeh",
  "Freckle Face",
  "Mallanna",
  "Livvic",
  "Sail",
  "Wendy One",
  "Major Mono Display",
  "Dokdo",
  "Patrick Hand SC",
  "Della Respira",
  "Clicker Script",
  "Over the Rainbow",
  "Atma",
  "Chau Philomene One",
  "Federo",
  "Antonio",
  "Homenaje",
  "Newsreader",
  "Fondamento",
  "Share Tech",
  "Numans",
  "Short Stack",
  "Gurajada",
  "Vesper Libre",
  "BioRhyme",
  "Walter Turncoat",
  "Euphoria Script",
  "Vollkorn SC",
  "Orienta",
  "Nova Round",
  "Almendra",
  "Mouse Memoirs",
  "Unkempt",
  "Inknut Antiqua",
  "Iceland",
  "Strait",
  "Oxanium",
  "IM Fell English SC",
  "STIX Two Text",
  "Shojumaru",
  "Tenali Ramakrishna",
  "Mako",
  "Mountains of Christmas",
  "Cormorant Upright",
  "Ledger",
  "Codystar",
  "Tienne",
  "Mukta Mahee",
  "Aref Ruqaa",
  "Italiana",
  "Trade Winds",
  "Goblin One",
  "Crafty Girls",
  "Salsa",
  "Syne",
  "Montserrat Subrayada",
  "Cherry Cream Soda",
  "Vampiro One",
  "Baloo Tammudu 2",
  "Delius Swash Caps",
  "Encode Sans Expanded",
  "Style Script",
  "Ranga",
  "Bilbo Swash Caps",
  "Notable",
  "Meera Inimai",
  "Shippori Mincho",
  "Timmana",
  "Bellota Text",
  "Yatra One",
  "Lexend Zetta",
  "Arya",
  "Peralta",
  "Ranchers",
  "Yeon Sung",
  "Asul",
  "Life Savers",
  "Geo",
  "Ruslan Display",
  "Flamenco",
  "Coiny",
  "Odibee Sans",
  "Loved by the King",
  "The Girl Next Door",
  "Artifika",
  "Carrois Gothic SC",
  "Mina",
  "Encode Sans Semi Expanded",
  "Prosto One",
  "Ma Shan Zheng",
  "Port Lligat Sans",
  "Dynalight",
  "Amarante",
  "Scope One",
  "Headland One",
  "Elsie",
  "Imprima",
  "Wire One",
  "Red Rose",
  "Medula One",
  "Nokora",
  "Metamorphous",
  "Cherry Swash",
  "Georama",
  "Atkinson Hyperlegible",
  "Poller One",
  "Puritan",
  "Yusei Magic",
  "Gafata",
  "Eater",
  "League Script",
  "Overlock SC",
  "Stalemate",
  "Cantora One",
  "Lily Script One",
  "Gaegu",
  "Charmonman",
  "Just Me Again Down Here",
  "Sarina",
  "Goldman",
  "Voces",
  "Slackey",
  "UnifrakturCook",
  "Milonga",
  "IM Fell French Canon",
  "Fjord One",
  "Tauri",
  "Nova Square",
  "Delius Unicase",
  "Song Myung",
  "Denk One",
  "Koulen",
  "Kodchasan",
  "Germania One",
  "Kite One",
  "Reggae One",
  "Saira Stencil One",
  "Vibur",
  "Engagement",
  "Manuale",
  "Kranky",
  "Shanti",
  "Baloo Bhai 2",
  "Pavanam",
  "Port Lligat Slab",
  "Libre Barcode 39 Text",
  "Baloo Paaji 2",
  "New Rocker",
  "Bilbo",
  "Lovers Quarrel",
  "Baloo Bhaina 2",
  "Mogra",
  "Henny Penny",
  "Jomhuria",
  "Fontdiner Swanky",
  "Inika",
  "Spicy Rice",
  "Bubbler One",
  "Cormorant Unicase",
  "Fresca",
  "Khmer",
  "Habibi",
  "Ramaraja",
  "Gamja Flower",
  "Paprika",
  "Julee",
  "Farsan",
  "Ribeye",
  "Modak",
  "Londrina Outline",
  "IM Fell Great Primer",
  "Chango",
  "Crushed",
  "Fahkwang",
  "Quintessential",
  "Dela Gothic One",
  "Lakki Reddy",
  "Kiwi Maru",
  "Dekko",
  "Fraunces",
  "Rationale",
  "Prociono",
  "Simonetta",
  "Sancreek",
  "Jolly Lodger",
  "Text Me One",
  "Libre Caslon Display",
  "Stoke",
  "Mystery Quest",
  "East Sea Dokdo",
  "IM Fell French Canon SC",
  "Rosarivo",
  "Cute Font",
  "Uncial Antiqua",
  "Averia Gruesa Libre",
  "Chicle",
  "Shippori Mincho B1",
  "Englebert",
  "Zilla Slab Highlight",
  "Stint Ultra Expanded",
  "Ibarra Real Nova",
  "Ruluko",
  "Sonsie One",
  "Hanalei Fill",
  "Asar",
  "Sumana",
  "Kotta One",
  "Pirata One",
  "Donegal One",
  "Sulphur Point",
  "Kulim Park",
  "Cagliostro",
  "IM Fell DW Pica SC",
  "Barriecito",
  "Palette Mosaic",
  "Kumar One",
  "Fenix",
  "Offside",
  "Akronim",
  "Libre Barcode 39 Extended Text",
  "Macondo Swash Caps",
  "Solway",
  "Condiment",
  "Lexend Exa",
  "Bellota",
  "Girassol",
  "Sura",
  "Moul",
  "Hachi Maru Pop",
  "Gotu",
  "Redressed",
  "Sree Krushnadevaraya",
  "Nova Flat",
  "Tulpen One",
  "Chathura",
  "Nosifer",
  "Kavivanar",
  "Tomorrow",
  "Buda",
  "Margarine",
  "Angkor",
  "Inria Serif",
  "Swanky and Moo Moo",
  "Stylish",
  "Trykker",
  "Piazzolla",
  "Croissant One",
  "Monofett",
  "Libre Barcode 128",
  "Bahiana",
  "Piedra",
  "Miniver",
  "Junge",
  "Autour One",
  "Barrio",
  "DotGothic16",
  "Bigelow Rules",
  "Eagle Lake",
  "Dorsa",
  "Gorditas",
  "Joti One",
  "Wellfleet",
  "Rum Raisin",
  "Rowdies",
  "Potta One",
  "Meie Script",
  "Marko One",
  "Jomolhari",
  "Train One",
  "Maiden Orange",
  "Zen Dots",
  "Poor Story",
  "Linden Hill",
  "Kantumruy",
  "Sansita Swashed",
  "Sahitya",
  "Kufam",
  "Bayon",
  "Ravi Prakash",
  "Atomic Age",
  "Kdam Thmor",
  "Devonshire",
  "Iceberg",
  "Petrona",
  "Ruthie",
  "Modern Antiqua",
  "Content",
  "Felipa",
  "Griffy",
  "Molle",
  "Arbutus",
  "Jim Nightshade",
  "Elsie Swash Caps",
  "Mr Bedfort",
  "Spirax",
  "Rhodium Libre",
  "MuseoModerno",
  "Sirin Stencil",
  "Lancelot",
  "Unlock",
  "Kavoon",
  "Bigshot One",
  "Beth Ellen",
  "Metal Mania",
  "Galdeano",
  "Mrs Sheppards",
  "Ribeye Marrow",
  "Risque",
  "Caesar Dressing",
  "Otomanopee One",
  "Almendra SC",
  "Diplomata SC",
  "Sedgwick Ave Display",
  "Romanesco",
  "Londrina Shadow",
  "Srisakdi",
  "Diplomata",
  "Stick",
  "Asset",
  "Libre Barcode 128 Text",
  "Gayathri",
  "Lexend Mega",
  "Peddana",
  "Revalia",
  "Odor Mean Chey",
  "Siemreap",
  "ZCOOL KuaiLe",
  "Varta",
  "Glass Antiqua",
  "Long Cang",
  "Andada Pro",
  "Flavors",
  "Smythe",
  "Princess Sofia",
  "MedievalSharp",
  "Chilanka",
  "Londrina Sketch",
  "Smokum",
  "Ewert",
  "Underdog",
  "Snippet",
  "Keania One",
  "Andika New Basic",
  "WindSong",
  "Irish Grover",
  "Chela One",
  "Benne",
  "Gupter",
  "Jacques Francois Shadow",
  "Syne Mono",
  "Metal",
  "Nova Slim",
  "Bokor",
  "Kirang Haerang",
  "Liu Jian Mao Cao",
  "Hahmlet",
  "Dr Sugiyama",
  "Kumar One Outline",
  "Macondo",
  "Oldenburg",
  "Grenze",
  "Tourney",
  "Galindo",
  "Libre Barcode 39 Extended",
  "IM Fell Great Primer SC",
  "Miss Fajardose",
  "Emblema One",
  "Jacques Francois",
  "Chenla",
  "Freehand",
  "Dangrek",
  "Lexend Giga",
  "Taprom",
  "GFS Neohellenic",
  "Fascinate Inline",
  "Bungee Outline",
  "Inria Sans",
  "Trochut",
  "Almendra Display",
  "Plaster",
  "Bahianita",
  "Combo",
  "Federant",
  "Akaya Telivigala",
  "Butterfly Kids",
  "Akaya Kanadaka",
  "STIX Two Math",
  "Purple Purse",
  "Fascinate",
  "Texturina",
  "IM Fell Double Pica SC",
  "Zhi Mang Xing",
  "Sunshiney",
  "Brygada 1918",
  "Snowburst One",
  "MonteCarlo",
  "Erica One",
  "Big Shoulders Stencil Text",
  "Viaoda Libre",
  "Preahvihear",
  "Gidugu",
  "Orelega One",
  "Ruge Boogie",
  "Nerko One",
  "Supermercado One",
  "Nova Script",
  "Bungee Hairline",
  "Passero One",
  "Stalinist One",
  "Black And White Picture",
  "Hanalei",
  "Bonbon",
  "Seymour One",
  "Astloch",
  "Yomogi",
  "Nova Cut",
  "New Tegomin",
  "Sevillana",
  "Vibes",
  "Miltonian",
  "Sofadi One",
  "Suwannaphum",
  "Miltonian Tattoo",
  "Suravaram",
  "Big Shoulders Stencil Display",
  "Nova Oval",
  "Aubrey",
  "Lacquer",
  "Moulpali",
  "Fasthand",
  "Encode Sans SC",
  "Lexend Tera",
  "Single Day",
  "Ballet",
  "Butcherman",
  "Dhurjati",
  "Fruktur",
  "BioRhyme Expanded",
  "Fuggles",
  "Truculenta",
  "Geostar Fill",
  "Imbue",
  "Big Shoulders Inline Text",
  "Xanh Mono",
  "Lexend Peta",
  "Kenia",
  "Bona Nova",
  "Gowun Batang",
  "Gowun Dodum",
  "Zen Tokyo Zoo",
  "Zen Loop",
  "Trispace",
  "Karantina",
  "Geostar",
  "Langar",
  "Festive",
  "Warnes",
  "Qahiri",
  "Big Shoulders Inline Display",
  "Oi",
  "Libre Barcode EAN13 Text",
  "Syne Tactile",
  "Uchen",
  "Alumni Sans",
  "Allison",
  "Azeret Mono",
  "Glory",
  "IBM Plex Sans Arabic",
  "IBM Plex Sans Hebrew",
  "IBM Plex Sans Devanagari",
  "IBM Plex Sans Thai",
  "IBM Plex Sans KR",
  "IBM Plex Sans Thai Looped",
  "Klee One",
  "Hina Mincho",
  "Rampart One",
  "Besley",
  "Urbanist",
  "Kaisei Decol",
  "Kaisei Opti",
  "Kaisei HarunoUmi",
  "Kaisei Tokumin"
];
export default class FontFamilyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFontFamily: this.props.currentFontFamily
        ? this.props.currentFontFamily
        : "Arial",
      isShowDropDown: false,
      isSystemFont: this.props.isSystemFont ? this.props.isSystemFont : false,
      fontList: this.props.isSystemFont
        ? [...localFontFamily]
        : [...localFontFamily, ...googlefontFamily],
      fonts: this.props.isSystemFont
        ? [...localFontFamily]
        : [...localFontFamily, ...googlefontFamily]
    };
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.onMouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.onMouseUp);
  }

  render() {
    return (
      <div className="row" id="dk-font-list-picker">
        <DKLabel text="Font Family" className="fw-m mt-s mb-s mr-s" />
        <div className="column position-relative ">
          <div
            className="row cursor-hand border-m bg-gray1 border-radius-s"
            style={{ padding: "3px 10px" }}
            onClick={(e) => {
              e.preventDefault();
              this.setState({ isShowDropDown: !this.state.isShowDropDown });
            }}
          >
            <DKLabel
              text={getCapitalized(this.state.currentFontFamily)}
              className=""
            />
            <DKIcon src={ic_arrow_down} className="ic-xs ml-s" />
          </div>
          {this.state.isShowDropDown && (
            <div
              className="position-absolute z-index-3 shadow-l bg-white border-radius-m border-m"
              style={{
                left: -13,
                top: 25,
                width: 150,
                textAlign: "left",
                maxHeight: 330
              }}
            >
              {this.getTextField()}
              {this.state.fonts.length === 0 && this.getNoRecordSection()}
              <div
                className="mt-xs"
                style={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  maxHeight: 250
                }}
              >
                {this.state.fonts.map((fontName, i) => {
                  return (
                    <div
                      data-id="i"
                      className="font-list listPickerBG p-v-s p-h-m cursor-hand fs-r"
                      style={{ fontFamily: fontName }}
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.onChangeValue(fontName);
                        this.hideDropDown();
                        this.textChanged("");
                        this.setState({ currentFontFamily: fontName });
                      }}
                    >
                      {getCapitalized(fontName)}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  onMouseUp = (e) => {
    e.preventDefault();
    if (
      e.target.classList.contains("font-list") ||
      e.target.classList.contains("searchlist")
    ) {
      return;
    }
    if (
      "dk-font-list-picker" === e.target.id ||
      "page-designer-editor" === e.target.id ||
      (this.state.isShowDropDown && e.target.id === "")
    ) {
      setTimeout(() => {
        this.hideDropDown();
      }, 10);
    }
  };

  getTextField() {
    return (
      <div className={"parent-width border-box p-h-s app-font mt-r "}>
        <input
          autoComplete="off"
          spellCheck={false}
          className="border-none bg-gray1 border-s p-h-s p-v-xs border-radius-s  border-box parent-width searchlist"
          style={{ outline: "none" }}
          onChange={(e) => {
            this.textChanged(e.target.value);
          }}
          placeholder="Search here"
          autoFocus
        />
      </div>
    );
  }

  textChanged = (value) => {
    this.filterDataFor(value);
  };

  filterDataFor(value) {
    let mainData = this.state.fontList;
    let filteredData = mainData.filter((obj) =>
      obj.toLowerCase().startsWith(value.toLowerCase())
    );
    this.setState({
      fonts: value === "" ? mainData : filteredData
    });
  }

  hideDropDown = () => {
    this.setState({ isShowDropDown: false });
  };

  getNoRecordSection = () => {
    return (
      <div className="p-v-m p-h-m">
        <DKLabel text="No record found" />
      </div>
    );
  };
}
