import ApiConstants from "../constants/ApiConstants";
import { getCustomAxiosInstance } from "../http";
import { PAGE_ROUTES } from "../managers/RouteManager";
const config = {
  withCredentials: true,
  baseURL: process.env.REACT_APP_STRIPE_END_POINT
};
export class Stripe {
  static getRedirectURL() {
    const URL = encodeURIComponent(
      `${process.env.REACT_APP_URL_CRM_PLUS}${PAGE_ROUTES.SETTINGS.replace("/", "")}`
    ); //need to remove this
    return getCustomAxiosInstance(config).get(
      `${ApiConstants.URL.PAYMENT.GET_REDIRECT_URL}?redirect_uri=${URL}`
    );
  }
  static getStripeAccounts() {
    return getCustomAxiosInstance(config).get(
      `${ApiConstants.URL.PAYMENT.GET_ACCOUNTS}`
    );
  }
  static addStripeAccount(params) {
    return getCustomAxiosInstance(config).get(
      `${ApiConstants.URL.PAYMENT.ADD_ACCOUNTS}code=${params.code}&state=${params.state}&accountName=""`
    );
  }
}
