import ApiConstants from "../../constants/ApiConstants";
import httpClient from "../../http";

export default class RolesService {
  private static _instance: RolesService;
  private constructor() {}
  public static getInstance(): RolesService {
    if (!this._instance) {
      this._instance = new RolesService();
    }
    return this._instance;
  }
  public fetchRoles(params = {}) {
    return httpClient.get(ApiConstants.URL.ROLE.GET_ROLES, { params });
  }
  public fetchRoleById(id: string) {
    return httpClient.get(ApiConstants.URL.ROLE.GET_ROLE_BY_ID(id));
  }
  public addRole(payload: any) {
    return httpClient.post(ApiConstants.URL.ROLE.ADD_ROLE, payload);
  }
  public updateRole(payload: any) {
    return httpClient.patch(
      ApiConstants.URL.ROLE.UPDATE_ROLE(payload.id),
      payload
    );
  }
  public deleteRole(id: string) {
    return httpClient.delete(ApiConstants.URL.ROLE.DELETE_ROLE(id));
  }
  public fetchLoggedInUserRole() {
    return httpClient.get(ApiConstants.URL.ROLE.GET_LOGGED_IN_USER_ROLE);
  }
}
