import React from "react";

const DEFAULT_STACK_TILE_STYLES = {
  pointerEvents: "none",
  zIndex: -1,
  transform: "translate3d(0px, 50%, 0)",
  opacity: 0
};

const AnimatedStackItem = ({ id, name, color, bgColor, styles }) => {
  return (
    <div
      className={`dk-animated-stack-item ${bgColor} ${color}`}
      key={id}
      style={{ ...(styles ?? DEFAULT_STACK_TILE_STYLES) }}
    >
      <span>{name}</span>
    </div>
  );
};

export default AnimatedStackItem;
