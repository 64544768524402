import { DKButton, DKIcons, DKLabel } from "deskera-ui-library";
import { isViewportMobile } from "../../utility/GetViewportSize";
const OrgSettingComponent = ({
  title,
  description,
  url = "",
  buttonName,
  baseUrl
}) => {
  return (
    <div className="column parent-width border-m border-radius-m  p-r mt-l mb-m" >
      <div className="row justify-content-between " >
        <div className="column" >
          <DKLabel text={title} className="mr-m mb-xs fw-m" />
          <DKLabel className="text-gray text-ellipsis" text={description} />
        </div>
        <div className="column m-auto justify-content-between ml-m">
          <DKButton
            title={isViewportMobile() ? "" : buttonName}
            className="align-self-end bg-gray1 border-m"
            icon={DKIcons.ic_arrow_right2}
            onClick={() => window.open(baseUrl + url, "_blank")}
            isReverse={true}
          />
        </div>
      </div>
    </div>
  );
};

export default OrgSettingComponent;
