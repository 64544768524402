import ApiConstants from "../constants/ApiConstants";
import http from "../http/index";
import {
  IWhatsAppRecord,
  IWhatsAppTemplateResponseData
} from "../model/WhatsAppCampaign";

const MAX_TEMPLATE_LIMIT = 1000;

export default class WhatsAppService {
  static async getWhatsappAccounts() {
    return http.get(ApiConstants.URL.WHATSAPP.GET_ACCOUNT_LIST);
  }

  static async getWhatsappTemplatesByAccount(
    accountId: number,
    params = { limit: MAX_TEMPLATE_LIMIT, offset: 0 }
  ): Promise<IWhatsAppTemplateResponseData[]> {
    const requestUrl = ApiConstants.URL.WHATSAPP.GET_ALL_TEMPLATES(accountId);
    return http
      .get(requestUrl, { params })
      .then((res: any) => res.waba_templates);
  }

  static async linkWhatsAppAccount(
    accountData: IWhatsAppRecord
  ): Promise<IWhatsAppRecord> {
    return http.post(ApiConstants.URL.WHATSAPP.LINK_ACCOUNT, accountData);
  }

  static async deleteLinkWhatsAppAccount(id: number): Promise<IWhatsAppRecord> {
    return http.delete(ApiConstants.URL.WHATSAPP.UNLINK_ACCOUNT(id));
  }

  static async unlinkWhatsappAccount(accountId: number) {}
}
