import { Component } from "react";
import TenantManager from "../../../managers/TenantManager";
import AddContactAddressPopup from "../../common/AddContactAddressPopup";
import Utility from "../../../utility/Utility";
import { INPUT_TYPE } from "deskera-ui-library";
import CampaignManager from "../../campaign/CampaignManager";
import { LOCAL_STORAGE_KEYS } from "../../../constants/Enum";
import {
  PAGE_DESIGNER_EVENTS,
  pageDesignerCustomEvent
} from "../../../services/event";

/*
PROPS
- isEditing
*/

export default class CampaignFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopUp: false
    };
    this.formData = [
      {
        key: "address1",
        title: "Address Line 1",
        type: INPUT_TYPE.TEXT,
        value: "",
        required: false
      },
      {
        key: "address2",
        title: "Address Line 2",
        type: INPUT_TYPE.TEXT,
        value: "",
        required: false
      },
      {
        key: "city",
        title: "City",
        type: INPUT_TYPE.TEXT,
        value: "",
        required: false
      },
      {
        key: "state",
        title: "State",
        type: INPUT_TYPE.TEXT,
        value: "",
        required: false
      },
      {
        key: "country",
        title: "Country",
        type: INPUT_TYPE.TEXT,
        value: "",
        required: false
      },
      {
        key: "postalCode",
        title: "Zipcode",
        type: INPUT_TYPE.TEXT,
        value: "",
        required: false
      }
    ];
  }

  componentDidMount() {
    pageDesignerCustomEvent.on(
      PAGE_DESIGNER_EVENTS.NEED_CAMPAIGN_ADDRESS,
      this.showPopUp
    );

    if (
      CampaignManager.isPageDataAvailable() ||
      !Utility.isEmptyObject(this.props.campaignAddress)
    )
      return;

    let localAddress = this.getLocalContactAddress();
    let tenantDetails = TenantManager.getTenantDetails();
    if (!Utility.isEmptyObject(localAddress?.address)) {
      this.saveAddress({ address: localAddress.address });
    } else if (!Utility.isEmptyObject(tenantDetails?.address)) {
      this.saveAddress({ address: tenantDetails.address });
    }
  }

  componentWillUnmount() {
    pageDesignerCustomEvent.remove(
      PAGE_DESIGNER_EVENTS.NEED_CAMPAIGN_ADDRESS,
      this.showPopUp
    );
  }

  render() {
    return (
      <div id="emailContactDetail" style={{ padding: 15 }}>
        <div
          className={
            this.props.isEditing ? "text-underline cursor-hand text-blue" : ""
          }
          dangerouslySetInnerHTML={{ __html: this.getAddress() }}
          onClick={() => this.props.isEditing && this.showPopUp()}
        ></div>
        {!this.props.unsubscribe && (
          <div id="dk-canvas-unsubscribe" style={{ marginTop: 6 }}>
            You may{" "}
            <a
              style={{
                pointerEvents: this.props.isEditing ? "none" : "auto",
                textDecoration: "none",
                color: "#000000"
              }}
              href="%unsubscribe%"
            >
              unsubscribe
            </a>{" "}
            at any time
          </div>
        )}
        {this.state.showPopUp && (
          <AddContactAddressPopup
            formData={this.getFormFields()}
            onCancel={() => this.hidePopUp()}
            onSave={(data) => this.saveAddress(data)}
          />
        )}
      </div>
    );
  }

  hidePopUp = () => {
    this.setState({ showPopUp: false });
  };

  showPopUp = () => {
    this.setState({ showPopUp: true });
  };

  saveAddress = (data) => {
    Utility.setPersistentValue(
      LOCAL_STORAGE_KEYS.CAMPAIGN_CONTACT_ADDRESS,
      JSON.stringify(data)
    );
    this.props.onCampaignAddressAdded?.(data);
    this.hidePopUp();
  };

  getLocalContactAddress = () => {
    const localAddress = Utility.getPersistentValue(
      LOCAL_STORAGE_KEYS.CAMPAIGN_CONTACT_ADDRESS
    );
    return JSON.parse(localAddress);
  };

  getAddress = () => {
    const { campaignAddress } = this.props;
    let address = "";
    if (!Utility.isEmptyObject(campaignAddress?.address)) {
      address = this.createAddressString(campaignAddress.address);
      CampaignManager.setCampaignFooterStatus(true);
    }

    if (this.props.isEditing && Utility.isEmptyObject(address)) {
      address = `<span class="fw-m"> Add organization address </span>`;
      CampaignManager.setCampaignFooterStatus(false);
    }

    return address;
  };

  createAddressString = (data) => {
    let address = "";
    address = data.address1;
    address = data.address2 ? address + ", " + data.address2 : address;
    address = data.city ? address + ", " + data.city : address;
    address = data.state ? address + ", " + data.state : address;
    address = data.country ? address + ", " + data.country : address;
    address = data.postalCode ? address + ", " + data.postalCode : address;
    return address;
  };

  getFormFields = () => {
    let localAddress = this.props.campaignAddress;
    let formFields = this.formData;
    if (!Utility.isEmptyObject(localAddress?.address)) {
      formFields.forEach((field) => {
        field.value = localAddress.address[field.key];
      });
    }
    return formFields;
  };
}
