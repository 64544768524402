import React, { Component } from "react";
import { DKButton } from "deskera-ui-library";

/*
PROPS
- data [{title, value}]
- onChange(value)
- className
- value
*/

class SegmentControl extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        className={
          "row bg-gray1 border-radius-s border-m justify-content-around hide-scroll-bar " +
          this.props.className
        }
      >
        {this.getAllSegments()}
      </div>
    );
  }
  getAllSegments() {
    let arr = [];
    this.props.data.forEach((segment, index) => {
      arr.push(
        <DKButton
          key={index}
          className={
            "cursor-hand " +
            (this.props.value === segment.value
              ? "text-black fw-m"
              : "text-dark-gray")
          }
          title={segment.title}
          onClick={() => {
            this.props.onChange(segment.value);
          }}
        />
      );
    });
    return arr;
  }
}

export default SegmentControl;
