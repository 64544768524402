import { Component } from "react";
import { DKInputForm } from "deskera-ui-library";

/*
PROPS
- onCancel
- onSave
*/

class AddContactAddressPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: this.props.formData
    };
  }

  render() {
    return (
      <div className="transparent-background">
        <DKInputForm
          autoFocus={true}
          title="Contact Details"
          className="popup-window "
          fields={this.state.formData}
          onSubmit={(data) => {
            this.saveAddress(data);
          }}
          onCancel={this.cancelTapped}
          onChange={(data, changeData) =>
            this.updateContactAddress(data, changeData)
          }
        />
      </div>
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  cancelTapped = () => {
    this.props.onCancel();
  };

  saveAddress = (data) => {
    let dataFormate = {};
    data.forEach((element) => {
      dataFormate[element.key] = element.value;
    });
    let addressData = { address: dataFormate };
    this.props.onSave(addressData);
  };

  updateContactAddress = (data, changeData) => {
    let formFields = this.state.formData;
    let index = formFields.findIndex((item) => item.key === changeData.key);
    formFields[index] = changeData;
    this.setState({ formData: formFields });
  };
}

export default AddContactAddressPopup;
