import React, { useEffect, useState } from "react";
import { DKLabel, LineChart } from "deskera-ui-library";
import DashboardService from "../../../services/dashboard";
import { COLORS } from "../../../constants/Colors";
import { connect, ConnectedProps } from "react-redux";
import DKDropdown from "../DKDropdown";
import { getDashboardWidgetLoading } from "../../dashboard/Dashboard";
import Utility from "../../../utility/Utility";
import { CHART_INTERVALS } from "../../../constants/Constant";
import GraphManager from "../../../managers/GraphManager";
function DealWonLostChart(props: PropsFromRedux) {
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState(
    GraphManager.get("dealWonLost").interval
  );

  useEffect(() => {
    const filterData = (data) => {
      if (selectedInterval.interval === CHART_INTERVALS[1].interval) {
        data.won = data.won.map((item) => ({
          ...item,
          title: new Date(item.title).getDate()
        }));
        data.lost = data.lost.map((item) => ({
          ...item,
          title: new Date(item.title).getDate()
        }));
      }
      setData([
        {
          title: "Won",
          color: COLORS.charts.line.green,
          points: data.won ? data.won : []
        },
        {
          title: "Lost",
          color: COLORS.charts.line.red,
          points: data.lost ? data.lost : []
        }
      ]);
    };
    function getData() {
      DashboardService.getDealWonLostCount({
        interval: selectedInterval.interval
      }).then((data: any) => {
        filterData(data);
        setShowLoader(false);
        GraphManager.set("dealWonLost", {
          data: data,
          interval: selectedInterval
        });
      });
    }
    if (GraphManager.hasData("dealWonLost")) {
      filterData(GraphManager.get("dealWonLost").data);
      setTimeout(getData, 100);
    } else {
      setShowLoader(true);
      getData();
    }
  }, [selectedInterval]);
  return (
    <div
      className="column align-items-center parent-size"
      style={{
        height: 302
      }}
    >
      <div className="row align-items-center justify-content-between mb-l">
        <DKLabel text={"Deals Won vs Lost"} className="fw-m fs-m column" />
        {selectedInterval && (
          <div className="column">
            <DKDropdown
              title={selectedInterval.name}
              data={CHART_INTERVALS.map((interval) => interval.name)}
              onSelect={(index) => {
                GraphManager.clear("dealWonLost");
                setSelectedInterval(Object.assign({}, CHART_INTERVALS[index]));
              }}
            />
          </div>
        )}
      </div>
      {!showLoader && data.length > 0 && <LineChart data={data} height={200} />}
      {showLoader && getDashboardWidgetLoading()}
    </div>
  );
}
const mapStateToProps = (state) => ({});

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(DealWonLostChart);
