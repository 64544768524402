class AudioService {
  url;
  audio;
  constructor({ url }) {
    this.url = url;
    this.audio = new Audio(url);
  }
  public play() {
    this.audio.play();
  }
}
export default AudioService;
