import {
  DKButton,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  Toggle,
  removeLoader,
  showAlert,
  showLoader
} from "deskera-ui-library";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import RouteManager from "../../managers/RouteManager";
import UserManager from "../../managers/UserManager";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchTenantDetails,
  getTenantsDetails
} from "../../redux/slices/booksSlice";
import {
  fetchCRMSettings,
  fetchCurrencies,
  getCurrencies,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import Tenant from "../../services/tenant";
import Utility from "../../utility/Utility";
import { isViewportMobile } from "../../utility/GetViewportSize";
import SideBarService from "../../services/sidebar";
import { isViewportLarge } from "../../utility/GetViewportSize";
import { LOCAL_STORAGE_KEYS } from "../../constants/Enum";
export interface ITenantSettingsProps {
  onClose: () => void;
  onSave: (settings: any) => void;
}
const TenantSettings: React.FC<any> = (props: ITenantSettingsProps) => {
  const crmSettings = useAppSelector(selectCRMSettings());
  const currencies = useSelector(getCurrencies());
  const tenantDetails = useAppSelector(getTenantsDetails);
  const allCurrencies = useAppSelector(getCurrencies());

  const [settings, setSettings] = useState({
    currency: currencies.find(
      (currency) => currency.currencyCode === UserManager.getUserCurrency()
    )
  });
  const [showMultiCurrencies, setShowMultiCurrencies] = useState(false);
  const [selectedMultiCurrencies, setSelectedMultiCurrencies] = useState([]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (Utility.isEmptyObject(currencies)) {
      dispatch(fetchCurrencies());
    }
    if (Utility.isEmptyObject(crmSettings)) {
      dispatch(fetchCRMSettings({}));
    }
  }, []);
  useEffect(() => {
    setSettings({
      currency: currencies.find(
        (currency) =>
          currency.currencyCode ===
          (crmSettings.currency || UserManager.getUserCurrency())
      )
    });
  }, [crmSettings, currencies]);
  const onSave = async () => {
    showLoader();
    try {
      if (selectedMultiCurrencies?.length === 0) {
        props?.onClose();
        return;
      }

      const payload = {
        multicurrency: true,
        multicurrencyEnabled: true,
        currencyCodesToActivate:
          selectedMultiCurrencies?.map(
            (currencyIndex) => allCurrencies[currencyIndex]?.currencyCode
          ) ?? []
      };

      const response = await Tenant.updateTenantDetails(payload);
      if (!Utility.isEmptyObject(response)) {
        dispatch(fetchCRMSettings({}));
        dispatch(fetchTenantDetails({}));
        props.onSave(payload);
      }
    } catch (error) {
    } finally {
      removeLoader();
    }
  };

  const multiCurrencySelected = (options: number[]) => {
    setSelectedMultiCurrencies(options);
  };

  const multiCurrencySelect = () => {
    if (!tenantDetails?.multicurrencyEnabled && !showMultiCurrencies) {
      showAlert(
        "Warning!",
        "After enabling multi-currency, you will not be able to disable it.",
        [
          {
            title: "Cancel",
            className: "bg-white border-m mr-s",
            onClick: () => {}
          },
          {
            title: "Proceed",
            className: "bg-button text-white",
            onClick: () => {
              setShowMultiCurrencies(!showMultiCurrencies);
            }
          }
        ]
      );
    } else if (showMultiCurrencies) {
      setShowMultiCurrencies(!showMultiCurrencies);
    } else {
      showAlert("", "This setting cannot be disabled.");
    }
  };

  const handleClose = () => {
    props.onClose();
  };
  const mobileStyle = { minWidth: "100%" };
  const desktopStyle = { minWidth: 200 };

  const inputStyle = isViewportMobile() ? mobileStyle : desktopStyle;
  return (
    <div className="column parent-width border-m border-radius-m position-relative p-r mt-l">
      <div className=" parent-size">
        <div
          className={`row parent-width bg-white ${isViewportMobile() ? "row-responsive" : ""} `}
        >
          <div className="row justify-content-between parent-width ">
            {" "}
            <div className="column">
              <DKLabel text="Currency Settings" className="mr-m mb-xs fw-m" />
              <DKLabel
                className="text-gray text-ellipsis"
                text="Change your currency settings here"
              />
            </div>
          </div>

          <div className="column bg-white pt-m " style={inputStyle}>
            <DKInput
              value={settings.currency}
              formatter={(currency) =>
                `${currency.currencyName} (${currency.currencyCode})`
              }
              style={{ width: 200 }}
              type={INPUT_TYPE.DROPDOWN}
              required={true}
              readOnly={true}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              dropdownConfig={{
                allowSearch: true,
                searchableKey: "currencyName",
                data: currencies,
                renderer: (index: number, currency) => {
                  return (
                    <div className="row justify-content-between p-v-xs">
                      {`${currency.currencyName} (${currency.currencyCode})`}
                    </div>
                  );
                }
              }}
              onChange={(data) => {
                setSettings({ ...settings, currency: data });
              }}
            />
          </div>
        </div>
        <div className="pt-m row justify-content-between">
          <DKLabel text={`Enable Multi-Currency`} />
          <Toggle
            color="bg-green"
            isOn={
              (tenantDetails?.multicurrencyEnabled ?? false) ||
              showMultiCurrencies
            }
            onChange={() => {
              multiCurrencySelect();
            }}
          />
        </div>
        {showMultiCurrencies && !tenantDetails?.multicurrencyEnabled && (
          <div className="row justify-content-between parent-width">
            {" "}
            <div className="column">
              <DKLabel text="Currency Settings" className="mr-m mb-xs fw-m" />
              <DKLabel
                className="text-gray text-ellipsis"
                text="Change your currency settings here"
              />
            </div>
            <div className="column bg-white pt-m " style={{ width: 200 }}>
              <DKInput
                className={
                  "mt-m mobile-mt-s mb-m setting-multi-currency-dropdown"
                }
                value={selectedMultiCurrencies}
                displayKey="label"
                type={INPUT_TYPE.DROPDOWN}
                required={true}
                canValidate={true}
                onChange={(value: number[]) => {
                  multiCurrencySelected(value);
                }}
                renderer={(obj) => {
                  return (
                    <div className="row flex-wrap" style={{ gap: 4 }}>
                      {obj.map((currencyIndex) => (
                        <DKLabel
                          className="row width-auto bg-white border-s border-radius-s p-h-s p-v-xs"
                          text={`${allCurrencies?.[currencyIndex].currencyName}`}
                        />
                      ))}
                    </div>
                  );
                }}
                dropdownConfig={{
                  className: "",
                  title: "MultiCurrency",
                  style: {},
                  allowSearch: true,
                  searchableKey: "currencyName",
                  multiSelect: true,
                  selectedIndexes: [],
                  checkMarkColor: "bg-button",
                  data: allCurrencies ?? [],
                  renderer: (index: number, obj: any) => {
                    return (
                      <div className="row justify-content-between">
                        <DKLabel text={obj.currencyName} />
                        <DKLabel text={obj.currencyCode} />
                      </div>
                    );
                  },
                  onSelect: (index: number, value: any) => {},
                  onClear: () => {}
                }}
              />
            </div>
          </div>
        )}
      </div>
      {tenantDetails?.multicurrencyEnabled && (
        <div className="row justify-content-end">
          <DKButton
            title="Go to Multi-currency"
            onClick={() => {
              RouteManager.navigateToPage("/settings/currency");
              handleClose();
              if (isViewportLarge()) {
                SideBarService.isSideBarExpanded = true;
              } else {
                SideBarService.isSideBarExpanded =
                  Utility.getPersistentValue(
                    LOCAL_STORAGE_KEYS.IS_SIDE_BAR_EXPANDED
                  )?.toString() === "true" || false;
              }
            }}
            className="mt-m text-app text-underline fw-m "
          />
        </div>
      )}
      {showMultiCurrencies && !tenantDetails?.multicurrencyEnabled && (
        <div className="row justify-content-end mt-m ">
          <DKButton
            title={"Save"}
            className="bg-button ml-r text-white fw-m"
            onClick={onSave}
          />
        </div>
      )}
    </div>
  );
};
export default TenantSettings;
