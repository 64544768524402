import React, { Component } from "react";
import { DKLabel } from "deskera-ui-library";

/*
PROPS
- className
- title
- value
- UOM
- onChange
*/
export interface IIncrementorProps {
  className?: string;
  title?: string;
  value?: any;
  UOM?: string;
  step?: number;
  onChange?: (e: any) => void;
}
export interface IIncrementState {
  showInput: boolean;
}
class Incrementor extends Component<IIncrementorProps, IIncrementState> {
  constructor(props) {
    super(props);
    this.state = {
      showInput: false
    };
  }

  render() {
    return (
      <div className={"row " + this.props.className}>
        {this.props.title && (
          <DKLabel text={`${this.props.title}`} className="mr-r fw-m" />
        )}

        <div
          className="row bg-gray1 border-radius-s unselectable border-m"
          style={{ overflow: "hidden", width: "auto" }}
        >
          <div
            className="p-v-xs p-h-s cursor-hand unselectable"
            onClick={() => {
              this.onChange(-1 * (this.props.step || 2));
            }}
          >
            -
          </div>
          {this.state.showInput ? (
            <div>
              <input
                type="number"
                className="incrementor-input fs-r"
                style={{
                  textAlign: "center",
                  border: "none",
                  width: 44.5,
                  height: 30,
                  outline: "none"
                }}
                value={this.props.value}
                autoFocus
                onBlur={() => this.setState({ showInput: false })}
                onChange={(e) => {
                  this.props.onChange(parseInt(e.target.value));
                }}
              />
            </div>
          ) : (
            <div
              className="p-s bg-white cursor-hand"
              onClick={() => this.setState({ showInput: true })}
            >
              {this.props.value +
                (this.props.value !== "auto"
                  ? this.props.UOM
                    ? this.props.UOM
                    : "px"
                  : "")}
            </div>
          )}
          <div
            className="p-v-xs p-h-s cursor-hand unselectable"
            onClick={() => {
              this.onChange(this.props.step || 2);
            }}
          >
            +
          </div>
        </div>
      </div>
    );
  }

  onChange = (incrementBy) => {
    let val = this.props.value;
    val = val + incrementBy;
    if (this.props.value === "auto") val = 100;
    this.props.onChange(val);
  };
}

export default Incrementor;
