import ReactDOM from "react-dom";
import Popup from "../common/Popup";
import { DKLabel, DKButton, DKIcons, DKInput } from "deskera-ui-library";
import EmailEditor from "./EmailEditor_Quill";
import { useState, useRef } from "react";
import { Record } from "../../model/Table";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import Table from "../../services/table";
import { store } from "../../redux/store";
import {
  addRecord,
  deleteRecord,
  fetchRecordsByTable,
  updateRecord
} from "../../redux/slices/recordSlice";
import Utility, { getRandomHexString } from "../../utility/Utility";
import { TEMPLATE_MODULE_ID } from "../../constants/Enum";

interface IAddEmailTemplatePopup {
  module: string;
  templateToEdit?: Record;
  needEmailPlaceholderTool?: boolean;
  additionalPlaceholders?: { id: string; name: string }[];
  handleTemplateInsertion?: (template: Record) => void;
  onClose: () => void;
}

const TEMPLATE_EDITOR_ID = "email-template-editor";

const AddEmailTemplatePopup = (props: IAddEmailTemplatePopup) => {
  const templateNameColId = TableManger.getColumnId(
    TABLES.TEMPLATE,
    COLUMN_CODE.TEMPLATE.NAME
  );
  const templateHtmlColId = TableManger.getColumnId(
    TABLES.TEMPLATE,
    COLUMN_CODE.TEMPLATE.HTML_DATA
  );
  const templateTagsColId = TableManger.getColumnId(
    TABLES.TEMPLATE,
    COLUMN_CODE.TEMPLATE.TAGS
  );

  const [templateName, setTemplateName] = useState(
    props.templateToEdit?.cells?.[templateNameColId] || ""
  );

  const [saveTapped, setSaveTapped] = useState(false);

  const retrieveEditorBodyCallback = useRef(null);

  const onSave = async (insert?: boolean) => {
    const templateBody = retrieveEditorBodyCallback.current?.(false);

    setSaveTapped(true);

    if (
      Utility.isEmptyObject(templateBody) ||
      Utility.isEmptyObject(templateName)
    )
      return;

    props.onClose();

    const isUpdateOperation = Boolean(props.templateToEdit?._id);
    let templateData = {
      _id: props.templateToEdit?._id || getRandomHexString(),
      cells: {
        [templateNameColId]: templateName,
        [templateTagsColId]: props.templateToEdit?.cells?.[
          templateTagsColId
        ] || [TEMPLATE_MODULE_ID[props.module.toUpperCase()]],
        [templateHtmlColId]: Utility.encodeHTML(templateBody)
      }
    };

    if (insert) {
      props.handleTemplateInsertion?.(templateData);
    }

    /* Save/Update template api integration */
    try {
      const tableId = TableManger.getTableId(TABLES.TEMPLATE);
      if (isUpdateOperation) {
        store.dispatch(
          updateRecord({
            tableName: TABLES.TEMPLATE,
            recId: templateData._id,
            record: templateData.cells
          })
        );
        await Table.updateRecord(templateData.cells, templateData._id, tableId);
      } else {
        store.dispatch(
          addRecord({
            tableName: TABLES.TEMPLATE,
            record: templateData
          })
        );

        await Table.addRecord(templateData, tableId);
      }

      store.dispatch(
        fetchRecordsByTable({
          tableName: TABLES.TEMPLATE
        })
      );
    } catch (err) {
      if (!isUpdateOperation) {
        store.dispatch(
          deleteRecord({
            tableName: TABLES.TEMPLATE,
            recIds: [templateData._id]
          })
        );
      }
    }
  };

  return (
    <Popup
      popupWindowStyles={{
        maxWidth: "90vw",
        maxHeight: "90vh",
        height: "auto",
        width: 700,
        padding: 0
      }}
      className={saveTapped ? " email-body-required " : ""}
    >
      {/* Header */}
      <div className="row justify-content-between pt-s pb-s bg-gray3 p-h-m flex-wrap">
        <DKLabel
          text={"Add Email template"}
          className="fw-m mr-r"
          style={{ whiteSpace: "nowrap" }}
        />
        <DKButton icon={DKIcons.ic_close} onClick={props.onClose} />
      </div>
      {/* Body */}
      <DKInput
        title={"Template Name"}
        autoFocus={true}
        value={templateName}
        valueStyle={{
          background: "transparent",
          border: "none"
        }}
        titleStyle={{
          fontWeight: 500,
          whiteSpace: "nowrap",
          color:
            saveTapped && Utility.isEmptyObject(templateName)
              ? "rgb(203, 26, 38)"
              : "gray"
        }}
        required={false}
        className={"p-h-r p-v-xs"}
        onChange={setTemplateName}
      />
      <EmailEditor
        editorID={TEMPLATE_EDITOR_ID}
        height={280}
        body={Utility.decodeHTML(
          props.templateToEdit?.cells?.[templateHtmlColId] || ""
        )}
        bodyPlaceholder={"Compose an email template..."}
        additionalPlaceholders={props.additionalPlaceholders}
        needEmailPlaceholderTool={props.needEmailPlaceholderTool}
        getBodyToSaveCallback={(callback) =>
          (retrieveEditorBodyCallback.current = callback)
        }
      />

      {/* Footer */}
      <div className="row-reverse p-r mt-auto" style={{ height: 60 }}>
        <DKButton
          title="Save"
          className="bg-button text-white ml-m"
          onClick={() => onSave(true)}
        />
        <DKButton
          title="Cancel"
          className="bg-gray1 border-m"
          onClick={props.onClose}
        />
      </div>
    </Popup>
  );
};

export function showEmailTemplatePopup({
  module,
  templateToEdit,
  needEmailPlaceholderTool,
  additionalPlaceholders,
  handleTemplateInsertion
}) {
  const id = `add-email-template-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "add-email-template-popup app-font";
  div.setAttribute("id", id);

  const handleOnClose = () => {
    const templatePopupElement = document.getElementById(id);
    ReactDOM.unmountComponentAtNode(templatePopupElement);
    templatePopupElement?.remove();
  };

  ReactDOM.render(
    <AddEmailTemplatePopup
      onClose={handleOnClose}
      templateToEdit={templateToEdit}
      module={module}
      needEmailPlaceholderTool={needEmailPlaceholderTool}
      additionalPlaceholders={additionalPlaceholders}
      handleTemplateInsertion={handleTemplateInsertion}
    />,
    document.body.appendChild(div)
  );
}

export default AddEmailTemplatePopup;
