import {
  DKButton,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  removeLoader,
  showAlert,
  showLoader
} from "deskera-ui-library";
import { isEqual } from "lodash";
import React, { Component, RefObject } from "react";
import { ConnectedProps, connect } from "react-redux";
import "../../../src/styles/chat.scss";
import {
  BUBBLE_POSITIONS,
  CHAT_BUBBLE_DEFAULT_COLOR,
  NOTIFICATION_SOUND,
  REGEX
} from "../../constants/Constant";
import { CHAT_BUBBLE_POSITION } from "../../constants/Enum";
import UserManager from "../../managers/UserManager";
import { fetchChatSettings } from "../../redux/slices/userPrefSlice";
import Chat from "../../services/chat";
import AudioService from "../../services/common/audio";
import Utility from "../../utility/Utility";
import { isViewportMobile } from "../../utility/GetViewportSize";

export interface ISettingChatProps extends PropsFromRedux {}
export interface ISettingChatState {
  showEmbedPopup: boolean;
  showProfilePopup: boolean;
  name: any;
  welcomeMessage: any;
  file: File;
  isSettingPresent: boolean;
  url: any;
  isCodeCopied: boolean;
  showEditIcon: boolean;
  notificationSound: any;
  bubbleColor: any;
  bubblePosition: any;
}

class ChatSetting extends Component<ISettingChatProps, ISettingChatState> {
  imageInputOpenFileRef: RefObject<any>;
  constructor(props) {
    super(props);
    this.state = {
      showEmbedPopup: false,
      showProfilePopup: false,
      name: "",
      welcomeMessage: "",
      file: null,
      isSettingPresent: false,
      url: DKIcons.ic_user,
      isCodeCopied: false,
      showEditIcon: false,
      bubbleColor: CHAT_BUBBLE_DEFAULT_COLOR,
      notificationSound: NOTIFICATION_SOUND[0],
      bubblePosition: CHAT_BUBBLE_POSITION.BOTTOM_RIGHT
    };
    this.imageInputOpenFileRef = React.createRef();
  }
  componentDidMount() {
    if (Utility.isEmptyObject(this.props.chatSettings)) {
      this.props.fetchChatSettings({});
    } else {
      this.populateChatSettings();
    }
  }
  componentDidUpdate(
    prevProps: Readonly<ISettingChatProps>,
    prevState: Readonly<ISettingChatState>,
    snapshot?: any
  ): void {
    if (
      !Utility.isEmptyObject(this.props.chatSettings) &&
      !isEqual(this.props.chatSettings, prevProps.chatSettings)
    ) {
      this.populateChatSettings();
    }
  }
  populateChatSettings = () => {
    const { chatSettings } = this.props;
    if (Utility.isEmptyObject(chatSettings)) {
      return;
    }
    const {
      name,
      welcomeMsg,
      notificationSound,
      bubbleColor,
      bubblePosition,
      profilePicUrl
    } = chatSettings;
    this.setState({
      name,
      url: !Utility.isEmptyObject(profilePicUrl)
        ? profilePicUrl
        : DKIcons.ic_user,
      welcomeMessage: welcomeMsg,
      notificationSound:
        NOTIFICATION_SOUND.find((sound) => sound.value === notificationSound) ||
        NOTIFICATION_SOUND[0],
      bubbleColor:
        bubbleColor && REGEX.HEX_COLOR.test(bubbleColor)
          ? bubbleColor
          : CHAT_BUBBLE_DEFAULT_COLOR,
      bubblePosition: Object.values(CHAT_BUBBLE_POSITION).includes(
        bubblePosition
      )
        ? bubblePosition
        : CHAT_BUBBLE_POSITION.BOTTOM_RIGHT
    });
  };
  getColorSection = () => {
    return (
      <div className="column p-m border-radius-s bg-gray1 border-m justify-content-between parent-width">
        <DKLabel text="Color" className="mb-s" />
        <input
          type="color"
          className=""
          style={{
            width: "70%",
            height: "35px",
            border: "none"
          }}
          value={this.state.bubbleColor}
          onChange={(e) => {
            this.changeStyle("color", e.target.value);
          }}
        />
      </div>
    );
  };
  getPositionSection = () => {
    return (
      <div className="column p-m border-radius-s bg-gray1 border-m align-self-start parent-width">
        <DKLabel text={"Position"} className="mb-s" />
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          required={false}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          titleStyle={{ color: "black", fontSize: 12 }}
          valueStyle={{ backgroundColor: "white" }}
          title=""
          value={BUBBLE_POSITIONS.find(
            (position) => position.value === this.state.bubblePosition
          )}
          displayKey="title"
          onChange={(value: any) => {
            this.setState({
              bubblePosition: value.value
            });
          }}
          dropdownConfig={{
            className: "parent-width",
            data: BUBBLE_POSITIONS,
            renderer: (index: any, obj: any) => {
              return <DKLabel text={obj.title} />;
            }
          }}
        />
      </div>
    );
  };
  getNotificationSection = () => {
    return (
      <div className="column p-m border-radius-s bg-gray1 border-m align-self-start parent-width">
        <DKLabel text={"Notification Sound"} className="mb-s" />
        <DKInput
          type={INPUT_TYPE.DROPDOWN}
          required={false}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          titleStyle={{ color: "black", fontSize: 12 }}
          valueStyle={{ backgroundColor: "white" }}
          value={this.state.notificationSound}
          displayKey={"displayName"}
          onChange={async (value: any) => {
            try {
              const fileName = `${value.value}.mp3`;
              const audio = await import(`../../assets/audio/${fileName}`);
              const audioService = new AudioService({ url: audio?.default });
              audioService.play();
            } catch (error) {
            } finally {
              this.setState({
                notificationSound: value
              });
            }
          }}
          dropdownConfig={{
            className: "parent-width",
            data: NOTIFICATION_SOUND,
            renderer: (index: any, obj: any) => {
              return <DKLabel text={obj.displayName} />;
            }
          }}
        />
      </div>
    );
  };
  changeStyle = (property, value) => {
    this.setState({
      bubbleColor: value
    });
  };
  getUserSection = () => {
    const direction = INPUT_VIEW_DIRECTION.VERTICAL;

    return (
      <div className="parent-size">
        <div className="parent-width ">
          <div className="column parent-width justify-content-center align-items-center chat-setting-input">
            <div
              className="image-div border-m"
              style={{ borderRadius: "50%", height: 80, width: 80 }}
            >
              <img
                src={this.state.url}
                id="img"
                alt=""
                className="real-image circle"
                style={{ height: 80, width: 80 }}
              />
              <div className="hidden-image">
                <DKIcon
                  src={DKIcons.ic_edit}
                  onClick={() => this.openImageSelection()}
                  className="align-self-center"
                />
              </div>
            </div>
            <DKInput
              title="Display Name"
              placeholder="Display Name"
              className="mb-m"
              type={INPUT_TYPE.TEXT}
              value={this.state.name}
              required={false}
              onChange={(value) => this.setState({ name: value })}
              direction={direction}
            />
            <DKLabel className="parent-width pb-xs" text={"Welcome Message"} />
            <textarea
              className="mb-m hide-outline textField app-font fs-r fw-r"
              placeholder="Welcome Message"
              style={{ fontWeight: "normal", fontSize: 13 }}
              rows={3}
              value={this.state.welcomeMessage}
              required={false}
              onChange={(e) =>
                this.setState({
                  welcomeMessage: e.target.value
                })
              }
            />

            <div
              className={` ${isViewportMobile() ? "row-responsive" : ""} gap-s row mb-m parent-width align-items-stretch `}
              style={{ columnGap: 12 }}
            >
              {this.getColorSection()}
              {this.getPositionSection()}
              {this.getNotificationSection()}
            </div>
            <div className="column parent-width  ">
              <div
                className="column mb-s bg-white border-radius-s parent-width border-box align-self-center align-items-center"
                style={{ maxWidth: 800 }}
              >
                <div className="p-xl border-radius-s bg-gray1 column parent-width border-m">
                  <DKLabel
                    className="fw-b fs-m"
                    text="Embed script in head section"
                  />
                  <DKLabel
                    className="text-gray mt-xs"
                    text="Add this script to your head section to start chat from your webpages"
                  />
                  <div className=" text-align-left text-wrap bg-white border-m border-radius-s p-s mt-m parent-width border-box text-black code-block">
                    <pre className="parent-width">
                      <code className="parent-width hide-scroll-bar ">
                        {this.getEmbeddedScript()}
                      </code>
                    </pre>
                  </div>
                  <DKButton
                    title={`${
                      this.state.isCodeCopied ? "Copied" : "Copy Code"
                    }`}
                    icon={DKIcons.ic_copy}
                    className="mt-s"
                    onClick={() => {
                      this.setState({
                        isCodeCopied: true
                      });
                      Utility.copyToClipBoard(this.getEmbeddedScript());
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-m mb-xxl">
              <DKButton
                title="Save"
                className="bg-button text-white "
                onClick={() => this.saveProfileDetails()}
              />
            </div>
          </div>
        </div>
        {this.getImagePicker()}
      </div>
    );
  };
  getEmbeddedScript = () => {
    return `<link rel="stylesheet" href="${window.location.origin}/chat-ui/${
      process.env.REACT_APP_ENV
    }/main.css" />\n<script src="${window.location.origin}/chat-ui/${
      process.env.REACT_APP_ENV
    }/bundle.js"></script>\n<script>window.addEventListener("load", function () {window.deskeraChat('init',{tenantId: ${UserManager.getUserTenantID()}});});</script>`;
  };
  saveProfileDetails = () => {
    const {
      file,
      name,
      welcomeMessage,
      isSettingPresent,
      url,
      bubbleColor,
      bubblePosition,
      notificationSound
    } = this.state;
    if (
      isSettingPresent
        ? !Utility.isEmptyObject(url)
        : file ||
          !Utility.isEmptyObject(name) ||
          !Utility.isEmptyObject(welcomeMessage) ||
          !Utility.isEmptyObject(bubbleColor)
    ) {
      showLoader("Saving profile details...");
      let formData = new FormData();
      formData.append("attachment", file);
      formData.append("name", name);
      formData.append("welcomeMsg", welcomeMessage);
      formData.append(
        "bubbleColor",
        bubbleColor && REGEX.HEX_COLOR.test(bubbleColor)
          ? bubbleColor
          : CHAT_BUBBLE_DEFAULT_COLOR
      );
      formData.append(
        "bubblePosition",
        bubblePosition &&
          Object.values(CHAT_BUBBLE_POSITION).includes(bubblePosition)
          ? bubblePosition
          : CHAT_BUBBLE_POSITION.BOTTOM_RIGHT
      );
      formData.append("notificationSound", notificationSound?.value);
      Chat.saveChatSetting(formData)
        .then((response) => {
          this.props.fetchChatSettings({});
          removeLoader();
        })
        .catch((err) => {
          showAlert(
            "Error",
            "Error while saving chat settings. Please try again!"
          );
          removeLoader();
        });
    }
  };
  openImageSelection = () => {
    this.imageInputOpenFileRef.current.click();
  };
  getImagePicker = () => {
    return (
      <input
        id="inputImage"
        type="file"
        accept="image/*"
        ref={this.imageInputOpenFileRef}
        style={{ display: "none" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          var reader = new FileReader();
          reader.onload = function (e: any) {
            document.querySelector("#img").setAttribute("src", e.target.result);
          };
          reader.readAsDataURL(
            e.target?.files[0] != undefined
              ? e.target?.files[0]
              : this.state.file
          );
          this.setState({
            file:
              e.target?.files[0] != undefined
                ? e.target?.files[0]
                : this.state.file
          });
        }}
      />
    );
  };
  render() {
    return (
      <div
        className="row overflow-y-auto parent-height mt-l pb-m"
        style={{ gap: "2%", maxHeight: "100%" }}
      >
        {this.getUserSection()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  chatSettings: state.userPref.chatSettings
});
const mapDispatchToProps = { fetchChatSettings };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ChatSetting);
