import ApiConstants from "../../constants/ApiConstants";
import {
  COUNTRY_CODES,
  COUNTRY_SPECIFIC_URL_CODE
} from "../../constants/Constant";
import { getCurrencySymbolFromCode } from "../../constants/Currencies";
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from "../../constants/Enum";
import UserManager from "../../managers/UserManager";
import BooksService from "../../services/books";
import NumberFormatService from "../../services/numberFormat";

export class PriceBookManager {
  static getTenantSpecificApiCode(
    moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME
  ): string {
    let countrySpecificURL = "";

    const countryCode = UserManager.getUserCountry()
      ? UserManager.getUserCountry()
      : "";

    switch (countryCode) {
      case COUNTRY_CODES.IN:
        if (
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION
        ) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.IN;
        }
        break;
      case COUNTRY_CODES.IL:
        if (
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION
        ) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.IL;
        }
        break;
      case COUNTRY_CODES.US:
        break;

      case COUNTRY_CODES.SG:
        break;

      case COUNTRY_CODES.ID:
        if (moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.INDONESIA;
        }
        break;
      case COUNTRY_CODES.MY:
        if (
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT
        ) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.MALAYSIA;
        }
        break;

      case COUNTRY_CODES.NZ:
        break;

      case COUNTRY_CODES.DE:
        break;

      case COUNTRY_CODES.AE:
        if (moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.UAE;
        }
        break;
      case COUNTRY_CODES.UK:
        if (
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.CONTACT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.INVOICE ||
          moduleName === COMPLIANCE_SPECIFIC_FIELD_NAME.QUOTATION
        ) {
          countrySpecificURL = COUNTRY_SPECIFIC_URL_CODE.UK;
        }
        break;
      case COUNTRY_CODES.BE:
        break;
      case COUNTRY_CODES.AU:
        break;

      default:
        break;
    }
    return countrySpecificURL;
  }
  static getProductURL = (
    search: string,
    tenantInfo: any,
    params: any,
    query?: any
  ) => {
    const queryArg = query ?? "active=true,hasVariants=false";
    return (
      ApiConstants.URL.BASE +
      BooksService.getProductsWithVariantsURL(search, queryArg, params)
    );
  };
  static getNumber = (num: number) => {
    return NumberFormatService.getNumber(num);
  };
  static toCurrencyFormat = (amount = 0, currency = null) =>
    `${getCurrencySymbolFromCode(currency)} ${
      amount < 0 ? "(" : ""
    }${PriceBookManager.getNumber(Math.abs(amount))}${amount < 0 ? ")" : ""}`;
}
