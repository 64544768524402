import React from "react";
import { DKButton, DKCheckMark, showAlert } from "deskera-ui-library";
import { IColumn } from "../../model/Table";
import { useClickOutSide } from "../../utility/Hooks";
import { useAppDispatch } from "../../redux/hooks";
import {
  setUserPreferences,
  updateHiddenColumns
} from "../../redux/slices/userPrefSlice";
import { store } from "../../redux/store";

export interface IColumnVisibilityProps {
  columns: IColumn[];
  tableName: string;
  hiddenColumns: string[];
  onApply?: () => void;
  onCancel?: () => void;
  style?: React.CSSProperties;
}
const ColumnVisibility = (props: IColumnVisibilityProps) => {
  const [hiddenColumns, setHiddenColumns] = React.useState(props.hiddenColumns);
  const ref = React.useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  useClickOutSide(ref, props.onCancel);
  const updateUserPref = async () => {
    let payload = store.getState().userPref.hiddenColumns as any;
    payload = { ...payload, [props.tableName.toUpperCase()]: hiddenColumns };
    try {
      await dispatch(updateHiddenColumns(payload));
      props.onApply();
      await dispatch(setUserPreferences({ hiddenColumns: payload }));
    } catch (error) {
      showAlert("Error", "Unable to perform operation.");
    }
  };
  return (
    <div
      ref={ref}
      className="bg-white position-absolute z-index-3 p-h-s p-v-r shadow-m border-m  border-radius-s"
      style={{
        width: 200,
        ...props.style
      }}
    >
      <div className="row justify-content-between">
        <div className="fw-m">Show/Hide Columns</div>
        <DKButton
          title="Save"
          className="fw-b text-white bg-button"
          onClick={updateUserPref}
        />
      </div>
      <div
        className="hide-scroll-bar"
        style={{
          overflowY: "scroll",
          maxHeight: 350
        }}
      >
        {props.columns.map((column: IColumn) => (
          <div
            key={column.key}
            className="row p-v-s p-h-r cursor-hand"
            onClick={() => {
              if (hiddenColumns.includes(column.id)) {
                setHiddenColumns(hiddenColumns.filter((c) => c !== column.id));
              } else {
                if (hiddenColumns.length < props.columns.length - 1) {
                  setHiddenColumns([...hiddenColumns, column.id]);
                }
              }
            }}
          >
            <DKCheckMark
              isSelected={!hiddenColumns.includes(column.id)}
              title={column.name}
              color="bg-button"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default React.memo(ColumnVisibility) as React.FC<IColumnVisibilityProps>;
