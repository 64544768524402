import { useEffect, useState } from "react";
import { DKLabel } from "deskera-ui-library";
import Popup from "../common/Popup";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import UserManager from "../../managers/UserManager";
import { setUserPreferences } from "../../redux/slices/userPrefSlice";
import Utility from "../../utility/Utility";
import {
  COMMON_EVENTS,
  pageDesignerCustomEvent as commonCustomEvent
} from "../../services/event";
import {
  MOBILE_DOWNLOAD_POPUP_CLOSED,
  US_COUNTRY_CODE
} from "../../constants/Constant";
import { LAUNCHED_VERSION, RELEASE_NOTES } from "./version_1.0.8.json";
import { INTL_KEYWORDS } from "../../constants/CountriesAndStates";

export default function ReleaseNotificationPopup() {
  const [showPopup, setShowPopup] = useState(false);
  const acknowledgedVersion = useAppSelector(
    (state) => state.userPref.acknowledgedVersion
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const onRequestReleaseNoteView = () => {
      setShowPopup(true);
    };

    shouldTriggerPopup();

    commonCustomEvent.on(
      COMMON_EVENTS.VIEW_RELEASE_NOTE,
      onRequestReleaseNoteView
    );

    return () => {
      commonCustomEvent.remove(
        COMMON_EVENTS.VIEW_RELEASE_NOTE,
        onRequestReleaseNoteView
      );
    };
  }, []);

  useEffect(() => {
    commonCustomEvent.on(COMMON_EVENTS.SUBSCRIPTION_CHECK, shouldTriggerPopup);

    shouldTriggerPopup();

    return () => {
      commonCustomEvent.remove(
        COMMON_EVENTS.SUBSCRIPTION_CHECK,
        shouldTriggerPopup
      );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acknowledgedVersion]);

  function shouldTriggerPopup() {
    if (
      acknowledgedVersion === null ||
      acknowledgedVersion === LAUNCHED_VERSION ||
      Utility.isEmptyObject(UserManager.userPlanDetails) ||
      Utility.getPersistentValue(MOBILE_DOWNLOAD_POPUP_CLOSED) !== "true"
    )
      return;

    if (UserManager.isPlanActive()) {
      setShowPopup(true);
    }

    dispatch(setUserPreferences({ acknowledgedVersion: LAUNCHED_VERSION }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  function replaceCountrySpecificKeywords(text: string) {
    if (!text) return "";

    if (UserManager.getUserCountry() === US_COUNTRY_CODE) {
      const keywordsMap = INTL_KEYWORDS[US_COUNTRY_CODE];

      Object.entries(keywordsMap).forEach(([key, replaceValue]) => {
        text = text.replaceAll(key, replaceValue);
      });
    }

    return text;
  }

  return showPopup ? (
    <Popup
      title="Release updates 🚀"
      allowClose={true}
      onClose={() => setShowPopup(false)}
    >
      <div
        className="column flex-1 mt-l hide-scroll-bar"
        style={{ overflowY: "auto", maxHeight: "70vh" }}
      >
        {RELEASE_NOTES.map((noteGroup) =>
          Utility.isEmptyObject(noteGroup.items) &&
          noteGroup.type !== "FIXES" ? null : (
            <div className="column">
              <DKLabel text={noteGroup.title} className="fs-m" />
              <ul className="pl-xxl mt-r mb-0">
                {noteGroup.items.map((item, index) => (
                  <li className="mb-m" key={`${noteGroup.type}_note_${index}`}>
                    <DKLabel
                      text={replaceCountrySpecificKeywords(item.heading)}
                      className="fw-m"
                    />
                    <DKLabel
                      text={replaceCountrySpecificKeywords(item.desc)}
                      className="text-gray"
                    />
                  </li>
                ))}
              </ul>
            </div>
          )
        )}
      </div>
    </Popup>
  ) : null;
}
