import React, { useState } from "react";
import { PRICING_DATA } from "./data";

export const PricingCard = (props) => {
  const [billedAnnually, setBilledAnnually] = useState(0);
  const [selectedPlan, setPlan] = useState("Startup");
  const PLANS = ["Startup", "Professional", "Enterprise"];

  const getToggleButtonContent = (annual, plan) => {
    return (
      <div>
        <span className="fs-s fw-b">
          Pay {annual ? "Upfront " : "Monthly"}
          {annual && (
            <span className="text-green fs-s">
              SAVE {plan.annualPaymentDiscount}%
            </span>
          )}
        </span>
        <br />
        <span className="fs-s text-gray">
          Commit {annual ? "Annually" : "Monthly"}
        </span>
      </div>
    );
  };

  const getCallToAction = (plan) => {
    return (
      <div className="row p-l bg-blue mt-l border-radius-s fs-m text-white justify-content-center cursor-hand fw-b">
        {plan.planName === "Startup" ? "Buy Now" : "Talk to Sales"}
      </div>
    );
  };

  const getPaymentToggle = (plan) => {
    return (
      <div
        className="row bg-gray1 border-radius-l position-relative border-s display-only-web"
        style={{
          height: "3rem",
          borderRadius: "1.5rem",
          visibility: plan.haveAnnualPaymentDiscount ? "visible" : "hidden"
        }}
      >
        <div
          style={{ width: "50%" }}
          className="cursor-hand"
          onClick={() => setBilledAnnually(false)}
        >
          {getToggleButtonContent(false)}
        </div>
        <div
          className="row bg-white border-radius-l justify-content-center border-m position-absolute"
          style={{
            height: "3.2rem",
            borderRadius: "1.5rem",
            width: "50%",
            left: billedAnnually ? "50%" : 0,
            transition: "all 0.2s ease-in-out 0s"
          }}
        >
          {getToggleButtonContent(billedAnnually, plan)}
        </div>
        <div
          style={{ width: "50%" }}
          className="cursor-hand"
          onClick={() => setBilledAnnually(true)}
        >
          {getToggleButtonContent(true, plan)}
        </div>
      </div>
    );
  };

  const getAmountSection = (plan) => {
    return (
      <>
        <div className="row justify-content-center pt-xl fs-xxl">
          {plan.planName}
        </div>
        <div className="row pt-l flex-wrap">
          <div className="parent-width fs-s text-gray p-s">Starts at</div>
          <div className="parent-width fs-xxl">
            $
            {billedAnnually
              ? (plan.monthlyPrice * 12 -
                  plan.monthlyPrice * 12 * (plan.annualPaymentDiscount / 100)) /
                12
              : plan.monthlyPrice}
            /mo
          </div>
          <div className="parent-width fs-s text-gray p-s">billed monthly</div>
        </div>
        <div
          className="row pt-m pb-l text-gray"
          style={{ lineHeight: 2, fontSize: 12 }}
        >
          {plan.description}
        </div>
      </>
    );
  };
  const getFeatureSections = (plan) => {
    return (
      <>
        <div className="row pt-l text-gray">{plan.inAdditionTo} plus:</div>
        {plan.features.map((feature) => {
          return (
            <div className="pb-l">
              <div className="row pt-l fs-m fw-b text-align-left">
                {feature.heading}
              </div>
              {feature.subs.map((sub) => (
                <>
                  <div className="row pt-l text-gray text-align-left">
                    {sub.title}
                  </div>
                </>
              ))}
            </div>
          );
        })}
      </>
    );
  };
  const DATA = PRICING_DATA[props.selectedPlan];
  return (
    <>
      <div className="row justify-content-center pt-xxl pb-s fs-xxxl fw-h">
        {props.selectedPlan}
      </div>
      <div className="row justify-content-center text-gray">
        Everything you need to capture leads and turn them into customers.
      </div>
      <div className="row justify-content-center align-items-center text-gray mt-m parent-width plan-wrapper">
        {PLANS.map((plan, index) => {
          return (
            <div
              className={`border-m p-v-l parent-width ${plan === selectedPlan ? "bg-chip-blue border-blue" : ""}`}
              onClick={() => setPlan(plan)}
            >
              {plan}
            </div>
          );
        })}
      </div>
      <div
        className="row mt-xl border-m pricing-card-wrapper flex-stretch shadow-s"
        style={{ height: "auto" }}
      >
        {DATA.map((plan) => {
          return (
            <div className="column parent-size p-h-xxl pb-xl pricing-card">
              {getAmountSection(plan)}
              {getPaymentToggle(plan)}
              {getCallToAction(plan)}
              {getFeatureSections(plan)}
            </div>
          );
        })}
      </div>
      <div
        className="row mt-xl border-m pricing-card-mobile-wrapper flex-stretch shadow-s"
        style={{ height: "auto" }}
      >
        {DATA.filter((plan) => plan.planName === selectedPlan)?.map((plan) => {
          return (
            <div className="column parent-size p-h-xxl pb-xl pricing-card">
              {getAmountSection(plan)}
              {getPaymentToggle(plan)}
              {getCallToAction(plan)}
              {getFeatureSections(plan)}
            </div>
          );
        })}
      </div>
      <div
        className="row border-m price-card-footer shadow-s"
        style={{ height: "auto", borderTop: 0 }}
      >
        {DATA.map((plan) => {
          return (
            <div className="row justify-content-center p-v-xl text-blue fw-b text-underline see-features cursor-hand">
              See all features
            </div>
          );
        })}
      </div>
      <div
        className="row border-m price-card-mobile-footer shadow-s"
        style={{ height: "auto", borderTop: 0 }}
      >
        <div className="row justify-content-center p-v-xl text-blue fw-b text-underline see-features cursor-hand">
          See all features
        </div>
      </div>
    </>
  );
};
