import {
  DKLabel,
  removeLoader,
  showLoader,
  DKButton,
  DKIcons,
  DKIcon
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import {
  NEW_SETTINGS_SECTION,
  SETTING_SCREENS,
  SUB_SETTING_SCREEN
} from "../../constants/Constant";
import { TABLES } from "../../managers/TableManger";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchIndiaMartAccounts,
  fetchJustDialAccounts,
  getIndiaMartAccounts,
  getJustDialAccounts
} from "../../redux/slices/userPrefSlice";
import PermissionService from "../../services/common/permission";
import NotificationService from "../../services/notification";
import Utility from "../../utility/Utility";
import ActivitySetting from "../settings/ActivitySetting";
import BookDemoSettings from "../settings/BookDemoSettings";
import ChatSetting from "../settings/ChatSetting";
import DuplicateCheckSetting from "../settings/DuplicateCheckSetting";
import EventScore from "../settings/EventScore";
import FieldMandatoryPopup from "../settings/FieldMandatoryPopup";
import IndiaMartLeadSyncSetting from "../settings/IndiaMartLeadSyncSetting";
import JustDialLeadSyncSetting from "../settings/JustDialLeadSyncSetting";
import NotificationSettings from "../settings/NotificationSettings";
import PaymentIntegration from "../settings/PaymentIntegration";
import TeamPermission from "../settings/TeamPermission";
import UserPermission from "../settings/UserPermission";
import AdvancedSettingsPage from "./AdvancedSettingsPage";
import OrganizationSettingsPage from "./OrganizationSettingsPage";
import { isViewportMobile } from "../../utility/GetViewportSize";

interface SettingsRightPanelProps {
  selectedSetting: any;
  currentScreen: any;
  onClose: () => void;
}

const SettingsRightPanel = (props: SettingsRightPanelProps) => {
  const selectedSetting = props.selectedSetting;
  const [subRightPanel, setSubRightPanel] = useState<string>(
    SUB_SETTING_SCREEN.NOTIF_MODULE_SELECTOR
  );
  const [subRightPanelFieldMand, setSubRightPanelFieldMand] = useState<string>(
    SUB_SETTING_SCREEN.FIELDMAND_MODULE_SELECTOR
  );

  const dispatch = useAppDispatch();
  const isEmpty = (value: any) => {
    if (value === null || value === undefined || value.length === 0)
      return true;
    if (Array.isArray(value) || typeof value === "string") return !value.length;
    return Object.keys(value).length === 0;
  };
  const renderSubRightPanel = (props) => {
    setSubRightPanel(props);
  };
  const renderSubRightPanelFieldMap = (props) => {
    setSubRightPanelFieldMand(props);
  };

  const permissionService = PermissionService.getInstance();
  const modulePermissions = permissionService.getUserPermissionByModule(
    permissionService.getUpdatedModuleName(TABLES.PERMISSION)
  );
  const [disabledBellNotification, setDisabledBellNotification] = useState<any>(
    {}
  );
  const indiaMartAccountData = useAppSelector((state) =>
    getIndiaMartAccounts(state)
  );
  const justDialAccountData = useAppSelector((state) =>
    getJustDialAccounts(state)
  );

  useEffect(() => {
    if (selectedSetting && selectedSetting.url) {
      Utility.openInNewTab(selectedSetting.url);
    }
  }, [selectedSetting]);

  useEffect(() => {
    getNotificationData();
    dispatch(fetchJustDialAccounts({}));
    dispatch(fetchIndiaMartAccounts({}));
  }, []);

  const getNotificationData = () => {
    NotificationService.getNotificationSetting().then(
      (response) => {
        setDisabledBellNotification(response?.disabledBellNotification);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const getSectionComponent = () => {
    const section = selectedSetting?.propertyName as NEW_SETTINGS_SECTION;
    switch (section) {
      case NEW_SETTINGS_SECTION.ORGANISATION_SETTINGS:
        return <OrganizationSettingsPage onClose={props.onClose} />;
      case NEW_SETTINGS_SECTION.ADVANCED_SETTINGS:
        return <AdvancedSettingsPage />;
      case NEW_SETTINGS_SECTION.USER_PERMISSIONS:
        return <UserPermission permittedActions={modulePermissions} />;
      case NEW_SETTINGS_SECTION.TEAM_PERMISSIONS:
        return <TeamPermission permittedActions={modulePermissions} />;
      case NEW_SETTINGS_SECTION.DUPLICATION_SETTINGS:
        return <DuplicateCheckSetting />;
      case NEW_SETTINGS_SECTION.CHAT_SETTINGS:
        return <ChatSetting />;
      case NEW_SETTINGS_SECTION.ACTIVITY_SETTING:
        return <ActivitySetting />;
      case NEW_SETTINGS_SECTION.NOTIFICATION_SETTINGS:
        return (
          <NotificationSettings
            disabledBellNotification={disabledBellNotification}
            changeSubRightPanel={renderSubRightPanel}
            currentScreen={subRightPanel}
            onSave={() => getNotificationData()}
          />
        );
      case NEW_SETTINGS_SECTION.INDIAMART_INTEGRATION:
        showLoader("Fetching IndiaMart integration details..");
        let indiaMartAccounts = indiaMartAccountData;
        removeLoader();
        return (
          <IndiaMartLeadSyncSetting
            indiaMartAccount={{ ...(indiaMartAccounts?.[0] || null) }}
            onSave={() => {
              dispatch(fetchIndiaMartAccounts({}));
            }}
            onClose={() => {}}
          />
        );
      case NEW_SETTINGS_SECTION.JUSTDIAL_INTEGRATION:
        showLoader("Fetching JustDial integration details..");
        let justDialAccounts: any = justDialAccountData;
        removeLoader();
        return (
          <JustDialLeadSyncSetting
            justDialAccount={{ ...(justDialAccounts?.[0] || null) }}
            onSave={() => {
              dispatch(fetchJustDialAccounts({}));
            }}
            onClose={() => {}}
          />
        );
      case NEW_SETTINGS_SECTION.REQUIRED_FIELDS:
        return (
          <FieldMandatoryPopup
            changeSubRightPanel={renderSubRightPanelFieldMap}
            currentScreen={subRightPanelFieldMand}
          />
        );
      case NEW_SETTINGS_SECTION.PAYMENT_INTEGRATION:
        return <PaymentIntegration />;
      case NEW_SETTINGS_SECTION.BOOK_DEMO_SETTINGS:
        return <BookDemoSettings onClose={() => console.log("")} />;
      case NEW_SETTINGS_SECTION.EVENT_SCORE:
        return <EventScore onClose={() => console.log("")} />;
      default:
        return (
          <div className="parent-size row justify-content-center">
            <div className="fw-s fs-l">
              You've opened{" "}
              <span className="fw-m">{selectedSetting?.title}</span> in a new
              tab.
            </div>
          </div>
        );
    }
  };
  const toBeChangedTo = subRightPanel
    ? SETTING_SCREENS.SETTINGS_LEFT_PANEL
    : SETTING_SCREENS.SETTINGS_RIGHT_PANEL;

  const renderBackButton = () => {
    const handleClick = (targetScreen, targetField) => {
      if (targetScreen !== undefined) {
        renderSubRightPanel(targetScreen);
      } else if (targetField !== undefined) {
        renderSubRightPanelFieldMap(targetField);
      } else {
        props.currentScreen(toBeChangedTo);
      }
    };

    let targetScreen;
    let targetField;

    switch (subRightPanel) {
      case SUB_SETTING_SCREEN.NOTIF_OPTIONS:
        targetScreen = SUB_SETTING_SCREEN.NOTIF_MODULE_SELECTOR;
        break;
      default:
        if (subRightPanelFieldMand === SUB_SETTING_SCREEN.FIELDMAND_OPTIONS) {
          targetField = SUB_SETTING_SCREEN.FIELDMAND_MODULE_SELECTOR;
        }
        break;
    }

    return (
      <DKButton
        icon={DKIcons.ic_arrow_left}
        onClick={() => handleClick(targetScreen, targetField)}
      />
    );
  };

  return (
    <>
      {isViewportMobile() && (
        <div className="mt-s ml-r" style={{ width: 30 }}>
          {renderBackButton()}{" "}
        </div>
      )}
      <div
        className={`column parent-size hide-scroll-bar overflow-y-hidden pt-l ${isViewportMobile() ? "p-h-xl" : "p-h-xxl"}`}
        style={{ maxHeight: "97%", overflowX: "auto" }} // Set overflowX to auto
      >
        <div style={{ minWidth: 360, width: "100%", height: "100%" }}>
          {selectedSetting?.showHeader && (
            <>
              <div className="row">
                <div className="column parent-width">
                  <DKLabel
                    text={selectedSetting?.title}
                    className="fw-m pb-xs"
                  />
                  <DKLabel
                    text={selectedSetting?.description}
                    className="text-gray"
                  />
                </div>
              </div>
              <div
                className="column parent-width bg-gray2 mt-r"
                style={{ minHeight: 1 }}
              ></div>
            </>
          )}
          <div
            className={`column parent-size overflow-y-hidden ${
              selectedSetting?.showHeader ? "" : "mt-l"
            }`}
            style={{
              maxHeight: `calc(100% - ${selectedSetting?.showHeader ? 60 : 0}px)`
            }}
          >
            {getSectionComponent()}
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsRightPanel;
