import { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import Popup from "./Popup";
import {
  INPUT_TYPE,
  DKLabel,
  DKButton,
  DKListPicker,
  DKIcons,
  DKInput,
  DKFilterRow
} from "deskera-ui-library";
import { TableManger } from "../../managers/TableManger";
import Utility, { getCapitalized } from "../../utility/Utility";
import { IColumn } from "../../model/Table";
import { PREFIXES } from "../../constants/Enum";

interface ILookupFieldPopupProps {
  tableName: string;
  lookup?: any;
  columnName?: any;
  popupId?: string;
  onSave: (lookup: any) => void;
  onClose?: () => void;
}

interface ILookupFieldPopupState {
  formData: any;
  showFilter: boolean;
  filterData: any;
}

class AddLookupFieldColumn extends Component<
  ILookupFieldPopupProps,
  ILookupFieldPopupState
> {
  formFieldKeys: any = {
    COLUMN: "Column Name",
    TABLE: "Source Table",
    FIELD: "Property"
  };
  sourceTableColumns: IColumn[] = null;
  targetTableColumns: IColumn[] = null;
  initialFormState: any;

  constructor(props: ILookupFieldPopupProps) {
    super(props);
    this.initialFormState = {
      [this.formFieldKeys.COLUMN]: {
        value: this.props.columnName,
        placeholder: "Please enter new column name",
        required: true,
        errorMessage: "Please provide a column name to create lookup field",
        canValidate: false
      },
      [this.formFieldKeys.TABLE]: {
        open: false,
        id: this.props.lookup?.sourceTableId || null,
        value: this.props.lookup?.objectType?.toLowerCase()
          ? TableManger.getTableNameFromId(this.props.lookup.sourceTableId)
          : null,
        placeholder: "Please select a table to pick lookup field",
        required: false,
        options: [...TableManger.tableMap.keys()],
        error: ""
      },
      [this.formFieldKeys.FIELD]: {
        open: false,
        id: null,
        value: null,
        placeholder: "Please select a data source/property",
        type: INPUT_TYPE.TEXT,
        required: false,
        options: null,
        error: ""
      }
    };
    this.state = {
      formData: Utility.makeCopyOfObject(this.initialFormState),
      showFilter: false,
      filterData: []
    };
  }

  componentDidMount() {
    this.populateExistingColumnData();
    document.addEventListener("keydown", this.bindEnter);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.bindEnter);
  }

  bindEnter = (evt: KeyboardEvent) => {
    /* to keep previous popups opened */
    evt.stopPropagation();
    if (evt.code === "Enter") {
      this.saveTapped();
    }
  };

  getFilterData = () => {
    let filterCondition = [];
    Object.values(this.props?.lookup?.filter).forEach((filter: any) => {
      if (!Utility.isEmptyObject(filter.conditions?.[0].colId)) {
        filterCondition.push({
          key: filter.conditions?.[0]?.colId,
          condition: filter.conditions?.[0]?.opr,
          value: filter.conditions?.[0]?.value
        });
      }
    });
    return filterCondition;
  };

  populateExistingColumnData = () => {
    if (Utility.isEmptyObject(this.props.lookup)) return;

    let { formData: updatedFormData } = this.state;
    updatedFormData = Utility.makeCopyOfObject(updatedFormData);

    /* Set lookup property field */
    const property = updatedFormData[this.formFieldKeys.FIELD];
    property.id = this.props.lookup.sourceColumn;
    if (Utility.isEmptyObject(property.options)) {
      property.options = this.getTableColumnNames(
        updatedFormData[this.formFieldKeys.TABLE].value,
        false
      );
    }
    const propertyColumn = this.targetTableColumns?.find(
      (col: IColumn) => col.id === property.id
    );
    property.value = propertyColumn?.name || "";
    property.type = propertyColumn?.type || INPUT_TYPE.MULTI_SELECT;

    this.setState({
      formData: updatedFormData,
      filterData: this.getFilterData()
    });
  };

  /*  *********************** COLUMN FIELD UTILITIES ****************************** */
  setTableColumns = (tableName: string, isSourceField: boolean) => {
    /* If we already have source columns/ target columns set then no need to reset */
    if (
      !tableName ||
      (isSourceField && this.sourceTableColumns) ||
      (!isSourceField && this.targetTableColumns)
    )
      return;

    const defaultIDColumn: IColumn = {
      id: "_id",
      name: "Id"
    };

    if (isSourceField) {
      this.sourceTableColumns = Utility.makeCopyOfObject(
        TableManger.getTableColumns(tableName)
      );
      this.sourceTableColumns.unshift(defaultIDColumn);
    } else {
      let visibleColumns = TableManger.getTableVisibleColumns(tableName);
      visibleColumns = visibleColumns?.filter(
        (column) =>
          ![
            INPUT_TYPE.SELECT,
            INPUT_TYPE.MULTI_SELECT,
            INPUT_TYPE.DROPDOWN,
            "file",
            "address",
            "json_array",
            "ref"
          ].includes(column.type) &&
          ![
            "notes",
            "dealsCount",
            "contactCount",
            "wonDealAmount",
            "lostDealAmount"
          ].includes(column.columnCode)
      );
      this.targetTableColumns = Utility.makeCopyOfObject(visibleColumns);
    }
  };

  getTableColumnNames = (tableName: string, isSourceField: boolean) => {
    this.setTableColumns(tableName, isSourceField);

    return (
      (isSourceField ? this.sourceTableColumns : this.targetTableColumns)?.map(
        (column) => column.name
      ) || null
    );
  };

  openTableColumnPicker = (fieldKey: string) => {
    const tableName = this.state.formData[this.formFieldKeys.TABLE].value;

    const columnNames = this.getTableColumnNames(tableName, false);

    const columnFieldData = this.state.formData[fieldKey];
    const newColumnFieldData = Utility.makeCopyOfObject(columnFieldData);

    if (columnNames) {
      newColumnFieldData.options = columnNames;
      newColumnFieldData.open = true;
    } else {
      newColumnFieldData.error = "Please select a source table first";
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [fieldKey]: newColumnFieldData
      }
    });
  };

  /*  *********************** FORM CONTROL HANDLERS ****************************** */
  toggleFormField = (fieldKey: string, isFieldOpen: boolean) => {
    if (!isFieldOpen) {
      switch (fieldKey) {
        case this.formFieldKeys.TABLE:
          break;
        case this.formFieldKeys.FIELD:
          this.openTableColumnPicker(fieldKey);
          return;
        default:
          return;
      }
    }

    const newFormData = Utility.makeCopyOfObject(this.state.formData);
    newFormData[fieldKey].open = !isFieldOpen;

    this.setState({ formData: newFormData });
  };

  onFormValueChange = (key: string, value: string, selectedIndex?: number) => {
    const { COLUMN, TABLE, FIELD } = this.formFieldKeys;
    const newFormData = Utility.makeCopyOfObject(
      key === this.formFieldKeys.TABLE
        ? this.initialFormState
        : this.state.formData
    );

    switch (key) {
      case COLUMN:
        newFormData[key].value = value;
        newFormData[key].canValidate = true;
        break;
      case TABLE:
        /* resetting newFormData to default data, as after changing table user will have to select related target column */
        newFormData[COLUMN] = this.state.formData[COLUMN];
        newFormData[key].id = TableManger.getTableId(value);
        this.targetTableColumns = null;
        break;
      case FIELD:
        const column =
          this.targetTableColumns[selectedIndex] ||
          this.targetTableColumns.find((column) => column.name === value);
        newFormData[key].id = column?.id;
        newFormData[key].type = column?.type || INPUT_TYPE.TEXT;
        break;
      default:
    }

    newFormData[key].value = value;
    newFormData[key].open = false;
    newFormData[key].error = "";

    this.setState({
      formData: newFormData
    });
  };

  deleteRow(index) {
    let data = [...this.state.filterData];
    data?.splice(index, 1);
    this.setState({ filterData: data });
  }

  /*  *********************** FORM SUBMISSION HANDLERS ****************************** */
  isFormDataInvalid = () => {
    let foundInvalidKey = false;
    const { COLUMN } = this.formFieldKeys;
    const newFormData = Utility.makeCopyOfObject(this.state.formData);

    Object.keys(newFormData).forEach((key) => {
      const formDataObj = newFormData[key];
      if (
        key === COLUMN &&
        (!formDataObj.value || formDataObj.value.trim() === "")
      ) {
        newFormData[key].canValidate = true;
        foundInvalidKey = true;
        return;
      } else if (key === COLUMN) {
        return;
      }

      if (!formDataObj.id) {
        newFormData[key].error =
          `Please select a ${key.toLowerCase()} for creating lookup field`;
        foundInvalidKey = true;
      }
    });

    if (foundInvalidKey) {
      this.setState({ formData: newFormData });
    }

    return foundInvalidKey;
  };

  getResponseData = () => {
    const { formData } = this.state;
    const { COLUMN, TABLE, FIELD } = this.formFieldKeys;

    const tableName = formData[TABLE].value;
    const tableId = formData[TABLE].id;
    const valueFieldId = formData[FIELD].id;

    const filterObject = {};
    this.state.filterData?.forEach((filter, index) => {
      filterObject[`filter${index + 1}`] = {
        conditions: [
          {
            colId: filter.key,
            opr: filter.condition,
            value: filter.value
          }
        ],
        logicalOperator: "and"
      };
    });

    return {
      name: formData[COLUMN]?.value || this.props.columnName,
      type: INPUT_TYPE.MULTI_SELECT,
      lookup: {
        objectType: tableName.toUpperCase(),
        sourceTableId: tableId,
        sourceColumn: valueFieldId,
        filter: { ...filterObject, logicalOperator: "and" }
      }
    };
  };

  saveTapped = () => {
    if (this.isFormDataInvalid()) return;

    const response = this.getResponseData();
    this.props.onSave(response);
    this.removePopUp();
  };

  onCancel = () => {
    this.removePopUp();
  };

  removePopUp = () => {
    if (this.props.popupId)
      ReactDOM.unmountComponentAtNode(
        document.getElementById(this.props.popupId)
      );
    document.getElementById(this.props.popupId)?.remove();
    if (this.props.onClose) this.props.onClose();
  };

  /*  *********************** FORM UI HANDLERS ****************************** */
  render() {
    return (
      <Popup popupWindowStyles={{ overflowY: "visible" }}>
        {this.getHeader()}
        {this.getForm()}
      </Popup>
    );
  }

  getHeader() {
    return (
      <div className="row align-items-center justify-content-between parent-width">
        <div className="row fs-xl fw-h">
          {this.props.lookup ? "Update" : "Create"} Lookup Field
        </div>
        <div className="row-reverse action-btn-wrapper">
          <DKButton
            className="border-m ml-r bg-button text-white"
            title="Save"
            onClick={this.saveTapped}
          />
          <DKButton
            className="border-m bg-white"
            title="Cancel"
            onClick={this.onCancel}
          />
        </div>
        {/* Subtitle */}
      </div>
    );
  }

  getFilterRow() {
    const { formData } = this.state;
    const { TABLE } = this.formFieldKeys;
    const tableColumns = TableManger.getTableColumns(formData[TABLE].value);
    const isTableSelected = !Utility.isEmptyObject(formData?.[TABLE]?.value);

    return (
      <Fragment>
        <div className="parent-width">
          {this.state.filterData?.map((obj, idx) => {
            return (
              <DKFilterRow
                index={idx}
                rowData={obj}
                headers={tableColumns}
                onDataChange={(data, index) => this.dataChanged(data, index)}
                onDelete={(index) => this.deleteRow(index)}
              />
            );
          })}
        </div>
        <div className="row justify-content-end">
          <DKButton
            title="+ Add filter"
            className="text-blue fw-m mt-s"
            style={{ padding: 0 }}
            disabled={!isTableSelected}
            onClick={() => {
              this.addNewRow();
            }}
          />
        </div>
      </Fragment>
    );
  }

  dataChanged = (_data, index) => {
    let data = this.state.filterData;
    data[index] = _data;
    this.setState({ filterData: data });
  };
  addNewRow = () => {
    const { formData } = this.state;
    const { TABLE } = this.formFieldKeys;

    // const columnFieldData = this.state.formData[fieldKey];
    const newColumnFieldData = Utility.makeCopyOfObject(formData[TABLE]);

    // if (columnNames) {
    //   newColumnFieldData.options = columnNames;
    //   newColumnFieldData.open = true;
    // } else {
    //   newColumnFieldData.error = "Please select a source table first";
    // }

    // this.setState({
    //   formData: {
    //     ...this.state.formData,
    //     [fieldKey]: newColumnFieldData,
    //   },
    // });

    if (Utility.isEmptyObject(newColumnFieldData?.value)) {
      newColumnFieldData.error = "Please select source table to add filter.";
      this.setState({
        ...formData,
        [TABLE]: newColumnFieldData
      });
    } else {
      const tableColumns = TableManger.getTableColumns(formData[TABLE].value);
      let data = this.state.filterData;
      data.push({
        key: tableColumns?.[0]?.id,
        condition: "eq",
        value: ""
      });
      this.setState({ filterData: data });
    }
  };

  getFormField(fieldKey: string, needLabel: boolean, isTargetFilter: boolean) {
    const fieldData = this.state.formData[fieldKey];
    return (
      <Fragment>
        <div
          className={`row justify-content-between ${needLabel ? "p-v-m" : ""}`}
        >
          {needLabel ? <DKLabel text={fieldKey} /> : null}
          <div
            className={`position-relative border-box bg-gray1 border-radius-s ${
              fieldData.error ? " border-red" : ""
            } ${fieldData.open ? " z-index-3" : ""}`}
            style={{
              height: 35,
              width: needLabel ? "80%" : "100%"
            }}
          >
            <DKButton
              title={fieldData.value || fieldData.placeholder || ""}
              className={`parent-height webhook-field-selector justify-content-between border-s border-radius-s text-capitalize ${
                fieldData.value ? "" : "text-gray"
              }`}
              style={{
                textTransform: fieldData.value ? "capitalize" : ""
              }}
              icon={DKIcons.ic_arrow_down}
              isReverse={true}
              disabled={
                !Utility.isEmptyObject(this.props.lookup) ? true : false
              }
              onClick={() => this.toggleFormField(fieldKey, fieldData.open)}
            />
            {fieldData.open ? (
              <DKListPicker
                data={fieldData.options}
                title={fieldKey}
                className="position-absolute border-m shadow-m parent-width"
                style={{ top: -60, [isTargetFilter ? "right" : "left"]: 0 }}
                onSelect={(index: number, value: string) =>
                  this.onFormValueChange(fieldKey, value, index)
                }
                onClose={() =>
                  setTimeout(() => this.toggleFormField(fieldKey, true), 10)
                }
              />
            ) : null}
          </div>
        </div>
        <DKLabel
          text={fieldData.error}
          className="text-red fs-s pl-xs"
          style={{
            display: needLabel && fieldData.error ? "inline-block" : "none",
            marginTop: -6,
            marginLeft: "20%"
          }}
        />
      </Fragment>
    );
  }

  getTextField(fieldKey: string) {
    const fieldData = this.state.formData[fieldKey];
    return (
      <div className="row pb-r">
        <DKLabel
          text={fieldKey}
          className={"mr-r"}
          style={{
            whiteSpace: "nowrap"
          }}
        />
        <DKInput
          value={fieldData.value}
          type={INPUT_TYPE.TEXT}
          required={fieldData.required}
          placeholder={fieldData.placeholder}
          canValidate={fieldData.canValidate}
          errorMessage={fieldData.errorMessage}
          onChange={(value) => this.onFormValueChange(fieldKey, value)}
        />
      </div>
    );
  }

  getForm() {
    return (
      <div
        className="column mt-xl hide-scroll-bar"
        style={{ maxHeight: "80vh", overflowY: "auto" }}
      >
        {this.getTextField(this.formFieldKeys.COLUMN)}
        {this.getFormField(this.formFieldKeys.TABLE, true, false)}
        {this.getFormField(this.formFieldKeys.FIELD, true, false)}
        {this.getFilterRow()}
      </div>
    );
  }
}

export const showAddLookupFieldPopup = (
  config: { tableName: string; lookup: any; columnName?: string },
  onSave: (response: any) => void,
  onClose?: () => void
) => {
  const id = `lookup-field-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "lookup-field-popup app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <AddLookupFieldColumn
      tableName={config.tableName}
      lookup={config.lookup}
      columnName={config.columnName}
      popupId={id}
      onSave={onSave}
      onClose={onClose}
    />,
    document.body.appendChild(div)
  );
};

export default AddLookupFieldColumn;
