import React, { Component } from "react";
import { DKLabel, DKIcon, DKButton, DKLine, DKIcons } from "deskera-ui-library";

import ic_contacts from "../../../assets/menu/ic_user.png";
import ic_deals from "../../../assets/menu/ic_deals.png";
import ic_campaign from "../../../assets/menu/ic_campaign.png";
/* import ic_funnel from "../../../assets/menu/ic_funnel.png"; */
import ic_landing_page from "../../../assets/menu/ic_langing_page.png";
import ic_forms from "../../../assets/menu/ic_form.png";
import ic_automation from "../../../assets/menu/ic_automation.png";

import ContactCard from "./ContactCard";
import DealCard from "./DealCard";
import CampaignCard from "./CampaignCard";
/* import FunnelCard from "./FunnelCard"; */
import LandingPageCard from "./LandingPageCard";
import FormCard from "./FormCard";
import AutomationCard from "./AutomationCard";
import { showFormPopup } from "../../menu/AddNewRecordPopup";
import {
  MODULE_ACTIONS,
  TABLES,
  TABLE_DISPLAY_NAME
} from "../../../managers/TableManger";
import RouteManager, { PAGE_ROUTES } from "../../../managers/RouteManager";
import { DealManager } from "../../../managers/DealManager";
import ContactManager from "../../../managers/ContactManager";
import FormManager from "../../forms/FormManager";
import LandingPageManager from "../../landing_pages/LandingPageManager";
import FormsTemplateManager from "../../forms/templates/TemplateManager";
import LandingPageTemplateManager from "../../landing_pages/templates/TemplateManager";
import AutomationManager from "../../automation/AutomationManager";
import { USER_ACTION_TYPES } from "../../../constants/Permission";
import { isMobileAppWebView } from "../../../utility/GetViewportSize";
import PermissionService from "../../../services/common/permission";
import ActivityCard from "./ActivityCard";
import { showAddActivityPopup } from "../AddActivityPopup";
import DKDropdown from "../../dashboard/DKDropdown";
import { ACTIVITY_FILTER, TICKET_FILTER } from "../../../constants/Constant";
import GraphManager from "../../../managers/GraphManager";
import TicketCard, { checkAndOpenAddTicket } from "./TicketCard";
import CampaignTypeSelector from "../../campaign/CampaignTypeSelector";
import AppManager from "../../../managers/AppManager";

export const PRODUCT_DESCRIPTION = {
  [TABLES.CONTACT]: "See all your newly added contact here",
  [TABLES.DEAL]: "Deals won or loss summary",
  [TABLES.CAMPAIGN]: "Recent Campaigns and modify it",
  [TABLES.FUNNEL]: "See all your funnels here",
  [TABLES.LANDING_PAGE]: "See how your landing pages are performing",
  [TABLES.FORM]: "Create and monitor Forms",
  [TABLES.AUTOMATION]: "Start automation to increase productivity"
};
export const PRODUCT_ICON = {
  [TABLES.CONTACT]: ic_contacts,
  [TABLES.DEAL]: ic_deals,
  [TABLES.CAMPAIGN]: ic_campaign,
  /* [TABLES.FUNNEL]: ic_funnel, */
  [TABLES.LANDING_PAGE]: ic_landing_page,
  [TABLES.FORM]: ic_forms,
  [TABLES.AUTOMATION]: ic_automation,
  [TABLES.ACTIVITY]: DKIcons.ic_recent
};
export const PRODUCT_BADGE_COLOR = {
  [TABLES.CONTACT]: "bg-gray2",
  [TABLES.DEAL]: "bg-gray2",
  [TABLES.CAMPAIGN]: "bg-gray2",
  [TABLES.FUNNEL]: "bg-gray2",
  [TABLES.LANDING_PAGE]: "bg-gray2",
  [TABLES.FORM]: "bg-gray2",
  [TABLES.AUTOMATION]: "bg-gray2"
};
export const PRODUCT_BADGE_COLOR1 = {
  [TABLES.CONTACT]: "data-grid-badge-color-4",
  [TABLES.DEAL]: "data-grid-badge-color-8",
  [TABLES.CAMPAIGN]: "data-grid-badge-color-6",
  [TABLES.FUNNEL]: "data-grid-badge-color-5",
  [TABLES.LANDING_PAGE]: "data-grid-badge-color-7",
  [TABLES.FORM]: "data-grid-badge-color-9",
  [TABLES.AUTOMATION]: "data-grid-badge-color-10"
};

/*
PROPS
- product
*/

class DashboardCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalCount: 0,
      selectedFilter: GraphManager.get("activity").filter,
      selectedStatus: GraphManager.get("ticket").filter,
      needCampaignTypeSelector: false,
      activityRefreshCount: 0
    };
  }
  render() {
    const showAddButton = PermissionService.getInstance().isUserPermitted(
      this.props.product,
      [USER_ACTION_TYPES.REC_CREATE]
    );
    return (
      <div className="column align-items-center parent-size">
        <div className="row parent-width justify-content-between">
          <div className="row align-items-center">
            <DKLabel
              text={TABLE_DISPLAY_NAME[this.props.product]}
              className="fw-m fs-m"
            />
            {this.getTotalCountView()}
          </div>
          {this.props.product === TABLES.ACTIVITY && (
            <div className="column">
              {this.state.selectedFilter && (
                <DKDropdown
                  title={
                    this.state.selectedFilter &&
                    this.state.selectedFilter["name"]
                  }
                  data={ACTIVITY_FILTER.map((filter) => filter.name)}
                  onSelect={(index) => {
                    GraphManager.set("activity", {
                      filter: ACTIVITY_FILTER[index]
                    });
                    this.setState({ selectedFilter: ACTIVITY_FILTER[index] });
                  }}
                />
              )}
            </div>
          )}
          {this.props.product === TABLES.TICKET && (
            <div className="column parent-width align-items-end">
              {this.state.selectedStatus && (
                <DKDropdown
                  title={
                    this.state.selectedStatus &&
                    this.state.selectedStatus["name"]
                  }
                  data={TICKET_FILTER.map((filter) => filter.name)}
                  onSelect={(index) => {
                    GraphManager.set("ticket", {
                      filter: TICKET_FILTER[index]
                    });
                    this.setState({ selectedStatus: TICKET_FILTER[index] });
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div
          className=" mt-r mb-s parent-width scroll-y-only-web hide-scroll-bar"
          style={{
            height: "90%",
            overflowX: "hidden"
          }}
        >
          {this.getProductCard()}
        </div>
        {this.allowActions() && (
          <div className="row-reverse mt-s position-relative">
            {showAddButton && (
              <DKButton
                title={this.getAddButtonText()}
                className=" fw-m bg-gray1 border-m fs-r ml-r"
                onClick={() => this.onCreateNew(this.props.product)}
              />
            )}
            {this.state.needCampaignTypeSelector && (
              <CampaignTypeSelector
                style={{
                  top: 30,
                  right: 8
                }}
                onClose={() => this.toggleCampaignTypeSelector(false)}
              />
            )}
            <DKButton
              onClick={() => this.viewDetailsHandler(this.props.product)}
              title="View All"
              className="fw-m app-font"
              style={{ color: "grey" }}
            />
          </div>
        )}
      </div>
    );
  }
  onCountUpdated = (count) => {
    this.setState({
      totalCount: count
    });
  };
  onCreateNew = (module) => {
    if (AppManager.isModuleActionRestricted(module, MODULE_ACTIONS.ADD)) {
      return;
    }

    switch (module) {
      case TABLES.CONTACT:
        showFormPopup({
          tableName: TABLES.CONTACT,
          defaultValues: ContactManager.getDefaultValues()
        });
        break;
      case TABLES.DEAL:
        showFormPopup({
          tableName: TABLES.DEAL,
          defaultValues: DealManager.getDefaultValues()
        });
        break;
      case TABLES.CAMPAIGN:
        this.toggleCampaignTypeSelector(true);
        break;
      case TABLES.FORM: {
        FormManager.setFormData(null);
        FormsTemplateManager.setSelectedTemplateId(null);
        RouteManager.navigateToPage(PAGE_ROUTES.SELECT_FORM_TEMPLATE);
        break;
      }
      case TABLES.LANDING_PAGE: {
        LandingPageManager.setPageData(null);
        LandingPageTemplateManager.setSelectedTemplateId(null);
        RouteManager.navigateToPage(PAGE_ROUTES.SELECT_LANDING_PAGE_TEMPLATE);
        break;
      }
      /* case TABLES.FUNNEL:
        RouteManager.navigateToPage(PAGE_ROUTES.FUNNEL_SELECTION);
        break; */
      case TABLES.AUTOMATION:
        AutomationManager.setData(null);
        RouteManager.navigateToPage(PAGE_ROUTES.AUTOMATION_BUILDER);
        break;
      case TABLES.ACTIVITY:
        showAddActivityPopup({
          isFetchCalled: true,
          onSubmit: () =>
            this.setState((prevState) => ({
              activityRefreshCount: prevState.activityRefreshCount + 1
            }))
        });
        break;
      case TABLES.TICKET:
        if (AppManager.isModuleActionRestricted(module, MODULE_ACTIONS.EMAIL)) {
          return;
        }
        checkAndOpenAddTicket();
        break;
      default:
        break;
    }
  };
  getProductCard() {
    switch (this.props.product) {
      case TABLES.CONTACT:
        return <ContactCard onCountUpdated={this.onCountUpdated} />;
      case TABLES.DEAL:
        return <DealCard onCountUpdated={this.onCountUpdated} />;
      case TABLES.CAMPAIGN:
        return <CampaignCard onCountUpdated={this.onCountUpdated} />;
      case TABLES.FUNNEL:
        return null;
      // <FunnelCard onCountUpdated={this.onCountUpdated} />;
      case TABLES.LANDING_PAGE:
        return <LandingPageCard onCountUpdated={this.onCountUpdated} />;
      case TABLES.FORM:
        return <FormCard onCountUpdated={this.onCountUpdated} />;
      case TABLES.AUTOMATION:
        return <AutomationCard onCountUpdated={this.onCountUpdated} />;
      case TABLES.ACTIVITY:
        return (
          <ActivityCard
            selectedFilter={this.state.selectedFilter}
            onCountUpdated={this.onCountUpdated}
            needRefresh={this.state.activityRefreshCount}
          />
        );
      case TABLES.TICKET:
        return <TicketCard selectedStatus={this.state.selectedStatus} />;
      default:
        return null;
    }
  }
  viewDetailsHandler = (product) => {
    switch (product) {
      case TABLES.CONTACT:
        return RouteManager.navigateToPage(PAGE_ROUTES.CONTACTS);
      case TABLES.DEAL:
        return RouteManager.navigateToPage(PAGE_ROUTES.DEALS);
      case TABLES.CAMPAIGN:
        return RouteManager.navigateToPage(PAGE_ROUTES.CAMPAIGN);
      /* case TABLES.FUNNEL:
        return RouteManager.navigateToPage(PAGE_ROUTES.FUNNEL); */
      case TABLES.LANDING_PAGE:
        return RouteManager.navigateToPage(PAGE_ROUTES.LANDING_PAGE);
      case TABLES.FORM:
        return RouteManager.navigateToPage(PAGE_ROUTES.FORMS);
      case TABLES.AUTOMATION:
        return RouteManager.navigateToPage(PAGE_ROUTES.AUTOMATION);
      case TABLES.ACTIVITY:
        return RouteManager.navigateToPage(PAGE_ROUTES.ACTIVITY);
      case TABLES.TICKET:
        return RouteManager.navigateToPage(PAGE_ROUTES.SUPPORT_TICKET);
      default:
        return RouteManager.navigateToHome();
    }
  };
  getTotalCountView = () => {
    return (
      <>
        <DKLabel
          className={" ml-s fw-m border-radius-s "}
          text={
            this.state.totalCount > 0 ? `<b>(${this.state.totalCount})</b>` : ""
          }
        />
      </>
    );
  };
  getAddButtonText() {
    switch (this.props.product) {
      case TABLES.CONTACT:
        return "+ New Contact";
      case TABLES.DEAL:
        return "+ New Deal";
      case TABLES.CAMPAIGN:
        return "+ New Campaign";
      /* case TABLES.FUNNEL:
        return "+ New Funnel"; */
      case TABLES.LANDING_PAGE:
        return "+ New Landing Page";
      case TABLES.FORM:
        return "+ New Form";
      case TABLES.AUTOMATION:
        return "+ New Automation";
      case TABLES.ACTIVITY:
        return "+ New Activity";
      case TABLES.TICKET:
        return "+ New Ticket";
      default:
        return "+ New";
    }
  }
  allowActions() {
    const mobileAppSupportedModules = [TABLES.CONTACT, TABLES.DEAL];
    return !(
      isMobileAppWebView() &&
      !mobileAppSupportedModules.includes(this.props.product)
    );
  }
  toggleCampaignTypeSelector = (shouldOpen) => {
    this.setState((prevState) => ({
      needCampaignTypeSelector:
        shouldOpen ?? !prevState.needCampaignTypeSelector
    }));
  };
}

export default DashboardCard;
