import ApiConstants from "../constants/ApiConstants";
import { CRM_NAME } from "../constants/Constant";
import http from "../http";

export default class MixpanelService {
  static getMixpanelToken() {
    return http
      .get(
        ApiConstants.URL_PRIVATE_EVENT_TRACKING +
          ApiConstants.URL.MIXPANEL.GET_TOKEN,
        { params: { skipInterceptor: true, appName: CRM_NAME } }
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        console.error("Error while trying to get mixpanel token: ", err);
        return Promise.reject(err);
      });
  }

  static createEventForTracking(data) {
    return http
      .post(
        ApiConstants.URL_PUBLIC_EVENT_TRACKING +
          ApiConstants.URL.MIXPANEL.CREATE_EVENT,
        data,
        {
          params: { skipInterceptor: true }
        }
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getMixpanelSetting() {
    return http
      .get(
        ApiConstants.URL_PRIVATE_EVENT_TRACKING +
          ApiConstants.URL.MIXPANEL.GET_SETTING,
        { params: { skipInterceptor: true, appName: CRM_NAME } }
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        console.error("Error while trying to get mixpanel setting: ", err);
        return Promise.reject(err);
      });
  }

  static updateMixpanelSetting(payload) {
    return http
      .put(
        ApiConstants.URL_PRIVATE_EVENT_TRACKING +
          ApiConstants.URL.MIXPANEL.GET_SETTING,
        payload
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        console.error("Error while trying to update mixpanel setting: ", err);
        return Promise.reject(err);
      });
  }

  static getRecords(params) {
    params = {
      sortCol: "createdAt",
      sortDir: "1",
      pageSize: 20,
      pageNo: 1,
      ...params
    };
    return http
      .get(
        ApiConstants.URL_PRIVATE_EVENT_TRACKING +
          ApiConstants.URL.MIXPANEL.GET_RECORD_BY_EMAIL_EVENT,
        { params }
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        console.error("Error while trying to fetch record: ", err);
        return Promise.reject(err);
      });
  }
}
