import React, { useEffect, useState } from "react";
import { DKLabel, LineChart } from "deskera-ui-library";
import DashboardService from "../../../services/dashboard";
import DKDropdown from "../DKDropdown";
import { getDashboardWidgetLoading } from "../../dashboard/Dashboard";
import { TableManger, TABLES } from "../../../managers/TableManger";
import { CHART_INTERVALS } from "../../../constants/Constant";
import { COLORS } from "../../../constants/Colors";
import GraphManager from "../../../managers/GraphManager";

export default function ContactByTrend(props: any) {
  const [data, setData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState(
    GraphManager.get("contactByTrend").interval
  );
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const filterData = (data) => {
      if (selectedInterval.interval === CHART_INTERVALS[1].interval) {
        data = data.map((item) => ({
          ...item,
          title: new Date(item.title).getDate()
        }));
      }
      setData([
        {
          title: "Contact",
          color: COLORS.charts.line.yellow,
          points: data
        }
      ]);
    };
    function getData() {
      const contactTableId = TableManger.getTableId(TABLES.CONTACT);
      DashboardService.getDataByTrend({
        tableId: contactTableId,
        interval: selectedInterval.interval
      }).then((data: any) => {
        filterData(data.data);
        setShowLoader(false);
        GraphManager.set("contactByTrend", {
          data: data.data,
          interval: selectedInterval
        });
      });
    }
    if (GraphManager.hasData("contactByTrend")) {
      filterData(GraphManager.get("contactByTrend").data);
      setTimeout(getData, 100);
    } else {
      setShowLoader(true);
      getData();
    }
  }, [selectedInterval]);

  return (
    <div
      className="column align-items-center parent-size"
      style={{
        height: 302
      }}
    >
      <div className="row align-items-center justify-content-between mb-l">
        <DKLabel text={"Contact Trend"} className="fw-m fs-m column" />
        {selectedInterval && (
          <div className="column">
            <DKDropdown
              title={selectedInterval.name}
              data={CHART_INTERVALS.map((interval) => interval.name)}
              onSelect={(index) => {
                GraphManager.clear("contactByTrend");
                setSelectedInterval(Object.assign({}, CHART_INTERVALS[index]));
              }}
            />
          </div>
        )}
      </div>
      {!showLoader && data.length > 0 && <LineChart data={data} height={200} />}
      {showLoader && getDashboardWidgetLoading()}
    </div>
  );
}
