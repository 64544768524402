import Utility, { getRandomAlphaNumericString } from "../utility/Utility";
import { TableManger } from "./TableManger";

export default class DocCopyManager {
  static copiedData = null;

  static setData(data, table) {
    DocCopyManager.copiedData = data
      ? DocCopyManager.getJSONDataFromRowData(data, table)
      : null;
  }

  static getData() {
    return DocCopyManager.copiedData;
  }

  static getJSONDataFromRowData(data, table) {
    let jsonData = data[TableManger.getColumnId(table, "json_data")];

    let json_decoded = Utility.decodeJSON(jsonData);
    json_decoded.hash = getRandomAlphaNumericString();

    return json_decoded;
  }
}
