import ApiConstants from "../constants/ApiConstants";
import httpClient from "../http";

export const DEFAULT_PAGE_SIZE = 20;

class Report {
  static reportTabName: "standard";
  static fetchActivityByContact = (params) => {
    params = { ...params, query: "appName=CRM3" };
    return httpClient.get(ApiConstants.URL.REPORT.GET_ACTIVITIES_BY_CONTACT, {
      params
    });
  };
  static fetchTicketByContact = (params) => {
    params = { ...params, query: "appName=CRM3" };
    return httpClient.get(ApiConstants.URL.REPORT.GET_TICKETS_BY_CONTACT, {
      params
    });
  };
  static fetchAllReports = () => {
    let params = { appName: "CRM3" };
    return httpClient.get(ApiConstants.URL.REPORT.GET, { params });
  };
  static fetchReportTables = () => {
    let params = { appName: "CRM3" };
    return httpClient.get(ApiConstants.URL.REPORT.GET_TABLE_DATA, { params });
  };
  static executeReport(data: any, params) {
    return httpClient
      .post(ApiConstants.URL.REPORT.EXECUTE, data, { params })
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
  static saveReport(payload: any) {
    return httpClient.post(ApiConstants.URL.REPORT.GET, payload).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  static sendEmail(requestData: any) {
    return httpClient
      .post(ApiConstants.URL.REPORT.SEND_EMAIL, requestData)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static updateReport(payload: any, uid: any) {
    return httpClient
      .put(ApiConstants.URL.REPORT.REPORT_BUILDER(uid), payload)
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
  static setActiveReportTab(tabName) {
    this.reportTabName = tabName;
  }
  static getActiveReportTab() {
    return this.reportTabName;
  }
  static deleteReport(reportId: string) {
    return httpClient
      .delete(ApiConstants.URL.REPORT.REPORT_BUILDER(reportId))
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
  static exportData(requestData: any) {
    const url =
      ApiConstants.URL.BASE +
      ApiConstants.URL.REPORT.EXPORT_DATA(
        requestData?.fileType === "XLSX" ? "XLS" : "CSV"
      );
    const requestOptions: any = {
      method: "POST",
      credentials: "include",
      withCredentials: true,
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestData)
    };

    return httpClient
      .post(url, requestData)
      .then((res) => {
        return res;
      })
      .catch((err) =>
        Promise.reject("Export records failed, please try again later.")
      );
  }
  static getUsersPermission(requestData: any) {
    return httpClient.post(ApiConstants.URL.ROLE.USERS, requestData).then(
      (res) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
}
export default Report;
