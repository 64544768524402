import { INPUT_TYPE } from "deskera-ui-library";

export const EVENT_TRACKING_TYPE = {
  ACCOUNT: {
    CREATED: "ACCOUNT_CREATED",
    VISITED: "ACCOUNT_VISITED",
    // DELETE: "ACCOUNT_DELETED",
    IMPORT: "ACCOUNT_IMPORT",
    EXPORT: "ACCOUNT_EXPORT"
  },
  CONTACT: {
    CREATED: "CONTACT_CREATED",
    VISITED: "CONTACT_VISITED",
    // DELETE: "CONTACT_DELETED",
    IMPORT: "CONTACT_IMPORT",
    EXPORT: "CONTACT_EXPORT"
  },
  DEAL: {
    CREATED: "DEAL_CREATED",
    VISITED: "DEAL_VISITED",
    // DELETE: "DEAL_DELETED",
    IMPORT: "DEAL_IMPORT",
    EXPORT: "DEAL_EXPORT",
    PIPELINE_CREATED: "PIPELINE_CREATED",
    PIPELINE_VISITED: "PIPELINE_VISITED",
    STAGE_CREATED: "STAGE_CREATED"
    // STAGE_VISITED: "STAGE_VISITED",
  },
  ACTIVITY: {
    CREATED: "ACTIVITY_CREATED",
    VISITED: "ACTIVITY_VISITED"
    // DELETE: "ACTIVITY_DELETED",
  },
  QUOTE: {
    CREATED: "QUOTE_CREATED",
    VISITED: "QUOTE_VISITED"
    // EXPORT: "QUOTE_EXPORT",
    // DELETE: "QUOTE_DELETED",
  },
  INVOICE: {
    // CREATED: "INVOICE_CREATED",
    VISITED: "INVOICE_VISITED"
    // EXPORT: "INVOICE_EXPORT",
    // DELETE: "INVOICE_DELETED",
  },
  FORM: {
    CREATED: "FORM_CREATED",
    VISITED: "FORM_VISITED"
    // DELETE: "FORM_DELETED",
  },
  CAMPAIGN: {
    CREATED: "CAMPAIGN_CREATED",
    VISITED: "CAMPAIGN_VISITED"
    // DELETE: "CAMPAIGN_DELETED",
  },
  LANDING_PAGE: {
    CREATED: "LANDING_PAGE_CREATED",
    VISITED: "LANDING_PAGE_VISITED"
    // DELETE: "LANDING_PAGE_DELETED",
  },
  AUTOMATION: {
    CREATED: "AUTOMATION_CREATED",
    VISITED: "AUTOMATION_VISITED"
    // DELETE: "AUTOMATION_DELETED",
  },
  TICKET: {
    CREATED: "TICKET_CREATED",
    VISITED: "TICKET_VISITED"
    // DELETE: "TICKET_DELETED",
  },
  CHAT: {
    // CREATED: "CHAT_CREATED",
    VISITED: "CHAT_VISITED"
    // DELETE: "CHAT_DELETED",
  }
};

export const EVENT_TYPE = {
  CREATED: "CREATED",
  VISITED: "VISITED",
  DELETE: "DELETE",
  IMPORT: "IMPORT",
  EXPORT: "EXPORT",
  PIPELINE_CREATED: "PIPELINE_CREATED",
  STAGE_CREATED: "STAGE_CREATED"
};

export const APP_VIEW_INDEX = {
  ALL: "All",
  CRM: "CRM",
  BOOKS: "BOOKS",
  PEOPLE: "PEOPLE"
};

export const LEAD_SCORE_COLS = [
  {
    name: "Event",
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: null,
    required: true,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "event",
    key: "event",
    id: "event",
    allowFilter: false
  },
  // {
  //   name: "Email",
  //   type: INPUT_TYPE.TEXT,
  //   index: 0,
  //   options: null,
  //   required: true,
  //   width: 230,
  //   editable: false,
  //   hidden: false,
  //   uiVisible: true,
  //   systemField: true,
  //   columnCode: "email",
  //   key: "email",
  //   id: "email",
  //   allowFilter: false,
  // },
  {
    name: "Module",
    type: INPUT_TYPE.TEXT,
    index: 0,
    options: null,
    required: true,
    width: 180,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "objectType",
    key: "objectType",
    id: "objectType",
    allowFilter: false
  },
  {
    name: "Score",
    type: INPUT_TYPE.NUMBER,
    index: 0,
    options: null,
    required: true,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "leadScore",
    key: "leadScore",
    id: "leadScore",
    allowFilter: false
  },
  {
    name: "Created Date",
    type: INPUT_TYPE.DATE,
    index: 0,
    options: null,
    required: true,
    width: 150,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "createdAt",
    key: "createdAt",
    id: "createdAt",
    allowFilter: false
  }
];
