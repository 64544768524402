import React, { Component } from "react";
import { DKIcons } from "deskera-ui-library";

const ALL_PRODUCT = [
  "Marketing",
  "Sales",
  "Customer Service",
  "CMS",
  "Operations"
];

export default class ContextMenuPopup extends Component {
  getHeader = () => {
    return (
      <div className="row parent-width justify-content-between p-h-r p-v-s">
        <div className="column fw-m fs-l"></div>
        <div className="column">
          <img
            className="ic-m"
            src={DKIcons.white.ic_close}
            onClick={() => this.props.onClose()}
          />
        </div>
      </div>
    );
  };
  getBody = () => {
    return (
      <div className="column parent-width">
        <div className="row parent-width">
          <div className="text-white parent-width text-align-left pl-xl">
            {"Product & Plans"}
          </div>
          <div
            className="parent-width"
            style={{ border: "1px solid black" }}
          ></div>
        </div>
        {ALL_PRODUCT.map((item) => {
          return (
            <div
              className="column p-v-l p-h-xl fs-xl"
              onClick={() => this.props.onItemClick(item)}
            >
              <div
                className={`text-white ${this.props.selectedItem === item ? "text-underline" : ""}`}
              >
                {item}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  render() {
    return (
      <div className="transparent-background">
        <div
          className="popup-window parent-height"
          style={{ backgroundColor: "#40608b", width: 320 }}
        >
          {this.getHeader()}
          {this.getBody()}
        </div>
      </div>
    );
  }
}
