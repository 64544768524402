import { TableManger as TableManager, TABLES } from "../managers/TableManger";
import Table from "./table";
import { showLoader, removeLoader } from "deskera-ui-library";

class LandingPageService {
  static save(data: any, onSuccess: any, onError: any) {
    showLoader("Saving... Please wait");
    const record = {
      cells: data
    };
    Table.addRecord(record, TableManager.getTableId(TABLES.LANDING_PAGE)).then(
      (response) => {
        removeLoader();
        onSuccess(response);
      },
      (error) => {
        removeLoader();
        onError(error);
      }
    );
  }

  static update(data: any, recordId: any, onSuccess: any, onError: any) {
    showLoader("Updating... Please wait");
    Table.updateRecord(
      data,
      recordId,
      TableManager.getTableId(TABLES.LANDING_PAGE)
    ).then(
      (response) => {
        removeLoader();
        onSuccess(response);
      },
      (error) => {
        removeLoader();
        onError(error);
      }
    );
  }

  static getData(recordId: string, onSuccess: any, onError: any) {
    showLoader("Fetching... Please wait");
    Table.getRecordById(
      recordId,
      TableManager.getTableId(TABLES.LANDING_PAGE)
    ).then(
      (response) => {
        removeLoader();
        onSuccess(response);
      },
      (error) => {
        removeLoader();
        onError(error);
      }
    );
  }
}

export default LandingPageService;
