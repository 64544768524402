import ApiConstants from "../../constants/ApiConstants";
import { DOC_TYPE } from "../../constants/Constant";
import httpClient from "../../http";

export const BOOKS_APP_NAME = "ERP";

export class PrintService {
  static getInvoiceDesignTemplatesByCategory(
    documentType: any
  ): Promise<any[]> {
    const endPoint = ApiConstants.URL.PRINT.GET_DESIGN_TEMPLATE_LIST(
      PrintService.getTemplateNameType(documentType.toString().toLowerCase()),
      BOOKS_APP_NAME
    );
    return httpClient.get(endPoint);
  }
  static getTemplateNameType(type: string) {
    switch (type) {
      case "quotation":
      case "quote":
      case "estimate":
        return DOC_TYPE.QUOTE;
      case "invoice":
      case "sales_invoice":
        return DOC_TYPE.SALES_INVOICE;
      default:
        return type.toUpperCase();
    }
  }
  static getTemplatePrintInfo(payload: any) {
    return httpClient.post(ApiConstants.URL.PRINT.TEMPLATE_PRINT_INFO, payload);
  }
  static printCustomTemplateInvoice(
    documentPrintUUID: string,
    templateId: any,
    preview = true
  ): Promise<any> {
    return httpClient.get(
      ApiConstants.URL.PRINT.PRINT_CUSTOM_TEMPLATE_BY_UUID(documentPrintUUID),
      {
        responseType: "arraybuffer",
        params: {
          preview,
          tid: templateId
        }
      }
    );
  }
  static getDocumentPrintUUID(
    documentCode: string,
    documentType: string,
    params: any = {}
  ): Promise<any> {
    return httpClient.get(
      ApiConstants.URL.PRINT.PRINT_INFO(
        PrintService.getTemplateNameType(documentType.toString().toLowerCase()),
        documentCode
      ),
      { params }
    );
  }
  static getPrintDocumentCategory = (docType: any) => {
    switch (docType) {
      case "QUOTE":
        return "QUOTATION";
      default:
        return docType;
    }
  };
}
