import Template0 from "./Template0";
import Template1 from "./Template1";
import Template2 from "./Template2";
import Template3 from "./Template3";
import Template4 from "./Template4";
import Template5 from "./Template5";
import Template6 from "./Template6";
import Template7 from "./Template7";
import Template8 from "./Template8";
import Template9 from "./Template9";
import Template10 from "./Template10";
import { getRandomAlphaNumericString } from "../../../utility/Utility";

export default class TemplateManager {
  static selectedTemplateId = null;

  static getTemplate() {
    if (TemplateManager.selectedTemplateId === -1) {
      return {
        metaData: null,
        scripts: null,
        hash: getRandomAlphaNumericString(),
        designElements: []
      };
    }

    let selectedTemplateId = TemplateManager.selectedTemplateId || 0;
    return TemplateManager.getTemplateData(selectedTemplateId);
  }

  static getTemplateData(templateId) {
    switch (templateId) {
      case 0:
        return Template0.getData();
      case 1:
        return Template1.getData();
      case 2:
        return Template2.getData();
      case 3:
        return Template3.getData();
      case 4:
        return Template4.getData();
      case 5:
        return Template5.getData();
      case 6:
        return Template6.getData();
      case 7:
        return Template7.getData();
      case 8:
        return Template8.getData();
      case 9:
        return Template9.getData();
      case 10:
        return Template10.getData();
      default:
        return Template0.getData();
    }
  }

  static setSelectedTemplateId(id) {
    TemplateManager.selectedTemplateId = id;
  }

  static getSelectedTemplateId() {
    return TemplateManager.selectedTemplateId;
  }
}
