import "./permission/permission.scss";
import { useEffect, useState } from "react";
import { DKLabel, DKIcon, DKIcons, DKSearchBar } from "deskera-ui-library";
import InviteUser from "./permission/InviteUser";
import UserPermissionRow from "./permission/UserPermissionRow";

import UserManager from "../../managers/UserManager";
import IUser, { getFullName } from "../../model/User";
import { IRole } from "../../model/Roles";
import Utility from "../../utility/Utility";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchRoles,
  selectRoles,
  updateUserPermission
} from "../../redux/slices/rolesPermissionSlice";
import { IPermissionPayload } from "../../services/common/permission";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import { getTenantsDetails } from "../../redux/slices/booksSlice";
import { fetchUsers } from "../../redux/slices/tenantSlice";

const UserPermission = ({ permittedActions }) => {
  const [loading, setLoading] = useState(true);
  const [filterByName, setFilterByName] = useState("");

  const roles: IRole[] = useAppSelector(selectRoles);
  const tenantDetails: any = useAppSelector(getTenantsDetails);
  const users: IUser[] = useAppSelector((state) => state.tenant.users);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (Utility.isEmptyObject(roles)) {
      dispatch(fetchRoles({}))
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshUsersAndRoles = () => {
    setLoading(true);
    Promise.all([dispatch(fetchUsers()), dispatch(fetchRoles({}))])
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const onInviteUserTapped = async (payload: IPermissionPayload) => {
    return dispatch(updateUserPermission(payload)).catch((err) => {});
  };

  function getInviteSection() {
    return (
      <InviteUser
        isLoading={loading}
        canInvite={!permittedActions[USER_ACTION_TYPES.ASSIGN_ROLE]}
        allowRoleAdd={!permittedActions[USER_ACTION_TYPES.REC_CREATE]}
        defaultRole={roles?.[0]?._id}
        inviteUserTapped={onInviteUserTapped}
      />
    );
  }

  function getUsersPermissionSection() {
    return (
      <div
        className="position-relative column flex-1 mt-xl border-s border-radius-s hide-scroll-bar parent-width"
        style={{
          // maxHeight: "86%",
          // maxHeight: "400",

          overflow: "hidden auto"
        }}
      >
        <div
          className="row justify-content-between parent-width p-v-s pl-r border-box bg-gray1 z-index-2 "
          style={{
            top: 0,
            left: 0,
            position: "sticky"
          }}
        >
          <DKLabel text={"Org users"} className="fw-m " />
          <DKIcon
            src={DKIcons.ic_repeat}
            className="ic-s cursor-hand ml-r"
            onClick={refreshUsersAndRoles}
          />
          <DKSearchBar
            className="ml-auto"
            onSearch={(searchText) =>
              setFilterByName(searchText?.toLowerCase())
            }
            searchText={filterByName}
          />
        </div>
        {users
          ?.filter((user: IUser) => {
            const fullName = getFullName(user)?.toLowerCase();
            const userEmail = user.email?.toLowerCase();

            return (
              fullName?.includes(filterByName) ||
              userEmail.includes(filterByName)
            );
          })
          ?.map((user: IUser) => (
            <UserPermissionRow
              user={user}
              isLoading={loading}
              tenantOwnerId={tenantDetails?.userId}
              isCurrentUser={user.iamUserId === UserManager.getUserIamID()}
            />
          ))}
      </div>
    );
  }

  return (
    <div
      className="mt-l parent-width column flex-1"
      style={{ maxHeight: "97%" }}
    >
      {getInviteSection()}
      {getUsersPermissionSection()}
    </div>
  );
};

export default UserPermission;
