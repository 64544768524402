import FormService from "../../../services/form";
import ContactService from "../../../services/contact";
import { getRandomAlphaNumericString } from "../../../utility/Utility";
import ComponentManager from "../../page_designer/ComponentManager";
import ic_x from "../../../assets/social_icons/ic_twitter.png";
import { PAGE_ELEMENT } from "../../page_designer/ComponentList";

export default class Template0 {
  static getData() {
    return {
      metaData: null,
      scripts: null,
      hash: getRandomAlphaNumericString(),
      designElements: [
        {
          type: "Icon",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "",
                fontSize: 14,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1622616405313_deskera-logo-512.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 14,
            marginBottom: 2,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 60,
            cellSpacing: 6,
            iconRadius: 0,
            titleColor: "#000000",
            titleSize: 14,
            descriptionColor: "#837c7c",
            descriptionSize: 14
          }
        },
        {
          type: "Text",
          value: "Let's get in touch!",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 20,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Thanks for your interest in Deskera! Please give us some more information about yourself and we'll be in touch soon.",
          style: {
            width: "82%",
            boxSizing: "border-box",
            fontSize: 14,
            color: "#979595",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Form",
          value: FormService.getContactData(true),
          segmentId:
            ContactService.getSegments().length > 0
              ? ContactService.getSegments()[0].id
              : "",
          style: {
            width: "80%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 16,
            marginBottom: 18,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            fieldSpacing: 10,
            labelSize: 12,
            labelColor: "#000000",
            fieldRadius: 4,
            fieldBorder: "#EBEBEB",
            fieldBackground: "#F8F7FA"
          },
          button: {
            value: "Submit",
            style: {
              buttonColor: "#ffffff",
              buttonSize: 18,
              buttonBackground: "#24bc4a"
            }
          },
          thankYouMsg: "Thank you for submitting form!",
          redirectURL: ""
        },
        {
          type: "Social Buttons",
          value: [
            {
              isRequired: true,
              title: "Facebook",
              url: "",
              icon: "https://i.ibb.co/FX4mz7J/ic-facebook.png"
            },
            {
              isRequired: true,
              title: "Instagram",
              url: "",
              icon: "https://i.ibb.co/VNvh8BL/ic-instagram.png"
            },
            {
              isRequired: true,
              title: "Twitter",
              url: "",
              icon: "https://i.ibb.co/qR8Q8PV/ic-twitter.png"
            },
            {
              isRequired: false,
              title: "LinkedIn",
              url: "",
              icon: "https://i.ibb.co/BzLpvD2/linkedin-2.png"
            },
            {
              isRequired: false,
              title: "Youtube",
              url: "",
              icon: "https://i.ibb.co/tJ6rf4x/youtube.png"
            },
            {
              isRequired: false,
              title: "WhatsApp",
              url: "",
              icon: "https://i.ibb.co/nn931VS/whatsapp-2.png"
            }
          ],
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 2,
            marginBottom: 22,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 26,
            cellSpacing: 10
          }
        }
      ]
    };
  }
}
