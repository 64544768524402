import ApiConstants from "../constants/ApiConstants";
import httpClient from "../http";

export const DEFAULT_PAGE_SIZE = 20;
export const FILE_UID_LENGTH = 14;

class ImportLog {
  static fetchImportLogs = (params) => {
    params = { ...params, query: "appName=CRM3" };
    return httpClient.get(ApiConstants.URL.IMPORT_EXPORT.AUDIT_LOG, { params });
  };
  static fetchPriceBookLogs = (params) => {
    params = { ...params, query: "module=PRICE_BOOK" };
    return httpClient.get(ApiConstants.URL.IMPORT_EXPORT.AUDIT_LOG, { params });
  };
}

export default ImportLog;
