import ApiConstants from "../constants/ApiConstants";
import httpClient from "../http";

export const DEFAULT_PAGE_SIZE = 20;
export const FILE_UID_LENGTH = 14;

class AutomationLog {
  static fetchAutomationLogs = (params) => {
    params = { ...params, query: "appName=CRM3" };
    return httpClient.get(ApiConstants.URL.IMPORT_EXPORT.AUTOMATION_LOGS, {
      params
    });
  };

  static exportAutomationLogs = (params) => {
    return httpClient.post(
      ApiConstants.URL.IMPORT_EXPORT.EXPORT_AUTOMATION_LOGS,
      {},
      { params }
    );
  };
}

export default AutomationLog;
