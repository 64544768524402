import { Component } from "react";
import { Link } from "react-router-dom";
import { DKLabel, showAlert, DKIcon } from "deskera-ui-library";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import { showFormPopup } from "./AddNewRecordPopup";
import { DealManager } from "../../managers/DealManager";
import ContactManager from "../../managers/ContactManager";
import FormManager from "../forms/FormManager";
import CampaignManager from "../campaign/CampaignManager";
import LandingPageManager from "../landing_pages/LandingPageManager";
import FormsTemplateManager from "../forms/templates/TemplateManager";
import CampaignTemplateManager from "../campaign/templates/TemplateManager";
import LandingPageTemplateManager from "../landing_pages/templates/TemplateManager";
import { showAddActivityPopup } from "../common/AddActivityPopup";
import DocCopyManager from "../../managers/DocCopyManager";
import AppManager from "../../managers/AppManager";
import CampaignTypeSelector from "../campaign/CampaignTypeSelector";
import Utility from "../../utility/Utility";
import { LOCAL_STORAGE_KEYS } from "../../constants/Enum";

class AddNewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needCampaignTypeSelector: false
    };
  }

  componentDidMount() {
    if (this.props.onClose) {
      document.addEventListener("mouseup", this.onMouseUp);
    }
  }
  componentWillUnmount() {
    if (this.props.onClose) {
      document.removeEventListener("mouseup", this.onMouseUp);
    }
  }
  onMouseUp = (e) => {
    if (this.props.onClose) {
      const target = e.target;
      const editingContainer = target.closest(
        "#add-new-doc-popup, #dk-alert, #dk-alert-background"
      );

      if (!editingContainer) {
        this.props.onClose();
      }
    }
  };

  render() {
    return (
      <div
        className="column bg-white border-radius-m border-m shadow-m position-absolute pb-s"
        style={{ top: 40, right: -35, width: 150 }}
        id="add-new-doc-popup"
      >
        <DKLabel text="Create New" className="fw-m fs-m pt-r pb-s p-h-s" />
        {this.props.list.map((obj, index) => {
          return obj.route ? (
            <Link
              className="row listPickerBG cursor-hand parent-width border-box p-m"
              style={{ textDecoration: "none", color: "#000" }}
              to={obj.route}
              onClick={(e) => {
                if (AppManager.isModuleActionRestricted(obj.tableName)) {
                  e.preventDefault();
                } else {
                  this.triggerActionToCreate(obj);
                }
                this.props.onClose();
              }}
              prefetch
            >
              <DKIcon src={obj.icon} className="ic-s" />
              <DKLabel text={obj.title} className="ml-r" />
            </Link>
          ) : (
            <div
              className="row listPickerBG cursor-hand parent-width border-box p-m position-relative"
              onClick={(e) => {
                if (AppManager.isModuleActionRestricted(obj.tableName)) {
                  e.preventDefault();
                } else {
                  this.triggerActionToCreate(obj);
                }

                if (obj.hasNestedPicker) return;
                this.props.onClose();
              }}
            >
              <DKIcon src={obj.icon} className="ic-s" />
              <DKLabel text={obj.title} className="ml-r" />
              {this.state.needCampaignTypeSelector &&
                obj.tableName === TABLES.CAMPAIGN && (
                  <CampaignTypeSelector
                    style={{
                      top: 0,
                      right: "100%"
                    }}
                    onClose={() =>
                      setTimeout(() => {
                        this.toggleCampaignTypeSelector(false);
                        this.props.onClose();
                      }, 10)
                    }
                  />
                )}
            </div>
          );
        })}
      </div>
    );
  }

  toggleCampaignTypeSelector = (shouldOpen) => {
    this.setState((prevState) => ({
      needCampaignTypeSelector:
        shouldOpen ?? !prevState.needCampaignTypeSelector
    }));
  };

  triggerActionToCreate(button) {
    DocCopyManager.setData(null);
    switch (button.tableName) {
      case TABLES.CONTACT:
        showFormPopup({
          tableName: TABLES.CONTACT,
          defaultValues: ContactManager.getDefaultValues()
        });
        break;
      case TABLES.DEAL:
        const dealData = DealManager.getDefaultValues();
        const pipelineColumnID = TableManger.getColumnId(
          TABLES.DEAL,
          COLUMN_CODE.DEAL.PIPELINE_ID
        );
        let pipelineIndex = Utility.getPersistentValue(
          LOCAL_STORAGE_KEYS.SELECTED_PIPELINE
        );
        if (pipelineIndex) {
          dealData[pipelineColumnID] = [pipelineIndex] ?? [0];
        }
        DealManager.getDataToUpdate(dealData);
        showFormPopup(
          {
            tableName: TABLES.DEAL,
            defaultValues: dealData
          },
          (newDealData) => {
            dispatchEvent(
              new CustomEvent("dealAdded", { detail: { data: newDealData } })
            );
          }
        );
        break;
      case TABLES.FORM: {
        FormManager.setFormData(null);
        FormsTemplateManager.setSelectedTemplateId(null);
        break;
      }
      case TABLES.CAMPAIGN: {
        this.toggleCampaignTypeSelector(true);
        return;
      }
      case TABLES.LANDING_PAGE: {
        LandingPageManager.setPageData(null);
        LandingPageTemplateManager.setSelectedTemplateId(null);
        break;
      }
      /* case TABLES.FUNNEL: */
      case TABLES.AUTOMATION: {
        break;
      }
      case TABLES.ACTIVITY:
        showAddActivityPopup({});
        break;
      default:
        showAlert("Cell tapped ", "Index- " + button.tableName);
        break;
    }
    this.props.onClose();
  }
}

export default AddNewList;
