import {
  INPUT_TYPE,
  DKIcon,
  DKIcons,
  DKLabel,
  DKTooltipWrapper
} from "deskera-ui-library";
import ApiConstants from "../../constants/ApiConstants";
import { getCurrencySymbolFromCode } from "../../constants/Currencies";
import TenantManager from "../../managers/TenantManager";
import ic_draft from "../../assets/icons/ic_draft.svg";
import {
  DOCUMENT_STATUS,
  DOC_TYPE,
  OPTIONAL_VALUES,
  PRODUCT_DEFAULT_MOQ,
  PRODUCT_TYPE
} from "../../constants/Constant";
import Utility, { unitPriceRenderer } from "../../utility/Utility";
import { BooksHelper } from "../../Helper/BooksHelper";
import { store } from "../../redux/store";

/** Product utility */
export const productFormatter = ({ value }) => {
  return value
    ? `${value.name}<br /><span style="font-size: 10px; color:gray; margin-top: 3px;">${
        value.hsnOrSacCode ? `HSN/SAC: ${value.hsnOrSacCode}` : ""
      }</span>`
    : "";
};
export const productDataParser = (data) => data.content;
export const productRenderer = (index, product) => {
  return (
    <div className="column parent-width">
      <div className="row parent-width text-align-left"> {product.name} </div>
      <div className="row parent-width justify-content-between fs-s">
        <div className="row text-gray"> {product?.documentSequenceCode} </div>
        {product.barcode && (
          <div className="row">
            <div className="column">
              <svg
                width="12px"
                height="12px"
                viewBox="0 0 12 12"
                fill="none"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path
                  d="M0 1.50006H1.17434V10.5001H0V1.50006ZM1.89308 10.5001H2.55422V1.50006H1.89308V10.5001ZM3.03077 10.5001H3.6906V1.50006H3.03077V10.5001ZM4.55859 10.5001H6.00393V1.50006H4.55859V10.5001ZM7.16125 10.5001H7.88392V1.50006H7.16125V10.5001ZM9.00458 10.5001H12V1.50006H9.00458V10.5001Z"
                  fill="#B5B4B4"
                >
                  {" "}
                </path>
              </svg>
            </div>
            <div className="column">{product.barcode}</div>
          </div>
        )}
      </div>
    </div>
  );
};
/** tax utility */
export const taxFormatter = ({ value }) => {
  return value?.name || "";
};
export function taxOptionRenderer(index, tax) {
  return (
    <div className="column parent-width">
      <div className="row parent-width"> {tax.name} </div>
    </div>
  );
}

/** quantity display utility */
export const quantityRenderer = ({ rowData, value }) => {
  let availableQuantity = rowData?.availableQuantity;
  if (
    typeof availableQuantity === "undefined" ||
    availableQuantity === null ||
    !Utility.isEmptyObject(rowData.product?.inventory)
  ) {
    if (rowData.product?.inventory) {
      availableQuantity = rowData.product?.inventory?.availableQuantity;
    } else {
      availableQuantity = rowData.product?.availableQuantity;
    }
  }

  if (rowData.product?.type === PRODUCT_TYPE.NON_TRACKED) {
    availableQuantity = undefined;
  }

  return (
    <div
      className={`row parent-width ${
        typeof availableQuantity === "undefined" || availableQuantity === null
          ? "justify-content-end"
          : "justify-content-between"
      }`}
    >
      {typeof availableQuantity !== "undefined" &&
        availableQuantity !== null && (
          <DKTooltipWrapper
            content={getQuantityTooltip(rowData, availableQuantity)}
            tooltipClassName="bg-deskera-secondary"
          >
            <DKIcon
              src={DKIcons.ic_info}
              className="ic-xs opacity-60 cursor-pointer"
            />
          </DKTooltipWrapper>
        )}
      <DKLabel className="fs-m" text={getNumber(value)} />
    </div>
  );
};

const getQuantityTooltip = (lineItem, availableQuantity) => {
  const documentUOMSchemaDefinition = lineItem.documentUOMSchemaDefinition;
  let availableCount = "NA";
  if (typeof availableQuantity !== "undefined" && availableQuantity !== null) {
    if (documentUOMSchemaDefinition && availableQuantity) {
      availableCount =
        (availableQuantity * documentUOMSchemaDefinition.sinkConversionFactor) /
        documentUOMSchemaDefinition.sourceConversionFactor;
      if (availableCount) {
        availableCount = Utility.roundOff(availableCount);
      }
    } else {
      availableCount = availableQuantity ? availableQuantity : "NA";
    }
  }

  let htmlStr = "<div>";

  /* For DOC_TYPE.QUOTE */
  if (typeof availableCount !== "undefined" && availableCount !== null) {
    htmlStr += `<div>Total Stock: ${availableCount}</div>`;
  }

  const productDetails = lineItem.product;
  if (
    (productDetails.inventory ||
      productDetails.reservedQuantity !== undefined) &&
    !isNaN(availableCount)
  ) {
    let reservedQuantity = productDetails.inventory
      ? productDetails.inventory.reservedQuantity || 0
      : productDetails.reservedQuantity;

    if (
      documentUOMSchemaDefinition &&
      reservedQuantity &&
      !documentUOMSchemaDefinition.isBaseUom
    ) {
      reservedQuantity = getUomQuantity(
        reservedQuantity,
        documentUOMSchemaDefinition
      );
    }
    htmlStr += `<div>Reserve Stock: ${reservedQuantity}</div>`;
    htmlStr += `<div>Available Stock: ${
      availableCount - reservedQuantity
    }</div>`;
  }

  htmlStr += "</div>";
  return htmlStr;
};

/** unit of measurement utility */
export const getUomQuantity = (baseQuantity, documentUOMSchemaDefinition) => {
  if (!isNaN(baseQuantity) && documentUOMSchemaDefinition) {
    return Utility.roundOff(
      (baseQuantity * documentUOMSchemaDefinition.sinkConversionFactor) /
        documentUOMSchemaDefinition.sourceConversionFactor
    );
  }
  return baseQuantity;
};
export const uomFormatter = (obj) => {
  const value = obj.value;
  if (!Utility.isEmptyObject(value) && obj?.rowData?.productQuantity) {
    return `${
      value.name
    }<br><span style="font-size: 10px; color:gray; margin-top: 3px;">${
      value.sourceConversionFactor
        ? `${Utility.roundOff(
            (value.sourceConversionFactor / value.sinkConversionFactor) *
              obj.rowData.productQuantity
          )} ${BooksHelper.getBaseUomName(value).name}`
        : ""
    }</span>`;
  } else {
    return value.name;
  }
};
export const uomOptionRenderer = (index, uom) => {
  return (
    <div className="column parent-width">
      <div className="row parent-width">{uom?.name}</div>
    </div>
  );
};

export const getNumber = (value) => {
  const tenantDecimalScale = store.getState().books.tenantsDetails.decimalScale;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: tenantDecimalScale ?? 2,
    maximumFractionDigits: tenantDecimalScale ?? 2
  });

  return formatter.format(value);
};
export const amountRenderer = ({ value, rowData }) => {
  return `${getCurrencySymbolFromCode(rowData?.currency) || TenantManager.getCurrencySymbol()} ${getNumber(value)}`;
};
export const priceRenderer = ({ value, rowData }) => {
  return (
    <div className={`row parent-width justify-content-between`}>
      <DKTooltipWrapper
        content={getBasePriceTooltip(rowData, value)}
        tooltipClassName="bg-deskera-secondary"
      >
        <DKIcon
          src={DKIcons.ic_info}
          className="ic-xs opacity-60 cursor-pointer"
        />
      </DKTooltipWrapper>
      <DKLabel
        text={`${getCurrencySymbolFromCode(rowData?.currency) || TenantManager.getCurrencySymbol()} ${getNumber(value)}`}
      />
    </div>
  );
};
const getBasePriceTooltip = (rowData) => {
  let basePrice =
    Number(rowData?.basePrice ?? rowData?.product?.salesPrice) || 0;
  return `<div>Base Price: ${amountRenderer({ rowData, value: basePrice })} <div class="fw-m">MoQ : ${rowData?.minimumQuantity || PRODUCT_DEFAULT_MOQ}</div></div>`;
};
export const discountRenderer = ({ value, rowIndex, rowData }) => {
  return rowData.isDiscountInPercentage && value
    ? `${value}%`
    : `${amountRenderer({ value, rowIndex, rowData })}`;
};
export const createdByRenderer = (creator) => {
  return (
    <div
      className={`row fs-m border-radius-s ${creator.color}`}
      style={{
        width: "auto",
        padding: "2px 6px"
      }}
    >
      <div className="fs-r text-align-left display-block">{creator.name}</div>
    </div>
  );
};
export const getDraftIconBlockForGrid = (draftCode) =>
  !Utility.isEmptyObject(draftCode) ? (
    <div>
      <div>{draftCode}</div>
      <div
        class="fs-s bg-chip-gray border-radius-s text-gray p-h-xs mt-xs"
        style={{ width: "fit-content" }}
      >
        Draft
      </div>
    </div>
  ) : (
    <div class="row" title="draft">
      <div class="column">
        <img style={{ width: 18 }} alt={"draft"} src={ic_draft} />
      </div>
      <span
        class="column bg-chip-gray fs-m border-radius-s ml-s text-gray"
        style={{ padding: "2px 6px" }}
      >
        Draft
      </span>
    </div>
  );

export const taxRenderer = ({ rowData, value }) => {
  let htmlBlock = (
    <div>
      {rowData?.product?.taxable === true &&
        rowData?.product?.taxPreference !== false &&
        value && <DKLabel text={value.name} />}
      {!Utility.isEmptyObject(rowData?.product) &&
        rowData?.product?.taxable === false && (
          <DKLabel
            className="text-gray fs-s"
            text={`NON-TAXABLE ${
              rowData?.product?.taxExemptionReason
                ? ": " + rowData?.product?.taxExemptionReason
                : ""
            }`}
          />
        )}
      {!Utility.isEmptyObject(rowData?.product) &&
        rowData?.product?.taxPreference === false && (
          <DKLabel
            className="text-gray fs-s"
            text={`NON-TAXABLE ${
              rowData?.product?.taxExemptionReason
                ? ": " + rowData?.product?.taxExemptionReason
                : ""
            }`}
          />
        )}
    </div>
  );
  return htmlBlock;
};

export const QuotesColumnConfig = [
  {
    id: "product",
    key: "product",
    name: "Product",
    type: INPUT_TYPE.DROPDOWN,
    width: 180,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    formatter: productFormatter,
    dropdownConfig: {
      title: "Select Product",
      allowSearch: true,
      searchableKey: "name",
      style: { minWidth: 230 },
      className: "shadow-m width-auto",
      searchApiConfig: {
        // getUrl: (search) => `${ApiConstants.URL.BASE}${ApiConstants.URL.BOOKS.GET_PRODUCTS}?search=${search}&query=active=true,hasVariants=false&productTransactionType=BOTH,SALES`,
        dataParser: productDataParser,
        debounceTime: 300
      },
      data: [],
      renderer: productRenderer,
      onSelect: (index, obj, rowIndex) => {},
      button: {
        title: "+ Add New",
        className: "bg-button text-white",
        onClick: () => {}
      }
    }
  },
  {
    id: "optional",
    key: "optional",
    name: "Optional",
    type: INPUT_TYPE.DROPDOWN,
    width: 180,
    systemField: true,
    editable: true,
    hidden: false,
    required: false,
    uiVisible: true,
    value: OPTIONAL_VALUES[0],
    dropdownConfig: {
      data: OPTIONAL_VALUES,
      onSelect: (index, obj, rowIndex) => {},
      renderer: (i, data) => {
        return <DKLabel text={data?.name} />;
      }
    }
  },
  {
    id: "documentSequenceCode",
    key: "documentSequenceCode",
    name: "Product Code",
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: "description",
    key: "productDescription",
    name: "Description",
    type: INPUT_TYPE.TEXT,
    width: 180,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true
  },
  {
    id: "quantity",
    key: "productQuantity",
    name: "Qty",
    type: INPUT_TYPE.NUMBER,
    width: 120,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: "right",
    formatter: ({ value }) => getNumber(value),
    renderer: quantityRenderer
  },
  {
    id: "uom",
    key: "uom",
    name: "UOM",
    type: INPUT_TYPE.DROPDOWN,
    width: 190,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    formatter: uomFormatter,
    dropdownConfig: {
      title: "Select UOM",
      allowSearch: true,
      searchableKey: "name",
      style: { minWidth: 150 },
      className: "shadow-m width-auto",
      data: [],
      renderer: uomOptionRenderer,
      onSelect: (index, obj, rowIndex) => {}
    }
  },
  {
    id: "price",
    key: "unitPrice",
    name: "Price",
    type: INPUT_TYPE.NUMBER,
    width: 120,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: "right",
    allowNegativeNumber: true,
    formatter: ({ value, rowIndex, rowData }) =>
      amountRenderer({ value, rowIndex, rowData }),
    renderer: ({ value, rowIndex, rowData }) =>
      priceRenderer({ value, rowIndex, rowData }),
    dropdownConfig: {
      title: "Price History",
      data: [],
      renderer: unitPriceRenderer
    }
  },
  {
    id: "unitDiscount",
    key: "unitDiscount",
    name: "Discount per Unit",
    type: INPUT_TYPE.TEXT,
    width: 140,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: "right",
    formatter: discountRenderer
  },
  {
    id: "discount",
    key: "discount",
    name: "Total Discount",
    type: INPUT_TYPE.TEXT,
    width: 120,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: "right",
    formatter: discountRenderer
  },
  {
    id: "tax",
    key: "tax",
    name: "Tax",
    type: INPUT_TYPE.DROPDOWN,
    width: 100,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    // formatter: taxFormatter,
    renderer: taxRenderer,
    dropdownConfig: {
      title: "Tax",
      allowSearch: true,
      searchableKey: "name",
      style: { minWidth: 150 },
      data: [],
      onSelect: (index, obj, rowIndex) => {},
      renderer: taxOptionRenderer,
      button: {
        title: "+ Add New",
        className: "bg-button text-white",
        onClick: () => {}
      }
    },
    searchApiConfig: {
      getUrl: (search) =>
        `${ApiConstants.URL.BASE}${ApiConstants.URL.BOOKS.GET_TAXES}?search=${search}`,
      dataParser: productDataParser,
      debounceTime: 300
    }
  },
  {
    id: "taxAmount",
    key: "taxAmount",
    name: "Tax Amount",
    type: INPUT_TYPE.NUMBER,
    width: 100,
    systemField: true,
    editable: true,
    hidden: true,
    uiVisible: false,
    textAlign: "right",
    formatter: ({ value, rowIndex, rowData }) =>
      amountRenderer({ value, rowIndex, rowData })
  },
  {
    id: "amount",
    key: "totalAmount",
    name: "Amount",
    type: INPUT_TYPE.NUMBER,
    width: 100,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: "right",
    formatter: (data) => amountRenderer(data)
  }
];
export const CREATOR_COLUMN = {
  name: "Created By",
  key: "createdBy",
  allowAddOption: false,
  required: false,
  systemField: true,
  width: 150,
  type: INPUT_TYPE.SELECT,
  allowFilter: false,
  columnCode: "createdBy",
  editable: false,
  hidden: false,
  options: [],
  uiVisible: true
};

export const APPROVAL_HISTORY_COLUMNS = [
  {
    name: "Level",
    type: "text",
    index: 3,
    options: null,
    required: false,
    width: 100,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "level",
    key: "level"
  },
  {
    name: "Level Name",
    type: "text",
    index: 3,
    options: null,
    required: false,
    width: 215,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "levelLabel",
    key: "levelLabel"
  },
  {
    name: "Approved/Rejected By",
    type: "text",
    index: 3,
    options: null,
    required: false,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "approvedBy",
    key: "approvedBy"
  },
  {
    name: "Approved/Rejected Date",
    type: "text",
    index: 4,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "approvedDate",
    key: "approvedDate"
  },
  {
    name: "Status",
    type: "text",
    index: 5,
    options: null,
    required: false,
    width: 100,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "status",
    key: "status"
  }
];
export const showActionButton = (btn, document, documentType) => {
  let showBtn = true;
  switch (btn) {
    case "convert":
      const nonPendingProductsInQuote = Utility.getNonPendingsItems(
        document.quotationItemDtoList ? document.quotationItemDtoList : []
      );
      if (
        documentType === DOC_TYPE.QUOTE &&
        (document.linkedSalesInvoices?.length > 0 ||
          document.linkedSalesOrders?.length > 0) &&
        nonPendingProductsInQuote?.length ===
          document.quotationItemDtoList?.length
      ) {
        showBtn = false;
      } else if (
        documentType === DOC_TYPE.QUOTE &&
        document.status === DOCUMENT_STATUS.PROCESSED
      ) {
        showBtn = false;
      }
      break;
  }
  return showBtn;
};

export const upLiftDownLiftColumnConfig = [
  {
    id: "upliftPrice",
    key: "upliftPrice",
    name: "Uplifted",
    type: INPUT_TYPE.NUMBER,
    width: 120,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: "right",
    formatter: ({ value, rowIndex, rowData }) =>
      amountRenderer({ value, rowIndex, rowData })
  },
  {
    id: "downliftPrice",
    key: "downliftPrice",
    name: "Down lifted",
    type: INPUT_TYPE.NUMBER,
    width: 120,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: "right",
    formatter: ({ value, rowIndex, rowData }) =>
      amountRenderer({ value, rowIndex, rowData })
  }
];
