import { Component, Fragment } from "react";
import "./smtp.scss";
import {
  DKInput,
  DKIcon,
  DKIcons,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKLabel,
  DKButton,
  DKCheckMark
} from "deskera-ui-library";
import ReactDOM from "react-dom";
import {
  APP_NAME,
  EMAIL_SOURCE_TYPE,
  SMTP_HELP_LINKS
} from "../../constants/Constant";
import SMTPService from "../../services/smtp";
import Utility from "../../utility/Utility";

import ic_gmail from "../../assets/mail_icons/gmail.png";
import ic_outlook from "../../assets/mail_icons/outlook.png";
import ic_yahoo from "../../assets/mail_icons/yahoo.png";

/*
PROPS
- data
- onSave(data)
- onCancel
- supportEmail
*/

export interface ISmptpPopupProps {
  supportEmail?: any;
  onCancel?: () => void;
  showImap?: boolean;
  onSave?: (data: any, isUpdated?: boolean) => void;
  popupId?: any;
  data?: any;
  type?: any;
  username?: any;
}
class SmtpPopup extends Component<ISmptpPopupProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      canValidate: false,
      showImap: this.props.showImap || false,
      smtpData: props.data
        ? Utility.makeCopyOfObject(props.data)
        : this.getDefaultData(),
      supportEmail: this.props.supportEmail || false
    };
  }
  render() {
    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ padding: 24 }}>
          {this.getHeader()}
          {this.getBasicForm()}
          {this.state.showImap && this.getIMAPForm()}
          {this.getSMTPForm()}
          {this.getServerSecurityForm()}
          {this.getHelpBanner(false)}
        </div>
      </div>
    );
  }

  getDefaultData() {
    return {
      name: "",
      email: "",
      username: "",
      password: "",
      smtp_server: "",
      smtp_port: "",
      type: "",
      is_secure_connection: false,
      is_default: true,
      app_name: APP_NAME
    };
  }
  removePopUp = () => {
    if (this.props?.popupId) {
      const componentPopup = document.getElementById(this.props.popupId);
      if (!Utility.isEmptyObject(componentPopup)) {
        ReactDOM.unmountComponentAtNode(componentPopup);
        componentPopup?.remove();
      }
    }
  };
  getHeader() {
    return (
      <Fragment>
        <div className="row justify-content-start">
          <DKLabel text="SMTP Setup" className="fs-l fw-m" />
          <div className="row width-auto position-relative smtp-help-tooltip">
            <DKIcon
              src={DKIcons.ic_info}
              className="ic-s ml-s"
              style={{
                paddingTop: 2
              }}
            />
            {this.getHelpBanner(true)}
          </div>
          <div className="row" style={{ width: "auto", marginLeft: "auto" }}>
            <DKButton
              title="Cancel"
              className="bg-gray1 border-m"
              onClick={() => {
                this.props.onCancel();
                this.removePopUp();
              }}
            />
            <DKButton
              title="Save"
              className="bg-button text-white ml-r"
              onClick={() => {
                this.saveTapped();
              }}
            />
          </div>
        </div>
        <DKLabel
          className="text-gray"
          text="SMTP is used to send and receive e-mails."
        />
      </Fragment>
    );
  }

  getBasicForm() {
    const direction = INPUT_VIEW_DIRECTION.VERTICAL;
    const passwordFieldVisible = this.props?.type
      ? this.props?.type !== EMAIL_SOURCE_TYPE.office365
      : this.state?.smtpData.type !== EMAIL_SOURCE_TYPE.office365;
    return (
      <div className="parent-width mt-xl">
        <DKLabel text="Basic Details" className="fw-m fs-l mb-s" />
        <DKInput
          title="Full Name or Display Name"
          className="mb-l"
          type={INPUT_TYPE.TEXT}
          value={this.state.smtpData.name}
          required
          canValidate={this.state.canValidate}
          onChange={(value) => this.updateFormData("name", value)}
          direction={direction}
        />
        <DKInput
          title="Your email address"
          className="mb-l"
          type={INPUT_TYPE.EMAIL}
          value={this.state.smtpData.email}
          required
          canValidate={this.state.canValidate}
          onChange={(value) => this.updateFormData("email", value)}
          direction={direction}
          capitalised={false}
        />
        <DKInput
          title="User name"
          className="mb-l"
          type={INPUT_TYPE.TEXT}
          value={this.state.smtpData.username}
          required
          canValidate={this.state.canValidate}
          onChange={(value) => this.updateFormData("username", value)}
          direction={direction}
          capitalised={false}
        />
        {passwordFieldVisible && (
          <DKInput
            title="Password"
            className="mb-l"
            type={"password"}
            value={this.state.smtpData.password}
            required
            canValidate={this.state.canValidate}
            onChange={(value) => this.updateFormData("password", value)}
            direction={direction}
            isPassword={true}
            capitalised={false}
          />
        )}
      </div>
    );
  }
  getSMTPForm() {
    const direction = INPUT_VIEW_DIRECTION.VERTICAL;
    return (
      <div className="parent-width mt-xxl">
        <DKLabel
          text="Outgoing Mail (SMTP) Server"
          className="fw-m fs-l mb-s"
        />
        <DKInput
          title="Server"
          className="mb-l"
          type={INPUT_TYPE.TEXT}
          value={this.state.smtpData.smtp_server}
          required
          canValidate={this.state.canValidate}
          onChange={(value) => this.updateFormData("smtp_server", value)}
          direction={direction}
          capitalised={false}
        />
        <DKInput
          title="port"
          className="mb-l"
          type={INPUT_TYPE.NUMBER}
          value={this.state.smtpData.smtp_port}
          required
          canValidate={this.state.canValidate}
          onChange={(value) => this.updateFormData("smtp_port", value)}
          direction={direction}
        />
      </div>
    );
  }

  getIMAPForm() {
    const direction = INPUT_VIEW_DIRECTION.VERTICAL;
    return (
      <div className="parent-width mt-xxl">
        {/* <DKLabel
          text="Advanced Options"
          className="fw-m fs-l mb-m"
        />
        <DKCheckMark
          isSelected={this.state.smtpData.is_secure_connection}
          title="Outgoing mail uses the same settings as incoming"
          onClick={() => {
            this.sameIncomingMail()
          }}
        /> */}
        <DKLabel
          text="Incoming Mail (IMAP) Server"
          className="fw-m fs-l mb-s"
        />
        <DKInput
          title="Server"
          className="mb-l"
          type={INPUT_TYPE.TEXT}
          value={this.state.smtpData.imap_server}
          required
          canValidate={this.state.canValidate}
          onChange={(value) => this.updateFormData("imap_server", value)}
          direction={direction}
          capitalised={false}
        />
        <DKInput
          title="port"
          className="mb-l"
          type={INPUT_TYPE.NUMBER}
          value={this.state.smtpData.imap_port}
          required
          canValidate={this.state.canValidate}
          onChange={(value) => this.updateFormData("imap_port", value)}
          direction={direction}
        />
      </div>
    );
  }

  getServerSecurityForm() {
    return (
      <div className="parent-width mt-xxl mb-l">
        <DKLabel text="Server security" className="fw-m fs-l mb-m" />
        <DKCheckMark
          isSelected={this.state.smtpData.is_secure_connection}
          title="Allow non secure certificate"
          onClick={() => {
            this.updateFormData(
              "is_secure_connection",
              !this.state.smtpData.is_secure_connection
            );
          }}
        />
      </div>
    );
  }

  updateFormData(property, value) {
    let smtpData = this.state.smtpData;
    if (property === "smtp_port") smtpData[property] = value;
    else smtpData[property] = value;
    this.setState({ smtpData: smtpData });
  }

  saveTapped = () => {
    this.setState({ canValidate: true });
    const passwordFieldVisible = this.props?.type
      ? this.props?.type !== EMAIL_SOURCE_TYPE.office365
      : this.state?.smtpData.type !== EMAIL_SOURCE_TYPE.office365;
    if (
      this.isEmpty(this.state.smtpData.name) ||
      this.isEmpty(this.state.smtpData.email) ||
      this.isEmpty(this.state.smtpData.username) ||
      (passwordFieldVisible && this.isEmpty(this.state.smtpData.password)) ||
      this.isEmpty(this.state.smtpData.smtp_server) ||
      this.isEmpty(this.state.smtpData.smtp_port) ||
      !Utility.isValidEmail(this.state.smtpData.email)
    ) {
      /* showAlert(
        "Incomplete/Invalid form",
        "Please enter all valid fields to proceed!"
      ); */
    } else {
      if (!this.state.smtpData.id) {
        if (this.state.showImap) {
          this.saveImapSetup();
        } else {
          this.saveSetup();
        }
      } else {
        this.updateSetup();
      }
    }
  };

  saveSetup() {
    SMTPService.saveSetup(this.getDataToSaveOrUpdate()).then((res: any) => {
      this.props.onSave(res);
      this.removePopUp();
    });
  }

  updateSetup() {
    SMTPService.updateSetup(
      this.getDataToSaveOrUpdate(),
      this.state.smtpData.id
    ).then((res) => {
      this.props.onSave(this.getDataToSaveOrUpdate(), true);
    });
  }
  getDataToSaveOrUpdate() {
    let data = { ...this.state.smtpData };
    if (data.smtp_port && !isNaN(parseInt(data.smtp_port))) {
      data.smtp_port = parseInt(data.smtp_port);
    }
    if (data.imap_port && !isNaN(parseInt(data.imap_port))) {
      data.imap_port = parseInt(data.imap_port);
      data.type = this.props?.type ? this.props?.type : EMAIL_SOURCE_TYPE.smtp;
    }
    data["app_name"] = APP_NAME;
    if (!Utility.isEmptyObject(this.props?.type)) {
      data["oauth_username"] = this.props?.username;
      data.type = this.props?.type ? this.props?.type : EMAIL_SOURCE_TYPE.smtp;
    }
    return data;
  }

  isEmpty(data) {
    return data === null || data === undefined || data.toString().trim() === "";
  }

  saveImapSetup() {
    SMTPService.saveImapSetup(
      this.getDataToSaveOrUpdate(),
      this.state.supportEmail
    ).then((res) => {
      this.props.onSave(res);
      this.removePopUp();
    });
  }

  sameIncomingMail() {}

  getHelpBanner(isTooltip) {
    return (
      <div
        className={`smtp-help-banner row border-radius-m bg-deskera-secondary p-r ${
          isTooltip
            ? "position-absolute z-index-3 width-auto border-m shadow-s"
            : "parent-width mt-xxl"
        }`}
        style={{
          top: isTooltip ? 16 : 0,
          left: isTooltip ? 16 : 0
        }}
      >
        <DKLabel
          text="🙋‍♂️ How to setup SMTP? "
          style={{
            whiteSpace: "nowrap"
          }}
        />
        <DKIcon
          src={ic_gmail}
          className="ml-m ic-s cursor-hand"
          onClick={() => Utility.openInNewTab(SMTP_HELP_LINKS.GMAIL)}
        />
        <DKIcon
          src={ic_outlook}
          className="ml-m ic-s cursor-hand"
          onClick={() => Utility.openInNewTab(SMTP_HELP_LINKS.OUTLOOK)}
        />
        <DKIcon
          src={ic_yahoo}
          className="ml-m ic-s cursor-hand"
          onClick={() => Utility.openInNewTab(SMTP_HELP_LINKS.YAHOO)}
        />
      </div>
    );
  }
}
export const showSmtpPopup = (config: {
  supportEmail?: any;
  onCancel?: any;
  showImap?: any;
  onSave?: any;
  data?: any;
}) => {
  const id = `add-smtp-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <SmtpPopup
      popupId={id}
      data={config.data}
      supportEmail={config.supportEmail}
      onCancel={config.onCancel}
      showImap={config.showImap}
      onSave={config.onSave}
    />,
    document.body.appendChild(div)
  );
};

export default SmtpPopup;
