import React, { useEffect, useState } from "react";
import { DKLabel, PieChart } from "deskera-ui-library";
import DashboardService from "../../../services/dashboard";
import { COLORS } from "../../../constants/Colors";
import {
  COLUMN_CODE,
  TableManger,
  TABLES
} from "../../../managers/TableManger";
import { getDashboardWidgetLoading } from "../../dashboard/Dashboard";
import GraphManager from "../../../managers/GraphManager";
import DKDropdown from "../DKDropdown";
import { CHART_INTERVALS } from "../../../constants/Constant";

export default function ContactByLabel(props: any) {
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState(
    GraphManager.get("contactByLabel").interval
  );

  useEffect(() => {
    const filterData = (data) => {
      const numChartColors = COLORS.charts.pie.length;
      setData(
        data.map((item, index) => ({
          ...item,
          color: COLORS.charts.pie[index % numChartColors]
        }))
      );
    };
    const getData = () => {
      const contactTableId = TableManger.getTableId(TABLES.CONTACT);
      const labelColumnId = TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.LABEL
      );
      DashboardService.getRecordCountByColumn({
        tableId: contactTableId,
        columnId: labelColumnId,
        interval: selectedInterval.interval
      }).then((data) => {
        filterData(data.data);
        setShowLoader(false);
        GraphManager.set("contactByLabel", {
          data: data.data,
          interval: selectedInterval
        });
      });
    };
    if (GraphManager.hasData("contactByLabel")) {
      filterData(GraphManager.get("contactByLabel").data);
      setTimeout(getData, 100);
    } else {
      setShowLoader(true);
      getData();
    }
  }, [selectedInterval]);
  return (
    <div
      className="column align-items-center parent-size"
      style={{
        height: 302
      }}
    >
      <div className="row align-items-center justify-content-between mb-xxl">
        <DKLabel text={"Contact by Label"} className="fw-m fs-m" />
        {selectedInterval && (
          <div className="column">
            <DKDropdown
              title={selectedInterval.name}
              data={CHART_INTERVALS.map((interval) => interval.name)}
              onSelect={(index) => {
                GraphManager.clear("contactByLabel");
                setSelectedInterval(Object.assign({}, CHART_INTERVALS[index]));
              }}
            />
          </div>
        )}
      </div>
      {!showLoader && data.length > 0 && (
        <PieChart
          dataSet={data}
          chartConfig={{
            arcWidth: 0.7,
            size: 180
          }}
          showLegend={true}
        />
      )}
      {showLoader && getDashboardWidgetLoading()}
    </div>
  );
}
