import { DKLabel, TOAST_TYPE, Toggle, showAlert } from "deskera-ui-library";
import { useEffect, useState } from "react";
import { TenantInfoSeqCodeStatus } from "../../constants/Constant";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { updateTenantSettings } from "../../services/tenant";
import Utility from "../../utility/Utility";

export interface ISequenceCodeSettings {
  // onClose: () => void;
}

export default function SequenceCodeSettings(props: ISequenceCodeSettings) {
  const dispatch = useAppDispatch();
  const crmSettings = useAppSelector(selectCRMSettings());
  const [seqCodeToggle, setSeqCodeToggle] = useState(false);

  const saveSequenceCodeSettings = async () => {
    try {
      const payload = {
        contactSeqCodeStatus: TenantInfoSeqCodeStatus.ACTIVE
      };
      const response = await updateTenantSettings(payload);
      if (!Utility.isEmptyObject(response)) {
        dispatch(fetchCRMSettings({}));
        // props?.onClose?.();
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  useEffect(() => {
    setSeqCodeToggle(
      crmSettings?.contactSeqCodeStatus === TenantInfoSeqCodeStatus.ACTIVE
    );
  }, []);

  return (
    <div className="column parent-width border-m border-radius-m position-relative p-r mt-l">
      <div className="column parent-width">
        <div className="row parent-width justify-content-between">
          <div className="column">
            <DKLabel
              text={`Enable Contact Sequence Code`}
              className="mr-m mb-xs fw-m"
            />
            <DKLabel
              className="text-gray text-ellipsis"
              text="You cannot disable sequence code once it is enabled"
            />
          </div>
          <div className="ml-l">

          <Toggle
            isOn={seqCodeToggle}
            onChange={() => {
              if (!seqCodeToggle) {
                showAlert(
                  "Warning!",
                  "After enabling this setting, you will not be able to disable it.",
                  [
                    {
                      title: "Cancel",
                      className: "bg-white border-m mr-s",
                      onClick: () => {}
                    },
                    {
                      title: "Proceed",
                      className: "bg-button text-white",
                      onClick: () => {
                        setSeqCodeToggle(true);
                        saveSequenceCodeSettings();
                      }
                    }
                  ]
                );
              } else {
                showAlert("", "This setting cannot be disabled.");
              }
            }}
            className=""
            color="bg-green"
            disabled={seqCodeToggle} // This will disable the toggle when it's on
          /></div>
        </div>
      </div>
    </div>
  );
}
