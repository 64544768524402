import { roundOff } from "../utility/Math";
import { DateUtil, DATE_FORMATS } from "../utility/Date";
import {
  getAmountFormatted,
  getAmountFormattedToPrint
} from "../constants/Currencies";
import { CURRENCY_PRECISION } from "../constants/Constant";
import { InventoryItem } from "./InventoryItem";

export class PrintDocument {
  CompanyName: string = "";
  Address: string = "";
  Date: string = "";
  Phone: string = "";
  BillToName: string = "";
  BillToAddr: string = "";
  ShipToName: string = "";
  ShipToAddr: string = "";
  ExpiryDate: string = "";
  items: any[] = [];
  SubTotal: any = "";
  Discount: any = "";
  Tax: any = "";
  Total: any = "";
  paymentsTotal: any = "";
  pays: any[] = [];
  creditsTotal: any = "";
  creds: any[] = [];
  outstandingAmt: any = "";
  Memo: string = "";
  DueDate: string = "";
  BeforeTax: string = "";
  TotalBase: string = "";
  orderNo: string = "";
  BillNo: string = "";
  debitTotal: string = "";
  OrderNo: string = "";
  exchangeRate: string = "";
  cgst: string;
  sgst: string;
  igst: string;
  cess: string;

  constructor(props: any, tenant: any) {
    this.CompanyName = tenant.name ? tenant.name : "";
    this.Address = this.setPreferredAddress(tenant.billingAddresses);
    this.Date = props.documentDate
      ? DateUtil.getFormattedDateString(
          props.documentDate,
          DATE_FORMATS["DD-MM-YYYY"]
        )
      : "";
    this.Phone =
      tenant.contacts && tenant.contacts.phone ? tenant.contacts.phone : "";
    this.Memo = props.memo ? props.memo : "";

    // TODO: Replace all default "parse variables and values" once an alternative email service solution is available
    const parseContact = props.contact ? props.contact.name : props.contactName;
    const parseCurrency = props.currency ? props.currency : tenant.currency;
    const parseItems = props.items
      ? props.items.sort((a: any, b: any) => (a["id"] > b["id"] ? 1 : -1))
      : [];

    // TODO: Parse Contact is used here
    this.BillToName = parseContact;
    this.BillToAddr = this.setAddress(props.billTo);
    this.ShipToName = parseContact;
    this.ShipToAddr = this.setAddress(props.shipTo);
    this.ExpiryDate = props.validTillDate
      ? DateUtil.getFormattedDateString(
          props.validTillDate,
          DATE_FORMATS["DD-MM-YYYY"]
        )
      : "";
    this.DueDate = props.validTillDate
      ? DateUtil.getFormattedDateString(
          props.validTillDate,
          DATE_FORMATS["DD-MM-YYYY"]
        )
      : "";

    this.cgst = getAmountFormattedToPrint(parseCurrency, props.cgst);
    this.sgst = getAmountFormattedToPrint(parseCurrency, props.sgst);
    this.igst = getAmountFormattedToPrint(parseCurrency, props.igst);
    this.cess = getAmountFormattedToPrint(parseCurrency, props.cess);
    // TODO: Parse Items is used here
    this.setInventoryItems(parseItems, parseCurrency);
    this.BeforeTax = getAmountFormatted(
      parseCurrency,
      props.totalAmount - props.taxAmount
    );
    this.TotalBase = getAmountFormattedToPrint(
      tenant.currency,
      props.totalAmountInBaseCurrency
    );
    this.orderNo = props.documentCode;
    this.BillNo = props.documentCode;
    // this.debitTotal: string="";
    this.OrderNo =
      props.linkedDocuments && props.linkedDocuments.length > 0
        ? props.linkedDocuments[0].documentCode
        : "";

    // TODO: Parse Currency is used here
    this.SubTotal = getAmountFormattedToPrint(parseCurrency, props.subTotal);
    this.Tax = getAmountFormattedToPrint(parseCurrency, props.taxAmount);
    this.Total = getAmountFormattedToPrint(parseCurrency, props.totalAmount);
    this.outstandingAmt = getAmountFormattedToPrint(
      parseCurrency,
      props.dueAmount
    );

    this.Discount = getAmountFormattedToPrint(parseCurrency, props.discount);
    //this.exchangeRate = props.exchangeRate ? props.exchangeRate+"" : "";
    if (props.currency !== tenant.currency || !props.exchangeRate) {
      this.exchangeRate = `1 ${props.currency} = ${roundOff(1 / props.exchangeRate, CURRENCY_PRECISION)} ${tenant.currency}`;
    } else {
      this.exchangeRate = "";
    }
  }

  setInventoryItems(items: any[], currency: string) {
    items.forEach((element: any) => {
      this.items.push(new InventoryItem(element, currency));
    });
  }

  setAddress(address: any): string {
    if (address) {
      return [
        address.address1,
        address.city,
        address.state,
        address.country,
        address.postalCode
      ]
        .filter(Boolean)
        .join();
    } else {
      return "";
    }
  }

  setPreferredAddress(address: any[]) {
    let addString = "";
    if (address) {
      address.forEach((item) => {
        if (item.preferred) {
          addString = this.setCompanyBillingAddress(item);
        }
      });
    }
    return addString;
  }

  setCompanyBillingAddress(item: any) {
    if (item) {
      return [
        item.address1,
        item.city,
        item.state,
        item.country,
        item.postalCode
      ].join();
    } else {
      return "";
    }
  }

  setTransactions(props: any) {
    if (props.knockoffInfo) {
      this.paymentsTotal = null;
      props.knockoffInfo.forEach((element: any) => {
        const transAction: any = {
          reference: element.documentCode,
          value: getAmountFormattedToPrint(element.currency, element.amount)
        };
        this.pays.push(transAction);
      });
      this.paymentsTotal = getAmountFormattedToPrint(
        props.currency,
        props.knockOffTotal
      );
    }
  }

  setTransactionsForBill(props: any) {
    if (props.knockoffInfo) {
      this.paymentsTotal = null;
      props.knockoffInfo.forEach((element: any) => {
        const transAction = {
          reference: element.documentCode,
          value: getAmountFormattedToPrint(element.currency, element.amount)
        };
        this.pays.push(transAction);
      });
      this.paymentsTotal = getAmountFormattedToPrint(
        props.currency,
        props.knockOffTotal
      );
    }
  }
}
