import { Attachment } from "../../components/common/AttachmentList";
import { triggerDownload } from "../../components/import_export/utility/ExportData";
import ApiConstants from "../../constants/ApiConstants";
import httpClient from "../../http";
import Utility from "../../utility/Utility";

export const downloadFileByRelativePath = (
  relativePath: string,
  fileName = null
) => {
  const params = { file: relativePath };
  httpClient.get(ApiConstants.URL.FILES.DOWNLOAD, { params }).then((data) => {
    triggerDownload(null, fileName, data);
  });
};
/**
 *
 * @param base64FileListString - a string of base64 encode file list
 * @returns - array of strings of decode base64 file list
 */
export const decodeBase64FileList = (
  base64FileListString: string
): Attachment[] => {
  let fileList = [];
  try {
    fileList = Utility.decodeJSON(base64FileListString);
  } catch (error) {
    console.log("Error while decoding file list", error);
  }

  return fileList;
};
export const uploadFileToAWS = async (
  file: File,
  entity = "REPORT_THUMBNAIL"
): Promise<string> => {
  let awsURL = "";
  let formData = new FormData();
  formData.append("file", file);
  formData.append("file-entity", entity);
  try {
    const response: any = await httpClient.post(
      ApiConstants.URL.FILES.UPLOAD,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }
    );
    awsURL = response?.publicUrl || "";
  } catch (error) {
    console.log("Error uploading file", error);
  }
  return Promise.resolve(awsURL);
};

export const openFileBrowser = (
  onFileSelected: (file: File[]) => void,
  inputAttribute: any = {}
) => {
  const fileInput = document.createElement("input");
  fileInput.type = "file";
  Object.keys(inputAttribute).forEach((key) => {
    fileInput.setAttribute(key, inputAttribute[key]);
  });
  fileInput.onchange = (event: any) => {
    const files = event.target.files;
    onFileSelected(files);
  };
  fileInput.click();
};
export const getHumanReadableFileSize = (fileSize: number): string => {
  const fileSizeInMB = fileSize / (1024 * 1024);
  console.log(fileSizeInMB);
  if (fileSizeInMB >= 1) {
    return `${fileSizeInMB.toFixed(0)} MB`;
  } else {
    const fileSizeInKB = fileSize / 1024;
    return `${fileSizeInKB.toFixed(0)} KB`;
  }
};

export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const EXTENSIONS_FILE_ALLOWED_UPLOAD = {
  PDF: ".pdf",
  XLS: ".xls",
  XLSX: ".xlsx",
  CSV: ".csv",
  PNG: ".png",
  JPG: ".jpg",
  JPEG: ".jpeg",
  GIF: ".gif",
  DOC: ".doc",
  DOCX: ".docx",
  PPT: ".ppt",
  PPTX: ".pptx",
  TXT: ".txt"
};
