import { Fragment, useState } from "react";
import { DKButton, INPUT_TYPE, DKListPicker2 } from "deskera-ui-library";
import { TableManger, TABLES } from "../../../managers/TableManger";
import Utility from "../../../utility/Utility";
import { PAGE_ELEMENT, PAGE_TYPE } from "../ComponentList";
import ic_placeholder from "../../../assets/canvas/ic_placeholder.png";
import {
  pageDesignerCustomEvent,
  PAGE_DESIGNER_EVENTS
} from "../../../services/event";

const PlaceholderButton = (props) => {
  const [showPlaceholderFields, setShowPlaceholderFields] = useState(false);
  const placeholderFields = getTableColumnsForEditor();

  function getTableColumnsForEditor() {
    const tableColumnsData = TableManger.getTableFilteredColumns(
      TABLES.CONTACT,
      (col) =>
        col.type !== INPUT_TYPE.SELECT &&
        col.type !== INPUT_TYPE.MULTI_SELECT &&
        col.type !== INPUT_TYPE.DROPDOWN &&
        col.type !== "address",
      true
    );

    return tableColumnsData;
  }

  return props.componentData.elementType === PAGE_ELEMENT.TEXT &&
    props.pageType === PAGE_TYPE.CAMPAIGN ? (
    <Fragment>
      <DKButton
        icon={ic_placeholder}
        className="bg-gray1 border-m border-radius-none"
        onClick={(e) => {
          /* e.preventDefault?.();
          e.stopPropagation?.(); */
          props.onToolInitialized?.();
          setShowPlaceholderFields(true);
        }}
      />
      <div className="z-index-1 position-relative parent-height">
        {showPlaceholderFields && (
          <DKListPicker2
            title="Insert Placeholder"
            data={placeholderFields}
            className="border-m shadow-l position-absolute width-auto"
            style={{ top: 16, right: -100, minWidth: 200 }}
            allowSearch={true}
            displayKey={"name"}
            searchableKey={"name"}
            onSelect={(selectedIndex, selectedObj) => {
              setShowPlaceholderFields(false);
              pageDesignerCustomEvent.dispatch(
                PAGE_DESIGNER_EVENTS.PLACEHOLDER_SELECTED,
                {
                  componentData: props.componentData,
                  placeholderData: {
                    id: !Utility.isEmptyObject(selectedObj.columnCode)
                      ? selectedObj.columnCode
                      : selectedObj.key,
                    name: selectedObj.name
                  }
                }
              );
              // onPlaceholderSelected(selectedObj);
            }}
            onClose={() => setShowPlaceholderFields(false)}
          />
        )}
      </div>
    </Fragment>
  ) : null;
};

export const getInnerPlaceholderHTML = (id: string, name: string) =>
  `<span class="pseudo-element" data-name="${name}"></span><span contenteditable="false">%${id}%</span>`;

export default PlaceholderButton;
