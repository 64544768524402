import { useEffect, useState } from "react";
import {
  DKDataGrid,
  DKIcons,
  INPUT_TYPE,
  DKIcon,
  DKLabel,
  showAlert,
  DKDateRangePicker,
  showToast,
  TOAST_TYPE
} from "deskera-ui-library";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import {
  deletePriceBook,
  exportPriceBook,
  updatePriceBookStatus
} from "../../services/priceBook";
import { COLUMN_CODE, TABLES, TableManger } from "../../managers/TableManger";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchPriceBookList,
  priceBookSelector
} from "../../redux/slices/priceBookSlice";
import Utility, { getCapitalized } from "../../utility/Utility";
import SideBarService from "../../services/sidebar";
import ic_no_data from "../../assets/icons/ic_no_data_3.png";
import { getTenantsDetails } from "../../redux/slices/booksSlice";
import BooksService from "../../services/books";
import {
  DATE_FORMATS,
  DateUtil,
  convertCRMDateFormatToUILibraryFormat
} from "../../utility/Date";
import debounce from "../../utility/Debounce";
import { triggerDownload } from "../import_export/utility/ExportData";
import { setUserPreferences } from "../../redux/slices/userPrefSlice";
import { IColumn } from "../../model/Table";
import { isMobileAppWebView } from "../../utility/GetViewportSize";
import AppManager from "../../managers/AppManager";
import { AddPriceBook } from "./AddPriceBook";
import { fetchCurrencies, getCurrencies } from "../../redux/slices/tenantSlice";
import { fetchPermissionInfo } from "../../redux/slices/reportSlice";
import { PRICE_BOOK_SORT_COL, STATUS_TYPE } from "../../constants/Constant";
import { selectPermissionsByModule } from "../../redux/slices/rolesPermissionSlice";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import UserManager from "../../managers/UserManager";

interface IPriceBookListProps {}
export function PriceBookList(props: IPriceBookListProps) {
  const priceBookList = useAppSelector(priceBookSelector);
  const allCurrencies = useAppSelector(getCurrencies());
  const dispatch = useAppDispatch();
  const [showAddPriceBookPopup, setShowAddPriceBookPopup] = useState(false);
  const [priceBookToEdit, setPriceBookToEdit] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [searchText, setSearchText] = useState("");
  const tenantDetails = useAppSelector(getTenantsDetails);
  const [sortInfo, setSortInfo] = useState({ sortCol: null, sortDir: null });
  const [pageNo, setPageNo] = useState(0);
  const [showHideCalendar, setShowHideCalendar] = useState(false);
  const [filterDates, setFilterDates] = useState({
    ...BooksService.getFinancialStartEndDate(),
    financialStartDate:
      BooksService.getFinancialBeginningDate()?.financialBeginningDate
  });
  const [rows, setRows] = useState([]);

  const freezedColumns = useAppSelector(
    (state) => state.userPref.freezedColumns
  );
  const priceBookFreezedColumns =
    (freezedColumns || {})[TABLES.BOOKS_PRICE_LIST.toUpperCase()] || [];
  const permissions = useAppSelector((state) =>
    selectPermissionsByModule(state, TABLES.BOOKS_PRICE_LIST)
  );

  useEffect(() => {
    RouteManager.setPresenter({ props });
    setIsLoading(true);
    fetchAllCurrencies();
    dispatch(fetchPermissionInfo());
  }, []);

  useEffect(() => {
    setIsLoading(false);
    if (Utility.isEmptyObject(priceBookList.content)) {
      setShowEmptyState(true);
    } else {
      setShowEmptyState(false);
    }
    getUpdatedRows();
  }, [priceBookList]);

  useEffect(() => {
    fetchPriceBooks();
  }, [
    tenantDetails.financialStartDate,
    tenantDetails.financialEndDate,
    filterDates,
    searchText,
    sortInfo,
    pageNo
  ]);

  const fetchAllCurrencies = () => {
    if (Utility.isEmptyObject(allCurrencies)) {
      dispatch(fetchCurrencies());
    }
  };
  const fetchPriceBooks = () => {
    // const filterDates = BooksService.getFinancialStartEndDate();
    let startDateString = DateUtil.getDateStrFromDate(
      new Date(filterDates?.financialStartDate),
      DATE_FORMATS["DD-MM-YYYY"]
    );
    let endDateString = DateUtil.getDateStrFromDate(
      new Date(filterDates?.financialEndDate),
      DATE_FORMATS["DD-MM-YYYY"]
    );
    let query = `isAdvance=true,fromDate=${startDateString},toDate=${endDateString}`;

    if (!permissions[USER_ACTION_TYPES.REC_VIEW]) {
      query =
        query +
        `,${COLUMN_CODE.BOOKS_PRICE_LIST.CREATED_BY}=${UserManager.getUserID()}`;
    }

    dispatch(
      fetchPriceBookList({
        search: searchText || "",
        page: pageNo || 0,
        limit: 20,
        sort: sortInfo?.sortCol,
        sortDir: sortInfo?.sortDir,
        query
      })
    );
  };

  const getActionButtons = (row) => {
    let actionButtons = [];
    if (!!permissions[USER_ACTION_TYPES.REC_UPDATE]) {
      actionButtons.push({
        title: "Edit",
        onClick: ({ rowIndex, rowData }) => onRowEdit(rowIndex, rowData),
        className: "p-0 text-blue fw-m text-underline"
      });
    }
    return actionButtons;
  };

  const getDeleteConfirmation = (rowData) => {
    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Delete",
        className: "bg-red text-white ml-r",
        onClick: () => onRowDelete(rowData)
      }
    ];
    showAlert(
      "Delete Price Book?",
      "Deleting this price book will delete it permanently you will not be able to restore it.",
      buttons
    );
  };

  const getRowContextMenu = (row) => {
    let contextMenu = [];
    !!permissions[USER_ACTION_TYPES.REC_UPDATE] &&
      contextMenu.push({
        title: "Edit",
        icon: DKIcons.ic_edit,
        onClick: ({ rowIndex, rowData }) => onRowEdit(rowIndex, rowData),
        className: "p-0"
      });
    !!permissions[USER_ACTION_TYPES.REC_DELETE] &&
      contextMenu.push({
        title: "Delete",
        icon: DKIcons.ic_delete,
        onClick: ({ rowIndex, rowData }) => getDeleteConfirmation(rowData),
        className: "p-0"
      });
    return contextMenu;
  };

  const getActionButtonsColumn = () => {
    return {
      key: "actions",
      name: "Actions",
      type: INPUT_TYPE.BUTTON,
      width: 110,
      options: []
    };
  };

  const getUpdatedColumns = () => {
    let updatedColumns = [...TableManger.getTableColumns("books_price_list")];
    updatedColumns = updatedColumns.map((column) => {
      if (column.columnCode === COLUMN_CODE.BOOKS_PRICE_LIST.NAME) {
        column.name = "Price Book Name";
      }
      if (column.columnCode === "type" || column.columnCode === "currency")
        column.editable = false;
      if (column.columnCode === "Date") {
        column.renderer = (obj) => {
          return (
            <div>
              {DateUtil.getFormattedDateString(
                obj?.value,
                DATE_FORMATS.DD_MM_YYYY,
                DateUtil.getOrgDateFormat()
              )}
            </div>
          );
        };
      }
      return {
        ...column,
        key: column.columnCode,
        freezed: priceBookFreezedColumns.includes(column.id)
      };
    });
    return [...updatedColumns, getActionButtonsColumn()];
  };

  const getUpdatedRows = () => {
    let updatedRows = [...(priceBookList?.content || [])];
    updatedRows = updatedRows.map((row: any) => ({
      Date: row.effectiveDate,
      currency: row.currencies || [],
      id: row.id,
      name: row.name,
      rowButtons: getActionButtons(row),
      rowContextMenu: getRowContextMenu(row),
      selected: false,
      status: row.active ? [STATUS_TYPE.ACTIVE] : [STATUS_TYPE.INACTIVE],
      type: getCapitalized(row.type.toLowerCase()),
      isDefault: row.isDefault
    }));
    setRows(updatedRows);
  };
  const getDateRangeString = () => {
    if (!Utility.isEmptyObject(filterDates)) {
      return (
        DateUtil.getDateStrFromDate(
          filterDates.financialStartDate,
          DateUtil.getOrgDateFormat()
        ) +
        " to " +
        DateUtil.getDateStrFromDate(
          filterDates.financialEndDate,
          DateUtil.getOrgDateFormat()
        )
      );
    } else {
      return "";
    }
  };
  const importPriceBookList = () => {
    RouteManager.navigateToPage(
      PAGE_ROUTES.IMPORT_PORTAL,
      `module=${"books_price_list"}&origin=${PAGE_ROUTES.PRICE_BOOK}`
    );
  };
  const exportPriceBookList = () => {
    try {
      if (Utility.isEmptyObject(priceBookList?.content)) {
        showToast("No records to export!!", TOAST_TYPE.FAILURE);
        return;
      }

      exportPriceBook({
        fromDate: DateUtil.getDateStrFromDate(filterDates.financialStartDate),
        toDate: DateUtil.getDateStrFromDate(filterDates.financialEndDate),
        format: "XLS",
        dateFormat: DateUtil.getOrgDateFormat() || DATE_FORMATS["DD-MM-YYYY"],
        search: searchText || "",
        isAdvance: true
      })
        .then(
          (response: any) => {
            const fileName = `PriceBook.xlsx`;
            triggerDownload(response, fileName);
          },
          (error: any) => {
            return Promise.reject(error);
          }
        )
        .catch((error: any) => {
          return Promise.reject(error);
        });
    } catch (err) {
      console.error("Error exporting PriceList: ", err);
    }
  };

  const getDocumentGridHeaderButtons = () => {
    let buttons = [];
    !!permissions[USER_ACTION_TYPES.REC_EXPORT] &&
      buttons.push({
        className: "border-m bg-white ml-r position-relative",
        icon: DKIcons.ic_export,
        title: "Export",
        onClick: () => exportPriceBookList()
      });
    !!permissions[USER_ACTION_TYPES.REC_IMPORT] &&
      buttons.push({
        className: "border-m bg-white ml-r position-relative",
        icon: DKIcons.ic_import,
        title: "Import",
        onClick: () => importPriceBookList()
      });
    buttons.push({
      title: getDateRangeString(),
      className: "border-m bg-white position-relative",
      icon: DKIcons.ic_calendar,
      onClick: () => setShowHideCalendar(true)
    });
    if (!!permissions[USER_ACTION_TYPES.REC_CREATE]) {
      buttons.splice(0, 0, {
        title: `+ Add Price Book`,
        className: "bg-button text-white ml-r",
        onClick: () => showPriceBookPopup()
      });
    }
    return buttons;
  };

  const showPriceBookPopup = () => {
    setPriceBookToEdit({});
    setShowAddPriceBookPopup(true);
  };

  const hidePriceBookPopup = () => {
    setShowAddPriceBookPopup(false);
    setPriceBookToEdit({});
  };

  const onRowEdit = (rowIndex, rowData) => {
    setPriceBookToEdit(priceBookList?.content?.[rowIndex]);
    setShowAddPriceBookPopup(true);
  };

  const onRowDelete = (rowData) => {
    deletePriceBook(rowData?.id).then((response) => {
      if (pageNo > 0 && priceBookList.content?.length === 1) {
        setPageNo(pageNo - 1);
      } else {
        fetchPriceBooks();
      }
    });
  };

  const onPriceBookSave = () => {
    hidePriceBookPopup();
    fetchPriceBooks();
  };

  const updateStatus = (rowData) => {
    let statusId = rowData.status?.[0] === STATUS_TYPE.ACTIVE ? 1 : 0;
    if (rowData?.isDefault) {
      showAlert("Warning", `Default price book can not be marked as Inactive`);
    } else {
      updatePriceBookStatus(rowData?.id, statusId).then((response) => {
        fetchPriceBooks();
      });
    }
  };
  const onRowUpdate = (data) => {
    const { columnKey, rowData, rowIndex } = data;
    switch (columnKey) {
      case "status":
        if (rowData?.isDefault) {
          showAlert(
            "Warning",
            `Default price book can not be marked as Inactive`
          );
          let rowsToUpdate = [...rows];
          rowsToUpdate[rowIndex].status = [STATUS_TYPE.ACTIVE];
          setRows(rowsToUpdate);
        } else {
          updateStatus(rowData);
        }
        break;

      default:
        break;
    }
  };

  const onSearch = debounce((searchText) => {
    setSearchText(searchText);
    setPageNo(0);
  }, 500);

  const onPageChange = (page: number) => {
    setPageNo(page - 1);
  };

  const onSort = ({ order, columnData }) => {
    let sortCol = columnData.columnCode || columnData.id;
    switch (columnData.columnCode) {
      case COLUMN_CODE.BOOKS_PRICE_LIST.EFFECTIVE_DATE:
        sortCol = PRICE_BOOK_SORT_COL.EFFECTIVE_DATE;
        break;
      case COLUMN_CODE.BOOKS_PRICE_LIST.CURRENCY:
        sortCol = PRICE_BOOK_SORT_COL.CURRENCIES;
        break;
      case COLUMN_CODE.BOOKS_PRICE_LIST.STATUS:
        sortCol = PRICE_BOOK_SORT_COL.ACTIVE;
        break;
    }

    const sortDir = order;
    setSortInfo({ sortCol, sortDir });
  };

  const onColumnFreezeToggle = (data: {
    column: IColumn;
    freezed: boolean;
  }) => {
    if (!data?.column?.id) return;
    const moduleName = TABLES.BOOKS_PRICE_LIST.toUpperCase();

    let freezedColumnsByModule = priceBookFreezedColumns || [];

    if (data.freezed) {
      freezedColumnsByModule = freezedColumnsByModule.concat(data.column.id);
    } else {
      freezedColumnsByModule = freezedColumnsByModule.filter(
        (columnId) => columnId !== data.column.id
      );
    }

    dispatch(
      setUserPreferences({
        freezedColumns: {
          ...freezedColumns,
          [moduleName]: freezedColumnsByModule
        }
      })
    );
  };

  const getDateRangePicker = () => {
    return (
      <div className="position-absolute shadow-m bg-white z-10 top-12 right-20">
        <DKDateRangePicker
          className="border shadow "
          onClose={() => {
            setShowHideCalendar(false);
          }}
          color={"rgb(101,24,102)"}
          showPresetList={true}
          startDate={filterDates.financialStartDate}
          selectedStartDate={filterDates.financialStartDate}
          selectedEndDate={filterDates.financialEndDate}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            if (startDate && endDate) {
              setFilterDates({
                financialStartDate: startDate,
                financialEndDate: endDate
              });
              setShowHideCalendar(false);
            }
          }}
        />
      </div>
    );
  };

  const getNoDataView = () => {
    return (
      <div
        className="column align-self-center align-items-center position-absolute"
        style={{ top: "30%", pointerEvents: "none" }}
      >
        <DKIcon
          src={ic_no_data}
          className="ic-l"
          style={{ opacity: 0.2, marginTop: 70 }}
        />
        <DKLabel text="No data found" className="fw-m mt-l" />
        <DKLabel
          text={
            !permissions[USER_ACTION_TYPES.REC_VIEW] &&
            Utility.isEmptyObject(searchText)
              ? "Looks like you don't have permission to view records."
              : `Once data is available, it will appear here`
          }
          className="text-gray mt-s "
        />
      </div>
    );
  };

  return (
    <div className="column mt-s parent-width mb-s position-relative flex-1">
      {showAddPriceBookPopup && (
        <AddPriceBook
          priceBookToEdit={priceBookToEdit}
          onSave={() => onPriceBookSave()}
          onCancel={() => onPriceBookSave()}
        />
      )}
      <div className="parent-width row position-relative">
        <div
          className="position-absolute z-index-1 mobile-left-0"
          style={{ right: 630, top: 45 }}
        >
          {showHideCalendar && getDateRangePicker()}
        </div>
      </div>
      <DKDataGrid
        title={"Price Book"}
        width={SideBarService.getContainerWidth()}
        allowRowEdit={!!permissions[USER_ACTION_TYPES.REC_UPDATE]}
        updating={isLoading}
        gridStickyHeaderConfig={
          isMobileAppWebView()
            ? null
            : {
                getRootScrollContainer: () => AppManager.getScrollContainerDiv()
              }
        }
        allowBulkOperation={false}
        allowSearch={true}
        onRowUpdate={onRowUpdate}
        onSearch={onSearch}
        onRowOpenClick={({ rowIndex, rowData }) => onRowEdit(rowIndex, rowData)}
        columns={getUpdatedColumns() || []}
        rows={rows}
        buttons={getDocumentGridHeaderButtons()}
        allowColumnSort={true}
        currentPage={priceBookList?.pageable?.pageNumber + 1}
        totalPageCount={priceBookList?.totalPages}
        onPageChange={onPageChange}
        onSort={onSort}
        dateFormat={convertCRMDateFormatToUILibraryFormat(
          DateUtil.getOrgDateFormat()
        )}
        allowColumnFreeze={true}
        onColumnFreezeToggle={onColumnFreezeToggle}
      />
      {showEmptyState && getNoDataView()}
    </div>
  );
}

export default PriceBookList;
