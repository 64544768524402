import {
  DKInput,
  DKLabel,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import { TABLES } from "../../managers/TableManger";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { updateTenantSettings } from "../../services/tenant";
import Utility from "../../utility/Utility";
import { isViewportMobile } from "../../utility/GetViewportSize";
export function PriceBookModuleSelectionPopup(props) {
  const globalCrmSettings = useAppSelector(selectCRMSettings());
  const dispatch = useAppDispatch();

  const moduleList = [
    { id: 1, module: TABLES.CONTACT, title: "Contact" },
    { id: 2, module: TABLES.ACCOUNT, title: "Account" }
  ];

  const getDefaultModule = (settings, moduleList) => {
    let defaultModule = moduleList[0];
    if (!Utility.isEmptyObject(settings?.priceBookFilterOnObjectType)) {
      defaultModule = moduleList.find(
        (item) =>
          item.module.toUpperCase() === settings?.priceBookFilterOnObjectType
      );
    }
    return defaultModule;
  };

  const [selectedModule, setSelectedModule] = useState(
    getDefaultModule(globalCrmSettings, moduleList)
  );

  useEffect(() => {
    savePriceBookModuleSettings();
  }, [selectedModule]);

  const savePriceBookModuleSettings = async () => {
    try {
      const payload = {
        priceBookFilterOnObjectType: selectedModule?.module.toUpperCase()
      };
      const response = await updateTenantSettings(payload);
      if (!Utility.isEmptyObject(response)) {
        dispatch(fetchCRMSettings({}));
        props?.onClose?.();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const mobileStyle = { minWidth: "100%" };
  const desktopStyle = { minWidth: 200 };

  const inputStyle = isViewportMobile() ? mobileStyle : desktopStyle;
  return (
    <div className={`column parent-width border-m border-radius-m p-r mt-l`}>
      <div
        className={`row justify-content-between ${isViewportMobile() ? "row-responsive" : ""} `}
      >
        <div className="column">
          <DKLabel
            text={`Price Book Module Selection`}
            className="mr-m fw-m pb-xs"
          />
          <DKLabel
            className="text-gray text-ellipsis"
            text="Select module to define price book criteria at the time of price book creation"
          />
        </div>

        <div
          className={` ${!isViewportMobile() ? "ml-l" : ""} column mt-m `}
          style={inputStyle}
        >
          <DKInput
            // style={{ width: 200 }}
            value={selectedModule}
            formatter={(obj) => `${obj.title}`}
            type={INPUT_TYPE.DROPDOWN}
            required={false}
            readOnly={false}
            direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
            dropdownConfig={{
              allowSearch: false,
              data: moduleList,
              renderer: (index: number, obj) => {
                return (
                  <div className="row justify-content-between p-v-xs">{`${obj.title}`}</div>
                );
              }
            }}
            onChange={(data) => {
              setSelectedModule(data);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default PriceBookModuleSelectionPopup;
