import { DKInput, DKLabel } from "deskera-ui-library";
import { useState, useEffect, useCallback } from "react";
import { DATE_FORMAT_LIST } from "../../constants/Constant";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { updateTenantSettings } from "../../services/tenant";
import { DateUtil } from "../../utility/Date";
import Utility from "../../utility/Utility";
import { isViewportMobile } from "../../utility/GetViewportSize";
import { IMPORT_DEFAULT_DATE_FORMAT } from "../import_export/EximConstants";
import { DATE_FORMATS } from "../../utility/Date";
export default function DateFormatSetting() {
  const crmSettings = useAppSelector(selectCRMSettings());
  const dispatch = useAppDispatch();
  const [defaultFormat, setDefaultFormat] = useState("");

  const mobileStyle = { minWidth: "100%" };
  const desktopStyle = { minWidth: 200 };

  const inputStyle = isViewportMobile() ? mobileStyle : desktopStyle;

  const saveDateFormat = useCallback(
    async (dateFormatString) => {
      try {
        const payload = {
          dateFormat: dateFormatString
        };
        const response = await updateTenantSettings(payload);
        if (!Utility.isEmptyObject(response)) {
          dispatch(fetchCRMSettings({}));
        }
      } catch (error) {
        console.error("Error saving date format:", error);
      }
    },
    [dispatch]
  );

  const setCurrentDateFormat = useCallback(
    (dateFormatString) => {
      if (dateFormatString) {
        const dateFormatObject = DATE_FORMAT_LIST.find(
          (format) => format.format === dateFormatString
        );
        if (dateFormatObject) {
          DateUtil.setOrgDateFormat(dateFormatObject.format);
          setDefaultFormat(dateFormatObject.format);
          saveDateFormat(dateFormatObject.format);
        }
      }
    },
    [saveDateFormat]
  );

  useEffect(() => {
    const currentFormat = !Utility.isEmptyObject(DateUtil.getOrgDateFormat())
      ? DateUtil.getOrgDateFormat()
      : DATE_FORMAT_LIST.find(
          (format) => format.format === DATE_FORMATS["DD-MM-YYYY"]
        )?.format;

    setDefaultFormat(currentFormat || "");
  }, [crmSettings]);

  return (
    <div className="column parent-width border-m border-radius-m position-relative p-h-r pt-r mt-l">
      <div className="parent-size">
        <div
          className={`${isViewportMobile() ? "row-responsive" : ""} parent-width justify-content-between row mb-l`}
        >
          <div className="column">
            <DKLabel text="Date Settings" className="mr-m mb-xs fw-m" />
            <DKLabel
              className="text-gray text-ellipsis"
              text="Change your date format here"
            />
          </div>
          <div className="column mt-m" style={inputStyle}>
            <DKInput
              value={defaultFormat}
              style={{ width: 200 }}
              type="dropdown"
              required={true}
              readOnly={false}
              direction="vertical"
              dropdownConfig={{
                allowSearch: false,
                data: DATE_FORMAT_LIST.map((format) => format.format),
                renderer: (index, dateFormatString) => (
                  <div className="row p-v-xs listPickerBG cursor-hand pl-s">
                    <span
                      className={
                        defaultFormat === dateFormatString ? "fw-b" : ""
                      }
                    >
                      {dateFormatString}
                    </span>
                  </div>
                )
              }}
              onChange={(selectedFormatString) => {
                if (selectedFormatString) {
                  setCurrentDateFormat(selectedFormatString);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
