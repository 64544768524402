import { TABLES } from "../managers/TableManger";
import EventTracker from "./EventTracker";
import { EVENT_TRACKING_TYPE } from "./utility/EventConstants";

export default class EventManager {
  static trackEventByModule = (module, event, data) => {
    try {
      switch (module) {
        case TABLES.ACCOUNT:
          EventTracker.trackEvent(EVENT_TRACKING_TYPE.ACCOUNT[event], data);
          break;
        case TABLES.CONTACT:
          EventTracker.trackEvent(EVENT_TRACKING_TYPE.CONTACT[event], data);
          break;
        case TABLES.DEAL:
          EventTracker.trackEvent(EVENT_TRACKING_TYPE.DEAL[event], data);
          break;
        case TABLES.ACTIVITY:
          EventTracker.trackEvent(EVENT_TRACKING_TYPE.ACTIVITY[event], data);
          break;
        case TABLES.BOOKS_QUOTE:
          EventTracker.trackEvent(EVENT_TRACKING_TYPE.QUOTE[event], data);
          break;
        case TABLES.BOOKS_INVOICE:
          EventTracker.trackEvent(EVENT_TRACKING_TYPE.INVOICE[event], data);
          break;
        case TABLES.CAMPAIGN:
          EventTracker.trackEvent(EVENT_TRACKING_TYPE.CAMPAIGN[event], data);
          break;
        case TABLES.FORM:
          EventTracker.trackEvent(EVENT_TRACKING_TYPE.FORM[event], data);
          break;
        case TABLES.LANDING_PAGE:
          EventTracker.trackEvent(
            EVENT_TRACKING_TYPE.LANDING_PAGE[event],
            data
          );
          break;
        case TABLES.AUTOMATION:
          EventTracker.trackEvent(EVENT_TRACKING_TYPE.AUTOMATION[event], data);
          break;
        case TABLES.CHAT_MESSAGE:
          EventTracker.trackEvent(EVENT_TRACKING_TYPE.CHAT[event], data);
          break;
        case TABLES.TICKET:
          EventTracker.trackEvent(EVENT_TRACKING_TYPE.TICKET[event], data);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log("Error while event tracking by module", error);
    }
  };
}
