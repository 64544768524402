import { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import Popup from "./Popup";
import {
  DKIcon,
  DKIcons,
  DKLabel,
  DKLine,
  DKInput,
  DKButton,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from "deskera-ui-library";
import Table from "../../services/table";
import { TableManger } from "../../managers/TableManger";
import { IColumn } from "../../model/Table";
import Utility from "../../utility/Utility";

export interface IAddColumnPushWebhookProps {
  tableName: string;
  targetColumnId?: string;
  targetColumnName?: string;
  popupId: string;
  onSave?: (response: any) => void;
  onClose?: () => void;
}

const AddColumnPushWebhook = (props: IAddColumnPushWebhookProps) => {
  const formFieldKeys = {
    FILTER_SOURCE: "Filter Column",
    TARGET_FIELD: "Target Column"
  };

  const tableColumns = TableManger.getTableFilteredColumns(
    props.tableName,
    (column: IColumn) =>
      [
        INPUT_TYPE.TEXT,
        INPUT_TYPE.EMAIL,
        INPUT_TYPE.NUMBER,
        INPUT_TYPE.PHONE,
        INPUT_TYPE.LONG_TEXT
      ].includes(column.type),
    true
  );

  const [formData, setFormData] = useState([
    {
      title: formFieldKeys.FILTER_SOURCE,
      id: null,
      value: null,
      placeholder: "Please select a column to match your webhook data..",
      type: INPUT_TYPE.SELECT,
      required: true,
      options: tableColumns.map((field: IColumn) => field.name),
      readOnly: false
    },
    {
      title: formFieldKeys.TARGET_FIELD,
      id: props.targetColumnId,
      value: props.targetColumnName,
      placeholder: "Please select a column to push value..",
      type: props.targetColumnName ? INPUT_TYPE.TEXT : INPUT_TYPE.SELECT,
      required: true,
      options: tableColumns.map((field: IColumn) => field.name),
      readOnly: Boolean(props.targetColumnId && props.targetColumnName)
    }
  ]);

  const [webhookUrl, setWebhookUrl] = useState(null);

  const [isSaveTapped, setIsSaveTapped] = useState(false);

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  const generateWebhook = async () => {
    const payload = { fieldId: formData[0].id, columnId: formData[1].id };

    if (
      Utility.isEmptyObject(payload.fieldId) ||
      Utility.isEmptyObject(payload.columnId)
    ) {
      return Promise.reject("Please select the required fields first");
    }

    const tableId = TableManger.getTableId(props.tableName);

    return Table.generateNewWebhook(payload, tableId).then((response: any) => {
      setWebhookUrl(response);
      return response;
    });
  };

  const saveTapped = () => {
    setIsSaveTapped(true);

    generateWebhook().then((response) => {
      props.onSave && props.onSave(response);
    });
  };

  const removePopUp = () => {
    if (props.popupId)
      ReactDOM.unmountComponentAtNode(document.getElementById(props.popupId));

    document.getElementById(props.popupId)?.remove();

    props.onClose && props.onClose();
  };

  const handleFormValueChange = (changedValue: any, index: number) => {
    const allFields = Utility.makeCopyOfObject(formData);

    const changedField = allFields[index];

    if (changedValue) {
      changedField.value = changedValue;
      changedField.id = tableColumns[changedValue[0]]?.id;
    }

    setFormData(allFields);
  };
  /////////////////////////////////////////////////////////////
  ///////////////////////RENDERERS/////////////////////////////
  /////////////////////////////////////////////////////////////
  function getForm() {
    return (
      <div className="column mt-l">
        <DKInput
          title={formData[1].title}
          placeholder={formData[1].placeholder}
          value={formData[1].value}
          type={formData[1].type}
          options={formData[1].options}
          required={formData[1].required}
          className={`p-v-s`}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          onChange={(value: any) => handleFormValueChange(value, 1)}
          errorMessage={" "}
          canValidate={isSaveTapped}
          readOnly={formData[1].readOnly}
        />
        <DKInput
          title={formData[0].title}
          placeholder={formData[0].placeholder}
          value={formData[0].value}
          type={formData[0].type}
          options={formData[0].options}
          required={formData[0].required}
          className={"p-v-s"}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          errorMessage={" "}
          canValidate={isSaveTapped}
          onChange={(value: any) => handleFormValueChange(value, 0)}
        />
        <div className="row mt-l">
          <DKButton
            title={"Generate"}
            className="bg-button border-app text-white"
            onClick={saveTapped}
          />
          <DKButton
            title={"Cancel"}
            className="bg-gray1 border-m ml-r"
            onClick={removePopUp}
          />
        </div>
      </div>
    );
  }

  const getUrlSection = () => {
    return (
      <div className="column">
        <DKLine className="m-v-l" />
        <DKLabel text={"Webhook URL"} />
        <div className="row mt-s justify-content-between">
          <DKLabel
            text={
              webhookUrl || "Please generate a webhook url using above options."
            }
            className="width-90 bg-gray1 mr-s border-m border-radius-s text-trailing p-v-xs p-h-s text-gray border-box"
            style={{
              height: 30,
              whiteSpace: "nowrap"
            }}
          />
          <DKIcon
            src={DKIcons.ic_copy}
            style={{
              cursor: webhookUrl ? "pointer" : "not-allowed"
            }}
            onClick={() => {
              if (webhookUrl) {
                Utility.copyToClipBoard(webhookUrl);
              }
            }}
          />
        </div>
        {webhookUrl ? (
          <Fragment>
            <DKLabel text="Sample POST request data" className="mt-l" />
            <DKLabel
              className="m-v-s bg-gray1 parent-width border-m border-radius-s text-gray p-v-xs p-h-s border-box"
              text={`{\n\tmatchValue: &lt${
                tableColumns[formData[0].value[0]]?.name
              }&gt,\n\tcontent: &lt${
                props.targetColumnName ||
                tableColumns[formData[1].value[0]]?.name
              }&gt\n}`}
            />
          </Fragment>
        ) : null}
      </div>
    );
  };

  return (
    <Popup title="Create Webhook" allowClose={true} onClose={removePopUp}>
      {webhookUrl ? getUrlSection() : getForm()}
    </Popup>
  );
};

export const showPushWebhookPopup = (
  config: {
    tableName: string;
    targetColumnId?: string;
    targetColumnName?: string;
  },
  onSave?: (response: any) => void,
  onClose?: () => void
) => {
  const id = `data-source-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "column-webhook-popup app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <AddColumnPushWebhook
      {...config}
      popupId={id}
      onSave={onSave}
      onClose={onClose}
    />,
    document.body.appendChild(div)
  );
};

export default AddColumnPushWebhook;
