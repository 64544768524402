import { store } from "../redux/store";

export default interface IUser {
  createdAt: string;
  _id: string;
  id?: string;
  userId: number;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  iamUserId: number;
  roleGroup: string;
  userName: string;
  status: string;
  crm3RoleId?: string;
  teamDesignation?: string;
}
export const getFullName = (user: IUser) =>
  `${user?.firstName || "..."} ${user?.lastName || ""}`;
export const getUserByUserId = (userId: number) =>
  store
    .getState()
    .tenant.users?.find((user) => user.userId === userId);
export const getUserByIamId = (iamUserId: number) =>
  store
    .getState()
    .tenant.users.find(
      (user) => user.iamUserId?.toString() === iamUserId?.toString()
    );

export interface ISalesRepresentativePayload {
  _id?: string;
  iamUserId: number;
  displayName: string;
  email?: string;
  meetingLink: string;
  profilePic: string;
  phone: string;
  active: boolean;
}

export interface ISalesRepresentativeListResponse {
  totalCount: number;
  pageNo: number;
  pageSize: number;
  sortBy: string;
  sortDir: number;
  data: ISalesRepresentativePayload[];
}

export interface ISalesRepresentativeDisplay
  extends ISalesRepresentativePayload {
  email: string;
}

/* book a demo models */
export interface IMeetSlot {
  title: string;
  format: string;
  startDate?: string;
  endDate?: string;
}

export interface IMeetMember {
  id?: string;
  name: string;
  email: string;
  phone: string;
  organization?: string;
  profilePic?: string;
}

export interface IMeetHost extends IMeetMember {
  userId: number;
  meetLink: string;
}
