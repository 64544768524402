import React, { useEffect } from "react";
import "./stack_hero/stackStyles.css";

import { DKLabel, DKIcon } from "deskera-ui-library";
import Slider from "react-slick";
import DKLabel2 from "./DKLabel2";

export const insertStyleSheet = (linkSrc, charset) => {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = linkSrc;
  if (charset) {
    link.charset = charset;
  }

  document.head.appendChild(link);
};

/**
 * PROPS
 *
 * title: string;
 * icons: string[];
 */
const Showcase = ({ title, icons }) => {
  const slickSettings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1800,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    pauseOnHover: false,
    cssEase: "ease-in"
  };

  useEffect(() => {
    insertStyleSheet(
      "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css",
      "UTF-8"
    );
    insertStyleSheet(
      "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
    );
  }, []);

  return (
    <div class="showcase column align-items-center flex-1">
      <DKLabel2
        className="fw-b text-align-center mb-xxl showcase-title"
        style={{ letterSpacing: ".05em" }}
        text={title}
      />
      <div
        className="showcase showcase-slider padding-2"
        style={{ overflow: "hidden" }}
      >
        <Slider {...slickSettings}>
          {icons?.map((icon) => (
            <DKIcon src={icon} className={"ic-huge"} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Showcase;
