import ApiConstants from "../constants/ApiConstants";
import httpClient from "../http";
import { IQuotationItem } from "../model/Quotation";
import Utility, { getRandomHexString } from "../utility/Utility";
export const getProductGroups = (params = {}) => {
  return httpClient.get(`${ApiConstants.URL.PRODUCT_GROUP.GET_ALL}`, {
    params
  });
};
export const createProductGroup = (payload) => {
  return httpClient.post(`${ApiConstants.URL.PRODUCT_GROUP.CREATE}`, payload);
};
export const updateProductGroup = (payload, id) => {
  return httpClient.put(
    `${ApiConstants.URL.PRODUCT_GROUP.UPDATE(id)}`,
    payload
  );
};
export const deleteProductGroup = (id) => {
  return httpClient.delete(`${ApiConstants.URL.PRODUCT_GROUP.UPDATE(id)}`);
};
export const getUniqueGroupsFromQuoteItems = (
  quoteItems: IQuotationItem[],
  productGroups: any[]
) => {
  let groups = [];
  let groupNames = [];
  let uniqueIds = [];
  quoteItems.forEach((item) => {
    if (item?.productGroupId == null || item?.productGroupId == undefined) {
      item.productGroupId = 0;
    }
    if (item?.productGroupName == null || item?.productGroupName == undefined) {
      item.productGroupName = "";
    }
    if (item?.productGroupUuid == null || item?.productGroupUuid == undefined) {
      item.productGroupUuid = "";
    }

    if (!uniqueIds.includes(item?.productGroupUuid)) {
      const productGroupId = item?.productGroupId ?? 0;
      const group = productGroups?.find(
        (group) => group.productGroupId == productGroupId
      );
      const productGroupName = !Utility.isEmptyObject(item?.productGroupName)
        ? item.productGroupName
        : group?.productGroupName ?? "";
      const productGroupUuid = !Utility.isEmptyObject(item?.productGroupUuid)
        ? item.productGroupUuid
        : group?.productGroupUuid ?? "";
      groups.push({
        productGroupName,
        productGroupId,
        productGroupUuid,
        productGroupIndex: item?.productGroupIndex ?? 0
      });
      uniqueIds.push(productGroupUuid);
    }
  });
  return groups?.sort(
    (group1, group2) => group1.productGroupIndex - group2.productGroupIndex
  );
};
export const filterQuotesItemsByProductGroupId = (
  productGroupUuid: any,
  quoteItems: IQuotationItem[]
) => {
  return quoteItems?.filter(
    (item) =>
      item.productGroupUuid?.toLowerCase() === productGroupUuid?.toLowerCase()
  );
};
export const checkDuplicateGroupName = (payload) => {
  return httpClient.post(
    `${ApiConstants.URL.PRODUCT_GROUP.DUPLICATE_GROUP}`,
    payload
  );
};
export function checkQuoteGroupAsFavorites(payload) {
  return httpClient.post(
    `${ApiConstants.URL.PRODUCT_GROUP.CHECK_FAVORITES_GROUP_EXISTS}`,
    payload
  );
}
