import { getRandomAlphaNumericString } from "../../../utility/Utility";
import { PAGE_ELEMENT } from "../../page_designer/ComponentList";
import ComponentManager from "../../page_designer/ComponentManager";

export default class Template9 {
  static getData() {
    return {
      metaData: null,
      scripts: null,
      hash: getRandomAlphaNumericString(),
      designElements: [
        {
          type: "Text",
          value: "Deskera",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 24,
            color: "#be1507",
            textAlign: "left",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 16,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "flex-start",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625743397936_LandingPage-9-Image1.png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 16,
            marginBottom: 30,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "BID & SEIZE DEAL",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 36,
            color: "#000000",
            textAlign: "left",
            fontWeight: "bold",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "flex-start",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
          style: {
            width: "76%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#000000",
            textAlign: "left",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 12,
            paddingRight: 12,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "flex-start",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value: "Select Category",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 24,
            color: "#000000",
            textAlign: "left",
            fontWeight: "600",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 16,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          style: {
            width: "66%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#020101",
            textAlign: "left",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#ffffff",
            overflow: "hidden",
            alignSelf: "flex-start",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Collection",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Luxury",
                fontSize: 18,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625743703654_LandingPage-9-Image2.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Classic",
                fontSize: 18,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625743707550_LandingPage-9-Image3.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "W Class",
                fontSize: 18,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625743713447_LandingPage-9-Image4.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 2,
            marginBottom: 2,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 200,
            cellSpacing: 11,
            iconRadius: 0,
            titleColor: "#000000",
            titleSize: 18,
            descriptionColor: "#837c7c",
            descriptionSize: 14
          }
        },
        {
          type: "Button",
          value: "Get Started",
          url: "",
          style: {
            width: "30%",
            boxSizing: "border-box",
            fontSize: 14,
            color: "#000000",
            textAlign: "center",
            fontWeight: "600",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 15,
            paddingRight: 15,
            marginTop: 10,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 32,
            backgroundColor: "#d3d3d3",
            alignSelf: "center",
            overflow: "hidden",
            cursor: "pointer",
            outline: "none",
            border: "none",
            textDecoration: "none"
          }
        },
        {
          type: "Text",
          value: "Untouched Masterpieces",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 24,
            color: "#000000",
            textAlign: "center",
            fontWeight: "600",
            paddingTop: -2,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 16,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit,\n sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#000000",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 12,
            paddingRight: 12,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625744050375_LandingPage-9-Image5.png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 26,
            marginBottom: 26,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "Digitaly Precise",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 24,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit,\n sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#000000",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 12,
            paddingRight: 12,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625744110269_LandingPage-9-Image6.png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 26,
            marginBottom: 26,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "Testimonials",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 20,
            color: "#000000",
            textAlign: "center",
            fontWeight: "600",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Collection",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Kurt Predovic",
                fontSize: 14,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Great service, great quality would recommend with nothing more, nothing less. Thank you BinQasem and will use again in the future for sure.",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625744466373_LandingPage-9-Image7.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Santos Walker",
                fontSize: 14,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Great service, great quality would recommend with nothing more, nothing less. Thank you BinQasem and will use again in the future for sure.",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625744469837_LandingPage-9-Image8.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Jared Weissnat",
                fontSize: 14,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Great service, great quality would recommend with nothing more, nothing less. Thank you BinQasem and will use again in the future for sure.",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625744472349_LandingPage-9-Image9.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 4,
            marginBottom: 4,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 106,
            cellSpacing: 41,
            iconRadius: 0,
            titleColor: "#000000",
            titleSize: 14,
            descriptionColor: "#837c7c",
            descriptionSize: 14,
            gridSize: 23
          }
        },
        {
          type: "Text",
          value: "© 2021, All Rights Reserved | Unsubscribe",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#ffffff",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#000000",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        }
      ]
    };
  }
}
