import React, { Component } from "react";

import { DKLabel, DKIcons, DKIcon } from "deskera-ui-library";

import ic_btn_ios from "../../../assets/mobile/ic_app_store.svg";
import ic_btn_android from "../../../assets/mobile/ic_google_play.svg";
import ic_mobile from "../../../assets/mobile/lp-aio-mobile-screenshot-new.png";
import ic_award from "../../../assets/mobile/ic_ratings.png";
import ic_qrcode from "../../../assets/mobile/ic_qrcode.png";
import ApiConstants from "../../../constants/ApiConstants";

/*
PROPS
- onClose
*/

class MobileDownloadPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="transparent-background">
        <div
          className="popup-window align-items-center text-align-center position-relative "
          style={{ width: 500, maxWidth: "90%" }}
        >
          <DKIcon
            src={DKIcons.ic_close_}
            className="ic-xs position-absolute cursor-hand"
            style={{ top: 15, right: 15 }}
            onClick={() => {
              this.props.onClose();
            }}
          />
          <DKLabel
            text="Your Business. Anywhere."
            className="fw-b fs-xl mt-m"
          />
          <DKLabel
            text="Whether on the go or working remotely, your business is with you.<br/>Deskera’s Webby award winning mobile app keeps you connected to your business at all times."
            className="mt-s text-align-center"
          />
          {/* <DKLabel
            text="All the features and reports you love, available on the go, all the
          time."
            className="fw-m fs-l mt-xl text-align-center"
          /> */}
          <img
            src={ic_mobile}
            alt=""
            className="mt-xl"
            style={{ width: "80%" }}
          />
          {/* <img
            src={ic_qrcode}
            alt=""
            className="mt-m"
            style={{ width: "25%" }}
          /> */}
          <img
            src={ic_award}
            alt=""
            className="mt-l"
            style={{ width: "45%" }}
          />
          <div
            className="row row-responsive mt-l justify-content-center mb-xl"
            style={{ gap: 15 }}
          >
            <img
              src={ic_btn_ios}
              alt=""
              className="cursor-hand "
              style={{ height: 40 }}
              onClick={() => {
                window.open(ApiConstants.URL_MOBILE_IOS, "_blank");
              }}
            />
            <img
              src={ic_btn_android}
              alt=""
              className="cursor-hand"
              style={{ height: 40 }}
              onClick={() => {
                window.open(ApiConstants.URL_MOBILE_ANDROID, "_blank");
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MobileDownloadPopup;
