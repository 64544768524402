import { getRandomAlphaNumericString } from "../../../utility/Utility";

export default class Template1 {
  static getData() {
    return {
      metaData: null,
      scripts: null,
      hash: getRandomAlphaNumericString(),
      designElements: [
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625723005246_deskera-logo-dark.png",
          style: {
            width: "30%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 30,
            marginBottom: 10,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "flex-start",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625723010891_Newsletter-1-Image1.png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 24,
            marginBottom: 28,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value:
            "Greetings Molly, welcome to <b>Deskera.</b> We’re very excited you’re here.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 36,
            color: "#000000",
            textAlign: "left",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Text",
          value:
            "We seriously couldn’t happier that you’re interested in what believe in.\n\nWe can’t wait to see you create, and I’m sure you’re going to make someone smile like crazy!",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 22,
            color: "#000000",
            textAlign: "left",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 10,
            marginBottom: 16,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Text",
          value: "© 2021, All Rights Reserved",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 10,
            color: "#a5a5a5",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 24,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#181818",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        }
      ]
    };
  }
}
