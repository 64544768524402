import React, { Component } from "react";

/*
PROPS
- data
- borderStyle
- isEditing
*/

class Social extends Component {
  render() {
    return (
      <div
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle,
          borderSpacing: "" + this.props.data.style.cellSpacing + "px 0px"
        }}
      >
        {this.getSocialButtons(this.props.data.value, this.props.data.style)}
      </div>
    );
  }
  getSocialButtons(buttons, style) {
    let arr = [];
    buttons.forEach((button, index) => {
      if (button.isRequired === true) {
        arr.push(this.getSocialButton(index, button, style));
      }
    });
    return arr;
  }

  getSocialButton(index, data, style) {
    return (
      <a
        key={index}
        href={data.url}
        style={{ pointerEvents: this.props.isEditing ? "none" : "auto" }}
      >
        <img
          src={data.icon}
          alt="Social link"
          style={{
            height: style.iconSize,
            marginLeft: style.cellSpacing,
            marginRight: style.cellSpacing
          }}
          draggable={false}
        />
      </a>
    );
  }
}

export default Social;
