import { DKListPicker2, DKLabel, DKIcon } from "deskera-ui-library";

import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import CampaignManager from "./CampaignManager";
import TemplateManager from "./templates/TemplateManager";

import ic_email from "../../assets/icons/ic_email.png";
import ic_whatsapp from "../../assets/icons/ic_whatsapp.png";
import { CAMPAIGN_TYPE } from "../../constants/Enum";
import AppManager from "../../managers/AppManager";
import { MODULE_ACTIONS, TABLES } from "../../managers/TableManger";

export const CAMPAIGN_TYPE_DATA = [
  {
    icon: ic_email,
    type: CAMPAIGN_TYPE.EMAIL,
    name: "Email"
  },
  {
    icon: ic_whatsapp,
    type: CAMPAIGN_TYPE.WHATSAPP,
    name: "Whatsapp"
  }
];

interface ICampaignTypeSelectorProps {
  className?: string;
  style?: any;
  onClose: () => void;
}

export default function CampaignTypeSelector(
  props: ICampaignTypeSelectorProps
) {
  return (
    <DKListPicker2
      data={CAMPAIGN_TYPE_DATA}
      className={
        "position-absolute border-m z-index-3 " + (props.className || "")
      }
      style={{
        right: 0,
        top: 20,
        pointerEvents: "all",
        width: 120,
        ...(props.style || {})
      }}
      onSelect={(
        index: number,
        campaignTypeData: (typeof CAMPAIGN_TYPE_DATA)[0]
      ) => {
        if (
          campaignTypeData.type === CAMPAIGN_TYPE.EMAIL &&
          AppManager.isModuleActionRestricted(
            TABLES.CAMPAIGN,
            MODULE_ACTIONS.EMAIL
          )
        ) {
          return;
        }

        CampaignManager.setCampaignType(campaignTypeData.type);
        CampaignManager.setPageData(null);
        if (campaignTypeData.type === CAMPAIGN_TYPE.EMAIL) {
          TemplateManager.setSelectedTemplateId(null);
          RouteManager.navigateToPage(PAGE_ROUTES.SELECT_CAMPAIGN_TEMPLATE);
        } else {
          RouteManager.navigateToPage(PAGE_ROUTES.WA_CAMPAIGN);
        }

        props.onClose();
      }}
      renderer={(index: number, typeData: (typeof CAMPAIGN_TYPE_DATA)[0]) => (
        <div className="row">
          <DKIcon src={typeData.icon} className="ic-s" />
          <DKLabel text={typeData.name} className="ml-r" />
        </div>
      )}
      onClose={() => props.onClose()}
    />
  );
}
