import {
  ACTIVITY_FILTER,
  CHART_INTERVALS,
  TICKET_FILTER
} from "../constants/Constant";
import Utility from "../utility/Utility";

export default class GraphManager {
  static graphData = {
    contactByLabel: {
      data: [],
      interval: CHART_INTERVALS[1]
    },
    contactByTrend: {
      data: [],
      interval: CHART_INTERVALS[1]
    },
    dealWonLost: {
      data: [],
      interval: CHART_INTERVALS[1]
    },
    pipeline: {
      data: [],
      pipeline: null
    },
    activity: {
      data: [],
      filter: ACTIVITY_FILTER[0]
    },
    ticket: {
      data: [],
      filter: TICKET_FILTER[0]
    }
  };
  static set(key, data) {
    this.graphData[key] = data;
  }
  static get(key) {
    return this.graphData[key];
  }
  static hasData(key) {
    return !Utility.isEmpty(this.graphData[key].data);
  }
  static clear(key) {
    this.graphData[key].data = null;
    if (this.graphData[key].interval)
      this.graphData[key].interval = CHART_INTERVALS[0];
    if (this.graphData[key].pipeline) this.graphData[key].pipeline = null;
  }
}
