import React, { Component } from "react";
import {
  DKLabel,
  DKContactIcon,
  DKIcons,
  DKIcon,
  DKListPicker,
  showAlert
} from "deskera-ui-library";
import IUser, { getFullName, getUserByIamId } from "../../../model/User";
import {
  DELETE_TEAM,
  DELETE_TEAM_MSG,
  INVITE_USER_STATUS
} from "../../../constants/Permission";
import { Team } from "../../../services/team";
import { store } from "../../../redux/store";
import { fetchTeams } from "../../../redux/slices/tenantSlice";
import Utility from "../../../utility/Utility";
import { CANCEL, DELETE } from "../../../constants/Constant";
export interface IUserTeamRowProps {
  user: any;
  isTeam?: boolean;
  isOwner?: boolean;
  showLoading?: boolean;
  onPermissionSelect: (permission: any) => void;
  onTeamEdit?: (team: any) => void;
  className?: string;
  iconRenderer?: (user: any) => any;
}
export interface IUserTeamRowState {
  needPermissionPicker: boolean;
  needTeamInfoPicker: boolean;
}
class TeamUserRow extends Component<IUserTeamRowProps, IUserTeamRowState> {
  constructor(props) {
    super(props);
    this.state = {
      needPermissionPicker: false,
      needTeamInfoPicker: false
    };
  }
  render() {
    return this.getUserRow(this.props.user);
  }
  getUserRow(user) {
    const users = user.users
      ?.map((userData) => getUserByIamId(userData.iamUserId))
      .filter((userData) => !Utility.isEmptyObject(userData));
    return (
      <div
        className={`row justify-content-between listPickerBG p-h-r p-v-m user-row-wrap ${this.props.className || ""}`}
      >
        <div className="row" style={{ maxWidth: "60%" }}>
          {this.props.iconRenderer ? (
            this.props.iconRenderer(user)
          ) : (
            <DKContactIcon
              title={!this.props.isTeam ? getFullName(user) : user.name}
              className={
                "flex-shrink-0 border-m display-only-web " +
                (this.props.isTeam ? "bg-gray1" : "")
              }
            />
          )}
          <div className="column ml-m width-auto margin-empty-mobile max-width-100">
            {user.status !== INVITE_USER_STATUS.SENT && (
              <DKLabel
                text={!this.props.isTeam ? getFullName(user) : user.name}
                className="fw-m mb-xs"
              />
            )}
            <DKLabel
              text={!this.props.isTeam ? user.email : `${users.length} Members`}
              className={`
                ${user.status !== INVITE_USER_STATUS.SENT ? "text-gray" : ""} parent-width`}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            />
          </div>
          {this.props.isTeam && this.getTeamInfoSection(users)}
        </div>
        <div className="row width-auto justify-content-end team-row-wrap">
          {this.props.isTeam && (
            <div
              className="column width-auto justify-content-end cursor-hand"
              onClick={() => this.props.onTeamEdit?.(this.props.user)}
            >
              <DKIcon
                className="ic-s"
                src={DKIcons.ic_edit}
                style={{ opacity: 0.6 }}
              />
            </div>
          )}
          {this.props.isTeam && (
            <div
              className="column width-auto justify-content-end ml-m cursor-hand"
              onClick={() => this.getDeleteConfirmation(user)}
            >
              <DKIcon
                className="ic-s"
                src={DKIcons.ic_delete}
                style={{ opacity: 0.6 }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  getTeamInfoSection(users: IUser[]) {
    return (
      <div
        className="position-relative cursor-hand"
        onClick={() => {
          this.setState({ needTeamInfoPicker: true });
        }}
        onMouseOver={() => {
          this.setState({ needTeamInfoPicker: true });
        }}
        onMouseLeave={() => {
          this.setState({ needTeamInfoPicker: false });
        }}
      >
        <DKIcon
          src={DKIcons.ic_info}
          className="ic-s ml-r"
          style={{ opacity: 0.2 }}
        />
        {this.state.needTeamInfoPicker && (
          <DKListPicker
            title="Team Members"
            data={users.map((user: IUser) => getFullName(user))}
            className="border-s shadow-s-2 position-absolute z-index-2"
            style={{ top: 10, left: 20, cursor: "default" }}
            width={150}
            onSelect={() => {}}
            onClose={() => {
              this.setState({ needTeamInfoPicker: false });
            }}
            button={{
              title: "Edit Team",
              className: "bg-gray1 border-m",
              icon: DKIcons.ic_edit,
              onClick: () => this.props.onTeamEdit?.(this.props.user)
            }}
          />
        )}
      </div>
    );
  }

  getDeleteConfirmation = (team) => {
    const buttons = [
      {
        title: CANCEL,
        className: "bg-gray1 border-m"
      },
      {
        title: DELETE,
        className: "bg-red text-white ml-r",
        onClick: () => {
          this.deleteRecord(team);
        }
      }
    ];
    showAlert(DELETE_TEAM, DELETE_TEAM_MSG, buttons);
  };
  deleteRecord = (team) => {
    Team.deleteTeam(team._id).then(() => {
      store.dispatch(fetchTeams());
    });
  };
}

export default TeamUserRow;
