import { Component } from "react";
import { DKLabel, DKButton, DKIcons } from "deskera-ui-library";
import Stepper from "../common/Stepper";
import CampaignManager from "./CampaignManager";
import ElementList from "../page_designer/ElementList";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";

import ic_send from "../../assets/mail_icons/ic_send.png";
/*
PROPS
- steps 
- stepperIndex
- title
- onSave
*/

class CampaignPageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showElement: false
    };
  }
  render() {
    let stepperIndex = this.props.stepperIndex;

    if (!this.props.steps && !CampaignManager.isCampaignEditable()) {
      stepperIndex = this.props.stepperIndex - 1;
    }

    return (
      <div className="parent-width border-box column position-relative z-index-2">
        <div className="row justify-content-between">
          <DKLabel text={this.props.title} className="fw-m fs-l" />
          {this.getButtons()}
        </div>
        <div className="row justify-content-center display-only-web">
          <Stepper
            data={this.props.steps || CampaignManager.getAllCreationSteps()}
            currentIndex={stepperIndex}
          />
        </div>
      </div>
    );
  }

  getButtons() {
    return (
      <div className="row" style={{ width: "auto" }}>
        {this.props.stepperIndex === 1 && (
          <div className="row" style={{ top: 47, zIndex: 1 }}>
            <DKButton
              icon={DKIcons.ic_add}
              className={`element-add-button bg-white border-m ml-r`}
              onClick={() => {
                this.setState({ showElement: true });
              }}
            />

            {this.state.showElement && (
              <div
                className="position-absolute z-index-1 shadow-m"
                style={{ top: 35, zIndex: 1 }}
              >
                <DKButton
                  icon={DKIcons.ic_close}
                  className="bg-white border-radius-none m-s"
                  iconClassName="ic-m"
                  style={{ float: "right" }}
                  onClick={(e) => {
                    this.setState({ showElement: false });
                    e.stopPropagation();
                  }}
                />
                <ElementList
                  className="p-r"
                  onAdd={(type) => {
                    this.props.onAdd(type);
                    this.setState({ showElement: false });
                  }}
                />
              </div>
            )}
          </div>
        )}
        {CampaignManager.checkCampaignSent() &&
          !CampaignManager.isWhatsAppCampaign() && (
            <DKButton
              title="Tracking Details"
              className="bg-white border-m ml-r unselectable"
              icon={DKIcons.ic_reports}
              onClick={() => {
                RouteManager.navigateToPage(
                  PAGE_ROUTES.CAMPAIGN_DETAILS +
                    "/" +
                    CampaignManager.getCampaignId()
                );
              }}
            />
          )}
        {this.props.onSave && CampaignManager.isCampaignEditable() && (
          <DKButton
            title={
              this.props.needPublishControl ? "Save as Draft" : "Save & Proceed"
            }
            icon={DKIcons.white.ic_save}
            className="bg-button text-white ml-r unselectable"
            onClick={() => {
              this.props.onSave();
            }}
          />
        )}
        {this.props.onSave &&
          CampaignManager.isCampaignEditable() &&
          this.props.needPublishControl && (
            <DKButton
              title="Activate & Send"
              icon={ic_send}
              className="bg-button text-white ml-r unselectable"
              onClick={() => {
                this.props.onSave(true);
              }}
            />
          )}
      </div>
    );
  }
}

export default CampaignPageHeader;
