import {
  DKLabel,
  DKButton,
  DKInput,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  DKDataGrid,
  DKIcon
} from "deskera-ui-library";
import { useEffect, useRef, useState } from "react";
import {
  createProductGroup,
  updateProductGroup
} from "../../services/productGroup";
import Utility from "../../utility/Utility";
import TableDataParser from "../../Helper/TableDataParser";
import { PRODUCT_TYPE } from "../../constants/Enum";
import { COLUMN_CODE, TableManger } from "../../managers/TableManger";
import { QTY_ROUND_OFF_PRECISION } from "../../constants/Constant";
import BooksService from "../../services/books";
import debounce from "../../utility/Debounce";
import ic_no_data from "../../assets/icons/ic_no_data_3.png";
import { USER_ACTION_TYPES } from "../../constants/Permission";

export function AddGroupPopup(props) {
  const [formState, setFormState] = useState({
    productGroupName: props?.dataToEdit?.name || "",
    description: props?.dataToEdit?.description,
    productGroupItems: props?.dataToEdit?.productGroupItems ?? []
  });

  const [canValidate, setCanValidate] = useState(false);
  const [selectedRows, setSelectedRows] = useState(
    props?.dataToEdit?.productGroupItems?.map((product) => product.productId) ||
      []
  );
  const [page, setPage] = useState(0);
  const [products, setProducts] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [query, setQuery] = useState("active=true,isVariant=false");
  const [filterDataForGrid, setFilterDataForGrid] = useState([]);
  const popupWrapperRef = useRef<any>();

  useEffect(() => {
    getProductsList();
  }, [page, searchText, query]);

  const getProductsList = () => {
    BooksService.getProducts({
      page: page ?? 0,
      limit: 20,
      query,
      search: searchText
    }).then((response: any) => {
      setProducts(response);
    });
  };

  const updateFormState = (key, value) => {
    let updatedFormState = { ...formState };
    setFormState({
      ...updatedFormState,
      [key]: value
    });
  };

  const validateForm = (formData) => {
    if (Utility.isEmptyObject(formData?.productGroupName?.trim())) {
      return true;
    }
    return false;
  };

  const onSave = () => {
    setCanValidate(true);

    if (validateForm(formState)) return;

    let dataToSend = {
      name: formState?.productGroupName,
      description: formState?.description || "",
      appName: "ERP",
      active: true,
      module: props?.module ? props?.module : "PRODUCT",
      productGroupItems: formState.productGroupItems
    };

    dataToSend = TableDataParser.getSanitizedJsonData(dataToSend);

    if (props?.dataToEdit?.id) {
      updateProductGroup(dataToSend, props?.dataToEdit?.id)
        .then((response) => {
          props.onSave?.();
        })
        .catch((err) => {
          props.onSave?.();
        });
    } else {
      createProductGroup(dataToSend)
        .then((response) => {
          props.onSave?.(true);
        })
        .catch((err) => {
          props.onSave?.();
        });
    }
  };
  const getUpdatedColumns = () => {
    let columns: any = [...TableManger.getTableColumns("books_product")];
    columns.forEach(
      (col: any) => ((col.key = col.columnCode), (col.editable = false))
    );
    const allowedColumns = [
      "documentSequenceCode",
      "name",
      "description",
      "outgoingQty"
    ];
    columns = columns.filter((col) => allowedColumns.includes(col.columnCode));
    return columns;
  };

  const getUpdatedRows = () => {
    let updatedRows = [...(products?.content || [])];
    const rowData = ProductRowsConfig(updatedRows);
    rowData.forEach((row: any) => {
      if (
        row.type.toLowerCase() === "non_tracked" &&
        row.outgoingQty == `0.00`
      ) {
        row.outgoingQty = "-";
      }
    });
    rowData?.map((row) => {
      row.allowRowEdit = props?.dataToEdit?.id
        ? !!props.permissions[USER_ACTION_TYPES.ASSIGN_PRODUCT]
        : true;
      row.selected = selectedRows?.includes(row.documentSequenceCode);
    });

    return rowData;
  };
  const getAvailableStock = (product: any) => {
    let quantity = product["inventory"]
      ? product["inventory"]["availableQuantity"]
      : 0;
    return isNaN(quantity)
      ? 0
      : Utility.roundingOff(quantity, QTY_ROUND_OFF_PRECISION);
  };

  function outgoingFont(OutgoingAmount: any) {
    const html = `<span class= ' text-red' > ${OutgoingAmount}</span>`;
    return html;
  }
  function ProductRowsConfig(productList: any[]): any {
    let rowData: any[] = [];
    const getCategory = (product: any) => {
      const avdTracking = product["advancedTracking"];
      const productType = product["type"];

      if (avdTracking && productType && avdTracking !== "NONE") {
        const advancedTrackedName: string = avdTracking + " " + productType;
        return Utility.convertInTitleCase(advancedTrackedName);
      } else if (avdTracking && productType && avdTracking === "NONE") {
        return Utility.convertInTitleCase(productType.replaceAll("_", " "));
      }
      return "-";
    };

    const getStatus = (product: any) => {
      return product["active"] ? "ACTIVE" : "INACTIVE";
    };

    productList.forEach((product, index) => {
      const hideIncomingOutgoingQty =
        product.type === PRODUCT_TYPE.BILL_OF_MATERIALS ||
        product.type === PRODUCT_TYPE.NON_TRACKED;
      let row: any = {
        ...product,
        [COLUMN_CODE.PRODUCTS.Number]: product.documentSequenceCode,
        [COLUMN_CODE.PRODUCTS.ProductName]: product.name,
        [COLUMN_CODE.PRODUCTS.Description]: product.description,
        [COLUMN_CODE.PRODUCTS.AvailableStock]: getAvailableStock(product),
        [COLUMN_CODE.PRODUCTS.Status]: [getStatus(product)],
        [COLUMN_CODE.PRODUCTS.Category]: getCategory(product),
        [COLUMN_CODE.PRODUCTS.Id]: product.id ? product.id : index,
        [COLUMN_CODE.PRODUCTS.Reorder]: product.reorderLevel,
        [COLUMN_CODE.PRODUCTS.TransactionType]: product.productTransactionType,
        [COLUMN_CODE.PRODUCTS.Barcode]: product.barcode,
        [COLUMN_CODE.PRODUCTS.Outgoing]: hideIncomingOutgoingQty
          ? ""
          : `${product.incomingQty}/${outgoingFont(product.outgoingQty)}`,
        attachmentsWithLink: product.attachmentsWithLink || []
      };
      if (
        product.reorderLevel !== null &&
        Number(getAvailableStock(product)) <= Number(product.reorderLevel)
      ) {
        row = {
          ...product,
          [COLUMN_CODE.PRODUCTS.Number]: product.documentSequenceCode,
          [COLUMN_CODE.PRODUCTS.ProductName]: product.name,
          [COLUMN_CODE.PRODUCTS.AvailableStock]:
            Number(getAvailableStock(product)) === 0
              ? outgoingFont(getAvailableStock(product))
              : getAvailableStock(product),
          [COLUMN_CODE.PRODUCTS.Status]: [getStatus(product)],
          [COLUMN_CODE.PRODUCTS.Category]: getCategory(product),
          [COLUMN_CODE.PRODUCTS.Id]: product.id ? product.id : index,
          [COLUMN_CODE.PRODUCTS.Reorder]: product.reorderLevel,
          [COLUMN_CODE.PRODUCTS.Outgoing]:
            `${product.incomingQty}/${outgoingFont(product.outgoingQty)}`,

          invalidFields: [
            COLUMN_CODE.PRODUCTS.Number,
            COLUMN_CODE.PRODUCTS.ProductName,
            COLUMN_CODE.PRODUCTS.AvailableStock,
            COLUMN_CODE.PRODUCTS.Status,
            COLUMN_CODE.PRODUCTS.Category,
            COLUMN_CODE.PRODUCTS.Id,
            COLUMN_CODE.PRODUCTS.Reorder,
            COLUMN_CODE.PRODUCTS.Outgoing
          ]
        };
      }
      rowData.push(row);
    });
    return rowData || [];
  }

  const onRowSelect = ({ rowData }) => {
    if (!rowData?.documentSequenceCode) return;

    let newSelectedRows = [...selectedRows];
    if (newSelectedRows.includes(rowData.documentSequenceCode)) {
      newSelectedRows = newSelectedRows.filter(
        (productId) => productId !== rowData.documentSequenceCode
      );
    } else {
      newSelectedRows.push(rowData.documentSequenceCode);
    }
    let updatedFormState = { ...formState };
    setFormState({
      ...updatedFormState,
      productGroupItems: newSelectedRows.map((id) => ({ productId: id }))
    });
    setSelectedRows(newSelectedRows);
  };
  const onAllRowSelect = ({ selected }) => {
    let newSelectedRows = [];
    let updatedFormState = { ...formState };

    if (selected) {
      newSelectedRows = products?.content?.map(
        (product) => product.documentSequenceCode
      );
    }
    setSelectedRows(newSelectedRows);
    setFormState({
      ...updatedFormState,
      productGroupItems: newSelectedRows?.map((id) => ({ productId: id })) ?? []
    });
  };
  const onPageChange = (page) => {
    setPage(page - 1);
  };
  const onSearch = debounce((searchQuery) => {
    setSearchText(searchQuery);
  }, 500);

  const onFilterApply = (filterData) => {
    const { query } = filterData;
    setFilterDataForGrid(query);
    const columns = getUpdatedColumns() || [];
    let queryArray: any = [{ isVariant: false }];
    let queryString = "";
    query.forEach((item) => {
      let columnToFilter = columns?.find((column) => column.id === item.key);
      let columnCode = columnToFilter?.columnCode;
      let value = item?.value?.[0] || item.value;
      if (columnCode === "status") {
        columnCode = "active";
        value = value === "ACTIVE" ? true : false;
      }
      if (columnCode === "type") {
        value = value === "NON_TRACKED" ? "NONTRACKED" : value;
      }
      queryArray.push({ [columnCode]: value });
      if (!Utility.isEmptyObject(queryArray)) {
        queryString = Utility.getQueryString(queryArray);
      }
    });
    setQuery(queryString);
  };

  const getHeader = () => {
    const popupTitle = props?.dataToEdit?.id
      ? `Edit Product Group`
      : "New Product Group";
    return (
      <div className="row justify-content-between pb-m parent-width">
        <DKLabel text={popupTitle} className="fw-b fs-m parent-width" />
        <div className="row justify-content-end">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m"
            onClick={() => props.onCancel?.()}
          />
          <DKButton
            title="Save"
            className="bg-button text-white ml-r"
            onClick={() => onSave()}
          />
        </div>
      </div>
    );
  };
  const getBody = () => {
    return (
      <>
        <DKInput
          title="Name"
          type={INPUT_TYPE.TEXT}
          required={true}
          autoFocus={true}
          className="mb-s"
          canValidate={canValidate}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={
            formState?.productGroupName
              ? formState.productGroupName?.toString()
              : ""
          }
          onChange={(value: string) =>
            updateFormState("productGroupName", value)
          }
        />
        <DKInput
          title="Description"
          type={INPUT_TYPE.LONG_TEXT}
          className="mt-m"
          required={false}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={
            formState?.description ? formState.description?.toString() : ""
          }
          onChange={(value: string) => updateFormState("description", value)}
        />
      </>
    );
  };
  const getProductGrid = () => {
    return (
      <div className="column parent-width product-grid-wrapper flex-1">
        <DKDataGrid
          title={"Products"}
          width={props.width ? props?.width - 740 : "100%"}
          allowRowEdit={true}
          allowBulkOperation={
            !!props.permissions[USER_ACTION_TYPES.ASSIGN_PRODUCT]
          }
          allowColumnSort={false}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowColumnDelete={false}
          allowColumnShift={false}
          allowSearch={true}
          // allowFilter={true}
          buttons={[]}
          columns={getUpdatedColumns() ?? []}
          rows={getUpdatedRows()}
          isAllRowSelected={
            !Utility.isEmptyObject(selectedRows) &&
            selectedRows?.length === products?.content?.length
          }
          onRowSelect={(data) => onRowSelect(data)}
          onAllRowSelect={(data) => onAllRowSelect(data)}
          onSearch={(data) => onSearch(data)}
          onFilter={onFilterApply}
          filterData={filterDataForGrid}
          currentPage={products?.pageable?.pageNumber + 1}
          onPageChange={onPageChange}
          totalPageCount={products?.totalPages}
        />
      </div>
    );
  };
  const getNoDataView = () => {
    return (
      <div
        className="column align-self-center align-items-center position-absolute"
        style={{ top: "60%", left: "40%", pointerEvents: "none" }}
      >
        <DKIcon
          src={ic_no_data}
          className="ic-l"
          style={{ opacity: 0.2, marginTop: 70 }}
        />
        <DKLabel text="No data found" className="fw-m mt-l" />
        <DKLabel
          text="Once data is available, it will appear here"
          className="text-gray mt-s "
        />
      </div>
    );
  };
  return (
    <div className="transparent-background">
      <div
        ref={popupWrapperRef}
        className="popup-window shadow-m"
        style={{
          width: props.width ? props?.width - 700 : "1065px",
          maxWidth: props.width ? props.width - 700 : "70%",
          maxHeight: "95%",
          height: "90%"
        }}
      >
        {getHeader()}
        {getBody()}
        {getProductGrid()}
        {Utility.isEmptyObject(getUpdatedRows() || []) && getNoDataView()}
      </div>
    </div>
  );
}
export default AddGroupPopup;
