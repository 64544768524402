import React, { Component } from "react";
import { DKButton, DKIcons, showAlert } from "deskera-ui-library";
import ElementList from "../ElementList";
import ComponentManager from "../ComponentManager";
import ElementComponentList from "../ElementComponentList";
import { PAGE_ELEMENT } from "../ComponentList";
import { shiftArrayElement } from "../../../utility/Utility";
import { getViewportSize } from "../../../utility/GetViewportSize";
import { VIEWPORT_SIZES } from "../../../constants/Constant";
import PlaceholderButton from "./PlaceholderButton";

/*
PROPS
- data
- index
- borderStyle
- components
- currentComponentIndex
- currentColumnSectionIndex
- isEditing
- hash
*/
export default class VStack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColumnIndex: null,
      components: this.props.components,
      currentComponentIndex: this.props.currentComponentIndex,
      currentColumnSectionIndex: this.props.currentColumnSectionIndex,
      isEditing: this.props.isEditing,
      needToShowImagePopup: this.props.needToShowImagePopup,
      isInlineTextEdit: this.props.isInlineTextEdit,
      showElements: false
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      components: nextProps.components,
      currentComponentIndex: nextProps.currentComponentIndex,
      currentColumnSectionIndex: nextProps.currentColumnSectionIndex,
      isEditing: nextProps.isEditing,
      needToShowImagePopup: nextProps.needToShowImagePopup,
      isInlineTextEdit: nextProps.isInlineTextEdit
    });
  }

  render() {
    return (
      <div
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle,
          borderSpacing: "" + this.props.data.style.cellSpacing + "px 0px"
        }}
        className={`vstack-section-${this.props.index}`}
      >
        {this.getColumnItems(this.props.data.value)}
      </div>
    );
  }

  getColumnItems(items) {
    let arr = [];
    if (items !== null && items !== undefined && items.length !== 0) {
      items.forEach((item, index) => {
        arr.push(this.getColumnItem(index, item, items));
      });
    }
    if (arr.length === 0 && this.state.isEditing) {
      arr.push(this.getBlankSection());
    }
    return arr;
  }

  getColumnItem(index, data) {
    return (
      <>
        {this.state.isEditing &&
          this.getCanvasTootip(this.props.index, index, data.type)}
        <div
          id={`vstack-${this.props.index + "-" + index}-component`}
          key={`vstack-${this.props.index + "-" + index}-component`}
          className="vstack-section-list position-relative mobile-view"
          style={{
            width: "100%",
            display: "inline-flex",
            wordBreak: "break-word",
            verticalAlign: "top",
            outline: this.state.isEditing ? "1px dotted #cbc2c2" : "none",
            minHeight: 50
          }}
          onClick={(e) => {
            if (data.value !== null) {
              let screenSize = getViewportSize();
              if (
                screenSize === VIEWPORT_SIZES.EXTRA_SMALL ||
                screenSize === VIEWPORT_SIZES.SMALL
              ) {
                this.onMobileToolTip(this.props.index, index, null);
              } else {
                this.props.onComponentSelect(
                  this.props.index,
                  index,
                  null,
                  false
                );
              }
            }
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            if (data.value !== null && data.type === PAGE_ELEMENT.FORM) {
              this.props.onComponentSelect(this.props.index, index, null, true);
            }
            e.stopPropagation();
          }}
          onMouseOver={(e) => {
            this.onMouseParentSection(this.props.index, index);
          }}
          onMouseDown={(e) => {
            if (
              this.state.currentColumnSectionIndex !== null &&
              this.state.currentColumnSectionIndex !== undefined &&
              this.state.currentColumnSectionIndex !== index
            ) {
              const div = document.getElementById(`dummy-focus-div`);
              div.focus();
            }
          }}
          onMouseLeave={(e) => {
            this.setState({ selectedColumnIndex: null });
            e.stopPropagation();
          }}
        >
          <ElementComponentList
            index={this.props.index}
            columnIndex={index}
            colComponentIndex={this.props.colComponentIndex}
            componentIndex={this.props.componentIndex}
            parentType={"v"}
            data={data}
            hash={this.props.hash}
            isEditing={this.state.isEditing}
            components={this.state.components}
            currentComponentIndex={this.state.currentComponentIndex}
            currentColumnSectionIndex={this.state.currentColumnSectionIndex}
            viewType={this.state.viewType}
            needToShowImagePopup={this.state.needToShowImagePopup}
            isInlineTextEdit={this.state.isInlineTextEdit}
            onDataChange={(data) => {}}
            onComponentSelect={(index, itemIndex, inlineEdit) => {}}
            onCloseInlineEdit={() => {
              // if(this.state.isInlineTextEdit && (this.state.currentColumnSectionIndex === null || this.tooltipClicked)) {
              this.props.onCloseInlineEdit();
              // }
            }}
            onClosePopUp={() => this.props.onClosePopUp()}
            onShowImagePopup={() => this.props.onShowImagePopup()}
            onShowInlineEdit={() => {}}
            enableInlineTextEdit={() => this.props.enableInlineTextEdit()}
          />
        </div>
      </>
    );
  }

  hideElementDropDown = () => {
    this.setState({ showElements: false });
  };

  onAddSectionComponent = (index, type) => {
    let components = { ...this.props.data };
    components.value.splice(
      index + 1,
      0,
      ComponentManager.getComponentDefaultData(type)
    );
    this.props.onValueChange(components.value);
    this.hideElementDropDown();
  };

  onColumnAddComponent = (columnIndex, type) => {
    let components = [...this.props.data.value];
    components.push(ComponentManager.getComponentDefaultData(type));
    this.props.onValueChange(
      components,
      this.props.index,
      this.props.columnIndex
    );
  };

  getCanvasTootip = (index, colIndex, type) => {
    return (
      <div
        className="column position-absolute dk-tootip-vstack z-index-1"
        id={`tooltip-${index + "-" + colIndex}-vstack-component`}
        style={{ display: "none", width: "auto" }}
        onMouseDown={(e) => {
          this.tooltipClicked = true;
          const div = document.getElementById(`dummy-focus-div`);
          div.focus();
        }}
      >
        <div className="row ">
          {colIndex !== this.props.data.value.length - 1 && (
            <DKButton
              icon={DKIcons.ic_arrow_down2}
              className="bg-gray1 border-m border-radius-none"
              onClick={() => {
                this.changeTootipEvent(index, colIndex, "down", type);
              }}
            />
          )}
          {colIndex !== 0 && (
            <DKButton
              icon={DKIcons.ic_arrow_up2}
              className="bg-gray1 border-m border-radius-none"
              onClick={() => {
                this.changeTootipEvent(index, colIndex, "up", type);
              }}
            />
          )}
          <DKButton
            icon={DKIcons.ic_copy}
            className="bg-gray1 border-m border-radius-none"
            onClick={() => {
              this.changeTootipEvent(index, colIndex, "copy", type);
            }}
          />
          <DKButton
            icon={DKIcons.ic_add}
            className="bg-gray1 border-m border-radius-none"
            onClick={() => {
              this.changeTootipEvent(index, colIndex, "add", type);
            }}
          />
          <PlaceholderButton
            pageType={this.props.pageType}
            componentData={{
              elementType: type,
              index,
              columnIndex: colIndex
            }}
            onToolInitialized={() => {
              this.changeTootipEvent(index, colIndex, "edit", type);
              this.hideElementDropDown();
            }}
            onPlaceholderSelected={() => {
              this.changeTootipEvent(index, colIndex, "edit", type);
            }}
          />
          <DKButton
            icon={DKIcons.ic_edit}
            className="bg-gray1 border-m border-radius-none"
            onClick={(e) => {
              e.stopPropagation();
              this.changeTootipEvent(index, colIndex, "edit", type);
            }}
          />
          <DKButton
            icon={DKIcons.ic_delete}
            className="bg-gray1 border-m border-radius-none"
            onClick={() => {
              this.changeTootipEvent(index, colIndex, "delete", type);
            }}
          />
        </div>
        <div className="z-index-1">
          {this.state.showElements && (
            <ElementList
              pageType={this.props.pageType}
              columnHide={true}
              stackHide={true}
              className="p-r shadow-l"
              onAdd={(type) => this.onAddSectionComponent(colIndex, type)}
            />
          )}
        </div>
      </div>
    );
  };

  changeTootipEvent = (index, colIndex, action, type) => {
    switch (action) {
      case "add":
        this.setState({ showElements: true });
        break;
      case "edit":
        if (type === null) {
          this.setState({ selectedColumnIndex: colIndex });
          this.props.onComponentSelect(index, null, null, false);
          break;
        }
        this.props.onComponentSelect(index, colIndex, null, false);
        if (type === PAGE_ELEMENT.IMAGE) {
          this.props.onShowImagePopup();
        }
        if (type === PAGE_ELEMENT.TEXT) {
          this.props.onShowInlineEdit();
        }
        if (type === PAGE_ELEMENT.FORM) {
          this.props.onComponentSelect(index, colIndex, null, true);
        }
        break;
      case "delete":
        this.props.onComponentSelect(index, null, null, false);
        this.onDeleteComponent(index, colIndex);
        break;
      case "copy":
        if (this.props.currentComponentIndex !== index) {
          if (type === null) {
            this.setState({ selectedColumnIndex: colIndex });
            this.props.onComponentSelect(index, colIndex, null, false);
            break;
          }
          this.props.onComponentSelect(index, colIndex, null, false);
          break;
        }
        this.onCopyComponent(index, colIndex, null);
        break;
      case "up":
        let newComponents = shiftArrayElement(
          this.props.data.value,
          colIndex,
          colIndex - 1
        );

        this.saveComponent(newComponents);
        break;
      case "down":
        let newComponent = shiftArrayElement(
          this.props.data.value,
          colIndex,
          colIndex + 1
        );

        this.saveComponent(newComponent);
        break;
      default:
        break;
    }
  };

  saveComponent = (components) => {
    this.props.onValueChange(components, this.props.index, null);
  };

  onCopyComponent = (index, colIndex) => {
    let components = [...this.props.components[index].value];
    let tempMyObj = { ...components[colIndex] };
    components.splice(colIndex + 1, 0, JSON.parse(JSON.stringify(tempMyObj)));
    this.props.onValueChange(components, index, null);
  };

  onDeleteComponent = (index, colIndex) => {
    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Delete",
        className: "bg-red text-white ml-r",
        onClick: () => {
          let components = [...this.props.data.value];
          components.splice(colIndex, 1);
          this.props.onValueChange(components, index);
        }
      }
    ];
    showAlert(
      "Delete element?",
      "Deleting this element will delete it permanently.",
      buttons
    );
  };

  setTooltip = (index, colIndex) => {
    if (this.props.readOnly) {
      return;
    }
    let canvas = document.getElementById(
      `vstack-${index + "-" + colIndex}-component`
    );
    let tooltip = document.getElementById(
      `tooltip-${index + "-" + colIndex}-vstack-component`
    );
    if (tooltip) {
      tooltip.style.display = "flex";
      tooltip.style.top = canvas.offsetTop - 33 + "px";
      tooltip.style.left = canvas.offsetLeft - 2 + "px";
      canvas.className = canvas.className + " canvas-active";
    }
    this.setCanvasToolTip(index);
  };

  setCanvasToolTip = (index) => {
    let canvas = document.getElementById(`canvas-${index}-component`);
    let tooltip = document.getElementById(`tooltip-${index}-component`);
    if (tooltip) {
      tooltip.style.display = "flex";
      tooltip.style.top = canvas.offsetTop - 33 + "px";
      canvas.className = canvas.className + " canvas-active";
    }
  };

  onMouseParentSection = (index, colIndex) => {
    this.onCanvasLeave();
    this.setTooltip(index, colIndex);
  };

  onCanvasLeave = () => {
    const allTooltip = document.getElementsByClassName("dk-tootip-vstack");
    for (let i = 0; i < allTooltip.length; i++) {
      allTooltip[i].style.display = "none";
    }
    this.hideElementDropDown();
    const elements = document.getElementsByClassName(
      "vstack-section-list canvas-active"
    );
    if (elements.length > 0) {
      elements[0].classList.remove("canvas-active");
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  getBlankSection = () => {
    return (
      <div
        id={`vstack-item-${this.props.index + "-0"}-component`}
        style={{ border: "1px dashed #80808094" }}
        className="vstack-section-list-item column align-items-center justify-content-center parent-width parent-height"
        onMouseLeave={(e) => {
          this.setState({ showElements: false });
          e.stopPropagation();
        }}
      >
        <DKButton
          icon={DKIcons.ic_add}
          className="position-relative border-radius-none"
          iconClassName="ic-m"
          onClick={(e) => {
            let screenSize = getViewportSize();
            if (
              screenSize === VIEWPORT_SIZES.EXTRA_SMALL ||
              screenSize === VIEWPORT_SIZES.SMALL
            ) {
              this.onMobileToolTip(this.props.index, null, null);
            } else {
              this.props.onComponentSelect(this.props.index, null);
            }
            this.setState({ showElements: true });
            e.stopPropagation();
          }}
        />
        {this.state.showElements && (
          <div
            className="position-absolute z-index-1 shadow-m"
            style={{ top: 67, zIndex: 1 }}
          >
            <DKButton
              icon={DKIcons.ic_close}
              className="bg-white border-radius-none m-s"
              iconClassName="ic-m"
              style={{ float: "right" }}
              onClick={(e) => {
                this.setState({ showElements: false });
                e.stopPropagation();
              }}
            />
            <ElementList
              pageType={this.props.pageType}
              columnHide={true}
              stackHide={true}
              viewType={this.state.viewType}
              className="p-r"
              onAdd={(type) =>
                this.onColumnAddComponent(this.props.index, type)
              }
            />
          </div>
        )}
      </div>
    );
  };

  onMobileToolTip = (index, itemIndex) => {
    this.onMouseParentSection(index, itemIndex);
  };
}
