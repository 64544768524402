import ReactDOM from "react-dom";
import React, { useState } from "react";
import { DKLabel, showAlert } from "deskera-ui-library";
import { IContactAddress } from "../../model/BooksContact";
import {
  INPUT_TYPE,
  DKInput,
  INPUT_VIEW_DIRECTION,
  DKButton,
  Toggle
} from "deskera-ui-library";
import BooksService from "../../services/books";
import {
  GST_TREATMENT_VALUES,
  REGEX,
  NON_GST_TREATMENT_VALUES
} from "../../constants/Constant";
import {
  COUNTRY_NAME,
  INDIAN_STATES_MOCK_DATA,
  US_STATES_MOCK_DATA
} from "../../constants/CountriesAndStates";
import { COUNTRIES_WITH_CURRENCIES } from "../../constants/Currencies";
import Utility, {
  getRandomAlphaNumericString,
  isObject,
  removeHtmlTagsAndNormalizeSpaces
} from "../../utility/Utility";
import Popup from "../common/Popup";
export interface IAddAddressProps {
  address?: IContactAddress;
  data?: {
    gstIn?: string;
    gstTreatment?: string;
  };
  onSave: (data: { address: IContactAddress; complianceDetails: any }) => void;
  onClose: () => void;
  defaultAddress?: string;
  className?: string;
  isAddressPopup?: boolean;
  isQuotePopup?: boolean;
  needDefaultToggles?: boolean;
  canValidateOnInitialize?: boolean;
  isQuoteByAccount?: string;
}
const ADDRESS_FIELDS = [
  {
    key: "contactName",
    label: "Contact Name",
    placeholder: "Contact name",
    type: INPUT_TYPE.TEXT,
    required: false
  },
  {
    key: "address1",
    label: "Street",
    placeholder: "Street",
    type: INPUT_TYPE.TEXT,
    required: false
  },
  {
    key: "address2",
    label: "Address 2",
    placeholder: "Address 2",
    type: INPUT_TYPE.TEXT,
    required: false
  },
  {
    key: "country",
    label: "Country",
    placeholder: "Country",
    required: false,
    displayKey: "country",
    type: INPUT_TYPE.DROPDOWN,
    dropdownConfig: {
      className: "parent-width",
      data: COUNTRIES_WITH_CURRENCIES,
      allowSearch: true,
      searchableKey: "country"
    }
  },
  {
    key: "state",
    label: "State",
    placeholder: "State",
    type: INPUT_TYPE.TEXT,
    required: false,
    displayKey: "name",
    dropdownConfig: {
      className: "parent-width",
      data: [],
      searchableKey: "name",
      allowSearch: true
    }
  },
  {
    key: "city",
    label: "City",
    placeholder: "City",
    type: INPUT_TYPE.TEXT,
    required: false,
    dropdownConfig: {
      className: "parent-width",
      data: [],
      allowSearch: true
    }
  },
  {
    key: "postalCode",
    label: "Postal Code",
    placeholder: "Postal Code",
    type: INPUT_TYPE.TEXT,
    required: false
  }
];

const ADDRESS_DEFAULT_TOGGLES = [
  {
    type: "toggle",
    key: "billing",
    label: "Default Billing"
  },
  {
    type: "toggle",
    key: "shipping",
    label: "Default Shipping"
  }
];

const AddAddress: React.FC<IAddAddressProps> = (props: IAddAddressProps) => {
  const getSelectedStateAndCountry = () => {
    let { country: countryName, state } = props.address || {};

    let countryObj = null;

    if (
      !Utility.isEmptyObject(countryName) ||
      !Utility.isEmptyObject(props.defaultAddress)
    ) {
      countryObj = COUNTRIES_WITH_CURRENCIES.find((item) =>
        countryName
          ? item.country === countryName
          : props.defaultAddress.includes(item.country)
      );
      state = getStateObjectByCountry({ country: countryName, state });
    }

    return { country: countryObj, state };
  };
  const getStateObjectByCountry = ({ country, state }) => {
    switch (country) {
      case COUNTRY_NAME.INDIA:
        return (
          INDIAN_STATES_MOCK_DATA.find((item) => item.name === state) ||
          INDIAN_STATES_MOCK_DATA[0]
        );
      case COUNTRY_NAME.USA:
        return (
          US_STATES_MOCK_DATA.find((item) => item.name === state) ||
          US_STATES_MOCK_DATA[0]
        );
      default:
        return state;
    }
  };
  //** state declaration render goes here */
  const [address, setAddress] = React.useState<IContactAddress>({
    id: props.address?.id || getRandomAlphaNumericString(24),
    contactName: props.address?.contactName || "",
    address1: props.address?.address1 || "",
    address2: props.address?.address2 || "",
    city: props.address?.city || "",
    ...getSelectedStateAndCountry(),
    postalCode: props.address?.postalCode || "",
    preferred: props.address?.preferred || false,
    billing: props.address?.billing || false,
    shipping: props.address?.shipping || false
  });
  const [complianceDetails, setComplianceDetails] = React.useState({
    gstIn: props.data?.gstIn || "",
    gstTreatment:
      GST_TREATMENT_VALUES.find(
        (item) => item.key === props.data?.gstTreatment
      ) || GST_TREATMENT_VALUES[0]
  });
  const defaultAddress = props.defaultAddress || "";
  const [isSaveTapped, setSaveTapped] = useState(
    props.canValidateOnInitialize ?? false
  );
  const [fields, setFields] = React.useState(ADDRESS_FIELDS);
  //** effects render goes here */
  React.useEffect(() => {
    updateFieldData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  //** business logic goes here */
  const onChange = (key, value) => {
    let newAddress = { ...address };
    setSaveTapped(false);
    switch (key) {
      case "country":
        newAddress.state = "";
        newAddress.city = "";
        break;
      case "state":
        newAddress.city = "";
        break;
      default:
        break;
    }
    newAddress[key] = value;
    setAddress(newAddress);
  };
  const getPlainAddressFromAddress = (
    addressToFlatten: any
  ): IContactAddress => {
    let newAddress = {};
    Object.keys(addressToFlatten).forEach((key) => {
      newAddress[key] = addressToFlatten[key];
      if (key === "country" && isObject(addressToFlatten[key])) {
        newAddress[key] = addressToFlatten[key].country;
      } else if (key === "state" && isObject(addressToFlatten[key])) {
        newAddress[key] = addressToFlatten[key].name;
      }
    });
    newAddress["full"] = BooksService.getAddressAsString(
      newAddress as IContactAddress
    );
    return newAddress as IContactAddress;
  };
  const updateFieldData = () => {
    const { country, state } = address;
    const fieldsCopy = Utility.deepCloneObject(fields);
    let stateArray = [];
    let cityArray = [];
    switch (country?.["country"]) {
      case COUNTRY_NAME.INDIA:
        stateArray = INDIAN_STATES_MOCK_DATA;
        cityArray =
          INDIAN_STATES_MOCK_DATA.find(
            (item) => item.name === (state?.["name"] ? state["name"] : state)
          )?.cities || INDIAN_STATES_MOCK_DATA[0].cities;
        break;
      case COUNTRY_NAME.USA:
        stateArray = US_STATES_MOCK_DATA;
        break;
      default:
        break;
    }
    const newAddress = getPlainAddressFromAddress({ ...address });
    const requiredFields = BooksService.getRequiredAddressFields(newAddress);
    fieldsCopy.forEach((field) => {
      field.required = requiredFields.includes(field.key);
      switch (field.key) {
        case "state":
          field.type =
            stateArray.length > 0 ? INPUT_TYPE.DROPDOWN : INPUT_TYPE.TEXT;
          field.dropdownConfig.data = stateArray;
          field.dropdownConfig.renderer = (index: any, obj: any) => (
            <DKLabel text={obj.name} />
          );
          break;
        case "city":
          field.type =
            cityArray.length > 0 ? INPUT_TYPE.DROPDOWN : INPUT_TYPE.TEXT;
          field.dropdownConfig.data = cityArray;
          field.dropdownConfig.renderer = (index: any, obj: any) => (
            <DKLabel text={obj} />
          );
          break;
        case "country":
          field.dropdownConfig.renderer = (index: any, obj: any) => (
            <DKLabel text={obj.country} />
          );
          break;
        default:
          field.type = INPUT_TYPE.TEXT;
          break;
      }
    });
    setFields(fieldsCopy);
  };
  //** sub render goes here */
  const getActionButtons = () => {
    const isGTSMandatory =
      BooksService.isIndiaGSTRegistered() &&
      !NON_GST_TREATMENT_VALUES.includes(complianceDetails.gstTreatment?.key);
    return (
      <div className="row">
        <DKButton
          className="bg-gray1 mr-m border-m"
          title={"Cancel"}
          onClick={() => props.onClose?.()}
        />
        <DKButton
          className="bg-button text-white border-m"
          title={"Save"}
          onClick={() => {
            setSaveTapped(true);
            const newAddress = getPlainAddressFromAddress({ ...address });
            Object.keys(newAddress).forEach((key: string) => {
              if (typeof newAddress[key] === "string") {
                newAddress[key] = removeHtmlTagsAndNormalizeSpaces(
                  newAddress[key]
                );
              }
            });
            if (Utility.isEmptyObject(newAddress.full)) {
              setSaveTapped(false);
              showAlert("Invalid Address", `Address can't be blank.`);
              return;
            }

            if (
              !BooksService.isAddressMissing(
                newAddress,
                BooksService.getRequiredAddressFields(newAddress)
              )
            ) {
              if (
                isGTSMandatory &&
                !props.isAddressPopup &&
                !REGEX.GST_IN_REGEX_PATTERN.test(complianceDetails.gstIn)
              )
                return;
              props.onSave?.({
                address: newAddress,
                complianceDetails: {
                  gstIn: complianceDetails?.gstIn,
                  gstTreatment: complianceDetails?.gstTreatment?.key
                }
              });
            }
          }}
        />
      </div>
    );
  };
  const getInputField = (field, index) => {
    return (
      <DKInput
        title={field.label}
        type={field.type}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        required={field.required}
        className={`${index !== 0 ? "mt-l" : "mt-s"}`}
        canValidate={isSaveTapped}
        value={address[field.key]}
        onChange={(value) => onChange(field.key, value)}
        displayKey={field.displayKey}
        dropdownConfig={field.dropdownConfig}
      />
    );
  };
  const getComplianceFields = () => {
    const isGTSMandatory =
      BooksService.isIndiaGSTRegistered() &&
      !NON_GST_TREATMENT_VALUES.includes(complianceDetails.gstTreatment?.key);
    return (
      <>
        {BooksService.isIndiaGSTRegistered() && (
          <div className="column p-h-l p-v-s">
            <div className="row fs-m fw-b">Compliance Details</div>
            <div className="row parent-width" key="contact-gst-treatment">
              <DKInput
                title={"GST Treatment"}
                type={INPUT_TYPE.DROPDOWN}
                required={true}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                className="mt-l"
                value={complianceDetails.gstTreatment}
                onChange={(value) =>
                  setComplianceDetails({
                    ...complianceDetails,
                    gstTreatment: value
                  })
                }
                displayKey="value"
                dropdownConfig={{
                  className: "parent-width",
                  data: GST_TREATMENT_VALUES,
                  searchableKey: "country",
                  renderer: (index: any, obj: any) => (
                    <DKLabel text={obj.value} />
                  )
                }}
              />
            </div>
            {isGTSMandatory && (
              <div className="row parent-width" key="contact-gstin">
                <DKInput
                  title={"GSTIN"}
                  type={INPUT_TYPE.TEXT}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={isGTSMandatory}
                  className="mt-l"
                  canValidate={isSaveTapped}
                  validator={(value) => REGEX.GST_IN_REGEX_PATTERN.test(value)}
                  value={complianceDetails.gstIn}
                  onChange={(value) =>
                    setComplianceDetails({ ...complianceDetails, gstIn: value })
                  }
                />
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  const getDefaultAddressToggles = () => {
    return (
      <div className="row flex-wrap parent-width justify-content-between mt-l p-h-l">
        {ADDRESS_DEFAULT_TOGGLES.map((defaultToggle) => (
          <div className="row p-v-xs width-auto">
            <Toggle
              isOn={address[defaultToggle.key]}
              onChange={() =>
                onChange(defaultToggle.key, !address[defaultToggle.key])
              }
            />
            <DKLabel text={defaultToggle.label} className="ml-r" />
          </div>
        ))}
      </div>
    );
  };
  //** main render goes here */
  return (
    <div className={`address-wrapper pb-l ${props.className}`}>
      <div className="row p-m bg-gray1 justify-content-between">
        <div className="col fw-b fs-l">
          {props.isAddressPopup
            ? `${props.address?.id ? "Edit" : "Add"} detailed address`
            : `${props.isQuotePopup && props.isQuoteByAccount === "ACCOUNT" ? "Account" : "Contact"} Details`}
        </div>
        <div className="col">{getActionButtons()}</div>
      </div>
      {props.isAddressPopup ? null : getComplianceFields()}
      <div className="column p-h-l pt-m">
        {props.isAddressPopup ? null : (
          <div className="row fs-m fw-b">Address Details</div>
        )}
        {defaultAddress && (
          <div className="row mt-r mb-r">
            <div className="fw-m mr-l">Current Address</div>
            <div
              style={{
                textAlign: "left"
              }}
            >
              {defaultAddress}
            </div>
          </div>
        )}
      </div>
      {fields.map((field, index) => (
        <div className="row parent-width p-h-l" key={field.key}>
          {getInputField(field, index)}
        </div>
      ))}
      {props.needDefaultToggles ? getDefaultAddressToggles() : null}
    </div>
  );
};
export default AddAddress;

export const showAddBooksContactAddress = (
  config: {
    address?: IContactAddress;
    data?: {
      gstIn?: string;
      gstTreatment?: string;
    };
    defaultAddress?: string;
    className?: string;
    isAddressPopup?: boolean;
    isQuotePopup?: boolean;
    needDefaultToggles?: boolean;
    canValidateOnInitialize?: boolean;
    isQuoteByAccount?: string;
  },
  onSave: (data: { address: IContactAddress; complianceDetails: any }) => void,
  onClose?: () => void
) => {
  const id = `add-books-address-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "app-font";
  div.setAttribute("id", id);

  const handleOnClose = () => {
    const addressPopupElement = document.getElementById(id);
    ReactDOM.unmountComponentAtNode(addressPopupElement);
    addressPopupElement?.remove();
    onClose?.();
  };

  ReactDOM.render(
    <Popup
      popupWindowStyles={{
        maxWidth: "90vw",
        width: 450,
        height: "auto",
        maxHeight: "90vh",
        padding: 0,
        pointerEvents: "auto"
      }}
    >
      <AddAddress
        {...config}
        onSave={(data) => {
          handleOnClose();
          onSave(data);
        }}
        onClose={handleOnClose}
      />
    </Popup>,
    document.body.appendChild(div)
  );
};
