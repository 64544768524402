export const profitAndLossData = {
  columns: [
    {
      title: "Accounts",
      type: "bold-lines",
      style: {
        width: 300,
        textAlign: "left",
        color: "white"
      }
    },
    {
      title: "Total",
      type: "bold-lines",
      style: {
        width: 300,
        textAlign: "right"
      }
    }
  ],
  rows: [
    {
      expanded: true,
      cells: [
        {
          title: "Income",
          style: {
            fontWeight: "normal",
            marginLeft: 0
          }
        },
        {
          title: "",
          style: {}
        }
      ],
      children: [
        {
          expanded: true,
          cells: [
            {
              title: "Sales",
              style: {
                marginLeft: 15
              }
            },
            {
              title: "$ 225.00"
            }
          ],
          children: [
            {
              cells: [
                {
                  title: "Level (2)",
                  style: {
                    marginLeft: 30
                  }
                },
                {
                  title: "$ 0.00"
                }
              ]
            }
          ]
        },
        {
          cells: [
            {
              title: "Sales of Product Income",
              style: {
                marginLeft: 15
              }
            },
            {
              title: "$ 12.00"
            }
          ]
        }
      ]
    },
    {
      cells: [
        {
          title: "Total Income",
          type: "bold-lines"
        },
        {
          title: "$ 237.00",
          type: "bold-lines"
        }
      ]
    },
    {
      expanded: true,
      cells: [
        {
          title: "Cost of Good Sold"
        },
        {
          title: ""
        }
      ],
      children: [
        {
          cells: [
            {
              title: "Cost of Good Sold",
              style: {
                marginLeft: 15
              }
            },
            {
              title: "$ 0.00"
            }
          ]
        },
        {
          cells: [
            {
              title: "Total Cost of Good Sold",
              type: "bold-lines"
            },
            {
              title: "$ 0.00",
              type: "bold-lines"
            }
          ]
        }
      ]
    },
    {
      expanded: true,
      cells: [
        {
          title: "GROSS PROFIT",
          style: {},
          type: "bold"
        },
        {
          title: "$ 237.00",
          type: "bold"
        }
      ]
    },
    {
      expanded: true,
      cells: [
        {
          title: "Expenses",
          style: {
            marginLeft: 0
          }
        },
        {
          title: ""
        }
      ],
      children: [
        {
          cells: [
            {
              title: "Ask My Accountant",
              style: {
                marginLeft: 15
              }
            },
            {
              title: "$ 100.00"
            }
          ]
        },
        {
          cells: [
            {
              title: "Contractors",
              style: {
                marginLeft: 15
              }
            },
            {
              title: "$ 299.00"
            }
          ]
        },
        {
          cells: [
            {
              title: "Legal & Professional Services",
              style: {
                marginLeft: 15
              }
            },
            {
              title: "$ 100.00"
            }
          ]
        },
        {
          cells: [
            {
              title: "Total Expenses",
              type: "bold-line",
              style: {
                marginLeft: 0,
                marginTop: 8
              }
            },
            {
              title: "$ 499.00",
              type: "bold-line",
              style: {
                marginLeft: 0,
                marginTop: 8
              }
            }
          ]
        }
      ]
    },
    {
      cells: [
        {
          title: "NET OPERATING INCOME",
          type: "bold-line"
        },
        {
          title: "$ -262.00",
          type: "bold-line"
        }
      ]
    },
    {
      cells: [
        {
          title: "NET INCOME",
          type: "bold-line"
        },
        {
          title: "$ -262.00",
          type: "bold-line"
        }
      ]
    }
  ]
};
