function getNodePosition(root) {
  let counter = 0,
    node = root?.previousSibling;
  while (node) {
    counter++;
    node = node.previousSibling;
  }

  return counter;
}

function getRangeDataToSave(range, container) {
  let newRangeData = null;
  if (!range || !container) return null;

  try {
    let startContainer = range.startContainer,
      endContainer = range.endContainer,
      startNodeAncestorPositions = [],
      endNodeAncestorPositions = [];

    while (startContainer && startContainer !== container) {
      startNodeAncestorPositions.push(getNodePosition(startContainer));
      startContainer = startContainer.parentNode;
    }

    while (endContainer && endContainer !== container) {
      endNodeAncestorPositions.push(getNodePosition(endContainer));
      endContainer = endContainer.parentNode;
    }

    newRangeData = {
      startNodeAncestorPositions,
      startOffset: range.startOffset,
      endNodeAncestorPositions,
      endOffset: range.endOffset
    };
  } catch (err) {
    newRangeData = null;
  }

  return newRangeData;
}

function setCaretSelection(range, containerElement) {
  const selection = window.getSelection();
  if (!range) {
    range = document.createRange();
    range.selectNodeContents(containerElement);
    range.collapse(false);
  }

  selection.removeAllRanges();
  selection.addRange(range);
}

function restoreSelectionFromSavedRange(savedRange, containerElement) {
  if (!containerElement) return;

  containerElement.focus();

  let range = document.createRange(),
    counter = 0,
    tempContainer = null,
    startContainer = containerElement,
    endContainer = containerElement;

  try {
    tempContainer = savedRange.startNodeAncestorPositions;
    counter = tempContainer.length;
    while (counter--) {
      const childPos = tempContainer[counter];
      startContainer = startContainer?.childNodes?.length
        ? startContainer.childNodes[childPos]
        : startContainer;
    }

    tempContainer = savedRange.endNodeAncestorPositions;
    counter = tempContainer.length;
    while (counter--) {
      const childPos = tempContainer[counter];
      endContainer = endContainer?.childNodes?.length
        ? endContainer.childNodes[childPos]
        : endContainer;
    }

    range.setStart(startContainer, savedRange.startOffset);
    range.setEnd(endContainer, savedRange.endOffset);
  } catch (err) {
    range = null;
  }

  setCaretSelection(range, containerElement);
}

/**
 * @description
 * This utility is used for inserting text/html content in place of current selection.
 */
function replaceSelectionWithContent(contentNode, container) {
  const selection = window.getSelection();

  if (!selection.rangeCount) {
    container?.appendChild?.(contentNode);
    return null;
  }

  let range = selection.getRangeAt(0);
  range.deleteContents();
  range.insertNode(contentNode);

  /* Special case scenario for chrome & safari,
    While inserting nodes in starting of container,
    pseudo text element gets generated & range gets incorrect due to same,
    hence deleting the empty text element.
  */
  try {
    if (
      range.startContainer?.nodeName === "#text" &&
      !range.startContainer?.textContent &&
      range.startContainer.parentNode
    ) {
      const parentElement = range.startContainer.parentNode;
      const childNode = range.startContainer;
      parentElement.removeChild(childNode);
    }
  } catch (err) {}

  range.selectNodeContents(contentNode);
  range.collapse(false);

  return getRangeDataToSave(range, container);
}

export {
  setCaretSelection,
  getRangeDataToSave,
  replaceSelectionWithContent,
  restoreSelectionFromSavedRange
};
