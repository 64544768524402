import React, { useState } from "react";
import Utility from "../../utility/Utility";
import { DKLabel, DKButton, DKIcons } from "deskera-ui-library";
import UserManager from "../../managers/UserManager";

export default function ChatEmbedPopup(props) {
  const [isCodeCopied, codeCopied] = useState(false);
  const getPopupHeader = () => {
    return (
      <div className="row justify-content-between ">
        <DKLabel text={"Embed Chat"} className="fw-m fs-l" />
        <DKButton
          title="Cancel"
          className="bg-gray1 border-m"
          onClick={() => props?.onClose()}
        />
      </div>
    );
  };
  const getPopupBody = () => {
    return (
      <div className="column parent-width  ">
        <div
          className="column mt-l bg-white border-radius-s parent-width border-box align-self-center align-items-center"
          style={{ maxWidth: 800 }}
        >
          <div className="p-xl border-radius-s bg-gray1 column parent-width border-m">
            <DKLabel
              className="fw-b fs-m"
              text="Embed script in head section"
            />
            <DKLabel
              className="text-gray mt-xs"
              text="Add this script to your head section to start chat from your webpages."
            />
            <div className=" text-align-left text-wrap bg-white border-m border-radius-s p-s mt-m parent-width border-box text-black code-block">
              <pre className="parent-width">
                <code className="parent-width hide-scroll-bar">
                  {getEmbeddedScript()}
                </code>
              </pre>
            </div>
            <DKButton
              title={`${isCodeCopied ? "Copied" : "Copy Code"}`}
              icon={DKIcons.ic_copy}
              className="mt-s"
              onClick={() => {
                codeCopied(true);
                Utility.copyToClipBoard(getEmbeddedScript());
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const getEmbeddedScript = () => {
    return `<link rel="stylesheet" href="${window.location.origin}/chat-ui/${
      process.env.REACT_APP_ENV
    }/main.css" />\n<script src="${window.location.origin}/chat-ui/${
      process.env.REACT_APP_ENV
    }/bundle.js"></script>\n<script>window.addEventListener("load", function () {window.deskeraChat('init',{tenantId: ${UserManager.getUserTenantID()}});});</script>`;
  };
  return (
    <>
      {getPopupHeader()}
      {getPopupBody()}
    </>
  );
}
