import React, { Component } from "react";
import { DKLabel, DKButton, DKIcons, showAlert } from "deskera-ui-library";
import { PAGE_ELEMENT, PAGE_TYPE } from "./ComponentList";
import ic_image from "../../assets/canvas/ic_image.png";
import ic_play from "../../assets/icons/ic_play.png";
import ic_image_text from "../../assets/canvas/ic_image_text.png";
import ic_link from "../../assets/canvas/ic_link.png";
import ic_button from "../../assets/canvas/ic_button.png";
import ic_code from "../../assets/canvas/ic_code.png";
import ic_image_gallery from "../../assets/canvas/ic_image_gallery.png";
import ic_column from "../../assets/canvas/ic_columns.png";
import ic_timer from "../../assets/canvas/ic_timer.png";
import ic_v_stack from "../../assets/canvas/ic_v_stack.png";
import { connect } from "react-redux";

/*
PROPS
- onAdd
- pageType (PAGE_TYPE)
*/

class ElementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: this.props.viewType,
      columnHide: this.props.columnHide ? this.props.columnHide : false,
      stackHide: this.props.stackHide ? this.props.stackHide : false
    };
  }

  render() {
    const buttonClass = "mt-r listPickerBG";
    return (
      <div
        className={
          "columns bg-white border-radius-s border-box " + this.props.className
        }
        style={{ ...this.props.style }}
      >
        <DKLabel text="+ Add Element" className="fw-m mb-m" />
        <DKButton
          title="Text"
          icon={DKIcons.ic_textfield}
          onClick={() => this.props.onAdd(PAGE_ELEMENT.TEXT)}
          className={buttonClass}
        />
        {this.props.pageType !== PAGE_TYPE.FORM && (
          <DKButton
            title="Button"
            className={buttonClass}
            icon={ic_button}
            onClick={() => this.props.onAdd(PAGE_ELEMENT.BUTTON)}
          />
        )}
        <DKButton
          title="Image"
          className={buttonClass}
          icon={ic_image}
          onClick={() => this.props.onAdd(PAGE_ELEMENT.IMAGE)}
        />
        <DKButton
          title="Video"
          className={buttonClass}
          icon={ic_play}
          onClick={() => this.props.onAdd(PAGE_ELEMENT.VIDEO)}
        />

        {!this.state.columnHide && (
          <DKButton
            title="Logo / Icon"
            className={buttonClass}
            icon={DKIcons.ic_product}
            onClick={() => this.props.onAdd(PAGE_ELEMENT.LOGO)}
          />
        )}
        {!this.state.columnHide && (
          <DKButton
            title="Collection / Gallery"
            className={buttonClass}
            icon={DKIcons.ic_dashboard}
            onClick={() => this.props.onAdd(PAGE_ELEMENT.COLLECTION)}
          />
        )}
        {!this.state.columnHide && (
          <DKButton
            title="Image & Text"
            className={buttonClass}
            icon={ic_image_text}
            onClick={() => this.props.onAdd(PAGE_ELEMENT.IMAGE_TEXT)}
          />
        )}
        {(this.props.pageType === PAGE_TYPE.LANDING_PAGE ||
          this.props.pageType === PAGE_TYPE.FUNNEL ||
          this.props.pageType === PAGE_TYPE.FORM) && (
          <DKButton
            title="Form"
            className={buttonClass}
            icon={DKIcons.data_type.ic_multi_select}
            onClick={() => this.props.onAdd(PAGE_ELEMENT.FORM)}
          />
        )}
        <DKButton
          title="Social links"
          className={buttonClass}
          icon={ic_link}
          onClick={() => this.props.onAdd(PAGE_ELEMENT.SOCIAL_BUTTONS)}
        />
        <DKButton
          title="Custom HTML"
          className={buttonClass}
          icon={ic_code}
          onClick={() => this.props.onAdd(PAGE_ELEMENT.CUSTOM_HTML)}
        />
        {!this.state.columnHide &&
          this.props.pageType !== PAGE_TYPE.CAMPAIGN && (
            <DKButton
              title="Image gallery"
              className={buttonClass}
              icon={ic_image_gallery}
              onClick={() => this.props.onAdd(PAGE_ELEMENT.IMAGE_GALLERY)}
            />
          )}
        {!this.state.columnHide && (
          <DKButton
            title="Columns"
            className={buttonClass}
            icon={ic_column}
            onClick={() => this.props.onAdd(PAGE_ELEMENT.COLUMN_SECTION)}
          />
        )}
        {!this.state.columnHide &&
          this.props.pageType !== PAGE_TYPE.CAMPAIGN && (
            <DKButton
              title="Order Form"
              className={buttonClass}
              icon={DKIcons.data_type.ic_multi_select}
              onClick={() => {
                this.props.onAdd(PAGE_ELEMENT.ORDER_FORM);
              }}
            />
          )}
        {!this.state.stackHide && (
          <DKButton
            title="V Stack"
            className={buttonClass}
            icon={ic_v_stack}
            onClick={() => this.props.onAdd(PAGE_ELEMENT.V_STACK)}
          />
        )}

        {this.props.pageType !== PAGE_TYPE.CAMPAIGN && (
          <DKButton
            title="Countdown"
            className={buttonClass}
            icon={ic_timer}
            onClick={() => this.props.onAdd(PAGE_ELEMENT.COUNTDOWN_TIMER)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isStripeConnected: state.userPref.isStripeConnected
});
const connector = connect(mapStateToProps, null);
export default connector(ElementList);
