import React, { Component } from "react";
import {
  DKLabel,
  DKButton,
  DKIcon,
  DKIcons,
  isEmpty
} from "deskera-ui-library";
import {
  COLUMN_CODE,
  TableManger,
  TABLES
} from "../../../managers/TableManger";

import Utility from "../../../utility/Utility";

import { getDashboardWidgetLoading } from "../../dashboard/Dashboard";
import FormManager from "../../forms/FormManager.js";
import RouteManager, { PAGE_ROUTES } from "../../../managers/RouteManager";
import { connect, ConnectedProps } from "react-redux";
import { addRecord, setRecords } from "../../../redux/slices/recordSlice";
import { store } from "../../../redux/store";
import { fetchRecordsByTable } from "../../../redux/slices/recordSlice";
import { USER_ACTION_TYPES } from "../../../constants/Permission";
import { isMobileAppWebView } from "../../../utility/GetViewportSize";

interface IFormsCardProps extends PropsFromRedux {
  onCountUpdated: (count: number) => void;
}
interface IFormsCardState {
  data: any[];
  showLoading: boolean;
}
class FormCard extends Component<IFormsCardProps, IFormsCardState> {
  columnsIds = {
    name: "",
    link: "",
    thumbnail: ""
  };
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showLoading: false
    };
  }
  componentDidMount() {
    this.setState({ showLoading: true });
    this.setColumnsIds();
    this.fetchRecords();
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.data) {
      this.onDataReceived(props.data);
    }
  }
  render() {
    return (
      <>
        {this.state.showLoading && getDashboardWidgetLoading()}
        {
          <>
            {!this.state.showLoading && this.state.data.length !== 0 && (
              <>{this.state.data.map((record) => this.getRow(record))}</>
            )}
          </>
        }
        {!this.state.showLoading &&
          this.state.data.length === 0 &&
          this.getEmptyState()}
      </>
    );
  }

  getRow(record) {
    const allowRowEdit = !!this.props.permissions[USER_ACTION_TYPES.REC_UPDATE];
    return (
      <div
        className="row p-v-xs justify-content-between listPickerBG border-radius-s"
        style={{
          cursor: allowRowEdit ? "pointer" : "default"
        }}
      >
        <div
          className=" row"
          onClick={() =>
            !isMobileAppWebView() && allowRowEdit
              ? this.onRowClick(record)
              : undefined
          }
        >
          {" "}
          <DKLabel text={`${record[this.columnsIds.name]}`} className="" />
        </div>
        {record[this.columnsIds.link] ? (
          !isMobileAppWebView() && (
            <div className="fs-r text-align-left cursor-hand data-link-wrapper word-break-all row-reverse width-auto">
              <DKButton
                className="p-s"
                style={{ opacity: 0.5 }}
                icon={DKIcons.ic_open}
                onClick={(e) =>
                  this.onClickRowIcon(e, record[this.columnsIds.link], "open")
                }
              />
              <DKButton
                className="p-s"
                style={{ opacity: 0.5 }}
                icon={DKIcons.ic_copy}
                onClick={(e) =>
                  this.onClickRowIcon(e, record[this.columnsIds.link], "copy")
                }
              />
            </div>
          )
        ) : (
          <DKLabel
            className={`p-v-xs p-h-s border-radius-s mt-s data-grid-badge-color-5`}
            text={"Draft"}
          />
        )}
      </div>
    );
  }

  fetchRecords(refresh = false) {
    let data = this.props.data;
    if (refresh || isEmpty(data)) {
      store.dispatch(fetchRecordsByTable({ tableName: TABLES.FORM }));
    } else {
      this.onDataReceived(data);
    }
  }
  onDataReceived = (data) => {
    if (this.props.onCountUpdated) this.props.onCountUpdated(data.totalCount);
    this.setState({
      data: data.data.map((record) => {
        return { ...record.cells, id: record._id };
      }),
      showLoading: false
    });
  };

  setColumnsIds = () => {
    this.columnsIds.name = TableManger.getColumnId(
      TABLES.FORM,
      COLUMN_CODE.FORMS.NAME
    );
    this.columnsIds.link = TableManger.getColumnId(
      TABLES.FORM,
      COLUMN_CODE.FORMS.DIRECT_LINK
    );
    this.columnsIds.thumbnail = TableManger.getColumnId(
      TABLES.FORM,
      COLUMN_CODE.FORMS.THUMBNAIL
    );
  };
  getEmptyState = () => {
    return (
      <div className="column parent-width parent-height align-items-center justify-content-center">
        <DKLabel text="No data found" className="fw-m fs-m text-gray" />
        <DKLabel text="Create and send form here" className="text-gray mt-xs" />
      </div>
    );
  };
  onRowClick = (record) => {
    let data = JSON.parse(JSON.stringify(record));
    let data_formatted = { data: null, id: null };
    data_formatted.data = {
      _id: data.id,
      cells: data
    };
    data_formatted.id = data.id;

    FormManager.setFormData(data_formatted);
    RouteManager.navigateToPage(`${PAGE_ROUTES.FORM_BUILDER}/${data.id}`);
  };
  onClickRowIcon = (e: any, link: string, type: string) => {
    e.stopPropagation && e.stopPropagation();
    switch (type) {
      case "open":
        window.open(link, "_blank");
        break;
      case "copy":
        Utility.copyToClipBoard(link, true);
        break;
    }
  };
}

const mapStateToProps = (state) => ({
  data: state.records.data[TABLES.FORM],
  permissions:
    state.rolesPermission?.currentUserModuleWisePermissions?.[TABLES.FORM] || {}
});
const mapDispatchToProps = { addRecord, setRecords };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(FormCard);
