import CustomDomainService from "../services/customDomain";

export default class DomainManager {
  static allCustomDomains = null;

  static getAllCustomDomains() {
    return DomainManager.allCustomDomains;
  }

  static getLatestDomain() {
    return DomainManager.allCustomDomains
      ? DomainManager.allCustomDomains[0]
      : null;
  }

  static getLatestDomainName() {
    return DomainManager.getDomainName(DomainManager.getLatestDomain());
  }

  static getDomainName(domainObj) {
    return domainObj ? domainObj.domain : null;
  }
  static isDomainVerified(domainObj) {
    return domainObj ? domainObj.is_verified : false;
  }

  static getAllCustomDomainsFromAPI(onSuccess, onFail) {
    CustomDomainService.getData()
      .then((response) => {
        if (response.data.length !== 0) {
          response.data.sort((prev, next) => prev.id - next.id);
          response.data.reverse();
          DomainManager.allCustomDomains = response.data;
        }
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((err) => {
        if (onFail) {
          onFail();
        }
      });
  }

  static getAllDomainNames() {
    if (DomainManager.allCustomDomains) {
      const arr_names = DomainManager.allCustomDomains.map((obj) => {
        return obj.domain;
      });
      return arr_names;
    }
    return [];
  }

  static getDomainFromIndex(index) {
    return DomainManager.allCustomDomains
      ? DomainManager.allCustomDomains[index]
      : null;
  }
}
