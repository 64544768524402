import React, { Component } from "react";
import { DKLabel, DKContactIcon, isEmpty } from "deskera-ui-library";
import {
  COLUMN_CODE,
  TableManger,
  TABLES
} from "../../../managers/TableManger";
import { getDashboardWidgetLoading } from "../../dashboard/Dashboard";
import { connect, ConnectedProps } from "react-redux";
import {
  addRecord,
  setRecords,
  fetchRecordsByTable
} from "../../../redux/slices/recordSlice";
import { store } from "../../../redux/store";
import { showFormPopup } from "../../menu/AddNewRecordPopup";
import ContactManager from "../../../managers/ContactManager";
import { USER_ACTION_TYPES } from "../../../constants/Permission";
interface IContactCardProps extends PropsFromRedux {
  onCountUpdated: (count: number) => void;
}
interface IContactCardState {
  data: any[];
  showLoading: boolean;
  refreshing: boolean;
}
class ContactCard extends Component<IContactCardProps, IContactCardState> {
  columnsIds = {
    name: "",
    email: "",
    phone: ""
  };
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      showLoading: false,
      refreshing: false
    };
  }
  componentDidMount() {
    this.setState({
      showLoading: true
    });
    this.setColumnsIds();
    this.fetchRecords();
  }
  componentWillUnmount() {}
  UNSAFE_componentWillReceiveProps(props) {
    if (props.data) {
      this.onDataReceived(props.data);
    }
  }
  render() {
    return (
      <>
        {this.state.showLoading && getDashboardWidgetLoading()}
        {
          <>
            {!this.state.showLoading &&
              this.state.data &&
              this.state.data.length !== 0 && (
                <>{this.state.data.map((record) => this.getRow(record))}</>
              )}
          </>
        }
        {!this.state.showLoading &&
          this.state.data &&
          this.state.data.length === 0 &&
          this.getEmptyState()}
      </>
    );
  }

  getRow(data) {
    const allowRowEdit = !!this.props.permissions[USER_ACTION_TYPES.REC_UPDATE];
    return (
      <div
        className="row p-v-xs mb-r justify-content-between listPickerBG border-radius-s cursor-hand"
        onClick={() => (allowRowEdit ? this.onRowClick(data) : undefined)}
      >
        <div>
          <div className="row">
            <DKContactIcon
              title={`${data[this.columnsIds.name]}`}
              className="bg-gray1 border-m"
            />
            <div className="column ml-r">
              <DKLabel
                text={`${data[this.columnsIds.name]}`}
                className="fw-m"
              />
              <DKLabel
                text={`${
                  data[this.columnsIds.email]
                    ? data[this.columnsIds.email]
                    : "-"
                }`}
                className="text-gray"
              />
            </div>
          </div>
        </div>
        <DKLabel
          text={`${
            data[this.columnsIds.phone] ? data[this.columnsIds.phone] : "-"
          }`}
          className="text-gray"
        />
      </div>
    );
  }
  getEmptyState = () => {
    return (
      <div
        className={`column parent-width parent-height align-items-center justify-content-center`}
      >
        <DKLabel text="No data found" className="fw-m fs-m text-gray" />
        <DKLabel text="Add your first contact" className="text-gray mt-xs" />
      </div>
    );
  };
  fetchRecords(refresh = false) {
    let data = this.props.data;
    if (refresh || isEmpty(data)) {
      store.dispatch(fetchRecordsByTable({ tableName: TABLES.CONTACT }));
    } else {
      this.onDataReceived(data);
      this.fetchRecords(true);
    }
  }
  onDataReceived = (data) => {
    if (this.props.onCountUpdated) this.props.onCountUpdated(data.totalCount);
    this.setState({
      data: data.data.map((record) => {
        return { ...record.cells, id: record._id };
      }),
      showLoading: false
    });
  };
  setColumnsIds = () => {
    this.columnsIds.name = TableManger.getColumnId(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.NAME
    );
    this.columnsIds.email = TableManger.getColumnId(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.EMAIL
    );
    this.columnsIds.phone = TableManger.getColumnId(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.PHONE
    );
  };
  onRowClick = (record) => {
    let data = JSON.parse(JSON.stringify(record));
    ContactManager.getDataToUpdate(data);
    showFormPopup({
      tableName: TABLES.CONTACT,
      recordId: data.id,
      defaultValues: ContactManager.getDefaultValues(data)
    });
  };
}

const mapStateToProps = (state) => ({
  data: state.records.data[TABLES.CONTACT],
  permissions:
    state.rolesPermission?.currentUserModuleWisePermissions?.[TABLES.CONTACT] ||
    {}
});
const mapDispatchToProps = { addRecord, setRecords };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ContactCard);
