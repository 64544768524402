import ApiConstants from "../constants/ApiConstants";
import httpClient from "../http";
class PriceBook {
  static createPriceBook = (payload: any, params = {}) => {
    params = { skipInterceptor: true, ...params };
    return httpClient
      .post(ApiConstants.URL.PRICE_BOOK.CREATE, payload, { params })
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  };
  static getPriceBookList = (params: any) => {
    return httpClient.get(`${ApiConstants.URL.PRICE_BOOK.GET_ALL}`, { params });
  };
  static getPriceBookVersions = (priceBookId: any) => {
    return httpClient.get(
      `${ApiConstants.URL.PRICE_BOOK.GET_VERSIONS(priceBookId)}`
    );
  };
}
export const DEFAULT_PRICE_BOOK_ID = 0;
export const DEFAULT_PRICE_BOOK = {
  id: DEFAULT_PRICE_BOOK_ID,
  name: "-",
  active: true
};
export const getProductsById = (productsIdList: string[] = []) => {
  return httpClient.post(
    `${ApiConstants.URL.PRICE_BOOK.FETCH_PRODUCTS_BY_ID}`,
    productsIdList
  );
};
export const deletePriceBook = (id) => {
  return httpClient.delete(
    `${ApiConstants.URL.PRICE_BOOK.CREATE}/${id}?isAdvance=true`
  );
};
export const updatePriceBook = (id, payload, params = {}) => {
  params = { skipInterceptor: true, ...params };
  return httpClient.put(
    `${ApiConstants.URL.PRICE_BOOK.CREATE}/${id}`,
    payload,
    { params }
  );
};
export const createPriceBookVersion = (id, payload) => {
  return httpClient.post(
    `${ApiConstants.URL.PRICE_BOOK.CREATE_VERSION}/${id}`,
    payload
  );
};
export const updatePriceBookStatus = (id, statusId) => {
  const headers = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  return httpClient.put(
    ApiConstants.URL.PRICE_BOOK.SET_ACTIVE(id),
    statusId,
    headers
  );
};
export const exportPriceBook = (query: any) => {
  return httpClient.get(ApiConstants.URL.PRICE_BOOK.EXPORT_PRICE_BOOK, {
    responseType: "blob",
    params: query
  });
};
export default PriceBook;
