import React, { Component } from "react";
import {
  DKLabel,
  DKButton,
  Toggle,
  showLoader,
  removeLoader,
  DKIcon,
  DKIcons
} from "deskera-ui-library";
import {
  MODULES,
  MODULE_EVENT,
  NOTIFICATION_SETTING_MODULES,
  SUB_SETTING_SCREEN
} from "../../constants/Constant";
import NotificationService from "../../services/notification";
import { getCapitalized } from "../../utility/Utility";
import { isViewportMobile } from "../../utility/GetViewportSize";

class NotificationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSectionIndex: isViewportMobile() ? null : 0, // Default to 0 if not mobile
      actions: [],
      disabledBellNotification: this.props?.disabledBellNotification || {},
      disableSaveButton: false,
      currentScreen: SUB_SETTING_SCREEN.NOTIF_MODULE_SELECTOR // Add this state to manage the current screen for mobile
    };
  }

  componentDidMount() {
    if (!isViewportMobile()) {
      this.updateActions();
    }
  }

  updateActions = () => {
    if (this.state.selectedSectionIndex !== null) {
      let allActions = this.getActions();
      let updatedActions = Object.keys(allActions).map((key) => ({
        key,
        title: allActions[key],
        isOn: !this.props?.disabledBellNotification?.[
          NOTIFICATION_SETTING_MODULES[this.state.selectedSectionIndex]
        ]?.includes(key)
      }));
      this.setState({ actions: updatedActions });
    }
  };

  render() {
    const { currentScreen } = this.props;

    return (
      <div className="parent-size">
        {isViewportMobile()
          ? currentScreen === SUB_SETTING_SCREEN.NOTIF_MODULE_SELECTOR
            ? this.renderModuleSelectorScreen()
            : this.renderOptionsScreen()
          : this.renderDefaultScreen()}
        {(!isViewportMobile() ||
          currentScreen === SUB_SETTING_SCREEN.NOTIF_OPTIONS) &&
          this.getButtons()}
      </div>
    );
  }

  getButtons() {
    const { disableSaveButton } = this.state;

    return (
      <div className="pt-l row justify-content-between align-items-start">
        <div className="row width-auto">
          <DKButton
            title="Save"
            className="bg-button text-white"
            disabled={disableSaveButton}
            onClick={() => this.saveNotificationSetting()}
          />
        </div>
      </div>
    );
  }

  renderModuleSelectorScreen() {
    return (
      <div className="mt-l mb-s flex-1 parent-width row align-items-start border-m-b">
        {this.getLeftPanel()}
      </div>
    );
  }

  renderOptionsScreen() {
    return (
      <div className="pb-l flex-1 parent-width column align-items-start border-m-b">
        {/* <div className="row justify-content-center"> */}
        <DKLabel
          text={getCapitalized(
            NOTIFICATION_SETTING_MODULES[
              this.state.selectedSectionIndex
            ]?.toLowerCase()
          )}
          className="mt-l pb-s fw-m"
        />
        {/* </div> */}
        {this.getRightPanel()}
      </div>
    );
  }

  renderDefaultScreen() {
    return (
      <div className="mt-l mb-s flex-1 parent-width row align-items-start border-m-b">
        {this.getLeftPanel()}
        <div
          className="parent-height bg-gray2 column"
          style={{ width: 1, marginLeft: -2, height: 400 }}
        />
        {this.getRightPanel()}
      </div>
    );
  }

  getLeftPanel() {
    const { selectedSectionIndex } = this.state;
    const width = isViewportMobile() ? "100%" : 150;
    return (
      <div className="z-index-1 pb-l " style={{ width }}>
        {NOTIFICATION_SETTING_MODULES.map((obj, index) => {
          let title = obj.toLowerCase();
          const isSelected =
            index === selectedSectionIndex &&
            (!isViewportMobile() ||
              this.state.currentScreen === SUB_SETTING_SCREEN.NOTIF_OPTIONS);
          const backgroundColor =
            !isViewportMobile() && isSelected ? "#f4f4f6" : "";

          return (
            <div
              className={`row justify-content-between ${isViewportMobile() ? "p-v-xs" : ""} cursor-hand`}
              onClick={() => {
                this.onModuleChange(index);
                this.props.changeSubRightPanel(
                  SUB_SETTING_SCREEN.NOTIF_OPTIONS
                );
              }}
              key={index}
            >
              <DKLabel
                text={getCapitalized(title)}
                className={
                  "listPickerBG parent-width p-v-s pl-xs " +
                  (isSelected && !isViewportMobile()
                    ? "fw-m text-app "
                    : "fw-app")
                }
                style={{
                  // backgroundColor: isSelected ? "#f4f4f6" : "",
                  backgroundColor
                }}
              />
              {isViewportMobile() && (
                <DKIcon src={DKIcons.ic_arrow_right2} className="ic-xs-2" />
              )}
              {isSelected && !isViewportMobile() && (
                <div
                  className="bg-button parent-height"
                  style={{ width: 2, height: 30 }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  onModuleChange = (index) => {
    this.setState(
      {
        selectedSectionIndex: index,
        currentScreen: isViewportMobile()
          ? SUB_SETTING_SCREEN.NOTIF_OPTIONS
          : SUB_SETTING_SCREEN.NOTIF_MODULE_SELECTOR
      },
      () => {
        this.updateActions();
      }
    );
    this.props.changeSubRightPanel();
  };

  getRightPanel() {
    return (
      <div className="z-index-1 flex-1 parent-width">
        {this.state.actions?.map((action, index) => {
          return (
            <div
              className={`${isViewportMobile() ? "" : "pl-l"} pt-m  row justify-content-between`}
              key={index}
            >
              <DKLabel text={action.title} />
              <Toggle
                color="bg-green"
                isOn={action.isOn}
                onChange={() =>
                  this.handleActionToggle(action.key, !action.isOn)
                }
              />
            </div>
          );
        })}
      </div>
    );
  }

  handleActionToggle = (key, toggle) => {
    let actionsToUpdate = [...this.state.actions];
    const index = actionsToUpdate.findIndex((action) => action.key === key);
    actionsToUpdate[index].isOn = toggle;
    this.setState({ actions: actionsToUpdate });
    const disableActions = actionsToUpdate
      .filter((action) => !action.isOn)
      ?.map((action) => action.key);
    let data = { ...this.state.disabledBellNotification };
    data[NOTIFICATION_SETTING_MODULES[this.state.selectedSectionIndex]] =
      disableActions;
    this.setState({ disabledBellNotification: data });
  };

  saveNotificationSetting = () => {
    this.setState({ disableSaveButton: true });
    showLoader("Saving settings...");
    NotificationService.updateNotificationSetting({
      disabledBellNotification: this.state.disabledBellNotification
    }).then((response) => {
      this.props?.onSave?.();
      this.setState({ disableSaveButton: false });
      removeLoader();
    });
  };

  getActions = () => {
    switch (NOTIFICATION_SETTING_MODULES[this.state.selectedSectionIndex]) {
      case MODULES.CONTACT:
        return MODULE_EVENT.CONTACT;
      case MODULES.DEAL:
        return MODULE_EVENT.DEAL;
      case MODULES.CAMPAIGN:
      case MODULES.FORM:
      case MODULES.LANDING_PAGE:
      case MODULES.AUTOMATION:
        return this.getActionsName(
          Object.values(MODULES)[this.state.selectedSectionIndex]
        );
      case MODULES.ACTIVITY:
        return MODULE_EVENT.ACTIVITY;
      case MODULES.PIPELINE:
        return MODULE_EVENT.PIPELINE;
      case MODULES.STAGE:
        return MODULE_EVENT.STAGE;
      case MODULES.CHAT_MESSAGE:
        return MODULE_EVENT.CHAT_MESSAGE;
      case MODULES.TICKET:
        return MODULE_EVENT.TICKET;
      case MODULES.SUPPORT_EMAIL:
        return MODULE_EVENT.SUPPORT_EMAIL;
      default:
        return MODULE_EVENT.COMMON;
    }
  };

  getActionsName = (module) => {
    let title = module.toLowerCase();
    title = getCapitalized(title);
    let data = {
      REC_CREATE: `${title} created`,
      REC_UPDATE: `${title} updated`,
      REC_BULK_DELETE: `${title} deleted`,
      REC_BULK_UPDATE: `Bulk ${module.toLowerCase()} updated`,
      REC_PERMISSION_CHANGED: `${title} record permission changed`,
      TABLE_PERMISSION_CHANGED: "Table permission changed"
    };
    return data;
  };
}

export default NotificationSettings;
