import React, { useEffect, useState } from "react";
import { DKLabel, HorizontalBar } from "deskera-ui-library";
import DashboardService from "../../../services/dashboard";
import { COLORS } from "../../../constants/Colors";
import { connect, ConnectedProps } from "react-redux";
import {
  COLUMN_CODE,
  TableManger,
  TABLES
} from "../../../managers/TableManger";
import DKDropdown from "../DKDropdown";
import { getDashboardWidgetLoading } from "../../dashboard/Dashboard";
import GraphManager from "../../../managers/GraphManager";

function PipelineChart(props: PropsFromRedux) {
  const [data, setData] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState(
    GraphManager.get("pipeline").pipeline
  );
  const [pipeline, setPipeline] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showEmptyState, setShowEmptyState] = useState(false);

  useEffect(() => {
    function filterPipelineData() {
      const pipelineNameColumnId = TableManger.getColumnId(
        TABLES.PIPELINE,
        COLUMN_CODE.PIPELINE.NAME
      );
      const mappedPipeline = props.pipelines.map((data) => ({
        id: data._id,
        name: data.cells[pipelineNameColumnId]
      }));
      setPipeline(mappedPipeline);
      const pipeline = GraphManager.get("pipeline").pipeline
        ? GraphManager.get("pipeline").pipeline
        : mappedPipeline[0];
      setSelectedPipeline(pipeline);
      GraphManager.set("pipeline", {
        pipeline: pipeline,
        data: GraphManager.get("pipeline").data
      });
    }
    if (props.pipelines?.length > 0) {
      filterPipelineData();
    } else {
      setShowLoader(false);
      setShowEmptyState(true);
    }
  }, [props.pipelines]);

  useEffect(() => {
    const filterData = (data) => {
      const numChartColors = COLORS.charts.pie.length;
      setShowLoader(false);
      setShowEmptyState(false);
      setData(
        data.map((item, index) => ({
          ...item,
          color: COLORS.charts.pie[index % numChartColors],
          value: item.value
        }))
      );
    };
    function getData() {
      DashboardService.getDealCountByStage({
        pipelineId: selectedPipeline.id
      }).then((data) => {
        filterData(data.data);
        setShowLoader(false);
        GraphManager.set("pipeline", {
          pipeline: selectedPipeline,
          data: data.data
        });
      });
    }
    if (GraphManager.hasData("pipeline")) {
      filterData(GraphManager.get("pipeline").data);
      setTimeout(getData, 100);
    } else {
      if (selectedPipeline && selectedPipeline.id) {
        setShowLoader(true);
        getData();
      }
    }
  }, [selectedPipeline]);

  const onPipelineChange = (selectedIndex) => {
    GraphManager.clear("pipeline");
    setSelectedPipeline(Object.assign({}, pipeline[selectedIndex]));
  };
  const getEmptyState = () => {
    return (
      <div className="column parent-width parent-height align-items-center justify-content-center">
        <DKLabel text="No data found" className="fw-m fs-m text-gray" />
      </div>
    );
  };
  return (
    <div
      className="column align-items-center parent-size"
      style={{
        height: 302
      }}
    >
      <div className="row align-items-center justify-content-between mb-l">
        <DKLabel text={"Deals by Pipeline"} className="fw-m fs-m column" />
        {selectedPipeline && (
          <div className="column">
            <DKDropdown
              title={selectedPipeline.name}
              data={pipeline.map((item) => item.name)}
              onSelect={onPipelineChange}
              width={180}
            />
          </div>
        )}
      </div>
      {!showLoader && data.length > 0 && (
        <HorizontalBar data={data} height={230} />
      )}
      {showLoader && getDashboardWidgetLoading()}
      {showEmptyState && getEmptyState()}
    </div>
  );
}
const mapStateToProps = (state) => ({
  pipelines: state.records.data[TABLES.PIPELINE]?.data
});

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PipelineChart);
