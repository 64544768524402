import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProductService from "../../services/product";
import { RootState } from "../store";

const initialState: any = {
  data: {} as any
};

export const fetchProducts = createAsyncThunk(
  "products",
  async (data: any, thunkAPI) => {
    const response: any = await ProductService.getProductsByPage();
    return response;
  }
);

export const ProductsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  }
});

export const selectProducts = (state: RootState) => state.products.data;

export default ProductsSlice.reducer;
