import ApiConstants from "../constants/ApiConstants";
import httpClient from "../http";
import { ISalesRepresentativeListResponse } from "../model/User";
export interface IDemoSettings {
  _id?: string;
  users?: {
    iamUserId: number;
    meetingLink: string;
    profilePic: string;
  }[];
}
export const fetchBookADemoSettings = () => {
  return httpClient.get(ApiConstants.URL.BOOK_A_DEMO.SETTING);
};
export const updateBookADemoSettings = (payload: any) => {
  return httpClient.put(ApiConstants.URL.BOOK_A_DEMO.SETTING, payload);
};
export const getRandomUserToBookADemo = (settingId) => {
  return httpClient.get(ApiConstants.URL.BOOK_A_DEMO.GET_USER(settingId));
};
export const scheduleADemo = (settingId: string, payload: any) => {
  return httpClient.post(
    ApiConstants.URL.BOOK_A_DEMO.SCHEDULE_OEP(settingId),
    payload
  );
};
export const getSlots = (settingId: string, params) => {
  return httpClient.get(ApiConstants.URL.BOOK_A_DEMO.GET_SLOTS(settingId), {
    params
  });
};

export class SalesRepService {
  static async fetchRepresentatives(
    params?: any
  ): Promise<ISalesRepresentativeListResponse> {
    return httpClient.get(ApiConstants.URL.BOOK_A_DEMO.GET_BDR, {
      params: {
        pageNo: 1,
        pageSize: 100,
        ...(params || {})
      }
    });
  }

  static async fetchRepresentativeById(bdrId: string) {
    return httpClient.get(ApiConstants.URL.BOOK_A_DEMO.GET_BDR_BY_ID(bdrId));
  }

  static async addRepresentative(payload) {
    return httpClient.post(ApiConstants.URL.BOOK_A_DEMO.ADD_BDR, payload);
  }

  static async updateRepresentative(bdrId: string, payload) {
    return httpClient.put(
      ApiConstants.URL.BOOK_A_DEMO.UPDATE_BDR(bdrId),
      payload
    );
  }
}
