import { useEffect, useRef, useState } from "react";
import { DKInputForm, INPUT_TYPE } from "deskera-ui-library";
import Popup from "../common/Popup";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchTenantsInfo,
  getTenantsInfo
} from "../../redux/slices/booksSlice";
import UserManager from "../../managers/UserManager";
import Utility from "../../utility/Utility";
import { ProductInitialState } from "./LineItem";

export interface CreateProductProps {
  onClose?: () => void;
  onSave?: (any) => void;
}

export default function CreateProduct(props: CreateProductProps) {
  const dispatch = useAppDispatch();
  const tenantsInfoData = useAppSelector(getTenantsInfo);
  const [tenantsInfo, setTenantsInfo] = useState({});
  useEffect(() => {
    dispatch(fetchTenantsInfo(UserManager.getUserTenantID()));
  }, []);

  useEffect(() => {
    if (!Utility.isEmptyObject(tenantsInfoData)) {
      setTenantsInfo(tenantsInfoData);
    }
  }, [tenantsInfoData]);

  const formFields = [
    {
      key: "product",
      title: "Name",
      type: INPUT_TYPE.TEXT,
      value: ""
    },
    {
      key: "description",
      title: "Description",
      type: INPUT_TYPE.LONG_TEXT,
      value: "",
      required: false
    },
    {
      key: "purchasePrice",
      title: "Purchase Price",
      type: INPUT_TYPE.NUMBER,
      value: "0"
    },
    {
      key: "salesPrice",
      title: "Sales Price",
      type: INPUT_TYPE.NUMBER,
      value: "0"
    }
  ];

  const getFormFields = () => {
    return (
      <DKInputForm
        autoFocus={true}
        title="Product Details"
        className="p-m1"
        fields={formFields}
        onSubmit={(data) => {
          onSaveForm(data);
        }}
        onCancel={() => props.onClose()}
        onChange={() => {}}
      />
    );
  };

  const onSaveForm = (data) => {
    let formRequest = { ...ProductInitialState };
    formRequest.name = data.find((value) => value.key === "product").value;
    formRequest.description = data.find(
      (value) => value.key === "description"
    ).value;
    formRequest.purchasePrice = Number(
      data.find((value) => value.key === "purchasePrice").value
    );
    formRequest.salesPrice = Number(
      data.find((value) => value.key === "salesPrice").value
    );
    formRequest.purchaseAccountCode = !Utility.isEmptyObject(tenantsInfo)
      ? tenantsInfo["inventorySettings"].inventoryPurchaseAccount
      : "";
    formRequest.salesAccountCode = !Utility.isEmptyObject(tenantsInfo)
      ? tenantsInfo["inventorySettings"].salesAccount
      : "";
    formRequest.salesReturnAccountCode = !Utility.isEmptyObject(tenantsInfo)
      ? tenantsInfo["inventorySettings"].salesAccount
      : "";
    formRequest.purchaseReturnAccountCode = !Utility.isEmptyObject(tenantsInfo)
      ? tenantsInfo["inventorySettings"].inventoryPurchaseAccount
      : "";
    formRequest.inventory.costOfGoodsSoldAccountCode = !Utility.isEmptyObject(
      tenantsInfo
    )
      ? tenantsInfo["inventorySettings"].costOfGoodsSoldAccount
      : "";
    formRequest.inventory.inventoryAccountCode = !Utility.isEmptyObject(
      tenantsInfo
    )
      ? tenantsInfo["inventorySettings"].inventoryAccount
      : "";
    formRequest.inventory.stockAdjustmentAccountCode = !Utility.isEmptyObject(
      tenantsInfo
    )
      ? tenantsInfo["inventorySettings"].stockAdjustmentAccount
      : "";
    formRequest.inventory.warehouseCode = !Utility.isEmptyObject(tenantsInfo)
      ? tenantsInfo["inventorySettings"].warehouse
      : "";
    props.onSave(formRequest);
  };

  return (
    <Popup
      popupWindowStyles={{
        overflowY: "visible"
      }}
    >
      {getFormFields()}
    </Popup>
  );
}
