import { Component } from "react";
import CompressedImagePicker from "../../common/CompressedImagePicker";

/*
PROPS
- data
- borderStyle
- showImagePopup
*/

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = { triggerImageDialog: false };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showImagePopup && !this.state.triggerImageDialog) {
      this.openImagePicker();
    }
  }

  /* ************* Render Utils *************  */
  render() {
    return (
      <>
        <img
          className={`unselectable ${this.props.className}`}
          src={this.props.data.value}
          alt=""
          style={{
            ...this.props.data.style,
            ...this.props.borderStyle,
            ...this.props.style,
            maxWidth: "100%"
          }}
          /* loading={"lazy"} */
          draggable={false}
          onDoubleClick={() => {
            if (!this.props.isEditing) {
              return;
            }
            this.openImagePicker();
          }}
        />
        {this.props.isEditing ? this.getImagePicker() : null}
      </>
    );
  }

  getImagePicker = () => {
    return this.props.isEditing ? (
      <CompressedImagePicker
        triggerImageDialog={this.state.triggerImageDialog}
        style={{
          display: "none"
        }}
        onSave={(url) => {
          let index = null,
            columnIndex = null,
            colComponentIndex = null;
          if (
            this.props.columnIndex !== null &&
            this.props.columnIndex !== undefined
          ) {
            if (this.props.colComponentIndex !== undefined) {
              index = this.props.componentIndex;
              columnIndex = this.props.columnIndex;
              colComponentIndex = this.props.colComponentIndex;
            } else {
              index = this.props.index;
              columnIndex = this.props.columnIndex;
              colComponentIndex = null;
            }
          } else {
            index = this.props.index;
          }
          this.props.onValueChange(url, index, columnIndex, colComponentIndex);
        }}
        onClose={this.onCloseImagePicker}
      />
    ) : null;
  };

  /* ************* Image picker Utils *************  */
  openImagePicker = () => {
    this.setState({ triggerImageDialog: true });
    this.props.onShowImagePopup?.();
  };

  onCloseImagePicker = () => {
    this.setState({ triggerImageDialog: false });
    this.props.onClosePopUp?.();
  };
}

export default Image;
