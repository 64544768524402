import {
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKRadioButton,
  DKSegmentControl,
  DKTooltipWrapper,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showAlert
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import { MODULES } from "../../constants/Constant";
import {
  DUPLICATE_CHECK_ACTIONS,
  DUPLICATE_CHECK_OPERATORS
} from "../../constants/Enum";
import { COLUMN_CODE, TableManger } from "../../managers/TableManger";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { updateTenantSettings } from "../../services/tenant";
import Utility from "../../utility/Utility";

interface IDuplicateSetting {
  objectType: string;
  systemColCodes: string[];
  customColIds: string[];
  logicalOperator: DUPLICATE_CHECK_OPERATORS;
  onDuplicate: DUPLICATE_CHECK_ACTIONS;
}

const DUPLICATE_CHECK_ENABLED_MODULES = [MODULES.CONTACT, MODULES.DEAL];
const MAX_ALLOWED_FIELDS = 4;
const DUPLICATE_CHECK_MODULE_INFO = {
  ACCOUNT:
    "Imposed for Accounts created or updated anywhere in CRM application i.e. via grid & import.",
  CONTACT:
    "Imposed for Contacts created or updated anywhere in CRM application i.e. via grid, import, marketing form, automation, indiamart & justdial leads.",
  DEAL: "Imposed for Deals created or updated anywhere in CRM application i.e. via grid, import and automation."
};

const DuplicateCheckSetting = () => {
  const crmSettings = useAppSelector(selectCRMSettings());
  const [allModuleSettings, setAllModuleSettings] = useState<
    IDuplicateSetting[]
  >(Utility.deepCloneObject(crmSettings?.uniqueColumns) || []);
  const [activeModule, setActiveModule] = useState(
    DUPLICATE_CHECK_ENABLED_MODULES[0]
  );
  const [saveDisabled, setSaveDisabled] = useState(false);
  const dispatch = useAppDispatch();

  // Auto-save changes when any change occurs
  useEffect(() => {
    onSaveSettings();
  }, [allModuleSettings]);

  // Function to get default duplicate setting data
  function getDefaultDuplicateSettingData(module: string) {
    return {
      objectType: module.toUpperCase(),
      systemColCodes: [],
      customColIds: [],
      logicalOperator: DUPLICATE_CHECK_OPERATORS.OR,
      onDuplicate: DUPLICATE_CHECK_ACTIONS.UPDATE
    };
  }

  // Function to get active module setting
  function getActiveModuleSetting() {
    let existingSetting = allModuleSettings?.find(
      (setting: IDuplicateSetting) => setting.objectType === activeModule
    );

    return existingSetting || getDefaultDuplicateSettingData(activeModule);
  }

  function getModuleFields() {
    const module = activeModule.toLowerCase();
    const fields =
      TableManger.getTableFilteredColumns(
        module,
        (column) => {
          return (
            [
              COLUMN_CODE.DEAL.CONTACT_ID,
              COLUMN_CODE.DEAL.AMOUNT,
              COLUMN_CODE.DEAL.PIPELINE_ID,
              COLUMN_CODE.DEAL.STAGE_ID
            ].includes(column.columnCode) ||
            ([INPUT_TYPE.TEXT, INPUT_TYPE.EMAIL, INPUT_TYPE.PHONE].includes(
              column.type
            ) &&
              ![COLUMN_CODE.CONTACT.ADDRESS, COLUMN_CODE.DEAL.REASON].includes(
                column.columnCode
              ) &&
              !column.hidden)
          );
        },
        false
      ) || [];
    return fields;
  }
  // Function to handle change in settings
  function onChange(key: string, value: any) {
    const updatedSettings = [...allModuleSettings];
    let settingToUpdate = updatedSettings?.find(
      (setting: IDuplicateSetting) => setting.objectType === activeModule
    );
    if (!settingToUpdate) {
      settingToUpdate = getDefaultDuplicateSettingData(activeModule);
      updatedSettings.push(settingToUpdate);
    }

    settingToUpdate[key] = value;
    setAllModuleSettings(updatedSettings);
  }

  // Function to save settings
  async function onSaveSettings() {
    try {
      setSaveDisabled(true);

      const dataToSave = allModuleSettings
        ?.filter((setting: IDuplicateSetting) =>
          DUPLICATE_CHECK_ENABLED_MODULES.includes(setting.objectType)
        )
        .map((setting: IDuplicateSetting) => ({
          ...setting,
          systemColCodes: setting.systemColCodes || [],
          customColIds: setting.customColIds || [],
          columnIds: undefined
        }));

      const payload = {
        uniqueColumns: dataToSave
      };
      const response = await updateTenantSettings(payload);

      if (response) {
        dispatch(fetchCRMSettings({}));
      }
    } catch (error) {
      console.error("Error while saving settings:", error);
    } finally {
      setSaveDisabled(false);
    }
  }

  const settings = getActiveModuleSetting();

  function getFieldSelector(settings: IDuplicateSetting) {
    const selectedIndices = [];
    const columns = getModuleFields();

    settings?.systemColCodes?.forEach((code: string) => {
      const index = columns.findIndex(
        (column: any) => column.columnCode === code
      );
      if (index !== -1) {
        selectedIndices.push(index);
      }
    });
    settings?.customColIds?.forEach((id: string) => {
      const index = columns.findIndex((column: any) => column.id === id);
      if (index !== -1) {
        selectedIndices.push(index);
      }
    });

    const MAX_VALUE_TO_DISPLAY = 4;
    return (
      <DKInput
        title={"Fields"}
        placeholder={"Select fields to check"}
        value={selectedIndices}
        renderer={(indices: number[]) => (
          <div className="row width-auto flex-wrap" style={{ gap: 5 }}>
            {(indices || [])
              .slice(0, MAX_VALUE_TO_DISPLAY)
              .map((index: number) => {
                return (
                  <DKLabel
                    className="bg-white border-radius-s p-h-xs shadow-s-2"
                    text={columns[index]?.name || ""}
                  />
                );
              })}
            {indices.length > MAX_VALUE_TO_DISPLAY && (
              <DKLabel
                className="text-app"
                text={`+ ${indices.length - MAX_VALUE_TO_DISPLAY} more`}
              />
            )}
          </div>
        )}
        type={INPUT_TYPE.DROPDOWN}
        required={false}
        displayKey={"name"}
        dropdownConfig={{
          data: columns,
          allowSearch: true,
          searchableKey: "name",
          multiSelect: true,
          checkMarkColor: "bg-button",
          onSelect: () => {},
          renderer: (index: number, option: any) => (
            <DKLabel text={option.name} />
          )
        }}
        className={`p-0`}
        direction={INPUT_VIEW_DIRECTION.VERTICAL}
        onChange={(newSelectedIndices: number[]) => {
          let newSelectedCodes = [],
            newSelectedIds = [];
          newSelectedIndices.forEach((index: number) => {
            const selectedColumn = columns[index];
            if (!selectedColumn) return;

            if (selectedColumn.systemField && selectedColumn.columnCode) {
              newSelectedCodes.push(selectedColumn.columnCode);
            } else {
              newSelectedIds.push(selectedColumn.id);
            }
          });

          if (
            newSelectedCodes.length + newSelectedIds.length >
            MAX_ALLOWED_FIELDS
          ) {
            showAlert(
              "Invalid value",
              `You can select upto ${MAX_ALLOWED_FIELDS} fields only for duplicate check.`
            );
            /* to Re-render */
            setAllModuleSettings([...allModuleSettings]);
            return;
          }

          onChange("systemColCodes", newSelectedCodes);
          onChange("customColIds", newSelectedIds);
        }}
        tooltip={{
          content: `Select fields to check for duplicate records. You can select upto ${MAX_ALLOWED_FIELDS} fields.`
        }}
      />
    );
  }

  function getSettingContent() {
    return (
      <div className="parent-size">
        {/* {getLeftPanel()} */}
        <div className="row justify-content-center z-index-1 m-v-l">
          <div style={{ width: 300 }}>
            <DKSegmentControl
              segments={DUPLICATE_CHECK_ENABLED_MODULES}
              selectedIndex={DUPLICATE_CHECK_ENABLED_MODULES.indexOf(
                activeModule
              )}
              onSelect={(index) =>
                setActiveModule(DUPLICATE_CHECK_ENABLED_MODULES[index])
              }
            />
          </div>
        </div>

        <div className="column parent-width">
          {getFieldSelector(settings)}
          {/* Fields operator */}
          <div className="column parent-width mt-l">
            <div className="row  mb-s">
              <DKLabel text="Match by" className="" />
              <DKTooltipWrapper content="In case of multiple fields selected, you can choose to find duplicate records by matching values for either All or Any field.">
                <DKIcon
                  src={DKIcons.ic_info}
                  className="ic-s opacity-5 ml-r pt-xs"
                />
              </DKTooltipWrapper>
            </div>
            <div className="row ">
              <DKRadioButton
                className="mr-l"
                color="bg-button"
                isSelected={
                  settings.logicalOperator === DUPLICATE_CHECK_OPERATORS.AND
                }
                onClick={() =>
                  onChange("logicalOperator", DUPLICATE_CHECK_OPERATORS.AND)
                }
                title="All"
              />
              <DKRadioButton
                className="mr-l"
                color="bg-button"
                isSelected={
                  settings.logicalOperator === DUPLICATE_CHECK_OPERATORS.OR
                }
                onClick={() =>
                  onChange("logicalOperator", DUPLICATE_CHECK_OPERATORS.OR)
                }
                title="Any"
              />
            </div>
          </div>
          {/* Duplicate record action */}
          <div className="column parent-width mt-l mb-l">
            <div className="row  mb-s">
              <DKLabel text="On found duplicate" className="" />
              <DKTooltipWrapper content="In case of new record found duplicate, you can choose to either Update existing record or Reject the new one.">
                <DKIcon
                  src={DKIcons.ic_info}
                  className="ic-s opacity-5 ml-r pt-xs"
                />
              </DKTooltipWrapper>
            </div>
            <div className="row ">
              <DKRadioButton
                className="mr-l"
                color="bg-button"
                isSelected={
                  settings.onDuplicate === DUPLICATE_CHECK_ACTIONS.UPDATE
                }
                onClick={() =>
                  onChange("onDuplicate", DUPLICATE_CHECK_ACTIONS.UPDATE)
                }
                title="Update"
              />
              <DKRadioButton
                className="mr-l"
                color="bg-button"
                isSelected={
                  settings.onDuplicate === DUPLICATE_CHECK_ACTIONS.REJECT
                }
                onClick={() =>
                  onChange("onDuplicate", DUPLICATE_CHECK_ACTIONS.REJECT)
                }
                title="Reject"
              />
            </div>
          </div>
          {/* Info Banner */}
          {DUPLICATE_CHECK_MODULE_INFO[activeModule] ? (
            <DKLabel
              text={DUPLICATE_CHECK_MODULE_INFO[activeModule]}
              className="mt-auto bg-deskera-secondary p-r border-radius-m border-m"
            />
          ) : null}
        </div>
      </div>
    );
  }

  return getSettingContent();
};

export default DuplicateCheckSetting;
