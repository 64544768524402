import { getRandomAlphaNumericString } from "../../../utility/Utility";
import { PAGE_ELEMENT } from "../../page_designer/ComponentList";
import ComponentManager from "../../page_designer/ComponentManager";
import ic_x from "../../../assets/social_icons/ic_twitter.png";

export default class Template6 {
  static getData() {
    return {
      metaData: null,
      scripts: null,
      hash: getRandomAlphaNumericString(),
      designElements: [
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625724272734_deskera-logo-black (1).png",
          style: {
            width: "28%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 30,
            marginBottom: 10,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "flex-start",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625724285367_Newsletter-6-Image1 (1).png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 26,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value:
            "Hi there, we are please to have you as one of our first users.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 38,
            color: "#000000",
            textAlign: "left",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 0,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 30,
            marginBottom: 18,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "flex-start",
            whiteSpace: "normal"
          }
        },
        {
          type: "Text",
          value:
            "We seriously couldn’t happier that you’re interested in what believe in. \n\nWe can’t wait to see you create, and I’m sure you’re going to make someone smile like crazy!",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 24,
            color: "#000000",
            textAlign: "left",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#ffffff",
            overflow: "hidden",
            alignSelf: "flex-start",
            whiteSpace: "normal"
          }
        },
        {
          type: "Collection",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Heartsy",
                fontSize: 24,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "Filter quickly by using our heartsy tool",
                  fontSize: 14,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625724302317_Newsletter-6-Image2 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "PawPaw",
                fontSize: 24,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "Book online with PawPaw",
                  fontSize: 14,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625724320397_Newsletter-6-Image3 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Foodeey",
                fontSize: 24,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "Foodeey is only for you",
                  fontSize: 14,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625724334534_Newsletter-6-Image4 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Delivery",
                fontSize: 24,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "We deliver, anywhere, everywhere",
                  fontSize: 14,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625724348526_Newsletter-6-Image5 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 24,
            marginBottom: 34,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 246,
            cellSpacing: 13,
            iconRadius: 0,
            titleColor: "#000000",
            titleSize: 24,
            descriptionColor: "#000000",
            descriptionSize: 14,
            gridSize: 39
          }
        },
        {
          type: "Text",
          value: "Start Creating Awwwesome Stuff",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 24,
            color: "#ffffff",
            textAlign: "center",
            fontWeight: "600",
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#0055c6",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Text",
          value:
            '<span style="font-size:33px; font-weight: bold">We’d love to hear from you!</span> \nHelp us improve by sharing your feedback in this short survey.',
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 16,
            color: "#000000",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 36,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 34,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#f5f5f5",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Social Buttons",
          value: [
            {
              isRequired: true,
              title: "Facebook",
              url: "",
              icon: "https://i.ibb.co/FX4mz7J/ic-facebook.png"
            },
            {
              isRequired: true,
              title: "Instagram",
              url: "",
              icon: "https://i.ibb.co/VNvh8BL/ic-instagram.png"
            },
            {
              isRequired: true,
              title: "Twitter",
              url: "",
              icon: "https://i.ibb.co/qR8Q8PV/ic-twitter.png"
            },
            {
              isRequired: false,
              title: "LinkedIn",
              url: "",
              icon: "https://i.ibb.co/BzLpvD2/linkedin-2.png"
            },
            {
              isRequired: false,
              title: "Youtube",
              url: "",
              icon: "https://i.ibb.co/tJ6rf4x/youtube.png"
            },
            {
              isRequired: false,
              title: "WhatsApp",
              url: "",
              icon: "https://i.ibb.co/nn931VS/whatsapp-2.png"
            }
          ],
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#f5f5f5",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 30,
            cellSpacing: 12
          }
        },
        {
          type: "Text",
          value: "© 2021, All Rights Reserved",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#000000",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 12,
            paddingBottom: 36,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#f5f5f5",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        }
      ]
    };
  }
}
