import React, { Component } from "react";
import ApiManager from "../../../managers/ApiManager";
import { showToast, TOAST_TYPE, showAlert, DKLabel } from "deskera-ui-library";
import {
  DEFAULT_IMAGE_SIZE_LIMIT,
  DEFAULT_QUALITY_FACTOR,
  FORCE_COMPRESS_QUALITY_FACTOR
} from "../../common/CompressedImagePicker";

/*
PROPS
- data
- borderStyle
*/

export default class ImageGallery extends Component {
  inputOpenFileRef;
  stopper;
  constructor(props) {
    super(props);
    this.inputOpenFileRef = React.createRef();
    this.state = {
      selectedImageIndex: 0,
      bannerImageIndex: 0,
      progresBarValue: 0
    };
  }

  // componentDidMount() {
  //   this.autoSlide()
  // }

  render() {
    return (
      <div
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle,
          // width: this.props.data.style.width,
          // height: this.props.data.style.height + 150,
          overflow: "hidden"
        }}
        className="image-gallery"
        id={`image-gallery-${this.props.index}`}
        data-timer={this.props.data.style.sildeTime}
      >
        {this.props.data.value.length !== 0 && (
          <div style={{ display: "contents", width: "100%" }}>
            {this.getBannerImage(this.props.data.value, this.props.data.style)}
          </div>
        )}
        {this.props.data.value.length !== 0 && (
          <div
            style={{ marginTop: 20, overflowX: "auto", whiteSpace: "nowrap" }}
            id="image-scroll"
          >
            {this.getThumbnailImages(
              this.props.data.value,
              this.props.data.style
            )}
          </div>
        )}
        {this.props.data.value.length === 0 && this.getBlankCanvas()}
        {this.getImagePicker()}
      </div>
    );
  }

  getBannerImage(item, style) {
    if (item.length === 0) {
      return;
    }
    let data = item[this.state.bannerImageIndex];
    if (data === undefined) {
      this.setState({ bannerImageIndex: 0 }, () => {
        return this.getBanner(
          this.state.bannerImageIndex,
          item[this.state.bannerImageIndex],
          style
        );
      });
    } else {
      return this.getBanner(
        this.state.bannerImageIndex,
        item[this.state.bannerImageIndex],
        style
      );
    }
  }

  getBanner(index, data, style) {
    return (
      <>
        <div
          key={index}
          className="banner-image-list"
          style={{
            width: "100%",
            display: "flex",
            height: "auto"
          }}
        >
          {/* <progress style={{ width: "100%", height: 6 }} value={this.state.progresBarValue} max="100">32%</progress> */}
          <img
            src={data.image}
            alt="BannerImage"
            style={{
              width: "100%",
              borderRadius: style.borderRadius,
              position: "relative",
              height: "100%",
              display: "block"
            }}
            draggable={false}
            onDoubleClick={() => this.onDoubleClickImage(index)}
            // onMouseOver={() => {
            //   clearTimeout(this.stopper);
            // }}
            // onMouseLeave={() => {
            //   this.autoSlide()
            // }}
          />
        </div>
      </>
    );
  }

  getThumbnailImages(items, style) {
    let arr = [];
    items.forEach((item, index) => {
      arr.push(this.getThumbnailImage(index, item, style));
    });
    return arr;
  }

  getThumbnailImage(index, data, style) {
    return (
      <div
        key={index}
        className={
          this.state.bannerImageIndex === index
            ? "thumbnail-image-list gallery-active"
            : "thumbnail-image-list"
        }
        id={`thumbnail-${this.props.index}-image-${index}`}
        style={{
          margin: 6,
          display: "inline-block",
          cursor: "pointer"
        }}
        onClick={() => {
          clearTimeout(this.stopper);
          this.setState(
            { bannerImageIndex: index, progresBarValue: 0 }
            // () => {
            // this.autoSlide()
            // }
          );
        }}
      >
        <img
          src={data.image}
          className="thumbnail-image"
          alt="ImageGallery"
          style={{
            width: "auto",
            height: style.thumbnailHeight,
            borderRadius: 4,
            border:
              this.state.bannerImageIndex === index
                ? "1.6px solid rgb(80, 80, 80) "
                : "1.6px solid rgba(80, 80, 80, 0)",
            opacity: this.state.bannerImageIndex === index ? 1 : 0.8
          }}
          draggable={false}
          onDoubleClick={() => this.onDoubleClickImage(index)}
        />
      </div>
    );
  }

  getImagePicker = () => {
    // return (
    //   <CompressedImagePicker
    //     triggerImageDialog={this.state.triggerImageDialog}
    //     onSave={(url) => this.props.onValueChange(url, this.state.selectedImageIndex)}
    //     onClose={this.onCloseImagePicker}
    //     style={{ display: "none" }}
    //   />
    // );
    return (
      <input
        type="file"
        accept="image/*"
        ref={this.inputOpenFileRef}
        style={{ display: "none" }}
        onChange={(e) => this.getCompressedImage(e.target.files)}
      />
    );
  };
  onDoubleClickImage = (index) => {
    this.setState({ selectedImageIndex: index });
    this.inputOpenFileRef.current.click();
  };

  /* ******************  TODO ************************
   Need to Replace these utilities with CompressedImagePicker
   As Image Gallery not working currently using CompressedImagePicker component
  */
  getCompressedImage = (
    files,
    qualityFactor = DEFAULT_QUALITY_FACTOR,
    isForceCompress = false
  ) => {
    const imageData = files?.length ? files[0] : null;

    if (!imageData) return;

    import("compressorjs").then((Compressor) => {
      new Compressor.default(imageData, {
        quality: qualityFactor,
        ...(isForceCompress ? { maxHeight: 900, maxWidth: 1600 } : {}),
        success: (compressedResult) => {
          if (this.isImageSizeExceeded(compressedResult)) {
            return;
          } else {
            this.uploadImageToAWS(compressedResult);
          }
        },
        error: (err) => {
          isForceCompress &&
            showToast("Image optimization failed, please try again!");
          if (!this.isImageSizeExceeded(imageData)) {
            this.uploadImageToAWS(imageData);
          }
        }
      });
    });
  };

  isImageSizeExceeded = (imageData) => {
    const fileSize = imageData?.size ? imageData.size / 1024 / 1024 : 0;

    if (fileSize < DEFAULT_IMAGE_SIZE_LIMIT) return false;

    const actionButtons = [
      {
        title: "Optimize & upload",
        className: "bg-button text-white",
        onClick: () =>
          this.getCompressedImage(
            [imageData],
            FORCE_COMPRESS_QUALITY_FACTOR,
            true
          )
      },
      {
        title: "Choose another",
        className: "bg-gray1",
        onClick: () => {
          this.onDoubleClickImage(this.state.selectedImageIndex);
        }
      }
    ];

    showAlert(
      "File size exceeded!",
      "Looks like image size is more than 1 MB. Please optimize or choose a different file.",
      actionButtons
    );

    return true;
  };

  uploadImageToAWS(imageData) {
    ApiManager.getUploadFileURL(
      imageData,
      (url) => {
        this.props.onValueChange(url, this.state.selectedImageIndex);
      },
      (err) => {
        showToast("Image upload failed", TOAST_TYPE.FAILURE);
      }
    );
  }

  // autoSlide = () => {
  //   this.stopper = setTimeout(this.autoSlide, 100);
  //   if (this.state.progresBarValue === 100) {
  //     let index = 0
  //     if (this.props.data.value.length - 1 === this.state.bannerImageIndex) {
  //       index = 0
  //     } else {
  //       index = this.state.bannerImageIndex + 1
  //     }
  //     clearTimeout(this.stopper);
  //     this.setState({ progresBarValue: 0, bannerImageIndex: index }, () => {
  //       this.autoSlide()
  //     })
  //   }
  //   this.setState({ progresBarValue: this.state.progresBarValue + 1 })
  // }

  ///////////////////////////////////////////////////////////////////////////////////////

  getBlankCanvas = () => {
    return (
      <div
        className="column p-l parent-width justify-content-center align-items-center"
        style={{ border: "1px dashed #80808094" }}
      >
        <DKLabel text="Please add image" className="text-gray" />
      </div>
    );
  };
}
