import {
  DKLabel,
  DKButton,
  DKInput,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  DKTooltipWrapper,
  DKIcon,
  DKIcons,
  DKLine,
  DKDataGrid,
  DKSpinner,
  showAlert,
  removeLoader,
  Toggle
} from "deskera-ui-library";
import { isBefore, isEqual, startOfDay } from "date-fns";
import { useEffect, useRef, useState } from "react";
import {
  DATE_FORMATS,
  DateUtil,
  convertCRMDateFormatToUILibraryFormat
} from "../../utility/Date";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCurrencies,
  getCurrencies,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import Utility, {
  getCapitalized,
  getRandomNumber,
  redirectToBooksPlus
} from "../../utility/Utility";
import { COUNTRIES_WITH_CURRENCIES } from "../../constants/Currencies";
import {
  DEFAULT_PRICE_BOOK_CURRENCY,
  MARK_AS_DEFAULT_ERROR_MESSAGE,
  PRICE_BOOK_ADDRESS,
  PRODUCT_TRANSACTION_TYPE_PURCHASE,
  PRODUCT_TRANSACTION_TYPE_SALES,
  REGEX
} from "../../constants/Constant";
import BooksService from "../../services/books";
import { PRICE_BOOK_COLUMN_CONFIG } from "./PriceBookColumnConfig";
import { PriceBookManager } from "./PriceBookManager";
import {
  fetchBooksProducts,
  getTenantsDetails,
  getTenantsInfo,
  selectUOMs
} from "../../redux/slices/booksSlice";
import CreateProduct from "../books/CreateProduct";
import { initialRowData } from "../../model/PriceBook";
import {
  COUNTRY_NAME,
  INDIAN_STATES_MOCK_DATA,
  US_STATES_MOCK_DATA
} from "../../constants/CountriesAndStates";
import { TABLES, TableManger } from "../../managers/TableManger";
import DataParser from "../../Helper/DataParser";
import PriceBook, {
  createPriceBookVersion,
  deletePriceBook,
  getProductsById,
  updatePriceBook
} from "../../services/priceBook";
import {
  fetchPriceBookVersions,
  priceBookVersionsSelector
} from "../../redux/slices/priceBookSlice";
import debounce from "../../utility/Debounce";

export interface IAddPriceBookProps {
  priceBookToEdit?: any;
  onSave?: () => void;
  onCancel?: () => void;
}
const initialAddress = {
  address1: null,
  address2: null,
  city: null,
  country: null,
  customFields: [],
  postalCode: null,
  state: null
};
const types = [
  { id: "1", name: "BUY" },
  { id: "2", name: "SELL" }
];
const TABS = {
  CREATE: "create",
  RULES: "rules"
};
const APP_NAME = "CRM_PLUS";

const ADDRESS_FIELDS = [
  {
    name: "Shipping Address 1",
    id: PRICE_BOOK_ADDRESS.SHIPPING_ADDRESS_1,
    type: INPUT_TYPE.TEXT,
    value: "",
    systemField: true
  },
  {
    name: "Shipping Address 2",
    id: PRICE_BOOK_ADDRESS.SHIPPING_ADDRESS_2,
    type: INPUT_TYPE.TEXT,
    value: "",
    systemField: true
  },
  {
    name: "Shipping Country",
    id: PRICE_BOOK_ADDRESS.SHIPPING_COUNTRY,
    type: INPUT_TYPE.DROPDOWN,
    value: "",
    systemField: true
  },
  {
    name: "Shipping State",
    id: PRICE_BOOK_ADDRESS.SHIPPING_STATE,
    type: INPUT_TYPE.TEXT,
    value: "",
    systemField: true
  },
  {
    name: "Shipping City",
    id: PRICE_BOOK_ADDRESS.SHIPPING_CITY,
    type: INPUT_TYPE.TEXT,
    value: "",
    systemField: true
  },
  {
    name: "Billing Address 1",
    id: PRICE_BOOK_ADDRESS.BILLING_ADDRESS_1,
    type: INPUT_TYPE.TEXT,
    value: "",
    systemField: true
  },
  {
    name: "Billing Address 2",
    id: PRICE_BOOK_ADDRESS.BILLING_ADDRESS_2,
    type: INPUT_TYPE.TEXT,
    value: "",
    systemField: true
  },
  {
    name: "Billing Country",
    id: PRICE_BOOK_ADDRESS.BILLING_COUNTRY,
    type: INPUT_TYPE.DROPDOWN,
    value: "",
    systemField: true
  },
  {
    name: "Billing State",
    id: PRICE_BOOK_ADDRESS.BILLING_STATE,
    type: INPUT_TYPE.TEXT,
    value: "",
    systemField: true
  },
  {
    name: "Billing City",
    id: PRICE_BOOK_ADDRESS.BILLING_CITY,
    type: INPUT_TYPE.TEXT,
    value: "",
    systemField: true
  }
];

const initialFormState = {
  name: "",
  version: {},
  type: types?.[1],
  currency:
    COUNTRIES_WITH_CURRENCIES?.find(
      ({ countryCode }) =>
        countryCode === BooksService.getTenantCurrency() ??
        DEFAULT_PRICE_BOOK_CURRENCY
    ) || [],
  effectiveDate: startOfDay(new Date()),
  productRow: [],
  isDefault: false,
  customFields: [],
  billingAddress: Utility.deepCloneObject(initialAddress),
  shippingAddress: Utility.deepCloneObject(initialAddress)
};
export function AddPriceBook(props: IAddPriceBookProps) {
  const dispatch = useAppDispatch();
  const allCurrencies = useAppSelector(getCurrencies());
  let products = useRef(null);

  const tenantInfo: any = useAppSelector(getTenantsInfo);
  const tenantDetail: any = useAppSelector(getTenantsDetails);
  const uoms = useAppSelector(selectUOMs);
  const globalCrmSettings = useAppSelector(selectCRMSettings());
  const priceBookVersions = useAppSelector(priceBookVersionsSelector);

  const [formFields, setFormFields] = useState(
    Utility.deepCloneObject(initialFormState)
  );
  const [columnConfig, setColumnConfig] = useState(PRICE_BOOK_COLUMN_CONFIG);
  const [activeTab, setActiveTab] = useState(TABS.CREATE);
  const [showProductPopup, setsShowProductPopup] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [rulesForConditions, setRulesForCondition] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState<any>(
    props?.priceBookToEdit
  );
  const [showLoader, setShowLoader] = useState<any>(false);

  const isAPICallInProgress = useRef<boolean>(false);

  const [formHasError, setFormHasError] = useState(null);
  const isEditMode = !Utility.isEmptyObject(props.priceBookToEdit);
  const query = `active=true,hasVariant=false`;
  const params: any = { query, productTransactionType: "BOTH,SALES" };
  const [searchText, setSearchText] = useState('');
  const [sortConfig, setSortConfig] = useState({ sort: null, sortDir: null });
  const [pageNo, setPageNo] = useState(0);
  const priceListItems = useRef(!Utility.isEmptyObject(selectedVersion)
  ? selectedVersion?.priceListItems
  : props.priceBookToEdit?.priceListItems || [])

  useEffect(() => {
    fetchAllCurrencies();
    // fetchProducts();
    if (isEditMode) {
      setShowLoader(true);
      populateFormStateInEditCase();
      dispatch(fetchPriceBookVersions(props.priceBookToEdit?.id));
    } else {
      let copyOfFormFields = { ...formFields };
      copyOfFormFields.productRow.push(getEmptyRow());
      copyOfFormFields.currency = COUNTRIES_WITH_CURRENCIES?.find(
        ({ countryCode }) =>
          countryCode ===
          (BooksService.getTenantCurrency() || DEFAULT_PRICE_BOOK_CURRENCY)
      );
      copyOfFormFields.customFields = getSelectedModuleCustomFields();
      setFormFields(copyOfFormFields);
    }
  }, []);
  const fetchProductsByPriceBookId =  () => {
    return BooksService.getProductsByPriceBookId(
      {
        ...params,
        search: searchText,
        sort: sortConfig.sort,
        page: pageNo ?? 0,
        limit: 10,
        sortDir: sortConfig.sortDir,
      },
      selectedVersion?.id || props.priceBookToEdit?.id
    );
 }

  useEffect(() => {
    const updatedFields = { ...formFields };
    if (!Utility.isEmptyObject(allCurrencies)) {
      if (Utility.isEmptyObject(updatedFields?.currency)) {
        let currencyToFind = !Utility.isEmptyObject(
          BooksService.getTenantCurrency()
        )
          ? BooksService.getTenantCurrency()
          : DEFAULT_PRICE_BOOK_CURRENCY;
        if (isEditMode) {
          currencyToFind = props.priceBookToEdit.currencies[0];
        }
        updatedFields.currency = allCurrencies?.find(
          ({ currencyCode }) => currencyCode === currencyToFind
        );
        setFormFields({ ...updatedFields });
      }
    }
  }, [allCurrencies]);

  useEffect(() => {
    if (!Utility.isEmptyObject(formFields.currency)) {
      updateColumnConfig();
    }
  }, [formFields]);

  useEffect(() => {
    if (!Utility.isEmptyObject(selectedVersion)) {
      populateFormStateInEditCase(selectedVersion);
    }
  }, [selectedVersion, searchText, sortConfig, pageNo]);

  const populateFormStateInEditCase = async (version = {}) => {
    products.current = await fetchProductsByPriceBookId();
    const dataToEdit = !Utility.isEmptyObject(version)
      ? version
      : props.priceBookToEdit;
    let formFieldsToUpdate = { ...formFields };
    const {
      type = "",
      name,
      effectiveDate = new Date(),
      selectionCriteria,
      priceListItems,
      id,
      isDefault,
      currencies,
      active
    } = dataToEdit;
    const { contact } = selectionCriteria
      ? selectionCriteria
      : {
          contact: { billingAddress: {}, shippingAddress: {}, customField: {} }
        };
    // Create Tab Fields
    formFieldsToUpdate.id = id;
    formFieldsToUpdate.name = name ?? "";
    formFieldsToUpdate.type = types?.find((ty) => ty.name === type);
    formFieldsToUpdate.isDefault = isDefault;
    formFieldsToUpdate.active = active;
    formFieldsToUpdate.effectiveDate = DateUtil.getDateFromStr(
      effectiveDate,
      DATE_FORMATS["DD-MM-YYYY"]
    );
    formFieldsToUpdate.currency = !Utility.isEmptyObject(allCurrencies)
      ? allCurrencies?.find(
          ({ currencyCode }) => currencyCode === currencies?.[0]
        )
      : [];

    // Create Tab - Product fields
    let unsavedProduct = formFieldsToUpdate?.productRow.filter(row=> row.isNewProduct);
    
    let productRows = [];
    products.current?.content?.forEach((product: any) => {
      let productDetails = null;
      let listItem = null;
      const matchedProducts = [];
        priceListItems?.forEach(
          (item) => {
            if (item.productId === product.productId) {
              matchedProducts.push({...product,...item});
            }
          }
      );
      matchedProducts?.forEach(item => {
        const uomList = getUOMList(item);
        const selectedUOM = uomList?.find(({ id }) => id === item.uomId);
        const [currency] = item?.currencyPrice;
        productRows.push({
          ...item,
          id:item.id,
          product: item,
          uomId: selectedUOM,
          currency: currency?.price ?? 0,
          documentSequenceCode: item?.documentSequenceCode,
          invalidFields: []
        });
      });
      })
    formFieldsToUpdate["productRow"] = [...productRows,...unsavedProduct];

    const { billingAddress, shippingAddress, customField } = contact;
    // Rules Tab - Custom fields
    const savedCustomFields = [];
    let contactSelectTypeCustomFields = getSelectedModuleCustomFields();
    contactSelectTypeCustomFields?.forEach((cfColumn: any) => {
      const cfValue = customField?.[cfColumn.id];
      const cfValueIndex = cfColumn?.options.findIndex(
        (option) => option.name === cfValue
      );
      if (cfValueIndex !== -1) {
        savedCustomFields.push({ ...cfColumn, value: [cfValueIndex] });
      }
    });
    const getBlankAddressObject = (key) => {
      return ADDRESS_FIELDS.find((field) => field.id === key);
    };
    const addressFields = [];
    if (!Utility.isEmptyObject(billingAddress)) {
      let countryValue = billingAddress["country"];
      let stateValue = billingAddress["state"];

      Object.keys(billingAddress).forEach((key) => {
        switch (key) {
          case "address1":
            const billingAddress1 = getBlankAddressObject(
              PRICE_BOOK_ADDRESS.BILLING_ADDRESS_1
            );
            addressFields.push({
              ...billingAddress1,
              value: billingAddress["address1"]
            });
            break;
          case "address2":
            const billingAddress2 = getBlankAddressObject(
              PRICE_BOOK_ADDRESS.BILLING_ADDRESS_2
            );
            addressFields.push({
              ...billingAddress2,
              value: billingAddress["address2"]
            });
            break;
          case "country":
            const billingCountry = getBlankAddressObject(
              PRICE_BOOK_ADDRESS.BILLING_COUNTRY
            );
            countryValue = COUNTRIES_WITH_CURRENCIES.find(
              (country) => country.country === countryValue
            );
            addressFields.push({ ...billingCountry, value: countryValue });
            break;
          case "state":
            const billingState = getBlankAddressObject(
              PRICE_BOOK_ADDRESS.BILLING_STATE
            );
            if (countryValue === COUNTRY_NAME.INDIA) {
              stateValue = INDIAN_STATES_MOCK_DATA.find(
                (state) => state.name === stateValue
              );
            }
            if (countryValue === COUNTRY_NAME.USA) {
              stateValue = US_STATES_MOCK_DATA.find(
                (state) => state.name === stateValue
              );
            }
            addressFields.push({ ...billingState, value: stateValue });
            break;
          case "city":
            const billingCity = getBlankAddressObject(
              PRICE_BOOK_ADDRESS.BILLING_CITY
            );
            let city = billingAddress["city"];
            addressFields.push({ ...billingCity, value: city });
            break;
          default:
            break;
        }
      });
    }

    if (!Utility.isEmptyObject(shippingAddress)) {
      let countryValue = shippingAddress["country"];
      let stateValue = shippingAddress["state"];

      Object.keys(shippingAddress).forEach((key) => {
        switch (key) {
          case "address1":
            const shippingAddress1 = getBlankAddressObject(
              PRICE_BOOK_ADDRESS.SHIPPING_ADDRESS_1
            );
            addressFields.push({
              ...shippingAddress1,
              value: shippingAddress["address1"]
            });
            break;
          case "address2":
            const shippingAddress2 = getBlankAddressObject(
              PRICE_BOOK_ADDRESS.SHIPPING_ADDRESS_2
            );
            addressFields.push({
              ...shippingAddress2,
              value: shippingAddress["address2"]
            });
            break;
          case "country":
            const shippingCountry = getBlankAddressObject(
              PRICE_BOOK_ADDRESS.SHIPPING_COUNTRY
            );
            countryValue = COUNTRIES_WITH_CURRENCIES.find(
              (country) => country.country === countryValue
            );
            addressFields.push({ ...shippingCountry, value: countryValue });
            break;
          case "state":
            const shippingState = getBlankAddressObject(
              PRICE_BOOK_ADDRESS.SHIPPING_STATE
            );
            if (countryValue === COUNTRY_NAME.INDIA) {
              stateValue = INDIAN_STATES_MOCK_DATA.find(
                (state) => state.name === stateValue
              );
            }
            if (countryValue === COUNTRY_NAME.USA) {
              stateValue = US_STATES_MOCK_DATA.find(
                (state) => state.name === stateValue
              );
            }
            addressFields.push({ ...shippingState, value: stateValue });
            break;
          case "city":
            const shippingCity = getBlankAddressObject(
              PRICE_BOOK_ADDRESS.SHIPPING_CITY
            );
            let city = shippingAddress["city"];
            addressFields.push({ ...shippingCity, value: city });
            break;
          default:
            break;
        }
      });
    }
    if (formFieldsToUpdate.isDefault) {
      setActiveTab(TABS.CREATE);
    }

    setRulesForCondition([...savedCustomFields, ...addressFields]);
    setFormFields(formFieldsToUpdate);
    setShowLoader(false);
  };

  const getSelectedModuleCustomFields = (): any[] => {
    let module = !Utility.isEmptyObject(
      globalCrmSettings?.priceBookFilterOnObjectType
    )
      ? globalCrmSettings?.priceBookFilterOnObjectType.toLowerCase()
      : TABLES.CONTACT;
    const moduleSelectTypeCustomFields = TableManger.getTableColumns(
      module
    )?.filter(
      (column) => !column.systemField && column.type === INPUT_TYPE.SELECT
    );
    return Utility.deepCloneObject(moduleSelectTypeCustomFields ?? []);
  };

  const fetchAllCurrencies = () => {
    if (Utility.isEmptyObject(allCurrencies)) {
      dispatch(fetchCurrencies());
    }
  };

  const fetchProducts = () => {
    // if (Utility.isEmptyObject(products?.content)) {
    //   dispatch(fetchBooksProducts({}));
    // }
  };

  const updateColumnConfig = async (uomList?: any) => {
    const newColumnConfig = [...columnConfig];
    newColumnConfig?.forEach((column) => {
      switch (column.key) {
        case "product":
          // column.dropdownConfig.data = productDataArray?.content || [];
          column.dropdownConfig.button.onClick = () => redirectToBooksPlus();
          column.dropdownConfig.searchApiConfig.getUrl = (search: any) =>
            PriceBookManager.getProductURL(
              search,
              tenantInfo,
              formFields.type?.name === "SELL"
                ? PRODUCT_TRANSACTION_TYPE_SALES
                : PRODUCT_TRANSACTION_TYPE_PURCHASE
            );
          break;
        case "uomId":
          column.dropdownConfig.data = uomList?.length ? uomList : [];
          break;
        case "discount":
          column.renderer = ({ rowData }: any) => {
            const { discountInPercent = false, discount = 0 } = rowData;

            return (
              <div className="column parent-width align-items-end">
                <DKLabel
                  className="fs-m"
                  text={
                    discountInPercent
                      ? PriceBookManager.getNumber(discount) + "%"
                      : PriceBookManager.getNumber(discount)
                  }
                />
              </div>
            );
          };
          break;
        case "currency":
          column.name =
            formFields?.currency?.currencyCode ?? DEFAULT_PRICE_BOOK_CURRENCY;
          column.renderer = ({ value }) => {
            return (
              <div className="column parent-width align-items-end">
                <DKLabel
                  className="fs-m"
                  text={PriceBookManager.getNumber(value)}
                />
              </div>
            );
          };
          break;
        default:
          break;
      }
    });
    setColumnConfig(newColumnConfig.filter((column) => !column.hidden));
  };

  const getUOMList = (product: any) => {
    if (product) {
      let uomList = [];
      let filteredUOMList: any[] = [];
      if (product.stockUom) {
        uomList.push(product.stockUom);
      }
      let additionalUom =
        product.uomSchemaDto &&
        product.uomSchemaDto.uomSchemaDefinitions &&
        product.uomSchemaDto.uomSchemaDefinitions.map(
          (uom: any) => uom.sinkUOM
        );
      if (additionalUom && additionalUom.length > 0) {
        uomList = uomList.concat(additionalUom);
      }
      if (uomList.length > 0) {
        uomList?.forEach((uom: any) => {
          let detail = uoms.filter((data: any) => uom === data.id);
          if (detail.length > 0) {
            filteredUOMList.push(detail[0]);
          }
        });
      }

      return filteredUOMList;
    }
    return [];
  };

  const updateFormFields = (key: string, value: any, isCustomField = false) => {
    let fields = { ...formFields };
    if (isCustomField) {
      let customFields = [...fields.customFields];
      let index = fields.customFields.findIndex((cf) => cf.key === key);
      customFields[index].value = value;
      fields.customFields = customFields;
    } else {
      switch (key) {
        case PRICE_BOOK_ADDRESS.SHIPPING_ADDRESS_1:
          fields.shippingAddress["address1"] = value;
          break;
        case PRICE_BOOK_ADDRESS.SHIPPING_ADDRESS_2:
          fields.shippingAddress["address2"] = value;
          break;
        case PRICE_BOOK_ADDRESS.SHIPPING_COUNTRY:
          fields.shippingAddress["country"] = value;
          break;
        case PRICE_BOOK_ADDRESS.SHIPPING_STATE:
          fields.shippingAddress["state"] = value;
          break;
        case PRICE_BOOK_ADDRESS.SHIPPING_CITY:
          fields.shippingAddress["city"] = value;
          break;
        case PRICE_BOOK_ADDRESS.BILLING_ADDRESS_1:
          fields.billingAddress["address1"] = value;
          break;
        case PRICE_BOOK_ADDRESS.BILLING_ADDRESS_2:
          fields.billingAddress["address2"] = value;
          break;
        case PRICE_BOOK_ADDRESS.BILLING_COUNTRY:
          fields.billingAddress["country"] = value;
          break;
        case PRICE_BOOK_ADDRESS.BILLING_STATE:
          fields.billingAddress["state"] = value;
          break;
        case PRICE_BOOK_ADDRESS.BILLING_CITY:
          fields.billingAddress["city"] = value;
          break;
        default:
          fields[key] = value;
          break;
      }
    }
    setFormFields(fields);
  };

  const preparePayload = () => {
    const formStateToSend = Utility.deepCloneObject(formFields);
    const filteredCustomField = {};
    let rules = [...rulesForConditions];
    let customFields = rules?.filter((cf) => !cf?.systemField);
    customFields
      ?.filter((cf) => !cf?.systemField)
      ?.forEach((customField) => {
        filteredCustomField[customField.key] =
          customField?.options[customField?.value?.[0]]?.name;
      });

    let shippingAddress: any = {};
    let billingAddress: any = {};
    rules.forEach((rule) => {
      switch (rule.id) {
        case PRICE_BOOK_ADDRESS.SHIPPING_ADDRESS_1:
          shippingAddress.address1 = rule.value;
          break;
        case PRICE_BOOK_ADDRESS.SHIPPING_ADDRESS_2:
          shippingAddress.address2 = rule.value;
          break;
        case PRICE_BOOK_ADDRESS.SHIPPING_COUNTRY:
          shippingAddress.country = rule.value?.country;
          break;
        case PRICE_BOOK_ADDRESS.SHIPPING_STATE:
          shippingAddress.state = !Utility.isEmptyObject(rule?.value)
            ? typeof rule?.value === "object"
              ? rule.value.name
              : rule.value
            : null;
          break;
        case PRICE_BOOK_ADDRESS.SHIPPING_CITY:
          shippingAddress.city = rule.value;
          break;
        case PRICE_BOOK_ADDRESS.BILLING_ADDRESS_1:
          billingAddress.address1 = rule.value;
          break;
        case PRICE_BOOK_ADDRESS.BILLING_ADDRESS_2:
          billingAddress.address2 = rule.value;
          break;
        case PRICE_BOOK_ADDRESS.BILLING_COUNTRY:
          billingAddress.country = rule.value?.country;
          break;
        case PRICE_BOOK_ADDRESS.BILLING_STATE:
          billingAddress.state = !Utility.isEmptyObject(rule?.value)
            ? typeof rule?.value === "object"
              ? rule.value.name
              : rule.value
            : null;
          break;
        case PRICE_BOOK_ADDRESS.BILLING_CITY:
          billingAddress.city = rule.value;
          break;

        default:
          break;
      }
    });
    const dataToSend = {
      appName: APP_NAME,
      active: formStateToSend?.active,
      name: formStateToSend?.name,
      type: formStateToSend?.type?.name,
      currencies: [formStateToSend?.currency?.currencyCode],
      effectiveDate: DataParser.formatDate(
        new Date(formStateToSend?.effectiveDate)
      ),
      isDefault: formStateToSend?.isDefault || false,
      isAdvance: true,
      priceListItems: priceListItems?.current?.map((product) => ({
        currencyPrice: [
          {
            currency: formStateToSend?.currency?.currencyCode,
            price: product?.currency || product.currencyPrice?.[0]?.price || 0
          }
        ],
        productId: product?.productId || product?.product?.productId,
        uomId: product?.uomId?.id || product?.uomId,
        minimumQuantity: product?.minimumQuantity,
        discountInPercent: product?.discountInPercent,
        discount: product?.discount || 0
      })),
      selectionCriteria: {
        contact: !formStateToSend?.isDefault
          ? {
              billingAddress: billingAddress,
              shippingAddress: shippingAddress,
              customField: filteredCustomField
            }
          : {}
      }
    };
    return JSON.parse(JSON.stringify(dataToSend), (key, value) =>
      value === null || value === "" ? undefined : value
    );
  };

  const checkForErrors = (fieldName) => {
    const booksBeginningDate = tenantDetail?.bookBeginningStartDate;
    let beginningDate = new Date(booksBeginningDate);
    const effectiveDate = new Date(formFields.effectiveDate);
    beginningDate.setHours(0, 0, 0, 0);

    let hasError = false;
    switch (fieldName) {
      case "name":
        hasError = Utility.isEmptyObject(formFields.name.replace(/\s+/, ""));
        break;
      case "effectiveDate":
        hasError = isBefore(effectiveDate, beginningDate);
        break;
      case "currency":
        hasError = Utility.isEmptyObject(formFields.currency);
        break;
      default:
        break;
    }
    return hasError;
  };

  const errorMessageHandling = (dataToSend, error) => {
    const errorMessageString = error?.response?.data?.errorMessage;
    const markAsDefaultErrorMessage = errorMessageString.includes(
      MARK_AS_DEFAULT_ERROR_MESSAGE
    );
    const params = { overwrite: true };
    const buttons = [
      {
        title: "No, do not assign",
        className: "bg-red text-white border-m",
        onClick: () =>
          canCreatePB(dataToSend)
            ? PriceBook.createPriceBook({
                ...dataToSend,
                isDefault: false
              }).then((res) => props?.onSave?.())
            : updateOrSaveVersion(
                dataToSend.id,
                { ...dataToSend, isDefault: false },
                params
              )
      },
      {
        title: "Confirm",
        className: "bg-green text-white ml-r",
        onClick: () =>
          canCreatePB(dataToSend)
            ? PriceBook.createPriceBook(dataToSend, params).then((res) =>
                props?.onSave?.()
              )
            : updateOrSaveVersion(dataToSend.id, dataToSend, params)
      },
      {
        title: "Cancel",
        className: "bg-gray1 border-m ml-r",
        onClick: () => updateFormFields("isDefault", !formFields.isDefault)
      }
    ];
    showAlert(
      "Warning",
      error?.response?.data?.errorMessage,
      markAsDefaultErrorMessage ? buttons : null
    );
  };

  const onSave = () => {
    const payload = preparePayload();
    if (!validateForm(payload)) {
      setFormHasError(true);
    } else {
      savePriceBook(payload, formFields?.id);
    }
  };

  const savePriceBook = (dataToSend, id = null) => {
    if (id === "" || id === null) {
      PriceBook.createPriceBook(dataToSend)
        .then((response) => {
          props?.onSave();
          isAPICallInProgress.current = false;
        })
        .catch((err) => {
          errorMessageHandling(dataToSend, err);
          isAPICallInProgress.current = false;
        });
    } else {
      updateOrSaveVersion(id, dataToSend);
    }
  };
  const updateOrSaveVersion = (id, dataToSend, params = {}) => {
    const effectiveDate = !Utility.isEmptyObject(selectedVersion)
      ? selectedVersion?.["effectiveDate"]
      : props.priceBookToEdit?.effectiveDate;
    const effectiveDateToCompare = DateUtil.getFormattedDateString(
      effectiveDate,
      DATE_FORMATS["DD-MM-YYYY"],
      DateUtil.getOrgDateFormat()
    );
    const currentEffectiveDate = DateUtil.getFormattedDateString(
      dataToSend?.effectiveDate,
      DATE_FORMATS["DD-MM-YYYY"],
      DateUtil.getOrgDateFormat()
    );
    if (currentEffectiveDate === effectiveDateToCompare) {
      updatePriceBook(id, dataToSend, params)
        .then((response) => {
          props?.onSave();
          isAPICallInProgress.current = false;
        })
        .catch((err) => {
          if (Utility.isEmptyObject(params))
            errorMessageHandling({ ...dataToSend, id }, err);
          isAPICallInProgress.current = false;
        });
    } else {
      createPriceBookVersion(id, {
        ...dataToSend,
        effectiveDate: dataToSend.effectiveDate
      })
        .then((response) => {
          props?.onSave();
          isAPICallInProgress.current = false;
        })
        .catch((error) => {
          isAPICallInProgress.current = false;
        });
    }
  };
  const canCreatePB = (dataToSend) => {
    return (
      dataToSend?.id === "" ||
      dataToSend?.id === null ||
      dataToSend.id === undefined
    );
  };
  const validateForm = (dataToSend) => {
    if (checkForErrors("name")) {
      return false;
    }
    if (checkForErrors("effectiveDate")) {
      return false;
    }
    if (checkForErrors("currency")) {
      return false;
    }
    if (Utility.isEmptyObject(dataToSend?.priceListItems)) {
      return false;
    } else {
      if (
        dataToSend?.priceListItems?.some(
          (row: any) => !row?.productId || row?.invalidFields?.length > 0
        )
      ) {
        return false;
      }
    }
    if (Utility.isEmptyObject(dataToSend.type)) {
      return false;
    }
    if (Utility.isEmptyObject(dataToSend.currencies)) {
      return false;
    }
    return true;
  };
  const getDeleteConfirmation = (obj) => {
    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Delete",
        className: "bg-red text-white ml-r",
        onClick: () => onPriceBookDelete(obj)
      }
    ];
    showAlert(
      "Delete Price Book Version?",
      "Deleting this price book version will delete it permanently you will not be able to restore it.",
      buttons
    );
  };
  const onPriceBookDelete = (obj) => {
    deletePriceBook(obj?.id).then((response) => {
      let pbId = !Utility.isEmptyObject(selectedVersion)
        ? selectedVersion?.id
        : props.priceBookToEdit?.id;
      dispatch(fetchPriceBookVersions(pbId)).then((response) => {
        if (Utility.isEmptyObject(response?.payload) && isEditMode) {
          props.onCancel?.();
        } else {
          setSelectedVersion(response?.payload?.[0]);
        }
      });
    });
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between pb-m parent-width">
        <DKLabel
          text={`${isEditMode ? "Edit Price Book" : "New Price Book"}`}
          className="fw-b fs-m"
          style={{ width: "250px" }}
        />
        {isEditMode && (
          <div style={{ minWidth: "250px" }}>
            <DKInput
              title={"Price Book Versions"}
              placeholder={"Select version"}
              type={INPUT_TYPE.DROPDOWN}
              value={
                !Utility.isEmptyObject(selectedVersion) ? selectedVersion : null
              }
              onChange={(value) => setSelectedVersion(value)}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={false}
              formatter={(obj) => obj.name}
              renderer={(obj) => {
                const dateDisplayValue = DateUtil.getFormattedDateString(
                  obj?.effectiveDate,
                  DATE_FORMATS["DD-MM-YYYY"],
                  DateUtil.getOrgDateFormat()
                );
                return (
                  <div className="row width-auto bg-gray1 p-xs mr-r">
                    <DKLabel text={`${obj.name} (${dateDisplayValue})`} />
                  </div>
                );
              }}
              dropdownConfig={{
                allowSearch: false,
                searchableKey: "name",
                data: priceBookVersions,
                renderer: (index: any, obj: any) => {
                  const dateDisplayValue = DateUtil.getFormattedDateString(
                    obj?.effectiveDate,
                    DATE_FORMATS["DD-MM-YYYY"],
                    DateUtil.getOrgDateFormat()
                  );
                  return (
                    <div className="row parent-width p-xs mr-r justify-content-between price-book-row">
                      <DKLabel text={`${obj.name} (${dateDisplayValue})`} />
                      <DKIcon
                        src={DKIcons.ic_delete}
                        className={
                          "ic-s ml-s unselectable cursor-hand price-book-delete-button"
                        }
                        style={{ height: 13, opacity: 0.5, padding: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          getDeleteConfirmation(obj);
                        }}
                      />
                    </div>
                  );
                },
                onSelect: () => {}
              }}
            />
          </div>
        )}
        <div className="row justify-content-end">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m"
            onClick={() => {
              props.onCancel?.();
              setSelectedVersion(null);
            }}
          />
          <DKButton
            title="Save"
            className="bg-button text-white ml-r"
            disabled={isAPICallInProgress?.current}
            onClick={() => onSave()}
          />
        </div>
      </div>
    );
  };

  const getBody = () => {
    return (
      <div className="column parent-height parent-width border-radius-s mb-m padding-top-mobile mobile-parent-width p-0">
        {getTabs()}
        <div className="column parent-width parent-height mt-m position-relative align-items-start hide-scroll-bar">
          {getActivePanel()}
        </div>
      </div>
    );
  };

  const getTabs = () => {
    const tabs = [
      { name: "Create", value: TABS.CREATE },
      { name: "Rules", value: TABS.RULES }
    ];
    let tabView = [];
    tabs?.forEach((tab) => {
      tabView.push(
        <div
          className={`text-align-center justify-content-center cursor-hand ${activeTab === tab.value ? "active-border" : ""} `}
          style={{
            padding: "18px 1.8%",
            display: "flex"
          }}
          onClick={() => {
            if (!formFields.isDefault) {
              setActiveTab(tab.value);
            }
          }}
        >
          <DKLabel
            className={`${activeTab === tab.value ? "fw-m text-app" : "text-dark-gray"}`}
            text={tab.name}
          />
          {formFields.isDefault && tab.value === TABS.RULES && (
            <DKTooltipWrapper
              content="By selecting set as default you can not add rules to this price book."
              tooltipClassName="text-gray"
            >
              <DKIcon className="ic-s pl-s opacity-6" src={DKIcons.ic_info} />
            </DKTooltipWrapper>
          )}
          {!validateForm(preparePayload()) && tab.value === TABS.CREATE && (
            <DKTooltipWrapper
              content="Please fill the required fields value."
              tooltipClassName="text-gray"
            >
              <DKIcon
                className="ic-s pl-s opacity-6"
                src={DKIcons.ic_warning_red}
              />
            </DKTooltipWrapper>
          )}
        </div>
      );
    });
    return (
      <div
        className="row bg-white border-m-b hide-scroll-bar mb-m"
        style={{ overflowX: "auto", minHeight: 58 }}
      >
        {tabView}
      </div>
    );
  };

  const getActivePanel = () => {
    switch (activeTab) {
      case TABS.CREATE:
        if (showLoader) {
          return (
            <div
              className="row flex-1 justify-content-center align-items-center position-absolute"
              style={{ top: "250px" }}
            >
              <DKSpinner />
            </div>
          );
        } else {
          return getCreateTab();
        }
      case TABS.RULES:
        return getRulesTab();
      default:
        return null;
    }
  };

  const getCreateTab = () => {
    return (
      <div className="column parent-size">
        <div
          className="row row-responsive parent-width align-items-start mb-xl"
          style={{ gap: 20 }}
        >
          <div className="column parent-width">
            <DKInput
              title="Name"
              required={true}
              placeholder={"Price Book Name"}
              value={formFields.name}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              canValidate={formHasError}
              errorMessage={
                formHasError && checkForErrors("name")
                  ? "Name cannot be empty"
                  : ""
              }
              onChange={(value: string) => updateFormFields("name", value)}
            />
          </div>
          <div className="column parent-width">
            <DKInput
              className={"parent-width"}
              formatter={(obj: any) => getCapitalized(obj.name.toLowerCase())}
              title="Type"
              type={INPUT_TYPE.DROPDOWN}
              value={formFields.type}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              onChange={(value: any) => updateFormFields("type", value)}
              dropdownConfig={{
                data: types,
                renderer: (index: any, obj: any) => {
                  return (
                    <DKLabel
                      text={`${getCapitalized(obj.name.toLowerCase())}`}
                    />
                  );
                },
                onSelect: (index: any, value: any) => {}
              }}
            />
          </div>
        </div>
        <div
          className="row row-responsive parent-width align-items-start mb-xl"
          style={{ gap: 20 }}
        >
          <div className="parent-width column">
            <div className="row">
              <DKLabel className="mb-xs cursor-hand" text={"Effective Date"} />
              <DKTooltipWrapper
                content="Changing effective date will create new version of this selected price book."
                tooltipClassName=""
              >
                <DKIcon
                  src={DKIcons.ic_info}
                  className="ic-xs opacity-40 ml-s"
                  onClick={() => {}}
                />
              </DKTooltipWrapper>
            </div>

            <DKInput
              title=""
              className={"text-gray-800 mt-1.5"}
              value={new Date(formFields.effectiveDate)}
              type={INPUT_TYPE.DATE}
              dateFormat={convertCRMDateFormatToUILibraryFormat(
                DateUtil.getOrgDateFormat()
              )}
              onChange={(date: any) => updateFormFields("effectiveDate", date)}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              canValidate={formHasError}
              validator={(value: Date) =>
                !isBefore(
                  value,
                  DateUtil.getDateFromStr(
                    tenantDetail?.bookBeginningStartDate,
                    DATE_FORMATS["YYYY-MM-DD"]
                  )
                )
              }
              errorMessage={`Effective date can't be before books beginning date`}
              required={true}
              datePickerConfig={{
                isDateRange: false
              }}
            />
          </div>
          <div className="parent-width column">
            <DKInput
              title={"Currency"}
              className={"parent-width"}
              value={formFields.currency}
              displayKey="currencyName"
              type={INPUT_TYPE.DROPDOWN}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              required={true}
              canValidate={formHasError}
              errorMessage={
                formHasError && checkForErrors("currency")
                  ? "Currency cannot be empty"
                  : ""
              }
              onChange={(value: number[]) =>
                updateFormFields("currency", value)
              }
              formatter={(obj) => {
                return obj.currencyName;
              }}
              dropdownConfig={{
                className: "",
                style: {},
                allowSearch: true,
                searchableKey: "currencyName",
                data: allCurrencies || [],
                renderer: (index: number, obj: any) => {
                  return <DKLabel text={obj.currencyName} />;
                },
                onSelect: (index: number, value: any) => {},
                onClear: () => {}
              }}
            />
          </div>
        </div>
        <div
          className="row row-responsive parent-width align-items-start mb-xl"
          style={{ gap: 20 }}
        >
          <div
            className="parent-width column align-items-center"
            style={{ flexDirection: "row" }}
          >
            <DKLabel text={`Set as default`} className="mr-m" />
            <Toggle
              isOn={formFields.isDefault}
              onChange={() =>
                updateFormFields("isDefault", !formFields.isDefault)
              }
            />
          </div>
        </div>
        <DKLine className="bg-gray2 m-v-s mt-m" />
        {getDataGrid()}
        <div className="row">
          <DKButton
            className="text-blue fw-m"
            title={`+ Add a Product`}
            onClick={addNewRow}
            style={{ paddingLeft: 0 }}
          />
          {formFields.productRow.length === 0 && (
            <DKLabel
              text="Please select one product"
              className="text-red ml-l"
            />
          )}
        </div>
      </div>
    );
  };

  const getBlankCondition = () => {
    const availableFields = getFilteredAddressFields();
    if (!Utility.isEmptyObject(availableFields)) {
      let fields = [...rulesForConditions];
      fields.push(availableFields[0]);
      setRulesForCondition(fields);
    }
  };

  const onConditionChange = (condition: any, index: number) => {
    const copyOfRulesForConditions =
      Utility.deepCloneObject(rulesForConditions);
    copyOfRulesForConditions[index] = condition;
    setRulesForCondition(copyOfRulesForConditions);
  };

  const onRuleValueChange = (value: any, index: number) => {
    const copyOfRulesForConditions =
      Utility.deepCloneObject(rulesForConditions);
    copyOfRulesForConditions[index].value = value;

    if (
      copyOfRulesForConditions[index].id === PRICE_BOOK_ADDRESS.SHIPPING_COUNTRY
    ) {
      const stateIndex = copyOfRulesForConditions.findIndex(
        (rule) => rule.id === PRICE_BOOK_ADDRESS.SHIPPING_STATE
      );
      if (stateIndex !== -1) {
        copyOfRulesForConditions[stateIndex].value = null;
      }
    }
    if (
      copyOfRulesForConditions[index].id === PRICE_BOOK_ADDRESS.SHIPPING_STATE
    ) {
      const cityIndex = copyOfRulesForConditions.findIndex(
        (rule) => rule.id === PRICE_BOOK_ADDRESS.SHIPPING_CITY
      );
      if (cityIndex !== -1) {
        copyOfRulesForConditions[cityIndex].value = null;
      }
    }

    if (
      copyOfRulesForConditions[index].id === PRICE_BOOK_ADDRESS.BILLING_COUNTRY
    ) {
      const stateIndex = copyOfRulesForConditions.findIndex(
        (rule) => rule.id === PRICE_BOOK_ADDRESS.BILLING_STATE
      );
      if (stateIndex !== -1) {
        copyOfRulesForConditions[stateIndex].value = null;
      }
    }
    if (
      copyOfRulesForConditions[index].id === PRICE_BOOK_ADDRESS.BILLING_STATE
    ) {
      const cityIndex = copyOfRulesForConditions.findIndex(
        (rule) => rule.id === PRICE_BOOK_ADDRESS.BILLING_CITY
      );
      if (cityIndex !== -1) {
        copyOfRulesForConditions[cityIndex].value = null;
      }
    }

    setRulesForCondition(copyOfRulesForConditions);
  };

  const getStateDataByCountry = (country: string) => {
    switch (country) {
      case COUNTRY_NAME.INDIA:
        return INDIAN_STATES_MOCK_DATA;
      case COUNTRY_NAME.USA:
        return US_STATES_MOCK_DATA;
      default:
        return null;
    }
  };

  const getDropDownConfig = (field: any) => {
    if (field.type === INPUT_TYPE.DROPDOWN) {
      const dropdownConfig = {
        data: [],
        allowSearch: true,
        searchableKey: "name",
        renderer: (index: any, obj: any) => <DKLabel text={obj.name} />,
        onSelect: (index: number, value: any) => {}
      };
      switch (field.id) {
        case PRICE_BOOK_ADDRESS.SHIPPING_COUNTRY:
        case PRICE_BOOK_ADDRESS.BILLING_COUNTRY:
          dropdownConfig.searchableKey = "country";
          dropdownConfig.data = COUNTRIES_WITH_CURRENCIES;
          dropdownConfig.renderer = (index: any, obj: any) => (
            <DKLabel text={obj.country} />
          );
          break;
        case PRICE_BOOK_ADDRESS.SHIPPING_STATE:
          const shippingCountry = getFieldValue(
            PRICE_BOOK_ADDRESS.SHIPPING_COUNTRY
          )?.country;
          dropdownConfig.data = getStateDataByCountry(shippingCountry);
          break;
        case PRICE_BOOK_ADDRESS.BILLING_STATE:
          const billingCountry = getFieldValue(
            PRICE_BOOK_ADDRESS.BILLING_COUNTRY
          )?.country;
          dropdownConfig.data = getStateDataByCountry(billingCountry);
          break;
        case PRICE_BOOK_ADDRESS.BILLING_CITY:
          const billingState = getFieldValue(PRICE_BOOK_ADDRESS.BILLING_STATE);
          dropdownConfig.data =
            billingState?.cities?.length > 0 ? billingState?.cities : null;
          dropdownConfig.renderer = (index: any, obj: any) => (
            <DKLabel text={obj} />
          );
          break;
        case PRICE_BOOK_ADDRESS.SHIPPING_CITY:
          const shippingState = getFieldValue(
            PRICE_BOOK_ADDRESS.SHIPPING_STATE
          );
          dropdownConfig.data =
            shippingState?.cities?.length > 0 ? shippingState?.cities : null;
          dropdownConfig.renderer = (index: any, obj: any) => (
            <DKLabel text={obj} />
          );
          break;
        default:
          break;
      }
      return dropdownConfig;
    }
    return null;
  };

  const valueFormatter = (field: any, value: any) => {
    switch (field.id) {
      case PRICE_BOOK_ADDRESS.SHIPPING_COUNTRY:
      case PRICE_BOOK_ADDRESS.BILLING_COUNTRY:
        return value?.country;
      case PRICE_BOOK_ADDRESS.SHIPPING_CITY:
      case PRICE_BOOK_ADDRESS.BILLING_CITY:
        return value;
      default:
        return value?.name;
    }
  };

  const getFieldValue = (key: string) => {
    return rulesForConditions?.find((rule) => rule.id === key)?.value;
  };

  const getFieldInputType = (field: any) => {
    switch (field.id) {
      case PRICE_BOOK_ADDRESS.SHIPPING_STATE:
        const shippingCountry = getFieldValue(
          PRICE_BOOK_ADDRESS.SHIPPING_COUNTRY
        )?.country;
        return shippingCountry === COUNTRY_NAME.INDIA ||
          shippingCountry === COUNTRY_NAME.USA
          ? INPUT_TYPE.DROPDOWN
          : INPUT_TYPE.TEXT;
      case PRICE_BOOK_ADDRESS.BILLING_STATE:
        const billingCountry = getFieldValue(
          PRICE_BOOK_ADDRESS.BILLING_COUNTRY
        )?.country;
        return billingCountry === COUNTRY_NAME.INDIA ||
          billingCountry === COUNTRY_NAME.USA
          ? INPUT_TYPE.DROPDOWN
          : INPUT_TYPE.TEXT;
      case PRICE_BOOK_ADDRESS.BILLING_CITY:
        const billingState = getFieldValue(PRICE_BOOK_ADDRESS.BILLING_STATE);
        return billingState?.cities?.length
          ? INPUT_TYPE.DROPDOWN
          : INPUT_TYPE.TEXT;
      case PRICE_BOOK_ADDRESS.SHIPPING_CITY:
        const shippingState = getFieldValue(PRICE_BOOK_ADDRESS.SHIPPING_STATE);
        return shippingState?.cities?.length
          ? INPUT_TYPE.DROPDOWN
          : INPUT_TYPE.TEXT;
      default:
        return field.type;
    }
  };

  const removeFilterCondition = (index: number) => {
    let fields = [...rulesForConditions];
    fields.splice(index, 1);
    setRulesForCondition(fields);
  };

  const getFieldDisplayValue = (field) => {
    if (field.type === INPUT_TYPE.SELECT) {
      return field?.value ?? [];
    }
    return field?.value;
  };

  const getOptions = (field) => {
    if (field.type === INPUT_TYPE.SELECT) {
      return field?.options?.map((option) => option.name) ?? [];
    }
    return null;
  };

  const getRulesTab = () => {
    return (
      <div className="column parent-size" style={{ gap: 10 }}>
        {rulesForConditions?.length > 0 &&
          rulesForConditions?.map((field: any, index: number) => {
            const type = getFieldInputType(field);
            return (
              <div className="row parent-width" style={{ gap: 10 }}>
                <DKInput
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={field}
                  required={false}
                  formatter={(obj) => {
                    return obj.name;
                  }}
                  type={INPUT_TYPE.DROPDOWN}
                  onChange={(value) => onConditionChange(value, index)}
                  dropdownConfig={{
                    data: getFilteredAddressFields(),
                    allowSearch: true,
                    searchableKey: "name",
                    renderer: (index: any, obj: any) => (
                      <DKLabel text={obj.name} />
                    ),
                    onSelect: (index: number, value: any) => {}
                  }}
                />
                <DKInput
                  placeholder={"Enter value"}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  value={getFieldDisplayValue(field)}
                  type={type}
                  required={false}
                  options={getOptions(field)}
                  dropdownConfig={getDropDownConfig({ ...field, type })}
                  onChange={(value: any) => onRuleValueChange(value, index)}
                  formatter={(value) => valueFormatter(field, value)}
                />
                <DKIcon
                  className={"ic-s opacity-6 cursor-hand"}
                  src={DKIcons.ic_delete}
                  onClick={() => removeFilterCondition(index)}
                />
              </div>
            );
          })}
        <DKButton
          className="text-blue fw-m mb-l hand-cursor"
          title={`+ Add a Condition`}
          onClick={() => getBlankCondition()}
        />
      </div>
    );
  };

  const getFilteredAddressFields = () => {
    const existingAddressFields = rulesForConditions?.map((rule) => rule?.id);
    const mappedCustomFields = getSelectedModuleCustomFields();
    return [...ADDRESS_FIELDS, ...mappedCustomFields]?.filter(
      (field) => !existingAddressFields.includes(field.id)
    );
  };

  const onSearch = debounce((searchQuery) => {
    setSearchText(searchQuery);
    setPageNo(0);
  }, 500);

  const onSort = ({ order, columnData }) => {
    const sort = columnData?.key === 'product' ? 'name' : columnData.key;
    const sortDir = order;
    setSortConfig({ sort, sortDir });
  }
  const onPageChange = (pageNo) => {
    setPageNo(pageNo - 1);
  };

  const getDataGrid = () => {
    return (
      <DKDataGrid
        title=""
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={false}
        allowBulkOperation={false}
        allowColumnSort={true}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={true}
        allowShare={false}
        rows={[...formFields.productRow]?.map((lineItem: any, index: any) => {
          return {
            ...lineItem,
            rowButtons: getButtonForRow(lineItem, index)
          };
        })}
        columns={[
          ...columnConfig,
          {
            key: "actions",
            name: "Actions",
            type: INPUT_TYPE.BUTTON,
            width: 172,
            options: []
          }
        ]}
        currentPage={products?.current?.pageable?.pageNumber + 1}
        totalPageCount={products?.current?.totalPages}
        onRowUpdate={onRowUpdate}
        onRowClick={({ columnData, rowIndex }: any) => {
          setSelectedRowIndex(rowIndex);
          updateColumnConfig(
            getUOMList(formFields.productRow[rowIndex].product)
          );
        }}
        onSearch={(data) => onSearch(data)}
        onSort={onSort}
        onPageChange={onPageChange}
      />
    );
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    let priceListItemsCopy: any = [...priceListItems?.current] || [];
    const itemIndex = priceListItemsCopy?.findIndex(item => item?.id === rowData?.id);
    let updatedState: any = Utility.deepCloneObject(formFields);
    let invalidFields = updatedState.productRow[rowIndex].invalidFields.filter(
      (invF: any) => invF !== columnKey
    );
    switch (columnKey) {
      case "product":
        const uomList = getUOMList(rowData.product);
        updatedState.productRow[rowIndex].product.value = {
          label: rowData.product.name,
          value: rowData.product.productId,
          stockUom: rowData.product.stockUom,
          documentSequenceCode: rowData.product?.documentSequenceCode,
          uomList
        };
        updatedState.productRow[rowIndex].product = rowData.product;
        updateColumnConfig(uomList);
        updatedState.productRow[rowIndex].uomId = uomList?.find(
          (uom: any) => uom.id === rowData.product.stockUom
        );
        if (!Utility.isEmptyObject(updatedState.productRow[rowIndex].uomId)) {
          invalidFields = invalidFields.filter(
            (columnCode: any) => columnCode.code !== "uom"
          );
        }
        updatedState.productRow[rowIndex].documentSequenceCode =
          rowData.product?.documentSequenceCode;
        break;
      case "uomId":
        updatedState.productRow[rowIndex].uomId = rowData.uomId;
        break;
      case "minimumQuantity":
        const minimumQuantity = isNaN(Number(rowData.minimumQuantity))
          ? 1
          : Number(rowData.minimumQuantity);
        updatedState.productRow[rowIndex].minimumQuantity = minimumQuantity;
        if (minimumQuantity <= 0) {
          invalidFields.push("minimumQuantity");
        }
        break;
      case "discount":
        const matcher = String(rowData?.discount).match(REGEX.PERCENT_NUMBER);
        updatedState.productRow[rowIndex].discountInPercent = false;
        if (!matcher) {
          updatedState.productRow[rowIndex].discount = 0;
        } else if ("%" === matcher[4]) {
          const percentDiscount = Number(rowData?.discount.replace("%", ""));
          if (percentDiscount > 100) {
            updatedState.productRow[rowIndex].discount = 100;
          } else {
            updatedState.productRow[rowIndex].discount = percentDiscount;
          }
          updatedState.productRow[rowIndex].discountInPercent = true;
        } else {
          updatedState.productRow[rowIndex].discount =
            PriceBookManager.getNumber(rowData.discount);
        }

        break;

      default:
        updatedState.productRow[rowIndex][`${columnKey}`] =
          rowData[`${columnKey}`];
        if (rowData[`${columnKey}`] < 0 || rowData[`${columnKey}`] === "") {
          invalidFields.push(columnKey);
        }
        break;
    }

    if (!updatedState.productRow[rowIndex].discountInPercent) {
      const invalidDiscount =
        Number(rowData.discount) > Number(rowData.currency || 0);
      invalidDiscount &&
        !invalidFields.some((field: string) => field === "discount") &&
        invalidFields.push("discount");

      if (!invalidDiscount) {
        invalidFields = invalidFields.filter(
          (field: string) => field !== "discount"
        );
      }
    }
    if (itemIndex === -1) {
      priceListItemsCopy.push({...updatedState.productRow[rowIndex]})
    } else {
      priceListItemsCopy[itemIndex] = updatedState.productRow[rowIndex];
    }

    updatedState.productRow[rowIndex].invalidFields = invalidFields ?? [];
    priceListItems.current = priceListItemsCopy;
    setFormFields({ ...updatedState });
  };

  const onDelete = (data: any, index: any) => {
    let updatedState: any = { ...formFields };
    let priceListItemsCopy: any = [...priceListItems?.current] || [];
    priceListItemsCopy = priceListItemsCopy.filter(item => item?.id !== updatedState?.productRow?.[index]?.id);
    updatedState.productRow.splice(index, 1);
    setFormFields({ ...updatedState });
    priceListItems.current = priceListItemsCopy;
  };

  const getButtonForRow = (row: any, index: any) => {
    let buttons: any[] = [];

    buttons.push({
      className: "p-0",
      icon: DKIcons.ic_delete,
      onClick: (data: any) => {
        onDelete(row, index);
      }
    });

    return buttons;
  };

  const addNewRow = () => {
    const updatedState: any = { ...formFields };
    updatedState.productRow.push(getEmptyRow());
    setFormFields({ ...updatedState });
  };

  const getEmptyRow = () => {
    let emptyRow = Utility.deepCloneObject(initialRowData);
    let invalidFields: any[] = [];
    columnConfig.forEach((config: any) => {
      if (config.key === "product") {
        invalidFields.push(config.key);
      }
    });
    emptyRow.invalidFields = invalidFields;
    emptyRow.id = getRandomNumber();
    emptyRow.isNewProduct = isEditMode ? true : false;
    // let multiCurrency = rowCurrency;
    // multiCurrency.key = formFields.currency;
    // emptyRow.rowCurrency = Utility.deepCloneObject(multiCurrency);
    return emptyRow;
  };

  const getProductPopup = () => {
    return (
      <CreateProduct
        onClose={() => setsShowProductPopup(false)}
        onSave={saveProduct}
      />
    );
  };

  const saveProduct = (formReq) => {
    showLoader();
    BooksService.createProduct(formReq)
      .then((res: any) => {
        let rowData = {
          ...formFields.productRow[selectedRowIndex],
          product: res
        };
        onRowUpdate({
          columnKey: "product",
          rowData,
          rowIndex: selectedRowIndex,
          columnData: null
        });
        dispatch(fetchBooksProducts({}));
        setsShowProductPopup(false);
      })
      .catch((err) => {
        showAlert(
          "Error occurred!",
          "There was some problem with server. Please try again later."
        );
      })
      .finally(() => {
        removeLoader();
      });
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          width: "930px",
          maxWidth: "90%",
          maxHeight: "90%",
          minHeight: "90%"
        }}
      >
        {getHeader()}
        {getBody()}
        {showProductPopup && getProductPopup()}
      </div>
    </div>
  );
}
