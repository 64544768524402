import { DKLabel, Toggle } from "deskera-ui-library";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { updateTenantSettings } from "../../services/tenant";
import Utility from "../../utility/Utility";
import { replaceCountrySpecificKeywords } from "../../utility/Utility";
export function AccountLinkingForDealAndQuote(props) {
  const tenantDetails = useAppSelector(selectCRMSettings());
  const [toggle, setToggle] = useState(
    tenantDetails?.contactLinkingDisabledFor?.length > 0 || false
  );
  const dispatch = useAppDispatch();

  const onToggle = async () => {
    try {
      setToggle(!toggle);
      let disabledFor = toggle ? [] : ["DEAL", "QUOTE"];
      const response = await updateTenantSettings({
        contactLinkingDisabledFor: disabledFor
      });
      if (response) {
        dispatch(fetchCRMSettings({}));
        props.onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onSave = async () => {
    try {
      let accountLinkingPayload = {
        contactLinkingDisabledFor: toggle ? ["DEAL", "QUOTE"] : []
      };
      const response = await updateTenantSettings(accountLinkingPayload);
      if (!Utility.isEmptyObject(response)) {
        dispatch(fetchCRMSettings({}));
        props.onClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return (
    <div>
      <div className="row justify-content-between border-m border-radius-m position-relative p-r mt-l">
        <div className="column">
          <DKLabel
            text={replaceCountrySpecificKeywords(
              `Contact Linkage to Quote/Deal`
            )}
            className="mr-m fw-m pb-xs"
          />
          <DKLabel
            className="text-gray text-ellipsis"
            text={replaceCountrySpecificKeywords(
              `Disable contact link in Deal & Quote`
            )}
          />
        </div>
        <div className="row justify-content-end ml-l" style={{ width: 75 }}>
          <Toggle isOn={toggle} onChange={onToggle} color="bg-green" />
        </div>
      </div>
    </div>
  );
}
export default AccountLinkingForDealAndQuote;
