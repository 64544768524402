import FormService from "../../../services/form";
import ContactService from "../../../services/contact";
import { getRandomAlphaNumericString } from "../../../utility/Utility";

export default class Template10 {
  static getData() {
    return {
      metaData: null,
      scripts: null,
      hash: getRandomAlphaNumericString(),
      designElements: [
        {
          type: "Icon",
          value: [
            {
              title: "",
              description: "",
              icon: "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625740946347_Logo.png"
            }
          ],
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 4,
            marginBottom: 4,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "flex-start",
            overflow: "hidden",
            iconSize: 36,
            cellSpacing: 6,
            iconRadius: 0,
            titleColor: "#000000",
            titleSize: 14,
            descriptionColor: "#837c7c",
            descriptionSize: 14
          }
        },
        {
          type: "Text",
          value: "Your House - Your Life",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 48,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          style: {
            width: "66%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#020101",
            textAlign: "center",
            fontWeight: "600",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#ffffff",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625742434624_LandingPage-10-Image1.png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 16,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "01 Introduction",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 24,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#000000",
            textAlign: "center",
            fontWeight: "600",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 12,
            paddingRight: 12,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625742491080_LandingPage-10-Image2.png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 22,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "02 Luxurious",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 24,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore etdolore magna aliqua.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#000000",
            textAlign: "center",
            fontWeight: "600",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 12,
            paddingRight: 12,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625742385101_LandingPage-10-Image3.png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 22,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "03 Nice Location",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 24,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#000000",
            textAlign: "center",
            fontWeight: "600",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 12,
            paddingRight: 12,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/67973/1625742328377_LandingPage-10-Image4.png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 22,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "LET’S START YOUR NEW LIFE RIGHT NOW",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 24,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 10,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Button",
          value: "Get Started",
          url: "",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#ffffff",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 31,
            paddingRight: 31,
            marginTop: 10,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 22,
            backgroundColor: "#e14525",
            alignSelf: "center",
            overflow: "hidden",
            cursor: "pointer",
            outline: "none",
            border: "none",
            textDecoration: "none"
          }
        },
        {
          type: "Text",
          value: "Let’s stay in touch",
          style: {
            width: "70%",
            boxSizing: "border-box",
            fontSize: 26,
            color: "#000000",
            textAlign: "center",
            fontWeight: "600",
            paddingTop: 18,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 28,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#cccccc",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Enjoy 15% off your first order when you join our mailing list.",
          style: {
            width: "70%",
            boxSizing: "border-box",
            fontSize: 14,
            color: "#000000",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 0,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#cacaca",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Form",
          value: FormService.getContactDataForLandingPages(),
          segmentId:
            ContactService.getSegments().length > 0
              ? ContactService.getSegments()[0].id
              : "",
          style: {
            width: "70%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#cccccc",
            alignSelf: "center",
            overflow: "hidden",
            fieldSpacing: 10,
            labelSize: 12,
            labelColor: "#000000",
            fieldRadius: 4,
            fieldBorder: "#EBEBEB",
            fieldBackground: "#F8F7FA"
          },
          button: {
            value: "Subscribe",
            style: {
              buttonColor: "#ffffff",
              buttonSize: 18,
              buttonBackground: "#F53206"
            }
          },
          thankYouMsg: "Thank you for submitting form!",
          redirectURL: ""
        },
        {
          type: "Text",
          value: "© 2021, All Rights Reserved | Unsubscribe",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#000000",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#F0F0F0",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        }
      ]
    };
  }
}
