import ApiConstants from "../constants/ApiConstants";
import httpClient from "../http";
import { COLUMN_CODE, TableManger, TABLES } from "../managers/TableManger";
export class DealService {
  static fetchStageMetaData(requestBody = {}, params = {}) {
    return httpClient.post(ApiConstants.URL.COMMON.STAGE_META, requestBody, {
      params: { ...params, _allowRetry: true }
    });
  }

  static fetchDealByStages(params: any = {}, data: any) {
    const contactColumnId = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.CONTACT_ID
    );
    const accountColumnId = TableManger.getColumnId(
      TABLES.DEAL,
      COLUMN_CODE.DEAL.ACCOUNT_ID
    );
    let colIdFilterString = `${contactColumnId}, ${accountColumnId}`;
    params = {
      pageNo: 1,
      pageSize: 20,
      colIdsToFetchRef: colIdFilterString,
      _allowRetry: true,
      ...params
    };

    return httpClient
      .post(`${ApiConstants.URL.COMMON.DEAL_RECORD_BY_PAGE}`, data, {
        params
      })
      .then(
        (response) => {
          response.data = response.data.map((record) => {
            return {
              ...record,
              cells: {
                ...record.cells,
                createdBy: record.createdBy,
                id: record._id
              }
            };
          });
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /* *************** DEAL STAGE CHANGE APPROVAL SERVICES **************** */
  static fetchDealStageChangeApprovalFlows() {
    return httpClient.get(ApiConstants.URL.DEAL_AUTOMATION.GET_APPROVAL_FLOWS);
  }
  static fetchApprovalTriggers(params) {
    return httpClient.get(ApiConstants.URL.DEAL_AUTOMATION.TRIGGER_APPROVAL, {
      params
    });
  }
  static TriggerDealStageChangeApproval(payload) {
    return httpClient.post(
      ApiConstants.URL.DEAL_AUTOMATION.TRIGGER_APPROVAL,
      payload
    );
  }
  static updateTriggerStatus(payload) {
    return httpClient.patch(
      ApiConstants.URL.DEAL_AUTOMATION.TRIGGER_APPROVAL,
      payload
    );
  }
  static deleteBulkApprovalTrigger(payload) {
    return httpClient.delete(
      ApiConstants.URL.DEAL_AUTOMATION.TRIGGER_APPROVAL,
      { data: payload }
    );
  }
  static deleteApprovalTrigger(recordId) {
    return httpClient.delete(
      ApiConstants.URL.DEAL_AUTOMATION.DELETE_APPROVAL(recordId)
    );
  }
}
