export const profitAndLossByMonth = {
  columns: [
    {
      title: "Months",
      style: {
        width: 300,
        textAlign: "left",
        borderBottom: "1px solid lightGray",
        borderTop: "1px solid lightGray",
        color: "white"
      }
    },
    {
      title: "Jan",
      style: {
        fontWeight: "600",
        width: 270,
        textAlign: "right",
        borderBottom: "1px solid lightGray",
        borderTop: "1px solid lightGray"
      }
    },
    {
      title: "Feb",
      style: {
        fontWeight: "600",
        width: 270,
        textAlign: "right",
        borderBottom: "1px solid lightGray",
        borderTop: "1px solid lightGray"
      }
    },
    {
      title: "Mar",
      style: {
        fontWeight: "600",
        width: 270,
        textAlign: "right",
        borderBottom: "1px solid lightGray",
        borderTop: "1px solid lightGray"
      }
    },
    {
      title: "Apr",
      style: {
        fontWeight: "600",
        width: 270,
        textAlign: "right",
        borderBottom: "1px solid lightGray",
        borderTop: "1px solid lightGray"
      }
    },
    {
      title: "May",
      style: {
        fontWeight: "600",
        width: 270,
        textAlign: "right",
        borderBottom: "1px solid lightGray",
        borderTop: "1px solid lightGray"
      }
    }
  ],
  rows: [
    {
      expanded: true,
      cells: [
        {
          title: "Income",
          style: {
            fontWeight: "normal",
            marginLeft: 0
          }
        },
        {},
        {},
        {},
        {},
        {}
      ],
      children: [
        {
          expanded: true,
          cells: [
            {
              title: "Sales",
              style: {
                fontWeight: "normal",
                marginLeft: 15
              }
            },
            {
              title: "$ 225.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 112.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 436.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 67.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 32.00",
              style: {
                fontWeight: "normal"
              }
            }
          ]
        },
        {
          cells: [
            {
              title: "Sales of Product Income",
              style: {
                fontWeight: "normal",
                marginLeft: 15
              }
            },
            {
              title: "$ 12.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 204.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 340.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 544.00",
              style: {
                fontWeight: "normal"
              }
            }
          ]
        },
        {
          cells: [
            {
              title: "Total Income",
              style: {
                fontWeight: "600",
                marginLeft: 0,
                borderTop: "1px solid lightGray",
                marginTop: 8
              }
            },
            {
              title: "$ 237.00",
              style: {
                fontWeight: "600",
                marginLeft: 0,
                borderTop: "1px solid lightGray",
                marginTop: 8
              }
            },
            {
              title: "$ 112.00",
              style: {
                fontWeight: "600",
                marginLeft: 0,
                borderTop: "1px solid lightGray",
                marginTop: 8
              }
            },
            {
              title: "$ 640.00",
              style: {
                fontWeight: "600",
                marginLeft: 0,
                borderTop: "1px solid lightGray",
                marginTop: 8
              }
            },
            {
              title: "$ 533.00",
              style: {
                fontWeight: "600",
                marginLeft: 0,
                borderTop: "1px solid lightGray",
                marginTop: 8
              }
            },
            {
              title: "$ 870.00",
              style: {
                fontWeight: "600",
                marginLeft: 0,
                borderTop: "1px solid lightGray",
                marginTop: 8
              }
            }
          ]
        }
      ]
    },
    {
      expanded: true,
      cells: [
        {
          title: "Cost of Good Sold",
          style: {
            fontWeight: "normal",
            marginLeft: 0
          }
        },
        {},
        {},
        {},
        {},
        {}
      ],
      children: [
        {
          cells: [
            {
              title: "Cost of Good Sold",
              style: {
                fontWeight: "normal",
                marginLeft: 15
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 78.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 10.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 70.00",
              style: {
                fontWeight: "normal"
              }
            }
          ]
        },
        {
          cells: [
            {
              title: "Total Cost of Good Sold",
              style: {
                fontWeight: "600",
                borderTop: "1px solid lightGray",
                borderBottom: "1px solid lightGray",
                marginTop: 8,
                marginBottom: 8
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "600",
                borderTop: "1px solid lightGray",
                borderBottom: "1px solid lightGray",
                marginTop: 8,
                marginBottom: 8
              }
            },
            {
              title: "$ 78.00",
              style: {
                fontWeight: "600",
                borderTop: "1px solid lightGray",
                borderBottom: "1px solid lightGray",
                marginTop: 8,
                marginBottom: 8
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "600",
                borderTop: "1px solid lightGray",
                borderBottom: "1px solid lightGray",
                marginTop: 8,
                marginBottom: 8
              }
            },
            {
              title: "$ 178.00",
              style: {
                fontWeight: "600",
                borderTop: "1px solid lightGray",
                borderBottom: "1px solid lightGray",
                marginTop: 8,
                marginBottom: 8
              }
            },
            {
              title: "$ 550.00",
              style: {
                fontWeight: "600",
                borderTop: "1px solid lightGray",
                borderBottom: "1px solid lightGray",
                marginTop: 8,
                marginBottom: 8
              }
            }
          ]
        }
      ]
    },
    {
      expanded: true,
      cells: [
        {
          title: "GROSS PROFIT",
          style: {}
        },
        {
          title: "$ 237.00",
          style: { fontWeight: "600" }
        },
        {
          title: "$ 190.00",
          style: { fontWeight: "600" }
        },
        {
          title: "$ 640.00",
          style: { fontWeight: "600" }
        },
        {
          title: "$ 40.00",
          style: { fontWeight: "600" }
        },
        {
          title: "$ 80.00",
          style: { fontWeight: "600" }
        }
      ]
    },
    {
      expanded: true,
      cells: [
        {
          title: "Expenses",
          style: {
            fontWeight: "normal",
            marginLeft: 0
          }
        },
        {},
        {},
        {},
        {},
        {}
      ],
      children: [
        {
          cells: [
            {
              title: "Ask My Accountant",
              style: {
                fontWeight: "normal",
                marginLeft: 15
              }
            },
            {
              title: "$ 100.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 200.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "normal"
              }
            }
          ]
        },
        {
          cells: [
            {
              title: "Contractors",
              style: {
                fontWeight: "normal",
                marginLeft: 15
              }
            },
            {
              title: "$ 299.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "normal"
              }
            }
          ]
        },
        {
          cells: [
            {
              title: "Legal & Professional Services",
              style: {
                fontWeight: "normal",
                marginLeft: 15
              }
            },
            {
              title: "$ 100.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 670.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 100.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 40.00",
              style: {
                fontWeight: "normal"
              }
            },
            {
              title: "$ 600.00",
              style: {
                fontWeight: "normal"
              }
            }
          ]
        },
        {
          cells: [
            {
              title: "Total Expenses",
              style: {
                fontWeight: "600",
                marginLeft: 0,
                borderTop: "1px solid lightGray",
                marginTop: 8
              }
            },
            {
              title: "$ 499.00",
              style: {
                fontWeight: "600",
                marginLeft: 0,
                borderTop: "1px solid lightGray",
                marginTop: 8
              }
            },
            {
              title: "$ 670.00",
              style: {
                fontWeight: "600",
                marginLeft: 0,
                borderTop: "1px solid lightGray",
                marginTop: 8
              }
            },
            {
              title: "$ 100.00",
              style: {
                fontWeight: "600",
                marginLeft: 0,
                borderTop: "1px solid lightGray",
                marginTop: 8
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "600",
                marginLeft: 0,
                borderTop: "1px solid lightGray",
                marginTop: 8
              }
            },
            {
              title: "$ 0.00",
              style: {
                fontWeight: "600",
                marginLeft: 0,
                borderTop: "1px solid lightGray",
                marginTop: 8
              }
            }
          ]
        }
      ]
    },
    {
      cells: [
        {
          title: "NET OPERATING INCOME",
          style: {
            borderTop: "1px solid lightGray"
          }
        },
        {
          title: "$ -262.00",
          style: { fontWeight: "600", borderTop: "1px solid lightGray" }
        },
        {
          title: "$ -520.00",
          style: { fontWeight: "600", borderTop: "1px solid lightGray" }
        },
        {
          title: "$ 540.00",
          style: { fontWeight: "600", borderTop: "1px solid lightGray" }
        },
        {
          title: "$ 1200.00",
          style: { fontWeight: "600", borderTop: "1px solid lightGray" }
        },
        {
          title: "$ 900.00",
          style: { fontWeight: "600", borderTop: "1px solid lightGray" }
        }
      ]
    },
    {
      cells: [
        {
          title: "NET INCOME",
          style: {
            borderTop: "1px solid lightGray"
          }
        },
        {
          title: "$ -262.00",
          style: { fontWeight: "600", borderTop: "1px solid lightGray" }
        },
        {
          title: "$ -520.00",
          style: { fontWeight: "600", borderTop: "1px solid lightGray" }
        },
        {
          title: "$ 540.00",
          style: { fontWeight: "600", borderTop: "1px solid lightGray" }
        },
        {
          title: "$ 1320.00",
          style: { fontWeight: "600", borderTop: "1px solid lightGray" }
        },
        {
          title: "$ 720.00",
          style: { fontWeight: "600", borderTop: "1px solid lightGray" }
        }
      ]
    }
  ]
};
