import React, { Component } from "react";
import {
  DKLabel,
  DKButton,
  DKInput,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKSpinner
} from "deskera-ui-library";
import { EVENT_TRACKING_TYPE } from "../../eventTracker/utility/EventConstants";
import Utility, { getCapitalized } from "../../utility/Utility";
import MixpanelService from "../../services/mixpanelService";
import { PRODUCTS } from "../../constants/Enum";

export interface IEventScoreProps {
  onClose: () => void;
}

export default class EventScore extends Component<IEventScoreProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      selectedModule: "ACCOUNT",
      defaultScore: null,
      modules: this.paresData(),
      isLoading: false
    };
  }
  getButtons = () => {
    return (
      <div className="row width-auto mt-m">
        <DKButton
          title="Save"
          className="bg-button text-white"
          onClick={() => this.saveEventScore()}
          disabled={this.state.isLoading}
        />
      </div>
    );
  };

  componentDidMount(): void {
    this.getSettingData();
  }

  titleFormate = (title) => {
    return getCapitalized(title?.toLowerCase()?.replace("_", " ") || "");
  };

  paresData = () => {
    let moduleData = {};
    Object.keys(EVENT_TRACKING_TYPE)?.forEach((module) => {
      let actions = [];
      Object.entries(EVENT_TRACKING_TYPE[module])?.forEach((values) => {
        actions.push({ action: values[0], event: values[1], leadScore: 0 });
      });
      moduleData[module] = actions;
    });
    return moduleData;
  };

  parseResponse = (response) => {
    let modules = Utility.deepCloneObject(this.state.modules);
    Object.keys(modules)?.forEach((module) => {
      modules[module]?.forEach((values) => {
        const actionData = response.find((item) => item.event === values.event);
        values.leadScore = actionData?.leadScore || 0;
      });
    });
    return modules;
  };

  updateLeadScore = () => {
    let modules = Utility.deepCloneObject(this.state.modules);
    Object.keys(modules)?.forEach((module) => {
      modules[module]?.forEach((values) => {
        const actionData = values.action.split("_");
        values.leadScore = this.state.defaultScore[actionData.pop()];
      });
    });
    this.setState({ modules });
  };

  getSettingData = () => {
    this.setState({ isLoading: true });
    MixpanelService.getMixpanelSetting().then((res) => {
      if (!Utility.isEmptyObject(res)) {
        if (!Utility.isEmptyObject(res.data)) {
          this.setState({
            modules: this.parseResponse(res.data?.leadScoreInfo),
            defaultScore: res.default
          });
        } else {
          if (!Utility.isEmptyObject(res?.default)) {
            this.setState({ defaultScore: res.default });
            this.updateLeadScore();
          }
        }
      }
      this.setState({ isLoading: false });
    });
  };

  getHeaderSection = () => {
    return (
      <div className="row justify-content-between parent-width">
        <div className="row">
          <DKLabel text={"Event Score Settings"} className="fw-m" />
          {this.state.isLoading && (
            <DKSpinner iconClassName="ic-s-2" className="ml-m" />
          )}
        </div>
        <div className="row justify-content-end">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m mr-s"
            onClick={() => this.props.onClose()}
          />
          <DKButton
            title="Save"
            className="bg-button text-white "
            onClick={() => this.saveEventScore()}
            disabled={this.state.isLoading}
          />
        </div>
      </div>
    );
  };

  getPayloadForRequest = () => {
    const moduleData = Utility.deepCloneObject(this.state.modules);
    let payload = [];
    Object.values(moduleData).map((module: any) => {
      let data = module?.map((item) => {
        delete item.action;
        return item;
      });
      payload = [...payload, ...data];
    });
    return payload;
  };

  saveEventScore = () => {
    let payload = {
      appName: PRODUCTS.CRM,
      leadScoreInfo: this.getPayloadForRequest()
    };
    MixpanelService.updateMixpanelSetting(payload).then((res) => {
      this.props.onClose();
    });
  };

  onModuleChange = (section) => {
    this.setState({ selectedModule: section });
  };

  getAllActions = () => {
    return this.state.modules && this.state.modules[this.state.selectedModule];
  };

  updateScore = (action, value) => {
    let data = { ...this.state.modules };
    let index = data[this.state.selectedModule].findIndex(
      (item) => item.event === action
    );
    data[this.state.selectedModule][index].leadScore = parseInt(value || 0);
    this.setState({ modules: data });
  };

  getRightPanel = () => {
    const mapData = this.getAllActions();
    return (
      <div className="parent-width z-index-1 flex-1">
        {mapData &&
          mapData?.map((action) => {
            return (
              <div className="pl-l row justify-content-between">
                <DKLabel text={action?.event} />
                <DKInput
                  // title={action}
                  value={action.leadScore}
                  type={INPUT_TYPE.NUMBER}
                  direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
                  className={"p-v-xs width-50"}
                  required={false}
                  onChange={(value) => this.updateScore(action.event, value)}
                />
              </div>
            );
          })}
      </div>
    );
  };
  getLeftPanel = () => {
    return (
      <div className=" z-index-1 " style={{ width: 150 }}>
        {this.state.modules &&
          Object.keys(this.state.modules)?.map((obj, index) => {
            let title = this.titleFormate(obj);
            return (
              <div
                className="row justify-content-between  cursor-hand"
                onClick={() => this.onModuleChange(obj)}
              >
                <DKLabel
                  text={getCapitalized(title)}
                  className={
                    "listPickerBG parent-width p-v-s pl-xs " +
                    (obj === this.state.selectedModule ? "fw-m text-app " : "")
                  }
                  style={{
                    backgroundColor:
                      obj === this.state.selectedModule ? "#f4f4f6" : ""
                  }}
                />
                {obj === this.state.selectedModule && (
                  <div
                    className="bg-button parent-height"
                    style={{ width: 2, height: 30 }}
                  />
                )}
              </div>
            );
          })}
      </div>
    );
  };
  render() {
    return (
      <div className="parent-size">
        {/* {this.getHeaderSection()} */}
        <div className="mt-l mb-s flex-1 parent-width row align-items-start border-m-b">
          {this.getLeftPanel()}
          <div
            className=" parent-height bg-gray2 column"
            style={{ width: 1, marginLeft: -2, height: 400 }}
          />
          <div className="column parent-width">
            <div className="row justify-content-between">
              <DKLabel className="pl-l fs-r fw-m mb-m" text={`Event Name`} />
              <DKLabel
                className="pl-l fs-r fw-m mb-m"
                text={`Score`}
                style={{ width: "47%" }}
              />
            </div>
            {this.getRightPanel()}
          </div>
        </div>
        {this.getButtons()}
      </div>
    );
  }
}
