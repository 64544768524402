import ApiConstants from "../constants/ApiConstants";
import http from "../http";

export interface AttachmentConfig {
  Module: string;
  EntityId?: any;
}
class AttachmentService {
  static attachmentConfig: AttachmentConfig;

  static getAllAttachments() {
    const finalEndPoint = ApiConstants.URL.BOOKS.ATTACHMENT.GET_ALL(
      this.attachmentConfig.EntityId,
      this.attachmentConfig.Module
    );
    return http
      .get(`${finalEndPoint}`)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static uploadAttachment(fileData: File, updateProgress?: any) {
    const formData = new FormData();
    if (this.attachmentConfig.EntityId) {
      formData.append("entityId", this.attachmentConfig.EntityId);
    }
    formData.append("entityName", this.attachmentConfig.Module);
    formData.append("file", fileData);

    return http
      .post(`${ApiConstants.URL.BOOKS.ATTACHMENT.UPLOAD}`, formData, {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          let percentComplete = progressEvent.loaded / progressEvent.total;
          percentComplete = parseInt((percentComplete * 100).toString());
          if (updateProgress) {
            updateProgress({ fileName: fileData.name, percentComplete });
          }
        }
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static downloadAttachment(attachmentId: any) {
    const finalEndPoint =
      ApiConstants.URL.BOOKS.ATTACHMENT.DOWNLOAD(attachmentId);
    return http
      .get(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updateAttachment(
    entityId: Number,
    entityName: String,
    attachmentId: any
  ) {
    const finalEndPoint = ApiConstants.URL.BOOKS.ATTACHMENT.UPDATE(
      entityId,
      entityName,
      attachmentId
    );
    return http
      .put(finalEndPoint)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static deleteAttachment(attachmentId: any) {
    const finalEndPoint =
      ApiConstants.URL.BOOKS.ATTACHMENT.DELETE(attachmentId);
    return http
      .delete(finalEndPoint)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default AttachmentService;
