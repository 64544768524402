import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  DKLabel,
  DKIcon,
  showAlert,
  DKTooltipWrapper
} from "deskera-ui-library";
import { fetchTickets } from "../../../redux/slices/recordSlice";
import { TICKET_PRIORITIES, TICKET_STATUS } from "../../../constants/Enum";
import { getDashboardWidgetLoading } from "../../dashboard/Dashboard";
import Utility from "../../../utility/Utility";
import { store } from "../../../redux/store";
import { showAddTicketPopup } from "../../support_ticket/AddTicket";
import { showSmtpPopup } from "../../campaign/SmtpPopup";
import { fetchSupportEmails } from "../../../redux/slices/userPrefSlice";
import TicketManager from "../../../managers/TicketManager";
import RouteManager, { PAGE_ROUTES } from "../../../managers/RouteManager";
interface ITicketCardProps extends PropsFromRedux {
  selectedStatus: any;
}
interface ITicketCardState {
  showLoading: any;
  selectedStatus: any;
}
class TicketCard extends Component<ITicketCardProps, ITicketCardState> {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      selectedStatus: this.props.selectedStatus
    };
  }
  componentDidMount() {
    this.setState(
      {
        showLoading: true
      },
      this.fetchTickets
    );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedStatus != this.props.selectedStatus) {
      this.setState(
        { selectedStatus: nextProps.selectedStatus },
        this.fetchTickets
      );
    }
  }
  fetchTickets = () => {
    this.setState({ showLoading: true });
    const params = {
      ticket_view_id: null,
      search: "",
      crm3_contact_id: null,
      assignee: null,
      status: this.state.selectedStatus?.name,
      orderBy: "updated_at",
      page: 1,
      limit: 20
    };
    this.props.fetchTickets({ params }).then((response: any) => {
      this.setState({ showLoading: false });
    });
  };
  getEmptyState = () => {
    return (
      <div className="column parent-width parent-height align-items-center justify-content-center">
        <DKLabel text="No data found" className="fw-m fs-m text-gray" />
        <DKLabel text="Create ticket form here" className="text-gray mt-xs" />
      </div>
    );
  };
  render() {
    return (
      <>
        {this.state.showLoading && getDashboardWidgetLoading()}
        {this.props?.tickets.data?.length === 0
          ? this.getEmptyState()
          : this.getRows()}
      </>
    );
  }
  getRows = () => {
    return this.props.tickets.data?.map((ticket) => (
      <div
        className="row pt-xs pb-s justify-content-between listPickerBG border-radius-s cursor-hand"
        onClick={() => {
          TicketManager.setSelectedTicket(ticket);
          RouteManager.navigateToPage(PAGE_ROUTES.SUPPORT_TICKET);
        }}
      >
        <div className="row" style={{ width: "71%" }}>
          <div
            className="column parent-width"
            style={{
              overflowWrap: "break-word",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden"
            }}
          >
            <div className="row width-auto">
              <DKLabel
                text={ticket.ticket_no}
                className="fw-m parent-width"
                style={{
                  overflowWrap: "break-word",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              />
              <DKTooltipWrapper
                content={`${ticket?.priority}`}
                tooltipClassName=""
              >
                <DKIcon
                  className="circle ic-s ml-s"
                  style={{ verticalAlign: "middle" }}
                  src={
                    TICKET_PRIORITIES.find(
                      (priority) => priority.name === ticket.priority
                    )?.icon
                  }
                />
              </DKTooltipWrapper>
            </div>
            {!Utility.isEmptyObject(ticket?.assignee_details) && (
              <DKLabel
                text={`Assignee: ${ticket?.assignee_details?.first_name || ""} ${ticket?.assignee_details?.last_name || ""}`}
                className="text-gray mt-xs"
                style={{
                  overflowWrap: "break-word",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden"
                }}
              />
            )}
          </div>
        </div>
        <div className="column d-flex align-items-end">
          <DKLabel
            text={ticket.status}
            className={`${this.getChipColorClass(
              ticket.status
            )} fs-r p-v-xs p-h-s border-radius-r`}
          />
        </div>
      </div>
    ));
  };
  getChipColorClass = (selectedStatus) => {
    const index = TICKET_STATUS.findIndex(
      (status) => status === selectedStatus
    );
    switch (TICKET_STATUS[index]) {
      case "Solved":
        return "bg-chip-green text-green";
      case "Open":
        return "bg-chip-blue text-blue";
      case "Pending":
        return "bg-chip-orange text-orange";
      default:
        return "bg-chip-green text-green";
    }
  };
}
const mapStateToProps = (state) => ({
  tickets: state.records.tickets || {}
});
const mapDispatchToProps = { fetchTickets };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(TicketCard);

export function checkAndOpenAddTicket() {
  const getSMTPPopup = () => {
    showSmtpPopup({
      supportEmail: true,
      showImap: true,
      onCancel: () => {},
      onSave: (smtpData) => {
        store.dispatch(fetchSupportEmails());
      }
    });
  };
  const supportEmails = store.getState().userPref.supportEmails;
  if (Utility.isEmptyObject(supportEmails?.data)) {
    store.dispatch(fetchSupportEmails()).then((response: any) => {
      if (!Utility.isEmptyObject(response?.payload?.data)) {
        showAddTicketPopup({
          onSave: () => {},
          onCancel: () => {}
        });
      } else {
        const buttons = [
          {
            title: "Cancel",
            className: "bg-gray1 border-m",
            onclick: () => {}
          },
          {
            title: "Add Email",
            className: "bg-button text-white ml-r",
            onClick: () => getSMTPPopup()
          }
        ];
        showAlert(
          "New Ticket?",
          "To Create a Ticket, You Will Require To Setup an Email.",
          buttons
        );
      }
    });
  } else {
    showAddTicketPopup({
      onSave: () => {},
      onCancel: () => {}
    });
  }
}
