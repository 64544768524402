import http from "../http";
import { removeLoader } from "deskera-ui-library";
import ApiConstants from "../constants/ApiConstants";

export interface ShopProductQuery {
  q?: string;
  pageSize?: number;
  pageNo?: number;
  sortDir?: string;
  sortBy?: string;
  shopId?: string;
}

export interface ShopCategoryQuery {
  q?: string;
  pageSize?: number;
  pageNo?: number;
  sortDir?: string;
  sortBy?: string;
  shopId?: string;
  category_type?: string;
}

export const DEFAULT_CONFIG: ShopProductQuery = {
  q: "",
  pageSize: 20,
  pageNo: 0,
  sortDir: "desc",
  sortBy: "createdAt"
};
export default class Shop {
  /* ****************** SHOP APIS **************************** */
  static getShopByTenantId() {
    return http
      .get(`${ApiConstants.URL.SHOP.GET}`)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static addShop(data) {
    return http
      .post(`${ApiConstants.URL.SHOP.ADD}`, data)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static deleteShop(shopId) {
    return http
      .delete(`${ApiConstants.URL.SHOP.UPDATE(shopId)}`)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static updateShop(data, shopId) {
    return http
      .put(`${ApiConstants.URL.SHOP.UPDATE(shopId)}`, data)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }

  /* ****************** SHOP CATEGORY APIS **************************** */
  static async getCategory(query: any = {}) {
    query["pageSize"] = 50;
    return http
      .get(ApiConstants.URL.SHOP.CATEGORY.GET, { params: query })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
  static getShopCategories() {
    return http
      .get(`${ApiConstants.URL.SHOP.CATEGORY.GET}`)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }

  static addCategory(data: any) {
    return http
      .post(ApiConstants.URL.SHOP.CATEGORY.ADD, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static async getProductCountByCategory(params = {}) {
    return http
      .get(ApiConstants.URL.SHOP.PRODUCT.COUNT_BY_CATEGORY, { params: params })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /* ****************** SHOP PRODUCT APIS **************************** */
  static async getProductsByPage(query: any) {
    const queryParams = { ...DEFAULT_CONFIG, ...query, pageSize: 100 };
    return http
      .get(ApiConstants.URL.SHOP.PRODUCT.GET, { params: queryParams })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static async getProductById(productId: string) {
    const getProductByIdUrl =
      ApiConstants.URL.SHOP.PRODUCT.GET_BY_ID(productId);
    return http
      .get(getProductByIdUrl)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static createProduct(data: any) {
    return http
      .post(ApiConstants.URL.SHOP.PRODUCT.CREATE, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static updateProduct(data: any, productId: string) {
    const updateProductUrl = ApiConstants.URL.SHOP.PRODUCT.UPDATE(productId);
    return http
      .put(updateProductUrl, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static deleteProduct(productId: string) {
    return http
      .delete(ApiConstants.URL.SHOP.PRODUCT.DELETE(productId))
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /* ****************** SHOP LEAD APIS **************************** */
  static async getLeadsByPage(query: any) {
    const queryParams = { ...DEFAULT_CONFIG, ...query };
    return http
      .get(ApiConstants.URL.SHOP.LEAD.GET, { params: queryParams })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}
