import { Component } from "react";
import {
  DKIcons,
  DKLabel,
  DKIcon,
  showToast,
  TOAST_TYPE,
  DKLine,
  getDateAsString
} from "deskera-ui-library";
import DKDateTime from "./DKDateTime";
import { ACTIVITY_DATE_FORMAT } from "../../constants/Constant";
import {
  convertCRMDateFormatToUILibraryFormat,
  DateUtil
} from "../../utility/Date";

export default class DKInputDateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: this.props.selectedDate
        ? this.props.selectedDate
        : new Date(),
      needDateTime: false,
      isPast: this.props.pastDate ? this.props.pastDate : false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedDate: nextProps.selectedDate,
      isPast: nextProps.pastDate
    });
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.title && (
          <DKLabel
            text={this.props.title}
            className=" mt-l mb-s datetime-title"
          />
        )}
        <div
          className="p-s border-m border-radius-s bg-gray1 row justify-content-between cursor-hand"
          onClick={() => this.showDateTimePopup()}
        >
          <div className="row" style={{ width: "auto" }}>
            <div className="position-relative row" style={{ width: "auto" }}>
              <DKIcon src={DKIcons.ic_calendar} className="ic-s mr-r" />
              <DKLabel text={this.getFormattedDateString()} />
            </div>
          </div>
          {/* <DKButton title="Change" className="bg-button text-white" onClick={() => this.showDateTimePopup()} /> */}
        </div>
        {this.state.needDateTime && this.getDateTimePicker()}
      </div>
    );
  }
  showDateTimePopup = () => {
    this.setState({ needDateTime: true });
  };

  hideDateTimePopup = () => {
    this.setState({ needDateTime: false });
  };

  getDateTimePicker = () => {
    return (
      <div className="transparent-background">
        <div className="popup-window dk-input-datetime-popup">
          <DKLabel text={this.props.title} className="fw-m fs-m mb-m" />
          <DKLine className="mb-m" />
          <DKDateTime
            is12Hour={true}
            selectedDate={this.state.selectedDate}
            onSave={(date) => {
              this.publishDateUpdate(date);
            }}
            onClose={() => {
              this.hideDateTimePopup();
            }}
          />
        </div>
      </div>
    );
  };

  getFormattedDateString = () => {
    const DATE_FORMAT =
      `EEE, ${convertCRMDateFormatToUILibraryFormat(DateUtil.getOrgDateFormat())}, HH:mm aa` ||
      ACTIVITY_DATE_FORMAT;
    let date = this.state.selectedDate
      ? new Date(this.state.selectedDate)
      : new Date();
    return getDateAsString(date, DATE_FORMAT);
  };

  publishDateUpdate = (date) => {
    var dateNew = Math.round(new Date(date).getTime() / 1000);
    let todayDate = Math.round(new Date().getTime() / 1000);
    if (todayDate > dateNew) {
      if (this.state.isPast) {
        showToast("Please do not select past date", TOAST_TYPE.FAILURE);
        return;
      }
    }
    this.hideDateTimePopup();
    this.props.onSave(date);
  };
}
