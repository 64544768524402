import { Fragment, useEffect, useState } from "react";
import {
  DKLabel,
  DKButton,
  DKListPicker,
  showToast,
  DKIcons,
  DKSpinner
} from "deskera-ui-library";

import IAM from "../../services/iam";
import AppManager from "../../managers/AppManager";
import UserManager from "../../managers/UserManager";
import { fetchTenants, getTenants } from "../../redux/slices/tenantSlice";

import Popup from "../common/Popup";
import { AUTHORIZATION_STATUS } from "../../constants/Enum";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { commonCustomEvent, COMMON_EVENTS } from "../../services/event";

interface ISubscriptionAlertProps {
  customMessage: string;
  onClose: () => void;
}

const SubscriptionAlert = (props: ISubscriptionAlertProps) => {
  const [authorizationStatus, setAuthorizationStatus] = useState(
    AUTHORIZATION_STATUS.DENIED
  );
  const [showTenantList, setShowTenantList] = useState(false);

  const tenantList = useAppSelector(getTenants());
  const dispatch = useAppDispatch();

  const handleAuthorizationStatusUpdate = (eventData: any) => {
    eventData?.status && setAuthorizationStatus(eventData.status);
  };

  useEffect(() => {
    dispatch(fetchTenants());

    commonCustomEvent.on(
      COMMON_EVENTS.SUBSCRIPTION_CHECK,
      handleAuthorizationStatusUpdate
    );

    return () => {
      commonCustomEvent.remove(
        COMMON_EVENTS.SUBSCRIPTION_CHECK,
        handleAuthorizationStatusUpdate
      );
    };
  }, []);

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  function handleTenantSelection(newSelectedTenantDetails) {
    if (newSelectedTenantDetails.tenantId !== UserManager.getUserTenantID()) {
      IAM.switchTenant({ tenantId: newSelectedTenantDetails.tenantId }).then(
        (res) => {
          AppManager.reloadApp();
        },
        (err) => {}
      );
    } else {
      showToast("Current Organisation");
    }

    setShowTenantList(false);
  }
  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////// RENDER UTILS ///////////////////////////////////
  function getAlertHeader() {
    return (
      <div className="row align-items-start">
        <DKLabel
          text={
            authorizationStatus === AUTHORIZATION_STATUS.AUTHORIZED
              ? "Subscribed Successfully!"
              : UserManager.isTrialPlanAvailable()
                ? "No Active Subscription!"
                : "Subscription Expired!"
          }
          className="fs-m fw-m mt-s mr-l width-auto"
        />
        <div className="position-relative">
          {authorizationStatus === AUTHORIZATION_STATUS.PENDING ? (
            <DKSpinner className="position-absolute" />
          ) : null}
        </div>
      </div>
    );
  }

  function getAlertMessage() {
    return (
      <DKLabel
        text={
          authorizationStatus === AUTHORIZATION_STATUS.AUTHORIZED
            ? `Your subscription has been successfully done, you can now continue using the app`
            : props.customMessage ||
              `It looks like you don't have any active plan and you have already used your trial period. Please switch to an organization having active subscription or subscribe to a new plan to continue using the product.`
        }
        className="mt-l"
      />
    );
  }

  function getAlertControls() {
    return (
      <div className="row row-responsive mt-xl">
        {authorizationStatus === AUTHORIZATION_STATUS.AUTHORIZED ? (
          <DKButton
            title="Close"
            icon={DKIcons.ic_close_2}
            onClick={() => props.onClose()}
            className="bg-gray2 border-m cursor-hand"
          />
        ) : (
          <Fragment>
            <div className="position-relative">
              <DKButton
                title="Switch Organization"
                icon={DKIcons.ic_arrow_down}
                isReverse={true}
                onClick={() => setShowTenantList(true)}
                className="bg-gray2 border-m cursor-hand"
              />
              {showTenantList ? (
                <DKListPicker
                  data={tenantList.map((tenant: any) => tenant.tenantName)}
                  className="position-absolute parent-width z-index-3 border-m shadow-s hide-scroll-bar"
                  style={{
                    top: "90%",
                    left: 0
                  }}
                  onSelect={(index: number, selectedName: string) =>
                    handleTenantSelection(tenantList[index])
                  }
                  onClose={() => setShowTenantList(false)}
                />
              ) : null}
            </div>
            <DKButton
              title={"Subscribe Now"}
              onClick={() => {
                window.open(process.env.REACT_APP_URL_GO + `billing`, "_blank");
              }}
              className="bg-button text-white border-app ml-r cursor-hand"
            />
            {/* <DKButton
              title="Logout"
              onClick={() => {
                AppManager.logout();
              }}
              icon={DKIcons.ic_logout}
              className="ml-r bg-gray2 border-m cursor-hand"
            /> */}
          </Fragment>
        )}
      </div>
    );
  }

  return (
    <Popup
      popupWindowStyles={{
        overflowY: "visible"
      }}
    >
      {getAlertHeader()}
      {getAlertMessage()}
      {getAlertControls()}
    </Popup>
  );
};

export default SubscriptionAlert;
