import ApiConstants from "../constants/ApiConstants";
import http from "../http";
import { removeLoader, showLoader } from "deskera-ui-library";
import { APP_NAME } from "../constants/Constant";

export default class SMTPService {
  static saveSetup(data) {
    showLoader("Saving... Please wait");
    return http
      .post(ApiConstants.URL.SMTP.SETUP, data)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }

  static updateSetup(data, setupId) {
    showLoader("Saving... Please wait");
    return http
      .put(`${ApiConstants.URL.SMTP.SETUP_WITH_ID(setupId)}`, data)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }

  static getSetups() {
    showLoader("Fetching... Please wait");
    return http
      .get(`${ApiConstants.URL.SMTP.GET_ALL_SETUPS}`)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }

  static getSetupById(setupId) {
    return http
      .get(`${ApiConstants.URL.SMTP.SETUP_WITH_ID(setupId)}`)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static deleteSetupById(setupId) {
    return http
      .delete(`${ApiConstants.URL.SMTP.SETUP_WITH_ID(setupId)}`)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static saveImapSetup(data, supportEmail) {
    showLoader("Saving... Please wait");
    return http
      .post(
        `${supportEmail ? ApiConstants.URL.SUPPORT_EMAIL.ADD_EMAIL : ApiConstants.URL.EMAIL_LOG.CHECK_EMAIL_SETUP} `,
        data
      )
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static deleteEmailSetup() {
    let params = { app_name: APP_NAME };
    return http
      .delete(ApiConstants.URL.EMAIL_LOG.CHECK_EMAIL_SETUP, { params })
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
}
