import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { DKLabel, DKButton } from "deskera-ui-library";
import "./stackStyles.css";
import AnimatedStackItem from "./AnimatedStackItem";
import Utility from "../../../utility/Utility";
import DKLabel2 from "../DKLabel2";

/**
 * PROPS
 *
 * heroSubTitle?: string;
 * heroTags?: { name: string, className: string }[];
 * heroTitle: string;
 * stackItems: string[];
 * stackTheme?: { background: string, color: string };
 * stackShuffleInterval?: number;
 * className?: string;
 * style?: { [property]: propertyValue };
 * ctaData?: { link: string; label: string; description: string; footer?: string;}
 */

const AnimatedStackHero = ({
  heroTitle,
  stackItems = [],
  stackTheme = { background: "bg-dark-gray", color: "text-white" },
  stackShuffleInterval = 1000,
  heroTags = [],
  heroSubTitle = "",
  className = "text-dark-gray",
  style = {},
  ctaData = null
}) => {
  const stackContainerRef = useRef(null);
  const [currentVisibleStackIndex, setCurrentVisibleStackIndex] = useState(
    stackItems.length - 1
  );

  useEffect(() => {
    const intervalId = setInterval(shuffleStackItem, stackShuffleInterval);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shuffleStackItem = useCallback(() => {
    setCurrentVisibleStackIndex(
      (currentVisibleStackIndex) => currentVisibleStackIndex + 1
    );
  }, []);

  function getActualStackIndex(stackIndex) {
    return stackIndex % stackItems.length;
  }

  const currentStackItemsIndices = useMemo(() => {
    return {
      elementIndexInFront: getActualStackIndex(currentVisibleStackIndex + 1),
      elementIndexInMid: getActualStackIndex(currentVisibleStackIndex + 2),
      elementIndexInBack: getActualStackIndex(currentVisibleStackIndex + 3)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVisibleStackIndex]);

  function getStackItemStyles(itemIndex) {
    const { elementIndexInFront, elementIndexInMid, elementIndexInBack } =
      currentStackItemsIndices;

    if (itemIndex === elementIndexInFront) {
      return {
        pointerEvent: "auto",
        zIndex: 3,
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
        opacity: 1,
        transition: "opacity 0s, transform 0.3s ease-in"
      };
    } else if (itemIndex === elementIndexInMid) {
      return {
        pointerEvent: "auto",
        zIndex: 2,
        transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, -50, 1)",
        opacity: 0.5
      };
    } else if (itemIndex === elementIndexInBack) {
      return {
        pointerEvent: "auto",
        zIndex: 1,
        transform:
          "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, -100, 1)",
        opacity: 0.25
      };
    } else {
      return null;
    }
  }

  return (
    <section className={`dk-animated-hero ${className}`} style={{ ...style }}>
      <div className="row" style={{ gap: 4 }}>
        {heroSubTitle ? (
          <DKLabel2 className="fs-xl-2 fw-m mr-l" text={heroSubTitle} />
        ) : null}
        {heroTags ? (
          <div className="row" style={{ gap: 8 }}>
            {heroTags.map((tag) => (
              <DKLabel2
                className={"row fw-m border-radius-s " + tag.color}
                text={tag.name}
                style={{
                  width: "auto",
                  paddingTop: 2,
                  paddingBottom: 4,
                  paddingLeft: 6,
                  paddingRight: 6
                }}
              />
            ))}
          </div>
        ) : null}
      </div>
      <DKLabel2 text={heroTitle} className="animated-stack-title" />
      <div className="dk-animated-stack" ref={stackContainerRef}>
        {stackItems.map((stackItem, index) => (
          <AnimatedStackItem
            id={`stack-item-${index}`}
            name={stackItem}
            bgColor={stackTheme.background}
            color={stackTheme.color}
            styles={getStackItemStyles(index)}
          />
        ))}
      </div>
      {ctaData ? (
        <div className="row width-auto cta-button" style={{ style: 50 }}>
          <DKLabel2
            text={ctaData.description}
            className={"row parent-height bg-lead-gray text-gray p-h-r p-v-m"}
            style={{
              maxHeight: 50,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10
            }}
          />
          <DKButton
            title={ctaData.label}
            className="bg-silver-gray text-white parent-height fw-m"
            style={{
              margin: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: 10,
              borderBottomRightRadius: 10,
              height: 50
            }}
            onClick={() => Utility.openInNewTab(ctaData.link)}
          />
        </div>
      ) : null}
      {ctaData.footer ? (
        <DKLabel2 text={ctaData.footer} className="mt-l fs-s pl-s text-gray" />
      ) : null}
    </section>
  );
};

export default AnimatedStackHero;
