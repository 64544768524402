import {
  DKButton,
  DKIcon,
  DKIcons,
  DKLabel,
  DKTooltipWrapper
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { fetchIcalUrlSettings } from "../../redux/slices/userPrefSlice";
import Utility from "../../utility/Utility";

export const ActivitySetting = (props) => {
  const [isLinkCopied, setLinkCopied] = useState<boolean>(false);

  useEffect(() => {
    if (Utility.isEmptyObject(props.icalUrl)) {
      props.fetchIcalUrlSettings();
    }
  });

  return (
    <div>
      <div className="column parent-width m-v-l">
        <DKLabel
          text={"Activity Settings"}
          className="fw-m fs-l pb-s parent-width"
        />
        <DKLabel
          text={`Link to import activity has been generated. Use below url to import
        activity in your google calendar`}
          className="text-align-center1"
          style={{ whiteSpace: "normal" }}
        />
        <div className="p-s bg-gray1 border-s border-radius-s mt-l mb-l row justify-content-between">
          <div className="text-align-left word-wrap-all fs-r text-gray">
            {props.icalUrl}
          </div>
          <DKButton
            title={`${isLinkCopied ? "Copied" : "Copy"}`}
            className="text-white bg-button ml-r"
            onClick={() => {
              setLinkCopied(true);
              Utility.copyToClipBoard(props.icalUrl);
            }}
          />
        </div>
        <div className="row">
          <DKLabel text={`How to import in Google calendar?`} />
          <DKTooltipWrapper
            content={`1. Open Google Calendar. <br> 2. In the top right, click Settings.<br> 3. On the left panel, under “Add calender,” click on "From URL"<br> 4. Add/paste your url that copied from Deskera activity setting and click on "Add Calender" button.<br>
          `}
            tooltipClassName=""
          >
            <DKIcon
              src={DKIcons.ic_info}
              className="ic-xs ml-s"
              style={{
                paddingTop: 2,
                opacity: 0.5
              }}
            />
          </DKTooltipWrapper>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  icalUrl: state.userPref.activityIcalUrl
});

const mapDispatchToProps = { fetchIcalUrlSettings };

export default connect(mapStateToProps, mapDispatchToProps)(ActivitySetting);
