import React, { Component } from "react";
import { DKButton, DKIcons, DKLabel, showAlert } from "deskera-ui-library";
import ElementList from "../ElementList";
import ComponentManager from "../ComponentManager";
import ElementComponentList from "../ElementComponentList";
import { PAGE_ELEMENT } from "../ComponentList";
import { shiftArrayElement } from "../../../utility/Utility";
import { getViewportSize } from "../../../utility/GetViewportSize";
import { VIEWPORT_SIZES } from "../../../constants/Constant";
import PlaceholderButton from "./PlaceholderButton";

/*
PROPS
- data
- index
- borderStyle
- components
- currentComponentIndex
- currentColumnSectionIndex
- isEditing
- hash
*/
export default class ColumnSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColumnIndex: null,
      components: this.props.components,
      currentComponentIndex: this.props.currentComponentIndex,
      currentColumnSectionIndex: this.props.currentColumnSectionIndex,
      currentColumnComponentIndex: this.props.currentColumnComponentIndex,
      isEditing: this.props.isEditing,
      needToShowImagePopup: this.props.needToShowImagePopup,
      isInlineTextEdit: this.props.isInlineTextEdit,
      showElement: false
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      components: nextProps.components,
      currentComponentIndex: nextProps.currentComponentIndex,
      currentColumnSectionIndex: nextProps.currentColumnSectionIndex,
      currentColumnComponentIndex: nextProps.currentColumnComponentIndex,
      isEditing: nextProps.isEditing,
      needToShowImagePopup: nextProps.needToShowImagePopup,
      isInlineTextEdit: nextProps.isInlineTextEdit
    });
  }

  render() {
    return (
      <div
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle,
          display: "flex",
          borderSpacing: "" + this.props.data.style.cellSpacing + "px 0px"
        }}
        className={`column-section-${this.props.index}`}
      >
        {this.getColumnItems(this.props.data.value)}
      </div>
    );
  }

  getColumnItems(items) {
    let arr = [];
    if (items !== null && items !== undefined) {
      items.forEach((item, index) => {
        let checkObjectArray = Array.isArray(item.value);
        if (
          item.type === PAGE_ELEMENT.FORM ||
          item.type === PAGE_ELEMENT.SOCIAL_BUTTONS
        ) {
          return false;
        }
        if (!checkObjectArray) {
          return false;
        }
        arr.push(this.getColumnItem(index, item, items));
      });
    }
    if (arr.length === 0 && this.state.isEditing) {
      arr.push(this.getBlankSection());
    }
    return arr;
  }

  getColumnItem(index, data, items) {
    let width = this.props.viewType === "phone" ? 100 : 100 / items.length;
    return (
      <>
        <div
          id={`column-${this.props.index + "-" + index}-component`}
          key={index}
          className="column-section-list position-relative mobile-view"
          style={{
            width: width + "%",
            display: "inline-grid",
            wordBreak: "break-word",
            verticalAlign: "top",
            outline: this.state.isEditing ? "1px dotted #cbc2c2" : "none",
            minHeight: 50
          }}
          // onClick={(e) => {
          //   if (data.value !== null) {
          //     this.props.onComponentSelect(this.props.index, index);
          //   }
          //   e.stopPropagation();
          // }}
          // onDoubleClick={(e) => {
          //   if (data.value !== null && data.type === PAGE_ELEMENT.FORM) {
          //     this.props.onComponentSelect(this.props.index, index, true);
          //   }
          //   e.stopPropagation();
          // }}
          // onMouseOver={(e) => {
          //   this.onMouseParentSection(this.props.index, index);
          // }}
          onMouseLeave={(e) => {
            this.setState({ selectedColumnIndex: null });
            e.stopPropagation();
          }}
        >
          {data.value.length === 0 && this.state.isEditing && (
            <div
              id={`column-item-${this.props.index + "-" + index + "-0"}-component`}
              key={`column-item-${this.props.index + "-" + index + "-0"}-component`}
              onMouseOver={(e) => {
                this.onMouseColumnParentSection(this.props.index, index, 0);
              }}
              className="column-section-list-item column align-items-center justify-content-center parent-width parent-height"
            >
              <DKButton
                icon={DKIcons.ic_add}
                className="position-relative border-radius-none"
                iconClassName="ic-m"
                onClick={(e) => {
                  let screenSize = getViewportSize();
                  if (
                    screenSize === VIEWPORT_SIZES.EXTRA_SMALL ||
                    screenSize === VIEWPORT_SIZES.SMALL
                  ) {
                    this.onMobileToolTip(this.props.index, null);
                  } else {
                    this.props.onComponentSelect(this.props.index, null);
                  }
                  this.setState({ selectedColumnIndex: index });
                  e.stopPropagation();
                }}
              />
              {index === this.state.selectedColumnIndex && (
                <div
                  className="position-absolute z-index-1 shadow-m"
                  style={{ top: 47, zIndex: 1 }}
                >
                  <DKButton
                    icon={DKIcons.ic_close}
                    className="bg-white border-radius-none m-s"
                    iconClassName="ic-m"
                    style={{ float: "right" }}
                    onClick={(e) => {
                      this.setState({ selectedColumnIndex: null });
                      e.stopPropagation();
                    }}
                  />
                  <ElementList
                    pageType={this.props.pageType}
                    columnHide={true}
                    stackHide={true}
                    viewType={this.state.viewType}
                    className="p-r"
                    onAdd={(type) => this.onColumnAddComponent(index, type)}
                  />
                </div>
              )}
            </div>
          )}
          {data.value.length > 0 &&
            data.value.map((data, itemIndex, items) => {
              return (
                <div
                  id={`column-item-${this.props.index + "-" + index + "-" + itemIndex}-component`}
                  key={`column-item-${this.props.index + "-" + index + "-" + itemIndex}-component`}
                  style={{ display: "inline-grid" }}
                  className="column-section-list-item"
                  onMouseOver={(e) => {
                    this.onMouseColumnParentSection(
                      this.props.index,
                      index,
                      itemIndex
                    );
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (data.value !== null) {
                      let screenSize = getViewportSize();
                      if (
                        screenSize === VIEWPORT_SIZES.EXTRA_SMALL ||
                        screenSize === VIEWPORT_SIZES.SMALL
                      ) {
                        this.onMobileToolTip(
                          this.props.index,
                          index,
                          itemIndex
                        );
                      } else {
                        this.props.onComponentSelect(
                          this.props.index,
                          index,
                          itemIndex
                        );
                      }
                    }
                    e.stopPropagation();
                  }}
                  onDoubleClick={(e) => {
                    if (
                      data.value !== null &&
                      data.type === PAGE_ELEMENT.FORM
                    ) {
                      this.props.onComponentSelect(
                        this.props.index,
                        index,
                        itemIndex,
                        true
                      );
                    }
                    e.stopPropagation();
                  }}
                  onMouseDown={(e) => {
                    if (
                      (this.state.currentColumnComponentIndex !== null &&
                        this.state.currentColumnComponentIndex !== undefined &&
                        this.state.currentColumnComponentIndex !== itemIndex) ||
                      (this.state.currentColumnSectionIndex !== null &&
                        this.state.currentColumnSectionIndex !== undefined &&
                        this.state.currentColumnSectionIndex !== index)
                    ) {
                      const div = document.getElementById(`dummy-focus-div`);
                      div.focus();
                    }
                  }}
                  onMouseLeave={(e) => {
                    this.hideElement();
                    e.stopPropagation();
                  }}
                >
                  {this.state.isEditing &&
                    this.getCanvasTootip(
                      this.props.index,
                      index,
                      itemIndex,
                      data.type,
                      items
                    )}
                  <ElementComponentList
                    index={itemIndex}
                    columnIndex={index}
                    componentIndex={this.props.index}
                    colComponentIndex={itemIndex}
                    data={data}
                    hash={this.props.hash}
                    isEditing={this.state.isEditing}
                    components={this.state.components}
                    currentComponentIndex={this.state.currentComponentIndex}
                    currentColumnSectionIndex={
                      this.state.currentColumnSectionIndex
                    }
                    currentColumnComponentIndex={
                      this.state.currentColumnComponentIndex
                    }
                    viewType={this.state.viewType}
                    needToShowImagePopup={this.state.needToShowImagePopup}
                    isInlineTextEdit={this.state.isInlineTextEdit}
                    onDataChange={(data) => {}}
                    onComponentSelect={(index, itemIndex, inlineEdit) => {}}
                    onCloseInlineEdit={() => {
                      // if(this.state.isInlineTextEdit &&
                      //   this.state.currentColumnComponentIndex === null &&
                      //   this.state.currentColumnSectionIndex === null
                      // ) {
                      this.props.onCloseInlineEdit();
                      // }
                    }}
                    onClosePopUp={() => this.props.onClosePopUp()}
                    onShowImagePopup={() => this.props.onShowImagePopup()}
                    onShowInlineEdit={() => {}}
                    enableInlineTextEdit={() =>
                      this.props.enableInlineTextEdit()
                    }
                  />
                </div>
              );
            })}
        </div>
      </>
    );
  }

  hideElement = () => {
    this.setState({ showElement: false });
  };

  onColumnAddComponent = (columnIndex, type) => {
    let components = [...this.props.data.value];
    components[columnIndex].value.push(
      ComponentManager.getComponentDefaultData(type)
    );
    this.props.onValueChange(components);
    this.hideElement();
    this.setState({ selectedColumnIndex: null });
  };

  getCanvasTootip = (index, colIndex, itemIndex, type, items) => {
    return (
      <div
        className="column position-absolute dk-tootip-column z-index-1"
        id={`tooltip-item-${index + "-" + colIndex + "-" + itemIndex}-column-component`}
        style={{ display: "none", width: "auto" }}
        onMouseDown={(e) => {
          const div = document.getElementById(`dummy-focus-div`);
          div.focus();
        }}
      >
        <div className="row ">
          {itemIndex !== items.length - 1 && (
            <DKButton
              icon={DKIcons.ic_arrow_down2}
              className="bg-gray1 border-m border-radius-none"
              onClick={() => {
                this.changeTootipEvent(
                  index,
                  colIndex,
                  itemIndex,
                  "down",
                  type,
                  items
                );
              }}
            />
          )}
          {itemIndex !== 0 && (
            <DKButton
              icon={DKIcons.ic_arrow_up2}
              className="bg-gray1 border-m border-radius-none"
              onClick={() => {
                this.changeTootipEvent(
                  index,
                  colIndex,
                  itemIndex,
                  "up",
                  type,
                  items
                );
              }}
            />
          )}
          <DKButton
            icon={DKIcons.ic_copy}
            className="bg-gray1 border-m border-radius-none"
            onClick={() => {
              this.changeTootipEvent(index, colIndex, itemIndex, "copy", type);
            }}
          />
          <DKButton
            icon={DKIcons.ic_add}
            className="bg-gray1 border-m border-radius-none"
            onClick={() => {
              this.changeTootipEvent(index, colIndex, itemIndex, "add", type);
            }}
          />
          <PlaceholderButton
            pageType={this.props.pageType}
            componentData={{
              elementType: type,
              index,
              componentIndex: index,
              columnIndex: colIndex,
              colComponentIndex: itemIndex
            }}
            onToolInitialized={() => {
              this.changeTootipEvent(index, colIndex, itemIndex, "edit", type);
              this.hideElement();
            }}
            onPlaceholderSelected={() => {
              this.changeTootipEvent(index, colIndex, itemIndex, "edit", type);
            }}
          />
          <DKButton
            icon={DKIcons.ic_edit}
            className="bg-gray1 border-m border-radius-none"
            onClick={() => {
              this.changeTootipEvent(index, colIndex, itemIndex, "edit", type);
            }}
          />
          <DKButton
            icon={DKIcons.ic_delete}
            className="bg-gray1 border-m border-radius-none"
            onClick={() => {
              this.changeTootipEvent(
                index,
                colIndex,
                itemIndex,
                "delete",
                type
              );
            }}
          />
        </div>
        <div className="z-index-1">
          {this.state.showElement && (
            <div
              className="position-absolute z-index-1 shadow-m"
              style={{ top: 32 }}
            >
              <DKButton
                icon={DKIcons.ic_close}
                className="bg-white border-radius-none m-s"
                iconClassName="ic-m"
                style={{ float: "right" }}
                onClick={(e) => {
                  this.setState({
                    showElement: false,
                    selectedColumnIndex: null
                  });
                  e.stopPropagation();
                }}
              />
              <ElementList
                pageType={this.props.pageType}
                className="p-r shadow-l"
                columnHide={true}
                stackHide={true}
                onAdd={(type) =>
                  this.onAddSectionComponent(
                    index,
                    colIndex,
                    itemIndex,
                    type,
                    "bottom"
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  onAddSectionComponent = (index, colIndex, itemIndex, type, position) => {
    if (position === "top") {
      itemIndex = itemIndex - 1;
    }
    this.props.onComponentSelect(index, colIndex, itemIndex, false);
    let components = { ...this.props.data };
    components.value[colIndex].value.splice(
      itemIndex + 1,
      0,
      ComponentManager.getComponentDefaultData(type)
    );
    this.hideElement();
  };

  changeTootipEvent = (
    index,
    colIndex,
    itemIndex,
    action,
    type,
    items = []
  ) => {
    switch (action) {
      case "add":
        this.setState({ showElement: true });
        break;
      case "edit":
        if (type === null) {
          this.setState({ selectedColumnIndex: colIndex });
          this.props.onComponentSelect(index, null, null, false);
          break;
        }
        this.props.onComponentSelect(index, colIndex, itemIndex, false);
        if (type === PAGE_ELEMENT.IMAGE) {
          this.props.onShowImagePopup();
        }
        if (type === PAGE_ELEMENT.TEXT) {
          this.props.onShowInlineEdit();
        }
        if (type === PAGE_ELEMENT.FORM) {
          this.props.onComponentSelect(index, colIndex, itemIndex, true);
        }
        break;
      case "delete":
        setTimeout(() => {
          this.props.onComponentSelect(index, null, null, false);
          this.onDeleteComponent(index, colIndex, itemIndex);
        }, 100);
        break;
      case "copy":
        setTimeout(() => {
          this.props.onComponentSelect(index, null, null, false);
          this.onCopyComponent(index, colIndex, itemIndex);
        }, 100);
        break;
      case "up":
        setTimeout(() => {
          this.props.onComponentSelect(index, null, null, false);
          let newComponents = shiftArrayElement(
            items,
            itemIndex,
            itemIndex - 1
          );
          this.saveComponent(newComponents, index, colIndex);
        }, 100);
        break;
      case "down":
        setTimeout(() => {
          this.props.onComponentSelect(index, null, null, false);
          let newComponents = shiftArrayElement(
            items,
            itemIndex,
            itemIndex + 1
          );
          this.saveComponent(newComponents, index, colIndex);
        }, 100);
        break;
      default:
        break;
    }
  };

  saveComponent = (components, index, colIndex) => {
    console.log(components, index, colIndex);
    let porpCompnent = [...this.props.components];
    porpCompnent[index].value[colIndex].value = components;
    console.log(porpCompnent[index].value);
    this.props.onValueChange(porpCompnent[index].value, index);
  };

  onCopyComponent = (index, colIndex, itemIndex) => {
    let components = [...this.props.components[index].value[colIndex].value];
    let tempMyObj = { ...components[itemIndex] };
    components.splice(itemIndex + 1, 0, JSON.parse(JSON.stringify(tempMyObj)));
    let porpCompnent = [...this.props.components];
    porpCompnent[index].value[colIndex].value = components;
    this.props.onValueChange(porpCompnent[index].value, index);
  };

  onDeleteComponent = (index, colIndex, itemIndex) => {
    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Delete",
        className: "bg-red text-white ml-r",
        onClick: () => {
          let components = [
            ...this.props.components[index].value[colIndex].value
          ];
          components.splice(itemIndex, 1);
          let porpCompnent = [...this.props.components];
          porpCompnent[index].value[colIndex].value = components;
          this.props.onValueChange(porpCompnent[index].value, index);
        }
      }
    ];
    showAlert(
      "Delete element?",
      "Deleting this element will delete it permanently.",
      buttons
    );
  };

  setTooltip = (index, colIndex) => {
    if (this.props.readOnly) {
      return;
    }
    let canvas = document.getElementById(
      `column-${index + "-" + colIndex}-component`
    );
    let tooltip = document.getElementById(
      `tooltip-${index + "-" + colIndex}-column-component`
    );
    if (tooltip) {
      tooltip.style.display = "flex";
      tooltip.style.top = canvas.offsetTop - 35 + "px";
      tooltip.style.left = canvas.offsetLeft - 2 + "px";
      canvas.className = canvas.className + " canvas-active";
    }
    this.setCanvasToolTip(index);
  };

  setCanvasToolTip = (index) => {
    let canvas = document.getElementById(`canvas-${index}-component`);
    let tooltip = document.getElementById(`tooltip-${index}-component`);

    if (tooltip) {
      tooltip.style.display = "flex";
      tooltip.style.top = canvas.offsetTop - 34 + "px";
      canvas.className = canvas.className + " canvas-active";
    }
  };

  // onMouseParentSection = (index, colIndex) => {
  //   this.onCanvasLeave();
  //   this.setTooltip(index, colIndex);
  // };

  onMouseColumnParentSection = (index, colIndex, itemIndex) => {
    this.onCanvasLeave();
    this.setColumnTooltip(index, colIndex, itemIndex);
  };

  setColumnTooltip = (index, colIndex, itemIndex) => {
    if (this.props.readOnly) {
      return;
    }
    let canvas = document.getElementById(
      `column-item-${index + "-" + colIndex + "-" + itemIndex}-component`
    );
    let tooltip = document.getElementById(
      `tooltip-item-${index + "-" + colIndex + "-" + itemIndex}-column-component`
    );
    if (canvas) {
      if (tooltip !== null) {
        tooltip.style.display = "flex";
        tooltip.style.top = canvas.offsetTop - 35 + "px";
        tooltip.style.left = canvas.offsetLeft - 2 + "px";
      }
      canvas.className = canvas.className + " canvas-active";
    }
    this.setCanvasToolTip(index);
  };

  onCanvasLeave = () => {
    const allTooltip = document.getElementsByClassName("dk-tootip-column");
    for (let i = 0; i < allTooltip.length; i++) {
      allTooltip[i].style.display = "none";
      // this.hideElement()
    }

    const elementsItems = document.getElementsByClassName(
      "column-section-list-item canvas-active"
    );
    if (elementsItems.length > 0) {
      elementsItems[0].classList.remove("canvas-active");
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  getBlankSection = () => {
    return (
      <div
        className="column parent-width justify-content-center align-items-center "
        style={{ minHeight: 50, border: "1px dashed #80808094" }}
      >
        <DKLabel text="Please add column" className="text-gray" />
      </div>
    );
  };

  onMobileToolTip = (index, colIndex, itemIndex) => {
    this.onMouseColumnParentSection(index, colIndex, itemIndex);
  };
}
