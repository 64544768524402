import { DKLabel, INPUT_TYPE, showAlert } from "deskera-ui-library";
import ProductEditor from "../editors/ProductEditor";
import Utility, { toCurrencyFormat } from "../../../utility/Utility";
import RouteManager, { PAGE_ROUTES } from "../../../managers/RouteManager";
import { URL_V1 } from "../../../constants/Constant";
import UserManager from "../../../managers/UserManager";
import ApiConstants from "../../../constants/ApiConstants";
import { PAGE_SIZE, PAGE_TYPE } from "../ComponentList";
import Incrementor from "../../common/Incrementor";
import { TableManger, TABLES } from "../../../managers/TableManger";
import { CURRENCY_SYMBOLS } from "../../../constants/Currencies";

const OrderForm = ({
  data,
  borderStyle,
  isEditing,
  viewType,
  hash,
  moduleType,
  onDataChange,
  isStripeConnected,
  paymentAccounts,
  saveTemplate
}) => {
  const getOrderFormStyles = (style, isEditing, viewType) => {
    return (
      <style>
        {`
        .order-form-container {
          display: flex;
          justify-content: space-around;
          flex-flow: ${
            isEditing && viewType === PAGE_SIZE.PHONE ? "column" : "row"
          };
          border: 1px solid #ccc;
          box-sizing: border-box;
          ${style.fontFamily ? `font-family: ${style.fontFamily}` : ""};
        }

        .order-form-left {
          display: flex;
          width: ${isEditing && viewType === PAGE_SIZE.PHONE ? "100%" : "60%"};
          ${isEditing ? "min-width: 300px;" : ""}
          padding: 30px 20px;
          flex-direction: column;
          align-items: space-around;
          box-sizing: border-box;
        }

        .order-form-right {
          width: ${isEditing && viewType === PAGE_SIZE.PHONE ? "100%" : "40%"};
          min-width: 300px;
          margin: 0 auto;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-left:  ${
            isEditing && viewType === PAGE_SIZE.PHONE
              ? "none"
              : "1px solid #ccc"
          };
          box-sizing: border-box;
        }

        .order-form-left .form-section {
          max-width: 500px;
        }

        .form-section {
          width: 90%;
          text-align: left;
          margin: 10px auto;
          box-sizing: border-box;
          pointer-events: ${isEditing ? "none" : "all"};
        }

        .form-section h3 {
          margin-top: 0;
          text-align: left;
          font-weight: 400;
          font-size: 1.5em;
        }

        .order-section {
          width: 100%;
          margin: 0;
          padding: 30px 40px 40px;
          pointer-events: all;
          border-top: ${
            isEditing && viewType === PAGE_SIZE.PHONE
              ? "1px solid #ccc"
              : "none"
          };
        }

        .payment-section {
          width: 100%;
          margin: 0;
          padding: 30px 40px;
          pointer-events: all;
        }

        .order-summary {
          width: 100%;
          margin: 0;
          padding: 20px 40px;
          border: 0 solid #ccc;
          border-top-width: 1px;
          border-bottom-width: 1px;
        }

        .order-product-row,
        .order-summary p {
          min-width: 225px;
          margin: 8px 0;
          display: flex;
          font-weight: 400;
          justify-content: space-between;
          align-items: start;
        }

        .order-product-row {
          justify-content: flex-start;
          align-items: center;
        }

        .order-product-row + .order-product-row {
          margin-top: 20px;
        }

        .order-product-row .order-product-name {
          text-transform: capitalize;
          padding-right: 8px;
        }

        .order-product-row div.mr-r.fw-m {
          font-weight: 400;
          margin-left: 10px;
          white-space: nowrap !important;
          margin-right: 0 !important;
        }

        .order-product-row .incrementor-control {
          width: 6px;
          padding: 6px 8px;
          background-color: #f4f4f6;
          cursor: pointer;
        }

        .quantity-incrementor {
          flex-flow: row-reverse nowrap;
          justify-content: flex-end;
          align-items: center;
          height: 100%;
        }

        .row {
          display: flex;
        }

        .justify-content-center {
          justify-content: center;
        }

        .quantity-incrementor .incrementor-input {
          width: 32px !important;
          padding-left: 5px;
          border: none;
          outline: none;
        }

        .quantity-incrementor .incrementor-input-control {
          width: auto;
          padding: 6px;
        }

        .quantity-incrementor > .unselectable {
          flex-shrink: 0;
        }

        .order-price {
          white-space: nowrap;
          display: inline-block;
        }

        p.order-total {
          margin-top: 12px;
          font-size: 1.4em;
          font-weight: 500;
        }

        .hide {
          display: none;
        }

        /* Chrome, Safari, Edge, Opera */
        .incrementor-input::-webkit-outer-spin-button,
        .incrementor-input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        .incrementor-input {
          -moz-appearance: textfield;
        }

        .transparent-background {
          position: fixed;
          top: 0px;
          left: 0px;
          z-index: 9997;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
        }

        .popup-window {
          position: fixed;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          box-sizing: border-box;
          padding: 0;
          top: 50%;
          left: 50%;
          z-index: 9998;
          overflow-y: scroll;
          box-sizing: border-box;
          width: 500px;
          height: 500px;
          max-width: 90vw;
          max-height: 90vh;
          border-radius: 4px;
          background-color: white;
          box-shadow: 0px 0px 10px rgba(0, 0, 20, 0.05);
          transform: translate(-50%, -50%);
          scrollbar-width: none;
          -ms-overflow-style: none;
        }

        .payment-form, .payment-form iframe {
          min-width: 500px;
          min-height: 500px !important;
        }

        @media screen and (max-width: 767px) {
          .order-form-container {
            flex-flow: column;
          }

          .order-form-right {
            min-width: 250px;
            border-left: none;
          }

          .order-form-container,
          .order-form-left,
          .order-form-right,
          .form-section {
            width: 100%;
          }

          .order-section {
            border-top: 1px solid #ccc;
          }

          .order-section,
          .order-summary,
          .payment-section {
            padding-left: 20px;
            padding-right: 20px;
          }
          .payment-selection{
            width: 100%;
            height: 40px;
            font-size: 18px;
            margin-top: 15px;
            border: none;
            padding: 5px;
            border-radius: 5px;
          }
        }
      `}
      </style>
    );
  };

  const getFormData = (data, hash, moduleType) => {
    let moduleId = null;
    if (moduleType && moduleType !== PAGE_TYPE.THANK_YOU_PAGE) {
      moduleId = TableManger.getTableId(moduleType);
    }

    const obj = {
      baseURL: ApiConstants.URL.BASE + URL_V1,
      STRIPE_CONNECT_END_POINT: ApiConstants.URL.STRIPE_END_POINT,
      tenantID: UserManager.getUserTenantID(),
      hash: hash,
      moduleTableId: moduleId,
      orderTableId: TableManger.getTableId(TABLES.ORDER),
      publishableKey: process.env.REACT_APP_STRIPE_KEY,
      thankYouMsg: data.thankYouMsg,
      redirectURL: data.redirectURL,
      productData: data.cartData.productDetails,
      subTotalAmount: data.cartData.subTotalAmount || 0,
      totalAmount: data.cartData.totalAmount || 0,
      currency: data.cartData.currency,
      currencySymbol: CURRENCY_SYMBOLS[data.cartData.currency],
      razorPayAccount: paymentAccounts?.razorpay?.[0]
    };

    return Utility.encodeJSON(obj);
  };

  const getProductDataCell = (
    cartData,
    currency,
    isEditing,
    index,
    onDataChange
  ) => {
    const quantity = cartData.productDetails[index]["quantity"];
    const price = cartData.productDetails[index]["price"];

    return isEditing ? (
      <Incrementor
        value={quantity}
        className="quantity-incrementor"
        title={toCurrencyFormat(price, currency)}
        UOM={" "}
        step={1}
        onChange={(requestedQuantity) => {
          /* to handle manual entry in incrementor, NaN scenarios */
          const newQuantity = Number(requestedQuantity) || 0;
          const oldQuantity = Number(quantity) || 0;
          if (newQuantity < 0) {
            showAlert(
              `Can't update quantity`,
              `Quantity can not be reduced further, please delete the product if required.`
            );
            return;
          }
          const newCartData = { ...cartData };
          const newProductDetails = [...newCartData.productDetails];
          const newProduct = { ...newProductDetails[index] };
          newProduct["quantity"] = requestedQuantity;
          newProductDetails[index] = newProduct;
          newCartData.productDetails = newProductDetails;
          newCartData.subTotalAmount += (newQuantity - oldQuantity) * price;
          newCartData.totalAmount += (newQuantity - oldQuantity) * price;
          newCartData.totalQty += newQuantity - oldQuantity;
          onDataChange(newCartData);
        }}
      />
    ) : (
      <div className="row quantity-incrementor">
        <span className="order-price">
          &nbsp; x &nbsp;{toCurrencyFormat(price, currency)}
        </span>
        <div
          className="row"
          style={{
            border: `1px solid rgb(220, 220, 220)`,
            borderRadius: 4
            // marginLeft: "auto",
          }}
        >
          <span className="incrementor-control" data-counter="-1">
            -
          </span>
          <span className="incrementor-input-control">{quantity}</span>
          <input
            className="incrementor-input hide"
            type="number"
            min={0}
            value={quantity}
          />
          <span className="incrementor-control" data-counter="1">
            +
          </span>
        </div>
      </div>
    );
  };

  const getProductSection = (cartData, style, isEditing, onDataChange) => {
    const currency = CURRENCY_SYMBOLS[cartData.currency];
    const productsAvailable = cartData.productDetails?.length;
    return (
      <>
        <div className="form-section order-section">
          {isEditing ? (
            <div className="row justify-content-between align-items-start">
              <h3>Order Summary</h3>
              <ProductEditor
                buttonClassName="text-blue bg-transparent fw-m"
                cartData={cartData}
                onDataChange={onDataChange}
                needProductList={false}
              />
            </div>
          ) : (
            <h3>Order Summary</h3>
          )}
          {cartData.productDetails?.map((product, index) => (
            <div className="order-product-row">
              <span
                className="order-product-name"
                style={{
                  width: "40%",
                  boxSizing: "border-box"
                }}
              >
                {product.name}
              </span>
              <span
                style={{
                  width: "60%",
                  textAlign: "right",
                  alignSelf: "stretch"
                }}
              >
                {getProductDataCell(
                  cartData,
                  currency,
                  isEditing,
                  index,
                  onDataChange
                )}
              </span>
            </div>
          ))}
        </div>
        <div className="form-section order-summary">
          <p className="order-sub-total">
            Subtotal
            <span>{toCurrencyFormat(cartData.subTotalAmount, currency)}</span>
          </p>
          <p>
            Discount{" "}
            <span>
              {toCurrencyFormat(
                productsAvailable && cartData.discount ? cartData.discount : 0,
                currency
              )}
            </span>
          </p>
          <p>
            Shipping{" "}
            <span>
              {toCurrencyFormat(
                productsAvailable && cartData.shipping ? cartData.shipping : 0,
                currency
              )}
            </span>
          </p>
          <p>
            Tax{" "}
            <span>
              {toCurrencyFormat(
                productsAvailable && cartData.tax ? cartData.tax : 0,
                currency
              )}
            </span>
          </p>
          <p className="order-total">
            Total{" "}
            <span>
              {toCurrencyFormat(
                productsAvailable && cartData.totalAmount
                  ? cartData.totalAmount
                  : 0,
                currency
              )}
            </span>
          </p>
        </div>
      </>
    );
  };

  const getSelectPaymentOptions = ({
    buttonData,
    styles,
    isEditing,
    isStripeConnected,
    saveTemplate,
    totalAmount = 0
  }) => {
    return (
      <div className="form-section payment-section">
        <div
          style={{
            fontSize: 18,
            marginBottom: 5
          }}
          className="payment-element"
        >
          Payment Details
        </div>
        {totalAmount <= 0 && isEditing && (
          <DKLabel
            className="text-red fw-m"
            text="Total amount should be more than 0 for accepting payments."
          />
        )}
        {!isEditing && (
          <select
            disabled={isEditing}
            id="payment-selection-dropdown"
            className="payment-selection payment-element"
            style={{
              WebkitAppearance: "none",
              MozAppearance: "none",
              appearance: "none",
              border: `2px solid #EBEBEB`,
              boxSizing: "border-box",
              outline: "none",
              boxShadow: "none !important",
              backgroundImage: "none",
              width: "100%",
              height: "40px",
              fontSize: "16px",
              marginTop: "10px",
              marginBottom: "5px",
              padding: 5,
              backgroundColor: "#F8F7FA",
              borderRadius: 4
            }}
          >
            <option value="">Select Payment Option</option>
            {isStripeConnected && <option value="stripe">Stripe</option>}
            {paymentAccounts?.razorpay?.length > 0 && (
              <option value="razorpay">Razorpay</option>
            )}
            {paymentAccounts?.paytm?.length > 0 && (
              <option value="paytm">Paytm</option>
            )}
            {paymentAccounts?.cashfree?.length > 0 && (
              <option value="cashfree">Cashfree</option>
            )}
          </select>
        )}
        {isEditing ? getPaymentWarning() : getCardElement(styles)}
        <div
          id="cashfree-payment-modal"
          className="transparent-background hide"
        >
          <div className="popup-window">
            <div
              id="payment-form"
              className="payment-form"
              data-styles={JSON.stringify({
                fontFamily: buttonData.style.fontFamily
              })}
            ></div>
          </div>
        </div>
        <button
          type="submit"
          id="order-payment-button"
          style={{
            fontSize: buttonData.style.buttonSize,
            fontFamily: buttonData.style.fontFamily,
            backgroundColor: buttonData.style.buttonBackground,
            color: buttonData.style.buttonColor,
            marginTop: 20,
            marginBottom: 20,
            padding: 8,
            width: "100%",
            border: `2px solid ${buttonData.style.buttonBackground}`,
            borderRadius: 5,
            pointerEvents: isEditing === true ? "none" : "auto",
            cursor: isEditing === true ? "none" : "pointer",
            fontWeight: 500
          }}
        >
          <div
            className="spinner hidden"
            style={{ display: "none" }}
            id="spinner"
          ></div>
          <span id="button-text" data-button-text={buttonData.value}>
            {totalAmount || isEditing ? buttonData.value : "Place order"}
          </span>
        </button>
        <p id="card-error" role="alert"></p>
        <p className="result-message hidden" style={{ display: "none" }}>
          Payment succeeded
        </p>
      </div>
    );
  };
  const getPaymentWarning = () => {
    if (!isPaymentOptionSetup()) {
      return (
        <div className="text-red mt-l">
          You have not setup any payment method please add it from &nbsp;
          <span
            className="fw-b cursor-hand text-underline"
            onClick={() => {
              RouteManager.navigateToPage(PAGE_ROUTES.PAYMENT_INTEGRATION);
            }}
          >
            Settings
          </span>
        </div>
      );
    }
    return null;
  };
  const isPaymentOptionSetup = () => {
    if (
      isStripeConnected ||
      (Object.keys(paymentAccounts).length > 0 &&
        Object.keys(paymentAccounts).some(
          (key) => paymentAccounts[key].length > 0
        ))
    ) {
      return true;
    }
    return false;
  };

  const getCardElement = (styles) => {
    return (
      <div
        id="card-element"
        className="payment-element"
        style={{
          display: "none",
          width: "100%",
          marginTop: "5px",
          padding: "10px 5px",
          paddingRight: 0,
          boxSizing: "border-box",
          border: `2px solid ${styles.fieldBorder}`,
          borderRadius: styles.fieldRadius,
          backgroundColor: styles.fieldBackground,
          pointerEvents: "all"
        }}
      ></div>
    );
  };

  const getFormSection = (formSectionData, style, isEditing) => {
    return (
      <div className="form-section">
        <h3>{formSectionData.sectionTitle}</h3>
        {formSectionData.formFields.map((formField) => (
          <div
            className="form-group"
            key={formField.key}
            style={{
              textAlign: "left",
              width: formField.fieldWidth || "100%",
              display: "inline-block",
              marginLeft: formField.marginLeft || 0
            }}
          >
            <label
              htmlFor={formField.key}
              style={{ fontSize: style.labelSize, color: style.labelColor }}
            >
              {formField.name} {formField.required ? "*" : ""}
            </label>
            {addInputFields(formField, style)}
          </div>
        ))}
      </div>
    );
  };

  const addInputFields = (data, style) => {
    switch (data.type) {
      case INPUT_TYPE.SELECT:
        return getSelectField(data, style);
      case INPUT_TYPE.MULTI_SELECT:
        return getMultiSelectField(data, style);
      default:
        return getInputField(data, style);
    }
  };

  const getInputField = (data, style) => {
    return (
      <input
        style={{
          WebkitAppearance: "none",
          MozAppearance: "none",
          appearance: "none",
          width: "100%",
          border: `2px solid ${style.fieldBorder}`,
          borderRadius: style.fieldRadius,
          backgroundColor: style.fieldBackground,
          boxSizing: "border-box",
          padding: 8,
          margin: `${style.fieldSpacing}px auto`,
          outline: "none",
          boxShadow: "none",
          backgroundImage: "none"
        }}
        key={data.key}
        type={data.type}
        name={data.columnCode}
        id={data.key}
        required={data.required}
      />
    );
  };

  const getSelectField = (data, style) => {
    return (
      <select
        style={{
          WebkitAppearance: "none",
          MozAppearance: "none",
          appearance: "none",
          width: "100%",
          border: `2px solid ${style.fieldBorder}`,
          borderRadius: style.fieldRadius,
          backgroundColor: style.fieldBackground,
          boxSizing: "border-box",
          padding: 8,
          margin: `${style.fieldSpacing}px auto`,
          outline: "none",
          boxShadow: "none !important",
          backgroundImage: "none"
        }}
        key={data.key}
        name={data.columnCode}
        id={data.key}
      >
        {(data.options || []).map((item, index) => (
          <option
            key={index}
            value={item.id}
            selected={data.defaultValue === item.id}
          >
            {item.name}
          </option>
        ))}
      </select>
    );
  };

  const getMultiSelectField = (data, style) => {
    return (
      <div>
        <select
          multiple
          style={{
            WebkitAppearance: "none",
            MozAppearance: "none",
            appearance: "none",
            width: "100%",
            border: `2px solid ${style.fieldBorder}`,
            borderRadius: style.fieldRadius,
            backgroundColor: style.fieldBackground,
            boxSizing: "border-box",
            padding: 8,
            margin: `${style.fieldSpacing}px auto`,
            outline: "none",
            boxShadow: "none !important",
            backgroundImage: "none"
          }}
          key={data.key}
          name={data.columnCode}
          id={data.key}
        >
          {(data.options || []).map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <div
          style={{
            color: "gray",
            textAlign: "left",
            marginLeft: "4px",
            marginTop: "5px"
          }}
        >
          (Press Shift to select multiple options)
        </div>
      </div>
    );
  };

  return (
    <form
      id="product-order-form"
      data-form-props={getFormData(data, hash, moduleType)}
      style={{ ...data.style, ...borderStyle }}
    >
      {getOrderFormStyles(data.style, isEditing, viewType)}
      <div className="order-form-container">
        <div className="order-form-left">
          {getFormSection(data.formSections[0], data.style, isEditing)}
          {getFormSection(data.formSections[1], data.style, isEditing)}
        </div>
        <div className="order-form-right">
          {getProductSection(
            data.cartData,
            data.style,
            isEditing,
            onDataChange
          )}
          {getSelectPaymentOptions({
            buttonData: data.button,
            styles: data.style,
            isEditing,
            isStripeConnected,
            saveTemplate,
            totalAmount: data.cartData?.totalAmount
          })}
        </div>
      </div>
    </form>
  );
};
export default OrderForm;
