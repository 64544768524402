import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import AppManager from "../../managers/AppManager";
import Utility from "../../utility/Utility";
import FormService from "../../services/form";
import React from "react";
import TemplateManager from "./templates/TemplateManager";
import template_0 from "../../assets/formTemplates/defaultTemplate.png";
import template_1 from "../../assets/formTemplates/template_1.png";
import template_2 from "../../assets/formTemplates/template_2.png";
import template_3 from "../../assets/formTemplates/template_3.png";
import template_4 from "../../assets/formTemplates/template_4.png";
import template_5 from "../../assets/formTemplates/template_5.png";
import template_6 from "../../assets/formTemplates/template_6.png";
import template_7 from "../../assets/formTemplates/template_7.png";
import template_8 from "../../assets/formTemplates/template_8.png";
import template_9 from "../../assets/formTemplates/template_9.png";
import template_10 from "../../assets/formTemplates/template_10.png";
import FormHeader from "./FormHeader";

import { showToast, TOAST_TYPE } from "deskera-ui-library";
import ComponentManager from "../page_designer/ComponentManager";
import { PAGE_ELEMENT } from "../page_designer/ComponentList";

export default class FormManager {
  static formData;
  static directLink = null;

  static status = {
    ACTIVE: [1],
    IN_ACTIVE: [2]
  };

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getAllCreationSteps() {
    const formId = this.getFormRecordId() ? "/" + this.getFormRecordId() : "";
    let steps = [
      {
        title: "Select Template",
        onClick: () => {
          RouteManager.navigateToPage(
            PAGE_ROUTES.SELECT_FORM_TEMPLATE + formId
          );
        }
      },
      {
        title: FormManager.isPageDataAvailable() ? "Edit Design" : "Design",
        onClick: () => {
          if (FormManager.isPageDataAvailable()) {
            RouteManager.navigateToPage(PAGE_ROUTES.FORM_BUILDER + formId);
          } else {
            showToast("Please select template", TOAST_TYPE.FAILURE);
          }
        }
      },
      {
        title: FormManager.isPageDataAvailable()
          ? "Publish Details"
          : "Publish",
        onClick: () => {
          if (FormManager.isPageDataAvailable()) {
            RouteManager.navigateToPage(PAGE_ROUTES.FORM_DOMAIN + formId);
          } else {
            showToast("Please save design to proceed", TOAST_TYPE.FAILURE);
          }
        }
      }
    ];
    return steps;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  static isPageDataAvailable() {
    return !(
      FormManager.formData === null || FormManager.formData === undefined
    );
  }

  static setFormData(response) {
    FormManager.formData = this.dataParseResponse(response);
  }

  static getFormData() {
    return FormManager.formData || "";
  }

  ////////////////////////////////////////////////////////////////////////////////////

  static getFormRecordId() {
    if (!FormManager.isPageDataAvailable()) {
      return null;
    }

    return FormManager.formData.data._id;
  }

  static getFormCreatedBy() {
    if (!FormManager.isPageDataAvailable()) {
      return null;
    }

    return FormManager.formData.data.cells.createdBy;
  }

  ////////////////////////////////////////////////////////////////////////////////////

  static getDirectLink() {
    if (!FormManager.isPageDataAvailable()) {
      return "URL not found";
    }

    let directLink =
      FormManager.formData.data.cells[
        FormManager.getColumnId(COLUMN_CODE.FORMS.DIRECT_LINK)
      ];

    return AppManager.generateDirectLink(directLink);
  }

  static getFormStatus() {
    if (!FormManager.isPageDataAvailable()) {
      return false;
    }

    let status =
      FormManager.formData.data.cells[
        FormManager.getColumnId(COLUMN_CODE.FORMS.STATUS)
      ];

    status = JSON.stringify(status);

    return status === "[1]";
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getFormJSONData() {
    if (!FormManager.isPageDataAvailable()) {
      return [];
    }

    let jsonData =
      FormManager.formData.data.cells[
        FormManager.getColumnId(COLUMN_CODE.FORMS.JSON_DATA)
      ];

    return Utility.decodeJSON(jsonData);
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  // static getEmbeddedFormId() {
  //   if (!FormManager.isPageDataAvailable()) {
  //     return "URL not found";
  //   }
  //
  //   let directLink =
  //     FormManager.formData.data.cells[
  //       FormManager.getColumnId(COLUMN_CODE.FORMS.DIRECT_LINK)
  //       ];
  //
  //   if (directLink && directLink.includes(PAGE_ROUTES.PUBLIC_PAGE_PATH)) {
  //     let splitDirectLink = directLink.split("/");
  //     return splitDirectLink[splitDirectLink.length - 1];
  //   }
  //
  //   return directLink;
  // }

  // static getEmbeddedFormScript() {
  //   return (
  //     '<div id="deskera-form" data-dkcrmbaseurl="' + ApiConstants.URL.BASE + '" data-formid="' +
  //     FormManager.getEmbeddedFormId() +
  //     '"></div>\n' +
  //     '<script type="text/javascript" src="' +
  //     ApiConstants.FORM_SUBMISSION_URL +
  //     '"></script>\n' +
  //     '<script type="text/javascript" src="' +
  //     ApiConstants.LOAD_SCRIPTS_URL +
  //     '"></script>\n' +
  //     '<script type="text/javascript" src="' +
  //     ApiConstants.FORM_EMBEDDED_URL +
  //     '"></script>'
  //   );
  // }

  static getEmbeddedFormScript() {
    return (
      '<embed type="text/html" src="' +
      FormManager.getDirectLink() +
      '" width="1300" height="1200">'
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getRequestToSaveForm(formData) {
    let data = {};
    data[this.getColumnId(COLUMN_CODE.FORMS.NAME)] = formData.formName;
    data[this.getColumnId(COLUMN_CODE.FORMS.SEGMENT_ID)] = [formData.segmentId];
    data[this.getColumnId(COLUMN_CODE.FORMS.HTML_DATA)] = formData.html;
    data[this.getColumnId(COLUMN_CODE.FORMS.JSON_DATA)] = formData.json;
    data[this.getColumnId(COLUMN_CODE.FORMS.HASH)] = formData.hash;
    data[this.getColumnId(COLUMN_CODE.FORMS.CUSTOM_DOMAIN)] = formData.domain;
    data[this.getColumnId(COLUMN_CODE.FORMS.SLUG)] = formData.slug;
    data[this.getColumnId(COLUMN_CODE.FORMS.VISITORS)] = 0;
    data[this.getColumnId(COLUMN_CODE.FORMS.CLICKS)] = 0;
    data[this.getColumnId(COLUMN_CODE.FORMS.STATUS)] =
      FormManager.status.ACTIVE;
    data[this.getColumnId(COLUMN_CODE.FORMS.PUBLISHED)] = true;
    return data;
  }

  static getRequestToUpdateForm(formData) {
    let data = {};
    data[this.getColumnId(COLUMN_CODE.FORMS.HTML_DATA)] = formData.html;
    data[this.getColumnId(COLUMN_CODE.FORMS.JSON_DATA)] = formData.json;
    data[this.getColumnId(COLUMN_CODE.FORMS.SEGMENT_ID)] = [formData.segmentId];
    data[this.getColumnId(COLUMN_CODE.FORMS.PUBLISHED)] = true;
    return data;
  }

  static getRequestToUpdateStatus(isActive) {
    let data = {};
    data[this.getColumnId(COLUMN_CODE.FORMS.STATUS)] =
      isActive === true
        ? FormManager.status.ACTIVE
        : FormManager.status.IN_ACTIVE;
    data[this.getColumnId(COLUMN_CODE.FORMS.PUBLISHED)] = true;
    return data;
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getColumnId(columnCode) {
    return TableManger.getColumnId(TABLES.FORM, columnCode);
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static fetchPageDataById(recordId, callback) {
    FormService.getData(
      recordId,
      (response) => {
        FormManager.setFormData({ data: response });
        callback && callback();
      },
      (error) => {
        Utility.alertWithRedirection(
          "Error!",
          "Record not found / You don't have permission",
          PAGE_ROUTES.FORMS
        );
      }
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static getHeader() {
    return <FormHeader title={"Form Templates"} stepperIndex={0} />;
  }

  static navigateToBuilder() {
    RouteManager.navigateToPage(
      PAGE_ROUTES.FORM_BUILDER +
        (FormManager.isPageDataAvailable() ? "/" + this.getFormRecordId() : "")
    );
  }

  static setSelectedTemplateId(id) {
    TemplateManager.setSelectedTemplateId(id);
  }

  static getSelectedTemplateId() {
    return TemplateManager.getSelectedTemplateId();
  }

  static getAllTemplates() {
    return [
      template_0,
      template_1,
      template_2,
      template_3,
      template_4,
      template_5,
      template_6,
      template_7,
      template_8,
      template_9,
      template_10
    ];
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////

  static dataParseResponse = (data) => {
    if (data === null) {
      return data;
    }
    let jsonData =
      data.data.cells[FormManager.getColumnId(COLUMN_CODE.FORMS.JSON_DATA)];
    let json = Utility.decodeJSON(jsonData);
    json.designElements.forEach((component) => {
      if (component.type === PAGE_ELEMENT.COLLECTION) {
        component.value.forEach((list) => {
          list = this.collectionResponse(list, component);
        });
      }
      if (component.type === PAGE_ELEMENT.ICON) {
        component.value.forEach((list) => {
          list = this.collectionResponse(list, component);
        });
      }
      if (component.type === PAGE_ELEMENT.IMAGE_TEXT) {
        component.value.forEach((list) => {
          list = this.imageTextResponse(list, component);
        });
      }
    });
    data.data.cells[FormManager.getColumnId(COLUMN_CODE.FORMS.JSON_DATA)] =
      Utility.encodeJSON(json);
    return data;
  };

  static collectionResponse = (data, component) => {
    if (typeof data.title === "string") {
      data.title = ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
        value: data.title,
        fontSize: component.style.titleSize,
        color: component.style.titleColor,
        fontWeight: "bold",
        paddingTop: 3,
        paddingBottom: 3
      });
    }
    if (
      component.type !== PAGE_ELEMENT.ICON &&
      typeof data.description === "string"
    ) {
      data.description = ComponentManager.getTextDefaultData(
        PAGE_ELEMENT.TEXT,
        {
          value: data.description,
          fontSize: component.style.descriptionSize,
          color: component.style.descriptionColor,
          paddingTop: 3,
          paddingBottom: 3
        }
      );
    }
    if (typeof data.icon === "string") {
      data.icon = ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
        value: data.icon,
        marginBottom: 0
      });
    }
    if (data.button === undefined) {
      data.button = ComponentManager.getButtonDefaultData(PAGE_ELEMENT.BUTTON, {
        fontSize: 12,
        paddingTop: 6,
        paddingBottom: 6,
        paddingLeft: 7,
        paddingRight: 7,
        marginTop: 2,
        visibility: false
      });
    }
    return data;
  };

  static imageTextResponse = (data, component) => {
    let isExistingButtonNeedUpdate = false;
    if (typeof data.title === "string") {
      isExistingButtonNeedUpdate = true;
      data.title = ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
        value: data.title,
        fontSize: component.style.titleSize,
        color: component.style.titleColor,
        textAlign: "left",
        fontWeight: "bold"
      });
    }
    if (typeof data.description === "string") {
      data.description = ComponentManager.getTextDefaultData(
        PAGE_ELEMENT.TEXT,
        {
          value: data.description,
          fontSize: component.style.descriptionSize,
          color: component.style.descriptionColor,
          textAlign: "left"
        }
      );
    }
    if (typeof data.image === "string") {
      data.image = ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
        value: data.image
      });
    }
    let buttonObj = {};
    if (data.button === undefined) {
      buttonObj = {
        fontSize: 14,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        width: "auto",
        alignSelf:
          component.style.textAlign === "left" ? "flex-start" : "flex-end",
        visibility: false
      };
      data.button = ComponentManager.getButtonDefaultData(
        PAGE_ELEMENT.BUTTON,
        buttonObj
      );
    } else if (isExistingButtonNeedUpdate) {
      buttonObj = {
        value: data.button.value,
        url: data.button.url,
        fontSize: 14,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: 8,
        paddingRight: 8,
        marginRight: component.style.textAlign === "right" ? 0 : "auto",
        marginLeft: component.style.textAlign === "left" ? 0 : "auto",
        marginTop: "auto",
        marginBottom: "auto",
        width: "auto",
        alignSelf:
          component.style.textAlign === "left" ? "flex-start" : "flex-end",
        visibility: data.button.visibility
      };
      data.button = ComponentManager.getButtonDefaultData(
        PAGE_ELEMENT.BUTTON,
        buttonObj
      );
    }
    return data;
  };
}
