import { getRandomAlphaNumericString } from "../../../utility/Utility";
import { PAGE_ELEMENT } from "../../page_designer/ComponentList";
import ComponentManager from "../../page_designer/ComponentManager";
import ic_x from "../../../assets/social_icons/ic_twitter.png";

export default class Template0 {
  static getData() {
    return {
      metaData: null,
      scripts: null,
      hash: getRandomAlphaNumericString(),
      designElements: [
        {
          type: "Text",
          value: "Deskera",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 22,
            color: "#000000",
            textAlign: "left",
            fontWeight: "bold",
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 32,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#fcc222",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Image & Text",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Run Your Business with Deskera",
                fontSize: 50,
                color: "#9f2219",
                textAlign: "left",
                fontWeight: "bold"
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "All the Benefits, None of the Hassles.<br/><br/>You don’t have to be an ERP expert or spend months implementing ERP to get all the tools your business needs.",
                  fontSize: 14,
                  color: "#000000",
                  textAlign: "left"
                }
              ),
              image: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404040188_home-page-hero-screenshot-67be112b87b4a43108b88a19fde47cae.png"
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 14,
                  paddingTop: 7,
                  paddingBottom: 7,
                  paddingLeft: 8,
                  paddingRight: 8,
                  marginRight: 8,
                  marginLeft: 8,
                  width: "auto",
                  textAlign: "left",
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 32,
            paddingLeft: 32,
            paddingRight: 14,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#fcc222",
            alignSelf: "center",
            overflow: "hidden",
            imageSize: 50,
            cellSpacing: 2,
            imageRadius: 0,
            imageAlign: "right",
            textAlign: "left",
            titleColor: "#9f2219",
            titleSize: 50,
            descriptionColor: "#000000",
            descriptionSize: 14,
            containerSpacing: 48
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404089227_Screenshot 2021-06-11 at 10.38.03 AM.png",
          style: {
            width: "100%",
            // height: "min-intrinsic",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 16,
            paddingRight: 16,
            marginTop: 26,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404137112_Screenshot 2021-06-11 at 10.38.30 AM.png",
          style: {
            width: "94%",
            // height: "min-intrinsic",
            boxSizing: "border-box",
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 12,
            paddingRight: 14,
            marginTop: 0,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 12,
            backgroundColor: "#fdfbf5",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Button",
          value: "Get Started",
          url: "",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#ffffff",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 31,
            paddingRight: 31,
            marginTop: 18,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 22,
            backgroundColor: "#922d21",
            alignSelf: "center",
            overflow: "hidden",
            cursor: "pointer",
            outline: "none",
            border: "none",
            textDecoration: "none"
          }
        },
        {
          type: "Text",
          value: "Your Business. Streamlined.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 28,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Collection",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "",
                fontSize: 14,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404203296_Screenshot 2021-06-11 at 10.40.12 AM.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "",
                fontSize: 14,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404206390_Screenshot 2021-06-11 at 10.40.33 AM.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "",
                fontSize: 14,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404209148_Screenshot 2021-06-11 at 10.40.43 AM.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "",
                fontSize: 14,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404209148_Screenshot 2021-06-11 at 10.40.43 AM.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "",
                fontSize: 14,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404209148_Screenshot 2021-06-11 at 10.40.43 AM.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "",
                fontSize: 14,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404209148_Screenshot 2021-06-11 at 10.40.43 AM.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 10,
            marginBottom: 16,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 256,
            cellSpacing: 5,
            iconRadius: 0,
            titleColor: "#000000",
            titleSize: 14,
            descriptionColor: "#837c7c",
            descriptionSize: 14,
            gridSize: 23
          }
        },
        {
          type: "Image & Text",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Header Text",
                fontSize: 18,
                color: "#000000",
                textAlign: "left",
                fontWeight: "bold"
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br/><br/>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                  fontSize: 14,
                  color: "#837c7c",
                  textAlign: "left"
                }
              ),
              image: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404341371_homepage-accounting-and-finance-screenshot-3d5f5f3c5c79492ca2c60159a28553c7.png"
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 14,
                  paddingTop: 7,
                  paddingBottom: 7,
                  paddingLeft: 8,
                  paddingRight: 8,
                  marginRight: 8,
                  marginLeft: 8,
                  width: "auto",
                  textAlign: "left",
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 40,
            paddingRight: 34,
            marginTop: 4,
            marginBottom: 4,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            imageSize: 50,
            cellSpacing: 2,
            imageRadius: 0,
            imageAlign: "right",
            textAlign: "left",
            titleColor: "#000000",
            titleSize: 18,
            descriptionColor: "#837c7c",
            descriptionSize: 14,
            containerSpacing: 48
          }
        },
        {
          type: "Image & Text",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Header Text",
                fontSize: 18,
                color: "#000000",
                textAlign: "left",
                fontWeight: "bold"
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br/><br/>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                  fontSize: 14,
                  color: "#837c7c",
                  textAlign: "left"
                }
              ),
              image: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404351026_homepage-payroll-hrms-screenshot-26628078a09b510a2ec6c86846a53380.png"
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 14,
                  paddingTop: 7,
                  paddingBottom: 7,
                  paddingLeft: 8,
                  paddingRight: 8,
                  marginRight: 8,
                  marginLeft: 8,
                  width: "auto",
                  textAlign: "left",
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 32,
            paddingRight: 32,
            marginTop: 4,
            marginBottom: 4,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            imageSize: 50,
            cellSpacing: 2,
            imageRadius: 0,
            imageAlign: "left",
            textAlign: "left",
            titleColor: "#000000",
            titleSize: 18,
            descriptionColor: "#837c7c",
            descriptionSize: 14,
            containerSpacing: 48
          }
        },
        {
          type: "Image & Text",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Header Text",
                fontSize: 18,
                color: "#000000",
                textAlign: "left",
                fontWeight: "bold"
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.<br/><br/>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
                  fontSize: 14,
                  color: "#837c7c",
                  textAlign: "left"
                }
              ),
              image: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404357239_homepage-sales-automation-screenshot-935c84f97e9df9f0e188059a02e9a23d.png"
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 14,
                  paddingTop: 7,
                  paddingBottom: 7,
                  paddingLeft: 8,
                  paddingRight: 8,
                  marginRight: 8,
                  marginLeft: 8,
                  width: "auto",
                  textAlign: "left",
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "92%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 4,
            marginBottom: 4,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            imageSize: 50,
            cellSpacing: 2,
            imageRadius: 0,
            imageAlign: "right",
            textAlign: "left",
            titleColor: "#000000",
            titleSize: 18,
            descriptionColor: "#837c7c",
            descriptionSize: 14,
            containerSpacing: 48
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1623404434024_Screenshot 2021-06-11 at 10.39.19 AM.png",
          style: {
            width: "94%",
            // height: "min-intrinsic",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 48,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Social Buttons",
          value: [
            {
              isRequired: true,
              title: "Facebook",
              url: "",
              icon: "https://i.ibb.co/FX4mz7J/ic-facebook.png"
            },
            {
              isRequired: true,
              title: "Instagram",
              url: "",
              icon: "https://i.ibb.co/VNvh8BL/ic-instagram.png"
            },
            {
              isRequired: true,
              title: "Twitter",
              url: "",
              icon: "https://i.ibb.co/qR8Q8PV/ic-twitter.png"
            },
            {
              isRequired: false,
              title: "LinkedIn",
              url: "",
              icon: "https://i.ibb.co/BzLpvD2/linkedin-2.png"
            },
            {
              isRequired: false,
              title: "Youtube",
              url: "",
              icon: "https://i.ibb.co/tJ6rf4x/youtube.png"
            },
            {
              isRequired: false,
              title: "WhatsApp",
              url: "",
              icon: "https://i.ibb.co/nn931VS/whatsapp-2.png"
            }
          ],
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 38,
            marginBottom: 34,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 26,
            cellSpacing: 10
          }
        }
      ]
    };
  }
}
