import ApiConstants from "../constants/ApiConstants";

import { removeLoader, showLoader } from "deskera-ui-library";
import http from "../http";

import TenantManager from "../managers/TenantManager";
import UserManager from "../managers/UserManager";
import { TAX_RESIDENCY } from "../constants/Enum";

class Tenant {
  static getTenantDetails(params) {
    return http
      .get(
        ApiConstants.URL.TENANT.GET_DETAILS(
          TAX_RESIDENCY[UserManager.getTaxResidency()] || ""
        ),
        { ...params, skipInterceptor: true }
      )
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static getOrgWithoutSetup(params) {
    showLoader("Creating new organisation...");
    return http
      .post(ApiConstants.URL.TENANT.ORG_WITHOUT_SETUP, {
        tenantName: params.tenantName,
        isBookkeeper: params.isBookkeeper,
        refreshToken: params.refreshToken
      })
      .then(
        (res) => {
          removeLoader();
          return Promise.resolve(res);
        },
        (err) => {
          removeLoader();
          return Promise.reject(err);
        }
      );
  }
  static addTenant(payload) {
    return http.post(ApiConstants.URL.TENANT.ADD_ORG, payload);
  }

  static updateTenant(payload) {
    return http.put(ApiConstants.URL.TENANT.UPDATE_ORG, payload);
  }

  static getAllTenants() {
    return http.get(ApiConstants.URL.TENANT.TENANTS).then(
      (res) => {
        TenantManager.setAllTenants(res);
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  static getCurrencyEnum(params = null) {
    if (!params)
      params = { limit: 200, page: 0, sortDir: "ASC", sortBy: "currencyName" };
    return http.get(ApiConstants.URL.CURRENCY.GET_ENUM, { params });
  }
  static updateTenantDetails(params) {
    return http
      .patch(
        ApiConstants.URL.TENANT.UPDATE_DETAILS(
          TAX_RESIDENCY[UserManager.getTaxResidency()] || ""
        ),
        { ...params, skipInterceptor: true }
      )
      .then(
        (res) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }
}
/**
 * @description - this is the API to get the CRM tenantSettings
 * @returns Promise<any>
 */
export const getTenantSettings = () =>
  http.get(ApiConstants.URL.TENANT.SETTINGS);

/**
 * @description - this is the API update CRM tenant settings
 * @param - setting payload
 * @returns Promise<any>
 */
export const updateTenantSettings = (payload) =>
  http.patch(ApiConstants.URL.TENANT.SETTINGS, payload);
export default Tenant;
