import ApiConstants from "../constants/ApiConstants";
import http from "../http";

export interface ProductAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: string;
}

const defaultConfig: ProductAPIConfig = {
  SearchTerm: "",
  Limit: 20,
  Page: 0,
  Query: ""
};

class ProductService {
  static productApiConfig = defaultConfig;

  static getProductEndPoint(searchTerm?: string, needAbsolutePath?: boolean) {
    ProductService.productApiConfig =
      ProductService.productApiConfig || defaultConfig;

    ProductService.productApiConfig.SearchTerm = searchTerm;

    return `${
      needAbsolutePath ? ApiConstants.URL.BASE.slice(0, -1) : ""
    }${ApiConstants.URL.PRODUCT.FETCH_PRODUCTS(
      ProductService.productApiConfig
    )}`;
  }

  static async getProductsByPage() {
    return http
      .get(ProductService.getProductEndPoint())
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }
}

export default ProductService;
