import ApiConstants from "../constants/ApiConstants";
import axios from "axios";
import { showAlert, TOAST_TYPE, showToast } from "deskera-ui-library";
import AppManager from "../managers/AppManager";
import Utility from "../utility/Utility";
import Auth from "../services/auth";
import { commonCustomEvent, COMMON_EVENTS } from "../services/event";
import { store } from "../redux/store";
import { setSettingPopup } from "../redux/slices/userPrefSlice";
import { setSelectedSetting } from "../redux/slices/userPrefSlice";
import {
  NEW_SETTINGS_SECTION,
  RECORD_DELETED_ERROR,
  RECORD_NOT_FOUND_ERROR
} from "../constants/Constant";
const FAILED_REQUEST_MAX_RETRY_COUNT = 2;
const FAILED_REQUEST_RETRY_DELAY = 2000;
const ERRORS_TO_IGNORE = [
  "Billing Info not added yet",
  "Email already sent, do you want to resend it",
  "Tenant CONTROLACCOUNT details not found.",
  "Tenant INVENTORY details not found.",
  "User is not authorized to perform the operation.",
  /** @todo To only ignore this error for new org */
  "No role is assigned, please ask company owner to assign a role."
];
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: ApiConstants.URL.BASE
});

export const getCustomAxiosInstance = (config) => {
  const customInstance = axios.create(config);
  return customInstance;
};
const requestInterceptSuccess = (config) => {
  return config;
};
const requestInterceptError = (error) => {
  return Promise.reject(error);
};
const responseInterceptSuccess = (response) => {
  AppManager.didSessionExpired = false;
  if (response.data && response.data.code && response.data.errorMessage) {
    let message = response.data.errorMessage || response.data.debugMessage;
    let alertTitle = response.data.errorTitle || "Error occurred!";

    if (
      response.data.isDuplicate &&
      !response.config?.params?.skipDuplicateInterceptor
    ) {
      showDuplicateRecordAlert(alertTitle, message);
    } else {
      showAlert(alertTitle, message);
    }

    return Promise.reject(response.data);
  }
  return response.data;
};

/**
 * @description
 * This function will be called when session is valid, but no response received from server.
 * It will retry the same request after 2seconds.
 * In case if it still fails, will show error boundary to inform user to refresh the app.
 */
const retryApiCall = async (error) => {
  const errorConfig = { ...error.config };

  errorConfig._retryCount = errorConfig._retryCount || 0;

  if (
    errorConfig._retryCount >= FAILED_REQUEST_MAX_RETRY_COUNT ||
    (errorConfig.method === "post" && !errorConfig.params?._allowRetry)
  ) {
    commonCustomEvent.dispatch(COMMON_EVENTS.ERROR_OCCURRED, { detail: {} });
    return Promise.reject(error);
  }

  errorConfig._retryCount += 1;
  errorConfig._isRetryRequest = true;
  const delayRetry = new Promise((resolve) =>
    setTimeout(() => resolve(), FAILED_REQUEST_RETRY_DELAY)
  );
  return delayRetry.then(() => axiosInstance.request(errorConfig));
};
/**
 * @description
 * Axios is currently not getting response in case of 401, 403, 5XX, ERR_EMPTY_RESPONSE
 * So here we are checking if session is actually expired or not.
 */
const onEmptyResponse = async (error) => {
  const AuthService = Auth.getInstance();

  if (!AppManager.didSessionExpired && !error?.config?._isRetryRequest) {
    const authData = await AuthService.checkIfUserLoggedIn();
    if (authData && authData?.accessToken) {
      // ACTIVE SESSION // DO NOTHING
      //return Promise.resolve(authData);
    } else {
      AppManager.gotoLoginPage();
      return Promise.reject(error);
    }
  }

  if (AppManager.didSessionExpired) {
    return Promise.reject(error);
  }

  return retryApiCall(error);
};

let isErrorAlertVisible = false;

function showDuplicateRecordAlert(alertTitle, message) {
  showAlert(
    alertTitle || `Duplicate record found!`,
    message ||
      `As per your duplication settings, we have rejected this entry. Manage your duplicate record checks in Settings.`,
    [
      {
        title: "Ok",
        className: "bg-gray1 border-m",
        onClick: () => {}
      },
      {
        title: "Go to setting",
        className: "bg-button text-white ml-r",
        onClick: () => {
          store.dispatch(setSettingPopup(true));
          store.dispatch(
            setSelectedSetting(NEW_SETTINGS_SECTION.DUPLICATION_SETTINGS)
          );
        }
      }
    ]
  );
}

const responseInterceptError = (error) => {
  /**
   * @description - Add the extra parameter to the request to skip response interceptors
   */
  if (!!error?.config?.params?.skipInterceptor) {
    return Promise.reject(error);
  } else {
    if (Utility.isEmptyObject(error.response)) {
      return onEmptyResponse(error);
    } else if (
      error.response.data &&
      (error.response.data.errorCode || error.response.data.code) &&
      error.response.data.errorMessage
    ) {
      if (ERRORS_TO_IGNORE.includes(error.response.data.errorMessage))
        return Promise.reject(error.response.data);

      let alertTitle = error.response.data.errorTitle || "Error occurred!";
      let message = error.response.data.errorMessage;

      if (
        error.response.data.isDuplicate &&
        !error.config?.params?.skipDuplicateInterceptor
      ) {
        showDuplicateRecordAlert(alertTitle, message);
        return Promise.reject(error.response.data);
      } else if (
        error.response.data.errorMessage.toLowerCase() ===
          "user not authorized" ||
        error.response.data.errorMessage.toLowerCase() === "user not authorised"
      ) {
        showToast(error.response.data.errorMessage, TOAST_TYPE.FAILURE);
      } else {
        if (
          error.config &&
          error.config.url &&
          (error.response.data.errorMessage === RECORD_NOT_FOUND_ERROR ||
            error.response.data.errorMessage === RECORD_DELETED_ERROR)
        ) {
          // no alert required if helpcenter get
        } else {
          if (!message && error.response.data.debugMessage) {
            message = error.response.data.debugMessage;
          }

          if (isErrorAlertVisible) {
            showToast(message, TOAST_TYPE.FAILURE);
          } else {
            isErrorAlertVisible = true;
            showAlert(alertTitle, message, [
              {
                title: "Ok",
                className: "bg-button text-white",
                onClick: () => (isErrorAlertVisible = false)
              }
            ]);
          }
        }
      }
      return Promise.reject(error.response.data);
    } else {
      const isRecordNotFoundError =
        error.response.status === 404 &&
        typeof error.response.data === "string" &&
        [RECORD_NOT_FOUND_ERROR, RECORD_DELETED_ERROR].includes(
          error.response.data.toLowerCase()
        );
      const isDealPipelineNotFoundError =
        error.response.status === 400 &&
        typeof error.response.data === "string" &&
        error.response.data === "Unable to find pipelines";
      const isRecordLinkedError =
        error?.response?.status === 409 &&
        !Utility.isEmptyObject(error?.response?.data);
      if (
        !isRecordLinkedError ||
        isRecordLinkedError === "undefined" ||
        isRecordLinkedError === null
      ) {
        if (
          !isErrorAlertVisible &&
          !isRecordNotFoundError &&
          !isDealPipelineNotFoundError
        ) {
          isErrorAlertVisible = true;
          let errorMessage =
            typeof error.response?.data === "string"
              ? error.response.data
              : "There was some problem with server. Please try again later.";
          if (error.response?.data?.message) {
            errorMessage = error.response.data.message;
          }
          showAlert("Error occurred!", errorMessage, [
            {
              title: "Ok",
              className: "bg-button text-white",
              onClick: () => (isErrorAlertVisible = false)
            }
          ]);
        }
      }

      return Promise.reject(error.response);
    }
  }
};

axiosInstance.interceptors.request.use(
  (response) => requestInterceptSuccess(response),
  (error) => requestInterceptError(error)
);

axiosInstance.interceptors.response.use(
  (response) => responseInterceptSuccess(response),
  (error) => responseInterceptError(error)
);

export default axiosInstance;
