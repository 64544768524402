import { INPUT_TYPE } from "deskera-ui-library";
import Utility from "../../utility/Utility";
import { BooksHelper } from "../../Helper/BooksHelper";
import { addYears, startOfDay } from "date-fns";

export const productFormatter = ({ value }) => {
  return value
    ? `${value.name}<br /><span style="font-size: 10px; color:gray; margin-top: 3px;">${value.hsnOrSacCode ? `HSN/SAC: ${value.hsnOrSacCode}` : ""}</span>`
    : "";
};
export const productDataParser = (data) => data?.content;
export const productRenderer = (index, product) => {
  return (
    <div className="column parent-width">
      <div className="row parent-width"> {product.name} </div>
      <div className="row parent-width justify-content-between fs-s">
        <div className="row text-gray"> {product?.documentSequenceCode} </div>
        {product.barcode && (
          <div className="row">
            <div className="column">
              <svg
                width="12px"
                height="12px"
                viewBox="0 0 12 12"
                fill="none"
                preserveAspectRatio="xMidYMid meet"
                focusable="false"
              >
                <path
                  d="M0 1.50006H1.17434V10.5001H0V1.50006ZM1.89308 10.5001H2.55422V1.50006H1.89308V10.5001ZM3.03077 10.5001H3.6906V1.50006H3.03077V10.5001ZM4.55859 10.5001H6.00393V1.50006H4.55859V10.5001ZM7.16125 10.5001H7.88392V1.50006H7.16125V10.5001ZM9.00458 10.5001H12V1.50006H9.00458V10.5001Z"
                  fill="#B5B4B4"
                >
                  {" "}
                </path>
              </svg>
            </div>
            <div className="column">{product.barcode}</div>
          </div>
        )}
      </div>
    </div>
  );
};
export const uomFormatter = (obj) => {
  const value = obj.value;
  if (!Utility.isEmptyObject(value) && obj?.rowData?.productQuantity) {
    return `${value.name}<br><span style="font-size: 10px; color:gray; margin-top: 3px;">${
      value.sourceConversionFactor
        ? `${Utility.roundOff((value.sourceConversionFactor / value.sinkConversionFactor) * obj.rowData.productQuantity)} ${
            BooksHelper.getBaseUomName(value).name
          }`
        : ""
    }</span>`;
  } else {
    return value.name;
  }
};
export const uomOptionRenderer = (index, uom) => {
  return (
    <div className="column parent-width">
      <div className="row parent-width">{uom?.name}</div>
    </div>
  );
};
export const PRICE_BOOK_COLUMN_CONFIG = [
  {
    id: "product",
    key: "product",
    name: "Product",
    type: INPUT_TYPE.DROPDOWN,
    width: 180,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    allowColumnSort:true,
    formatter: productFormatter,
    dropdownConfig: {
      title: "Select Product",
      allowSearch: true,
      searchableKey: "name",
      style: { minWidth: 230 },
      className: "shadow-m width-auto",
      searchApiConfig: {
        getUrl: null,
        dataParser: productDataParser,
        debounceTime: 300
      },
      data: [],
      renderer: productRenderer,
      onSelect: (index, obj, rowIndex) => {},
      button: {
        title: "+ Add New",
        className: "bg-button text-white",
        onClick: () => {}
      }
    }
  },
  {
    id: "documentSequenceCode",
    key: "documentSequenceCode",
    name: "Product Code",
    type: INPUT_TYPE.TEXT,
    width: 160,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true
  },
  {
    id: "uomId",
    key: "uomId",
    name: "UOM",
    type: INPUT_TYPE.DROPDOWN,
    width: 160,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    formatter: uomFormatter,
    allowColumnSort:false,
    dropdownConfig: {
      title: "Select UOM",
      allowSearch: true,
      searchableKey: "name",
      className: "shadow-m width-auto",
      data: [],
      renderer: uomOptionRenderer,
      onSelect: (index, obj, rowIndex) => {}
    }
  },
  {
    id: "minimumQuantity",
    key: "minimumQuantity",
    name: "Minimum Quantity",
    type: INPUT_TYPE.NUMBER,
    width: 160,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    allowColumnSort:false,
    textAlign: "right"
  },
  {
    id: "currency",
    key: "currency",
    name: "Currency",
    type: INPUT_TYPE.NUMBER,
    width: 125,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    required: true,
    allowColumnSort:false,
    textAlign: "right"
  },
  {
    editable: true,
    hidden: false,
    id: "discount",
    key: "discount",
    name: "Discount",
    systemField: true,
    textAlign: "right",
    type: "text",
    uiVisible: true,
    allowColumnSort:false,
    width: 100
  }
];

export const initialPriceListFormValues = {
  type: {
    key: "",
    hidden: false,
    value: { label: "Sell", value: "SELL" },
    defaultValue: { label: "Sell", value: "SELL" },
    type: INPUT_TYPE.DROPDOWN,
    hasError: false,
    errorMsg: ""
  },
  priceBookName: {
    key: "",
    hidden: false,
    value: "",
    type: INPUT_TYPE.INPUT,
    hasError: false,
    isMandatory: true,
    errorMsg: ""
  },
  currency: {
    key: "",
    hidden: false,
    value: { label: "", value: "" },
    type: INPUT_TYPE.DROPDOWN,
    defaultValue: { label: "", value: "" }
  },
  shippingCountry: {
    key: "",
    hidden: false,
    // value: [{ label: '', value: '' }],
    hasError: false,
    type: INPUT_TYPE.DROPDOWN,
    defaultValue: { label: "", value: "" }
  },
  billingCountry: {
    key: "",
    hidden: false,
    // value: [{ label: '', value: '' }],
    hasError: false,
    type: INPUT_TYPE.DROPDOWN,
    defaultValue: { label: "", value: "" }
  },
  effectiveDate: {
    isOpen: false,
    value: startOfDay(new Date())
  },
  expiryDate: {
    isOpen: false,
    value: startOfDay(addYears(new Date(), 1))
  },
  isDefault: {
    value: false
  },
  productRow: [],
  customFields: []
};
export const PRICE_LIST_TYPE_MOCK_DATA = [
  {
    name: "Buy",
    value: "BUY"
  },
  {
    name: "Sell",
    value: "SELL"
  }
];
