import { Component } from "react";
import { PAGE_ELEMENT } from "./ComponentList";
import ComponentManager from "./ComponentManager";
import CustomHtml from "./canvas_components/CustomHtml";
import Form from "./canvas_components/Form";
import Social from "./canvas_components/Social";
import ImageText from "./canvas_components/ImageText";
import Collection from "./canvas_components/Collection";
import Button from "./canvas_components/Button";
import Text from "./canvas_components/Text";
import ColumnSection from "./canvas_components/ColumnSection";
import Image from "./canvas_components/Image";
import VideoSection from "./canvas_components/VideoSection";
import OrderForm from "./canvas_components/OrderForm";
import CountDownTimer from "./canvas_components/CountDownTimer";
import ImageGallery from "./canvas_components/ImageGallery";
import { store } from "../../redux/store";
import VStack from "./canvas_components/VStack";
import Logo from "./canvas_components/Logo";

/*
PROPS
- data
- index
- isEditing
- viewType
- onViewTypeChange
- currentComponentIndex
- currentColumnSectionIndex
- pageType (PAGE_TYPE)
- onScriptAdd
- onMetaDataAdd
- components
- onDataChange
- hash
*/

class ElementComponentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      index: this.props.index,
      viewType: this.props.viewType,
      isEditing: this.props.isEditing,
      components: this.props.components,
      currentComponentIndex: this.props.currentComponentIndex,
      currentColumnSectionIndex: this.props.currentColumnSectionIndex,
      currentColumnComponentIndex: this.props.currentColumnComponentIndex,
      needToShowImagePopup: this.props.needToShowImagePopup,
      isInlineTextEdit: this.props.isInlineTextEdit
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      index: nextProps.index,
      data: nextProps.data,
      viewType: nextProps.viewType,
      components: nextProps.components,
      currentComponentIndex: nextProps.currentComponentIndex,
      currentColumnSectionIndex: nextProps.currentColumnSectionIndex,
      currentColumnComponentIndex: nextProps.currentColumnComponentIndex,
      needToShowImagePopup: nextProps.needToShowImagePopup,
      isInlineTextEdit: nextProps.isInlineTextEdit
    });
  }
  render() {
    return <>{this.getComponentList(this.props.data, this.props.index)}</>;
  }

  getComponentList = (data, index) => {
    return (
      <>
        {data.type === PAGE_ELEMENT.TEXT && this.getText(data, index)}
        {data.type === PAGE_ELEMENT.BUTTON && this.getButton(data, index)}
        {data.type === PAGE_ELEMENT.IMAGE && this.getImage(data, index)}
        {data.type === PAGE_ELEMENT.VIDEO && this.getVideoSection(data, index)}

        {data.type === PAGE_ELEMENT.SOCIAL_BUTTONS &&
          this.getSocialSection(data, index)}

        {(data.type === PAGE_ELEMENT.COLLECTION ||
          data.type === PAGE_ELEMENT.ICON) &&
          this.getCollectionSelection(data, index)}

        {data.type === PAGE_ELEMENT.LOGO && this.getLogo(data, index)}

        {data.type === PAGE_ELEMENT.IMAGE_TEXT &&
          this.getImageTextDefaultData(data, index)}

        {data.type === PAGE_ELEMENT.FORM &&
          this.getFormsDefaultData(data, index)}

        {data.type === PAGE_ELEMENT.CUSTOM_HTML &&
          this.getCustomHtmlDefaultData(data, index)}

        {data.type === PAGE_ELEMENT.ORDER_FORM &&
          this.getOrderFormSection(data, index)}

        {data.type === PAGE_ELEMENT.IMAGE_GALLERY &&
          this.getImageGalleryDefaultData(data, index)}

        {data.type === PAGE_ELEMENT.COLUMN_SECTION &&
          this.getColumnSection(data, index)}

        {data.type === PAGE_ELEMENT.COUNTDOWN_TIMER &&
          this.getCountDownTimer(data, index)}

        {data.type === PAGE_ELEMENT.V_STACK && this.getVStak(data, index)}
      </>
    );
  };

  getText(data, index) {
    return (
      <Text
        data={data}
        index={index}
        columnIndex={this.props.columnIndex}
        colComponentIndex={this.props.colComponentIndex}
        componentIndex={this.props.componentIndex}
        isEditing={this.state.isEditing}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.getSelectedComponentId(),
          this.getComponetId(index),
          this.state.isEditing
        )}
        inlineEditing={this.editCall(
          index,
          this.props.columnIndex,
          this.props.colComponentIndex,
          this.props.componentIndex
        )}
        onCloseInlineEdit={this.closeInlineEdit}
        enableInlineTextEdit={this.enableInlineTextEdit}
        onTextChange={(value, index, colIndex, colComponetIndex) =>
          this.onCollectionValueChange(value, index, colIndex, colComponetIndex)
        }
      />
    );
  }
  getButton(data, index) {
    return (
      <Button
        data={data}
        index={index}
        columnIndex={this.props.columnIndex}
        colComponentIndex={this.props.colComponentIndex}
        componentIndex={this.props.componentIndex}
        isEditing={this.state.isEditing}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.getSelectedComponentId(),
          this.getComponetId(index),
          this.state.isEditing
        )}
        onTextChange={(value, index, colIndex, colComponetIndex) =>
          this.onCollectionValueChange(value, index, colIndex, colComponetIndex)
        }
      />
    );
  }

  getCollectionSelection(data, index) {
    return (
      <Collection
        data={data}
        index={index}
        viewType={this.state.viewType}
        isEditing={this.state.isEditing}
        currentComponentIndex={this.state.currentComponentIndex}
        currentColumnSectionIndex={this.state.currentColumnSectionIndex}
        currentInnerComponentType={this.props.currentInnerComponentType}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.state.currentComponentIndex,
          index,
          this.state.isEditing
        )}
        onMouseDown={(itemIndex, type) => {
          if (
            (this.state.currentColumnSectionIndex !== null &&
              this.state.currentColumnSectionIndex !== undefined &&
              this.state.currentColumnSectionIndex !== itemIndex) ||
            (this.props.currentInnerComponentType !== null &&
              this.props.currentInnerComponentType !== undefined &&
              this.props.currentInnerComponentType !== type)
          ) {
            this.props.shiftFocusToDummyDiv();
          }
        }}
        onComponentSelect={(index, itemIndex, inlineEdit, type) => {
          this.props.onComponentSelect(
            index,
            itemIndex,
            null,
            inlineEdit,
            type
          );
        }}
        onValueChange={(value, index, colIndex, type) => {
          this.onChangeCollectionValue(value, index, colIndex, type, true);
        }}
      />
    );
  }

  getLogo(data, index) {
    return (
      <Logo
        data={data}
        index={index}
        columnIndex={this.props.columnIndex}
        colComponentIndex={this.props.colComponentIndex}
        componentIndex={this.props.componentIndex}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.getSelectedComponentId(),
          this.getComponetId(index),
          this.state.isEditing
        )}
        isEditing={this.state.isEditing}
        showImagePopup={this.editImageCall(
          index,
          this.props.columnIndex,
          this.props.colComponentIndex,
          this.props.componentIndex
        )}
        onShowImagePopup={this.showImagePopup}
        onClosePopUp={this.closeImagePopup}
        onValueChange={(value, index, colIndex, colComponetIndex) => {
          this.onCollectionValueChange(
            value,
            index,
            colIndex,
            colComponetIndex
          );
        }}
      />
    );
  }

  getImageTextDefaultData(data, index) {
    return (
      <ImageText
        index={index}
        viewType={this.state.viewType}
        isEditing={this.state.isEditing}
        data={data}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.state.currentComponentIndex,
          index,
          this.state.isEditing
        )}
        onMouseDown={(type) => {
          if (
            this.props.currentInnerComponentType !== null &&
            this.props.currentInnerComponentType !== undefined &&
            this.props.currentInnerComponentType !== type
          ) {
            this.props.shiftFocusToDummyDiv();
          }
        }}
        currentComponentIndex={this.state.currentComponentIndex}
        currentColumnSectionIndex={this.state.currentColumnSectionIndex}
        currentInnerComponentType={this.props.currentInnerComponentType}
        onComponentSelect={(index, itemIndex, inlineEdit, type) => {
          this.props.onComponentSelect(
            index,
            itemIndex,
            null,
            inlineEdit,
            type
          );
        }}
        onValueChange={(value, index, colIndex, type) => {
          this.onChangeCollectionValue(value, index, colIndex, type, true);
        }}
      />
    );
  }

  getSocialSection(data, index) {
    return (
      <Social
        data={data}
        index={index}
        columnIndex={this.props.columnIndex}
        colComponentIndex={this.props.colComponentIndex}
        isEditing={this.state.isEditing}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.getSelectedComponentId(),
          this.getComponetId(index),
          this.state.isEditing
        )}
      />
    );
  }

  getFormsDefaultData(data, index) {
    return (
      <Form
        viewType={this.state.viewType}
        data={data}
        index={index}
        columnIndex={this.props.columnIndex}
        colComponentIndex={this.props.colComponentIndex}
        componentIndex={this.props.componentIndex}
        hash={this.props.hash}
        isEditing={this.state.isEditing}
        onDoubleClick={() =>
          this.props.onComponentSelect(index, null, null, true)
        }
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.getSelectedComponentId(),
          this.getComponetId(index),
          this.state.isEditing
        )}
      />
    );
  }

  getCustomHtmlDefaultData(data, index) {
    return (
      <CustomHtml
        data={data}
        index={index}
        columnIndex={this.props.columnIndex}
        colComponentIndex={this.props.colComponentIndex}
        isEditing={this.state.isEditing}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.getSelectedComponentId(),
          this.getComponetId(index),
          this.state.isEditing
        )}
      />
    );
  }

  getColumnSection(data, index) {
    return (
      <ColumnSection
        data={data}
        index={index}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.state.currentComponentIndex,
          index,
          this.state.isEditing
        )}
        isEditing={this.state.isEditing}
        hash={this.props.hash}
        components={this.state.components}
        currentComponentIndex={this.state.currentComponentIndex}
        currentColumnSectionIndex={this.state.currentColumnSectionIndex}
        currentColumnComponentIndex={this.state.currentColumnComponentIndex}
        viewType={this.state.viewType}
        pageType={this.props.pageType}
        onValueChange={(value) => {
          this.onValueChange(value, index);
        }}
        needToShowImagePopup={this.state.needToShowImagePopup}
        isInlineTextEdit={this.state.isInlineTextEdit}
        onDataChange={(data) => this.props.onDataChange(data)}
        onComponentSelect={(index, itemIndex, colIndex, inlineEdit) =>
          this.props.onComponentSelect(index, itemIndex, colIndex, inlineEdit)
        }
        onCloseInlineEdit={this.closeInlineEdit}
        onClosePopUp={this.closeImagePopup}
        onShowImagePopup={this.showImagePopup}
        onShowInlineEdit={this.showInlineEdit}
        enableInlineTextEdit={this.enableInlineTextEdit}
      />
    );
  }
  getImage(data, index) {
    return (
      <Image
        data={data}
        index={index}
        columnIndex={this.props.columnIndex}
        colComponentIndex={this.props.colComponentIndex}
        componentIndex={this.props.componentIndex}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.getSelectedComponentId(),
          this.getComponetId(index),
          this.state.isEditing
        )}
        isEditing={this.state.isEditing}
        showImagePopup={this.editImageCall(
          index,
          this.props.columnIndex,
          this.props.colComponentIndex,
          this.props.componentIndex
        )}
        onClosePopUp={this.closeImagePopup}
        onShowImagePopup={this.showImagePopup}
        onValueChange={(value, index, colIndex, colComponetIndex) => {
          this.onCollectionValueChange(
            value,
            index,
            colIndex,
            colComponetIndex
          );
        }}
      />
    );
  }

  getVideoSection(data, index) {
    return (
      <VideoSection
        data={data}
        pageType={this.props.pageType}
        index={index}
        columnIndex={this.props.columnIndex}
        colComponentIndex={this.props.colComponentIndex}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.getSelectedComponentId(),
          this.getComponetId(index),
          this.state.isEditing
        )}
        isEditing={this.state.isEditing}
      />
    );
  }

  getOrderFormSection(data, index) {
    return (
      <OrderForm
        data={data}
        hash={this.props.hash}
        isStripeConnected={store?.getState().userPref.isStripeConnected}
        paymentAccounts={store?.getState().userPref.paymentAccounts}
        saveTemplate={this.props.onSave}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.state.currentComponentIndex,
          index,
          this.state.isEditing
        )}
        onDataChange={(cartData) => this.onCartDataChange(cartData, index)}
        isEditing={this.state.isEditing}
        viewType={this.state.viewType}
        moduleType={this.props.pageType}
      />
    );
  }
  getImageGalleryDefaultData(data, index) {
    return (
      <ImageGallery
        data={data}
        index={index}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.state.currentComponentIndex,
          index,
          this.state.isEditing
        )}
        onValueChange={(value, index) => {
          this.onChangeCollectionValue(
            value,
            this.state.currentComponentIndex,
            index,
            "image"
          );
        }}
      />
    );
  }

  getCountDownTimer(data, index) {
    return (
      <CountDownTimer
        data={data}
        index={index}
        columnIndex={this.props.columnIndex}
        colComponentIndex={this.props.colComponentIndex}
        isEditing={this.state.isEditing}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.getSelectedComponentId(),
          this.getComponetId(index),
          this.state.isEditing
        )}
      />
    );
  }

  getVStak(data, index) {
    return (
      <VStack
        data={data}
        index={index}
        columnIndex={this.props.columnIndex}
        colComponentIndex={this.props.colComponentIndex}
        componentIndex={this.props.componentIndex}
        borderStyle={ComponentManager.getCurrentComponentBorder(
          this.state.currentComponentIndex,
          index,
          this.state.isEditing
        )}
        isEditing={this.state.isEditing}
        hash={this.props.hash}
        components={this.state.components}
        currentComponentIndex={this.state.currentComponentIndex}
        currentColumnSectionIndex={this.state.currentColumnSectionIndex}
        currentColumnComponentIndex={this.state.currentColumnComponentIndex}
        viewType={this.state.viewType}
        pageType={this.props.pageType}
        onValueChange={(value, index, colIndex) => {
          this.onCollectionValueChange(value, index, colIndex);
        }}
        needToShowImagePopup={this.state.needToShowImagePopup}
        isInlineTextEdit={this.state.isInlineTextEdit}
        onDataChange={(data) => this.props.onDataChange(data)}
        onComponentSelect={(index, itemIndex, colIndex, inlineEdit) => {
          this.props.onComponentSelect(index, itemIndex, colIndex, inlineEdit);
        }}
        onCloseInlineEdit={this.closeInlineEdit}
        onClosePopUp={this.closeImagePopup}
        onShowImagePopup={this.showImagePopup}
        onShowInlineEdit={this.showInlineEdit}
        enableInlineTextEdit={this.enableInlineTextEdit}
      />
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////

  onValueChange = (value, index) => {
    let components = [...this.state.components];
    if (index !== null && index !== undefined && components[index]) {
      components[index].value = value;
      this.props.onDataChange(components[index]);
    }
  };

  onChangeCollectionValue = (value, index, arrIndex, type, isValue = false) => {
    let components = [...this.state.components];
    if (index !== null && index !== undefined && components[index]) {
      if (isValue) {
        components[index].value[arrIndex][type].value = value;
        // let newObj = Object.create(components[index].value[arrIndex]);
        this.props.onDataChange(
          components[index].value[arrIndex],
          index,
          arrIndex,
          null,
          type
        );
      } else {
        components[index].value[arrIndex][type] = value;
        this.props.onDataChange(
          components[index].value[arrIndex],
          index,
          arrIndex,
          null,
          type
        );
      }
    }
  };

  onCollectionValueChange = (value, index, columnIndex, colComponetIndex) => {
    let components = [...this.state.components];
    if (index !== null && index !== undefined && components[index]) {
      if (columnIndex !== null && columnIndex !== undefined) {
        if (colComponetIndex !== null && colComponetIndex !== undefined) {
          components[index].value[columnIndex].value[colComponetIndex].value =
            value;
        } else {
          components[index].value[columnIndex].value = value;
        }
        this.props.onDataChange(components[index]);
      } else {
        components[index].value = value;
        this.props.onDataChange(components[index], index, columnIndex);
      }
    }
  };

  onCartDataChange = (cartData, index) => {
    if (this.state.currentComponentIndex === index) {
      const componentData = this.state.components[index];
      componentData.cartData = cartData;
      this.props.onDataChange(componentData);
    } else {
      this.props.onComponentSelect(index, null, null, true);
      setTimeout(() => this.onCartDataChange(cartData, index), 100);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////

  closeInlineEdit = () => {
    this.props.onCloseInlineEdit();
  };

  closeImagePopup = () => {
    this.props.onClosePopUp();
  };

  showImagePopup = () => {
    this.props.onShowImagePopup();
  };

  showInlineEdit = () => {
    this.props.onShowInlineEdit();
  };

  enableInlineTextEdit = () => {
    this.props.enableInlineTextEdit();
  };

  getComponetId = (index) => {
    let id = "";
    if (
      this.props.columnIndex !== null &&
      this.props.columnIndex !== undefined
    ) {
      if (
        this.props.colComponentIndex !== null &&
        this.props.colComponentIndex !== undefined
      ) {
        id =
          this.props.componentIndex +
          "-" +
          this.props.columnIndex +
          "-" +
          this.props.colComponentIndex;
      } else {
        id = index + "-" + this.props.columnIndex;
      }
    } else {
      id = index;
    }
    return id;
  };

  getSelectedComponentId = () => {
    let id = "";
    if (
      this.props.columnIndex !== null &&
      this.props.columnIndex !== undefined
    ) {
      if (
        this.props.currentColumnComponentIndex !== null &&
        this.props.currentColumnComponentIndex !== undefined
      ) {
        id =
          this.props.currentComponentIndex +
          "-" +
          this.props.currentColumnSectionIndex +
          "-" +
          this.state.currentColumnComponentIndex;
      } else {
        id =
          this.state.currentComponentIndex +
          "-" +
          this.state.currentColumnSectionIndex;
      }
    } else {
      id = this.state.currentComponentIndex;
    }
    return id;
  };

  editCall = (index, colIndex, componentIndex, indexProps) => {
    /* Column Section inner components, Collection Items, Icon Items, ImageText Items */
    if (
      this.state.currentColumnSectionIndex !== null &&
      this.state.currentColumnSectionIndex !== undefined &&
      colIndex !== null &&
      colIndex !== undefined
    ) {
      if (
        this.state.currentColumnComponentIndex !== null &&
        this.state.currentColumnComponentIndex !== undefined &&
        componentIndex !== null &&
        componentIndex !== undefined
      ) {
        if (
          this.state.isEditing &&
          componentIndex === this.state.currentColumnComponentIndex &&
          indexProps === this.state.currentComponentIndex &&
          colIndex === this.state.currentColumnSectionIndex &&
          this.state.isInlineTextEdit
        ) {
          return true;
        }
      } else {
        if (
          this.state.isEditing &&
          index === this.state.currentComponentIndex &&
          colIndex === this.state.currentColumnSectionIndex &&
          this.state.isInlineTextEdit
        ) {
          return true;
        }
      }
    } else {
      if (
        this.state.isEditing &&
        (colIndex === null || colIndex === undefined) &&
        index === this.state.currentComponentIndex &&
        this.state.isInlineTextEdit
      ) {
        return true;
      }
    }
    return false;
  };

  editImageCall = (index, colIndex, componentIndex, indexProps) => {
    if (
      this.state.currentColumnSectionIndex !== null &&
      this.state.currentColumnSectionIndex !== undefined &&
      colIndex !== null &&
      colIndex !== undefined
    ) {
      if (
        this.state.currentColumnComponentIndex !== null &&
        this.state.currentColumnComponentIndex !== undefined &&
        componentIndex !== null &&
        componentIndex !== undefined
      ) {
        if (
          this.state.isEditing &&
          componentIndex === this.state.currentColumnComponentIndex &&
          indexProps === this.state.currentComponentIndex &&
          colIndex === this.state.currentColumnSectionIndex &&
          this.state.needToShowImagePopup
        ) {
          return true;
        }
      } else {
        if (
          this.state.isEditing &&
          index === this.state.currentComponentIndex &&
          colIndex === this.state.currentColumnSectionIndex &&
          this.state.needToShowImagePopup
        ) {
          return true;
        }
      }
    } else {
      if (
        this.state.isEditing &&
        (colIndex === null || colIndex === undefined) &&
        index === this.state.currentComponentIndex &&
        this.state.needToShowImagePopup
      ) {
        return true;
      }
    }
    return false;
  };
}

export default ElementComponentList;
