import React, { Component } from "react";

import { DKLabel } from "deskera-ui-library";

import ic_contact from "../../assets/tutorial/ic_contact.jpg";
import ic_campaign from "../../assets/tutorial/ic_campaign.jpg";
import ic_deal from "../../assets/tutorial/ic_deal.jpg";
import ic_form from "../../assets/tutorial/ic_form.jpg";
// import ic_funnel from "../../assets/tutorial/ic_funnel.jpg";
import ic_landing from "../../assets/tutorial/ic_landing.jpg";

class Tutorials extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="transparent-background">
        <div
          id="dk-desk-tutorial"
          className="dk-desk-tutorial column border-radius-s p-l bg-white position-absolute"
        >
          <DKLabel text="Deskera CRM+ help" className="fw-m fs-m" />
          {this.renderVideoSection()}
        </div>
      </div>
    );
  }

  componentDidMount() {
    if (this.props.onClose) {
      document.addEventListener("mouseup", this.onMouseUp);
    }
  }
  componentWillUnmount() {
    if (this.props.onClose) {
      document.removeEventListener("mouseup", this.onMouseUp);
    }
  }
  onMouseUp = (e) => {
    if (this.props.onClose) {
      const target = e.target;
      const editingContainer = target.closest("#dk-desk-tutorial");
      if (!editingContainer) {
        this.props.onClose();
      }
    }
  };

  renderVideoSection() {
    const videos = [
      {
        title: "Create & Import contacts in Deskera CRM+",
        icon: ic_contact,
        url: "https://youtu.be/XYgSQ8pBFUQ"
      },
      {
        title: "Create deals in Deskera CRM+",
        icon: ic_deal,
        url: "https://youtu.be/GQUznx416F4"
      },
      {
        title: "Create campaigns in Deskera CRM+",
        icon: ic_campaign,
        url: "https://youtu.be/kl0nDwv4I60"
      },
      {
        title: "Create forms in Deskera CRM+",
        icon: ic_form,
        url: "https://youtu.be/Wv564Q1-dd0"
      },
      {
        title: "Create landing pages in Deskera CRM+",
        icon: ic_landing,
        url: "https://youtu.be/tUv0BmZsjHQ"
      }
      /* {
        title: "Create funnels in Deskera CRM+",
        icon: ic_funnel,
        url: "https://youtu.be/SAvUJhcL8bI",
      }, */
    ];
    return (
      <div className="row parent-width flex-wrap mt-l" style={{ gap: 20 }}>
        {videos.map((video) => {
          return (
            <div
              className=" cursor-hand listPickerBG"
              style={{ width: "47%" }}
              onClick={() => {
                window.open(video.url, "_blank");
              }}
            >
              <img
                src={video.icon}
                alt=""
                className="parent-width border-m border-radius-s"
                style={{}}
              />
              <DKLabel
                text={video.title}
                className=" text-dark-gray p-xs fw-m"
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default Tutorials;
