import React from "react";
import { DKLabel, DKButton, DKIcon } from "deskera-ui-library";
import DKLabel2 from "./DKLabel2";

/**
 * PROPS
 * image: string;
 * header: string;
 * bullets?: { title: string, subTitle: string }[];
 * buttons?: { title: string, className: string, icon: string }[]
 */
const TextAndImage = ({
  image,
  title,
  bullets,
  buttons,
  isReverse = false
}) => {
  return (
    <div
      className={`justify-content-between align-items-start padding-2 pt-xl ${
        isReverse ? "row-reverse" : "row"
      }`}
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "80rem"
      }}
    >
      <div className="column p-v-xxl text-image-title-wrapper">
        <DKLabel2
          className="fw-h fs-xxxl mb-l"
          style={{ lineHeight: "2.25rem", letterSpacing: " -.025em" }}
          text={title}
        />
        {bullets?.map((data) => {
          return (
            <div className="mb-xl column">
              <DKLabel2 text={data.title} className="fw-h fs-l" />
              <DKLabel2
                className="text-gray fs-l"
                style={{ lineHeight: "1.5" }}
                text={data.subTitle}
              />
            </div>
          );
        })}
        {buttons?.map((button) => {
          return (
            <DKButton
              title={button.title}
              className={`fw-m fs-l ${button.className}`}
              icon={button.icon}
              style={{ marginTop: 0 }}
            />
          );
        })}
      </div>
      <div
        className="column parent-height p-h-xl display-only-web"
        style={{ width: "50%" }}
      >
        <DKIcon
          className="p-h-xl parent-height"
          src={image}
          style={{ objectFit: "cover" }}
        />
      </div>
    </div>
  );
};

export default TextAndImage;
