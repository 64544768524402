import ApiConstants from "../../constants/ApiConstants";
import httpClient from "../../http";
import { store } from "../../redux/store";
import Utility from "../../utility/Utility";
import { IUserPermission } from "../../model/PermissionModel";
import UserManager from "../../managers/UserManager";
import { TEAMS } from "../../constants/Permission";
import { TABLES } from "../../managers/TableManger";

export default class PermissionService {
  private static _instance: PermissionService;
  public static getInstance(): PermissionService {
    if (!this._instance) {
      this._instance = new PermissionService();
    }
    return this._instance;
  }
  public updateUserPermission(payload: IPermissionPayload) {
    return httpClient.patch(
      ApiConstants.URL.PERMISSION.UPDATE_PERMISSION,
      payload
    );
  }
  public getUserPermissionByModule(module: string) {
    const currentUsersPermissions =
      store.getState().rolesPermission?.currentUsersRole?.permissions?.data ||
      [];
    return (
      currentUsersPermissions.find((item) => item.objectType === module)
        ?.permissions || []
    );
  }
  /**
   * @description
   * Utility to check if current user is the owner of a record,
   * to allow user to edit or delete the record, irrespective of their permissions.
   * i.e. in CONTACT, DEAL & ACTIVITY modules.
   */
  public isRecordOwner(recordData: any) {
    if (Utility.isEmptyObject(recordData)) return false;

    const currentUserId = UserManager.getUserIamID().toString();

    const recordOwners = recordData.permissions?.owners || [];
    const isSubOwner = this.isRecordSubOwner?.(recordData);
    const isTeamLead = this.isTeamLeadByRecord?.(recordData);

    return Boolean(
      recordOwners.includes(currentUserId) || isSubOwner || isTeamLead
    );
  }
  /**
   * @description
   * Utility to check if current user is the sub owner of a record,
   * to allow user to edit or delete the record, irrespective of their permissions.
   * i.e. in CONTACT, DEAL modules.
   */
  public isRecordSubOwner(recordData: any) {
    if (Utility.isEmptyObject(recordData)) return false;

    const currentUserId = UserManager.getUserIamID().toString();

    const recordSubOwners = recordData.permissions?.sub_owners || [];

    return Boolean(recordSubOwners.includes(currentUserId));
  }
  /**
   * @description
   * Utility to check if a record is visible through team lead permission.
   * So as to provide it's access to user.
   */
  public isTeamLeadByRecord(recordData: any) {
    const currentUserId = UserManager.getUserIamID().toString();
    const teamLeads =
      recordData?.permissions?.team_designation?.team_lead || [];
    return Boolean(teamLeads.includes(currentUserId));
  }
  /**
   * @description
   * Utility to check if current user is the team lead of any team.
   * We assign special privilege to this user.
   * i.e. to be able to see all records assigned
   * to fellow team members in CONTACT, DEAL, ACTIVITY modules.
   */
  public isTeamLead(userId?: any) {
    if (!userId) {
      userId = UserManager.getUserIamID().toString();
    }

    const teams = store.getState()?.tenant?.teams;
    return Boolean(
      teams?.some((team: any) =>
        team.users.some(
          (user) =>
            user.iamUserId.toString() === userId &&
            user.designation === TEAMS.TEAM_LEAD
        )
      )
    );
  }
  /**
   *
   * @param tableName - the table name
   * @param permissionType - the permission type respective to the given table
   * @returns - boolean value indicating whether the user has the permission or not
   */
  public isUserPermitted(tableName: string, permissionType: string[]) {
    const modulePermission = this.getUserPermissionByModule(
      this.getUpdatedModuleName(tableName)
    );
    if (Utility.isEmptyObject(modulePermission)) return false;
    return permissionType.every((item) => modulePermission.includes(item));
  }
  /**
   *
   * @param tableName - the table name
   * @returns - the updated module name
   * @description - this function is used to convert the table name to UpperCase, also some tables are named differently it also replaces it
   */
  public getUpdatedModuleName(tableName: string): string {
    if (tableName === TABLES.BOOKS_QUOTE) {
      tableName = "quote";
    }
    if (tableName === TABLES.SEGMENT) {
      tableName = TABLES.CONTACT;
    }
    return tableName.toUpperCase();
  }

  public userHasOwnerOrAdminRole() {
    const currentUserRole = store.getState().rolesPermission?.currentUsersRole;

    if (
      currentUserRole?.default &&
      ["owner", "admin"].includes(currentUserRole?.name.toLowerCase())
    )
      return true;

    return false;
  }
}

export interface IPermissionPayload {
  data: IUserPermission[];
}
