import { store } from "../redux/store";

export const roundOff = (valToRoundOff: any, precisionVal = 2) => {
  valToRoundOff = Number(valToRoundOff);
  valToRoundOff = valToRoundOff + 1 / Math.pow(10, precisionVal + 10);
  let roundedNumber =
    Math.round(valToRoundOff * Math.pow(10, precisionVal)) /
    Math.pow(10, precisionVal);
  return roundedNumber;
};

export const roundOffToTenantDecimalScale = (
  val: number,
  decimalScale?: number
) => {
  const tenantDecimalScale = store.getState().books.tenantsDetails.decimalScale;
  return roundOff(
    val,
    decimalScale ? decimalScale : tenantDecimalScale ? tenantDecimalScale : 2
  );
};
