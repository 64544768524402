import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProductGroups } from "../../services/productGroup";

export interface ProductGroupState {
  productGroupList: {};
}

const initialState: ProductGroupState = {
  productGroupList: {}
};
export const fetchProductGroupList = createAsyncThunk(
  "productGroup/fetchProductGroupList",
  async (data: any, thunkAPI) => {
    const response = await getProductGroups(data);
    return response;
  }
);

export const ProductGroupSlice = createSlice({
  name: "records",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProductGroupList.fulfilled, (state, action: any) => {
      state.productGroupList = action?.payload || {};
    });
  }
});
export const productGroupSelector = (state) =>
  state.productGroup.productGroupList;
export default ProductGroupSlice.reducer;
