import {
  DKLabel,
  INPUT_TYPE,
  Toggle,
  DKInput,
  INPUT_VIEW_DIRECTION,
  DKButton,
  showAlert
} from "deskera-ui-library";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { updateTenantSettings } from "../../services/tenant";
import Utility from "../../utility/Utility";
import { isViewportMobile } from "../../utility/GetViewportSize";

export default function TicketAutoMailCCSetting(props: any) {
  const dispatch = useAppDispatch();
  const crmSettings = useAppSelector(selectCRMSettings());
  const [ccEMailToggle, setCcEMailToggle] = useState(false);
  const [ccEMailList, setCcEMailList] = useState("");

  useEffect(() => {
    setCcEMailToggle(
      crmSettings?.crmEmailSetting?.supportEmailAutoReplyDefaultRecipients
        ?.length > 0
    );
    setCcEMailList(
      crmSettings?.crmEmailSetting?.supportEmailAutoReplyDefaultRecipients?.toString()
    );
  }, [crmSettings?.crmEmailSetting]);

  const onSave = async () => {
    try {
      const ccEmails = !Utility.isEmptyObject(ccEMailList)
        ? ccEMailList?.split(",")
        : [];

      if (ccEmails?.length > 0) {
        let invalidEmail = ccEmails.every((email) =>
          Utility.isValidEmail(email?.trim())
        );
        if (!invalidEmail) {
          showAlert(`Invalid Email`, `Please add a valid email`);
          return;
        }
      }

      const payload = {
        crmEmailSetting: { supportEmailAutoReplyDefaultRecipients: ccEmails }
      };

      const response = await updateTenantSettings(payload);
      if (!Utility.isEmptyObject(response)) {
        dispatch(fetchCRMSettings({}));
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  const checkEmailValueSame = () => {
    let emails = !Utility.isEmptyObject(ccEMailList)
      ? ccEMailList?.split(",")
      : [];
    return (
      crmSettings?.crmEmailSetting?.supportEmailAutoReplyDefaultRecipients
        ?.length !== emails?.length
    );
  };
  // const width = isViewportMobile()?"100%"
  return (
    <div className="column parent-width border-m border-radius-m position-relative p-r mt-l">
      <div className="column parent-width">
        <div className="row parent-width justify-content-between row-responsive">
          <div className="column" style={{ width: "70%" }}>
            <DKLabel
              text={`Support Ticket Auto Reply Email Setting`}
              className="mr-m mb-xs fw-m"
            />
            <DKLabel
              className="text-gray text-ellipsis"
              text="You can add auto reply emails for new generated ticket"
            />
          </div>
          <div className="column align-items-end">
            {!ccEMailToggle && (
              <Toggle
                isOn={ccEMailToggle}
                onChange={() => {
                  setCcEMailToggle(!ccEMailToggle);
                }}
                className=""
                color="bg-green"
              />
            )}
            {ccEMailToggle && (
              <div className="row align-items-start">
                <div className="column" style={{ minWidth: 300 }}>
                  <DKInput
                    style={{ width: 200 }}
                    title=""
                    className="mt-xs"
                    type={INPUT_TYPE.TEXT}
                    value={ccEMailList}
                    required
                    onChange={(value) => setCcEMailList(value)}
                    direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  />
                  <DKLabel
                    className="mt-xs text-gray fs-s"
                    text={`Use a comma to separate multiple email addresses`}
                  />
                </div>
                {checkEmailValueSame() && (
                  <DKButton
                    title="Save"
                    className="bg-button text-white m-s"
                    onClick={() => onSave()}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
