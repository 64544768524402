import { useEffect, useRef, useState } from "react";
import ApiManager from "../../managers/ApiManager";
import Utility from "../../utility/Utility";
import { showToast, TOAST_TYPE, showAlert } from "deskera-ui-library";

export const DEFAULT_QUALITY_FACTOR = 0.75;
export const FORCE_COMPRESS_QUALITY_FACTOR = 0.7;
export const DEFAULT_IMAGE_SIZE_LIMIT = 1;

const CompressedImagePicker = ({
  onSave,
  style = {},
  triggerImageDialog = false /* trigger image dialog externally */,
  fileSizeLimit = DEFAULT_IMAGE_SIZE_LIMIT /* In MB's */,
  allowMultipleUpload = false,
  onClose = () => {},
  onCancel = () => {}
}) => {
  /* *************** IMAGE PICKER UTILS *********************** */
  const inputOpenFileRef: any = useRef();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (triggerImageDialog) {
      initializeImageSelect();
    }
  }, [triggerImageDialog]);

  const onCloseImageSelect = (e?: any) => {
    if (e?.target) {
      e.target.value = "";
    }

    setIsOpen(false);
    onClose?.();
  };

  const onClickOutside = () => {
    if (Utility.isEmptyObject(inputOpenFileRef.current?.files)) {
      onCloseImageSelect();
    }

    document.body.onfocus = null;
  };

  const initializeImageSelect = (
    isInternalClick?: boolean,
    forceOpen?: boolean
  ) => {
    if (
      Utility.isEmptyObject(inputOpenFileRef?.current) ||
      (isOpen && !forceOpen)
    )
      return;

    setIsOpen(true);
    !isInternalClick && inputOpenFileRef.current.click();
    document.body.onfocus = onClickOutside;
  };

  /* *************** IMAGE UPLOAD UTILS *********************** */
  const getCompressedImage = (
    imageData: any,
    qualityFactor = DEFAULT_QUALITY_FACTOR,
    isForceCompress = false
  ) => {
    if (!imageData) return;

    import("compressorjs").then((Compressor) => {
      new Compressor.default(imageData, {
        quality: qualityFactor,
        ...(isForceCompress ? { maxHeight: 900, maxWidth: 1600 } : {}),
        success: (compressedResult) => {
          if (isImageSizeExceeded(compressedResult)) {
            return;
          } else {
            uploadImageToAWS(compressedResult);
          }
        },
        error: (err) => {
          isForceCompress &&
            showToast("Image optimization failed, please try again!");
          if (!isImageSizeExceeded(imageData)) {
            uploadImageToAWS(imageData);
          }
        }
      });
    });
  };

  const isImageSizeExceeded = (imageData) => {
    const fileSize = imageData?.size ? imageData.size / 1024 / 1024 : 0;

    if (fileSize < fileSizeLimit) return false;

    const actionButtons = [
      {
        title: "Optimize & upload",
        className: "bg-button text-white",
        onClick: () =>
          getCompressedImage(imageData, FORCE_COMPRESS_QUALITY_FACTOR, true)
      },
      {
        title: "Choose another",
        className: "bg-gray1",
        onClick: () => {
          onCloseImageSelect();
          initializeImageSelect(false, true);
        }
      }
    ];

    showAlert(
      "File size exceeded!",
      "Looks like image size is more than 1 MB. Please optimize or choose a different file.",
      actionButtons
    );

    return true;
  };

  const uploadImageToAWS = (imageData) => {
    ApiManager.getUploadFileURL(
      imageData,
      (url: string) => onSave(url),
      (err: any) => {
        showToast("Image upload failed", TOAST_TYPE.FAILURE);
        onCancel?.();
      }
    );
  };

  /* *************** RENDERER *********************** */
  return (
    <input
      type="file"
      accept="image/*"
      ref={inputOpenFileRef}
      multiple={allowMultipleUpload}
      style={{ ...style }}
      onClick={(e) => {
        e.stopPropagation();
        initializeImageSelect(true, false);
      }}
      onChange={(e) => {
        if (allowMultipleUpload && e.target.files) {
          for (let i = 0; i < e.target.files.length; i++) {
            getCompressedImage(e.target.files[i]);
          }
        } else {
          getCompressedImage(e.target.files?.[0] || null);
        }
        onCloseImageSelect(e);
      }}
    />
  );
};

export default CompressedImagePicker;
