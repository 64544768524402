import React, { Component } from "react";
import { DKLabel } from "deskera-ui-library";
import DKLabel2 from "./DKLabel2";

export default class TextBanner extends Component {
  render() {
    return (
      <div className="row column parent-width padding-2 ">
        <div
          className="parent-width text-banner-wrapper"
          style={{
            maxWidth: " 56rem",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ flexDirection: "column" }}
          >
            <DKLabel2
              className="eyebrow-label fs-l fw-b"
              style={{ letterSpacing: ".025em" }}
              text={this.props.eyebrow}
            />
            <DKLabel2
              className="text-banner-title mt-m"
              style={{ letterSpacing: ".01em", fontWeight: 800 }}
              text={this.props.title}
            />
            <DKLabel2
              className="text-gray mt-l fs-xl text-banner-subtitle"
              style={{ lineHeight: "1.75rem", maxWidth: "36rem" }}
              text={this.props.subTitle}
            />
          </div>
        </div>
      </div>
    );
  }
}
