import React, { Component } from "react";
import { DKLabel, DKButton } from "deskera-ui-library";

/*
- headScript
- bodyScript
- onSave
- onCancel
*/

class ScriptEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headScript: this.props.headScript ? this.props.headScript : "",
      bodyScript: this.props.bodyScript ? this.props.bodyScript : ""
    };
  }

  render() {
    return (
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{ maxWidth: 640, padding: 20, paddingBottom: 30 }}
        >
          {this.getHeader()}
          <DKLabel
            text="Paste your script to add it to <b>'head'</b> of the web page"
            className=" mt-xl"
          />
          {this.getHeadPart()}

          <DKLabel
            text="Additionally, paste your script to add it to <b>'body'</b> of the web page"
            className=" mt-xxl"
          />
          {this.getBodyPart()}
        </div>
      </div>
    );
  }

  getHeader() {
    return (
      <div className="row justify-content-between">
        <DKLabel text="< > Integrate scripts" className="fw-m fs-l" />
        <div className="row" style={{ width: "auto" }}>
          <DKButton
            title="Cancel"
            className="border-m bg-gray1"
            onClick={() => {
              this.props.onCancel();
            }}
          />
          <DKButton
            title="Save"
            className="bg-button text-white ml-r"
            onClick={() => {
              this.props.onSave({
                head: this.state.headScript,
                body: this.state.bodyScript
              });
            }}
          />
        </div>
      </div>
    );
  }

  getHeadPart() {
    return (
      <div
        className="parent-width  border-m border-radius-s bg-gray1 p-r border-box mt-s"
        style={{ height: 145 }}
      >
        <textarea
          style={{ outline: "none", resize: "none" }}
          className=" app-font text-gray border-none parent-width parent-height bg-transparent"
          placeholder='<script async="" src="...."></script>'
          spellCheck={false}
          onChange={(e) => {
            this.setState({
              headScript: e.target.value
            });
          }}
          value={this.state.headScript}
        />
      </div>
    );
  }
  getBodyPart() {
    return (
      <div
        className="parent-width  border-m border-radius-s bg-gray1 p-r border-box mt-s"
        style={{ height: 145 }}
      >
        <textarea
          style={{ outline: "none", resize: "none" }}
          className=" app-font text-gray border-none parent-width parent-height bg-transparent"
          placeholder='<script async="" src="...."></script>'
          spellCheck={false}
          onChange={(e) => {
            this.setState({
              bodyScript: e.target.value
            });
          }}
          value={this.state.bodyScript}
        />
      </div>
    );
  }
}

export default ScriptEditor;
