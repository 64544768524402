import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DealService } from "../../services/deal";

export interface DealState {
  dealStageChangeApprovalFlows: any;
  approvalTriggers: any;
}
const initialState: DealState = {
  dealStageChangeApprovalFlows: [],
  approvalTriggers: []
};
export const fetchDealStageChangeApprovalFlows = createAsyncThunk(
  "deal/fetchDealStageChangeApprovalFlows",
  async (data: any, thunkAPI) => {
    const response: any = await DealService.fetchDealStageChangeApprovalFlows();
    return response;
  }
);
export const fetchApprovalTriggers = createAsyncThunk(
  "deal/fetchApprovalTriggers",
  async (data: any, thunkAPI) => {
    const response: any = await DealService.fetchApprovalTriggers(data.params);
    return response;
  }
);

export const dealSlice = createSlice({
  name: "records",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchDealStageChangeApprovalFlows.fulfilled,
      (state, action) => {
        state.dealStageChangeApprovalFlows = action.payload;
      }
    );
    builder.addCase(fetchApprovalTriggers.fulfilled, (state, action) => {
      state.approvalTriggers = action.payload;
    });
  }
});
export const selectDealApprovalTriggers = (state) =>
  state.deal.approvalTriggers;

export default dealSlice.reducer;
