import { Component, Fragment } from "react";
import { connect } from "react-redux";
import "./menu.scss";
import {
  showToast,
  TOAST_TYPE,
  DKSidebar,
  DKIcons,
  showAlert
} from "deskera-ui-library";
import { TABLES, TABLE_DISPLAY_NAME } from "../../managers/TableManger";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import {
  isMobileAppWebView,
  isViewportLarge
} from "../../utility/GetViewportSize";

import IAM from "../../services/iam";
import SideBarService from "../../services/sidebar";
import UserManager from "../../managers/UserManager";
import AppManager from "../../managers/AppManager";

import ic_dashboard from "../../assets/menu/filled/ic_dashboard.png";
import ic_contacts from "../../assets/menu/filled/ic_contact.png";
import ic_list from "../../assets/menu/filled/ic_list.png";
import ic_deals from "../../assets/menu/filled/ic_deal.png";
import ic_campaign from "../../assets/menu/filled/ic_campaign.png";
import ic_invoice from "../../assets/menu/filled/ic_invoice.svg";
/* import ic_funnel from "../../assets/menu/filled/ic_funnel.png"; */
import ic_landing_page from "../../assets/menu/filled/ic_landing.png";
import ic_forms from "../../assets/menu/filled/ic_form.png";
import ic_automation from "../../assets/menu/filled/ic_automation.png";
import ic_activity from "../../assets/menu/filled/ic_activities.png";
import ic_chat from "../../assets/menu/filled/ic_chat.png";
import ic_order from "../../assets/menu/filled/ic_order.png";
import ic_support from "../../assets/menu/filled/Support-Ticket-White-01.svg";
import ic_quote from "../../assets/menu/filled/ic_quote.svg";
import ic_accounts from "../../assets/menu/filled/accounts.png";
import ic_sales from "../../assets/menu/filled/sales.png";
import ic_marketing from "../../assets/menu/filled/marketing.png";
import ic_customer_service from "../../assets/menu/filled/customer-service.png";
import ic_ecommerce from "../../assets/menu/filled/ecommerce.png";
import ic_reports from "../../assets/menu/filled/reports.png";
import ic_settings from "../../assets/menu/filled/setting.png";
import ic_products from "../../assets/menu/filled/ic_product.svg";
import Popup from "../../components/common/Popup";
import AddNewTenant from "../../components/common/AddNewTenant";
import BooksService from "../../services/books";
import Utility from "../../utility/Utility";
import { EVENT_TYPE } from "../../eventTracker/utility/EventConstants";
import EventManager from "../../eventTracker/EventManager";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import PermissionService from "../../services/common/permission";
import { setSettingPopup } from "../../redux/slices/userPrefSlice";
import { store } from "../../redux/store";

class LeftMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuExpanded: SideBarService.isSideBarExpanded,
      showAddTenantPopup: false,
      selectedTenantDetails: {
        tenantName: UserManager.getUserTenantName(),
        tenantId: UserManager.getUserTenantID()
      }
    };
  }

  componentDidMount() {
    window.addEventListener("onSideBarChange", this.handleSidebarToggle);
    window.addEventListener("resize", this.handleSidebarOnResize);
  }

  componentWillUnmount() {
    window.removeEventListener("onSideBarChange", this.handleSidebarToggle);
    window.removeEventListener("resize", this.handleSidebarOnResize);
  }

  handleSidebarOnResize() {
    SideBarService.setIsSideBarExpanded();
    SideBarService.triggerSidebarChangeEvent();
  }

  handleSidebarToggle = () => {
    this.setState({ isMenuExpanded: SideBarService.isSideBarExpanded });
  };

  onSelectTenant = (newSelectedTenantDetails) => {
    if (
      newSelectedTenantDetails.tenantId !==
      this.state.selectedTenantDetails.tenantId
    ) {
      IAM.switchTenant({ tenantId: newSelectedTenantDetails.tenantId }).then(
        (res) => {
          AppManager.reloadApp();
        },
        (err) => {}
      );
    } else {
      showToast("Current organisation", TOAST_TYPE.SUCCESS);
    }
  };

  render() {
    return !AppManager.isMobileAppURL() ? this.renderMenu() : null;
  }

  renderMenu() {
    const menuItemList = this.getFilteredMenuItems(this.getMenuItems());
    return (
      <Fragment>
        <div
          className={`transparent-background parent-height parent-width position-absolute ${
            this.state.isMenuExpanded
              ? "display-only-mobile z-index-5"
              : "display-none z-index-0"
          }`}
          onClick={() => SideBarService.toggleSideBar()}
        ></div>
        <DKSidebar
          className={`z-index-6 ${
            isMobileAppWebView() ? "mobile-app-theme-color" : ""
          }`}
          allowTenantSelection={!isMobileAppWebView()}
          collapsedWidth={0}
          expandedWidth={230}
          menuItemList={menuItemList}
          tenantList={this.props.tenants}
          isMenuExpanded={this.state.isMenuExpanded}
          selectedTenantDetails={this.state.selectedTenantDetails}
          onAddNewTenantTapped={(data) =>
            this.setState({ showAddTenantPopup: true })
          }
          onTenantSelect={(newTenantDetails) =>
            this.onSelectTenant(newTenantDetails)
          }
          onExpandCollapse={() => SideBarService.toggleSideBar()}
        />
        {this.state.showAddTenantPopup && (
          <Popup popupWindowStyles={{ overflow: "visible" }}>
            <AddNewTenant
              onClose={() => this.setState({ showAddTenantPopup: false })}
              onSave={(data) => this.setState({ showAddTenantPopup: false })}
            />
          </Popup>
        )}
      </Fragment>
    );
  }

  performTrackingEvent = (url) => {
    let tableName = "";
    const pathname = window.location.pathname;
    if (url === pathname) {
      return;
    }
    switch (url.split("/").pop()) {
      case "accounts":
        tableName = TABLES.ACCOUNT;
        break;
      case "contacts":
        tableName = TABLES.CONTACT;
        break;
      case "deals":
        tableName = TABLES.DEAL;
        break;
      case "activity":
        tableName = TABLES.ACTIVITY;
        break;
      case "quotes":
        tableName = TABLES.BOOKS_QUOTE;
        break;
      case "invoices":
        tableName = TABLES.BOOKS_INVOICE;
        break;
      case "forms":
        tableName = TABLES.FORM;
        break;
      case "campaign":
        tableName = TABLES.CAMPAIGN;
        break;
      case "landing-pages":
        tableName = TABLES.LANDING_PAGE;
        break;
      case "support-ticket":
        tableName = TABLES.TICKET;
        break;
      case "chat":
        tableName = TABLES.CHAT_MESSAGE;
        break;
      case "orders":
        tableName = TABLES.ORDER;
        break;
      case "automation":
        tableName = TABLES.AUTOMATION;
        break;
      case "reports":
        tableName = TABLES.REPORTS;
        break;
      default:
        break;
    }
    if (!Utility.isEmptyObject(tableName)) {
      EventManager.trackEventByModule(tableName, EVENT_TYPE.VISITED, {});
    }
  };

  onMenuItemClick = (route) => {
    this.performTrackingEvent(route);
    if (this.isPermittedToNavigate(route)) {
      if (route === PAGE_ROUTES.SETTINGS) {
        this.props.setSettingPopup(true);
      } else {
        RouteManager.navigateToPage(route);
        if (!isViewportLarge()) {
          SideBarService.toggleSideBar();
        }
      }
    } else {
      showAlert(
        "No Permissions!",
        "You don't have permission to view this module, please contact owner/admin of this organisation"
      );
    }
  };

  isPermittedToNavigate = (route) => {
    let isPermitted = true;
    // if (route === PAGE_ROUTES.INVOICES) {
    //   isPermitted = PermissionService.getInstance().isUserPermitted("INVOICE", [
    //     USER_ACTION_TYPES.REC_VIEW
    //   ]);
    // }
    // if (route === PAGE_ROUTES.SUPPORT_TICKET) {
    //   isPermitted = PermissionService.getInstance().isUserPermitted("TICKET", [
    //     USER_ACTION_TYPES.REC_VIEW
    //   ]);
    // }
    return isPermitted;
  };

  getFilteredMenuItems = (menuItems) => {
    if (Utility.isEmptyObject(menuItems)) return menuItems;

    return menuItems
      .filter((item) => item.visible)
      .map((item) => ({
        ...item,
        subItems: this.getFilteredMenuItems(item.subItems)
      }));
  };

  getMenuItems = () => {
    return [
      {
        name: "Dashboard",
        icon: ic_dashboard,
        route: PAGE_ROUTES.DASHBOARD,
        onClick: this.onMenuItemClick,
        visible: !isMobileAppWebView()
      },
      {
        name: "Sales",
        icon: ic_sales,
        className: "left-menu-group",
        visible: true,
        expandByDefault: true,
        subItems: [
          {
            name: "Accounts",
            icon: ic_accounts,
            route: PAGE_ROUTES.ACCOUNTS,
            onClick: this.onMenuItemClick,
            visible: !isMobileAppWebView()
          },
          {
            name: TABLE_DISPLAY_NAME[TABLES.CONTACT],
            icon: ic_contacts,
            route: PAGE_ROUTES.CONTACTS,
            onClick: this.onMenuItemClick,
            visible: true
          },
          {
            name: "Segments / Lists",
            icon: ic_list,
            route: PAGE_ROUTES.LIST,
            onClick: this.onMenuItemClick,
            visible: true
          },
          {
            name: TABLE_DISPLAY_NAME[TABLES.DEAL],
            icon: ic_deals,
            route: PAGE_ROUTES.DEALS,
            onClick: this.onMenuItemClick,
            visible: true
          },
          {
            name: TABLE_DISPLAY_NAME[TABLES.ACTIVITY],
            icon: ic_activity,
            route: PAGE_ROUTES.ACTIVITY,
            onClick: this.onMenuItemClick,
            visible: true
          },
          {
            name:
              BooksService.getTenantCountry() === "US" ? "Estimates" : "Quotes",
            icon: ic_quote,
            route: PAGE_ROUTES.QUOTES,
            onClick: this.onMenuItemClick,
            visible: !isMobileAppWebView()
          },
          {
            name: "Invoices",
            icon: ic_invoice,
            route: PAGE_ROUTES.INVOICES,
            onClick: this.onMenuItemClick,
            visible: !isMobileAppWebView()
          },
          {
            name: "Price Book",
            icon: ic_list,
            route: PAGE_ROUTES.PRICE_BOOK,
            onClick: this.onMenuItemClick,
            visible: !isMobileAppWebView()
          },
          {
            name: "Products",
            icon: ic_products,
            route: PAGE_ROUTES.PRODUCTS,
            onClick: this.onMenuItemClick,
            visible: !isMobileAppWebView()
          }
        ]
      },
      {
        name: "Marketing",
        className: "left-menu-group",
        icon: ic_marketing,
        visible: !isMobileAppWebView(),
        subItems: [
          {
            name: TABLE_DISPLAY_NAME[TABLES.FORM],
            icon: ic_forms,
            route: PAGE_ROUTES.FORMS,
            onClick: this.onMenuItemClick,
            visible: !isMobileAppWebView()
          },
          {
            name: TABLE_DISPLAY_NAME[TABLES.CAMPAIGN],
            icon: ic_campaign,
            route: PAGE_ROUTES.CAMPAIGN,
            onClick: this.onMenuItemClick,
            visible: !isMobileAppWebView()
          },
          {
            name: TABLE_DISPLAY_NAME[TABLES.LANDING_PAGE],
            icon: ic_landing_page,
            route: PAGE_ROUTES.LANDING_PAGE,
            onClick: this.onMenuItemClick,
            visible: !isMobileAppWebView()
          }
        ]
      },
      {
        name: "Customer Service",
        className: "left-menu-group",
        icon: ic_customer_service,
        visible: !isMobileAppWebView(),
        subItems: [
          {
            name: "Tickets",
            icon: ic_support,
            route: PAGE_ROUTES.SUPPORT_TICKET,
            onClick: this.onMenuItemClick,
            visible: !isMobileAppWebView(),
            className: ""
          },
          {
            name: "Chat",
            icon: ic_chat,
            route: PAGE_ROUTES.CHAT,
            onClick: this.onMenuItemClick,
            visible: !isMobileAppWebView(),
            className: ""
          }
        ]
      },
      {
        name: "E-Commerce",
        className: "left-menu-group",
        icon: ic_ecommerce,
        visible: !isMobileAppWebView(),
        subItems: [
          /*
           {
              name: TABLE_DISPLAY_NAME[TABLES.FUNNEL],
              icon: ic_funnel,
              route: PAGE_ROUTES.FUNNEL,
              onClick: this.onMenuItemClick,
              visible: !isMobileAppWebView(),
            },
          */
          {
            name: "Shop",
            className: "left-menu-group",
            icon: ic_landing_page,
            visible: false,
            // !isMobileAppWebView() &&
            // !["production"].includes(process.env.REACT_APP_ENV),
            subItems: Utility.isEmptyObject(this.props.shopDetails?.data)
              ? [
                  {
                    name: "Become a Seller",
                    icon: DKIcons.ic_blank,
                    route: PAGE_ROUTES.NEW_SHOP,
                    onClick: this.onMenuItemClick,
                    visible: !isMobileAppWebView()
                  }
                ]
              : [
                  {
                    name: "Shop Dashboard",
                    icon: DKIcons.ic_blank,
                    route: PAGE_ROUTES.SHOP_DASHBOARD,
                    onClick: this.onMenuItemClick,
                    visible: !isMobileAppWebView()
                  },
                  {
                    name: "Leads",
                    icon: DKIcons.ic_blank,
                    route: PAGE_ROUTES.SHOP_LEADS,
                    onClick: this.onMenuItemClick,
                    visible: !isMobileAppWebView()
                  }
                ]
          },
          {
            name: "My Orders",
            icon: ic_order,
            route: PAGE_ROUTES.ORDER,
            onClick: this.onMenuItemClick,
            className: "mt-auto",
            visible: !isMobileAppWebView()
          }
        ]
      },
      {
        name: TABLE_DISPLAY_NAME[TABLES.AUTOMATION],
        icon: ic_automation,
        route: PAGE_ROUTES.AUTOMATION,
        onClick: this.onMenuItemClick,
        visible: !isMobileAppWebView()
      },
      {
        name: "Reports",
        icon: ic_reports,
        route: PAGE_ROUTES.REPORTS,
        onClick: this.onMenuItemClick,
        visible: !isMobileAppWebView()
      },
      {
        name: "Logs",
        icon: ic_list,
        route: PAGE_ROUTES.LOGS,
        onClick: this.onMenuItemClick,
        visible: !isMobileAppWebView()
      },
      {
        name: "Settings",
        icon: ic_settings,
        route: PAGE_ROUTES.SETTINGS,
        onClick: this.onMenuItemClick,
        visible: !isMobileAppWebView()
      }
    ];
  };
}

const mapStateToProps = (state) => ({
  tenants: state.tenant?.tenants || [],
  shopDetails: state.shop.shopDetails
});
const mapDispatchToProps = { setSettingPopup };
export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
