import React, { Suspense, useEffect, useState } from "react";
import {
  DKDataGrid,
  DKSpinner,
  DKIcons,
  DKIcon,
  DKLabel,
  DKSearchBar,
  DKListPicker2,
  DKButton,
  showAlert,
  showToast,
  TOAST_TYPE,
  DKDateRangePicker
} from "deskera-ui-library";
import RouteManager from "../managers/RouteManager";
import {
  COLUMN_CODE,
  TableManger,
  TABLES,
  TABLE_DISPLAY_NAME
} from "../managers/TableManger";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  fetchAutomationLogs,
  recordsByTableNameSelector
} from "../redux/slices/recordSlice";
import Utility, { getCapitalized, toCurrencyFormat } from "../utility/Utility";
import { DEFAULT_PAGE_SIZE } from "../services/importLog";
import SideBarService from "../services/sidebar";
import {
  AUTOMATION_ACTIONS_TITLE,
  AUTOMATION_LOG_NODE_TYPE,
  AUTOMATION_TRIGGER_TITLE,
  DATE_RNGE_FILTER_OPTION
} from "../constants/Enum";
import ic_no_data from "../../src/assets/icons/ic_no_data_3.png";
import { useDebounce } from "../utility/Debounce";
import { IColumn } from "../model/Table";
import ApiConstants from "../constants/ApiConstants";
import AutomationLog from "../services/automationLog";
import { supportedFileTypes } from "../components/import_export/EximConstants";
import { triggerDownload } from "../components/import_export/utility/ExportData";
import IUser, { getFullName } from "../model/User";
import {
  ACTIVITY_DATE_FORMAT,
  AUTOMATION_STATUS,
  LOG_SCROLL_CONTAINER_ID
} from "../constants/Constant";
import {
  convertCRMDateFormatToUILibraryFormat,
  DateUtil
} from "../utility/Date";
import BooksService from "../services/books";
import { isMobileAppWebView } from "../utility/GetViewportSize";
import AppManager from "../managers/AppManager";
import { CURRENCY_SYMBOLS } from "../constants/Currencies";

const AUTOMATION_LOG_STATUS = [
  { id: "Success", name: "Success", color: "data-grid-badge-color-6" },
  { id: "Failed", name: "Failed", color: "data-grid-badge-color-10" },
  { id: "InProgress", name: "In Progress", color: "data-grid-badge-color-5" }
];
const AUTOMATION_LOG_COLUMNS: IColumn[] = [
  {
    name: "Automation",
    id: "automationName",
    type: "text",
    index: 0,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "automationName",
    key: "automationName"
  },
  {
    name: "Trigger Type",
    id: "NWLS",
    type: "text",
    index: 1,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "triggerNodeType",
    key: "triggerNodeType"
  },
  {
    name: "Action Type",
    id: "JAJc",
    type: "text",
    index: 2,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "executedNodeType",
    key: "executedNodeType"
  },
  {
    name: "Log",
    id: "JAJc",
    type: "text",
    index: 3,
    options: null,
    required: false,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "log",
    key: "log"
  },
  {
    name: "Executed At",
    id: "JAJc",
    type: "date",
    index: 4,
    options: null,
    required: false,
    width: 200,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "executedAt",
    key: "executedAt"
  },
  {
    name: "Status",
    type: "select",
    index: 5,
    options: AUTOMATION_LOG_STATUS,
    required: false,
    width: 100,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "status",
    id: "status"
  },
  {
    name: "Reason",
    type: "text",
    index: 6,
    options: null,
    required: false,
    width: 300,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "reason",
    id: "reason"
  }
];
export default function AutomationLogs(props) {
  /*
   state and other declarations goes here
   */
  const isReport = Utility.getQueryParam(props.location, "report");
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const automationLogData = useAppSelector(
    recordsByTableNameSelector(TABLES.AUTOMATION_LOG)
  );
  const [columns, setColumns] = React.useState([]);
  const [selectedDayFilter, setSelectedDayFilter] = useState(
    DATE_RNGE_FILTER_OPTION.THIS_WEEK
  );
  const [showAllAutomations, setShowAllAutomations] = useState(false);
  const [showAllActions, setShowAllActions] = useState(false);
  const [showAllStatus, setShowAllStatus] = useState(false);
  const financialDates = BooksService.getFinancialStartEndDate();
  const [filter, setFilter] = useState({
    statusData: null,
    automation: null,
    actionData: null,
    fromDate: financialDates?.financialStartDate,
    toDate: financialDates?.financialEndDate,
    ...getFiltersFromParams()
  });
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(null);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const debouncedSearchTerm = useDebounce(searchText, 300);
  const users: IUser[] = useAppSelector((state) => state.tenant.users);
  const dateFormat =
    `EEE, ${convertCRMDateFormatToUILibraryFormat(DateUtil.getOrgDateFormat())}, HH:mm aa` ||
    ACTIVITY_DATE_FORMAT;
  const [filterDates, setFilterDates] = useState<any>(
    BooksService.getFinancialStartEndDate()
  );
  const [showHideCalendar, setShowHideCalendar] = useState(false);

  /*
   all the effect goes here
   */

  useEffect(() => {
    if (!props?.view) {
      RouteManager.setPresenter({ props });
    }
    getColumnConfig();
  }, []);
  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      getAutomationLogs(1);
    }
  }, [debouncedSearchTerm, filter, selectedDayFilter]);
  useEffect(() => {
    getAutomationLogs(page);
  }, [page]);
  useEffect(() => {
    getColumnConfig();
  }, [users]);
  /*
  business logic goes here
  */
  function getFiltersFromParams() {
    const automationId = Utility.getQueryParam(props.location, "automationId");
    const automationName = Utility.getQueryParam(
      props.location,
      "automationName"
    );

    const paramFilters = {};
    if (automationId && automationName) {
      paramFilters["automation"] = { id: automationId, name: automationName };
    }

    return paramFilters;
  }

  const getFilterParams = () => {
    const filterParams = {};

    if (filter.statusData?.id) {
      filterParams["status"] = filter.statusData.id;
    }

    if (filter.automation?.id) {
      filterParams["automationId"] = filter.automation.id;
    }

    if (filter.actionData?.id) {
      filterParams["nodeType"] = filter.actionData.id;
    }

    if (filter.fromDate) {
      filterParams["fromDate"] = filter.fromDate;
    }
    if (filter.toDate) {
      filterParams["toDate"] = filter.toDate;
    }

    return filterParams;
  };

  const getAutomationLogs = (page) => {
    setLoading(true);
    dispatch(
      fetchAutomationLogs({
        params: {
          pageSize: DEFAULT_PAGE_SIZE,
          pageNo: page,
          search: debouncedSearchTerm,
          ...getFilterParams()
        }
      })
    ).finally(() => {
      setLoading(false);
    });
  };
  const getRowData = () => {
    let rowData: any[] = [];
    automationLogData?.data.forEach((data, index) => {
      rowData.push({
        ...data,
        reason: data.reason || "-",
        status: [data.status || "Success"]
      });
    });
    return rowData;
  };
  const onSearch = (searchText) => {
    setSearchText(searchText);
  };
  const getColumnConfig = () => {
    let columns = Utility.deepCloneObject(AUTOMATION_LOG_COLUMNS);

    columns = columns.map((column) => {
      column.key = column.columnCode;
      if (column.columnCode === COLUMN_CODE.AUTOMATION_LOGS.LOG) {
        column.renderer = logRenderer;
      }
      if (column.columnCode === COLUMN_CODE.AUTOMATION_LOGS.TRIGGER_TYPE) {
        column.renderer = triggerTypeRenderer;
      }
      if (column.columnCode === COLUMN_CODE.AUTOMATION_LOGS.ACTION_TYPE) {
        column.renderer = actionTypeRenderer;
      }
      return column;
    });
    setColumns(columns);
  };
  const onPageChange = (page) => {
    setLoading(true);
    setPage(page);
  };
  const getRecordData = (rowData) => {
    let objectType = rowData?.metaData?.objectType?.toLowerCase();
    let name = "";
    let ownerId = null,
      owner = null;
    if (rowData?.metaData?.dealRecord) {
      name =
        rowData.metaData.dealRecord.cells[
          TableManger.getColumnId(TABLES.DEAL, COLUMN_CODE.DEAL.NAME)
        ];
      ownerId =
        rowData.metaData.dealRecord.cells[
          TableManger.getColumnId(TABLES.DEAL, COLUMN_CODE.DEAL.OWNER_ID)
        ]?.[0];
      objectType = TABLES.DEAL;
    }
    if (
      rowData?.metaData?.contactRecord &&
      !["BOOKS PLUS APPROVAL", "QUOTATION"].includes(
        rowData?.metaData?.objectType
      )
    ) {
      name =
        rowData.metaData.contactRecord.cells[
          TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.NAME)
        ];
      ownerId =
        rowData.metaData.contactRecord.cells[
          TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.OWNER_ID)
        ]?.[0];
      objectType = TABLES.CONTACT;
    }
    if (rowData?.metaData?.activityRecord) {
      name =
        rowData.metaData.activityRecord.cells[
          TableManger.getColumnId(TABLES.ACTIVITY, COLUMN_CODE.ACTIVITY.NAME)
        ];
      ownerId =
        rowData.metaData.activityRecord.cells[
          TableManger.getColumnId(
            TABLES.ACTIVITY,
            COLUMN_CODE.ACTIVITY.OWNER_ID
          )
        ]?.[0];
      objectType = TABLES.ACTIVITY;
    }

    const user = users.find((user) => user.iamUserId === ownerId);
    owner = Utility.isEmptyObject(user) ? "" : getFullName(user);

    return {
      name,
      objectType,
      owner,
      evalResult: rowData?.metaData?.evalResult
    };
  };
  const getNoDataView = () => {
    return (
      <div
        className="column align-self-center align-items-center position-absolute"
        style={{ top: "30%", pointerEvents: "none" }}
      >
        <DKIcon
          src={ic_no_data}
          className="ic-l"
          style={{ opacity: 0.2, marginTop: 70 }}
        />
        <DKLabel text="No data found" className="fw-m mt-l" />
        <DKLabel
          text="Once data is available, it will appear here"
          className="text-gray mt-s "
        />
      </div>
    );
  };
  function convertMinutesToDHM(minutes: number) {
    minutes = Number(minutes);
    if (!minutes) return "0m";

    const days = Math.floor(minutes / (24 * 60));
    minutes = minutes % (24 * 60);
    const hours = Math.floor(minutes / 60);
    const remMinutes = minutes % 60;
    return (
      `${days ? `${days}d ` : ""}${hours ? `${hours}h ` : ""}${remMinutes ? `${remMinutes}m` : ""}` ||
      "0m"
    );
  }
  /*
     sub-renderer goes here
     */

  const quotationRender = (rowData) => {
    if (
      rowData.approvalStatus === "REJECTED" ||
      (rowData.reason === "Approval is rejected" &&
        rowData.status?.[0] === "Failed")
    ) {
      return AUTOMATION_TRIGGER_TITLE.BOOKS_REJECT_QUOTATION;
    }
    if (rowData?.metaData?.opName === "u") {
      return AUTOMATION_TRIGGER_TITLE.BOOKS_UPDATE_QUOTATION;
    }
    return AUTOMATION_TRIGGER_TITLE[rowData.triggerNodeType] ?? "-";
  };
  const actionTypeRenderer = ({ rowData }) => {
    let actionType = AUTOMATION_ACTIONS_TITLE[rowData.executedNodeType];
    switch (rowData.executedNodeType) {
      case AUTOMATION_LOG_NODE_TYPE.BOOKS_CREATE_QUOTATION:
        actionType = quotationRender(rowData);
        break;
      case AUTOMATION_LOG_NODE_TYPE.DEAL_UPDATE_APPROVAL:
        actionType = AUTOMATION_TRIGGER_TITLE.CRM_DEAL_APPROVAL;
        break;
      default:
        break;
    }
    return <div>&nbsp;{actionType || "-"}</div>;
  };
  const triggerTypeRenderer = ({ rowData }) => {
    return (
      <div>
        &nbsp;{AUTOMATION_TRIGGER_TITLE[rowData.triggerNodeType] || "-"}
      </div>
    );
  };
  const logRenderer = ({ rowData }) => {
    try {
      const { name, objectType, owner, evalResult } = getRecordData(rowData);
      switch (rowData.executedNodeType) {
        case AUTOMATION_LOG_NODE_TYPE.CREATE_DEAL:
          return (
            <div className="text-align-left">
              Created {objectType}
              <strong>
                &nbsp;
                {name}
              </strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.CREATE_CONTACT:
          return (
            <div className="text-align-left">
              Created {objectType}
              <strong>
                &nbsp;
                {name}
              </strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.UPDATE_DEAL:
          return (
            <div className="text-align-left">
              Updated {objectType}
              <strong>
                &nbsp;
                {name}
              </strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.UPDATE_CONTACT:
          return (
            <div className="text-align-left">
              Updated {objectType}
              <strong>
                &nbsp;
                {name}
              </strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.SEND_EMAIL:
          return (
            <div className="text-align-left">
              Sent email to {objectType}
              <strong>
                &nbsp;
                {name}
              </strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.SPLIT:
          return evalResult === null || evalResult === undefined ? (
            <div className="text-align-left">
              Split path conditions evaluated for {objectType}{" "}
              <strong>{name}</strong>
            </div>
          ) : (
            <div className="text-align-left">
              Split conditions for {objectType} <strong>{name}</strong>{" "}
              evaluated to <strong>{evalResult ? "true" : "false"}</strong>.
              Workflow proceeded to <strong>{evalResult ? "Yes" : "No"}</strong>{" "}
              path.
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.WAIT_FOR_TIME:
          return (
            <div className="text-align-left">
              Wait for
              <strong>
                &nbsp;
                {convertMinutesToDHM(rowData?.metaData?.delayInMin || 0)}
              </strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.SEND_WHATSAPP_MESSAGE:
          return (
            <div className="text-align-left">
              {rowData?.status?.toString() === "Success"
                ? `Whatsapp message sent for`
                : `Whatsapp message delivery failed for`}
              &nbsp;
              {objectType}
              <strong>
                &nbsp;
                {name}
              </strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.ASSIGN_OWNER:
          return (
            <div className="text-align-left">
              {getCapitalized(objectType)} <strong>{name}</strong> assigned to
              <strong>
                &nbsp;
                {owner}
              </strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.END_WORKFLOW:
          return <div className="text-align-left">Workflow ended</div>;
        case AUTOMATION_LOG_NODE_TYPE.CAMPAIGN_PUBLISHED:
          return (
            <div className="text-align-left">
              Campaign published workflow triggered by {objectType}{" "}
              <strong>{name}</strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.FORM_SUBMITTED:
          return (
            <div className="text-align-left">
              Form submitted workflow triggered by {objectType}{" "}
              <strong>{name}</strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.CONTACT_ADDED:
          return (
            <div className="text-align-left">
              Contact added workflow triggered by {objectType}{" "}
              <strong>{name}</strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.CONTACT_UPDATED:
          return (
            <div className="text-align-left">
              Contact field changed workflow triggered by {objectType}{" "}
              <strong>{name}</strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.DEAL_UPDATED:
          return (
            <div className="text-align-left">
              Deal field changed workflow triggered by {objectType}{" "}
              <strong>{name}</strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.DEAL_STAGE_CHANGED:
          return (
            <div className="text-align-left">
              Deal stage changed workflow triggered by {objectType}{" "}
              <strong>{name}</strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.ACTIVITY_UPDATED:
          return (
            <div className="text-align-left">
              Activity field changed workflow triggered by {objectType}{" "}
              <strong>{name}</strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.BOOKS_CREATE_QUOTATION:
          let action = "created";
          if (
            rowData.approvalStatus === "REJECTED" ||
            (rowData.reason === "Approval is rejected" &&
              rowData.status?.[0] === "Failed")
          ) {
            action = "rejected";
          }
          if (rowData?.metaData?.opName === "u") {
            action = "updated";
          }
          let totalAmountWithCurrency = Utility.isEmptyObject(
            CURRENCY_SYMBOLS[rowData?.metaData?.currency]
          )
            ? rowData?.metaData?.currency + " " + rowData?.metaData?.totalAmount
            : toCurrencyFormat(
                rowData?.metaData?.totalAmount,
                CURRENCY_SYMBOLS[rowData?.metaData?.currency]
              );
          return (
            <div className="text-align-left">
              Quotation has been {action} with amount{" "}
              <strong>{totalAmountWithCurrency}</strong> by User{" "}
              <strong>{rowData?.metaData?.userName ?? ""}</strong>.
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.CRM_DATE_ALERT_TRIGGER:
          return (
            <div className="text-align-left">
              Date alert workflow triggered by {objectType}{" "}
              <strong>{name}</strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.BOOKS_PLUS_APPROVAL:
          return (
            <div className="text-align-left">
              Approval triggered for Quotation.
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.DEAL_UPDATE_APPROVAL:
          const userName = !Utility.isEmptyObject(
            rowData?.metaData?.approvalReqestedByName
          )
            ? rowData?.metaData?.approvalReqestedByName
            : "-";
          const dealName =
            rowData.metaData?.dealRecord?.cells[
              TableManger.getColumnId(TABLES.DEAL, COLUMN_CODE.DEAL.NAME)
            ];

          let message = (
            <div className="text-align-left">
              {"Deal"} <strong>{dealName}</strong>{" "}
              {"has been sent for approval by user"}{" "}
              <strong>{userName}.</strong>
            </div>
          );
          if (rowData?.status?.[0] === AUTOMATION_STATUS.FAILED) {
            message = (
              <div className="text-align-left">
                {"Deal"} <strong>{dealName}</strong>{" "}
                {"was not sent for approval"}
              </div>
            );
          }
          return message;
        case AUTOMATION_LOG_NODE_TYPE.TICKET_CREATED:
          return (
            <div className="text-align-left">
              {"Created ticket "}
              <strong>{rowData?.metaData?.ticketRecord?.ticket_no}</strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.UPDATE_TICKET:
          return (
            <div className="text-align-left">
              Ticket updated
              <strong> {rowData?.metaData?.ticketRecord?.ticket_no}</strong>
            </div>
          );
        case AUTOMATION_LOG_NODE_TYPE.TICKET_UPDATED:
          return (
            <div className="text-align-left">
              Ticket field changed workflow triggered by {objectType}
              <strong> {rowData?.metaData?.ticketRecord?.ticket_no}</strong>
            </div>
          );
        default:
          return <div>-</div>;
      }
    } catch (err) {
      return <div>-</div>;
    }
  };
  const showAutomationsList = () => {
    let nameColumnID = TableManger.getColumnId(
      TABLES.AUTOMATION,
      COLUMN_CODE.AUTOMATION.NAME
    );
    const defaultAutomationFilter = [{ id: "", name: "All Automations " }];
    return (
      <DKListPicker2
        title="Select Automation"
        onSelect={(index, obj) => {
          setShowAllAutomations(false);
          let updateFilter = { ...filter };
          updateFilter.automation = obj;
          setFilter(updateFilter);
        }}
        data={[]}
        displayKey={"name"}
        allowSearch
        className="position-absolute z-index-3 shadow-m border-s"
        style={{ width: 200 }}
        searchApiConfig={{
          method: "POST",
          getUrl: (value) =>
            `${ApiConstants.URL.BASE}${ApiConstants.URL.TABLE.GET_RECORD_BY_PAGE(TableManger.getTableId(TABLES.AUTOMATION))}?q=${value}`,
          dataParser: (response) => {
            const automations = [...defaultAutomationFilter];
            response.data?.forEach((automationRecord) =>
              automations.push({
                id: automationRecord._id,
                name: automationRecord.cells[nameColumnID]
              })
            );
            return automations;
          },
          debounceTime: 300
        }}
        onClose={() => setShowAllAutomations(false)}
      />
    );
  };
  const showActionsList = () => {
    const allActions = [{ id: "", name: "All Actions " }];

    Object.entries(AUTOMATION_ACTIONS_TITLE)
      ?.sort()
      ?.forEach((entry) => allActions.push({ id: entry[0], name: entry[1] }));

    return (
      <DKListPicker2
        data={allActions}
        className="z-index-5"
        displayKey={"name"}
        allowSearch={true}
        searchableKey={"name"}
        style={{
          width: 150
        }}
        onSelect={(index, obj) => {
          setShowAllActions(false);
          let updateFilter = { ...filter };
          updateFilter.actionData = obj;
          setFilter(updateFilter);
        }}
        onClose={() => setShowAllActions(false)}
      />
    );
  };
  const showStatus = () => {
    return (
      <DKListPicker2
        data={[{ id: "", name: "All Status " }, ...AUTOMATION_LOG_STATUS]}
        displayKey="name"
        className="z-index-5"
        style={{ width: 100 }}
        onSelect={(index, obj) => {
          setShowAllStatus(false);
          let updateFilter = { ...filter };
          updateFilter.statusData = obj;
          setFilter(updateFilter);
        }}
        onClose={() => setShowAllStatus(false)}
      />
    );
  };
  const showExportOptionsList = () => {
    return (
      <DKListPicker2
        data={Object.keys(supportedFileTypes)}
        className="border-m z-10"
        style={{ width: 85 }}
        renderer={(index, value) => (
          <DKButton
            title={value}
            style={{ padding: 0 }}
            onClick={() => {}}
            icon={
              supportedFileTypes[value] === supportedFileTypes.CSV
                ? DKIcons.doc_type.ic_csv
                : DKIcons.doc_type.ic_xls
            }
          />
        )}
        onSelect={(index, value) => onSelectExportType(value)}
        onClose={() => setShowExportOptions(false)}
      />
    );
  };
  const onExportSuccess = (
    response: any,
    fileName: string,
    fileType: string
  ) => {
    if (response?.is_async) {
      showAlert(
        "Exporting records!",
        "Your data export is in progress. You will receive a download link for the same on registered email-id once completed."
      );
    } else if (response?.download_link) {
      triggerDownload(null, `${fileName}.${fileType}`, response.download_link);
    } else {
      showAlert(
        "Export records failed!",
        "Something went wrong while exporting your data, please try again"
      );
    }
  };

  const onSelectExportType = (fileType: string) => {
    setShowExportOptions(false);
    setIsExporting(true);
    AutomationLog.exportAutomationLogs({
      query: `appName=CRM3`,
      ...getFilterParams(),
      format: fileType,
      timezoneOffset: new Date().getTimezoneOffset() * -1
    })
      .then((res) => {
        onExportSuccess(
          res,
          `${TABLES.AUTOMATION_LOG}_${new Date().getTime()}`,
          supportedFileTypes[fileType]
        );
      })
      .catch((error) => {
        showToast(error, TOAST_TYPE.FAILURE);
      })
      .finally(() => {
        setIsExporting(false);
      });
  };
  const getHeaderWithActions = () => {
    return (
      <div
        className="row row-responsive parent-width justify-content-between"
        style={{
          gap: 8
        }}
      >
        <div className="row width-auto">
          <DKLabel
            text={`${isReport ? `Automation Report` : TABLE_DISPLAY_NAME[TABLES.AUTOMATION_LOG]} (${automationLogData?.totalCount || 0})`}
            className="fw-m fs-m"
          />
          {loading && <DKSpinner className={"ml-r"} />}
        </div>
        <div
          className="row row-responsive width-auto"
          style={{
            gap: 8
          }}
        >
          <div className="position-relative">
            <div
              className="bg-white border-m border-radius-m row width-auto cursor-hand p-h-m"
              style={{ height: 36 }}
              onClick={() => setShowAllAutomations(true)}
            >
              <DKLabel
                text={filter.automation?.name || `All Automations`}
                className="text-ellipsis"
                style={{ minWidth: 100, maxWidth: 200, WebkitLineClamp: 1 }}
              />
              <DKIcon src={DKIcons.ic_arrow_down2} className={"ic-s ml-s"} />
            </div>
            <div
              className="position-absolute z-index-5"
              style={{ top: 36, left: 0 }}
            >
              {showAllAutomations && showAutomationsList()}
            </div>
          </div>
          <div className="position-relative">
            <DKButton
              title={filter.actionData?.name || `All Actions`}
              className="border-m bg-white"
              icon={DKIcons.ic_arrow_down2}
              isReverse={true}
              onClick={() => setShowAllActions(true)}
            />
            <div
              className="position-absolute shadow-m z-index-5"
              style={{ top: 36, left: 0 }}
            >
              {showAllActions && showActionsList()}
            </div>
          </div>
          <div className="position-relative">
            <DKButton
              title={filter.statusData?.name || `All Status`}
              className="border-m bg-white"
              icon={DKIcons.ic_arrow_down2}
              isReverse={true}
              onClick={() => setShowAllStatus(true)}
            />
            <div
              className="position-absolute shadow-m z-index-5"
              style={{ top: 36, left: 0 }}
            >
              {showAllStatus && showStatus()}
            </div>
          </div>
          <div className="position-relative">
            <DKButton
              title={getDateRangeString()}
              className="border-m bg-white position-relative"
              icon={DKIcons.ic_calendar}
              onClick={() => setShowHideCalendar(true)}
            />
            <div
              className="position-absolute z-index-1 mobile-left-0"
              style={{ right: 630, top: 45 }}
            >
              {showHideCalendar && getDateRangePicker()}
            </div>
          </div>
          <div className="position-relative">
            <DKButton
              title={isExporting ? "Exporting..." : "Export"}
              className="border-m bg-white"
              icon={DKIcons.ic_arrow_down2}
              isReverse={true}
              onClick={() => setShowExportOptions(true)}
            />
            <div
              className="position-absolute z-index-5 shadow-m"
              style={{ top: 34, left: 0 }}
            >
              {showExportOptions && showExportOptionsList()}
            </div>
          </div>
          <DKSearchBar
            className="deal-kanban-search-bar"
            onSearch={onSearch}
            searchText={searchText}
          />
        </div>
      </div>
    );
  };
  const getDateRangeString = () => {
    if (!Utility.isEmptyObject(filterDates)) {
      return (
        DateUtil.getDateStrFromDate(
          filterDates.financialStartDate,
          DateUtil.getOrgDateFormat()
        ) +
        " to " +
        DateUtil.getDateStrFromDate(
          filterDates.financialEndDate,
          DateUtil.getOrgDateFormat()
        )
      );
    } else {
      return "";
    }
  };
  const getDateRangePicker = () => {
    return (
      <div className="position-absolute shadow-m bg-white z-10 top-12 right-20">
        <DKDateRangePicker
          className="border shadow "
          onClose={() => {
            setShowHideCalendar(false);
          }}
          color={"rgb(101,24,102)"}
          showPresetList={true}
          startDate={filterDates.financialStartDate}
          selectedStartDate={filterDates.financialStartDate}
          selectedEndDate={filterDates.financialEndDate}
          onSelectDateRange={(startDate: Date, endDate: Date) => {
            if (startDate && endDate) {
              setFilterDates({
                financialStartDate: startDate,
                financialEndDate: endDate
              });
              setShowHideCalendar(false);
              let updateFilter = { ...filter };
              updateFilter.fromDate = startDate;
              updateFilter.toDate = endDate;
              setFilter(updateFilter);
            }
          }}
        />
      </div>
    );
  };
  /*
   main renderer goes here
   */
  return (
    <div className="column parent-width flex-1">
      <div className="column mt-s parent-width mb-s position-relative flex-1">
        <Suspense
          fallback={
            <div className="row justify-content-center">
              <DKSpinner />
            </div>
          }
        >
          {getHeaderWithActions()}
          {automationLogData?.data.length === 0 && getNoDataView()}
          <DKDataGrid
            showHeader={false}
            allowColumnAdd={false}
            width={SideBarService.getContainerWidth()}
            gridStickyHeaderConfig={
              isMobileAppWebView()
                ? null
                : {
                    getRootScrollContainer: () =>
                      isReport
                        ? AppManager.getScrollContainerDiv()
                        : document.getElementById(LOG_SCROLL_CONTAINER_ID)
                  }
            }
            allowColumnEdit={false}
            allowColumnDelete={false}
            updating={loading}
            allowColumnShift={false}
            allowColumnSort={false}
            currentPage={automationLogData?.pageNo}
            columns={columns}
            allowRowAdd={false}
            allowRowEdit={false}
            allowBulkOperation={false}
            rows={getRowData()}
            dateFormat={dateFormat}
            tableName={TABLES.AUTOMATION_LOG}
            needTrailingColumn={true}
            totalPageCount={Math.ceil(
              automationLogData?.totalCount / automationLogData?.pageSize
            )}
            onPageChange={onPageChange}
          />
        </Suspense>
      </div>
    </div>
  );
}
