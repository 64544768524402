import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import User from "../../services/user";
import IUser, { ISalesRepresentativeListResponse } from "../../model/User";
import { RootState } from "../store";
import { createSelector } from "reselect";
import Tenant, { getTenantSettings } from "../../services/tenant";
import TableDataParser from "../../Helper/TableDataParser";
import ICurrency from "../../model/Currency";
import { Team } from "../../services/team";
import ITeam from "../../model/Team";
import {
  fetchBookADemoSettings as fetchBookADemoSettingsService,
  IDemoSettings,
  SalesRepService
} from "../../services/bookaDemo";
import { DateUtil, DATE_FORMATS } from "../../utility/Date";
import UserManager from "../../managers/UserManager";
import { US_COUNTRY_CODE } from "../../constants/Constant";
import Utility from "../../utility/Utility";

export interface TenantState {
  tenants: any[];
  usersInfo: any;
  users: IUser[];
  teams: ITeam[];
  currencies: ICurrency[];
  teamPermissions: [];
  crmSettings: any;
  bookADemoSettings: IDemoSettings;
  bdrData: ISalesRepresentativeListResponse;
}
const initialState: TenantState = {
  tenants: [],
  users: [],
  usersInfo: {},
  teams: [],
  teamPermissions: [],
  crmSettings: {},
  currencies: [],
  bookADemoSettings: {},
  bdrData: null
};
export const fetchTenants = createAsyncThunk(
  "tenants/fetchTenants",
  async (data, thunkAPI) => {
    const response = await Tenant.getAllTenants();
    return response;
  }
);
export const fetchUsers = createAsyncThunk(
  "tenants/fetchUsers",
  async (data, thunkAPI) => {
    const response = await User.getUsers();
    return response;
  }
);
export const fetchTeams = createAsyncThunk(
  "tenants/fetchTeams",
  async (data, thunkAPI) => {
    const response = await Team.getTeams();
    return response;
  }
);
export const fetchTeamPermission = createAsyncThunk(
  "tenants/fetchTeamPermission",
  async (data, thunkAPI) => {
    const response = await Team.getTeamPermission();
    return response;
  }
);
export const fetchCurrencies = createAsyncThunk(
  "tenants/fetchCurrencies",
  async (data, thunkAPI) => {
    const response = await Tenant.getCurrencyEnum();
    return response;
  }
);
export const fetchUserInfo = createAsyncThunk(
  "tenants/fetchUserInfo",
  async (data: any, thunkAPI) => {
    const response = await User.fetchUsersInfo(data.params);
    return response;
  }
);
export const fetchCRMSettings = createAsyncThunk(
  "tenants/fetchCRMSettings",
  async (data: any, thunkAPI) => {
    const response = await getTenantSettings();
    return response;
  }
);
export const fetchBookADemoSettings = createAsyncThunk(
  "tenants/fetchBookADemoSettings",
  async (data: any, thunkAPI) => {
    const response = await fetchBookADemoSettingsService();
    return response;
  }
);
export const fetchBDRSettings = createAsyncThunk(
  "tenants/fetchBDRSettings",
  async (data: any, thunkAPI) => {
    const response = await SalesRepService.fetchRepresentatives(data.params);
    return response;
  }
);
export const tenantsSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTenants.fulfilled, (state, action) => {
      state.tenants = action.payload as any;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      const res: any = action.payload;
      const filteredUsers = res.invites.filter(onlyUniqueUsers).map((user) => {
        return { ...user, id: user.iamUserId || user.id };
      });

      TableDataParser.putDataInOwnersColumn(filteredUsers);

      state.users = filteredUsers;
    });
    builder.addCase(fetchCurrencies.fulfilled, (state, action: any) => {
      state.currencies = action.payload.content;
    });
    builder.addCase(fetchCurrencies.rejected, (state, action: any) => {
      state.currencies = [];
    });
    builder.addCase(fetchTeams.fulfilled, (state, action) => {
      state.teams = action.payload.data;
    });
    builder.addCase(fetchTeamPermission.fulfilled, (state, action) => {
      state.teamPermissions = (action.payload as any)?.teamData;
    });
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.usersInfo = action.payload;
    });
    builder.addCase(fetchBookADemoSettings.fulfilled, (state, action: any) => {
      state.bookADemoSettings = action.payload;
    });
    builder.addCase(fetchCRMSettings.fulfilled, (state, action: any) => {
      state.crmSettings = action.payload;
      let dateFormat =
        UserManager?.getUserCountry() === US_COUNTRY_CODE
          ? DATE_FORMATS["MM/DD/YYYY"]
          : DATE_FORMATS["DD-MM-YYYY"];
      if (!Utility.isEmptyObject(action.payload?.dateFormat)) {
        dateFormat = action.payload?.dateFormat;
      }
      DateUtil.setOrgDateFormat(dateFormat);
    });
    builder.addCase(fetchBDRSettings.fulfilled, (state, action) => {
      state.bdrData = action.payload;
    });
  }
});

export const {} = tenantsSlice.actions;

const onlyUniqueUsers = (item: IUser, index: number, users: []) =>
  users.findIndex((obj: IUser) => obj.email === item.email) === index;

const selfSelector = (state: RootState) => state?.tenant;
export const getTenants = () =>
  createSelector(selfSelector, (state: TenantState) => state.tenants);
export const getUsers = () =>
  createSelector(selfSelector, (state: TenantState) => state.users);
export const getCurrencies = () =>
  createSelector(selfSelector, (state: TenantState) => state.currencies);
export const getTeams = () =>
  createSelector(selfSelector, (state: TenantState) => state.teams);
export const getTeamPermission = () =>
  createSelector(selfSelector, (state: TenantState) => state.teamPermissions);
export const selectUserInfo = (state) => state.tenant?.usersInfo?.content?.[0];
export const selectCRMSettings = () =>
  createSelector(selfSelector, (state: TenantState) => state.crmSettings);
export const isEnableAccountLinking = (state) =>
  state.tenant?.crmSettings?.isDealRequiresAccount ?? false;
export default tenantsSlice.reducer;
