import React, { Component } from "react";
import { DKLabel, DKButton, DKIcons, showToast } from "deskera-ui-library";
import Stepper from "../common/Stepper";
import FormManager from "./FormManager";
import FormService from "../../services/form";
import { TABLES } from "../../managers/TableManger";
import ElementList from "../page_designer/ElementList";

/*
PROPS
- stepperIndex
- title
-onSave
*/

class FormHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: FormManager.getFormStatus(),
      showElement: false
    };
  }

  render() {
    return (
      <div className="parent-width border-box column position-relative z-index-2">
        <div className="row justify-content-between">
          <DKLabel text={this.props.title} className="fw-m fs-l" />
          {this.getButtons()}
        </div>
        <div className="row justify-content-center display-only-web">
          <Stepper
            data={FormManager.getAllCreationSteps()}
            currentIndex={this.props.stepperIndex ? this.props.stepperIndex : 0}
          />
        </div>
      </div>
    );
  }

  getButtons() {
    return (
      <div className="row" style={{ width: "auto" }}>
        {this.props.stepperIndex === 1 && (
          <div className="row" style={{ top: 47, zIndex: 1 }}>
            <DKButton
              icon={DKIcons.ic_add}
              className={`element-add-button bg-white border-m ml-r`}
              onClick={() => {
                this.setState({ showElement: true });
              }}
            />

            {this.state.showElement && (
              <div
                className="position-absolute z-index-1 shadow-m"
                style={{ top: 35, zIndex: 1 }}
              >
                <DKButton
                  icon={DKIcons.ic_close}
                  className="bg-white border-radius-none m-s"
                  iconClassName="ic-m"
                  style={{ float: "right" }}
                  onClick={(e) => {
                    this.setState({ showElement: false });
                    e.stopPropagation();
                  }}
                />
                <ElementList
                  className="p-r"
                  onAdd={(type) => {
                    this.props.onAdd(type);
                    this.setState({ showElement: false });
                  }}
                />
              </div>
            )}
          </div>
        )}

        {this.props.onSave && (
          <DKButton
            title={
              FormManager.isPageDataAvailable() ? "Save" : "Save & Publish"
            }
            icon={DKIcons.white.ic_save}
            className="bg-button text-white ml-r unselectable"
            onClick={() => {
              this.props.onSave();
            }}
          />
        )}
      </div>
    );
  }

  updateStatus() {
    const request = FormManager.getRequestToUpdateStatus(this.state.isActive);
    FormService.update(
      request,
      FormManager.getFormRecordId(),
      (data) => {
        showToast("Status updated!", "success");
      },
      (error) => {}
    );
  }
}

export default FormHeader;
