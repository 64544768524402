import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TableManger } from "../../managers/TableManger";
import UserManager from "../../managers/UserManager";
import ReportService from "../../services/report";

export interface ReportState {
  activityByContact: any;
  ticketByContact: any;
  reportsTables: any;
  permissionInfo: any;
}
const initialState: ReportState = {
  activityByContact: [],
  ticketByContact: [],
  reportsTables: [],
  permissionInfo: {}
};
/* Custom records other that CRM Core goes here (import_logs, etc...) */
export const fetchActivityByContact = createAsyncThunk(
  "reports/fetchActivityByContact",
  async (data: any) => {
    let response = await ReportService.fetchActivityByContact(data?.params);
    return response;
  }
);
export const fetchTicketByContact = createAsyncThunk(
  "reports/fetchTicketByContact",
  async (data: any) => {
    let response = await ReportService.fetchTicketByContact(data?.params);
    return response;
  }
);
export const fetchReportTables = createAsyncThunk(
  "reports/fetchReportTables",
  async (data) => {
    let response = await ReportService.fetchReportTables();
    return response;
  }
);
export const fetchPermissionInfo = createAsyncThunk(
  "reports/fetchPermissionInfo",
  async () => {
    let payload = [UserManager.getUserID()];
    const response = await ReportService.getUsersPermission(payload);
    return response;
  }
);
export const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActivityByContact.fulfilled, (state, action) => {
      state.activityByContact = action.payload;
    });
    builder.addCase(fetchTicketByContact.fulfilled, (state, action) => {
      state.ticketByContact = action.payload;
    });
    builder.addCase(fetchReportTables.fulfilled, (state, action) => {
      state.reportsTables = action.payload;
      TableManger.setAllReportsTableData(action.payload);
    });
    builder.addCase(fetchPermissionInfo.fulfilled, (state, action) => {
      state.permissionInfo = action.payload;
    });
  }
});

export const getRecordsForActivityByContact = (state) =>
  state.reports.activityByContact;
export const getRecordsForTicketByContact = (state) =>
  state.reports.ticketByContact;
export const getPermissionInfo = (state) => state.reports.permissionInfo;
export default reportSlice.reducer;
