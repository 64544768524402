import React, { Component } from "react";

/*
PROPS
- data
- borderStyle
- isEditing
*/

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inlineEditing: this.props.inlineEditing ? this.props.inlineEditing : false
    };
  }
  render() {
    return this.props.isEditing ? this.getEditableTag() : this.getAnchorTag();
  }

  getEditableTag = () => {
    return (
      <div
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle,
          display: "block",
          ...this.props.style
        }}
      >
        <span
          id={`button-${this.props.columnIndex !== null && this.props.columnIndex !== undefined ? this.props.columnIndex + "-" + this.props.index : this.props.index}`}
          contentEditable={this.state.inlineEditing}
          dangerouslySetInnerHTML={{ __html: this.props.data.value }}
          onClick={(e) => {
            if (this.state.inlineEditing) {
              e.stopPropagation();
            }
          }}
          onDoubleClick={() => {
            if (!this.props.isEditing || this.state.inlineEditing) {
              return;
            }
            this.setState({ inlineEditing: true }, () => {
              this.selectedTextBox(
                `${this.props.columnIndex !== null && this.props.columnIndex !== undefined ? this.props.columnIndex + "-" + this.props.index : this.props.index}`
              );
            });
          }}
          style={{ padding: 1 }}
          onBlur={(e) => {
            e.preventDefault();
            this.setState({ inlineEditing: false });
            let index = null,
              columnIndex = null,
              colComponentIndex = null;
            if (
              this.props.columnIndex !== null &&
              this.props.columnIndex !== undefined
            ) {
              if (this.props.colComponentIndex !== undefined) {
                index = this.props.componentIndex;
                columnIndex = this.props.columnIndex;
                colComponentIndex = this.props.colComponentIndex;
              } else {
                index = this.props.index;
                columnIndex = this.props.columnIndex;
                colComponentIndex = null;
              }
            } else {
              index = this.props.index;
            }
            this.props.onTextChange(
              e.target.innerHTML,
              index,
              columnIndex,
              colComponentIndex
            );
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              document.execCommand("insertLineBreak");
              e.preventDefault();
            }
          }}
          onPaste={(e) => {
            e.preventDefault();
            const text = e.clipboardData.getData("text/plain");
            document.execCommand("insertHTML", false, text);
          }}
        ></span>
      </div>
    );
  };

  getAnchorTag = () => {
    return (
      <a
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle,
          display: "inline-block"
        }}
        href={this.props.data.url}
      >
        <span
          id={`button-${this.props.columnIndex !== null && this.props.columnIndex !== undefined ? this.props.columnIndex + "-" + this.props.index : this.props.index}`}
          dangerouslySetInnerHTML={{ __html: this.props.data.value }}
        ></span>
      </a>
    );
  };

  selectedTextBox = (index) => {
    let button = document.getElementById("button-" + index);
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(button);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
    button.focus();
    range.detach();
  };
}

export default Button;
