import React, { Component } from "react";
import { DKLabel } from "deskera-ui-library";
/* import FunnelManager from "../funnel/FunnelManager"; */

/*
PROPS
- data {title, onClick}
- currentIndex
- onClick
- draggable
*/

class Stepper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      draggedIndex: null,
      draggedStep: null,
      onHover: false,
      hoverIndex: null
    };
    this.width = 130;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({ data: nextProps.data });
    }
  }
  render() {
    return (
      <div
        className="row justify-content-center align-items-start "
        style={{ marginTop: -10 }}
      >
        {this.getAllSteps(this.state.data)}
      </div>
    );
  }

  getAllSteps(steps) {
    let arr = [];
    steps.forEach((step, index) => {
      arr.push(
        this.getStep(
          step,
          index,
          false
          /* FunnelManager.isFunnelDataAvailable() && index === steps.length - 1 */
        )
      );
    });
    return arr;
  }

  getStep(data, index, isPublishStep) {
    return (
      <div
        key={index}
        className="column justify-content-center align-items-center position-relative"
        style={{ width: this.width }}
        onMouseLeave={() => this.setState({ onHover: false })}
      >
        {/* <div className="row bg-white justify-content-center align-items-center funnel-stepper-rollover">
          {this.state.onHover && index === this.state.hoverIndex && (
            <>
              {this.state.hoverIndex !== 0 && (
                <DKButton
                  icon={DKIcons.ic_arrow_left}
                  className="border-radius-none"
                  onClick={(e) => this.shiftLeft(e, data, index)}
                />
              )}
              {index !== this.state.data.length - 1 && (
                <DKButton
                  icon={DKIcons.ic_arrow_right}
                  className="border-radius-none"
                  onClick={(e) => this.shiftRight(e, data, index)}
                />
              )}
            </>
          )}
        </div> */}
        {index !== this.state.data.length - 1 && (
          <div
            className="bg-gray4 position-absolute"
            style={{ width: this.width, height: 3, top: 20, left: "50%" }}
          />
        )}
        <div
          draggable={
            !isPublishStep && this.props.draggable
              ? this.props.draggable
              : false
          }
          onDragStart={(e) => this.onDragStart(e, data, index)}
          onDragOver={(e) => {
            if (!isPublishStep) {
              this.onStepDragOver(e, index);
            }
          }}
          onDrop={(e) => {
            if (!isPublishStep) {
              this.onDrop(e, index);
            }
          }}
          onMouseEnter={(e) => {
            if (!isPublishStep) {
              this.onMouseOver(e, data, index);
            }
          }}
          className={
            "column  border-m shadow-m fw-m circle justify-content-center align-items-center z-index-2 cursor-hand " +
            (index <= this.props.currentIndex
              ? "bg-button text-white"
              : "bg-white text-black")
          }
          style={{ width: 40, height: 40 }}
          onClick={() => {
            data.onClick(index);
            if (this.props.onClick) {
              this.props.onClick(index);
            }
          }}
        >
          {index + 1}
        </div>
        <DKLabel
          text={data.title}
          className={
            "text-align-center mt-s " +
            (index === this.props.currentIndex
              ? "fw-m bg-white p-v-xs p-h-s border-radius-s shadow-m"
              : "")
          }
        />
      </div>
    );
  }
  onDragStart = (e, data, index) => {
    this.setState({ draggedIndex: index, draggedStep: data });
  };
  onStepDragOver = (e, index) => {
    e.preventDefault();
  };
  onDrop = (e, index) => {
    e.preventDefault();
    this.setState({ onHover: false });
    const droppedStep = { ...this.state.draggedStep };
    if (this.props.draggable)
      this.props.onStepDrop(this.state.draggedIndex, index, droppedStep);
  };
  onMouseOver = (e, data, index) => {
    this.setState({ onHover: true, hoverIndex: index });
    e.preventDefault();
  };
  shiftRight = (e, data, index) => {
    this.setState({ onHover: false });
    if (this.props.draggable) this.props.onStepDrop(index, index + 1, data);
  };
  shiftLeft = (e, data, index) => {
    this.setState({ onHover: false });
    if (this.props.draggable) this.props.onStepDrop(index, index - 1, data);
  };
}

export default Stepper;
