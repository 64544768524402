import { useEffect, useState } from "react";
import {
  DKButton,
  DKListPicker2,
  DKIcons,
  showAlert
} from "deskera-ui-library";

import { IRole } from "../../../model/Roles";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  deleteRole,
  selectRoles
} from "../../../redux/slices/rolesPermissionSlice";
import { showCreateRolePopup } from "./CreateRole";
import Utility from "../../../utility/Utility";

const RoleSelector = ({
  className = "",
  labelClassName = "",
  selectedRoleId = null,
  defaultLabel = "Pending",
  allowRolePicker = true,
  allowRoleAdd = false,
  allowRoleAssign = false,
  allowRoleEdit = false,
  allowRoleDelete = false,
  isOwner = false,
  onChangeRole
}) => {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(selectRoles);
  const [selectedRole, setSelectedRole] = useState(
    roles?.find((role: IRole) => role._id === selectedRoleId)
  );
  const [showRolePicker, setShowRolePicker] = useState(false);

  useEffect(() => {
    const selectedRoleData = roles?.find(
      (role: IRole) => role._id === selectedRoleId
    );
    setSelectedRole(selectedRoleData);
  }, [roles, selectedRoleId]);

  const toggleRolePicker = (shouldOpen?: boolean) => {
    let openRolePicker = shouldOpen ?? !showRolePicker;
    if (!allowRolePicker) {
      openRolePicker = false;
    }
    setShowRolePicker(openRolePicker);
  };

  /**
   * @description
   * User Role will only change in case of creating role & role selection
   * & Not in case of editing role.
   */
  const onRoleChange = (
    newSelectedRole: IRole,
    applyRole: boolean,
    needAlert?: boolean
  ) => {
    if (!allowRoleAssign) {
      needAlert &&
        showAlert(
          "Operation not allowed!",
          "You don't have permission to assign roles, please contact your organization owner."
        );
      return;
    }

    if (isOwner) {
      needAlert &&
        showAlert(
          "Operation not allowed!",
          "Organization owner role can not be updated."
        );
      return;
    }

    if (applyRole && newSelectedRole?._id !== selectedRoleId) {
      setSelectedRole(newSelectedRole);
      onChangeRole?.(newSelectedRole);
    }

    setShowRolePicker(false);
  };

  const toggleUserRolePopup = (roleToUpdate?: IRole, shouldOpen?: boolean) => {
    if (shouldOpen) {
      showCreateRolePopup({
        roleData: roleToUpdate,
        onSave: (newRoleData) =>
          onRoleChange(newRoleData, Utility.isEmptyObject(roleToUpdate?._id))
      });
    }
  };

  const onDeleteRoleTapped = (role: IRole) => {
    const buttons = [
      {
        title: "Delete",
        className: "bg-red text-white",
        onClick: () => {
          let error = null;
          dispatch(deleteRole(role._id))
            .then((res: any) => {
              if (!Utility.isEmptyObject(res?.error)) {
                error = res.error;
              }
            })
            .catch((err) => {
              error = err;
            })
            .finally(() => {
              if (!Utility.isEmptyObject(error)) {
                showAlert(
                  "Delete role failed",
                  error?.message ||
                    "Looks like the role is in use, or you don't have permission to delete this role."
                );
              }
            });
        }
      },
      {
        title: "Cancel",
        className: "ml-xs",
        onClick: () => {}
      }
    ];

    showAlert(
      "Delete role",
      "Please make sure this role is not assigned to any user before deleting.",
      buttons
    );
  };

  return (
    <div
      className={`user-role-selector row width-auto position-relative ${className}`}
    >
      <DKButton
        title={selectedRole?.name || defaultLabel}
        className={`text-gray user-role-selector-button ${labelClassName}`}
        onClick={() => toggleRolePicker(true)}
        icon={allowRolePicker ? DKIcons.ic_arrow_down2 : null}
        isReverse={true}
      />
      {showRolePicker && (
        <DKListPicker2
          data={roles}
          displayKey={"name"}
          searchableKey={"name"}
          allowSearch={true}
          className="position-absolute border-m shadow-s border-radius-s z-index-3"
          style={{
            right: 0,
            top: -80,
            width: 150
          }}
          onSelect={(index: number, newSelectedRole: IRole) => {
            onRoleChange(newSelectedRole, true, true);
          }}
          canEdit={allowRoleEdit}
          onEdit={(index: number, newSelectedRole: IRole) => {
            toggleUserRolePopup(newSelectedRole, true);
          }}
          canDelete={allowRoleDelete}
          onDelete={(index: number, newSelectedRole: IRole) => {
            if (newSelectedRole.default) {
              showAlert(
                "Operation not allowed!",
                "You can not delete the default role."
              );
            } else {
              onDeleteRoleTapped(newSelectedRole);
            }
          }}
          button={
            allowRoleAdd
              ? {
                  title: "+ New Role",
                  className: "bg-button text-white",
                  onClick: () => toggleUserRolePopup(null, true)
                }
              : null
          }
          onClose={() => toggleRolePicker(false)}
        />
      )}
    </div>
  );
};

export default RoleSelector;
