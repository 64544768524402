import React, { Component } from "react";

/*
- text
- className
- style
*/

export default class DKLabel2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: this.props.text
    };
  }

  componentWillReceiveProps(nextProp) {
    this.setState({
      text: nextProp.text
    });
  }

  render() {
    return (
      <div
        className={`text-align-left ${this.props.className}`}
        dangerouslySetInnerHTML={{ __html: this.state.text }}
        style={{
          ...{
            wordWrap: "break-word",
            whiteSpace: "pre-wrap"
          },
          ...this.props.style
        }}
      />
    );
  }
}
