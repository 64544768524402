import { getRandomAlphaNumericString } from "../../../utility/Utility";
import { PAGE_ELEMENT } from "../../page_designer/ComponentList";
import ComponentManager from "../../page_designer/ComponentManager";
import ic_x from "../../../assets/social_icons/ic_twitter.png";

export default class Template8 {
  static getData() {
    return {
      metaData: null,
      scripts: null,
      hash: getRandomAlphaNumericString(),
      designElements: [
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625725022405_deskera-logo-d1 (1).png",
          style: {
            width: "32%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 30,
            marginBottom: 10,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "flex-start",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625665754520_Newsletter-8-Image1 (1).png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 24,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "Guides for Later Travels",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 38,
            color: "#000000",
            textAlign: "center",
            fontWeight: "600",
            paddingTop: 6,
            paddingBottom: 0,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 30,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Text",
          value:
            "Est voluptas non a vel odit aliquid voluptatem officia. Dolorem magni cupiditate sequi impedit vel non. Inventore porro est hic earum dolor non. Perspiciatis qui cumque consequuntur. Quod vero neque nesciunt sapiente placeat qui nam non. Autem rerum molestiae error quidem est voluptas deleniti sed. \n\nQuod magnam neque consectetur ratione quae dolores exercitationem corrupti est. Culpa enim est velit",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 14,
            color: "#000000",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#ffffff",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Button",
          value: "Book Now",
          url: "",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 16,
            color: "#ffffff",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 29,
            paddingRight: 29,
            marginTop: 16,
            marginBottom: 22,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 4,
            backgroundColor: "#90001d",
            alignSelf: "center",
            overflow: "hidden",
            cursor: "pointer",
            outline: "none",
            border: "none",
            textDecoration: "none"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625665772977_Newsletter-8-Image2 (1).png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 10,
            marginBottom: 16,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "Get ready for your journey",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 38,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Text",
          value:
            "Libero quia tempore neque quas laborum repellendus similique nobis omnis. Rerum culpa aut sequi veritatis. Earum qui similique tenetur est qui et repellendus tenetur excepturi. Est tempore quia.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 14,
            color: "#000000",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Collection",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Boston",
                fontSize: 20,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Libero quia tempore neque quas laborum repellendus similique nobis omnis. Rerum culpa aut sequi veritatis. Earum qui similique tenetur est qui et repellendus tenetur excepturi. Est tempore quia.",
                  fontSize: 12,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625665785245_Newsletter-8-Image3 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Philadelphia",
                fontSize: 20,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Libero quia tempore neque quas laborum repellendus similique nobis omnis. Rerum culpa aut sequi veritatis. Earum qui similique tenetur est qui et repellendus tenetur excepturi. Est tempore quia.",
                  fontSize: 12,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625665798959_Newsletter-8-Image4 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "East Hampton",
                fontSize: 20,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Libero quia tempore neque quas laborum repellendus similique nobis omnis. Rerum culpa aut sequi veritatis. Earum qui similique tenetur est qui et repellendus tenetur excepturi. Est tempore quia.",
                  fontSize: 12,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625665812479_Newsletter-8-Image5 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Milan",
                fontSize: 20,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Libero quia tempore neque quas laborum repellendus similique nobis omnis. Rerum culpa aut sequi veritatis. Earum qui similique tenetur est qui et repellendus tenetur excepturi. Est tempore quia.",
                  fontSize: 12,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625665822515_Newsletter-8-Image6 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Moscow",
                fontSize: 20,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Libero quia tempore neque quas laborum repellendus similique nobis omnis. Rerum culpa aut sequi veritatis. Earum qui similique tenetur est qui et repellendus tenetur excepturi. Est tempore quia.",
                  fontSize: 12,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625665832868_Newsletter-8-Image7 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Nevada",
                fontSize: 20,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Libero quia tempore neque quas laborum repellendus similique nobis omnis. Rerum culpa aut sequi veritatis. Earum qui similique tenetur est qui et repellendus tenetur excepturi. Est tempore quia.",
                  fontSize: 12,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625665841157_Newsletter-8-Image8 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 24,
            marginBottom: 20,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 204,
            cellSpacing: 9,
            iconRadius: 0,
            titleColor: "#000000",
            titleSize: 20,
            descriptionColor: "#000000",
            descriptionSize: 12,
            gridSize: 29
          }
        },
        {
          type: "Button",
          value: "EXPLORE JOURNEY",
          url: "",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 16,
            color: "#ffffff",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 14,
            paddingBottom: 14,
            paddingLeft: 23,
            paddingRight: 23,
            marginTop: 10,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 6,
            backgroundColor: "#90001d",
            alignSelf: "center",
            overflow: "hidden",
            cursor: "pointer",
            outline: "none",
            border: "none",
            textDecoration: "none"
          }
        },
        {
          type: "Social Buttons",
          value: [
            {
              isRequired: true,
              title: "Facebook",
              url: "",
              icon: "https://i.ibb.co/FX4mz7J/ic-facebook.png"
            },
            {
              isRequired: true,
              title: "Instagram",
              url: "",
              icon: "https://i.ibb.co/VNvh8BL/ic-instagram.png"
            },
            {
              isRequired: true,
              title: "Twitter",
              url: "",
              icon: "https://i.ibb.co/qR8Q8PV/ic-twitter.png"
            },
            {
              isRequired: false,
              title: "LinkedIn",
              url: "",
              icon: "https://i.ibb.co/BzLpvD2/linkedin-2.png"
            },
            {
              isRequired: false,
              title: "Youtube",
              url: "",
              icon: "https://i.ibb.co/tJ6rf4x/youtube.png"
            },
            {
              isRequired: false,
              title: "WhatsApp",
              url: "",
              icon: "https://i.ibb.co/nn931VS/whatsapp-2.png"
            }
          ],
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 30,
            paddingBottom: 30,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#ffffff",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 30,
            cellSpacing: 12
          }
        },
        {
          type: "Text",
          value: "© 2021, All Rights Reserved",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 10,
            color: "#ffffff",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#000000",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        }
      ]
    };
  }
}
