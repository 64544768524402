import TableDataParser from "../Helper/TableDataParser";
import ApiConstants from "../constants/ApiConstants";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../constants/Constant";
import { APP_NAME } from "../constants/Enum";
import httpClient from "../http";
import { COLUMN_CODE, TableManger, TABLES } from "../managers/TableManger";
import { Account, IBulkAccountData, ParsedAccount } from "../model/Accounts";
import Utility from "../utility/Utility";

export const ACCOUNTS_DEFAULT_CONFIG = {
  q: "",
  pageNo: DEFAULT_PAGE_NO,
  pageSize: DEFAULT_PAGE_SIZE,
  sortCol: "createdAt",
  sortDir: -1
};

export class AccountService {
  static fetchAccounts({ params = {}, payload = null }): Promise<any> {
    params = {
      ...ACCOUNTS_DEFAULT_CONFIG,
      ...params
    };
    return httpClient.post(ApiConstants.URL.ACCOUNT.GET_ACCOUNT, payload, {
      params
    });
  }
  static fetchAccountById(accountId: string): Promise<any> {
    let params = {
      colIdsToFetchRef: TableManger.getColumnId(
        TABLES.ACCOUNT,
        COLUMN_CODE.ACCOUNT.PRICE_LIST
      )
    };
    return httpClient.get(
      ApiConstants.URL.ACCOUNT.GET_ACCOUNT_BY_ID(accountId),
      { params }
    );
  }
  static createAccount(account: any): Promise<any> {
    const tableId = TableManger.getTableId(TABLES.ACCOUNT);
    return httpClient.post(
      ApiConstants.URL.TABLE.ADD_RECORD(tableId),
      account,
      {
        params: {
          skipDuplicateInterceptor: true
        }
      }
    );
  }
  static updateAccount(accountId: string, account: any): Promise<any> {
    const tableId = TableManger.getTableId(TABLES.ACCOUNT);
    return httpClient.put(
      ApiConstants.URL.TABLE.UPDATE_RECORD(accountId, tableId),
      account,
      {
        params: {
          skipDuplicateInterceptor: true
        }
      }
    );
  }
  static updateAccountInBulk(accountList: IBulkAccountData[]): Promise<any> {
    const tableId = TableManger.getTableId(TABLES.ACCOUNT);
    return httpClient.put(
      ApiConstants.URL.TABLE.UPDATE_BULK_RECORD(tableId),
      accountList,
      {
        params: {
          skipDuplicateInterceptor: true
        }
      }
    );
  }
  static deleteAccount(
    accountId: string,
    isForceDelete: boolean
  ): Promise<any> {
    let query = "";
    if (isForceDelete) {
      query = `?isForceDelete=${isForceDelete}`;
    }
    const tableId = TableManger.getTableId(TABLES.ACCOUNT);
    return httpClient.delete(
      ApiConstants.URL.TABLE.DELETE_RECORD(accountId, tableId) + query
    );
  }
  static deleteAccountInBulk(
    accountIds: string[],
    isForceDelete: boolean
  ): Promise<any> {
    const tableId = TableManger.getTableId(TABLES.ACCOUNT);
    let query = "";
    if (isForceDelete) {
      query = `?isForceDelete=${isForceDelete}`;
    }
    return httpClient.delete(
      `${ApiConstants.URL.TABLE.DELETE_BULK_RECORD(tableId) + query}`,
      { data: accountIds }
    );
  }
  static getEmailsByAccountsContacts(contactIds: string[]) {
    return httpClient.post(ApiConstants.URL.ACCOUNT.GET_EMAILS_BY_ACCOUNT_ID, {
      crm3_contact_ids: contactIds
    });
  }
  static getTicketsByContacts(contactIds: string[], params = {}) {
    return httpClient.post(
      ApiConstants.URL.ACCOUNT.GET_TICKETS_BY_ACCOUNT_ID,
      { crm3_contact_ids: contactIds },
      { params: { ...params, app_name: APP_NAME.CRM_3 } }
    );
  }
  static getTicketsByAccounts(account: string[], params = {}) {
    return httpClient.post(
      ApiConstants.URL.ACCOUNT.GET_TICKETS_BY_ACCOUNT_ID,
      { crm3_account_ids: account },
      { params: { ...params, app_name: APP_NAME.CRM_3 } }
    );
  }
  static getDealsByAccountId(accountId: string) {
    return httpClient.get(
      ApiConstants.URL.ACCOUNT.GET_DEALS_BY_ACCOUNT_ID(accountId),
      { params: { fetchUserRef: true } }
    );
  }
  static getActivitiesByAccountId(accountId: string) {
    return httpClient.get(
      ApiConstants.URL.ACCOUNT.GET_ACTIVITY_BY_ACCOUNT_ID(accountId),
      { params: { fetchAllRef: true } }
    );
  }
  static fetchAuditLogsForAccount(contactId: string, params = {}) {
    return httpClient.post(
      ApiConstants.URL.ACCOUNT.GET_AUDIT_LOGS,
      { recordIds: contactId },
      { params: { ...params, appName: APP_NAME.CRM_3 } }
    );
  }
  static buildAccountPayload = (data) => {
    data = TableDataParser.getSanitizedJsonData(data);
    const accountColumns = TableManger.getTableColumns(TABLES.ACCOUNT);
    const account = {};
    accountColumns?.forEach((column) => {
      const columnValue = data[column.columnCode || column.id];
      if (!Utility.isEmptyObject(column?.lookup)) {
        account[column.id] = columnValue?.id ? [columnValue?.id] : null;
      } else {
        account[column.id] = columnValue;
      }
    });
    return account;
  };
  static parseAccountPayload = (data) =>
    data.map(AccountService.parseAccountDetailsPayload);
  static parseAccountDetailsPayload = (data: Account): ParsedAccount => {
    let account: ParsedAccount = {
      id: data._id,
      name: "Account"
    };

    const accountColumns = TableManger.getTableColumns(TABLES.ACCOUNT);
    accountColumns?.forEach((column) => {
      let key = column.columnCode || column.key;
      account[key] = data.cells?.[column.id] || "";
      if (!Utility.isEmptyObject(data.cells?.[key + "_detail"])) {
        account[key + "_detail"] = data.cells?.[key + "_detail"] || "";
      }
      if (column.columnCode === COLUMN_CODE.ACCOUNT.NOTE) {
        account[COLUMN_CODE.ACCOUNT.NOTE] = data.cells?.[column.id] || [];
      }
    });
    const priceBookColumnId = TableManger.getColumnId(
      TABLES.ACCOUNT,
      COLUMN_CODE.ACCOUNT.PRICE_LIST
    );

    const contactColumns = TableManger.getColumnCodeKeyMap(TABLES.CONTACT);
    account[`${priceBookColumnId}_detail`] =
      data?.cells?.[`${priceBookColumnId}_detail`];
    account.contactCount = data?.cells?.["contact_count"] ?? 0;
    account.dealsCount = data?.cells?.["deal_count"] ?? 0;
    account.wonDealAmount = data?.cells?.["deal_won_amount"] ?? 0;
    account.lostDealAmount = data?.cells?.["deal_lost_amount"] ?? 0;
    account.contactDetail = data?.cells?.["contact_detail"]?.map((item) => ({
      id: item?._id,
      name: item?.cells[contactColumns?.[COLUMN_CODE.CONTACT.NAME]],
      email: item?.cells[contactColumns?.[COLUMN_CODE.CONTACT.EMAIL]],
      phone: item?.cells[contactColumns?.[COLUMN_CODE.CONTACT.PHONE]],
      notes: item?.cells[contactColumns?.[COLUMN_CODE.CONTACT.NOTE]] || [],
      attachments:
        item?.cells[contactColumns?.[COLUMN_CODE.CONTACT.ATTACHMENT]],
      booksContactId:
        item?.cells[contactColumns?.[COLUMN_CODE.CONTACT.BOOKS_CONTACT_ID]],
      detail: item
    }));
    return account;
  };
}
