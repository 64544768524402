import React from "react";
import { DKLabel, DKIcon } from "deskera-ui-library";
import DKLabel2 from "./DKLabel2";

/**
 * PROPS
 *
 * eyebrow?: string;
 * title: string;
 * subtitle: string;
 * cards: { icon?: string; image?: string; title: string; subtitle?: string; }[];
 */
const CardGrid = ({ eyebrow, title, subTitle, cards, className }) => {
  return (
    <div
      className={`row row-responsive justify-content-between align-items-start padding-2 p-v-xxl ${
        className || ""
      }`}
      style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "80rem" }}
    >
      <div className="column pr-xl width-30">
        {eyebrow ? (
          <DKLabel2
            className="fw-h fs-xl text-blue"
            style={{ lineHeight: "2.25rem", letterSpacing: " -.025em" }}
            text={eyebrow}
          />
        ) : null}
        <DKLabel2
          className="fw-h fs-xxxl"
          style={{ lineHeight: "2.25rem", letterSpacing: " -.025em" }}
          text={title}
        />
        {subTitle ? (
          <DKLabel2
            className="text-gray fs-l"
            style={{ lineHeight: "1.5" }}
            text={subTitle}
          />
        ) : null}
      </div>
      <div
        className="row row-responsive flex-wrap width-70"
        style={{
          gap: "40px 32px"
        }}
      >
        {cards.map((card) => {
          return (
            <div className="column width-45">
              {card.image ? (
                <DKIcon
                  src={card.image}
                  className="parent-height border-radius-s mb-l"
                  style={{ objectFit: "cover" }}
                  alt="cardImage"
                />
              ) : null}
              <div className="row">
                {card.icon ? (
                  <DKIcon className="ic-s mr-l" src={card.icon} />
                ) : null}
                <DKLabel2 className="fw-m fs-xl mb-xs" text={card.title} />
              </div>
              {card.subTitle ? (
                <DKLabel2
                  style={{ lineHeight: "1.5" }}
                  className="text-gray fs-l"
                  text={card.subTitle}
                />
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CardGrid;
