import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Table from "../../services/table";
import { createSelector } from "reselect";
import { RootState } from "../store";
import { TableManger, TABLES } from "../../managers/TableManger";
export interface CounterState {
  data: any;
}
const initialState: CounterState = {
  data: {}
};
export const fetchTables = createAsyncThunk(
  "table/getTables",
  async (data, thunkAPI) => {
    const response = await Table.getAllViews();
    return response;
  }
);
export const fetchTableByName = createAsyncThunk(
  "table/getTableByName",
  async (data: any, thunkAPI) => {
    const tableId = TableManger.getTableId(data.tableName);
    const response = await Table.getTable(tableId);
    return response;
  }
);
function getFilteredTablesFromAction(action) {
  let data = {};
  action.payload.forEach((item: any) => {
    if (item.databaseName === "crm") {
      data[item.name] = item;

      if (item.name === TABLES.CONTACT) {
        data[TABLES.SEGMENT] = item;
      }
    }
  });
  return data;
}
export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    getTables: (state) => {
      return state.data;
    },
    setTables: (state, action: PayloadAction<any>) => {
      state.data = getFilteredTablesFromAction(action);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTables.fulfilled, (state, action) => {
      state.data = getFilteredTablesFromAction(action);
    });
    builder.addCase(fetchTableByName.fulfilled, (state, action) => {
      const tableName = action.meta.arg.tableName;
      state.data[tableName] = action.payload;

      if (tableName === TABLES.CONTACT) {
        state.data[TABLES.SEGMENT] = action.payload;
      }
    });
  }
});

export const { getTables, setTables } = tableSlice.actions;
const selfSelector = (state: RootState) => state;
export const getTableByNameSelector = (tableName: string) =>
  createSelector(
    selfSelector,
    (state: RootState) => state.table.data[tableName]
  );
export default tableSlice.reducer;
