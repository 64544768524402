import { Component, Fragment } from "react";
import ic_profile_pic from "../../assets/menu/ic_profile_pic.png";
import ic_scanner from "../../assets/mobile/ic_scanner.png";
import { DKButton, DKLabel, DKIcon, DKIcons } from "deskera-ui-library";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import NotificationPopup from "../notification/NotificationPopup";
import Tutorials from "./Tutorials";
import AddNewList from "./AddNewList";
import ProfileOptionsSection from "./ProfileOptionsSection";
import { PRODUCT_ICON } from "../common/Dashboard/DashboardCard";
import { TABLES } from "../../managers/TableManger";
import UserManager from "../../managers/UserManager";
import SideBarService from "../../services/sidebar";
import {
  isMobileAppWebView,
  isViewportLarge
} from "../../utility/GetViewportSize";
import Utility from "../../utility/Utility";
import {
  APP_TOP_MENU_HEIGHT,
  MOBILE_APP_ACTIONS,
  NEW_SETTINGS_SECTION,
  NOTIFICATIONS
} from "../../constants/Constant";
import { connect } from "react-redux";
import {
  setNewNotificationFlag,
  setSelectedSetting,
  setSettingPopup
} from "../../redux/slices/userPrefSlice";
import { isEqual } from "lodash";
import NotificationManager from "../../managers/NotificationManager";

import ic_startup from "../../assets/pricingSection/startup.svg";
import ic_essential from "../../assets/pricingSection/essential.svg";
import ic_professional from "../../assets/pricingSection/professional.svg";

import ApiConstants from "../../constants/ApiConstants";
import {
  AUTHORIZATION_STATUS,
  PLANS,
  PRODUCTS,
  USER_ROLE
} from "../../constants/Enum";
import { commonCustomEvent, COMMON_EVENTS } from "../../services/event";
import IAM from "../../services/iam";
import AppManager from "../../managers/AppManager";
import SubscriptionAlert from "../subscription/SubscriptionAlert";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import { fetchLoggedInUserRole } from "../../redux/slices/rolesPermissionSlice";
import { fetchUserInfo } from "../../redux/slices/tenantSlice";
import SettingView from "../settingPage/SettingView";
import { store } from "../../redux/store";

/*
- isOrgAvailable
*/
const ALL_THE_BUTTONS = [
  {
    title: "Contact",
    icon: PRODUCT_ICON[TABLES.CONTACT],
    tableName: TABLES.CONTACT
  },
  {
    title: "Deal",
    icon: PRODUCT_ICON[TABLES.DEAL],
    tableName: TABLES.DEAL
  },
  {
    title: "Form",
    icon: PRODUCT_ICON[TABLES.FORM],
    tableName: TABLES.FORM,
    route: PAGE_ROUTES.SELECT_FORM_TEMPLATE
  },
  {
    title: "Campaign",
    icon: PRODUCT_ICON[TABLES.CAMPAIGN],
    tableName: TABLES.CAMPAIGN,
    hasNestedPicker: true
  },
  {
    title: "Landing Page",
    icon: PRODUCT_ICON[TABLES.LANDING_PAGE],
    tableName: TABLES.LANDING_PAGE,
    route: PAGE_ROUTES.SELECT_LANDING_PAGE_TEMPLATE
  },
  /* {
    title: "Funnel",
    icon: PRODUCT_ICON[TABLES.FUNNEL],
    tableName: TABLES.FUNNEL,
    route: PAGE_ROUTES.FUNNEL_SELECTION,
  }, */
  {
    title: "Automation",
    icon: PRODUCT_ICON[TABLES.AUTOMATION],
    tableName: TABLES.AUTOMATION,
    route: PAGE_ROUTES.AUTOMATION_BUILDER
  },
  {
    title: "Activity",
    icon: PRODUCT_ICON[TABLES.ACTIVITY],
    tableName: TABLES.ACTIVITY
  }
];
class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      needNotificationPopup: false,
      needAddNewList: false,
      needTutorialPopup: false,
      needProfileOptionsSection: false,
      isDesktop: isViewportLarge(),
      subscriptionData: null
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.windowSizeUpdated);
    this.isNewNotificationReceived(this.props.notifications);

    if (!isMobileAppWebView()) {
      this.onWindowFocussed();
      AppManager.handleWindowFocusListeners(this.onWindowFocussed, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.windowSizeUpdated);
    if (!isMobileAppWebView()) {
      AppManager.handleWindowFocusListeners(this.onWindowFocussed, false);
    }
  }

  windowSizeUpdated = () => {
    this.setState({
      isDesktop: isViewportLarge()
    });
  };

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.notifications, this.props.notifications)) {
      this.isNewNotificationReceived(nextProps.notifications);
    }
  }

  render() {
    return !AppManager.isMobileAppURL() ? this.renderMenu() : null;
  }

  renderMenu() {
    const isNotificationUrl =
      window.location.href.split("/").pop() === NOTIFICATIONS;
    let list = this.getPermittedActionList();

    const { isDesktop } = this.state;
    return (
      <Fragment>
        <div
          className="parent-width row justify-content-between p-h-r p-v-s border-b-gray shadow-s flex-shrink-0"
          style={{
            backgroundColor: isMobileAppWebView() ? "#2C1F47" : "white",
            height: APP_TOP_MENU_HEIGHT,
            zIndex: 9
          }}
        >
          <div
            className="dk-sidebar-toggle p-v-s expandable-button cursor-hand z-index-4 column justify-content-center display-only-mobile"
            onClick={() => SideBarService.toggleSideBar()}
          >
            <div className="row">
              <DKIcon
                src={
                  isMobileAppWebView() ? DKIcons.white.ic_menu : DKIcons.ic_menu
                }
                className={`ic-s shadow-s-2`}
              />
              {isMobileAppWebView() && this.getTenantNameView()}
            </div>
          </div>
          <div className="row width-auto">{this.getNoCardView()}</div>
          <div className="row width-auto">
            {!isMobileAppWebView() && (
              <div className="position-relative">
                <DKButton
                  icon={DKIcons.ic_video}
                  title={isDesktop ? "Help videos" : ""}
                  className={`bg-gray1 border-m ml-r ${
                    isDesktop ? "" : "circle"
                  }`}
                  onClick={this.showTutorialPopup}
                />
                {this.state.needTutorialPopup && this.getTutorialsPopup()}
              </div>
            )}
            {isMobileAppWebView() && this.getMobileAppActions()}
            {!isMobileAppWebView() && list.length !== 0 && (
              <div className="position-relative">
                <DKButton
                  icon={DKIcons.white.ic_add}
                  title={isDesktop ? "New" : ""}
                  className={`bg-button text-white ml-m ${
                    isDesktop ? "" : "circle"
                  }`}
                  onClick={this.toggleAddNewList}
                />
                {this.state.needAddNewList && this.getAddNewList(list)}
              </div>
            )}
            {!isMobileAppWebView() && (
              <div className="position-relative ml-m">
                {this.props.isNewNotification && (
                  <div
                    className="position-absolute p-xs bg-red border-radius-m"
                    style={{ right: 0 }}
                  />
                )}
                <DKButton
                  icon={DKIcons.ic_notification}
                  title={""}
                  className={`bg-gray2 text-white circle`}
                  onClick={() => this.showNotificationPopup(isNotificationUrl)}
                />
                {this.state.needNotificationPopup &&
                  !isNotificationUrl &&
                  this.getNotificationPopup()}
              </div>
            )}
            {!isMobileAppWebView() && this.getProfileSection()}
          </div>
        </div>
        {this.getSubscriptionAlert()}
        {this.props.isSettingPopup && this.settingWindow()}
      </Fragment>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getProfileSection() {
    return (
      <div className=" position-relative ml-m">
        <div
          className="cursor-hand"
          onClick={() => {
            this.setState({ needProfileOptionsSection: true });
          }}
          style={{ opacity: 0.6 }}
        >
          <DKIcon src={ic_profile_pic} className="circle ic-r-3" />
        </div>

        {this.state.needProfileOptionsSection &&
          this.getProfileOptionsSection()}
      </div>
    );
  }

  getProfileOptionsSection() {
    return (
      <ProfileOptionsSection
        onClose={() => {
          setTimeout(() => {
            this.setState({ needProfileOptionsSection: false });
          }, 10);
        }}
      />
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getPermittedActionList() {
    const permittedActions = ALL_THE_BUTTONS.filter((action) => {
      const isAllowed =
        action.tableName !== TABLES.DEAL
          ? !!this.props.allPermissions?.[action.tableName]?.[
              USER_ACTION_TYPES.REC_CREATE
            ]
          : this.props.pipelinesStages?.data?.length > 0 &&
            !!this.props.allPermissions?.[action.tableName]?.[
              USER_ACTION_TYPES.REC_CREATE
            ];
      return isAllowed;
    });

    return permittedActions;
  }

  getAddNewList(list) {
    return <AddNewList onClose={() => this.hideAddNewList()} list={list} />;
  }

  toggleAddNewList = () => {
    this.setState({
      needAddNewList: !this.state.needAddNewList
    });
  };

  hideAddNewList = () => {
    setTimeout(() => {
      this.setState({ needAddNewList: false });
    }, 10);
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getTutorialsPopup() {
    return <Tutorials onClose={() => this.hideTutorialPopup()} />;
  }

  showTutorialPopup = () => {
    this.setState({
      needTutorialPopup: true
    });
  };

  hideTutorialPopup = () => {
    this.setState({
      needTutorialPopup: false
    });
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  onWindowFocussed = (e) => {
    if (isMobileAppWebView()) {
      return;
    }

    if (document.hidden || !AppManager.needSubscriptionApiCall) return;

    commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
      status: AUTHORIZATION_STATUS.PENDING
    });

    IAM.checkIfUserSubscribed()
      .then(
        () => {
          /* Calling once on focus Only */
          AppManager.needSubscriptionApiCall = false;

          /* For triggering Top menu Rerender in case alert not opened,
          If Subscription alert already opened,
          updated message will get showcased through custom event  */
          if (!this.state.subscriptionData?.needAlert) {
            this.setState({
              subscriptionData: null
            });
          }

          commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
            status: AUTHORIZATION_STATUS.AUTHORIZED
          });

          this.props.fetchLoggedInUserRole();

          /* Refreshing user role info, for checking updated product access on subscription */
          this.props
            .fetchUserInfo({ params: { id: UserManager.getUserID() } })
            .then((res) => this.checkProductAccessForUser(res?.payload));
        },
        () => {
          /* Need to Show Subscription alert */
          this.setState({
            subscriptionData: {
              customMessage: "",
              needAlert: true
            }
          });

          AppManager.needSubscriptionApiCall = true;

          commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
            status: AUTHORIZATION_STATUS.DENIED
          });
        }
      )
      .catch((err) => {
        AppManager.needSubscriptionApiCall = true;
        commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
          status: AUTHORIZATION_STATUS.DENIED
        });
      });
  };

  checkProductAccessForUser = (usersInfo = this.props.usersInfo) => {
    /* Checking if user has access to CRM+ product */
    const loggedInUserInfo = usersInfo?.content?.find(
      (user) => user.id === UserManager.getUserID()
    );

    const isOrgOwner =
      loggedInUserInfo?.roleShortInfo?.find(
        (role) => PRODUCTS.ORGANISATION === role.appName?.toLowerCase()
      )?.shortCode === USER_ROLE.OWNER.toLowerCase();

    if (
      isOrgOwner ||
      Utility.isEmptyObject(loggedInUserInfo) ||
      AppManager.needSubscriptionApiCall
    )
      return;

    if (!UserManager.hasProductAccess(loggedInUserInfo)) {
      this.props.history.push(PAGE_ROUTES.NO_ACCESS);
    }
  };

  getSubscriptionAlert() {
    return this.state.subscriptionData?.needAlert ? (
      <SubscriptionAlert
        customMessage={this.state.subscriptionData.customMessage || ""}
        onClose={() => {
          this.setState({
            subscriptionData: null
          });
        }}
      />
    ) : null;
  }

  settingWindow() {
    return (
      <SettingView
        onClose={() => {
          if (AppManager.isCurrentRoute(PAGE_ROUTES.SETTINGS)) {
            RouteManager.navigateToHome();
          }
          this.props.setSelectedSetting(
            NEW_SETTINGS_SECTION.ORGANISATION_SETTINGS
          );
          this.props.setSettingPopup(false);
        }}
        location={this.props.location}
      />
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////

  getPlanIcon(planName = "") {
    let iconSrc = null;
    switch (planName.toLowerCase()) {
      case PLANS.STARTUP:
        iconSrc = ic_startup;
        break;
      case PLANS.ESSENTIAL:
        iconSrc = ic_essential;
        break;
      case PLANS.PROFESSIONAL:
        iconSrc = ic_professional;
        break;
      default:
    }

    return iconSrc;
  }

  getPlanBadgeStatus(isPlanActive) {
    return isPlanActive ? "Active" : "Expired";
  }

  getNoCardView() {
    let helloKeywords = [
      "Hi",
      "Hello",
      "Hey",
      "Hola",
      "Bonjour",
      "Salve",
      "Nǐn hǎo",
      "Namaste"
    ];
    let hey = helloKeywords[Math.floor(Math.random() * helloKeywords.length)];
    // let message = `👋 ${hey}! We will need your payment details for uninterrupted access 🙂`;

    //${hey}!
    // let message = `<span style="font-size: 16px">👋</span> Welcome <b>${UserManager.getUserName()}</b>`;

    const userPlan = {
      name: "",
      icon: "",
      inTrial: UserManager.isTrialPlanActive(),
      isActive: UserManager.isPlanActive(),
      trialDays: 0
    };

    if (userPlan.inTrial) {
      userPlan.trialDays = UserManager.getTrialDays(true);
    } else if (userPlan.isActive) {
      userPlan.name = UserManager.getUserPlanName();
      userPlan.icon = this.getPlanIcon(userPlan.name);
    }
    const loggedInUserInfo = this.props?.usersInfo?.content?.find(
      (user) => user.id === UserManager.getUserID()
    );
    return (
      <div className=" display-only-web border-ss p-h-s border-radius-m">
        <div
          className="row align-items-center border-box"
          style={{ paddingBottom: 2 }}
        >
          {loggedInUserInfo && (
            <>
              <DKLabel text={"👋"} className="fs-l" />
              <DKLabel text={" Welcome "} />
              <DKLabel
                text={`${loggedInUserInfo?.firstName || ""} ${
                  loggedInUserInfo?.lastName || ""
                }`}
                className="fw-b"
              />
            </>
          )}
          {userPlan.inTrial ? (
            <Fragment>
              <DKLabel
                text={`, Your trial will end ${
                  userPlan.trialDays ? `in ${userPlan.trialDays} days` : `today`
                }, `}
                className="fw-m text-red"
              />
              <DKButton
                title={`subscribe now`}
                className="fw-m text-blue text-underline"
                style={{ padding: "0 2px" }}
                onClick={() => {
                  AppManager.needSubscriptionApiCall = true;
                  window.open(ApiConstants.PRODUCT_URL_GO + "billing");
                }}
              />
            </Fragment>
          ) : userPlan.name ? (
            <Fragment>
              <DKLabel text=", you are on " />
              <DKButton
                title={`${userPlan.name} Plan`}
                className="fw-m text-blue text-underline"
                style={{ padding: "0 2px" }}
                onClick={() => {
                  AppManager.needSubscriptionApiCall = true;
                  window.open(ApiConstants.PRODUCT_URL_GO + "billing");
                }}
              />
              <DKIcon src={userPlan.icon} className="ic-s-2 pl-xs" />
              <DKLabel
                text={this.getPlanBadgeStatus(userPlan.isActive)}
                className={`${
                  userPlan.isActive
                    ? "bg-chip-green border-green text-green"
                    : "bg-chip-red border-red text-red"
                } fw-m border-radius-r ml-s p-h-s`}
              />
            </Fragment>
          ) : null}
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  settingsTapped = () => {
    this.props.setSettingPopup(true);
    this.props.setSelectedSetting(NEW_SETTINGS_SECTION.ORGANISATION_SETTINGS);
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getNotificationPopup = () => {
    return (
      <div
        id="notification-section"
        className="notification-popup position-absolute bg-white pl-m pr-m pb-xl pt-s border-radius-s shadow-m border-m"
      >
        <NotificationPopup onClose={this.hideNotificationPopup} />
      </div>
    );
  };

  showNotificationPopup = (isNotificationUrl) => {
    this.setState({ needNotificationPopup: isNotificationUrl ? false : true });
  };

  hideNotificationPopup = () => {
    setTimeout(() => {
      this.setState({ needNotificationPopup: false });
    }, 10);
  };
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getTenantNameView() {
    return (
      <DKLabel
        className="fw-m fs-l p-h-m text-white"
        style={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxWidth: "160px"
        }}
        text={UserManager.getUserTenantName()}
      />
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getMobileAppActions() {
    return (
      <div className="row position-relative ml-m">
        <DKButton
          icon={DKIcons.ic_home}
          className={"mr-m circle bg-white"}
          onClick={() =>
            RouteManager.navigateToPage(PAGE_ROUTES.MOBILE_APP_LANDING)
          }
        />
        <DKButton
          icon={ic_scanner}
          className={"circle bg-white"}
          onClick={() =>
            Utility.postMobileAppActions(MOBILE_APP_ACTIONS.SCANNER)
          }
        />
      </div>
    );
  }

  isNewNotificationReceived = (notifications) => {
    NotificationManager.onNewNotification(notifications);
    if (notifications?.data) {
      const isNewNotification = notifications?.data.some(
        (notification) =>
          !notification.seenBy?.includes(UserManager.getUserIamID())
      );
      this.props.setNewNotificationFlag(isNewNotification);
    }
  };
}

const mapStateToProps = (state) => ({
  isNewNotification: state.userPref.isNewNotification,
  notifications: state.userPref.notifications,
  usersInfo: state.tenant.usersInfo,
  allPermissions: state.rolesPermission?.currentUserModuleWisePermissions || {},
  isSettingPopup: state.userPref.isSettingPopup,
  pipelinesStages: state.records.currentData.pipelinesStages
});
const mapDispatchToProps = {
  setNewNotificationFlag,
  fetchLoggedInUserRole,
  fetchUserInfo,
  setSettingPopup,
  setSelectedSetting
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
