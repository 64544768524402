import React, { Component, createRef } from "react";
import {
  CHAR_CODES,
  PLACEHOLDER_IDENTIFIER
} from "../../../constants/Constant";
import {
  pageDesignerCustomEvent,
  PAGE_DESIGNER_EVENTS
} from "../../../services/event";
import {
  getRangeDataToSave,
  replaceSelectionWithContent,
  restoreSelectionFromSavedRange
} from "../../../utility/TextSelection";
import { getInnerPlaceholderHTML } from "./PlaceholderButton";

/*
PROPS
- data
- index
- borderStyle
- inlineEditing
*/
class Text extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inlineEditing: this.props.inlineEditing
        ? this.props.inlineEditing
        : false,
      selectedRange: null
    };

    this.textComponentRef = createRef();
  }
  componentDidMount() {
    pageDesignerCustomEvent.on(
      PAGE_DESIGNER_EVENTS.PLACEHOLDER_SELECTED,
      this.onPlaceholderSelected
    );
  }
  componentWillUnmount() {
    this.setState({ inlineEditing: false });
    pageDesignerCustomEvent.remove(
      PAGE_DESIGNER_EVENTS.PLACEHOLDER_SELECTED,
      this.onPlaceholderSelected
    );
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.inlineEditing !== nextProps.inlineEditing) {
      this.setState({ inlineEditing: nextProps.inlineEditing }, () => {
        if (nextProps.inlineEditing === true) {
          this.setTextBoxSelection();
        }
      });
    }
  }
  insertKeyForPlaceholders = () => {
    const allCustomPlaceholderFields =
      this.textComponentRef?.current?.querySelectorAll(
        `.${PLACEHOLDER_IDENTIFIER}`
      );
    allCustomPlaceholderFields?.forEach((placeholderField) => {
      placeholderField.innerHTML = getInnerPlaceholderHTML(
        placeholderField.dataset.id,
        placeholderField.dataset.name
      );
    });
  };
  enableInlineTextEdit = (selectedRange = null) => {
    this.props.enableInlineTextEdit();
    this.setState({ inlineEditing: true, selectedRange: selectedRange }, () => {
      this.setTextBoxSelection();
    });
  };
  getNewTextElementToInsert = (isLineBreak = false) => {
    const lineBreakSpan = document.createElement("span");
    lineBreakSpan.innerHTML = isLineBreak ? `</br>&#xFEFF;` : `&#xFEFF;`;
    return lineBreakSpan;
  };
  handleKeyboardInteractions = (e) => {
    try {
      if (e.shiftKey || e.ctrlKey) return;

      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      if (!range.startContainer) return;

      const closestCustomPlaceholder = range.startContainer.closest
        ? range.startContainer.closest(`.${PLACEHOLDER_IDENTIFIER}`)
        : range.startContainer.parentElement?.closest?.(
            `.${PLACEHOLDER_IDENTIFIER}`
          );
      switch (e.keyCode) {
        case CHAR_CODES.LEFT_ARROW:
          if (
            closestCustomPlaceholder &&
            range.collapsed &&
            range.startOffset > 1
          ) {
            const newRange = document.createRange();
            if (closestCustomPlaceholder.previousSibling) {
              newRange.selectNodeContents(
                closestCustomPlaceholder.previousSibling
              );
              newRange.collapse(false);
            } else {
              newRange.selectNodeContents(closestCustomPlaceholder);
              newRange.collapse(true);
            }

            selection.removeAllRanges();
            selection.addRange(newRange);
            e.preventDefault();
          }
          break;
        case CHAR_CODES.RIGHT_ARROW:
          if (
            closestCustomPlaceholder &&
            range.collapsed &&
            range.startOffset <= 1
          ) {
            const newRange = document.createRange();

            if (closestCustomPlaceholder.nextSibling) {
              newRange.selectNodeContents(closestCustomPlaceholder.nextSibling);
              newRange.collapse(true);
            } else {
              newRange.selectNodeContents(closestCustomPlaceholder);
              newRange.collapse(false);
            }

            selection.removeAllRanges();
            selection.addRange(newRange);
            e.preventDefault();
          }
          break;
        case CHAR_CODES.DELETE:
          if (
            closestCustomPlaceholder &&
            range.collapsed &&
            range.startOffset === 0
          ) {
            closestCustomPlaceholder.remove();
            e.preventDefault();
          }
          break;
        case CHAR_CODES.BACKSPACE:
          if (
            closestCustomPlaceholder &&
            range.collapsed &&
            range.startOffset !== 0
          ) {
            closestCustomPlaceholder.remove();
            e.preventDefault();
          }
          break;
        case CHAR_CODES.ENTER:
          if (closestCustomPlaceholder) {
            const insertAfterEnd = range.collapsed && range.startOffset > 0;
            const startNode =
              (insertAfterEnd
                ? closestCustomPlaceholder.nextSibling
                : closestCustomPlaceholder.previousSibling) ||
              closestCustomPlaceholder.parentElement;
            const endNode =
              (insertAfterEnd
                ? closestCustomPlaceholder.nextSibling
                : closestCustomPlaceholder.previousSibling) ||
              closestCustomPlaceholder.parentElement;
            range.setStart(startNode, 0);
            range.setEnd(endNode, endNode?.textContent?.length);
            range.collapse(insertAfterEnd ? true : false);
            selection.removeAllRanges();
            selection.addRange(range);
          }

          const newRange = replaceSelectionWithContent(
            this.getNewTextElementToInsert(true),
            this.textComponentRef.current
          );
          this.setState({ selectedRange: newRange });

          e.preventDefault();
          break;
        case CHAR_CODES.TOP_ARROW:
          break;
        case CHAR_CODES.DOWN_ARROW:
          break;
        default:
          /* Prevent typing inside custom placeholder */
          if (closestCustomPlaceholder) {
            e.preventDefault();
          }
      }
    } catch (err) {}
  };
  handleComponentSelections = (e) => {
    const selection = window.getSelection();
    let range = selection.rangeCount ? selection.getRangeAt(0) : null;
    const textBox = this.textComponentRef.current;

    if (range && textBox) {
      range = getRangeDataToSave(range, textBox);
    } else {
      range = null;
    }

    this.setState({ selectedRange: range });
  };
  render() {
    return (
      <div
        id={`textBox-${this.getTextComponentId()}`}
        ref={this.textComponentRef}
        contentEditable={this.state.inlineEditing}
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle
        }}
        dangerouslySetInnerHTML={{ __html: this.props.data.value }}
        onDoubleClick={() => {
          if (!this.props.isEditing || this.state.inlineEditing) {
            return;
          }
          this.enableInlineTextEdit();
        }}
        onClick={(e) => {
          if (this.state.inlineEditing) {
            e.stopPropagation();
          }
        }}
        onBlur={(e) => {
          e.preventDefault();
          this.setState({ inlineEditing: false });
          let index = null,
            columnIndex = null,
            colComponentIndex = null;
          if (
            this.props.columnIndex !== null &&
            this.props.columnIndex !== undefined
          ) {
            if (this.props.colComponentIndex !== undefined) {
              index = this.props.componentIndex;
              columnIndex = this.props.columnIndex;
              colComponentIndex = this.props.colComponentIndex;
            } else {
              index = this.props.index;
              columnIndex = this.props.columnIndex;
              colComponentIndex = null;
            }
          } else {
            index = this.props.index;
          }
          this.insertKeyForPlaceholders();
          this.props.onTextChange(
            e.target.innerHTML,
            index,
            columnIndex,
            colComponentIndex
          );
          this.props.onCloseInlineEdit();
        }}
        onKeyDown={this.handleKeyboardInteractions}
        onPaste={(e) => {
          try {
            const htmlData = e.clipboardData.getData("text/html");
            /**
             * If no custom placeholder field present in selected text,
             * then paste as a plain text.
             */
            if (!htmlData?.includes?.(PLACEHOLDER_IDENTIFIER)) {
              e.preventDefault();
              const textContent = e.clipboardData.getData("text/plain");
              // document.execCommand("insertHTML", false, text);
              replaceSelectionWithContent(
                document.execCommand("insertText", false, textContent)
              );
            }
          } catch (err) {}
        }}
        onSelect={this.handleComponentSelections}
      />
    );
  }

  setTextBoxSelection = () => {
    restoreSelectionFromSavedRange(
      this.state.selectedRange,
      this.textComponentRef.current
    );
  };

  getTextComponentId = (componentData = this.props) => {
    const {
      index,
      componentIndex,
      columnIndex,
      colComponentIndex,
      textCompType
    } = componentData;
    let id = "";
    if (columnIndex !== null && columnIndex !== undefined) {
      if (colComponentIndex !== null && colComponentIndex !== undefined) {
        id = componentIndex + "-" + columnIndex + "-" + colComponentIndex;
      } else {
        id = index + "-" + columnIndex;
      }

      id += textCompType || "";
    } else {
      id = index;
    }
    return id;
  };
  onPlaceholderSelected = (eventData) => {
    const { componentData, placeholderData } = eventData?.detail;

    if (this.getTextComponentId() !== this.getTextComponentId(componentData))
      return;

    const placeholderContainer = document.createElement("span");
    const placeholder = document.createElement("span");
    placeholder.className = PLACEHOLDER_IDENTIFIER;
    placeholder.dataset.id = placeholderData.id;
    placeholder.dataset.name = placeholderData.name;
    placeholder.innerHTML = getInnerPlaceholderHTML(
      placeholderData.id,
      placeholderData.name
    );

    placeholderContainer.appendChild(this.getNewTextElementToInsert());
    placeholderContainer.appendChild(placeholder);
    placeholderContainer.appendChild(this.getNewTextElementToInsert());
    this.setTextBoxSelection();
    const selectedRange = replaceSelectionWithContent(
      placeholderContainer,
      this.textComponentRef.current
    );
    this.enableInlineTextEdit(selectedRange);
  };
}

export default Text;
