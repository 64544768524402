import React, { Component } from "react";
import {
  DKLabel,
  isEmpty,
  getDateAsString,
  getDateByAddingDays
} from "deskera-ui-library";
import {
  COLUMN_CODE,
  TableManger,
  TABLES
} from "../../../managers/TableManger";
import { isEqual } from "lodash";

import { getDashboardWidgetLoading } from "../../dashboard/Dashboard";
import { connect, ConnectedProps } from "react-redux";
import { addRecord, setRecords } from "../../../redux/slices/recordSlice";
import { store } from "../../../redux/store";
import { fetchRecordsByTable } from "../../../redux/slices/recordSlice";
import GraphManager from "../../../managers/GraphManager";
import { showActivityDetailsPopup } from "../ActivityDetailsPopup";
import ActivityManager from "../../../managers/ActivityManager";
import AppManager from "../../../managers/AppManager";
import TableDataParser from "../../../Helper/TableDataParser";
import {
  ACTIVITY_STATUS_CODE,
  FILTER_OPERATORS
} from "../../../constants/Enum";
import { IColumnOption, Record } from "../../../model/Table";
import Utility from "../../../utility/Utility";
import { DateUtil } from "../../../utility/Date";
import { fetchCRMSettings } from "../../../redux/slices/tenantSlice";

interface IActivityCardProps extends PropsFromRedux {
  selectedFilter: { name: string; interval: string };
  onCountUpdated: (count: number) => void;
  needRefresh: number;
}
interface IActivityCardState {
  activities: Record[];
  showLoading: boolean;
  selectedFilter: { name: string; interval: string };
}
class ActivityCard extends Component<IActivityCardProps, IActivityCardState> {
  status: IColumnOption[] = [];
  columnsIds = {
    name: "",
    status: "",
    dueDate: "",
    startDate: ""
  };
  constructor(props: IActivityCardProps) {
    super(props);
    this.state = {
      activities: [],
      showLoading: false,
      selectedFilter: GraphManager.get("activity").filter
    };

    this.setColumnsIds();
    this.setStatusOptions();
  }
  componentDidMount() {
    if (Utility.isEmptyObject(this.props?.crmSettings)) {
      store.dispatch(fetchCRMSettings({}));
    }
    if (
      !Utility.isEmptyObject(this.state.selectedFilter) &&
      !Utility.isEmptyObject(this.state.activities)
    ) {
      this.setState({ showLoading: true });
    }

    this.fetchRecords(true);
  }
  UNSAFE_componentWillReceiveProps(nextProps: IActivityCardProps) {
    /* need shallow check only */
    if (nextProps.data !== this.props.data) {
      this.onDataReceived(nextProps.data);
    }

    if (!isEqual(nextProps.selectedFilter, this.state.selectedFilter)) {
      this.setState(
        { showLoading: true, selectedFilter: nextProps.selectedFilter },
        () => this.fetchRecords(true)
      );
    }

    if (
      nextProps.needRefresh &&
      nextProps.needRefresh !== this.props.needRefresh
    ) {
      this.setState({ showLoading: true }, () => this.fetchRecords(true));
    }
  }

  setColumnsIds = () => {
    this.columnsIds.name = TableManger.getColumnId(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.NAME
    );
    this.columnsIds.status = TableManger.getColumnId(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.STATUS
    );
    this.columnsIds.dueDate = TableManger.getColumnId(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.END_DATE
    );
    this.columnsIds.startDate = TableManger.getColumnId(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.START_DATE
    );
  };

  setStatusOptions = () => {
    this.status = TableManger.getColumn(
      TABLES.ACTIVITY,
      COLUMN_CODE.ACTIVITY.STATUS
    ).options;
  };

  getActivitySortParams = () => {
    const { interval } = this.state.selectedFilter;
    switch (interval) {
      case "overdue":
      case "upcoming":
        return {
          sortCol: this.columnsIds.dueDate,
          sortDir: 1
        };
      case "today":
        return {
          sortCol: this.columnsIds.startDate,
          sortDir: 1
        };
      default:
        return {};
    }
  };
  getActivityFilterPayload = () => {
    const { interval } = this.state.selectedFilter;
    switch (interval) {
      case "overdue":
        return TableDataParser.getModifiedFilterPayload(
          TableManger.getFilterObject([
            {
              key: this.columnsIds.status,
              condition: FILTER_OPERATORS.CONTAINS,
              value: [ACTIVITY_STATUS_CODE.OVERDUE]
            }
          ]),
          ActivityManager.getFilterableColumns()
        );
      case "upcoming":
        return TableDataParser.getModifiedFilterPayload(
          TableManger.getFilterObject([
            {
              key: this.columnsIds.dueDate,
              condition: FILTER_OPERATORS.GREATER_THAN_OR_EQUAL,
              value: new Date()
            },
            {
              key: this.columnsIds.status,
              condition: FILTER_OPERATORS.CONTAINS,
              value: [ACTIVITY_STATUS_CODE.PENDING]
            }
          ]),
          ActivityManager.getFilterableColumns()
        );
      case "today":
        return TableDataParser.getModifiedFilterPayload(
          TableManger.getFilterObject([
            {
              key: this.columnsIds.startDate,
              condition: FILTER_OPERATORS.GREATER_THAN_OR_EQUAL,
              value: DateUtil.getDateString(new Date())
            },
            {
              key: this.columnsIds.dueDate,
              condition: FILTER_OPERATORS.LESS_THAN,
              value: DateUtil.getDateString(getDateByAddingDays(1))
            },
            {
              key: this.columnsIds.status,
              condition: FILTER_OPERATORS.CONTAINS,
              value: [ACTIVITY_STATUS_CODE.PENDING]
            }
          ]),
          ActivityManager.getFilterableColumns()
        );
      case "completed":
        return TableDataParser.getModifiedFilterPayload(
          TableManger.getFilterObject([
            {
              key: this.columnsIds.status,
              condition: FILTER_OPERATORS.CONTAINS,
              value: [ACTIVITY_STATUS_CODE.COMPLETED]
            }
          ]),
          ActivityManager.getFilterableColumns()
        );
      default:
    }

    return null;
  };

  fetchRecords(refresh = false) {
    let data = this.props.data;
    if (refresh || isEmpty(data)) {
      const sortParams = this.getActivitySortParams();
      const filterPayload = this.getActivityFilterPayload();
      store
        .dispatch(
          fetchRecordsByTable({
            tableName: TABLES.ACTIVITY,
            payload: filterPayload,
            params: sortParams || {}
          })
        )
        .then((response) => {
          TableDataParser.checkAndReplaceActivityData(
            response.payload && response.payload["data"],
            this.props.users
          );
        });
    } else {
      this.onDataReceived(data);
    }
  }

  onDataReceived = (activities: Record[]) => {
    if (Utility.isEmptyObject(activities)) activities = [];

    this.props.onCountUpdated?.(activities.length);
    this.setState({
      activities: activities,
      showLoading: false
    });
  };

  showActivityDetailPopup = (activity: Record) => {
    let activityFormatted = ActivityManager.transformActivityData([activity]);
    showActivityDetailsPopup({
      selectedActivity: activityFormatted?.activitylist[0],
      onSubmit: () => {
        this.fetchRecords(true);
      }
    });
  };

  isActivityOverdue = (activity: Record) => {
    return (
      activity.cells[this.columnsIds.status].includes(2) &&
      new Date(activity.cells[this.columnsIds.dueDate]) < new Date()
    );
  };

  /* ****************** RENDERERS ***************** */
  getStatusBadge = (activity: Record) => {
    const activityStatusId = activity.cells[this.columnsIds.status]?.[0];
    let activityStatus =
      this.status.find((status) => status.id === activityStatusId)?.name ||
      "Pending";

    if (this.isActivityOverdue(activity)) {
      activityStatus = "Overdue";
    }

    return (
      <div
        className={`${
          activityStatus === "Completed"
            ? "text-green bg-chip-green"
            : "text-red bg-chip-red"
        } fs-r p-v-xs p-h-s border-radius-r`}
      >
        {activityStatus}
      </div>
    );
  };

  getRow(activity: Record) {
    return (
      <div
        className="row pt-xs pb-s justify-content-between listPickerBG border-radius-s cursor-hand"
        onClick={() => this.showActivityDetailPopup(activity)}
      >
        <div className="row" style={{ width: "73%" }}>
          <div className="column parent-width">
            <DKLabel
              text={`${activity.cells[this.columnsIds.name]}`}
              className="fw-m parent-width text-ellipsis"
            />
            <DKLabel
              text={`Due on ${DateUtil.getDateStrFromDate(
                activity.cells[this.columnsIds.dueDate],
                this.props.crmSettings?.dateFormat || "DD-MM-YYYY"
              )}`}
              className="text-gray mt-xs mr-s"
            />
          </div>
        </div>
        <div className="column">
          {!Utility.isEmptyObject(activity.cells[this.columnsIds.status]) &&
            this.getStatusBadge(activity)}
        </div>
      </div>
    );
  }

  getEmptyState = () => {
    return (
      <div className="column parent-width parent-height align-items-center justify-content-center">
        <DKLabel text="No data found" className="fw-m fs-m text-gray" />
        <DKLabel
          text="Create your first activity now"
          className="text-gray mt-xs"
        />
      </div>
    );
  };

  render() {
    const { showLoading, activities } = this.state;

    return (
      <>
        {showLoading && getDashboardWidgetLoading()}
        {!showLoading &&
          activities.length > 0 &&
          activities.map((record) => this.getRow(record))}
        {!showLoading && activities.length === 0 && this.getEmptyState()}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.records.data[TABLES.ACTIVITY]?.data,
  users: state.tenant.users,
  crmSettings: state.tenant.crmSettings
});
const mapDispatchToProps = { addRecord, setRecords, fetchCRMSettings };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ActivityCard);
