import { getAmountFormattedToPrint } from "../constants/Currencies";
import { wholeNumberWithCommas } from "../utility/Utility";

export class InventoryItem {
  Name: string;
  Desc: string;
  Quant: any;
  unitPrice: string;
  cgst: string;
  sgst: string;
  igst: string;
  cess: string;
  Disc: string;
  Tax: string;
  Total: string;
  constructor(props: any, currency: string) {
    this.Name = this.nullCheckValue(props.productName || props.product?.name);
    this.Desc = this.nullCheckValue(props.productDescription);
    this.Quant = this.nullCheckValue(
      wholeNumberWithCommas(props.productQuantity).toString()
    );
    this.unitPrice = this.nullCheckValue(
      getAmountFormattedToPrint(currency, props.unitPrice)
    );
    this.cgst = getAmountFormattedToPrint(currency, props.cgstAmount);
    this.sgst = getAmountFormattedToPrint(currency, props.sgstAmount);
    this.igst = getAmountFormattedToPrint(currency, props.igstAmount);
    this.cess = getAmountFormattedToPrint(currency, props.cessAmount);
    this.Disc = props.discount
      ? this.nullCheckValue(
          getAmountFormattedToPrint(
            currency,
            props.discount,
            props.discountInPercent
          )
        )
      : currency + " " + Number(props.discount).toFixed(2);
    this.Tax = this.nullCheckValue(
      getAmountFormattedToPrint(currency, props.taxAmount)
    );
    this.Total = this.nullCheckValue(
      getAmountFormattedToPrint(currency, props.totalAmount)
    );
  }

  nullCheckValue(value: any): any {
    return value ? value : "";
  }
}
