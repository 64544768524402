import React, { Component } from "react";
import Image from "./Image";
import ComponentManager from "../ComponentManager";
import Text from "./Text";
import Button from "./Button";
import { DKLabel } from "deskera-ui-library";
import { PAGE_ELEMENT, PAGE_SIZE } from "../ComponentList";

/*
PROPS
- data
- borderStyle
*/

class Collection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inlineEditing: false,
      selectedImageIndex: 0,
      selectedCollectionIndex: null,
      selectedTextBox: this.props.currentInnerComponentType || "",
      selectedTypeBox: this.props.currentInnerComponentType || ""
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedTextBox: nextProps.currentInnerComponentType || "",
      selectedTypeBox: nextProps.currentInnerComponentType || ""
    });
  }

  render() {
    return (
      <div
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle,
          borderSpacing: "" + this.props.data.style.cellSpacing + "px 0px",
          maxWidth: "100%"
        }}
        className="collections"
      >
        {this.getCollectionItems(this.props.data.value, this.props.data.style)}
      </div>
    );
  }

  getCollectionItems(items, style) {
    let arr = [];
    items.forEach((item, index) => {
      arr.push(this.getCollectionItem(index, item, style));
    });
    if (arr.length === 0) {
      arr.push(this.getBlankSection());
    }
    return arr;
  }

  getCollectionItem(index, data, style) {
    let width =
      this.props.viewType === PAGE_SIZE.PHONE
        ? 100
        : style.gridSize === undefined
          ? PAGE_ELEMENT.ICON === this.props.data.type
            ? 90
            : 23
          : style.gridSize;
    let margin =
      this.props.viewType === PAGE_SIZE.PHONE
        ? style.cellSpacing + "px auto"
        : style.cellSpacing;
    return (
      <div
        id={`collection-container-${this.props.index + "-" + index}`}
        key={index}
        className="collection-list mobile-view mobile-margin-x-auto"
        style={{
          width: width + "%",
          margin: margin,
          display: "inline-block",
          wordBreak: "break-word",
          verticalAlign: "top",
          overflow: "hidden"
        }}
        // onMouseLeave={(e) => {
        //   this.setState({ inlineEditing: false, needToShowImagePopup: false });
        //   e.stopPropagation();
        // }}
      >
        <div
          style={{
            width: style.iconSize,
            display: "flex",
            flexDirection: "row",
            marginTop:
              data.icon && data.icon.style ? data.icon.style.marginTop : "auto",
            marginRight:
              data.icon && data.icon.style
                ? data.icon.style.marginRight
                : "auto",
            marginLeft:
              data.icon && data.icon.style
                ? data.icon.style.marginLeft
                : "auto",
            marginBottom:
              data.icon && data.icon.style
                ? data.icon.style.marginBottom
                : "auto"
          }}
          onDoubleClick={() => {
            this.closeInlineEdit();
            this.closeImagePopup();
            this.props.onComponentSelect(
              this.props.index,
              index,
              false,
              "icon"
            );
            this.setState({
              needToShowImagePopup: true,
              selectedTypeBox: "icon",
              selectedCollectionIndex: index
            });
          }}
          onClick={() => {
            this.closeInlineEdit();
            this.closeImagePopup();
            this.props.onComponentSelect(
              this.props.index,
              index,
              false,
              "icon"
            );
            this.setState({
              selectedTypeBox: "icon",
              selectedCollectionIndex: index
            });
          }}
        >
          <Image
            style={{
              width: "100%",
              borderRadius: style.iconRadius
            }}
            data={data.icon}
            index={this.props.index}
            columnIndex={index}
            borderStyle={ComponentManager.getCurrentComponentBorder(
              this.props.currentComponentIndex +
                "-" +
                this.state.selectedCollectionIndex +
                "-" +
                this.state.selectedTypeBox,
              this.props.index + "-" + index + "-icon",
              this.props.isEditing
            )}
            isEditing={this.props.isEditing}
            showImagePopup={this.editImageCall(this.props.index, index)}
            onClosePopUp={this.closeImagePopup}
            onValueChange={(value, index, colIndex) => {
              this.closeImagePopup();
              this.onValueChange(value, index, colIndex, "icon");
            }}
          />
        </div>
        {data.title && this.getText(data.title, index, "title")}
        {data.description &&
          this.getText(data.description, index, "description")}
        {data.button &&
          data.button.visibility &&
          this.getButton(data.button, index)}
      </div>
    );
  }

  getButton = (data, index) => {
    return (
      <div
        id={`dk-collection-${this.props.index + "-" + index}-button`}
        draggable={false}
        onClick={() => {
          this.closeInlineEdit();
          this.closeImagePopup();
          this.props.onComponentSelect(
            this.props.index,
            index,
            false,
            "button"
          );
          this.setState({
            selectedTypeBox: "button",
            selectedCollectionIndex: index
          });
        }}
        onMouseDown={
          this.props.isEditing
            ? () => this.props.onMouseDown(index, "button")
            : null
        }
        style={{ display: "flex", flexDirection: "row" }}
      >
        <Button
          data={data}
          index={this.props.index}
          columnIndex={index}
          style={{ display: "inline-block" }}
          isEditing={this.props.isEditing}
          borderStyle={ComponentManager.getCurrentComponentBorder(
            this.props.currentComponentIndex +
              "-" +
              this.state.selectedCollectionIndex +
              "-" +
              this.state.selectedTypeBox,
            this.props.index + "-" + index + "-button",
            this.props.isEditing
          )}
          onTextChange={(value, index, colIndex) => {
            this.closeInlineEdit();
            this.onValueChange(value, index, colIndex, "button");
          }}
        />
      </div>
    );
  };

  getText(data, index, type) {
    return (
      <div
        id={`dk-collection-${this.props.index + "-" + index}-${type}`}
        draggable={false}
        onDoubleClick={() => {
          this.closeInlineEdit();
          this.closeImagePopup();
          this.props.onComponentSelect(this.props.index, index, false, type);
          this.setState({
            inlineEditing: true,
            selectedTextBox: type,
            selectedTypeBox: type,
            selectedCollectionIndex: index
          });
        }}
        onClick={() => {
          this.closeInlineEdit();
          this.closeImagePopup();
          this.props.onComponentSelect(this.props.index, index, false, type);
          this.setState({
            selectedTypeBox: type,
            selectedCollectionIndex: index
          });
        }}
        onMouseDown={
          this.props.isEditing
            ? () => this.props.onMouseDown(index, type)
            : null
        }
      >
        <Text
          data={data}
          textCompType={type}
          index={this.props.index}
          columnIndex={index}
          isEditing={this.props.isEditing}
          borderStyle={ComponentManager.getCurrentComponentBorder(
            this.props.currentComponentIndex +
              "-" +
              this.state.selectedCollectionIndex +
              "-" +
              this.state.selectedTypeBox,
            this.props.index + "-" + index + "-" + type,
            this.props.isEditing
          )}
          inlineEditing={this.editCall(this.props.index, index, type)}
          onCloseInlineEdit={() => {
            if (
              this.state.inlineEditing &&
              this.state.selectedCollectionIndex === null
            )
              this.closeInlineEdit();
          }}
          enableInlineTextEdit={() => {}}
          onTextChange={(value, index, colIndex) => {
            this.onValueChange(value, index, colIndex, type);
          }}
        />
      </div>
    );
  }

  closeInlineEdit = () => {
    this.setState({ inlineEditing: false });
  };

  closeImagePopup = () => {
    this.setState({ needToShowImagePopup: false });
  };

  editCall = (index, colIndex, type) => {
    if (
      this.props.currentComponentIndex !== null &&
      this.props.currentComponentIndex !== undefined &&
      this.state.selectedCollectionIndex !== null &&
      this.state.selectedCollectionIndex !== undefined
    ) {
      if (
        this.props.isEditing &&
        index === this.props.currentComponentIndex &&
        this.state.inlineEditing &&
        colIndex === this.state.selectedCollectionIndex &&
        type === this.state.selectedTextBox
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  editImageCall = (index, colIndex) => {
    if (
      this.props.currentComponentIndex !== null &&
      this.props.currentComponentIndex !== undefined &&
      this.state.selectedCollectionIndex !== null &&
      this.state.selectedCollectionIndex !== undefined
    ) {
      if (
        this.props.isEditing &&
        index === this.props.currentComponentIndex &&
        this.state.needToShowImagePopup &&
        colIndex === this.state.selectedCollectionIndex
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  onValueChange = (value, index, colIndex, type) => {
    // if (index !== null && colIndex !== null && colIndex === this.state.selectedCollectionIndex) {
    this.props.onValueChange(value, index, colIndex, type);
    // }
  };

  getBlankSection = () => {
    return (
      <div
        className="column parent-width justify-content-center align-items-center "
        style={{ minHeight: 50, border: "1px dashed #80808094" }}
      >
        <DKLabel text="Please add card" className="text-gray" />
      </div>
    );
  };
}

export default Collection;
