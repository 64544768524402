import ContactService from "../../../services/contact";
import FormService from "../../../services/form";
import { getRandomAlphaNumericString } from "../../../utility/Utility";
import { PAGE_ELEMENT } from "../../page_designer/ComponentList";
import ComponentManager from "../../page_designer/ComponentManager";

export default class Template5 {
  static getData() {
    return {
      metaData: null,
      scripts: null,
      hash: getRandomAlphaNumericString(),
      designElements: [
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625738791907_logo.png",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 14,
            paddingRight: 0,
            marginTop: 22,
            marginBottom: 26,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "flex-start",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "We Provide Best Digital \nMarketing Solutions",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 42,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 42,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#fdf7e2",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Et fugiat et voluptates. Aperiam dolor modi nesciunt soluta excepturi. Sed dolore quia voluptatibus hic quia nobis ut.\n Doloremque quia qui id deserunt et ea.",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 14,
            color: "#3a3a3a",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 42,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#fdf7e2",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625738923598_LandingPage-5-Image1.png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 0,
            marginBottom: 48,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "Let's Check Our Featured Services",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 34,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Alias qui aliquam quia voluptatem velit. Aut necessitatibus fugit ut. Accusantium suscipit explicabo similique\n est at repellendus porro consequuntur.\n Molestias inventore qui et possimus dolores veritatis aut rerum in.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 16,
            color: "#000000",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Collection",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Marketing Analysis",
                fontSize: 18,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
                  fontSize: 12,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625739643166_LandingPage-5-Image2.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Website Optimization",
                fontSize: 18,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
                  fontSize: 12,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625739648594_LandingPage-5-Image3.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Email Marketing",
                fontSize: 18,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
                  fontSize: 12,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625739653533_LandingPage-5-Image4.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 4,
            marginBottom: 4,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 122,
            cellSpacing: 43,
            iconRadius: 0,
            titleColor: "#000000",
            titleSize: 18,
            descriptionColor: "#837c7c",
            descriptionSize: 12,
            gridSize: 23
          }
        },
        {
          type: "Text",
          value: "Enjoy full-service Digital \nMarketing Expertise",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 42,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 42,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#fdf7e2",
            overflow: "hidden",
            alignSelf: "flex-start",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Et fugiat et voluptates. Aperiam dolor modi nesciunt soluta excepturi. Sed dolore quia voluptatibus hic quia nobis ut. \nDoloremque quia qui id deserunt et ea.",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 14,
            color: "#3a3a3a",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 42,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#fdf7e2",
            overflow: "hidden",
            alignSelf: "flex-start",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value: "Let’s Check Our Services",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 32,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 42,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Alias qui aliquam quia voluptatem velit. Aut necessitatibus fugit ut. Accusantium suscipit \nest at repellendus porro consequuntur.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 14,
            color: "#000000",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Collection",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Social Media Marketing",
                fontSize: 16,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
                  fontSize: 12,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625740162885_LandingPage-5-Image5.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Keyword Research",
                fontSize: 16,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
                  fontSize: 12,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625740174439_LandingPage-5-Image6.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Content Marketing",
                fontSize: 16,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
                  fontSize: 12,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625740181770_LandingPage-5-Image7.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "PPC Advertising",
                fontSize: 16,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
                  fontSize: 12,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625740211391_LandingPage-5-Image8.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Competitor Research",
                fontSize: 16,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
                  fontSize: 12,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625740218960_LandingPage-5-Image9.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Skyrocketing Growth",
                fontSize: 16,
                color: "#000000",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
                  fontSize: 12,
                  color: "#000000",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625740232411_LandingPage-5-Image10.png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 14,
            marginBottom: 4,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#ffffff",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 140,
            cellSpacing: 45,
            iconRadius: 0,
            titleColor: "#000000",
            titleSize: 16,
            descriptionColor: "#000000",
            descriptionSize: 12,
            gridSize: 21
          }
        },
        {
          type: "Text",
          value: "Subscribe To Our Newsletter",
          style: {
            width: "70%",
            boxSizing: "border-box",
            fontSize: 28,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 26,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#dbdbdb",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Text",
          value:
            "Lorem ipsum dolor sit amet, consectetur\n adipiscing elit, sed do eiusmod tempor.",
          style: {
            width: "70%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#000000",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#dbdbdb",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Form",
          value: FormService.getContactDataForLandingPages(),
          segmentId:
            ContactService.getSegments().length > 0
              ? ContactService.getSegments()[0].id
              : "",
          style: {
            width: "70%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 12,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#dbdbdb",
            alignSelf: "center",
            overflow: "hidden",
            fieldSpacing: 10,
            labelSize: 12,
            labelColor: "#000000",
            fieldRadius: 4,
            fieldBorder: "#EBEBEB",
            fieldBackground: "#F8F7FA"
          },
          button: {
            value: "Submit",
            style: {
              buttonColor: "#ffffff",
              buttonSize: 16,
              buttonBackground: "#f53206"
            }
          },
          thankYouMsg: "Thank you for submitting form!",
          redirectURL: ""
        },
        {
          type: "Text",
          value: "© 2021, All Rights Reserved | Unsubscribe",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#ffffff",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#000000",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        }
      ]
    };
  }
}
