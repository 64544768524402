import { getRandomAlphaNumericString } from "../../../utility/Utility";
import ic_x from "../../../assets/social_icons/ic_twitter.png";

export default class Template10 {
  static getData() {
    return {
      metaData: null,
      scripts: null,
      hash: getRandomAlphaNumericString(),
      designElements: [
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625725861934_deskera-logo-theme (1).png",
          style: {
            width: "32%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 30,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "Your Business. Anywhere.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 38,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 0,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 10,
            marginBottom: 4,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Text",
          value:
            "Whether on the go or working remotely, your business is with you. \nDeskera’s Webby award winning mobile app keeps you connected to your business at all times.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 14,
            color: "#000000",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#ffffff",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Text",
          value:
            "All the features and reports you love, available on the go, all the time.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 18,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Button",
          value: "Start Free Trial",
          url: "",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 18,
            color: "#ffffff",
            textAlign: "left",
            fontWeight: "bold",
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 25,
            paddingRight: 25,
            marginTop: 16,
            marginBottom: 16,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 4,
            backgroundColor: "#ae2e00",
            alignSelf: "center",
            overflow: "hidden",
            cursor: "pointer",
            outline: "none",
            border: "none",
            textDecoration: "none"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625667276705_Newsletter-10-Image1 (1).png",
          style: {
            width: "86%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 34,
            marginBottom: 46,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Social Buttons",
          value: [
            {
              isRequired: true,
              title: "Facebook",
              url: "",
              icon: "https://i.ibb.co/FX4mz7J/ic-facebook.png"
            },
            {
              isRequired: true,
              title: "Instagram",
              url: "",
              icon: "https://i.ibb.co/VNvh8BL/ic-instagram.png"
            },
            {
              isRequired: true,
              title: "Twitter",
              url: "",
              icon: "https://i.ibb.co/qR8Q8PV/ic-twitter.png"
            },
            {
              isRequired: false,
              title: "LinkedIn",
              url: "",
              icon: "https://i.ibb.co/BzLpvD2/linkedin-2.png"
            },
            {
              isRequired: false,
              title: "Youtube",
              url: "",
              icon: "https://i.ibb.co/tJ6rf4x/youtube.png"
            },
            {
              isRequired: false,
              title: "WhatsApp",
              url: "",
              icon: "https://i.ibb.co/nn931VS/whatsapp-2.png"
            }
          ],
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 14,
            paddingBottom: 6,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 4,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#000000",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 26,
            cellSpacing: 10
          }
        },
        {
          type: "Text",
          value: "© 2021, All Rights Reserved",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#ffffff",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 10,
            paddingBottom: 14,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#000000",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        }
      ]
    };
  }
}
