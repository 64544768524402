import { TableManger, TABLES } from "../managers/TableManger";
import Table from "./table";
import http from "../http/index";
import ApiConstants from "../constants/ApiConstants";
import Utility from "../utility/Utility";
const CRM3 = "CRM3";
const DEFAULT_PAGE_SIZE = 20;
export class CampaignService {
  static getColumnsData(isRequiredOnly) {
    let allCampaignColumns = TableManger.getTableColumns(TABLES.CAMPAIGN);
    if (isRequiredOnly) {
      return (allCampaignColumns || []).filter(
        (item) => item.required === true
      );
    }
    return allCampaignColumns;
  }

  static add(data, tableId) {
    return Table.addRecord({ cells: data }, tableId);
  }

  static update(data, tableId, recordId) {
    return Table.updateRecord(data, recordId, tableId);
  }

  static getData(recordId, tableId) {
    return Table.getRecordById(recordId, tableId);
  }
  static getEmailVerification(email) {
    let data = {
      app_name: CRM3,
      search: email
    };
    return http.get(ApiConstants.URL.WORKFLOW.EMAIL_VERIFICATION, {
      params: data
    });
  }
  static sendVerificationEmail(email, resend = false) {
    let data = {
      app_name: CRM3,
      email: email,
      resend: resend
    };
    return http.post(ApiConstants.URL.WORKFLOW.EMAIL_VERIFICATION, data);
  }

  static getUnsubscribe(params = {}) {
    params = {
      pageSize: DEFAULT_PAGE_SIZE,
      pageNo: params?.page ? params.page : 1
    };
    return http.get(ApiConstants.URL.CAMPAIGN_UNSUBSCRIBE.GET, { params });
  }

  static getTrackingDetails(campaignId, params, conditions) {
    let filter = {};
    if (!Utility.isEmptyObject(conditions)) {
      filter = { logicalOperator: "and", conditions };
    }
    return http.post(
      `${ApiConstants.URL.CAMPAIGN.TRACKING(campaignId)}`,
      filter,
      { params }
    );
  }
  static getCampaignExport(campaignId, params, conditions) {
    let filter = {};
    if (!Utility.isEmptyObject(conditions)) {
      filter = { logicalOperator: "and", conditions };
    }
    return http
      .post(ApiConstants.URL.CAMPAIGN.EXPORT_CAMPAIGN(campaignId), filter, {
        params
      })
      .catch((err) =>
        Promise.reject("Export records failed, please try again later.")
      );
  }
  static getTrackingCounts(campaignId, params) {
    return http.get(`${ApiConstants.URL.CAMPAIGN.TRACKING_COUNT(campaignId)}`, {
      params
    });
  }

  /*
  ICampaignPreviewPayload {
    campaignId: string;
    recipientContacts?: string;
    recipientEmails: string[];
    subject: string;
    smtpSetupId: number;
  }
  */
  static sendCampaignPreviewEmail(payload) {
    return http.post(ApiConstants.URL.CAMPAIGN.SEND_PREVIEW, payload);
  }
  static getTrialEmailLimit() {
    return http.get(ApiConstants.URL.CAMPAIGN.EMAIL_LIMIT);
  }
}
