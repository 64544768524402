import { useEffect, useState } from "react";
import { DKIcon, DKIcons } from "deskera-ui-library";
import RouteManager from "../../managers/RouteManager";
import "./pageStyles.css";
import "../../styles/common.scss";

import AppHeader from "./AppHeader";
import AnimatedStackHero from "./stack_hero/AnimatedStackHero";
import PageHero from "./PageHero";
import Showcase, { insertStyleSheet } from "./Showcase";
import TextBanner from "./TextBanner";
import TextAndImage from "./TextAndImage";
import CardGrid from "./CardGrid";

import Image1 from "../../assets/website/image1.svg";
import Image2 from "../../assets/website/image2.svg";
import Team from "../../assets/website/team.svg";
import Projects from "../../assets/website/projects.svg";
import Inventory from "../../assets/website/inventory.svg";
import CRM from "../../assets/website/crm.svg";
import Stars from "../../assets/website/stars.svg";
import Utility from "../../utility/Utility";
import "./stack_hero/stackStyles.css";

export default function DeskeraHomePage(props) {
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    RouteManager.setPresenter({ props });

    insertStyleSheet(
      "https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={
        "website-redesign-holder column align-items-center flex-1 parent-width bg-white " +
        (isDarkTheme ? "text-white" : "text-black")
      }
      style={{
        paddingBottom: 100,
        backgroundColor: isDarkTheme ? "black" : "white"
      }}
    >
      <AppHeader isDarkTheme={isDarkTheme} onChangeTheme={setIsDarkTheme} />
      <AnimatedStackHero
        className="parent-width column p-l"
        style={{
          maxWidth: "80rem"
        }}
        heroSubTitle=""
        heroTags={[
          {
            name: "Capterra (4.8)",
            color: "border-blue text-blue"
          },
          {
            name: "Software advice (4.8)",
            color: "border-orange text-orange"
          }
        ]}
        stackTheme={{
          background: isDarkTheme ? "bg-dark-gray" : "bg-stack-item-gray",
          color: isDarkTheme ? "text-white" : "text-black"
        }}
        heroTitle={`All-in-One Platform`}
        stackItems={[
          "Accounting & Finance",
          "Inventory",
          "CRM & Marketing",
          "Automation",
          "HRMS & Payroll",
          "Webhooks",
          "Customizations"
        ]}
        ctaData={{
          link: "https://www.deskera.com/signup?trial=false",
          label: "Get Started ✌️",
          description: "Get our free plan for 15days, and upgrade later",
          footer: "Be first on launch and updates. GDPR ready."
        }}
      />
      <div className="column parent-width align-items-center flex-1">
        <PageHero
          className=""
          header={"The smart business management software for SMEs"}
          subtitle={
            "Deskera is the solution in the cloud that has everything you need to manage your business – anytime, anywhere."
          }
          buttons={[
            {
              title: "Start for free",
              className: "text-white bg-blue p-l mr-m",
              onClick: () =>
                Utility.openInNewTab(
                  "https://www.deskera.com/signup?trial=false"
                )
            },
            {
              title: "Buy Now",
              className: "text-blue border border-m p-l",
              onClick: () =>
                Utility.openInNewTab("https://www.deskera.com/in/pricing")
            }
          ]}
          ratingData={{
            label: "Excellent",
            icon: Stars,
            review: `Rated <b>4,5</b> / from <b>833</b> reviews on Trustpilot`
          }}
          media=""
        />
        <Showcase
          title="TRUSTED BY BRANDS AROUND THE WORLD"
          icons={[
            DKIcons.ic_google,
            DKIcons.ic_facebook,
            DKIcons.ic_linkedin,
            DKIcons.ic_instagram,
            DKIcons.ic_google,
            DKIcons.ic_facebook,
            DKIcons.ic_linkedin,
            DKIcons.ic_instagram,
            DKIcons.ic_google,
            DKIcons.ic_facebook,
            DKIcons.ic_linkedin,
            DKIcons.ic_instagram,
            DKIcons.ic_google,
            DKIcons.ic_facebook,
            DKIcons.ic_linkedin,
            DKIcons.ic_instagram
          ]}
        />
        <TextBanner
          eyebrow="YOUR BUSINESS, ONE CLICK AWAY"
          title="See how your business is performing at all times"
          subTitle="How about changing hours of work with a simple click? Deskera simplifies your day-to-day, automating tasks and streamlining your accounting and invoicing."
        />
        <TextAndImage
          image={Image1}
          title="Deskera takes the stress out from invoicing"
          bullets={[
            {
              title: "Look professional",
              subTitle:
                "Choose from over 40 editable templates, and personalize the invoices and messages that your company sends."
            },
            {
              title: "Create documents, fast",
              subTitle:
                "All contact, product, bank, and tax information is added automatically. All you have to do is verify, save, and submit."
            }
          ]}
          isReverse={true}
          buttons={[
            { title: "Learn more", className: "bg-blue text-white mt-l" }
          ]}
        />
        <TextAndImage
          image={Image2}
          title="A companion in your accounting journey"
          bullets={[
            {
              title: "Your ledger more up to date than ever",
              subTitle:
                "The process is automatic, but you will always have the last word, so you can keep track of what you have reviewed from what you have not."
            },
            {
              title: "Up-to-date reports always at your fingertips",
              subTitle:
                "From the moment you start working with Deskera, it will collect your data and automatically create reports in real-time."
            }
          ]}
          isReverse={false}
          buttons={[
            { title: "Learn more", className: "bg-blue text-white mt-l" }
          ]}
        />
        <CardGrid
          title={"Take your business to the cloud"}
          subTitle={
            "Every aspect of your company connected in a single management platform."
          }
          cards={[
            {
              title: "Team",
              subTitle:
                "Empower and organize your team with the best HR tools.",
              image: Team
            },
            {
              title: "Projects",
              subTitle:
                "Plan each step, visualize the goal and calculate the profitability of your projects.",
              image: Projects
            },
            {
              title: "Inventory",
              subTitle:
                "Much more than a list of products. Monitor your inventory in real time and control your sales cycles.",
              image: Inventory
            },
            {
              title: "CRM",
              subTitle:
                "Manage opportunities with a personal touch thanks to a CRM that closes deals.",
              image: CRM
            }
          ]}
        />
        <CardGrid
          eyebrow="THERE'S EVEN MORE"
          title="And that's not all..."
          subTitle="Whatever your business may be, Deskera has the tool you are looking for."
          className={"mt-l"}
          cards={[
            {
              icon: isDarkTheme
                ? DKIcons.white.ic_check_mark
                : DKIcons.ic_check_mark,
              title: "Multiple currency support",
              subTitle:
                "Create invoices in any currency by changing the currency in your settings."
            },
            {
              icon: isDarkTheme
                ? DKIcons.white.ic_check_mark
                : DKIcons.ic_check_mark,
              title: "Quotes",
              subTitle:
                "Prepare quotes and send them from Deskera to be approved by your customer."
            },
            {
              icon: isDarkTheme
                ? DKIcons.white.ic_check_mark
                : DKIcons.ic_check_mark,
              title: "Tax calculation",
              subTitle:
                "Choose which tax you want to add to your invoice, and Deskera will do the math for you."
            },
            {
              icon: isDarkTheme
                ? DKIcons.white.ic_check_mark
                : DKIcons.ic_check_mark,
              title: "Integrations",
              subTitle:
                "Connect all your applications in two steps through the Deskera Store."
            },
            {
              icon: isDarkTheme
                ? DKIcons.white.ic_check_mark
                : DKIcons.ic_check_mark,
              title: "Payrolls",
              subTitle:
                "Manage payrolls with all the information you need: base salary, bonuses, personal income tax, etc."
            },
            {
              icon: isDarkTheme
                ? DKIcons.white.ic_check_mark
                : DKIcons.ic_check_mark,
              title: "Receipts",
              subTitle:
                "Speed up your transactions by creating receipts automatically."
            },
            {
              icon: isDarkTheme
                ? DKIcons.white.ic_check_mark
                : DKIcons.ic_check_mark,
              title: "Proforma",
              subTitle:
                "Customize them to specify the details about future invoices for your clients."
            },
            {
              icon: isDarkTheme
                ? DKIcons.white.ic_check_mark
                : DKIcons.ic_check_mark,
              title: "Customer service",
              subTitle:
                "Call or use FaceTime to contact your customer from Deskera."
            }
          ]}
        />
      </div>
    </div>
  );
}
