import React, { Component } from "react";
import { DKLabel, DKButton } from "deskera-ui-library";
import SegmentControl from "../common/SegmentControl";
import { getCapitalized } from "../../utility/Utility";
import ScriptEditor from "./editors/ScriptEditor";
import MetaDataEditor from "./editors/MetaDataEditor";
import ElementList from "./ElementList";

export const PAGE_ELEMENT = {
  TEXT: "Text",
  IMAGE: "Image",
  VIDEO: "Video",
  BUTTON: "Button",
  FORM_BUTTON: "Form Button",
  COLLECTION: "Collection",
  ICON: "Icon",
  LOGO: "Logo",
  SOCIAL_BUTTONS: "Social Buttons",
  IMAGE_TEXT: "Image & Text",
  FORM: "Form",
  CUSTOM_HTML: "Custom HTML",
  IMAGE_GALLERY: "Image Gallery",
  COLUMN_SECTION: "Columns",
  ORDER_FORM: "Order Form",
  COUNTDOWN_TIMER: "Countdown",
  V_STACK: "V Stack"
};

export const PAGE_SIZE = {
  WEB: "web",
  TABLET: "tablet",
  PHONE: "phone"
};
export const PAGE_TYPE = {
  CAMPAIGN: "campaign",
  FORM: "form",
  FUNNEL: "funnel",
  LANDING_PAGE: "landing_page",
  THANK_YOU_PAGE: "thank_you_page"
};

/*
PROPS
- onAdd
- viewType
- onViewTypeChange
- pageType (PAGE_TYPE)
- onScriptAdd
- onMetaDataAdd
- components
*/

class ComponentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: this.props.viewType,
      needEmbedScriptEditor: false,
      needMetaDataEditor: false,
      components: this.props.components,
      scripts: this.props.scripts,
      metaData: this.props.metaData
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      components: nextProps.components,
      scripts: nextProps.scripts,
      metaData: nextProps.metaData
    });
  }
  render() {
    return (
      <div
        className="column bg-white border-radius-s border-box p-l shadow-s ml-xl hide-scroll-bar display-only-web"
        style={{ width: 280 }}
      >
        <DKLabel text="View Options" className="fw-m  " />
        <SegmentControl
          className="mt-r"
          data={[
            { title: getCapitalized(PAGE_SIZE.WEB), value: PAGE_SIZE.WEB },
            {
              title: getCapitalized(PAGE_SIZE.TABLET),
              value: PAGE_SIZE.TABLET
            },
            { title: getCapitalized(PAGE_SIZE.PHONE), value: PAGE_SIZE.PHONE }
          ]}
          onChange={(value) => {
            this.changeViewType(value);
          }}
          value={this.state.viewType}
        />

        <ElementList
          pageType={this.props.pageType}
          onAdd={(type) => this.props.onAdd(type)}
          className="mt-xl"
        />

        <DKLabel
          text="You can select above elements to create a campaign page"
          className="text-gray  mt-s p-s"
        />
        {this.getMetaDataSection()}
        {this.getEmbedScriptSection()}
      </div>
    );
  }

  changeViewType = (type) => {
    this.setState({
      viewType: type
    });
    this.props.onViewTypeChange(type);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////

  getEmbedScriptSection() {
    let str = '<script async="" src="...."></script>';
    return (
      <div className="mt-xxl parent-width" style={{ paddingBottom: 100 }}>
        <div onClick={this.showEmbedScriptEditor}>
          <div className="row justify-content-between">
            <DKLabel text="< > Embed Script" className="fw-m cursor-hand" />
            <DKButton
              title="+ Add"
              className="bg-gray1 border-m"
              onClick={this.showEmbedScriptEditor}
            />
          </div>
        </div>
        <div
          className="parent-width cursor-hand p-s word-break-all border-box text-align-left bg-deskera-secondary border-m  mt-r border-radius-s"
          style={{
            overflowY: "hidden"
          }}
          onClick={this.showEmbedScriptEditor}
        >
          {str}
        </div>
        <DKLabel
          text="Add your page tracking scripts (GTM, MixPanel & etc) or any other"
          className="text-gray  p-s mt-s"
        />
        {this.state.needEmbedScriptEditor && (
          <ScriptEditor
            headScript={this.state.scripts ? this.state.scripts.head : ""}
            bodyScript={this.state.scripts ? this.state.scripts.body : ""}
            onCancel={this.hideEmbedScriptEditor}
            onSave={(data) => {
              this.hideEmbedScriptEditor();
              this.props.onScriptAdd(data);
            }}
          />
        )}
      </div>
    );
  }

  showEmbedScriptEditor = () => {
    this.setState({ needEmbedScriptEditor: true });
  };
  hideEmbedScriptEditor = () => {
    this.setState({ needEmbedScriptEditor: false });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////

  getMetaDataSection() {
    let str = "<title, description, favicon>...</>";
    return (
      <div className="mt-xxl parent-width">
        <div onClick={this.showMetaDataEditor}>
          <div className="row justify-content-between">
            <DKLabel text="+ Metadata" className="fw-m cursor-hand" />
            <DKButton
              title="+ Add"
              className="bg-gray1 border-m"
              onClick={this.showMetaDataEditor}
            />
          </div>
        </div>
        <div
          className="parent-width cursor-hand p-s word-break-all border-box text-align-left bg-deskera-secondary border-m  mt-r border-radius-s"
          style={{
            overflowY: "hidden"
          }}
          onClick={this.showMetaDataEditor}
        >
          {str}
        </div>
        {this.state.needMetaDataEditor && (
          <MetaDataEditor
            data={this.state.metaData}
            onCancel={this.hideMetaDataEditor}
            onSave={(data) => {
              this.hideMetaDataEditor();
              this.props.onMetaDataAdd(data);
            }}
          />
        )}
      </div>
    );
  }

  showMetaDataEditor = () => {
    this.setState({ needMetaDataEditor: true });
  };

  hideMetaDataEditor = () => {
    this.setState({ needMetaDataEditor: false });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////
}

export default ComponentList;
