import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  DKInput,
  DKLabel,
  DKButton,
  Toggle,
  DKIcon,
  DKIcons,
  DKTooltipWrapper,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showAlert,
  showLoader,
  removeLoader
} from "deskera-ui-library";
import Popup from "../common/Popup";

import Utility, { getCapitalized } from "../../utility/Utility";
import ApiConstants from "../../constants/ApiConstants";

import { store } from "../../redux/store";
import { INVITE_USER_STATUS } from "../../constants/Permission";
import { getFullName } from "../../model/User";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import IndiaMartService from "../../services/indiamart";
import { INDIA_MART_LEAD_DATA_KEYS } from "../../constants/Enum";
import { showAddColumnPopup } from "../common/AddColumnPopup";
import Table from "../../services/table";
import {
  convertCRMDateFormatToUILibraryFormat,
  DateUtil,
  DATE_FORMATS
} from "../../utility/Date";
import { IColumnOption } from "../../model/Table";

interface IIndiaMartLeadSyncSetting {
  indiaMartAccount?: any;
  popupId?: string;
  onSave: (response?: any) => void;
  onClose: () => void;
}

const RESTRICTED_CONTACT_COLUMNS_FOR_MAPPING = [
  COLUMN_CODE.CONTACT.SEGMENT,
  COLUMN_CODE.CONTACT.STATUS,
  COLUMN_CODE.CONTACT.ACCOUNT,
  COLUMN_CODE.CONTACT.OWNER_ID,
  COLUMN_CODE.CONTACT.SUB_OWNER_ID,
  COLUMN_CODE.CONTACT.ATTACHMENT,
  COLUMN_CODE.CONTACT.TYPE,
  COLUMN_CODE.CONTACT.DETAILED_ADDRESS,
  COLUMN_CODE.CONTACT.PRICE_LIST
];

interface IFormField {
  id: string;
  title: string;
  value: any;
  isMappingField?: boolean;
  payloadKey: string;
  isDynamic?: boolean;
  dynamicValue?: string;
  fieldKey?: string;
  minVal?: any;
  minValueAlert?: string;
  isNested?: boolean;
  placeholder?: string;
  type: string;
  options?: IColumnOption[];
  required: boolean;
  tooltip?: { content: string; className: string; style: any };
  readOnly?: boolean;
}

const IndiaMartLeadSyncSetting = (props: IIndiaMartLeadSyncSetting) => {
  const [saveTapped, setSaveTapped] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const contactColumnsForMap = getContactColumnsToMap();
  const fields = [
    {
      id: "crmKey",
      title: "CRM key",
      value: "" as any,
      payloadKey: "apiKey",
      placeholder: "Enter IndiaMart account CRM Key",
      type: INPUT_TYPE.TEXT,
      required: true,
      tooltip: {
        content: `If you already have a Seller account registered with <a href="${ApiConstants.URL.INDIAMART.SELLER_ACCOUNT_PROVIDER}" target="_blank">IndiaMart</a>, <br/>You can retrieve your CRM Key by following <a href="${ApiConstants.URL.INDIAMART.SELLER_API_KEY_HELP}" target="_blank">this link.</a>`,
        className: "bg-deskera-secondary",
        style: {}
      }
    },
    {
      id: "startDate",
      title: "Sync from",
      value: new Date(),
      minVal: new Date().setDate(new Date().getDate() - 365),
      minValueAlert:
        "Your leads from only past 365 days are available for sync. You can start syncing your leads from today, or select a date within last year.",
      payloadKey: "metaData.startDate",
      isNested: true,
      type: INPUT_TYPE.DATE,
      required: false,
      tooltip: {
        content: `Your leads created from selected date will get synced to CRM. You can only choose to sync leads created within last 365 days.`,
        className: "bg-deskera-secondary",
        style: {}
      }
    },
    {
      id: "autoSync",
      title: "Auto sync",
      value: true,
      payloadKey: "enableCron",
      type: "toggle",
      required: false,
      tooltip: {
        content: `Your leads created after selected "Sync from" date will get synced to CRM on enabling this option.`,
        className: "bg-deskera-secondary",
        style: {}
      }
    },
    {
      id: "segmentId",
      title: "Segment",
      value: [],
      isMappingField: true,
      payloadKey: "fields",
      isDynamic: false,
      fieldKey: TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.SEGMENT
      ),
      placeholder: "Select segments",
      type: INPUT_TYPE.MULTI_SELECT,
      options:
        TableManger.getColumn(TABLES.CONTACT, COLUMN_CODE.CONTACT.SEGMENT)
          ?.options || [],
      required: true,
      tooltip: {
        content: `Leads synced from IndiaMart to CRM will get tagged to selected segments.`,
        className: "bg-deskera-secondary",
        style: {}
      }
    },
    {
      id: "ownerId",
      title: "Owners",
      value: [],
      isMappingField: true,
      payloadKey: "fields",
      isDynamic: false,
      fieldKey: TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.OWNER_ID
      ),
      placeholder: "Select owners",
      type: INPUT_TYPE.MULTI_SELECT,
      options: (store.getState().tenant.users || [])
        .filter((user) => user.status === INVITE_USER_STATUS.JOINED)
        .map((user) => ({
          id: user.iamUserId,
          name: getFullName(user)
        })),
      required: false,
      tooltip: {
        content: `One of the selected users will get assigned as an owner, to leads received from IndiaMart, in a round robin manner.`,
        className: "bg-deskera-secondary",
        style: {}
      }
    },
    ...generateFieldConfigWithIndiaMartKeys([
      INDIA_MART_LEAD_DATA_KEYS.SENDER_NAME,
      INDIA_MART_LEAD_DATA_KEYS.SENDER_EMAIL,
      INDIA_MART_LEAD_DATA_KEYS.SENDER_MOBILE,
      INDIA_MART_LEAD_DATA_KEYS.SENDER_COMPANY,
      INDIA_MART_LEAD_DATA_KEYS.SENDER_ADDRESS,
      INDIA_MART_LEAD_DATA_KEYS.SENDER_CITY,
      INDIA_MART_LEAD_DATA_KEYS.SENDER_STATE,
      INDIA_MART_LEAD_DATA_KEYS.SENDER_PINCODE,
      INDIA_MART_LEAD_DATA_KEYS.SENDER_COUNTRY_ISO,
      INDIA_MART_LEAD_DATA_KEYS.QUERY_PRODUCT_NAME,
      INDIA_MART_LEAD_DATA_KEYS.QUERY_MESSAGE,
      INDIA_MART_LEAD_DATA_KEYS.QUERY_MCAT_NAME
    ])
  ];

  const [formData, setFormData] = useState<IFormField[]>(fields);

  useEffect(() => {
    if (Utility.isEmptyObject(props.indiaMartAccount)) return;

    if (!Utility.isEmptyObject(props.indiaMartAccount.error)) {
      let { message, source } = props.indiaMartAccount.error;
      message =
        typeof message === "object" ? message?.error : message?.toString();
      message = message ?? "Please check lead sync logs for more details.";
      if (source !== "SYNCED") {
        message = `Please note: We encountered an issue while syncing previously.<br><em>${message}</em>`;
      } else {
        message = `<em>${message}</em>`;
      }
      showAlert(
        `${source === "SYNCED" ? "Synced" : "Sync issue"}`,
        `${message}`
      );
    }

    const updatedFormData = formData.map((formField) => {
      let value;

      if (formField.isDynamic) {
        const fieldData = props.indiaMartAccount.fields?.find(
          (field) => field.value === formField.dynamicValue
        );
        value = fieldData?.key ? [fieldData.key] : [];

        if (formField.readOnly && Utility.isEmptyObject(value)) {
          value = formField.value;
        }
      } else if (formField.isMappingField) {
        const fieldData = props.indiaMartAccount.fields?.find(
          (field) => field.key === formField.fieldKey
        );
        value = fieldData?.value;
      } else if (formField.isNested) {
        const keys = formField.payloadKey.split(".");
        value = props.indiaMartAccount[keys[0]];

        for (let index = 1; index < keys.length; index++) {
          const key = keys[index];
          value = value?.[key];
        }

        if (formField.type === INPUT_TYPE.DATE) {
          value = Utility.isValidDate(new Date(value))
            ? DateUtil.getDateFromStr(value, DATE_FORMATS["DD-MMM-YYYY"])
            : new Date();
        }
      } else {
        value = props.indiaMartAccount[formField.payloadKey];
      }

      value = (value ?? formField.value) as boolean | string | any[];

      return {
        ...formField,
        value: value
      };
    });

    setFormData(updatedFormData);
  }, []);

  function getIndiaMartFieldTitle(key: INDIA_MART_LEAD_DATA_KEYS) {
    let title = key.toLowerCase().replaceAll("_", " ");
    title = getCapitalized(title);
    switch (key) {
      case INDIA_MART_LEAD_DATA_KEYS.QUERY_PRODUCT_NAME:
        return "Product";
      case INDIA_MART_LEAD_DATA_KEYS.QUERY_MESSAGE:
        return "Message";
      case INDIA_MART_LEAD_DATA_KEYS.QUERY_MCAT_NAME:
        return "Category name";
      case INDIA_MART_LEAD_DATA_KEYS.SENDER_COUNTRY_ISO:
        return "Sender country code";
      default:
    }

    return title;
  }

  function getPreMappedValueForIndiaMartKey(key: INDIA_MART_LEAD_DATA_KEYS) {
    let columnCode = "",
      colId = "";
    switch (key) {
      case INDIA_MART_LEAD_DATA_KEYS.SENDER_NAME:
        columnCode = COLUMN_CODE.CONTACT.NAME;
        break;
      case INDIA_MART_LEAD_DATA_KEYS.SENDER_EMAIL:
        columnCode = COLUMN_CODE.CONTACT.EMAIL;
        break;
      case INDIA_MART_LEAD_DATA_KEYS.SENDER_MOBILE:
        columnCode = COLUMN_CODE.CONTACT.PHONE;
        break;
      case INDIA_MART_LEAD_DATA_KEYS.SENDER_COMPANY:
        columnCode = COLUMN_CODE.CONTACT.ORGANISATION;
        break;
      case INDIA_MART_LEAD_DATA_KEYS.SENDER_ADDRESS:
        columnCode = COLUMN_CODE.CONTACT.ADDRESS;
        break;
      default:
    }

    if (columnCode) {
      colId = TableManger.getColumnId(TABLES.CONTACT, columnCode);
    }

    return colId ? [colId] : [];
  }

  function generateFieldConfigWithIndiaMartKeys(
    indiaMartPayloadKeys: INDIA_MART_LEAD_DATA_KEYS[]
  ) {
    return indiaMartPayloadKeys.map((key) => ({
      id: key,
      title: getIndiaMartFieldTitle(key),
      value: getPreMappedValueForIndiaMartKey(key),
      isMappingField: true,
      payloadKey: "fields",
      isDynamic: true,
      dynamicValue: key,
      placeholder: "Select contact field",
      type: INPUT_TYPE.SELECT,
      options: contactColumnsForMap,
      required: [INDIA_MART_LEAD_DATA_KEYS.SENDER_NAME].includes(key),
      allowNewField: true,
      readOnly: !Utility.isEmptyObject(getPreMappedValueForIndiaMartKey(key))
    }));
  }

  function getContactColumnNameToDisplay(column) {
    let name = column.name;

    /* We need to display Detail address column name if enabled,
      For BE we need to always map Indiamart address field with Contact grid address field.
    */
    if (column.columnCode === COLUMN_CODE.CONTACT.ADDRESS) {
      const hiddenColumns =
        store.getState().userPref.hiddenColumns?.[
          TABLES.CONTACT.toUpperCase()
        ] || [];
      const detailAddressCol = TableManger.getColumn(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.DETAILED_ADDRESS
      );
      name =
        detailAddressCol && !hiddenColumns.includes(detailAddressCol.id)
          ? detailAddressCol.name
          : column.name;
    }

    return `Contact.${getCapitalized(name.toLowerCase())}`;
  }

  function getContactColumnsToMap() {
    return TableManger.getTableFilteredColumns(
      TABLES.CONTACT,
      (column) =>
        !column.hidden &&
        !RESTRICTED_CONTACT_COLUMNS_FOR_MAPPING.includes(column.columnCode) &&
        (column.editable ||
          [COLUMN_CODE.CONTACT.NOTE].includes(column.columnCode))
    ).map((column) => ({
      ...column,
      name: getContactColumnNameToDisplay(column)
    }));
  }
  /* *************** Popup save & close utils ***************** */
  const onSaveTapped = () => {
    setSaveTapped(true);

    let isValid = true;
    const accountData: any = {};

    formData.forEach((element) => {
      if (element.required && Utility.isEmptyObject(element.value)) {
        isValid = false;
      }

      if (element.isDynamic) {
        accountData.fields = accountData.fields || [];
        accountData.fields.push({
          key: (element.value || "").toString(),
          value: element.dynamicValue,
          isDynamic: true
        });
      } else if (element.isMappingField) {
        accountData.fields = accountData.fields || [];
        accountData.fields.push({
          key: element.fieldKey,
          value: element.value,
          isDynamic: false
        });
      } else if (element.isNested) {
        const keys = element.payloadKey.split(".");

        let tempValue = {};
        for (let index = 1; index < keys.length - 1; index++) {
          tempValue[keys[index]] = {};
        }

        tempValue[keys[keys.length - 1]] =
          element.type === INPUT_TYPE.DATE
            ? DateUtil.getDateStrFromDate(
                new Date(element.value),
                DATE_FORMATS["DD-MMM-YYYY"]
              )
            : element.value;

        accountData[keys[0]] = tempValue;
      } else {
        accountData[element.payloadKey] = element.value;
      }
    });

    if (!isValid) return;

    setFormSubmitting(true);

    showLoader("Saving connection settings...");

    IndiaMartService.updateAccountConfig(accountData)
      .then((res) => {
        const { success, data } = res;

        if (!success) {
          showAlert(
            "Failed to save settings",
            data?.error?.message ||
              "Something went wrong while connecting IndiaMart account, please try again."
          );
          return;
        }

        removePopUp();

        showAlert(
          `Integration settings saved!`,
          `Your IndiaMart account integration settings has been saved and lead syncing has been ${
            data.enableCron
              ? "enabled. <br><br>It might take some time to sync your leads, once synced it will be visible under contacts."
              : "disabled."
          }${
            data.enableCron
              ? "<br><br>Please note: Leads sync will get enabled after 24 hours, in case of newly generated CRM Key."
              : ""
          }`
        );
        props.onSave?.();
      })
      .catch((err) => {
        showAlert(
          "Failed to save settings",
          err?.message ||
            "Something went wrong while IndiaMart account integration, please try again."
        );
      })
      .finally(() => {
        setFormSubmitting(false);
        removeLoader();
      });
  };

  const onDeleteTapped = () => {
    showLoader("Deleting linked IndiaMart account...");

    IndiaMartService.deleteAccountConfig(props.indiaMartAccount._id)
      .then((res) => {
        removePopUp();
        setFormData(fields);
        showAlert(
          "Account deleted!",
          "Your IndiaMart account has been unlinked from CRM successfully."
        );
        props.onSave?.();
      })
      .catch((err) => {
        showAlert(
          "Failed to delete account",
          err?.message ||
            "Something went wrong while deleting your linked IndiaMart account, please try again."
        );
      })
      .finally(() => {
        removeLoader();
      });
  };

  const removePopUp = () => {
    if (props.popupId)
      ReactDOM.unmountComponentAtNode(document.getElementById(props.popupId));

    document.getElementById(props.popupId)?.remove();

    props.onClose && props.onClose();
  };

  const handleFormValueChange = (
    fieldId: string,
    value: string | boolean | number[] | Date
  ) => {
    const updatedFormData: IFormField[] = Utility.makeCopyOfObject(formData);
    const fieldToUpdate = updatedFormData.find((field) => field.id === fieldId);
    if (value && fieldToUpdate.minVal) {
      let isValid = true;
      switch (fieldToUpdate.type) {
        case INPUT_TYPE.DATE:
          isValid = (value as Date).getTime() >= fieldToUpdate.minVal;
          break;
        case INPUT_TYPE.NUMBER:
          isValid = value >= fieldToUpdate.minVal;
          break;
        default:
      }

      if (!isValid) {
        showAlert("Invalid value", fieldToUpdate.minValueAlert);
        value = fieldToUpdate.value;
      }
    }

    if (fieldToUpdate.isMappingField && fieldToUpdate.isDynamic) {
      const fieldWithDuplicateValue = updatedFormData.find(
        (field) => field.value?.toString() === value?.toString()
      );
      if (fieldWithDuplicateValue) {
        showAlert(
          "Duplicate mapping",
          "This field is already mapped with another IndiaMart field."
        );

        value = fieldToUpdate.value;
      }
    }

    fieldToUpdate.value = value;
    setFormData(updatedFormData);
  };

  /* *********************RENDERERS******************* */
  const getAddFieldConfig = (fieldId: string) => ({
    title: "+ New Field",
    className: "bg-button text-white",
    onClick: () => {
      showAddColumnPopup(
        {
          tableName: TABLES.CONTACT
        },
        async (response) => {
          const tableData = await Table.getTable(
            TableManger.getTableId(TABLES.CONTACT)
          );
          TableManger.setTableColumns(tableData, TABLES.CONTACT);

          const contactColumnsForMap = getContactColumnsToMap();
          const updatedFormData = formData.map((formField) => {
            let value = formField.value;

            if (formField.id === fieldId && response?.data?.id) {
              value = [response.data.id];
            }

            return {
              ...formField,
              value: value,
              options: formField.isDynamic
                ? contactColumnsForMap
                : formField.options
            };
          });
          setFormData(updatedFormData as any);
        }
      );
    }
  });

  function getButtons() {
    return (
      <div className="row align-items-center justify-content-between parent-width mt-m">
        <div className="row action-btn-wrapper">
          <DKButton
            className="border-m mr-r bg-button text-white"
            title="Save"
            disabled={formSubmitting}
            onClick={onSaveTapped}
          />
          {!Utility.isEmptyObject(props.indiaMartAccount) && (
            <DKButton
              title="Delete Account"
              className="border-m bg-red text-white"
              onClick={() => onDeleteTapped()}
            />
          )}
        </div>
      </div>
    );
  }

  function getHeader() {
    return (
      <div className="row align-items-center justify-content-between parent-width">
        <DKLabel
          text={
            Utility.isEmptyObject(props.indiaMartAccount?.apiKey)
              ? "Connect with IndiaMart"
              : "Indiamart integration settings"
          }
          className="row fw-m"
        />
        <div className="row-reverse action-btn-wrapper">
          <DKButton
            className="border-m ml-r bg-button text-white"
            title="Save"
            disabled={formSubmitting}
            onClick={onSaveTapped}
          />
          <DKButton
            className="border-m bg-white"
            title="Cancel"
            onClick={removePopUp}
          />
        </div>
      </div>
    );
  }
  function getFormInput(formInput) {
    switch (formInput.type) {
      case "toggle":
        return (
          <div className="row p-v-xs">
            <div className="d-flex dkinput-header-section">
              <DKLabel text={formInput.title} />
              {!Utility.isEmptyObject(formInput.tooltip) && (
                <DKTooltipWrapper
                  content={formInput.tooltip.content}
                  className="row width-auto align-items-end"
                  tooltipClassName={formInput.tooltip.className}
                  tooltipStyle={formInput.tooltip.style}
                >
                  <DKIcon
                    src={DKIcons.ic_info}
                    className="ic-s ml-s opacity-4"
                  />
                </DKTooltipWrapper>
              )}
            </div>
            <Toggle
              isOn={formInput.value}
              className="ml-l"
              color="bg-green"
              onChange={() =>
                handleFormValueChange(formInput.id, !formInput.value)
              }
            />
          </div>
        );
      case INPUT_TYPE.SELECT:
        const selectedValue = formInput.options.find(
          (option: any) => option.id === formInput.value?.[0]
        );
        return (
          <DKInput
            title={formInput.title}
            placeholder={formInput.placeholder}
            value={selectedValue}
            type={INPUT_TYPE.DROPDOWN}
            required={formInput.required}
            displayKey={"name"}
            dropdownConfig={{
              data: formInput.options,
              allowSearch: true,
              searchableKey: "name",
              onSelect: () => {},
              renderer: (index: number, option: any) => (
                <DKLabel text={option.name} />
              ),
              button: formInput.allowNewField
                ? getAddFieldConfig(formInput.id)
                : null
            }}
            className={`p-0`}
            direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
            onChange={(newValue: any) =>
              handleFormValueChange(
                formInput.id,
                newValue?.id ? [newValue.id] : []
              )
            }
            tooltip={formInput.tooltip}
            errorMessage={" "}
            readOnly={formInput.readOnly}
            canValidate={saveTapped}
          />
        );
      case INPUT_TYPE.MULTI_SELECT:
        const selectedIndices = formInput.value
          .map((id: number) =>
            formInput.options.findIndex((option: any) => option.id === id)
          )
          .filter((index: number) => index !== -1);
        const MAX_VALUE_TO_DISPLAY = 2;
        return (
          <DKInput
            title={formInput.title}
            placeholder={formInput.placeholder}
            value={selectedIndices}
            renderer={(indices: number[]) => (
              <div className="row width-auto flex-wrap" style={{ gap: 5 }}>
                {(indices || [])
                  .slice(0, MAX_VALUE_TO_DISPLAY)
                  .map((index: number) => {
                    return (
                      <DKLabel
                        className="bg-white border-radius-s p-h-xs shadow-s-2"
                        text={formInput.options[index]?.name || ""}
                      />
                    );
                  })}
                {indices.length > MAX_VALUE_TO_DISPLAY && (
                  <DKLabel
                    className="text-app"
                    text={`+ ${indices.length - MAX_VALUE_TO_DISPLAY} more`}
                  />
                )}
              </div>
            )}
            type={INPUT_TYPE.DROPDOWN}
            required={formInput.required}
            displayKey={"name"}
            dropdownConfig={{
              data: formInput.options,
              allowSearch: true,
              searchableKey: "name",
              multiSelect: true,
              checkMarkColor: "bg-button",
              onSelect: () => {},
              renderer: (index: number, option: any) => (
                <DKLabel text={option.name} />
              )
            }}
            className={`p-0`}
            direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
            onChange={(newSelectedIndices: number[]) => {
              const newSelectedIds = [];
              newSelectedIndices.forEach((index: number) => {
                if (!formInput.options[index]) return;
                newSelectedIds.push(formInput.options[index].id);
              });
              handleFormValueChange(formInput.id, newSelectedIds);
            }}
            tooltip={formInput.tooltip}
            errorMessage={" "}
            canValidate={saveTapped}
          />
        );
      default:
        return (
          <DKInput
            title={formInput.title}
            placeholder={formInput.placeholder}
            value={
              formInput.type === INPUT_TYPE.DATE
                ? new Date(formInput.value)
                : formInput.value
            }
            type={formInput.type}
            required={formInput.required}
            className={`p-0`}
            direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
            onChange={(value: any) =>
              handleFormValueChange(formInput.id, value)
            }
            tooltip={formInput.tooltip}
            errorMessage={" "}
            canValidate={saveTapped}
            autoFocus={false}
            dateFormat={convertCRMDateFormatToUILibraryFormat(
              DateUtil.getOrgDateFormat()
            )}
          />
        );
    }
  }
  function getForm() {
    const connectionFields = formData.filter(
      (fields) => !fields.isMappingField
    );
    const mappingFields = formData.filter(
      (fields) => fields.isMappingField && !fields.isDynamic
    );
    const dynamicMappingFields = formData.filter(
      (fields) => fields.isMappingField && fields.isDynamic
    );
    return (
      <div className="column parent-width mt-l" style={{ gap: 16 }}>
        <div className="column parent-width" style={{ gap: 12 }}>
          {connectionFields.map(getFormInput)}
        </div>
        <div
          className="column parent-width p-r border-radius-s bg-gray1"
          style={{ gap: 12 }}
        >
          <DKLabel
            text={"Select segments & owner for IndiaMart leads"}
            className="fw-m"
          />
          {mappingFields.map(getFormInput)}
        </div>
        <div
          className="column parent-width p-r border-radius-s bg-gray1"
          style={{ gap: 12 }}
        >
          <DKLabel
            text={"Map IndiaMart fields with Deskera contact fields"}
            className="fw-m"
          />
          <div className="row">
            <DKLabel
              text={"IndiaMart fields"}
              className="fw-m text-gray"
              style={{
                width: "50%"
              }}
            />
            <DKLabel
              text={"Deskera fields"}
              className="fw-m text-gray"
              style={{
                width: "50%"
              }}
            />
          </div>
          {dynamicMappingFields.map(getFormInput)}
        </div>
      </div>
    );
  }
  function getFooter() {
    return (
      Utility.isEmptyObject(props.indiaMartAccount) && (
        <div className="row mt-l">
          <DKLabel text={"Don't have an account?"} />
          <DKButton
            title="Know more"
            className="text-blue text-underline"
            style={{
              padding: "0 4px"
            }}
            onClick={() =>
              Utility.openInNewTab(
                ApiConstants.URL.INDIAMART.SELLER_ACCOUNT_PROVIDER
              )
            }
          />
        </div>
      )
    );
  }
  return (
    <div className="column parent-size overflow-y-auto">
      {/* {getHeader()} */}
      {getForm()}
      {getFooter()}
      {getButtons()}
    </div>
  );
};

export default IndiaMartLeadSyncSetting;

export const showIndiaMartLeadSyncSetting = (
  config: { indiaMartAccount?: any },
  onSave?: () => {},
  onClose?: () => {}
) => {
  const id = `data-source-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "indiamart-lead-sync-popup app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <IndiaMartLeadSyncSetting
      {...config}
      popupId={id}
      onSave={onSave}
      onClose={onClose}
    />,
    document.body.appendChild(div)
  );
};
