import { COLUMN_CODE, TableManger, TABLES } from "./TableManger";
import { INPUT_TYPE } from "deskera-ui-library";
import Table from "../services/table";
import { DealManager } from "./DealManager";
import Utility from "../utility/Utility";
import {
  CONTACT_SOURCE,
  LOOKUP_FIELD_ENABLE_COL_TYPE
} from "../constants/Constant";
import UserManager from "./UserManager";
import { IColumn } from "../model/Table";
import { AccountService } from "../services/accounts";
export default class ContactManager {
  static persons: any = {};
  static organization: any = {};
  static contactDetails: any = {};
  static contactData: any = {};
  static getDefaultValues(values = {}) {
    const ownerIdColumn = TableManger.getColumn(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.OWNER_ID
    );
    const selectedOwnerIndex = ownerIdColumn.options?.findIndex(
      (option) => option.id === UserManager.getUserIamID()
    );

    return {
      [TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.SEGMENT)]: [
        0
      ],
      [TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.STATUS)]: [
        0
      ],
      [TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.TYPE)]: [0],
      [TableManger.getColumnId(TABLES.CONTACT, COLUMN_CODE.CONTACT.LABEL)]: [0],
      [ownerIdColumn.id]: [selectedOwnerIndex ?? 0],
      ...values
    };
  }
  static getDataToUpdate = (data) => {
    let contactColumns = {};
    TableManger.getTableColumns(TABLES.CONTACT).forEach((column) => {
      if (
        column.type === INPUT_TYPE.SELECT ||
        column.type === INPUT_TYPE.MULTI_SELECT
      ) {
        contactColumns[column.id] = column;
      }
    });
    Object.keys(contactColumns).forEach((item) => {
      if (data[item]) {
        data[item] = data[item].map((r) =>
          contactColumns[item].options.findIndex((op) => op.id === r)
        );
      }
    });
  };
  static getPersons(options = null) {
    if (this.persons && this.persons.data && this.persons.data.length > 0) {
      return Promise.resolve(this.persons);
    } else {
      return Table.getRecordsByPage(
        options,
        DealManager.getQuery("personQuery"),
        TableManger.getTableId(TABLES.CONTACT)
      ).then((res) => {
        this.setPerson(res);
        return Promise.resolve(res);
      });
    }
  }
  static setPerson(res) {
    this.persons = res;
  }
  static clearPerson() {
    this.setPerson({});
  }
  static getOrganization(options = null) {
    if (
      this.organization &&
      this.organization.data &&
      this.organization.data.length > 0
    ) {
      return Promise.resolve(this.organization);
    } else {
      return Table.getRecordsByPage(
        options,
        DealManager.getQuery("orgQuery"),
        TableManger.getTableId(TABLES.CONTACT)
      ).then((res) => {
        this.setPerson(res);
        return Promise.resolve(res);
      });
    }
  }
  static setOrganization(res) {
    this.organization = res;
  }
  static clearOrganization() {
    this.setOrganization({});
  }
  static getContactDetail(recordId: string, refresh = false): Promise<any> {
    const contactTableId = TableManger.getTableId(TABLES.CONTACT);
    if (
      this.contactDetails &&
      this.contactDetails._id === recordId &&
      !refresh
    ) {
      return Promise.resolve(this.contactDetails);
    } else {
      let accountColumnId = TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.ACCOUNT
      );
      let priceBookColumnId = TableManger.getColumnId(
        TABLES.CONTACT,
        COLUMN_CODE.CONTACT.PRICE_LIST
      );
      let coltofetch = [accountColumnId, priceBookColumnId];
      let columns = TableManger.getTableColumns(TABLES.CONTACT);
      let lookupColumns = columns
        .filter(
          (column) =>
            LOOKUP_FIELD_ENABLE_COL_TYPE.includes(column.type) &&
            !Utility.isEmptyObject(column.lookup)
        )
        ?.map((col) => col.id);

      coltofetch = [...coltofetch, ...lookupColumns];

      return Table.getRecordById(recordId, contactTableId, {
        colIdsToFetchRef: coltofetch.toString()
      });
    }
  }

  static setContactDetail(contactDetails) {
    this.contactDetails = contactDetails;
    this.setContactData();
  }

  static clearContactDetails() {
    this.setContactDetail({});
  }

  static getContactData() {
    return this.contactData;
  }

  static setContactData() {
    let contactData = {},
      contactColumns = {};
    TableManger.getTableColumns(TABLES.CONTACT).forEach((column) => {
      contactColumns[column.columnCode] = column;
    });
    if (!Utility.isEmptyObject(this.contactDetails)) {
      contactData = Object.assign(
        contactData,
        { id: this.contactDetails._id },
        {
          [COLUMN_CODE.CONTACT.NAME]:
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.NAME].id
            ]
        },
        {
          [COLUMN_CODE.CONTACT.EMAIL]:
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.EMAIL]?.id
            ]
        },
        {
          [COLUMN_CODE.CONTACT.PHONE]:
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.PHONE]?.id
            ]
        },
        {
          [COLUMN_CODE.CONTACT.ADDRESS]:
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.ADDRESS]?.id
            ]
        },
        {
          [COLUMN_CODE.CONTACT.ORGANISATION]:
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.ORGANISATION]?.id
            ]
        },
        {
          [COLUMN_CODE.CONTACT.STATUS]: this.getOptionData(
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.STATUS]?.id
            ],
            contactColumns[COLUMN_CODE.CONTACT.STATUS]
          )
        },
        {
          [COLUMN_CODE.CONTACT.LABEL]: this.getOptionData(
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.LABEL]?.id
            ],
            contactColumns[COLUMN_CODE.CONTACT.LABEL]
          )
        },
        {
          [COLUMN_CODE.CONTACT.SEGMENT]: this.getOptionData(
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.SEGMENT]?.id
            ],
            contactColumns[COLUMN_CODE.CONTACT.SEGMENT]
          )
        },
        {
          [COLUMN_CODE.CONTACT.OWNER_ID]: this.getOptionData(
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.OWNER_ID]?.id
            ],
            contactColumns[COLUMN_CODE.CONTACT.OWNER_ID]
          )
        },
        {
          [COLUMN_CODE.CONTACT.TYPE]:
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.TYPE]?.id
            ]
        },
        {
          [COLUMN_CODE.CONTACT.PROFILE_PIC]:
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.PROFILE_PIC]?.id
            ]
        },
        {
          [COLUMN_CODE.CONTACT.ATTACHMENT]:
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.ATTACHMENT]?.id
            ]
        },
        {
          [COLUMN_CODE.CONTACT.SOURCE]:
            CONTACT_SOURCE[
              this.contactDetails.cells[
                contactColumns[COLUMN_CODE.CONTACT.SOURCE]?.id
              ] || "NA"
            ]
        },
        {
          [COLUMN_CODE.CONTACT.NOTE]:
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.NOTE]?.id
            ]
        },
        {
          [COLUMN_CODE.CONTACT.ACCOUNT]:
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.ACCOUNT]?.id
            ]
        },
        {
          [COLUMN_CODE.CONTACT.CREATED_AT]:
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.CREATED_AT]?.id
            ]
        },
        {
          [COLUMN_CODE.CONTACT.BOOKS_CONTACT_ID]:
            this.contactDetails.cells[
              contactColumns[COLUMN_CODE.CONTACT.BOOKS_CONTACT_ID]?.id
            ]
        }
      );
    }
    ContactManager.contactData = contactData;
  }

  static getOptionData(selectionOptionIds: any[], column: IColumn) {
    if (Utility.isEmptyObject(selectionOptionIds)) {
      return "";
    }
    let selectedSegment = [];
    selectionOptionIds.forEach((id) => {
      let data = column.options.find((col) => col.id === id);
      selectedSegment.push(data?.name);
    });
    return selectedSegment;
  }

  static getQuery(contactId) {
    let contactQuery = {
      logicalOperator: "and",
      conditions: [
        {
          colId: TableManger.getColumnId(
            TABLES.DEAL,
            COLUMN_CODE.DEAL.CONTACT_ID
          ),
          value: contactId,
          opr: "in"
        }
      ]
    };
    return contactQuery;
  }

  static getContactDisplayValues(contact) {
    const excludedColumns = [COLUMN_CODE.CONTACT.NAME];
    const requiredColumns = [
      COLUMN_CODE.CONTACT.ATTACHMENT,
      COLUMN_CODE.CONTACT.ACCOUNT
    ];
    let contactDisplayValue = [];
    const contactColumnsForLeftPanel = TableManger.getTableFilteredColumns(
      TABLES.CONTACT,
      (column: IColumn) => {
        const isRequired = requiredColumns.includes(column.columnCode);
        const isExcluded = excludedColumns.includes(column.columnCode);
        const isEditable = column.uiVisible && column.editable;

        return isRequired || (!isExcluded && isEditable);
      }
    ).sort((col1: IColumn, col2: IColumn) => col1.index - col2.index);

    contactColumnsForLeftPanel.forEach((col) => {
      let value = contact[col.id];
      let keyOptions = [];
      if (
        col.type === INPUT_TYPE.DATE &&
        !Utility.isEmptyObject(contact[col.id])
      ) {
        value = new Date(value).toDateString();
      }
      if (col.type === INPUT_TYPE.SELECT) {
        value = col.options[contact[col.id]];
        if (
          !Utility.isEmptyObject(contact[col.id]) &&
          Utility.isEmptyObject(value)
        ) {
          value = { name: "-", id: -1 };
        }
      }

      if (!Utility.isEmptyObject(col.lookup)) {
        let lookupValue = contact[col.id + "_detail"]?.[0];
        value = lookupValue;
      }

      if (col.type === INPUT_TYPE.MULTI_SELECT) {
        let valueName = [];
        col.options?.forEach((data) => {
          valueName.push(data.name);
        });
        keyOptions = valueName;
      }
      if (col.type === INPUT_TYPE.DROPDOWN) {
        keyOptions = contact[col.id + "_detail"] || [];
      }

      if (col.columnCode === COLUMN_CODE.CONTACT.ACCOUNT) {
        keyOptions = AccountService.parseAccountPayload(keyOptions);
      }

      contactDisplayValue.push({
        name: col.name,
        value: value,
        type: col.type,
        colCode: col.id,
        options: col.options,
        keyOptions: keyOptions,
        required: col.required ? col.required : col?.requiredByUser ?? false,
        readOnly: !col.editable,
        dropdownConfig: col.dropdownConfig,
        lookup: col.lookup
      });
    });
    return contactDisplayValue;
  }
}
