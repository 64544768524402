import { getRandomAlphaNumericString } from "../../../utility/Utility";
import { PAGE_ELEMENT } from "../../page_designer/ComponentList";
import ComponentManager from "../../page_designer/ComponentManager";

export default class Template7 {
  static getData() {
    return {
      metaData: null,
      scripts: null,
      hash: getRandomAlphaNumericString(),
      designElements: [
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625725502564_deskera-logo-green (1).png",
          style: {
            width: "40%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 34,
            paddingRight: 0,
            marginTop: 30,
            marginBottom: 10,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "flex-start",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625666731341_Newsletter-9-Image1 (1).png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 30,
            marginBottom: -2,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "Craft Modern Furniture",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 38,
            color: "#004046",
            textAlign: "center",
            fontWeight: "600",
            paddingTop: 6,
            paddingBottom: 0,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 30,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "flex-start",
            whiteSpace: "normal"
          }
        },
        {
          type: "Text",
          value:
            "Est voluptas non a vel odit aliquid voluptatem officia. Dolorem magni cupiditate sequi impedit vel non. Inventore porro est hic earum dolor non. Perspiciatis qui cumque consequuntur. Quod vero neque nesciunt sapiente placeat qui nam non. Autem rerum molestiae error quidem est voluptas deleniti sed.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#666666",
            textAlign: "left",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#ffffff",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Button",
          value: "Visit Our Store",
          url: "",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 16,
            color: "#004046",
            textAlign: "left",
            fontWeight: "bold",
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 7,
            paddingRight: 7,
            marginTop: 6,
            marginBottom: 26,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 4,
            backgroundColor: "#ffffff",
            alignSelf: "flex-start",
            overflow: "hidden",
            cursor: "pointer",
            outline: "none",
            border: "none",
            textDecoration: "none"
          }
        },
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625666746437_Newsletter-9-Image2 (1).png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 10,
            marginBottom: 16,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "Sed laudantium ullam.",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 38,
            color: "#004046",
            textAlign: "left",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "flex-start",
            whiteSpace: "normal"
          }
        },
        {
          type: "Text",
          value:
            "Est voluptas non a vel odit aliquid voluptatem officia. Dolorem magni cupiditate sequi impedit vel non. Inventore porro est hic earum dolor non. Perspiciatis qui cumque consequuntur. Quod vero neque nesciunt sapiente placeat qui nam non. Autem rerum molestiae error quidem est voluptas deleniti sed. ",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 14,
            color: "#666666",
            textAlign: "left",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Button",
          value: "Go to Shop",
          url: "",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 16,
            color: "#ffffff",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 14,
            paddingBottom: 14,
            paddingLeft: 31,
            paddingRight: 31,
            marginTop: 30,
            marginBottom: 30,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 6,
            backgroundColor: "#004046",
            alignSelf: "center",
            overflow: "hidden",
            cursor: "pointer",
            outline: "none",
            border: "none",
            textDecoration: "none"
          }
        },
        {
          type: "Text",
          value: "Welcome to our Store Support",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 38,
            color: "#004046",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Text",
          value:
            "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don’t look even slightly believable If you.",
          style: {
            width: "76%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#666666",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Collection",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "24 / 7 Support",
                fontSize: 18,
                color: "#666666",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625725526876_Newsletter-9-Image3 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Safe Payment",
                fontSize: 18,
                color: "#666666",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625725550163_Newsletter-9-Image4 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Quick Delivery",
                fontSize: 18,
                color: "#666666",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value: "",
                  fontSize: 14,
                  color: "#837c7c",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66710/1625725560857_Newsletter-9-Image5 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 24,
            marginBottom: 34,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 192,
            cellSpacing: 13,
            iconRadius: 0,
            titleColor: "#666666",
            titleSize: 18,
            descriptionColor: "#837c7c",
            descriptionSize: 14,
            gridSize: 29
          }
        },
        {
          type: "Text",
          value: "Most Popular",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 38,
            color: "#004046",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Text",
          value:
            "There are many variations of passages of Lorem Ipsum available, but the majority",
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            fontSize: 14,
            color: "#666666",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 6,
            paddingRight: 6,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        },
        {
          type: "Collection",
          value: [
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Debitis",
                fontSize: 24,
                color: "#004046",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Perferendis in exercitationem quis ut deserunt. Ipsam ipsam consequuntur.",
                  fontSize: 14,
                  color: "#666666",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625666788572_Newsletter-9-Image6 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Amet",
                fontSize: 24,
                color: "#004046",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Perferendis in exercitationem quis ut deserunt. Ipsam ipsam consequuntur.",
                  fontSize: 14,
                  color: "#666666",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625666795485_Newsletter-9-Image7 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Ullam",
                fontSize: 24,
                color: "#004046",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Perferendis in exercitationem quis ut deserunt. Ipsam ipsam consequuntur.",
                  fontSize: 14,
                  color: "#666666",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625666802839_Newsletter-9-Image8 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            },
            {
              title: ComponentManager.getTextDefaultData(PAGE_ELEMENT.TEXT, {
                value: "Eveniet",
                fontSize: 24,
                color: "#004046",
                fontWeight: "bold",
                paddingTop: 3,
                paddingBottom: 3
              }),
              description: ComponentManager.getTextDefaultData(
                PAGE_ELEMENT.TEXT,
                {
                  value:
                    "Dolorem nulla quia minima culpa fugit. Vero et minus excepturi reprehenderit. Excepturi dicta cum ut.",
                  fontSize: 14,
                  color: "#666666",
                  paddingTop: 3,
                  paddingBottom: 3
                }
              ),
              icon: ComponentManager.getImageDefaultData(PAGE_ELEMENT.IMAGE, {
                value:
                  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/66709/1625666811880_Newsletter-9-Image9 (1).png",
                marginBottom: 0
              }),
              button: ComponentManager.getButtonDefaultData(
                PAGE_ELEMENT.BUTTON,
                {
                  fontSize: 12,
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 7,
                  paddingRight: 7,
                  marginTop: 2,
                  visibility: false
                }
              )
            }
          ],
          style: {
            width: "fit-content",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 24,
            marginBottom: 48,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            iconSize: 248,
            cellSpacing: 7,
            iconRadius: 0,
            titleColor: "#004046",
            titleSize: 24,
            descriptionColor: "#666666",
            descriptionSize: 14,
            gridSize: 45
          }
        },
        {
          type: "Text",
          value: "© 2021, All Rights Reserved",
          style: {
            width: "100%",
            boxSizing: "border-box",
            fontSize: 12,
            color: "#ffffff",
            textAlign: "center",
            fontWeight: "normal",
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "#000000",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "normal"
          }
        }
      ]
    };
  }
}
