import { APP_NAME } from "../constants/Constant";
import UserManager from "../managers/UserManager";
// import { store } from "../redux/store";
// import MixpanelService from "../services/mixpanelService";
// import Utility from "../utility/Utility";
// import MixPanelTracker from "./utility/MixPanelTracker";

export default class EventTracker {
  static getBasicDetails = () => ({
    app: APP_NAME,
    userId: UserManager.getUserID(),
    iamUserId: UserManager.getUserIamID(),
    tenantId: UserManager.getUserTenantID()
  });

  static trackEvent = (event, data) => {
    // let dataToSend = [
    //   {
    //     event: event,
    //     properties: {
    //       token: store.getState().userPref.mixpanelToken,
    //       tenantId: UserManager.getUserTenantID(),
    //       ...data,
    //     },
    //   },
    // ];
    // MixpanelService.createEventForTracking({
    //   data: Utility.encodeJSON(dataToSend),
    // }).then((res) => {}).catch((err) => {});
    // MixPanelTracker?.track(event, {
    //   ...this.getBasicDetails,
    //   ...data,
    // });
  };
}
