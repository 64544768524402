import {
  DKLabel,
  DKContactIcon,
  DKIcon,
  DKIcons,
  showAlert,
  DKRadioButton
} from "deskera-ui-library";
import IUser, { getFullName } from "../../../model/User";
import {
  INVITE_USER_STATUS,
  TEAM_MEMBER_ACTION,
  USER_ACTION_TYPES
} from "../../../constants/Permission";
import RoleSelector from "./RoleSelector";
import { IRole } from "../../../model/Roles";
import {
  selectPermissionsByModule,
  updateUserPermission
} from "../../../redux/slices/rolesPermissionSlice";
import { fetchUsers } from "../../../redux/slices/tenantSlice";
import { useState } from "react";
import { TABLES } from "../../../managers/TableManger";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import InviteService from "../../../services/inviteService";
import ApiConstants from "../../../constants/ApiConstants";
import { CANCEL, DELETE } from "../../../constants/Constant";

interface IUserPermissionRowProps {
  user: IUser;
  isCurrentUser: boolean;
  isLoading?: boolean;
  className?: string;
  iconRenderer?: any;
  tenantOwnerId?: any;
  needRoleSelector?: boolean;
  needUserSelector?: boolean;
  needTeamLeadSelector?: boolean;
  isUserSelected?: boolean;
  onUserSelectionChange?: (data, type) => void;
  onTeamLeadSelected?: (data) => void;
}

const UserPermissionRow = ({
  user,
  isCurrentUser,
  isLoading = false,
  className = "",
  iconRenderer = null,
  tenantOwnerId = null,
  needRoleSelector = true,
  needUserSelector = false,
  needTeamLeadSelector = false,
  isUserSelected = false,
  onUserSelectionChange,
  onTeamLeadSelected
}: IUserPermissionRowProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useAppDispatch();
  const permissions = useAppSelector((state) =>
    selectPermissionsByModule(state, TABLES.PERMISSION)
  );

  const allowRoleAssign = !!permissions[USER_ACTION_TYPES.ASSIGN_ROLE];
  const allowRoleAdd = !!permissions[USER_ACTION_TYPES.REC_CREATE];
  const allowRoleEdit = !!permissions[USER_ACTION_TYPES.REC_UPDATE];
  const allowRoleDelete = !!permissions[USER_ACTION_TYPES.REC_DELETE];

  function getUserRow() {
    return (
      <div className="row" style={{ maxWidth: "60%" }}>
        {iconRenderer ? (
          iconRenderer(user)
        ) : (
          <DKContactIcon
            title={getFullName(user)}
            className={"flex-shrink-0 border-m display-only-web "}
          />
        )}
        <div className="column ml-m width-auto margin-empty-mobile max-width-100">
          {user.status !== INVITE_USER_STATUS.SENT && (
            <DKLabel text={getFullName(user)} className="fw-m mb-xs" />
          )}
          <DKLabel
            text={user.email}
            className={`
              ${user.status !== INVITE_USER_STATUS.SENT ? "text-gray" : ""} parent-width`}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          />
          {user.status === INVITE_USER_STATUS.SENT && (
            <DKLabel text="Pending" className="text-red" />
          )}
        </div>
      </div>
    );
  }

  function getDefaultRoleLabel() {
    let defaultRole = "Select role";

    if (user.status === INVITE_USER_STATUS.SENT) {
      defaultRole = "Pending";
    } else if (isUpdating) {
      defaultRole = "Updating...";
    } else if (isLoading) {
      defaultRole = "Loading...";
    }

    return defaultRole;
  }

  function getRoleSelectorSection() {
    return (
      <div className="row width-auto justify-content-end">
        <RoleSelector
          selectedRoleId={
            isUpdating || user.status === INVITE_USER_STATUS.SENT
              ? null
              : user.crm3RoleId
          }
          defaultLabel={getDefaultRoleLabel()}
          labelClassName={
            user.status === INVITE_USER_STATUS.SENT ? "text-red" : ""
          }
          isOwner={user.userId === tenantOwnerId}
          allowRoleAdd={allowRoleAdd}
          allowRoleAssign={allowRoleAssign}
          allowRoleEdit={allowRoleEdit}
          allowRoleDelete={allowRoleDelete}
          allowRolePicker={
            !isCurrentUser && user.status !== INVITE_USER_STATUS.SENT
          }
          onChangeRole={async (newRole: IRole) => {
            const payload = {
              data: [
                {
                  iamUserId: user.iamUserId,
                  roleId: newRole._id,
                  invitationStatus: user.status
                }
              ]
            };

            setIsUpdating(true);

            try {
              await dispatch(updateUserPermission(payload));
              await dispatch(fetchUsers());
            } catch (error) {
              console.log(error);
            } finally {
              setIsUpdating(false);
            }
          }}
        />
      </div>
    );
  }

  function removeUserInvite(user) {
    const buttons = [
      {
        title: CANCEL,
        className: "bg-gray1 border-m"
      },
      {
        title: DELETE,
        className: "bg-red text-white ml-r",
        onClick: () => {
          InviteService.cancelInvite(user.id)
            .then((res) => dispatch(fetchUsers()))
            .catch((error) => {});
        }
      }
    ];
    showAlert(
      "Delete Invite?",
      "Deleting this invite will delete it permanently you will not be able to restore it.",
      buttons
    );
  }

  function resendUserInvite(user) {
    let dataToSend = {
      appName: ApiConstants.APP_NAME,
      email: user.email,
      role: user.role
    };
    InviteService.invite(dataToSend)
      .then((res) => {
        showAlert(
          `Invitation sent!`,
          `Invitation has been sent to <strong>${user.email}</strong>. Please ask user to check the mail inbox for invitation email and accept it.`
        );
      })
      .catch((error) => {
        if (error?.code === 403) {
          showAlert(
            `Not Allowed!`,
            `Looks like you are not authorize to invite user please contact your organization owner.`
          );
        }
      });
  }

  function getUserRemoveAndResentButton() {
    return (
      user.status === INVITE_USER_STATUS.SENT && (
        <div className="row justify-content-end">
          <div
            className="row width-auto justify-content-between bg-white p-h-s p-v-xs  border-radius-s cursor-hand position-relative"
            onClick={() => {
              resendUserInvite(user);
            }}
          >
            <DKIcon
              className="ic-s"
              src={DKIcons.ic_repeat}
              style={{ opacity: 0.75 }}
            />
          </div>
          <div
            className="row width-auto justify-content-between bg-white p-h-s p-v-xs ml-xs border-radius-s cursor-hand position-relative"
            onClick={() => {
              removeUserInvite(user);
            }}
          >
            <DKIcon
              className="ic-s"
              src={DKIcons.ic_delete}
              style={{ opacity: 0.75 }}
            />
          </div>
        </div>
      )
    );
  }

  function getSelectUserOption() {
    return (
      <>
        {getUserRemoveAndResentButton()}
        {user.status !== INVITE_USER_STATUS.SENT && (
          <div className="column">
            {isUserSelected ? (
              <div
                className="row width-auto justify-content-between bg-white p-h-s p-v-xs  border-radius-s cursor-hand position-relative"
                onClick={() => {
                  onUserSelectionChange(user, TEAM_MEMBER_ACTION.REMOVE);
                }}
              >
                <DKLabel text="Remove" className="text-gray" />
              </div>
            ) : (
              <div
                className="row width-auto justify-content-between bg-white p-h-s p-v-xs  border-radius-s cursor-hand position-relative"
                onClick={() => {
                  onUserSelectionChange(user, TEAM_MEMBER_ACTION.ADD);
                }}
              >
                <DKLabel text="+ Add" className="text-gray" />
              </div>
            )}
          </div>
        )}
      </>
    );
  }

  function getDesignationRow() {
    return (
      <div className={`row justify-content-end align-items-center`}>
        <DKRadioButton
          className=""
          isSelected={user?.teamDesignation || false}
          color={"bg-button"}
          onClick={(value) => onTeamLeadSelected(user)}
        />
        <div
          className="cursor-hand"
          onClick={(value) => onTeamLeadSelected(user)}
        >
          <DKLabel
            text="Team lead"
            className={`ml-s fw-m cursor-hand ${user?.teamDesignation ? "text-blue" : "text-gray"}`}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`row justify-content-between listPickerBG p-h-r p-v-m user-row-wrap ${className}`}
    >
      {getUserRow()}
      {needRoleSelector
        ? user.status === INVITE_USER_STATUS.SENT
          ? getUserRemoveAndResentButton()
          : getRoleSelectorSection()
        : null}
      {needUserSelector && getSelectUserOption()}
      {needTeamLeadSelector && getDesignationRow()}
    </div>
  );
};

export default UserPermissionRow;
