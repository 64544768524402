import React, { Component } from "react";

/*
PROPS
- data
- borderStyle
*/

class CustomHtml extends Component {
  render() {
    return (
      <div
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle
        }}
        dangerouslySetInnerHTML={{ __html: this.props.data.value }}
      ></div>
    );
  }
}

export default CustomHtml;
