import FormService from "../../../services/form";
import ContactService from "../../../services/contact";
import { getRandomAlphaNumericString } from "../../../utility/Utility";

export default class Template1 {
  static getData() {
    return {
      metaData: null,
      scripts: null,
      hash: getRandomAlphaNumericString(),
      designElements: [
        {
          type: "Image",
          value:
            "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/70932/1625644346480_form_template_1.png",
          style: {
            width: "100%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 10,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            textAlign: "center"
          }
        },
        {
          type: "Text",
          value: "Fill out the form to get Our latest papers for free",
          style: {
            width: "70%",
            boxSizing: "border-box",
            fontSize: 38,
            color: "#000000",
            textAlign: "center",
            fontWeight: "bold",
            paddingTop: 16,
            paddingBottom: 8,
            paddingLeft: 8,
            paddingRight: 8,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            overflow: "hidden",
            alignSelf: "center",
            whiteSpace: "pre-wrap"
          }
        },
        {
          type: "Form",
          value: FormService.getContactData(true),
          segmentId:
            ContactService.getSegments().length > 0
              ? ContactService.getSegments()[0].id
              : "",
          style: {
            width: "80%",
            boxSizing: "border-box",
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: 16,
            marginBottom: 16,
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 0,
            backgroundColor: "transparent",
            alignSelf: "center",
            overflow: "hidden",
            fieldSpacing: 10,
            labelSize: 12,
            labelColor: "#000000",
            fieldRadius: 4,
            fieldBorder: "#EBEBEB",
            fieldBackground: "#F8F7FA"
          },
          button: {
            value: "Submit",
            style: {
              buttonColor: "#ffffff",
              buttonSize: 18,
              buttonBackground: "#000000"
            }
          },
          thankYouMsg: "Thank you for submitting form!",
          redirectURL: ""
        }
      ]
    };
  }
}
