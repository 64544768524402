import ApiConstants from "../constants/ApiConstants";
import httpClient from "../http";

export const DEFAULT_PAGE_SIZE = 30;
export const FILE_UID_LENGTH = 14;

class AuditLog {
    static fetchAuditLogs = (params,searchText="") => {
        params = { ...params, query: 'appName=CRM3',q:searchText };
        return httpClient.get(ApiConstants.URL.IMPORT_EXPORT.AUDIT_LOGS, { params});
    }

    static fetchTicketAuditLogs = (params, searchText="", payload) => {
        params = { ...params, query: 'appName=CRM3',q:searchText };
        return httpClient.post(ApiConstants.URL.IMPORT_EXPORT.AUDIT_LOGS, payload, { params });
    }
}

export default AuditLog;
