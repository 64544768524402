import { Component } from "react";
import {
  DKInput,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  DKLabel,
  DKButton
} from "deskera-ui-library";
import Utility from "../../../utility/Utility";
import CompressedImagePicker from "../../common/CompressedImagePicker";

interface ITemplateSelectionProps {
  data: any;
  onSave: (data) => void;
  onCancel: () => void;
}

interface ITemplateSelectionState {
  canValidate: boolean;
  metaData: any;
}

class MetaDataEditor extends Component<
  ITemplateSelectionProps,
  ITemplateSelectionState
> {
  constructor(props) {
    super(props);
    this.state = {
      canValidate: false,
      metaData: props.data
        ? Utility.makeCopyOfObject(props.data)
        : this.getDefaultData()
    };
  }

  render() {
    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ padding: 24 }}>
          {this.getHeader()}
          {this.getMetadataForm()}
        </div>
      </div>
    );
  }

  getDefaultData() {
    return {
      title: "",
      description: "",
      canonicalUrl: "",
      faviconUrl: ""
    };
  }

  getHeader() {
    return (
      <div className="row justify-content-between">
        <DKLabel text="Add Metadata" className="fs-l fw-m" />
        <div className="row" style={{ width: "auto" }}>
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m"
            onClick={() => {
              this.props.onCancel();
            }}
          />
          <DKButton
            title="Save"
            className="bg-button text-white ml-r"
            onClick={() => {
              this.saveTapped();
            }}
          />
        </div>
      </div>
    );
  }

  getMetadataForm() {
    const direction = INPUT_VIEW_DIRECTION.VERTICAL;
    return (
      <div className="parent-width mt-r">
        <DKInput
          title="Title"
          required={false}
          type={INPUT_TYPE.TEXT}
          value={this.state.metaData.title}
          canValidate={this.state.canValidate}
          onChange={(value) => this.updateFormData("title", value)}
          direction={direction}
          capitalised={false}
        />
        <div className="column mt-m parent-width">
          <DKLabel className="mb-s" text="Description" />
          <textarea
            rows={3}
            value={this.state.metaData.description || ""}
            name="message"
            className="parent-width border-box p-v-s p-h-s bg-gray1 border-none border-radius-s app-font "
            style={{ minHeight: "5rem", outline: "none", resize: "none" }}
            onChange={(e) => {
              this.updateFormData("description", e.target.value);
            }}
          />
        </div>
        <DKInput
          title="Canonical URL"
          required={false}
          type={INPUT_TYPE.TEXT}
          value={this.state.metaData.canonicalUrl}
          canValidate={this.state.canValidate}
          onChange={(value) => this.updateFormData("canonicalUrl", value)}
          direction={direction}
          capitalised={false}
        />
        <div className="column mt-l parent-width p-r bg-gray1 border-radius-s mb-m">
          <DKLabel
            text="<b>Favicon</b> - Select an icon below"
            className="mb-xs"
          />
          <DKLabel
            text="This icon will appear on the browser's tab"
            className="text-gray mb-l"
          />
          <CompressedImagePicker
            onSave={(url) => this.updateFormData("faviconUrl", url)}
          />
        </div>
      </div>
    );
  }

  updateFormData(property, value) {
    let metaData = this.state.metaData;
    metaData[property] = value;
    this.setState({ metaData: metaData });
  }

  saveTapped = () => {
    this.props.onSave(this.state.metaData);
  };

  isEmpty(data) {
    return data === null || data === undefined || data.toString().trim() === "";
  }
}

export default MetaDataEditor;
