import { Component } from "react";
import { DKLabel, DKIcon, DKButton } from "deskera-ui-library";
import ic_no_data from "../../assets/icons/ic_no_data_3.png";

/*
- title
- className
- isButton
- buttonTitle
- onClick()
*/

class NoRecordFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        className={
          "column align-self-center align-items-center position-absolute " +
          (this.props.className || "")
        }
        style={{ top: this.props?.top || "30%" }}
      >
        <DKIcon
          src={this.props?.icon ? this.props?.icon : ic_no_data}
          className={`ic-l ${this.props?.iconClass}`}
          style={{ opacity: 0.2, marginTop: 70 }}
        />
        <DKLabel
          text={`${this.props.title ? this.props.title : "No data found"}`}
          className="fw-m mt-l"
        />
        <DKLabel
          text={
            this.props.subTitle || "Once data is available, it will appear here"
          }
          className="text-gray mt-s text-align-center"
        />
        {this.props.isButton && (
          <DKButton
            title={`${this.props.buttonTitle ? this.props.buttonTitle : "Save"}`}
            className="bg-button text-white mt-l"
            onClick={() => {
              this.props.onClick();
            }}
          />
        )}
      </div>
    );
  }
}

export default NoRecordFound;
