import {
  COLUMN_CODE,
  TableManger as TableManager,
  TableManger,
  TABLES
} from "../managers/TableManger";
import Table from "./table";
import TableDataParser from "../Helper/TableDataParser";
import http from "../http";
import ApiConstants from "../constants/ApiConstants";
import { APP_NAME } from "../constants/Constant";
import ContactManager from "../managers/ContactManager";

class ContactService {
  static addColumn(data, onSuccess, onError) {
    let tableId = TableManager.getTableId(TABLES.CONTACT);
    let columnData = TableDataParser.getColumnToAdd(data);
    Table.addColumn(columnData, tableId).then(
      (response) => {
        onSuccess({ data: response, columnData: columnData });
      },
      (error) => {
        onError(error);
      }
    );
  }

  static setTableColumns(onSuccess, onError) {
    Table.getTable(TableManger.getTableId(TABLES.CONTACT)).then(
      (data) => {
        TableManger.setTableColumns(data, TABLES.CONTACT);
        onSuccess();
      },
      (error) => {
        onError(error);
      }
    );
  }

  static updateColumn(columnData, onSuccess, onError) {
    let tableId = TableManager.getTableId(TABLES.CONTACT);
    Table.updateColumn(columnData, tableId).then(
      (response) => {
        onSuccess({ data: response });
      },
      (error) => {
        onError(error);
      }
    );
  }

  static getSegments() {
    let segment = TableManger.getColumn(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.SEGMENT
    );
    return segment.options || [];
  }

  static clearAllContacts() {
    let tableId = TableManager.getTableId(TABLES.CONTACT);
    return http
      .post(`${ApiConstants.URL.TABLE.DELETE_ALL_RECORD(tableId)}`)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static getAllContactEmailLog(query) {
    return http.get(ApiConstants.URL.EMAIL_LOG.GET_LOG, { params: query }).then(
      (res: any) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getEmailsByContactId(recordId) {
    let queryParams = {
      app_name: APP_NAME,
      query: `crm3_contact_id=` + recordId
    };
    return http
      .get(ApiConstants.URL.EMAIL_LOG.GET_EMAIL, { params: queryParams })
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static checkEmailSetup() {
    let query = { app_name: APP_NAME };
    return http
      .get(ApiConstants.URL.EMAIL_LOG.CHECK_EMAIL_SETUP, { params: query })
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static saveEmailLog(data) {
    return http
      .post(ApiConstants.URL.EMAIL_LOG.GET_LOG, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static saveEmail(data) {
    return http
      .post(ApiConstants.URL.EMAIL_LOG.GET_EMAIL + "?app_name=CRM3", data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static unlinkEmail(id) {
    return http.get(ApiConstants.URL.EMAIL_LOG.GET_UNLINK(id)).then(
      (res: any) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getEmailThread(id) {
    return http.get(ApiConstants.URL.EMAIL_LOG.GET_UNLINK(id)).then(
      (res: any) => {
        return Promise.resolve(res);
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }

  static getLinkThreadData(queryParams) {
    return http
      .get(ApiConstants.URL.EMAIL_LOG.GET_EMAIL_THREAD, { params: queryParams })
      .then(
        (res: any) => {
          return Promise.resolve(res);
        },
        (err) => {
          return Promise.reject(err);
        }
      );
  }

  static getDealByContactId(contactId) {
    let req = { pageNo: 1, pageSize: 500, fetchAllRef: true };
    return Table.getRecordsByPage(
      req,
      ContactManager.getQuery(contactId),
      TableManger.getTableId(TABLES.DEAL)
    ).then((res) => {
      return Promise.resolve(res);
    });
  }
  static restartWatch() {
    return http.get(`${ApiConstants.URL.EMAIL_LOG.RESTART_WATCH}`);
  }
}

export default ContactService;
