import ApiConstants from "../constants/ApiConstants";
import httpClient from "../http";
export class Team {
  static getTeams(params?: any) {
    return httpClient.get(ApiConstants.URL.TEAMS.GET, { params });
  }
  static addTeam(payload: any, params?: any) {
    return httpClient.post(ApiConstants.URL.TEAMS.POST, payload, { params });
  }
  static updateTeam(payload: any, params?: any) {
    return httpClient.put(ApiConstants.URL.TEAMS.PUT(payload.id), payload, {
      params
    });
  }
  static deleteTeam(id) {
    return httpClient.delete(ApiConstants.URL.TEAMS.DELETE(id));
  }
  static getTeamPermission(params?: any) {
    return httpClient.get(ApiConstants.URL.TEAMS.GET_PERMISSIONS, { params });
  }
  static updateTeamPermission(payload: any, params?: any) {
    return httpClient.patch(ApiConstants.URL.TEAMS.PATCH, payload, { params });
  }
  static deleteTeamPermission(payload) {
    return httpClient.delete(ApiConstants.URL.TEAMS.DELETE_PERMISSIONS, {
      data: payload
    });
  }
}
