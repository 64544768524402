import ApiConstants from "../constants/ApiConstants";
import { CHART_INTERVALS } from "../constants/Constant";
import httpClient from "../http/index";
class Dashboard {
  static getDealCountByStage({ pipelineId, startDate = null, endDate = null }) {
    if (!startDate || !endDate)
      [startDate, endDate] = this.getDefaultStartEndDate(
        CHART_INTERVALS[2].interval
      );
    return httpClient.get(ApiConstants.URL.DASHBOARD.DEAL_COUNT_BY_STAGE, {
      params: {
        pipelineId,
        startDate,
        endDate
      }
    });
  }
  static getRecordCountByColumn({
    tableId,
    columnId,
    startDate = null,
    endDate = null,
    interval = "weekly"
  }) {
    if (!startDate || endDate)
      [startDate, endDate] = this.getDefaultStartEndDate(interval);
    return httpClient.get(
      ApiConstants.URL.DASHBOARD.RECORD_COUNT_BY_COLUMN_ID(tableId, columnId),
      {
        params: {
          startDate,
          endDate
        }
      }
    );
  }
  static getDealWonLostCount({
    pipelineId = null,
    startDate = null,
    endDate = null,
    interval = "weekly"
  }) {
    if (!startDate || endDate)
      [startDate, endDate] = this.getDefaultStartEndDate(interval);
    return httpClient.get(ApiConstants.URL.DASHBOARD.DEAL_WON_LOST, {
      params: {
        startDate,
        pipelineId,
        endDate,
        interval,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    });
  }
  static getDataByTrend({
    tableId = null,
    startDate = null,
    endDate = null,
    interval = "weekly"
  }) {
    if (!startDate || endDate)
      [startDate, endDate] = this.getDefaultStartEndDate(interval);
    return httpClient.get(ApiConstants.URL.DASHBOARD.RECORD_BY_TREND(tableId), {
      params: {
        startDate,
        endDate,
        interval,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    });
  }
  static getDefaultStartEndDate(interval): [startDate: Date, endDate: Date] {
    const currentDate = new Date();
    let endDate = new Date();
    let startDate = new Date();
    switch (interval) {
      case CHART_INTERVALS[0].interval:
        startDate.setDate(currentDate.getDate() - 6);
        endDate.setDate(currentDate.getDate());
        break;
      case CHART_INTERVALS[1].interval:
        startDate.setMonth(currentDate.getMonth() - 1);
        endDate.setDate(currentDate.getDate() + 1);
        break;
      case CHART_INTERVALS[2].interval:
        startDate.setFullYear(currentDate.getFullYear() - 1);
        startDate.setMonth(currentDate.getMonth() - 11);
        endDate.setMonth(currentDate.getMonth() + 1);
        break;
    }

    return [startDate, endDate];
  }
  static getPreference() {
    return httpClient.get(ApiConstants.URL.DASHBOARD.GET_PREFERENCE);
  }
  static setPreference(payload) {
    return httpClient.patch(ApiConstants.URL.DASHBOARD.SET_PREFERENCE, payload);
  }
}
export default Dashboard;
