import React, { Component } from "react";
import { LOGS_TAB } from "../../constants/Enum";
import IndiaMartLeadSyncLogs from "../../pages/IndiaMartLeadSyncLogs";
import AuditLogs from "../../pages/AuditLogs";
import AutomationLogs from "../../pages/AutomationLogs";
import ImportLog from "../../pages/ImportLog";
import RouteManager from "../../managers/RouteManager";
import PriceBookLog from "../../pages/PriceBookLog";
import { LOG_SCROLL_CONTAINER_ID } from "../../constants/Constant";

class Logs extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: LOGS_TAB.AUDIT
    };
  }
  componentDidMount() {
    RouteManager.setPresenter(this);
  }
  getTabs = () => {
    const tabs = [
      { name: "Audit Logs", value: LOGS_TAB.AUDIT, isHidden: false },
      { name: "Automation Logs", value: LOGS_TAB.AUTOMATION, isHidden: false },
      { name: "Import Logs", value: LOGS_TAB.IMPORT, isHidden: false },
      {
        name: "Price Book Logs",
        value: LOGS_TAB.PRICE_BOOK_LOGS,
        isHidden: false
      },
      { name: "Lead Sync Logs", value: LOGS_TAB.LEAD_SYNC, isHidden: false }
    ];

    let tabView = [];
    tabs
      .filter((tab) => !tab.isHidden)
      .forEach((tab) => {
        tabView.push(
          <div
            className={`text-align-center ${this.state.activeTab === tab.value ? "fw-m active-border text-app" : "text-dark-gray"} cursor-hand`}
            style={{
              padding: "18px 1.8%"
            }}
            onClick={() => {
              this.setState({ activeTab: tab.value });
            }}
          >
            {tab.name}
          </div>
        );
      });
    return (
      <div
        className="row bg-white border-m-b hide-scroll-bar"
        style={{ overflowX: "auto", minHeight: 58 }}
      >
        {tabView}
      </div>
    );
  };
  getActivePanel = () => {
    switch (this.state.activeTab) {
      case LOGS_TAB.LEAD_SYNC:
        return <IndiaMartLeadSyncLogs />;
      case LOGS_TAB.AUDIT:
        return <AuditLogs />;
      case LOGS_TAB.AUTOMATION:
        return <AutomationLogs view="Tab" />;
      case LOGS_TAB.IMPORT:
        return <ImportLog />;
      case LOGS_TAB.PRICE_BOOK_LOGS:
        return <PriceBookLog />;
      default:
        break;
    }
  };
  render() {
    return (
      <div className="column parent-height parent-width border-radius-s mb-m padding-top-mobile mobile-parent-width p-0">
        {this.getTabs()}
        <div
          id={LOG_SCROLL_CONTAINER_ID}
          className="column parent-width parent-height mt-m position-relative align-items-start hide-scroll-bar"
          style={{ overflowY: "auto" }}
        >
          {this.getActivePanel()}
        </div>
      </div>
    );
  }
}

export default Logs;
