import { showLoader, removeLoader } from "deskera-ui-library";
import ApiConstants from "../constants/ApiConstants";
import http from "../http";

export default class InviteService {
  static invite(data: any) {
    showLoader("Inviting user...");
    return http
      .post(ApiConstants.URL.INVITE.INVITE_USER, data)
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
  static cancelInvite(userId: any) {
    showLoader("Cancelling invite...");
    return http
      .delete(ApiConstants.URL.INVITE.CANCEL_INVITE(userId))
      .then(
        (response) => {
          removeLoader();
          return Promise.resolve(response);
        },
        (error) => {
          removeLoader();
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        removeLoader();
        return Promise.reject(error);
      });
  }
}
