import ApiConstants from "../constants/ApiConstants";
import http from "../http";

export enum LEAD_APP_NAME {
  INDIAMART = "indiamart",
  JUSTDIAL = "justdial"
}

export default class IndiaMartService {
  static async getAccountConfigs(
    apiName: LEAD_APP_NAME = LEAD_APP_NAME.INDIAMART
  ) {
    return http
      .get(ApiConstants.URL.INDIAMART.GET_ACCOUNTS, {
        params: { apiName }
      })
      .then((res: any) => Promise.resolve(res))
      .catch((err: any) => Promise.reject(err));
  }

  static async updateAccountConfig(
    payload,
    apiName: LEAD_APP_NAME = LEAD_APP_NAME.INDIAMART
  ) {
    return http
      .patch(ApiConstants.URL.INDIAMART.UPDATE_CONFIG, {
        ...(payload || {}),
        apiName
      })
      .then((res: any) => Promise.resolve(res))
      .catch((err: any) => {
        console.error(
          "Error while trying to update IndiaMart lead sync setting: ",
          err
        );
        return Promise.reject(err);
      });
  }

  static async deleteAccountConfig(id: string) {
    return http
      .delete(ApiConstants.URL.INDIAMART.DELETE_ACCOUNT(id))
      .then((res: any) => Promise.resolve(res))
      .catch((err: any) => Promise.reject(err));
  }

  static async getIndiaMartLeadSyncLogs(
    params: any,
    apiName: LEAD_APP_NAME = LEAD_APP_NAME.INDIAMART
  ) {
    return http.get(ApiConstants.URL.INDIAMART.LEAD_SYNC_LOGS, {
      params: {
        integrationApiName: apiName,
        ...(params || {})
      }
    });
  }
}
