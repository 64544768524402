import React, { Component } from "react";
import { DKButton, DKIcons, DKLabel, DKIcon } from "deskera-ui-library";
import {
  defaultCustomNumberFormatPreFixSuffix,
  generateSequenceCodeWithPrefixSuffix
} from "./CustomNumber";
import { CUSTOM_NUMBER_INPUT_MODULES } from "../../../constants/Constant";
import { getCapitalized } from "../../../utility/Utility";
/*
PROPS
    - className
    - iconClassName
    - title
    - data
    - onSelect={(index, value)}
    - onClose
    - needIcon
    - createNewButton ({title, icon, className, style, onClick })
    - manualInputButton ({title, icon, className, style, onClick })
*/

export default class CustomDropdowList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataInit: this.props.data,
      data: this.props.data,
      textFieldValue: ""
    };
  }
  componentWillReceiveProps(nextProps) {}
  render() {
    return (
      <div
        id="dk-list-picker"
        className={
          "column shadow-ms border-radius-s pb-s " + this.props.className
        }
        style={{
          ...{
            backgroundColor: "white",
            width: this.props.width ? this.props.width : null
          },
          ...this.props.style
        }}
      >
        <div className="parent-width ">
          <div>
            {this.props.title && (
              <div className="row justify-content-between p-h-r pt-r ">
                {this.props.title && (
                  <DKLabel
                    className="fw-m fs-m unselectable"
                    text={this.props.title}
                  />
                )}
                {this.props.onClose && (
                  <DKButton
                    className="ml-l"
                    onClick={this.props.onClose}
                    style={{ padding: 0 }}
                  />
                )}
              </div>
            )}
            {this.state.dataInit.length > 6 && this.getTextField()}
          </div>
          <div
            style={{
              overflowY: "scroll",
              maxHeight: 300
            }}
            className="hide-scroll-bar mt-s"
          >
            {this.getList(this.state.data, this.props.needIcon)}
          </div>
          {this.props.manualInputButton && this.getManualInputButton()}
          {this.props.createNewButton && this.getButton()}
          {(this.state.data === null ||
            this.state.data === undefined ||
            this.state.data.length === 0) && (
            <DKLabel
              text="No data found"
              className="text-align-center p-v-xl text-gray"
            />
          )}
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        dataInit: this.props.data,
        data: this.props.data
      });
    }
  }

  componentDidMount() {
    if (this.props.onClose) {
      document.addEventListener("mouseup", this.onMouseUp);
    }
  }
  componentWillUnmount() {
    if (this.props.onClose) {
      document.removeEventListener("mouseup", this.onMouseUp);
    }
  }
  onMouseUp = (e) => {
    if (this.props.onClose) {
      const target = e.target;
      const editingContainer = target.closest("#dk-list-picker");

      if (!editingContainer) {
        this.props.onClose();
      }
    }
  };

  getButton() {
    const buttonData = this.props.createNewButton;
    return (
      <div className="border-box">
        <DKButton
          icon={buttonData.icon}
          title={buttonData.title}
          onClick={() => {
            buttonData.onClick();
          }}
          className={"parent-width border-box " + buttonData.className}
          style={buttonData.style}
        />
      </div>
    );
  }

  getManualInputButton() {
    const buttonData = this.props.manualInputButton;
    return (
      <div className="border-box">
        <DKButton
          icon={buttonData.icon}
          title={buttonData.title}
          onClick={() => {
            buttonData.onClick();
          }}
          className={"parent-width border-box " + buttonData.className}
          style={buttonData.style}
        />
      </div>
    );
  }

  getList(list, needIcon) {
    let arr = [];
    for (let i = 0; i < list.length; i++) {
      arr.push(this.listItem(list[i], i, needIcon));
    }
    return arr;
  }

  getRenderListItemName(value) {
    if (
      defaultCustomNumberFormatPreFixSuffix(value.module, value.sequenceCode)
    ) {
      return (
        <div className="d-flex">
          <div className={value.isDefault ? "mr-xs font-semibold" : "mr-s"}>
            {value.isDefault ? "*" : ""}
          </div>
          <DKLabel
            className="unselectable truncate"
            text={generateSequenceCodeWithPrefixSuffix(value)}
          />
        </div>
      );
    } else {
      return (
        <div className="d-flex">
          <div className={value.isDefault ? "mr-xs font-semibold" : "mr-s"}>
            {value.isDefault ? "*" : ""}
          </div>
          <DKLabel
            className="unselectable truncate"
            text={getCapitalized(value?.sequenceCode || "")}
          />
        </div>
      );
    }
  }

  listItem(value, index, needIcon) {
    return (
      <div className="row listPickerBG cursor-hand p-h-r p-v-s">
        <div className="d-flex justify-between parent-width items-center">
          <div
            className="parent-width"
            onClick={() => {
              this.props.onSelect(this.state.dataInit.indexOf(value), value);
            }}
          >
            {this.getRenderListItemName(value)}
          </div>
          {value?.module !== CUSTOM_NUMBER_INPUT_MODULES?.JE &&
            value?.text !== "JE-0000000" && (
              <div className="d-flex opacity-50">
                <DKIcon
                  onClick={() =>
                    this.props.onEdit(this.state.dataInit.indexOf(value), value)
                  }
                  src={DKIcons.ic_edit}
                  className="ic-s ml-s cursor-hand"
                />
                <DKIcon
                  onClick={() =>
                    this.props.onDelete(
                      this.state.dataInit.indexOf(value),
                      value
                    )
                  }
                  src={DKIcons.ic_delete}
                  className="ic-s ml-r cursor-hand"
                />
              </div>
            )}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////

  getTextField() {
    return (
      <div
        className={
          "parent-width border-box p-h-r app-font " +
          (this.props.title ? "mt-r" : "mt-l")
        }
      >
        <input
          autoComplete="off"
          spellCheck={false}
          className="border-none bg-gray1 border-s p-h-s p-v-xs border-radius-s  border-box parent-width"
          style={{ outline: "none" }}
          onChange={(e) => {
            this.textChanged(e.target.value);
          }}
          placeholder="Search here"
        />
      </div>
    );
  }

  textChanged = (value) => {
    this.setState({
      textFieldValue: value
    });
    this.filterDataFor(value);
  };

  filterDataFor(value) {
    let mainData = this.state.dataInit;
    let filteredData = mainData.filter((obj) =>
      obj?.sequenceCode?.toLowerCase().startsWith(value.toLowerCase())
    );
    this.setState({
      data: value === "" ? mainData : filteredData
    });
  }
}
