import React, { Component } from "react";
import {
  DKLabel,
  DKCheckMark,
  DKIcon,
  DKIcons,
  DKLine
} from "deskera-ui-library";
import { shiftArrayElement } from "../../../utility/Utility";
import { connect } from "react-redux";
import {
  updateDashboardOrder,
  setUserPreferences,
  DEFAULT_DASHBOARD_CARD_ORDER
} from "../../../redux/slices/userPrefSlice";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { differenceBy } from "lodash";

class ChartRearrangePopup extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      orderList: JSON.parse(JSON.stringify(this.props.cards))
    };
  }
  componentDidMount() {
    let { orderList } = this.state;
    orderList = [
      ...orderList,
      ...differenceBy(DEFAULT_DASHBOARD_CARD_ORDER, orderList, "name").map(
        (item) => ({ ...item, visible: false })
      )
    ];
    this.setState({ orderList });
  }
  render() {
    return (
      <div className="transparent-background">
        <div
          className="popup-window p-h-r"
          style={{ maxWidth: 400, overflowY: "scroll" }}
        >
          {this.getHeader()}
          <SortableList
            items={this.state.orderList}
            onSortEnd={this.onSortEnd}
            distance={20}
            lockAxis="y"
            onChartTypeSelect={this.onChartTypeChange}
            onCheck={this.showHideCard}
          />
        </div>
      </div>
    );
  }
  onSortEnd = ({ newIndex, oldIndex }) => {
    const result = shiftArrayElement(
      [...this.state.orderList],
      oldIndex,
      newIndex
    );
    this.setState({ orderList: result }, () => {
      this.props.setUserPreferences({ dashboardSettings: { order: result } });
    });
  };
  getHeader = () => {
    return (
      <div className="row justify-content-between mb-s align-items-start mb-m">
        <div className="column">
          <DKLabel text="Arrange Dashboard" className="fw-b fs-m" />
          <DKLabel
            text="You can re-arrange and show/hide cards"
            className="fw-r fs-r text-gray mt-s"
          />
        </div>
        <div className="row width-auto">
          <DKIcon
            src={DKIcons.ic_close_}
            className="ic-xs cursor-hand opacity-5"
            onClick={() => this.props.onClose?.()}
          />
        </div>
      </div>
    );
  };
  showHideCard = (index) => {
    let { orderList } = this.state;
    orderList = orderList.map((chart, i) => {
      if (index === i) {
        chart.visible = !chart.visible;
      }
      return chart;
    });
    this.setState({ orderList }, () => {
      this.props.setUserPreferences({
        dashboardSettings: { order: orderList }
      });
    });
  };
  onChartTypeChange = (chartIndex, type) => {
    const { orderList } = this.state;
    orderList[chartIndex].chartType = type;
    this.setState({ orderList }, () => {
      this.props.setUserPreferences({
        dashboardSettings: { order: orderList }
      });
    });
  };
}
const SortableItem = SortableElement(
  ({ chart, index, onCheck, onChartTypeSelect }) => {
    return (
      <>
        <div
          id={`canvas-${index}-component`}
          className={
            "cursor-hand canvas-section bg-white unselectable row justify-content-between listPickerBG p-h-r parent-width"
          }
        >
          <div className="parent-width mt-m column justify-content-between">
            <div className="row justify-content-between">
              <DKCheckMark
                isSelected={chart.visible}
                onClick={() => onCheck(index)}
                title={`${getCardName(chart.name)}`}
              />

              <img
                src={DKIcons.ic_menu}
                className="ic-s opacity-2 unselectable"
                draggable="false"
                alt="drag"
              />
            </div>
            <DKLine className="mt-m unselectable" />
          </div>
          {/* {chart.chartType && <div>
                    <DKDropdown
                        className={`position-absolute`}
                        title={CHART_TYPE[chart.chartType].name}
                        data={Object.keys(CHART_TYPE).map((key) => CHART_TYPE[key].name)}
                        onSelect={(index) => onChartTypeSelect(Object.keys(CHART_TYPE)[index])}
                        width={180}
                    />
                </div>} */}
        </div>
      </>
    );
  }
);
const getCardName = (module) => {
  switch (module) {
    case "CONTACT_BY_LABEL":
      return "Contact by Label";
    case "DEALS_BY_PIPELINE":
      return "Deals by Pipeline";
    case "DEAL_WON_LOST":
      return "Deals Won vs Lost";
    case "CONTACT_TREND":
      return "Contact Trend";
    case "CAMPAIGN":
      return "Campaigns";
    case "LANDING_PAGE":
      return "Landing Pages";
    case "FORM":
      return "Forms";
    /* case 'FUNNEL':
            return 'Funnels'; */
    case "AUTOMATION":
      return "Automation";
    case "ACTIVITY":
      return "Activity";
    case "TICKET":
      return "Ticket";
    default:
      return null;
  }
};

const SortableList = SortableContainer(
  ({ items, onCheck, onChartTypeSelect }) => {
    return (
      <div
        id={"dk-chart-reorder-component"}
        className={
          "column border-radius-s parent-width align-items-stretch bg-gray1s"
        }
      >
        {items.map((chart, index) => (
          <SortableItem
            chart={chart}
            index={index}
            onCheck={() => onCheck(index)}
            onChartTypeSelect={(chartType) =>
              onChartTypeSelect(index, chartType)
            }
          />
        ))}
      </div>
    );
  }
);

const mapStateToProps = (state) => ({
  cards: state.userPref.dashboardCardOrder
});
const mapDispatchToProps = {
  updateDashboardOrder,
  setUserPreferences
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartRearrangePopup);
