import React, { Component } from "react";
import "./dashboard.scss";
import {
  DKLabel,
  DKSpinner,
  DKButton,
  DKIcons,
  DKLine,
  INPUT_TYPE,
  DKInput,
  DKRadioButton
} from "deskera-ui-library";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import DashboardCard from "../common/Dashboard/DashboardCard";
import ContactByLabel from "./Charts/ContactByLabel";
import PipelineChart from "./Charts/PipelineChart";
import DealWonLostChart from "./Charts/DealWonLostChart";
import ContactByTrend from "./Charts/ContactByTrend";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Utility, { shiftArrayElement } from "../../utility/Utility";
import { connect } from "react-redux";
import {
  updateDashboardOrder,
  setUserPreferences as setDashboardOrder,
  setSettingPopup
} from "../../redux/slices/userPrefSlice";
import { TABLES } from "../../managers/TableManger";
import ChartRearrangePopup from "./Charts/ChartRearrangePopup";
import { isEqual } from "lodash";
import {
  isMobileAppWebView,
  isViewportLarge
} from "../../utility/GetViewportSize";
import MobileDownloadPopup from "./DownloadMobile/MobileDownloadPopup";
import MobileDownloadBanner from "./DownloadMobile/MobileDownloadBanner";
import {
  MOBILE_DOWNLOAD_POPUP_CLOSED,
  MOBILE_DOWNLOAD_BANNER_CLOSED
} from "../../constants/Constant";
import { commonCustomEvent, COMMON_EVENTS } from "../../services/event";
import { AUTHORIZATION_STATUS } from "../../constants/Enum";
import ReportTable from "./ReportTable/ReportTable";
import { profitAndLossData } from "./ReportTable/ProfitAndLossData";
import { profitAndLossDepartmentData } from "./ReportTable/ProfitAndLossDepartmentData";
import { profitAndLossByMonth } from "./ReportTable/ProfitAndLossByMonth";
import { profitAndLossByYear } from "./ReportTable/ProfitAndLossByYear";

import ic_books_bg from "./ReportTable/ic_books_bg.png";
import AppManager from "../../managers/AppManager";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: JSON.parse(
        JSON.stringify(this.props.cards.filter((card) => card.visible))
      ),
      showChartRearrangePopup: false,
      needMobilePopup:
        !isMobileAppWebView() &&
        Utility.getPersistentValue(MOBILE_DOWNLOAD_POPUP_CLOSED) !== "true",
      needMobileBanner:
        !isMobileAppWebView() &&
        Utility.getPersistentValue(MOBILE_DOWNLOAD_BANNER_CLOSED) !== "true" &&
        Utility.getPersistentValue(MOBILE_DOWNLOAD_POPUP_CLOSED) === "true",
      reportTableColumnBy: {
        id: 0,
        title: "Total Only"
      },
      reportData: profitAndLossData
    };
  }

  componentDidMount() {
    RouteManager.setPresenter(this);

    if (AppManager.isCurrentRoute(PAGE_ROUTES.SETTINGS)) {
      this.props.setSettingPopup(true);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.cards, this.props.cards)) {
      this.setState({ cards: this.props.cards.filter((card) => card.visible) });
    }
  }
  render() {
    return (
      <div className="parent-width">
        {/* {this.getReportTable()} */}
        {this.state.needMobileBanner && this.getMobileView()}
        {this.state.needMobilePopup && this.getMobilePopup()}
        <div className="row justify-content-between" style={{ marginTop: -10 }}>
          <DKLabel text="Dashboard" className="fw-m fs-m" />
          <DKButton
            title="Re-arrange"
            icon={DKIcons.ic_settings}
            className="bg-white border-m"
            onClick={this.showHideChartRearrangePopup}
          />
        </div>
        {this.state.showChartRearrangePopup && (
          <ChartRearrangePopup onClose={this.showHideChartRearrangePopup} />
        )}
        <SortableList
          items={this.state.cards}
          onSortEnd={this.onSortEnd}
          distance={20}
          axis="xy"
          helperClass="SortableHelper"
        />
      </div>
    );
  }

  getReportTable() {
    return (
      <div className="transparent-background">
        <div
          className="column popup-window border-box position-relative"
          style={{
            width: "100%",
            maxWidth: "100%",
            height: "100%",
            maxHeight: "100%",
            backgroundColor: "rgb(245, 245, 245)",
            borderRadius: 0,
            padding: 0,
            overflowY: "hidden"
          }}
        >
          <img
            src={ic_books_bg}
            alt=""
            className="parent-width position-absolute"
          />
          <div className="row" style={{ marginTop: 60 }}>
            <div style={{ width: 230 }} />
            <div
              className="flex-1 column align-items-center"
              style={{ overflowY: "scroll", height: window.innerHeight - 65 }}
            >
              {this.getReportHeader()}
              <div className="column align-items-center border-m border-radius-s p-xl bg-white unselectable z-index-1">
                <div
                  className="row justify-content-between"
                  style={{ marginTop: -10 }}
                >
                  <div className="row" style={{ width: 110 }}></div>
                  <div className="row width-auto">
                    <DKButton icon={DKIcons.ic_email} className="opacity-5" />
                    <DKButton icon={DKIcons.ic_printer} className="opacity-5" />
                    <DKButton icon={DKIcons.ic_export} className="opacity-5" />
                    <DKButton
                      icon={DKIcons.ic_settings}
                      className="opacity-5"
                    />
                  </div>
                </div>
                <DKLabel
                  text="Dell Computers Pvt. Ltd."
                  className="fs-xl text-dark-gray mt-m"
                  style={{ fontWeight: 300 }}
                />
                <DKLabel text="Profit & Loss" className="mt-l fw-b fs-m" />
                <DKLabel
                  text="January - February, 2022"
                  className="mt-s mb-m"
                />

                <div
                  className="pb-l"
                  style={{
                    maxWidth: window.innerWidth - 320,
                    overflow: "scroll"
                  }}
                >
                  <ReportTable
                    data={this.state.reportData}
                    onExpandCollapse={(data) => {
                      alert(JSON.stringify(data));
                      // data.rowData.cells[1].title = data.rowData.expanded
                      //   ? data.rowData.totalB
                      //   : "$ 2000.00";
                      // this.setState({});
                    }}
                  />
                </div>
                <DKLine />
                <DKLine />
                <DKLabel
                  text="Accrual basis Tuesday, March 1, 2022 09:53 AM GMT+05:30"
                  className="mt-xxl mb-xl text-gray"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getReportHeader() {
    let valueStyle = {
      paddingTop: 0,
      paddingBottom: 0,
      minHeight: 26,
      marginTop: 3,
      backgroundColor: "rgb(240, 240, 243)"
    };
    let titleStyle = { color: "gray", fontSize: 12 };
    let inputWidth = 150;
    return (
      <div className="column border-box parent-width  p-h-l z-index-2 ">
        <DKLabel text="Profit & Loss Report" className="fw-m fs-m mt-m" />
        <div
          className="row mt-m mb-l bg-white p-l border-radius-s border-m "
          style={{ gap: 25 }}
        >
          <div style={{ width: inputWidth }}>
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              direction="Vertical"
              title="Column by"
              titleStyle={titleStyle}
              valueStyle={valueStyle}
              value={this.state.reportTableColumnBy}
              displayKey="title"
              onChange={(value) => {
                let data = [];
                if (value.id === 3) {
                  data = profitAndLossByMonth;
                } else if (value.id === 5) {
                  data = profitAndLossByYear;
                } else if (value.id === 6) {
                  data = profitAndLossDepartmentData;
                } else {
                  data = profitAndLossData;
                }
                this.setState({
                  reportTableColumnBy: value,
                  reportData: data
                });
              }}
              dropdownConfig={{
                className: "",
                style: {},
                allowSearch: false,
                searchableKey: undefined,
                data: [
                  { id: 0, title: "Total Only" },
                  { id: 1, title: "Days" },
                  { id: 2, title: "Weeks" },
                  { id: 3, title: "Months" },
                  { id: 4, title: "Quarters" },
                  { id: 5, title: "Years" },
                  { id: 6, title: "Departments" },
                  { id: 7, title: "Customers" }
                ],
                renderer: (index, obj) => {
                  return <DKLabel text={obj.title} />;
                },
                onSelect: (index, value) => {}
              }}
            />
          </div>
          <div style={{ width: inputWidth }}>
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              direction="Vertical"
              title="Comparison mode"
              titleStyle={titleStyle}
              valueStyle={valueStyle}
              value={{ id: 0, title: "Off" }}
              displayKey="title"
              onChange={(value) => {}}
              dropdownConfig={{
                className: "",
                style: {},
                allowSearch: false,
                searchableKey: undefined,
                data: [
                  { id: 0, title: "Off" },
                  { id: 0, title: "6 Months" },
                  { id: 0, title: "3 Years" },
                  { id: 0, title: "Quarterly" },
                  { id: 0, title: "Customize" }
                ],
                renderer: (index, obj) => {
                  return <DKLabel text={obj.title} />;
                },
                onSelect: (index, value) => {}
              }}
            />
          </div>
          <div style={{ width: inputWidth }}>
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              direction="Vertical"
              title="Date range"
              titleStyle={titleStyle}
              valueStyle={valueStyle}
              value={{ id: 0, title: "All Dates" }}
              displayKey="title"
              onChange={(value) => {}}
              dropdownConfig={{
                className: "",
                style: {},
                allowSearch: false,
                searchableKey: undefined,
                data: [
                  { id: 0, title: "All Dates" },
                  { id: 1, title: "Today" },
                  { id: 2, title: "This Week" },
                  { id: 3, title: "This Month" },
                  { id: 4, title: "This Year" },
                  { id: 5, title: "Last Week" },
                  { id: 6, title: "Last Month" },
                  { id: 7, title: "Last Year" },
                  { id: 8, title: "Customize" }
                ],
                renderer: (index, obj) => {
                  return <DKLabel text={obj.title} />;
                },
                onSelect: (index, value) => {}
              }}
            />
          </div>
          <div style={{ width: inputWidth + 20 }}>
            <DKInput
              type={INPUT_TYPE.DROPDOWN}
              required={false}
              direction="Vertical"
              title="Active or Non-Zero row"
              titleStyle={titleStyle}
              valueStyle={valueStyle}
              value={{ id: 0, title: "All Rows" }}
              displayKey="title"
              onChange={(value) => {}}
              dropdownConfig={{
                className: "",
                style: {},
                allowSearch: false,
                searchableKey: undefined,
                data: [
                  { id: 0, title: "Show All Rows" },
                  { id: 1, title: "Active Rows" },
                  { id: 2, title: "Non-zero Rows" }
                ],
                renderer: (index, obj) => {
                  return <DKLabel text={obj.title} />;
                },
                onSelect: (index, value) => {}
              }}
            />
          </div>
          <div className="column">
            <DKLabel
              style={titleStyle}
              text="Accounting Method"
              className="mb-m"
            />
            <div className="row width-auto">
              <DKRadioButton
                title="Accural"
                color="bg-blue"
                className="mr-l"
                isSelected={true}
              />
              <DKRadioButton title="Cash" color="bg-blue" isSelected={false} />
            </div>
          </div>
          <div className="bg-gray2 parent-height" style={{ width: 1 }} />
          <div className="column">
            <DKLabel style={titleStyle} text="Custom filter" className="mb-s" />
            <DKButton icon={DKIcons.ic_filter} />
          </div>
          <DKButton
            title="Run Report"
            className="border-blue text-blue ml-xl fw-m"
          />
        </div>
      </div>
    );
  }

  ///////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////

  getMobilePopup() {
    return (
      <MobileDownloadPopup
        onClose={() => {
          this.setState({
            needMobilePopup: false,
            needMobileBanner: true
          });
          Utility.setPersistentValue(MOBILE_DOWNLOAD_POPUP_CLOSED, true);
          /* For showing subscription/release alerts after mobile download popup is closed, to avoid overlapping */
          commonCustomEvent.dispatch(COMMON_EVENTS.SUBSCRIPTION_CHECK, {
            status: AUTHORIZATION_STATUS.INITIATE
          });
        }}
      />
    );
  }

  getMobileView() {
    return (
      <MobileDownloadBanner
        onClose={() => {
          this.setState({
            needMobileBanner: false
          });
          Utility.setPersistentValue(MOBILE_DOWNLOAD_BANNER_CLOSED, true);
        }}
      />
    );
  }

  ///////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////

  onSortEnd = ({ newIndex, oldIndex }) => {
    let cards = JSON.parse(JSON.stringify(this.state.cards));
    cards = shiftArrayElement(cards, oldIndex, newIndex);
    this.setState({ cards }, () => {
      this.props.setDashboardOrder({ dashboardSettings: { order: cards } });
    });
  };
  showHideChartRearrangePopup = () => {
    this.setState({
      showChartRearrangePopup: !this.state.showChartRearrangePopup
    });
  };
}

export const getDashboardWidgetLoading = () => {
  return (
    <div className="parent-width parent-height row align-items-center justify-content-center">
      <DKSpinner iconClassName="ic-s-2" title="Loading..." />
    </div>
  );
};

const CardWrapper = (props) => (
  <div
    className="bg-white p-l border-m shadow-s border-radius-m border-box dashboard-card column unselectable cursor-hand"
    style={{ height: 302 }}
  >
    {props.children}
  </div>
);

const ListElement = ({ module }) => {
  switch (module) {
    case "CONTACT_BY_LABEL":
      return (
        <CardWrapper>
          {" "}
          <ContactByLabel />{" "}
        </CardWrapper>
      );
    case "DEALS_BY_PIPELINE":
      return (
        <CardWrapper>
          {" "}
          <PipelineChart />{" "}
        </CardWrapper>
      );
    case "DEAL_WON_LOST":
      return (
        <CardWrapper>
          {" "}
          <DealWonLostChart />{" "}
        </CardWrapper>
      );
    case "CONTACT_TREND":
      return (
        <CardWrapper>
          {" "}
          <ContactByTrend />{" "}
        </CardWrapper>
      );
    case "CAMPAIGN":
      return (
        <CardWrapper>
          {" "}
          <DashboardCard product={TABLES.CAMPAIGN} />{" "}
        </CardWrapper>
      );
    case "LANDING_PAGE":
      return (
        <CardWrapper>
          {" "}
          <DashboardCard product={TABLES.LANDING_PAGE} />{" "}
        </CardWrapper>
      );
    case "FORM":
      return (
        <CardWrapper>
          {" "}
          <DashboardCard product={TABLES.FORM} />{" "}
        </CardWrapper>
      );
    /*case "FUNNEL":
      return (
        <CardWrapper>
          {" "}
          <DashboardCard product={TABLES.FUNNEL} />{" "}
        </CardWrapper>
      ); */
    case "AUTOMATION":
      return (
        <CardWrapper>
          {" "}
          <DashboardCard product={TABLES.AUTOMATION} />{" "}
        </CardWrapper>
      );
    case "ACTIVITY":
      return (
        <CardWrapper>
          <DashboardCard product={TABLES.ACTIVITY} />
        </CardWrapper>
      );
    case "TICKET":
      return (
        <CardWrapper>
          <DashboardCard product={TABLES.TICKET} />
        </CardWrapper>
      );
    default:
      return null;
  }
};

const SortableItem = SortableElement(({ module }) => {
  return <ListElement module={module} />;
});

const SortableList = SortableContainer(({ items }) => {
  return (
    <div
      className="row flex-wrap dashboard-card-wrapper pb-xxl align-items-stretch"
      style={{ columnGap: "2%" }}
    >
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value.name}`}
          index={index}
          module={value.name}
          disabled={isMobileAppWebView() || !isViewportLarge()}
        />
      ))}
    </div>
  );
});

const mapStateToProps = (state) => ({
  cards: state.userPref.dashboardCardOrder
});
const mapDispatchToProps = {
  updateDashboardOrder,
  setDashboardOrder,
  setSettingPopup
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
