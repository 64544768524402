import { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  DKLabel,
  DKButton,
  DKListPicker2,
  DKIcons,
  showLoader,
  removeLoader,
  showAlert
} from "deskera-ui-library";
import MoreIconBlack from "../../assets/icons/ic_more_vertical.png";
import MoreIconWhite from "../../assets/icons/white/ic_more.png";
import SideBarService from "../../services/sidebar";
import "./segment.scss";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import Table from "../../services/table";
import Utility from "../../utility/Utility";

/*
PROPS:
 - className
 - data ([{id, name}])
 - selectedId (id: selected Segment)
 - onChange
 - onClickNew
 - maxWidth (Controls maxWidth for whole segmentListContainer)
*/
const SegmentListContainer = ({
  data,
  selectedId,
  onChange,
  onClickNew,
  maxWidth,
  allowToAddSegment,
  allowToUpdateSegment = true,
  allowToDeleteSegment = true,
  onClickEdit,
  allowDeleteFromModule,
  onClickDelete,
  module = "",
  onSave
}) => {
  const [showSegmentPicker, setShowSegmentPicker] = useState(false);
  const [showMoreList, setShowMoreList] = useState([]);
  const [selectedSegmentOverflown, setSelectedSegmentOverflown] =
    useState(false);
  const [controlWidth, setControlWidth] = useState(getListWidth());

  const segmentListElement = useRef();

  function onResize(e) {
    setControlWidth(getListWidth());
  }

  useEffect(() => {
    window.addEventListener("onSideBarChange", onResize);
    return () => {
      window.removeEventListener("onSideBarChange", onResize);
    };
  }, []);

  function getElementEndPosition(element) {
    return element.offsetLeft + element.clientWidth;
  }

  useLayoutEffect(() => {
    const container = segmentListElement.current;
    const segmentControls = container.childNodes;

    /* Reset Segments Display */
    for (const segmentControlElement of segmentControls) {
      segmentControlElement.hidden = false;
    }

    const containerEnd = getElementEndPosition(container);
    const overflownElements = [];
    let isSelectedSegmentOverflown = false;

    for (const segmentControlElement of segmentControls) {
      const segmentIndex = Number(
        segmentControlElement.getAttribute("data-index")
      );

      const segmentName = segmentControlElement.getAttribute("data-name");

      if (
        getElementEndPosition(segmentControlElement) > controlWidth &&
        getElementEndPosition(segmentControlElement) > containerEnd
      ) {
        overflownElements.push({ id: segmentIndex, name: segmentName });
        segmentControlElement.hidden = true;
        if (selectedId === segmentIndex) {
          isSelectedSegmentOverflown = true;
        }
      }
    }

    setShowMoreList(overflownElements);
    setSelectedSegmentOverflown(isSelectedSegmentOverflown);
  }, [data, controlWidth, selectedId]);

  function getListWidth() {
    /* More Dropdown & Add buttons width */
    const controlsWidth = 70;
    const availableWidth = SideBarService.getContainerWidth();

    let requiredWidth = availableWidth - controlsWidth;

    if (maxWidth) {
      requiredWidth = Math.min(requiredWidth, maxWidth - controlsWidth);
    }

    return requiredWidth;
  }

  return (
    <div className="row mt-m mb-s p-v-r justify-content-center">
      <div
        className="d-flex shadow-s" /* "show-scroll-bar" */
        style={{
          // overflow: "visible",
          maxWidth: controlWidth
        }}
        ref={segmentListElement}
        onMouseLeave={() => onCanvasLeave()}
      >
        {data.map((segment, index) => (
          <div
            id={`segment${segment.id}`}
            key={`segment-${index}`}
            data-index={segment.id}
            data-name={segment.name}
            className={`position-relative p-xs border-box ${!Utility.isEmptyObject(module) ? "bg-gray1" : "bg-white"} ${index === 0 ? " segment-ctrl-first" : ""} ${
              !allowToAddSegment &&
              index === data.length - 1 &&
              showMoreList.length === 0
                ? " segment-ctrl-last"
                : ""
            }`}
            style={{
              height: 34
            }}
            onMouseOver={(e) => {
              if (allowToAddSegment || allowDeleteFromModule) {
                tooltipShow(`tooltip-${segment.id}-segment`);
                e.stopPropagation();
              }
            }}
          >
            {index !== 0 && getCanvasTooltip(segment.id, segment.systemField)}
            <div
              className={`row parent-height border-radius-s ${
                selectedId === segment.id ? "bg-button text-white fw-m" : ""
              }`}
              onClick={() => onChange(segment, index)}
            >
              <DKLabel
                className={"p-h-l pt-l cursor-hand row"}
                style={{
                  marginTop: 16,
                  overflowWrap: "unset",
                  whiteSpace: "nowrap",
                  top: "50%",
                  transform: "translateY(-50%)"
                }}
                text={segment.name}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex shadow-s position-relative">
        {showMoreList.length ? (
          <DKButton
            style={{
              borderLeft: "1px solid lightgray",
              marginLeft: "-2px",
              height: 34
            }}
            className={`border-radius-none z-index-1
              ${selectedSegmentOverflown ? "bg-app" : "bg-white"}
              ${!allowToAddSegment && "segment-ctrl-last"}
            `}
            icon={selectedSegmentOverflown ? MoreIconWhite : MoreIconBlack}
            onClick={() => {
              onCanvasLeave();
              setShowSegmentPicker(true);
            }}
          />
        ) : null}
        {allowToAddSegment && (
          <DKButton
            style={{
              borderLeft: "1px solid lightgray",
              height: 34
            }}
            className="segment-ctrl-last bg-white"
            icon={DKIcons.ic_add}
            onClick={onClickNew}
          />
        )}
        {showSegmentPicker ? (
          <DKListPicker2
            title={`${allowDeleteFromModule ? "More Product Groups" : "More Segments"}`}
            className="position-absolute border-m shadow-m z-index-3"
            style={{ top: 10, right: 32, width: 200 }}
            data={showMoreList}
            onSelect={(index, obj) => {
              onChange(obj);
              setShowSegmentPicker(false);
            }}
            onClose={() => {
              setShowSegmentPicker(false);
            }}
            renderer={(index, obj) => (
              <DKLabel
                style={{ overflowWrap: "anywhere" }}
                text={selectedId === obj.id ? `${obj.name} ✓` : obj.name}
              />
            )}
            canEdit={allowToUpdateSegment}
            canDelete={allowToDeleteSegment}
            onEdit={(index, value) => {
              onClickEdit(value.id);
              setShowSegmentPicker(false);
            }}
            onDelete={(index, value) => {
              if (allowDeleteFromModule) {
                onClickDelete(value?.id);
              } else {
                onDeleteSegment(value.id);
              }
              setShowSegmentPicker(false);
            }}
          />
        ) : null}
      </div>
    </div>
  );

  function onDeleteSegment(id) {
    let segList = data.filter((segment) => segment.name !== "All");
    let segmentIndex = segList.findIndex((segment) => segment.id === id);
    if (segmentIndex === -1) {
      return;
    }
    segList.splice(segmentIndex, 1);

    showLoader("Please wait...");
    let segmentColumn = TableManger.getColumn(
      TABLES.CONTACT,
      COLUMN_CODE.CONTACT.SEGMENT
    );

    segmentColumn.options = segList;
    Table.updateColumn(segmentColumn, TableManger.getTableId(TABLES.CONTACT))
      .finally(() => removeLoader())
      .then((data) => {
        Table.getTable(TableManger.getTableId(TABLES.CONTACT)).then((res) => {
          TableManger.setTableColumns(res, TABLES.CONTACT);
        });
        onSave(true);
      });
  }

  function segmentAction(id, action) {
    if (action === "edit") {
      onClickEdit(id);
    }
    if (action === "delete") {
      if (allowDeleteFromModule) {
        onClickDelete(id);
      } else {
        const buttons = [
          {
            title: "Cancel",
            className: "bg-gray1 border-m"
          },
          {
            title: "Delete",
            className: "bg-red text-white ml-r",
            onClick: () => onDeleteSegment(id)
          }
        ];
        showAlert(
          "Delete Segment?",
          "Deleting this segment will delete it permanently, you will not be able to restore it.",
          buttons
        );
      }
    }
  }

  function tooltipShow(id) {
    onCanvasLeave();
    setTooltip(id);
  }

  function onCanvasLeave() {
    const allTooltip = document.getElementsByClassName("dk-segment-tooltip");
    for (let i = 0; i < allTooltip.length; i++) {
      allTooltip[i].style.display = "none";
    }
    const elements = document.getElementsByClassName("segment-active");
    if (elements.length > 0) {
      elements[0].classList.remove("segment-active");
    }
  }

  function setTooltip(id) {
    let index = id.replace("tooltip-", "").replace("-segment", "");
    let canvas = document.getElementById(id);
    let tooltip = document.getElementById(`tooltip-${index}-segment`);
    if (tooltip) {
      tooltip.style.display = "flex";
      canvas.className = canvas.className + " segment-active";
    }
  }

  function getCanvasTooltip(index, isSystemField) {
    return (
      <div
        className="column parent-width position-absolute dk-segment-tooltip"
        id={`tooltip-${index}-segment`}
        style={{ top: -27, zIndex: 9, display: "none", opacity: 0.7 }}
      >
        <div className="row justify-content-center">
          {allowToUpdateSegment && (
            <DKButton
              icon={DKIcons.ic_edit}
              className="bg-gray1 border-radius-none"
              style={{ padding: 5 }}
              onClick={() => {
                segmentAction(index, "edit");
              }}
            />
          )}
          {!isSystemField && allowToDeleteSegment && (
            <DKButton
              icon={DKIcons.ic_delete}
              className="bg-gray1 border-radius-none"
              style={{ padding: 5 }}
              onClick={() => {
                segmentAction(index, "delete");
              }}
            />
          )}
        </div>
      </div>
    );
  }
};

export default SegmentListContainer;
