import { createSlice } from "@reduxjs/toolkit";
import { FILTER_LOGICAL_OPERATORS } from "../../constants/Enum";
import { ITableFilterCondition } from "../../model/Table";
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_SEARCH_QUERY = "";

/* *************** Types ****************** */
export interface TableMetaData {
  filter: {
    logicalOperator: FILTER_LOGICAL_OPERATORS;
    conditions: ITableFilterCondition[];
  };
  page: number;
  pageSize?: number;
  searchQuery: string;
  sortConfig?: {
    sortCol: string;
    sortDir: 1 | -1 | "ASC" | "DESC";
  };
}
export interface TableMetaDataState {
  contact: TableMetaData;
  account: TableMetaData;
  books_quote: TableMetaData;
  segment: TableMetaData;
  deal: TableMetaData;
  campaign: TableMetaData;
  landing_page: TableMetaData;
  activity: TableMetaData;
  automation: TableMetaData;
  funnel: TableMetaData;
  form: TableMetaData;
  order: TableMetaData;
  overdueActivity: TableMetaData;
  books_invoice: TableMetaData;
}

/* *************** INITIAL STATES ****************** */
const initialFilter: any = {
  logicalOperator: FILTER_LOGICAL_OPERATORS.AND,
  conditions: []
};
const initialMetaData: TableMetaData = {
  filter: initialFilter,
  page: DEFAULT_PAGE_NUMBER,
  pageSize: DEFAULT_PAGE_SIZE,
  searchQuery: "",
  sortConfig: null
};
const initialState: TableMetaDataState = {
  contact: initialMetaData,
  account: initialMetaData,
  books_quote: initialMetaData,
  segment: initialMetaData,
  deal: initialMetaData,
  campaign: initialMetaData,
  landing_page: initialMetaData,
  activity: initialMetaData,
  automation: initialMetaData,
  funnel: initialMetaData,
  form: initialMetaData,
  order: initialMetaData,
  overdueActivity: initialMetaData,
  books_invoice: initialMetaData
};

/* *************** REDUCER ****************** */
export const tableMetaDataSlice = createSlice({
  name: "tableMetaData",
  initialState,
  reducers: {
    updateMetaData: (state, action) => {
      state[action.payload.tableName] = action.payload.metaData;
    },
    patchFilterCondition: (state, action) => {
      const filterData =
        state[action.payload.tableName].filter || initialFilter;
      const updatedConditions = [...(filterData?.conditions || [])];
      const existingIndex = updatedConditions.findIndex(
        (condition) => condition.key === action.payload.condition.key
      );
      if (existingIndex === -1) {
        updatedConditions.push(action.payload.condition);
      } else {
        updatedConditions[existingIndex] = action.payload.condition;
      }
      filterData.conditions = updatedConditions;

      state[action.payload.tableName].filter = filterData;
    },
    removeFilterCondition: (state, action) => {
      const filterData =
        state[action.payload.tableName].filter || initialFilter;
      const updatedConditions = [...(filterData?.conditions || [])];
      const existingIndex = updatedConditions.findIndex(
        (condition) => condition.key === action.payload.key
      );
      if (existingIndex !== -1) {
        updatedConditions.splice(existingIndex, 1);
      }
      filterData.conditions = updatedConditions;

      state[action.payload.tableName].filter = filterData;
    },
    updateFilter: (state, action) => {
      state[action.payload.tableName].filter =
        action.payload.filter || initialFilter;
    },
    updatePage: (state, action) => {
      state[action.payload.tableName].page =
        action.payload.page || DEFAULT_PAGE_NUMBER;
      if (action.payload.pageSize) {
        state[action.payload.tableName].pageSize = action.payload.pageSize;
      }
    },
    updateSearchQuery: (state, action) => {
      state[action.payload.tableName].searchQuery =
        action.payload.searchQuery || DEFAULT_SEARCH_QUERY;
    },
    updateSortConfig: (state, action) => {
      state[action.payload.tableName].sortConfig =
        action.payload.sortConfig ?? null;
    }
  }
});
export const {
  updateMetaData,
  updateFilter,
  removeFilterCondition,
  patchFilterCondition,
  updatePage,
  updateSearchQuery,
  updateSortConfig
} = tableMetaDataSlice.actions;

export const selectTableMeta = (state: any, tableName: string) =>
  state.tableMetaData[tableName];

export default tableMetaDataSlice.reducer;
