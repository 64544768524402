import React, { Component } from "react";

/*
PROPS
- data
- index
- borderStyle
*/

export default class CountDownTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    this.onTimeValueSet(this.props.data.value.timer);
  }

  render() {
    let idIndex =
      this.props.columnIndex !== null && this.props.columnIndex !== undefined
        ? this.props.columnIndex + "+" + this.props.index
        : this.props.index;
    return (
      <div
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle,
          display: "block"
        }}
        className="dk-timer"
        id={`dk-timer-${idIndex}`}
        date-timer={this.props.data.value.timer}
      >
        <div
          style={{
            display: "flex",
            justifyContent: this.props.data.style.alignSelf
          }}
        >
          <div
            style={{
              display: "block",
              border: "1px solid #000000",
              padding: "10px 12px",
              backgroundColor: this.props.data.style.timerBgColor,
              borderRadius: this.props.data.style.timerBorderRadius,
              borderColor: this.props.data.style.timerBorderRadiusColor
            }}
          >
            <div
              id={`dk-timer-days-${idIndex}`}
              style={{
                fontSize: this.props.data.style.timerSize,
                color: this.props.data.style.timerColor,
                fontWeight: "bold"
              }}
            >
              00
            </div>
            <div
              style={{ fontSize: 10, color: this.props.data.style.timerColor }}
            >
              DAYS
            </div>
          </div>
          <div style={{ display: "block", padding: 6, alignSelf: "center" }}>
            <div
              style={{
                fontSize: 32,
                fontWeight: "bold",
                color: "rgb(80, 80, 80)"
              }}
            >
              :
            </div>
          </div>
          <div
            style={{
              display: "block",
              border: "1px solid #000000",
              padding: "10px 7px",
              backgroundColor: this.props.data.style.timerBgColor,
              borderRadius: this.props.data.style.timerBorderRadius,
              borderColor: this.props.data.style.timerBorderRadiusColor
            }}
          >
            <div
              id={`dk-timer-hours-${idIndex}`}
              style={{
                fontSize: this.props.data.style.timerSize,
                color: this.props.data.style.timerColor,
                fontWeight: "bold"
              }}
            >
              00
            </div>
            <div
              style={{ fontSize: 10, color: this.props.data.style.timerColor }}
            >
              HOURS
            </div>
          </div>
          <div style={{ display: "block", padding: 6, alignSelf: "center" }}>
            <div
              style={{
                fontSize: 32,
                fontWeight: "bold",
                color: "rgb(80, 80, 80)"
              }}
            >
              :
            </div>
          </div>
          <div
            style={{
              display: "block",
              border: "1px solid #000000",
              padding: "10px 12px",
              backgroundColor: this.props.data.style.timerBgColor,
              borderRadius: this.props.data.style.timerBorderRadius,
              borderColor: this.props.data.style.timerBorderRadiusColor
            }}
          >
            <div
              id={`dk-timer-mins-${idIndex}`}
              style={{
                fontSize: this.props.data.style.timerSize,
                color: this.props.data.style.timerColor,
                fontWeight: "bold"
              }}
            >
              00
            </div>
            <div
              style={{ fontSize: 10, color: this.props.data.style.timerColor }}
            >
              MINS
            </div>
          </div>
          <div style={{ display: "block", padding: 6, alignSelf: "center" }}>
            <div
              style={{
                fontSize: 32,
                fontWeight: "bold",
                color: "rgb(80, 80, 80)"
              }}
            >
              :
            </div>
          </div>
          <div
            style={{
              display: "block",
              border: "1px solid #000000",
              padding: "10px 12px",
              backgroundColor: this.props.data.style.timerBgColor,
              borderRadius: this.props.data.style.timerBorderRadius,
              borderColor: this.props.data.style.timerBorderRadiusColor
            }}
          >
            <div
              id={`dk-timer-secs-${idIndex}`}
              style={{
                fontSize: this.props.data.style.timerSize,
                color: this.props.data.style.timerColor,
                fontWeight: "bold"
              }}
            >
              00
            </div>
            <div
              style={{ fontSize: 10, color: this.props.data.style.timerColor }}
            >
              SECS
            </div>
          </div>
        </div>
      </div>
    );
  }

  onTimeValueSet = (data) => {
    let idIndex =
      this.props.columnIndex !== null && this.props.columnIndex !== undefined
        ? this.props.columnIndex + "+" + this.props.index
        : this.props.index;
    var deadline = new Date(data).getTime();
    var now = new Date().getTime();
    var t = deadline - now;
    var days =
      Math.floor(t / (1000 * 60 * 60 * 24)) > 0
        ? Math.floor(t / (1000 * 60 * 60 * 24))
        : 0;
    var hours =
      Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) > 0
        ? Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        : 0;
    var minutes =
      Math.floor((t % (1000 * 60 * 60)) / (1000 * 60)) > 0
        ? Math.floor((t % (1000 * 60 * 60)) / (1000 * 60))
        : 0;
    var seconds = Math.floor((t % (1000 * 60)) / 1000)
      ? Math.floor((t % (1000 * 60)) / 1000)
      : 0;
    document.getElementById("dk-timer-days-" + idIndex).innerText = (
      "0" + days
    ).slice(-2);
    document.getElementById("dk-timer-hours-" + idIndex).innerText = (
      "0" + hours
    ).slice(-2);
    document.getElementById("dk-timer-mins-" + idIndex).innerText = (
      "0" + minutes
    ).slice(-2);
    document.getElementById("dk-timer-secs-" + idIndex).innerText = (
      "0" + seconds
    ).slice(-2);
  };
}
