import { useEffect, useState } from "react";
import RouteManager from "../../managers/RouteManager";
import "./pageStyles.css";

import AppHeader from "./AppHeader";
import { PlanBand } from "./pricing/PlanBand";
import { PricingCard } from "./pricing/PricingCard";
import { insertStyleSheet } from "./Showcase";

export default function PricingHome(props) {
  useEffect(() => {
    RouteManager.setPresenter({ props });

    insertStyleSheet(
      "https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("Marketing");
  return (
    <div
      className={
        "website-redesign-holder column align-items-center flex-1 parent-width bg-white " +
        (isDarkTheme ? "text-white" : "text-black")
      }
      style={{
        paddingBottom: 100,
        backgroundColor: isDarkTheme ? "black" : "white"
      }}
    >
      <AppHeader isDarkTheme={isDarkTheme} onChangeTheme={setIsDarkTheme} />
      <PlanBand
        isDarkTheme={isDarkTheme}
        onPlanChange={(plan) => setSelectedPlan(plan)}
      />
      <PricingCard isDarkTheme={isDarkTheme} selectedPlan={selectedPlan} />
    </div>
  );
}
