import { commonCustomEvent, COMMON_EVENTS } from "../services/event";
import { matchPath } from "react-router-dom";
import AppManager from "./AppManager";
// import PlanManager from "./PlanManager";
// import AddOnManager from "./AddOnManager";
// import IntercomManager from "./IntercomManager";

export const PAGE_GROUP = {
  SALES: "/sales",
  MARKETING: "/marketing",
  CUSTOMER_SERVICE: "/customer-service",
  ECOMMERCE: "/e-commerce"
};

export const ROUTE_ID_IDENTIFIER = ":id";

export const PAGE_ROUTES = {
  /* Menu Item 1: Dashboard routes */
  DASHBOARD: "/",
  NOTIFICATIONS: "/notifications",
  NO_ACCESS: "/no-access",
  PUBLIC_PAGE: "/public/" + ROUTE_ID_IDENTIFIER,
  PUBLIC_PAGE_PATH: "/public/",
  EMAIL_VERIFICATION: "/public/email-verification/" + ROUTE_ID_IDENTIFIER,

  /* Menu Item 2: Sales Group */
  ACCOUNTS: PAGE_GROUP.SALES + "/accounts",
  ACCOUNT_DETAILS: PAGE_GROUP.SALES + "/account/" + ROUTE_ID_IDENTIFIER,
  CONTACTS: PAGE_GROUP.SALES + "/contacts",
  CONTACTS_UI: PAGE_GROUP.SALES + "/contact/" + ROUTE_ID_IDENTIFIER,
  CONTACT_LEAD_SCORE_UI:
    PAGE_GROUP.SALES + "/contact/lead-score/" + ROUTE_ID_IDENTIFIER,
  IMPORT_PORTAL: "/import-portal",
  LIST: PAGE_GROUP.SALES + "/list",
  DEALS: PAGE_GROUP.SALES + "/deals",
  DEALS_UI: PAGE_GROUP.SALES + "/deal/" + ROUTE_ID_IDENTIFIER,
  ACTIVITY: PAGE_GROUP.SALES + "/activity",
  QUOTES: PAGE_GROUP.SALES + "/quotes",
  QUOTE: PAGE_GROUP.SALES + "/quotes/" + ROUTE_ID_IDENTIFIER,
  INVOICES: PAGE_GROUP.SALES + "/invoices",
  OLD_CONTACT_ROUTE: "/contacts",
  OLD_LIST_ROUTE: "/list",
  OLD_DEAL_ROUTE: "/deals",
  OLD_ACTIVITY_ROUTE: "/activity",
  PRODUCTS: PAGE_GROUP.SALES + "/products",

  /* Menu Item 3: Marketing Group */
  FORMS: PAGE_GROUP.MARKETING + "/forms",
  SELECT_FORM_TEMPLATE: PAGE_GROUP.MARKETING + "/select-form-template",
  FORM_BUILDER: PAGE_GROUP.MARKETING + "/form-builder",
  FORM_DOMAIN: PAGE_GROUP.MARKETING + "/form-domain",
  CAMPAIGN: PAGE_GROUP.MARKETING + "/campaign",
  CAMPAIGN_BUILDER: PAGE_GROUP.MARKETING + "/campaign-builder",
  CREATE_CAMPAIGN: PAGE_GROUP.MARKETING + "/create-campaign",
  SELECT_CAMPAIGN_TEMPLATE: PAGE_GROUP.MARKETING + "/select-campaign-template",
  CAMPAIGN_SUMMARY: PAGE_GROUP.MARKETING + "/campaign-summary",
  CAMPAIGN_SUCCESS: PAGE_GROUP.MARKETING + "/campaign-success",
  CAMPAIGN_DETAILS: PAGE_GROUP.MARKETING + "/campaign-details",
  WA_CAMPAIGN: PAGE_GROUP.MARKETING + "/whatsapp-campaign",
  LANDING_PAGE: PAGE_GROUP.MARKETING + "/landing-pages",
  SELECT_LANDING_PAGE_TEMPLATE:
    PAGE_GROUP.MARKETING + "/select-landing-page-template",
  LANDING_PAGE_BUILDER: PAGE_GROUP.MARKETING + "/landing-page-builder",
  LANDING_PAGE_DOMAIN: PAGE_GROUP.MARKETING + "/landing-page-domain",

  /* Menu Item 4: Customer Service Group */
  SUPPORT_TICKET: PAGE_GROUP.CUSTOMER_SERVICE + "/support-ticket",
  SUPPORT: PAGE_GROUP.CUSTOMER_SERVICE + "/support",
  CHAT: PAGE_GROUP.CUSTOMER_SERVICE + "/chat",

  /* Menu Item 5: ECommerce Group */
  ORDER: PAGE_GROUP.ECOMMERCE + "/orders",
  FUNNEL: PAGE_GROUP.ECOMMERCE + "/funnel",
  FUNNEL_SELECTION: PAGE_GROUP.ECOMMERCE + "/funnel-selection",
  FUNNEL_BUILDER: PAGE_GROUP.ECOMMERCE + "/funnel-builder",
  FUNNEL_DOMAIN: PAGE_GROUP.ECOMMERCE + "/funnel-domain",
  NEW_SHOP: PAGE_GROUP.ECOMMERCE + "/new-shop",
  SHOP_DASHBOARD: PAGE_GROUP.ECOMMERCE + "/shop-dashboard",
  SHOP_LEADS: PAGE_GROUP.ECOMMERCE + "/shop-leads",

  /* Menu Item 6: Automation Group */
  AUTOMATION: "/automation",
  AUTOMATION_BUILDER: "/automation-builder",

  /* Menu Item 7: Reports Group */
  REPORTS: "/reports",
  ACTIVITY_BY_CONTACT_REPORT: "/reports/activity-by-contact-report",
  TICKET_BY_CONTACT_REPORT: "/reports/ticket-by-contact-report",
  UNSUBSCRIBE_REPORT: "/reports/unsubscribe-report",
  CONTACT_WITH_DEALS: "/reports/salesByContact",
  ACTIVITY_REPORT: "/reports/activity-report",
  CUSTOM_REPORT: "/reports/custom-report",
  PIPELINE_STAGE_REPORT: "/reports/pipelinr-stage-Report",

  /* Menu Item 8: Settings Group */
  SETTINGS: "/settings",
  CHAT_SETTING: "/settings/chat",
  IMPORT_LOGS: "/import-logs",
  AUTOMATION_LOGS: "/automation-logs",
  AUDIT_LOGS: "/audit-logs",
  INDIA_MART_LEAD_SYNC_LOGS: "/settings/indiamart-lead-sync",
  BOOK_A_DEMO: "/:settingId/bookademo",
  PAYMENT_INTEGRATION: "/settings/payment-integration",
  SALES_REPRESENTATIVE_SETTING: "/settings/sales-representative",
  MULTI_CURRENCY: "/settings/currency",

  DESKERA_HOME: "/public/deskera-home",
  PRICING_HOME: "/public/pricing",

  MOBILE_PAGE_PATH: "/mobile-app/",
  MOBILE_APP_LANDING: "/mobile-app/landing",

  /* Menu Item 8: Price Book Group */
  PRICE_BOOK: "/price-book",

  /* Menu Item 9: Logs */
  LOGS: "/logs"
};

export const PAGE_GROUP_REDIRECTS = {
  [PAGE_GROUP.SALES]: PAGE_ROUTES.ACCOUNTS,
  [PAGE_GROUP.MARKETING]: PAGE_ROUTES.FORMS,
  [PAGE_GROUP.CUSTOMER_SERVICE]: PAGE_ROUTES.SUPPORT_TICKET,
  [PAGE_GROUP.ECOMMERCE]: PAGE_ROUTES.ORDER
};

export default class RouteManager {
  static presenter = null;
  static setPresenter(presenter) {
    this.presenter = presenter;
  }
  static navigateToPage(pageRoute, param = null) {
    RouteManager.presenter.props.history.push(
      pageRoute + (param ? "?" + param : "")
    );
    AppManager.scrollToTop();
    commonCustomEvent.dispatch(COMMON_EVENTS.NAVIGATION_TRIGGERED, {});
  }

  static navigateToHome() {
    RouteManager.navigateToPage(PAGE_ROUTES.DASHBOARD);
  }

  static replacePageParams(pageRoute = window.location.pathname, param = "") {
    RouteManager.presenter?.props.history?.replace(
      pageRoute + (param ? "?" + param : "")
    );
  }

  static isCurrentRoute(route) {
    return matchPath(window.location.pathname, route)?.isExact;
  }
}
