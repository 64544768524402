import React, { Component } from "react";
import {
  DKIcon,
  DKLabel,
  DKButton,
  DKIcons,
  DKLine,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showLoader,
  removeLoader,
  showAlert
} from "deskera-ui-library";
import stripeIcon from "../../assets/images/stripeLogo.png";
import razorPayIcon from "../../assets/images/razorpayLogo.png";
import paytmIcon from "../../assets/images/paytmLogo.png";
import cashFreeIcon from "../../assets/images/cashfreeLogo.png";
import RouteManager from "../../managers/RouteManager";
import { connect, ConnectedProps } from "react-redux";
import {
  fetchStripeAccount,
  getAccountsByGateway
} from "../../redux/slices/userPrefSlice";
import Utility from "../../utility/Utility";
import { Stripe } from "../../services/stripe";
import PaymentService, {
  IPaymentGatewayRegisterPayload,
  PAYMENT_GATEWAY
} from "../../services/payment";
import ControlledInput from "../common/ControlledInput";
import UserManager from "../../managers/UserManager";

interface IPaymentIntegrationsProps extends PropsFromRedux {}
interface IPaymentIntegrationsState {
  showConnectionFrame: boolean;
  showAddGatewayPopUp: boolean;
  gatewayData: any;
  isBeingSubmitted: boolean;
  paymentTypes: any;
  isStripeConnectingProgress: boolean;
}

const GATEWAY_DATA_PLACEHOLDER = {
  id: {
    value: "",
    invalid: false
  },
  secrete: {
    value: "",
    invalid: false
  },
  name: {
    value: "",
    invalid: false
  }
};
class PaymentIntegrations extends Component<
  IPaymentIntegrationsProps,
  IPaymentIntegrationsState
> {
  PAYMENT_TYPES = [
    {
      name: PAYMENT_GATEWAY.STRIPE,
      icon: stripeIcon,
      title: "Connect With Stripe",
      onClick: () => this.connectStripe()
    },
    {
      name: PAYMENT_GATEWAY.RAZORPAY,
      icon: razorPayIcon,
      title: "Connect With Razorpay",
      onClick: () => this.showHideConnectionFrame(PAYMENT_GATEWAY.RAZORPAY)
    },
    {
      name: PAYMENT_GATEWAY.PAYTM,
      icon: paytmIcon,
      title: "Connect With Paytm",
      transaction_fee_text:
        "Online Transaction Fees - 0.80% fee per transaction for transactions Rs. 2000/- and above and 0% fee below 2000 via UPI.",
      onClick: () => this.showHideConnectionFrame(PAYMENT_GATEWAY.PAYTM)
    },
    {
      name: PAYMENT_GATEWAY.CASHFREE,
      icon: cashFreeIcon,
      title: "Connect With Cashfree",
      transaction_fee_text:
        "Zero fees on transactions via all UPI apps (Google Pay, PhonePe, BHIM etc.) (**Applicable till December, 2021)",
      onClick: () => this.showHideConnectionFrame(PAYMENT_GATEWAY.CASHFREE)
    }
  ];
  selectedGatewayType;
  constructor(props) {
    super(props);
    this.state = {
      showConnectionFrame: false,
      showAddGatewayPopUp: false,
      gatewayData: JSON.parse(JSON.stringify(GATEWAY_DATA_PLACEHOLDER)),
      isBeingSubmitted: false,
      paymentTypes: this.PAYMENT_TYPES,
      isStripeConnectingProgress: false
    };
    this.selectedGatewayType = React.createRef();
    this.selectedGatewayType.current = "";
  }
  /*lifecycle function goes here*/
  componentDidMount() {
    this.checkAndAddStripeAccount(this.props);
    this.props.getAccountsByGateway(PAYMENT_GATEWAY.RAZORPAY);
    this.props.getAccountsByGateway(PAYMENT_GATEWAY.PAYTM);
    this.props.getAccountsByGateway(PAYMENT_GATEWAY.CASHFREE);
  }
  /*
    @params - pass the component props
    @description - this function checks for the code,state and scope keys in props.location
    */
  checkAndAddStripeAccount = (props) => {
    if (window.location && window.location.search) {
      const code = Utility.getQueryParam(window.location, "code");
      const state = Utility.getQueryParam(window.location, "state");
      const scope = Utility.getQueryParam(window.location, "scope");
      if (code && state && scope) {
        this.setState({ isStripeConnectingProgress: true });
        Stripe.addStripeAccount({ code, state })
          .then((data) => {
            return this.props.fetchStripeAccount();
          })
          .catch(() =>
            showAlert("Error", "Error occured while connecting to stripe")
          )
          .finally(() => this.setState({ isStripeConnectingProgress: false }));
      } else {
        console.warn("Incomplete Stripe account data. Navigation prevented.");
      }
    } else {
      this.props.fetchStripeAccount();
    }
  };

  /*Helper Function will go here*/
  showHideConnectionFrame = (gateway = null) => {
    this.selectedGatewayType.current = gateway;
    this.setState((prevState) => ({
      showConnectionFrame: !prevState.showConnectionFrame
    }));
  };
  showAddGatewayPopup = () => {
    this.setState((prevState) => ({
      gatewayData: JSON.parse(JSON.stringify(GATEWAY_DATA_PLACEHOLDER)),
      showAddGatewayPopUp: !prevState.showAddGatewayPopUp
    }));
  };
  connectStripe = () => {
    Stripe.getRedirectURL().then((response) => {
      if (response && response.data) {
        window.open(response.data.url, "_self");
      }
    });
  };
  registerConnection = (code: string) => {
    showLoader();
    PaymentService.registerConnection(code).then((response) => {
      if (response) {
        this.showAddGatewayPopup();
        removeLoader();
      }
    });
  };
  registerGateway = () => {
    this.setSubmitFlag(true);
    let payload: IPaymentGatewayRegisterPayload = {
      merchantId: this.state.gatewayData["id"].value?.trim(),
      merchantKey: this.state.gatewayData["secrete"].value?.trim(),
      accountName: this.state.gatewayData["name"].value,
      state: UserManager.getUserTenantID(),
      gateway: this.selectedGatewayType.current,
      userId: UserManager.getUserID()
    };
    PaymentService.registerGateway(payload)
      .then((response) => {
        this.setSubmitFlag(false);
        this.showAddGatewayPopup();
        this.props.getAccountsByGateway(this.selectedGatewayType.current);
      })
      .catch((err) => {
        this.setSubmitFlag(false);
      });
  };
  onGateWayDataChange = (value, key) => {
    let { gatewayData } = this.state;
    gatewayData[key].value = value;
    gatewayData[key].invalid = Utility.isEmptyObject(value);
    this.setState({ gatewayData });
  };
  onGateWayAdd = () => {
    let { gatewayData } = this.state;
    Object.keys(gatewayData).map((key) =>
      this.onGateWayDataChange(gatewayData[key].value, key)
    );
    setTimeout(() => {
      if (
        Object.keys(this.state.gatewayData).every(
          (key) => !gatewayData[key].invalid
        )
      ) {
        this.registerGateway();
      }
    }, 1000);
  };
  setSubmitFlag = (isSubmitted) => {
    this.setState({ isBeingSubmitted: isSubmitted });
  };
  getInputLabels = () => {
    if (this.selectedGatewayType.current === PAYMENT_GATEWAY.RAZORPAY) {
      return {
        icon: razorPayIcon,
        id: "Key ID",
        secrete: "Key Secret",
        name: "Account Name"
      };
    } else if (this.selectedGatewayType.current === PAYMENT_GATEWAY.PAYTM) {
      return {
        icon: paytmIcon,
        id: "Merchant ID",
        secrete: "Merchant Key",
        name: "Account Name"
      };
    } else if (this.selectedGatewayType.current === PAYMENT_GATEWAY.CASHFREE) {
      return {
        icon: cashFreeIcon,
        id: "App ID",
        secrete: "Secret Key",
        name: "Account Name"
      };
    }
  };
  /*sub-render function goes here*/
  getOAuthConnectionFrame = () => {
    return (
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            width: 900,
            height: 400,
            padding: 12
          }}
        >
          <div
            className={`popup-wrapper parent-width parent-height`}
            style={{ overflow: "hidden" }}
          >
            <div className="row-reverse">
              <div>
                <DKIcon
                  className="ic-s cursor-hand"
                  src={DKIcons.ic_close}
                  onClick={() =>
                    this.showHideConnectionFrame(
                      this.selectedGatewayType.current
                    )
                  }
                />
              </div>
            </div>
            <iframe
              id="client-connection-iframe"
              src={PaymentService.getAuthorizationURL()}
              width={"100%"}
              height={"100%"}
              frameBorder={0}
              onLoad={() => {
                let iFrame = document.getElementById(
                  "client-connection-iframe"
                ) as HTMLIFrameElement;
                if (iFrame?.contentDocument?.URL) {
                  let url = new URL(iFrame?.contentDocument?.URL);
                  let params = new URLSearchParams(url.search);
                  let code = params.get("code");
                  if (code) {
                    this.showHideConnectionFrame(
                      this.selectedGatewayType.current
                    );
                    this.registerConnection(code);
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };
  getAddGateWayPop = () => {
    const labels = this.getInputLabels();
    return (
      <div className="transparent-background">
        <div
          className="popup-window"
          style={{
            width: 400,
            height: 320,
            padding: 10,
            overflow: "hidden"
          }}
        >
          <div className="row">
            <div className="p-s">
              <img
                src={labels.icon}
                alt="razorPayLogo"
                style={{
                  width: "60%",
                  float: "left"
                }}
              />
            </div>
            <div className="row justify-content-end">
              <DKButton
                className="bg-gray2 m-h-s"
                title={"Cancel"}
                onClick={() => this.showAddGatewayPopup()}
              />
              <DKButton
                className="bg-button text-white"
                title={"Save"}
                onClick={() => this.onGateWayAdd()}
                disabled={this.state.isBeingSubmitted}
              />
            </div>
          </div>
          <div className="row m-v-r">
            <ControlledInput
              className="parent-width"
              required
              invalid={this.state.gatewayData.id.invalid}
              name={labels.id}
              type={INPUT_TYPE.TEXT}
              value={this.state.gatewayData.id.value}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              onChange={(e) => this.onGateWayDataChange(e.target.value, "id")}
            />
          </div>
          <div className="row">
            <ControlledInput
              className="parent-width"
              required
              invalid={this.state.gatewayData.secrete.invalid}
              name={labels.secrete}
              type={INPUT_TYPE.TEXT}
              value={this.state.gatewayData.secrete.value}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              onChange={(e) =>
                this.onGateWayDataChange(e.target.value, "secrete")
              }
            />
          </div>
          <div className="row">
            <ControlledInput
              className="parent-width"
              required
              invalid={this.state.gatewayData.name.invalid}
              name={labels.name}
              type={INPUT_TYPE.TEXT}
              value={this.state.gatewayData.name.value}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              onChange={(e) => this.onGateWayDataChange(e.target.value, "name")}
            />
          </div>
        </div>
      </div>
    );
  };
  getButtonTitle(paymentType) {
    switch (paymentType) {
      case PAYMENT_GATEWAY.RAZORPAY:
        return this.props.paymentAccounts?.razorpay.length <= 0
          ? "Connect With Razorpay"
          : null;
      case PAYMENT_GATEWAY.PAYTM:
        return this.props.paymentAccounts?.paytm?.length <= 0
          ? "Connect With Paytm"
          : null;
      case PAYMENT_GATEWAY.CASHFREE:
        return this.props.paymentAccounts?.cashfree?.length <= 0
          ? "Connect With Cashfree"
          : null;
      case PAYMENT_GATEWAY.STRIPE:
        return this.state.isStripeConnectingProgress
          ? "Connecting With Stripe..."
          : !this.props.isStripeConnected
            ? "Connect With Stripe"
            : null;
      default:
        break;
    }
  }
  getDescription(paymentType) {
    switch (paymentType) {
      case PAYMENT_GATEWAY.RAZORPAY:
        return this.props.paymentAccounts?.razorpay.length > 0
          ? "Razorpay account already added"
          : "Integrate your Razorpay account to accept payments.";
      case PAYMENT_GATEWAY.PAYTM:
        return this.props.paymentAccounts?.paytm.length > 0
          ? "Paytm account already added"
          : "Paytm is a payment gateway with which customers can pay you securely through their Debit/Credit Card, Internet Banking or Paytm Wallet.";
      case PAYMENT_GATEWAY.CASHFREE:
        return this.props.paymentAccounts?.cashfree.length > 0
          ? "Cashfree account already added"
          : "Accept domestic and international payments with 120 + payment modes on Cashfree Payment Gateway. Access advanced features like instant settlements, instant refunds and card pre-authorization.";
      case PAYMENT_GATEWAY.STRIPE:
        return !this.props.isStripeConnected
          ? "Integrate your stripe account to accept payments."
          : "Stripe Account Already Connected";
      default:
        break;
    }
  }
  /*render function goes here*/
  render() {
    return (
      <div
        style={{
          flexWrap: "wrap",
          rowGap: 10,
          columnGap: 10
        }}
        className="row align-items-stretch pt-m overflow-y-auto"
      >
        {this.state.paymentTypes.map((payment: any) => (
          <div
            className="bg-white border-radius-m border-m p-r column border-box shadow-s mobile-parent-width justify-content-start"
            style={{ minWidth: "32%", width: "32%", flexWrap: "wrap" }}
            onClick={() => {}}
          >
            <DKIcon src={payment.icon} className="ic-m" />
            <DKLabel text={payment.title} className="fw-m mt-l fs-m" />
            <DKLabel
              text={this.getDescription(payment.name)}
              className="text-dark-gray mt-xs"
            />
            <DKLabel
              text={payment?.transaction_fee_text}
              className="text-dark-gray mt-l mb-xl fs-s text-red"
            />

            {this.getButtonTitle(payment.name) && (
              <DKButton
                title={this.getButtonTitle(payment.name)}
                className="align-self-end bg-gray1 border-m mt-auto justify-self-end"
                icon={DKIcons.ic_arrow_right2}
                onClick={() => {
                  if (payment.url) {
                    window.open(payment.url, "_blank");
                  } else {
                    payment?.onClick?.();
                  }
                }}
                isReverse={true}
              />
            )}
          </div>
        ))}
        {this.state.showConnectionFrame && this.getOAuthConnectionFrame()}
        {this.state.showAddGatewayPopUp && this.getAddGateWayPop()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isStripeConnected: state.userPref.isStripeConnected,
  paymentAccounts: state.userPref.paymentAccounts
});
const mapDispatchToProps = { fetchStripeAccount, getAccountsByGateway };
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PaymentIntegrations);
