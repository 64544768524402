import { Component, Fragment } from "react";
import {
  DKLabel,
  DKIcon,
  DKIcons,
  DKButton,
  DKInputForm,
  DKListPicker2,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION
} from "deskera-ui-library";
import { connect } from "react-redux";
import {
  COLUMN_CODE,
  TableManger,
  TABLES
} from "../../../managers/TableManger";
import { showFormPopup } from "../../menu/AddNewRecordPopup";
import { fetchProducts } from "../../../redux/slices/productSlice";
import Utility, { toCurrencyFormat } from "../../../utility/Utility";
import ProductService from "../../../services/product";
import Popup from "../../common/Popup";
import { CURRENCY_SYMBOLS } from "../../../constants/Currencies";

class ProductEditor extends Component {
  constructor(props) {
    super(props);

    this.productNameColKey = TableManger.getColumnId(
      TABLES.PRODUCT,
      COLUMN_CODE.PRODUCT.NAME
    );

    this.productPriceColKey = TableManger.getColumnId(
      TABLES.PRODUCT,
      COLUMN_CODE.PRODUCT.PRICE
    );

    this.state = {
      needProductPicker: false,
      needProductEditor: false,
      selectedProduct: null
    };
  }

  componentDidMount() {
    if (
      Utility.isEmpty(this.props.products) ||
      this.props.products?.length === 0
    ) {
      ProductService.productApiConfig.SearchTerm = "";
      this.props.fetchProducts();
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  toggleProductPicker = (shouldOpen) => {
    this.setState((prevState) => ({
      needProductPicker: shouldOpen ?? !prevState.needProductPicker
    }));
  };

  setNewCartData = (products = []) => {
    const { discount, shipping, tax } = this.props.cartData;
    let subTotalAmount = 0,
      totalQty = 0;

    products.forEach((product) => {
      const productPrice = Number(product["price"]);
      const productQty = Number(product["quantity"]);

      subTotalAmount += productPrice * productQty;
      totalQty += productQty;
    });

    const totalAmount =
      subTotalAmount - Number(discount) + Number(shipping) + Number(tax);

    const newCartData = {
      ...this.props.cartData,
      productDetails: products,
      subTotalAmount: subTotalAmount,
      totalQty: totalQty,
      totalAmount: totalAmount
    };

    this.props.onDataChange(newCartData);
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  onSelectProduct = (product) => {
    const productDetail = product.cells
      ? {
          id: product._id,
          key: product._id,
          name: product.cells[this.productNameColKey],
          price: product.cells[this.productPriceColKey],
          quantity: 1,
          cells: product.cells
        }
      : {
          id: product.id,
          key: product.id,
          name: product.name,
          price: product.salesPrice,
          quantity: 1,
          product: {
            id: product.id,
            name: product.name,
            description: product.description,
            salesPrice: product.salesPrice
          }
        };

    const cartItems = this.props.cartData.productDetails;

    const existingProductIndex = cartItems.findIndex(
      (cartItem) => `${cartItem.id}` === `${productDetail.id}`
    );

    if (existingProductIndex === -1) {
      cartItems.push(productDetail);
      this.setNewCartData(cartItems);
    } else {
      productDetail.quantity = cartItems[existingProductIndex].quantity;
      cartItems[existingProductIndex] = productDetail;
      this.setNewCartData(cartItems);
    }

    this.toggleProductPicker(false);
  };

  onEditProduct = (productId, productData) => {
    const cells = productData.cells;
    if (cells) {
      /* custom product editor */
      showFormPopup(
        {
          tableName: TABLES.PRODUCT,
          defaultValues: { ...cells },
          recordId: productId
        },
        this.onSelectProduct
      );
    } else {
      this.setState({
        needProductEditor: true,
        selectedProduct: productData.product
      });
    }
  };

  onDeleteProduct = (productIndex) => {
    const { productDetails } = this.props.cartData;
    if (productDetails && productDetails[productIndex]) {
      productDetails.splice(productIndex, 1);
    }

    this.setNewCartData(productDetails);
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  getProductRow = (rowData, productIndex) => {
    if (!rowData) return null;

    return (
      <div className="row justify-content-between parent-width m-v-r">
        <DKLabel
          className="p-s bg-gray1 border-radius-s border-m"
          style={{
            width: "60%"
          }}
          text={rowData.name}
        />
        <DKIcon
          src={DKIcons.ic_edit}
          className="ic-s cursor-hand"
          onClick={() => this.onEditProduct(rowData.id, rowData)}
        />
        <DKIcon
          src={DKIcons.ic_delete}
          className="ic-s cursor-hand"
          onClick={() => this.onDeleteProduct(productIndex)}
        />
      </div>
    );
  };

  getProductsPicker = () => {
    const { products, cartData, customProductRecords } = this.props;

    const currencySymbol = cartData?.currency
      ? CURRENCY_SYMBOLS[cartData.currency]
      : null;

    let allProducts = [...products, ...customProductRecords];

    let filteredCustomProducts = [];

    return this.state.needProductPicker ? (
      <DKListPicker2
        data={allProducts}
        searchableKey={"name"}
        className="position-absolute border-m shadow-m bg-white z-index-3 width-auto"
        style={{
          top: this.props.needProductList ? 50 : 30,
          minWidth: 150,
          maxWidth: 250,
          [this.props.needProductList ? "left" : "right"]: 0
        }}
        renderer={(index, obj) => (
          <div className="row justify-content-between">
            <DKLabel
              text={obj.cells ? obj.cells[this.productNameColKey] : obj.name}
              className="mr-r"
              style={{
                minWidth: 100
              }}
            />
            <DKLabel
              text={toCurrencyFormat(
                obj.cells ? obj.cells[this.productPriceColKey] : obj.salesPrice,
                currencySymbol
              )}
              className="text-gray fs-s"
              style={{
                whiteSpace: "nowrap"
              }}
            />
          </div>
        )}
        onSelect={(index) => this.onSelectProduct(allProducts[index])}
        allowSearch={true}
        searchApiConfig={{
          getUrl: (searchTerm) => {
            filteredCustomProducts = customProductRecords.filter((record) =>
              record.name.includes(searchTerm)
            );
            return ProductService.getProductEndPoint(searchTerm, true);
          },
          dataParser: (response) => [
            ...(response?.content || []),
            ...filteredCustomProducts
          ]
        }}
        button={{
          title: "+ Create Product",
          className: "bg-button text-white",
          onClick: () => {
            this.toggleProductPicker(false);
            showFormPopup(
              {
                tableName: TABLES.PRODUCT,
                defaultValues: []
              },
              this.onSelectProduct
            );
          }
        }}
        onClose={() => setTimeout(() => this.toggleProductPicker(false), 1)}
      />
    ) : null;
  };

  getProductEditPopup = () => {
    const { needProductEditor, selectedProduct } = this.state;

    if (!needProductEditor) {
      return null;
    }

    const fields = [
      {
        title: "name",
        value: selectedProduct.name,
        options: null,
        type: INPUT_TYPE.TEXT,
        required: true
      },
      {
        title: "description",
        value: selectedProduct.description,
        options: null,
        type: INPUT_TYPE.TEXT,
        required: false
      },
      {
        title: "price",
        value: selectedProduct.salesPrice,
        options: null,
        type: INPUT_TYPE.NUMBER,
        required: true
      }
    ];

    return (
      <Popup>
        <DKInputForm
          autoFocus={true}
          title="Edit Product"
          fields={fields}
          direction={INPUT_VIEW_DIRECTION.HORIZONTAL}
          onCancel={() => {
            this.setState({
              needProductEditor: false,
              selectedProduct: null
            });
          }}
          onSubmit={(data) => {
            const updatedProduct = {
              id: this.state.selectedProduct.id
            };
            data.forEach((field) => {
              updatedProduct[field.title] = field.value;
            });

            updatedProduct.salesPrice = updatedProduct.price;

            this.onSelectProduct(updatedProduct);
            this.setState({
              needProductEditor: false,
              selectedProduct: null
            });
          }}
        />
      </Popup>
    );
  };

  render() {
    const { productDetails } = this.props.cartData;
    const isDummy = !productDetails?.length;

    return (
      <Fragment>
        {this.props.needProductList &&
          productDetails.map((product, index) =>
            this.getProductRow(product, index)
          )}
        {isDummy && this.props.needProductList ? (
          <DKLabel
            text="Please add products here to receive order and payment."
            className="text-red m-v-r width-80"
          />
        ) : null}
        <div className="position-relative">
          <DKButton
            className={this.props.buttonClassName}
            style={{
              paddingRight: this.props.buttonClassName?.includes(
                "bg-transparent"
              )
                ? 0
                : 12,
              paddingTop: this.props.buttonClassName?.includes("bg-transparent")
                ? 6
                : 12
            }}
            onClick={(event) => {
              event.stopPropagation && event.stopPropagation();
              this.toggleProductPicker();
            }}
            title="+ Add Product"
          />

          {this.getProductsPicker()}
          {this.getProductEditPopup()}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  customProductRecords: state.records.data[TABLES.PRODUCT]?.data || [],
  products: state.products.data?.content || []
});
const mapDispatchToProps = {
  fetchProducts
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ProductEditor);
