import {
  DKIcon,
  DKIcons,
  DKLabel,
  DKSpinner,
  showAlert
} from "deskera-ui-library";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import {
  fetchEmailSetup,
  fetchSupportEmails
} from "../../redux/slices/userPrefSlice";
import ContactService from "../../services/contact";
import SupportTicketService from "../../services/supportTicket";
import Utility from "../../utility/Utility";

class EmailSyncSettings extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      contactLoading: false,
      supportLoading: false
    };
  }
  refreshContactAndDealEmailSync = () => {
    this.setState({ contactLoading: true }, () => {
      ContactService.restartWatch()
        .then((res) => {
          this.props.fetchEmailSetup(null).then((res) => {
            this.setState({ contactLoading: false });
            this.showAlert();
          });
        })
        .catch((error) => {
          showAlert("Error", error?.errorMessage);
          this.setState({ contactLoading: false });
        });
    });
  };
  refreshSupportEmailSync = () => {
    this.setState({ supportLoading: true });
    this.props
      .fetchSupportEmails()
      .then((res) => {
        if (Utility.isEmptyObject(res?.payload?.data)) {
          this.setState({ supportLoading: false });
          return;
        }
        let defaultEmail = res?.payload?.data?.find(
          (setup) => setup.is_default
        );
        SupportTicketService.restartWatch(defaultEmail?.id).then((res) => {
          this.setState({ supportLoading: false });
          this.showAlert();
        });
      })
      .catch((error) => {
        showAlert("Error", error?.errorMessage);
        this.setState({ supportLoading: false });
      });
  };
  showAlert = () => {
    showAlert("Email Sync", "Email sync has been restarted");
    this.props?.onClose?.();
  };
  render() {
    return (
      <div className="column parent-width border-m border-radius-m position-relative p-r mt-l">
        <div className="row justify-content-between">
          <div className="column">
            <DKLabel text="Email Sync Restart" className="mr-m mb-xs fw-m" />
          </div>
        </div>
        <div className="parent-size">
          <div>
            <div className="column parent-width pt-m">
              <div className={`row parent-width justify-content-between`}>
                <div className="column">
                  <DKLabel text={`Contact and Deal email`} className="mr-m" />
                  <div className="row text-gray mt-s">
                    <DKIcon
                      src={DKIcons.ic_info}
                      className="mr-xs"
                      style={{ opacity: 0.6, height: 11 }}
                    />
                    <DKLabel
                      text={`Restart Contact and Deal email sync`}
                      className="mr-m fs-s"
                    />
                  </div>
                </div>
                {!this.state.contactLoading && (
                  <DKIcon
                    className="ic-s cursor-hand"
                    onClick={() => this.refreshContactAndDealEmailSync?.()}
                    src={DKIcons.ic_reload}
                  />
                )}
                {this.state.contactLoading && <DKSpinner />}
              </div>
              <div className={`row parent-width justify-content-between mt-l`}>
                <div className="column">
                  <DKLabel text={`Support ticket email`} className="mr-m" />
                  <div className="row text-gray mt-s">
                    <DKIcon
                      src={DKIcons.ic_info}
                      className="mr-xs"
                      style={{ opacity: 0.6, height: 11 }}
                    />
                    <DKLabel
                      text={`Restart Support ticket email sync`}
                      className="mr-m fs-s"
                    />
                  </div>
                </div>
                {!this.state.supportLoading && (
                  <DKIcon
                    className="ic-s cursor-hand"
                    onClick={() => this.refreshSupportEmailSync?.()}
                    src={DKIcons.ic_reload}
                  />
                )}
                {this.state.supportLoading && <DKSpinner />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  supportEmails: state.userPref.supportEmails?.data || []
});
const mapDispatchToProps = {
  fetchEmailSetup,
  fetchSupportEmails
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EmailSyncSettings);
