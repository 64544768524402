import React from "react";
import { DKButton, DKLabel, DKIcon } from "deskera-ui-library";
import AnimatedGif from "../../assets/website/crm-animate.gif";
import "./stack_hero/stackStyles.css";
import DKLabel2 from "./DKLabel2";

const PageHero = ({
  className,
  header,
  subtitle,
  buttons = [],
  ratingData = null
}) => {
  return (
    <div
      className={`${className} row row-responsive align-items-stretch`}
      style={{ maxWidth: "80rem" }}
    >
      <div className="column text-section">
        <DKLabel2
          text={header}
          className="hero-page-header header text-align-left fw-h"
          style={{
            lineHeight: 1,
            letterSpacing: " -.025em"
          }}
        />
        <DKLabel2
          text={subtitle}
          className="sub-title mt-l text-align-left text-gray fs-xl"
          style={{
            lineHeight: "1.75rem",
            maxWidth: "48rem"
          }}
        />
        <div className="button-section row row-responsive mt-xxl parent-width">
          {buttons.map((button) => {
            return (
              <DKButton
                title={button.title}
                className={`fw-m fs-l hero-page-button ${button.className}`}
                icon={button?.icon}
                style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
              />
            );
          })}
        </div>
        {ratingData ? (
          <div className="column">
            <div className="rating-section row mt-xxl">
              <DKLabel2 className="fw-b fs-xl mr-l" text={ratingData.label} />
              <DKIcon src={ratingData.icon} />
            </div>
            <DKLabel2 className="mt-s fs-l" text={ratingData.review} />
          </div>
        ) : null}
      </div>
      <div
        className="column media-section p-h-xxl display-only-web"
        style={{
          width: "50%",
          paddingTop: "4rem",
          paddingBottom: "4rem"
        }}
      >
        <DKIcon src={AnimatedGif} className="parent-size" />
      </div>
    </div>
  );
};

export default PageHero;
