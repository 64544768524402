import {
  NEW_SETTINGS_SECTION,
  SETTING_MODULE,
  SETTING_SCREENS
} from "../../constants/Constant";
import "./Settings.scss";
import SettingsLeftPanel from "./SettingsLeftPanel";
import SettingsRightPanel from "./SettingsRightPanel";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectPermissionsByModule } from "../../redux/slices/rolesPermissionSlice";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import { TABLES } from "../../managers/TableManger";
import Utility from "../../utility/Utility";
import UserManager from "../../managers/UserManager";
import { isViewportMobile } from "../../utility/GetViewportSize";
import { DKButton, DKIcons } from "deskera-ui-library";
import { useCallback, useEffect, useState } from "react";
import * as preferenceSlice from "../../redux/slices/userPrefSlice";
import "./Settings.scss";
import { shallowEqual } from "react-redux";

interface SettingsProps {
  onClose: () => void;
  location: any;
}

export interface SettingsOption {
  title: string;
  description: string;
  propertyName: NEW_SETTINGS_SECTION;
  hidden: boolean;
  showHeader: boolean;
  children?: SettingsOption[];
  icon?: any | undefined;
  url?: string | undefined;
  keywords?: string[];
}

const SettingView = ({ onClose, location }: SettingsProps) => {
  const dispatch = useAppDispatch();
  const [selectedSetting, setSelectedSetting] = useState<SettingsOption | null>(
    null
  );
  const [navigationScreen, setNavigationScreen] = useState<any>(
    SETTING_SCREENS.SETTINGS_LEFT_PANEL
  );

  const onSettingSelected = (setting: SettingsOption) => {
    setSelectedSetting(setting);
    setNavigationScreen(SETTING_SCREENS.SETTINGS_RIGHT_PANEL);
  };

  const handleSettingScreenNavigation = (givenscreen) => {
    setNavigationScreen(givenscreen);
  };
  const settingPermissions = useAppSelector(
    (state) => selectPermissionsByModule(state, SETTING_MODULE),
    shallowEqual
  );
  const moduleWisePermissions = useAppSelector((state) =>
    selectPermissionsByModule(state, TABLES.PERMISSION)
  );
  const selectedSettingFromStore = useAppSelector(
    preferenceSlice.selectSelectedSetting
  );

  const [isDeskeraTenant, setIsDeskeraTenant] = useState(false);
  const userEmail = UserManager.getUserEmail();
  const tenantDetails = UserManager.getUserDetails();
  const userId = UserManager.getUserID();

  const getSettingsOptions = useCallback(() => {
    let settingOptions: any = [
      {
        title: "Organisation Settings",
        description: "You can update or modify your organisation details here",
        icon: DKIcons.ic_settings,
        showHeader: true,
        propertyName: NEW_SETTINGS_SECTION.ORGANISATION_SETTINGS,
        keywords: [
          "currency",
          "date format",
          "date setting",
          "email sync",
          "alert restart",
          "contact",
          "deal",
          "support",
          "delete contact",
          "subscription",
          "billing",
          "Two-Factor Authentication (2FA)",
          "Two Factor Authentication (2FA)",
          "Change Password",
          "BDR Settings",
          "Email sync restart",
          "Clear Contacts",
          "Delete Contacts",
          "multi",
          "My Profile",
          "Subscription / Billing",
          "Subscription Billing",
          "Currency Settings",
          "Email Sync Restart",
          "Clear Contacts"
        ]
      },
      {
        title: "Advanced Settings",
        description: "Update or modify your advanced settings here",
        icon: DKIcons.ic_settings,
        showHeader: true,
        propertyName: NEW_SETTINGS_SECTION.ADVANCED_SETTINGS,
        keywords: [
          "email alert",
          "Enable Email Alert",
          "Enable Product Grouping",
          "Enable Account Linkage",
          "Contact Linkage to Quote/Deal",
          "Contact Linkage to Estimate/Deal",
          "Contact Linkage to Quote Deal",
          "Contact Linkage to Estimate Deal",
          "Uplift/DownLift Estimate Price",
          "Uplift DownLift Estimate Price",
          "Estimate Block Edit By Multi Users",
          "Estimate Sync Setting",
          "Estimate Approval Lock",
          "Price Book Module Selection",
          "Estimate Actions Setting",
          "design",
          "print",
          "email",
          "whatsapp",
          "Enable Contact Sequence Code",
          "Support Ticket Auto Reply Email Setting",
          "alert settings",
          "contact",
          "deal",
          "product grouping",
          "grouping",
          "estimate",
          "group",
          "Account Linkage",
          "linked deal",
          "linked contact",
          "Account",
          "Contact disable",
          "link in deal",
          "link in quote",
          "primary",
          "won",
          "draft",
          "sequence number",
          "owner",
          "sub owner",
          "auto populate"
        ]
      }
    ];

    settingPermissions?.[USER_ACTION_TYPES.USER_PERMISSION] &&
      !Utility.isEmptyObject(moduleWisePermissions) &&
      settingOptions.push({
        title: "User Permissions",
        description: "Invite new users to your org and manage user permissions",
        icon: DKIcons.ic_user,
        showHeader: true,
        propertyName: NEW_SETTINGS_SECTION.USER_PERMISSIONS
      });

    settingPermissions?.[USER_ACTION_TYPES.TEAM_PERMISSION] &&
      settingOptions.push({
        title: "Team Permissions",
        description: "Create team and manage team permissions",
        icon: DKIcons.ic_team,
        showHeader: true,
        propertyName: NEW_SETTINGS_SECTION.TEAM_PERMISSIONS
      });

    isDeskeraTenant &&
      settingOptions.push({
        title: "Event Score",
        description: "You can change your event score in setting",
        icon: DKIcons.ic_user,
        showHeader: true,
        propertyName: NEW_SETTINGS_SECTION.EVENT_SCORE
      });

    settingPermissions?.[USER_ACTION_TYPES.DUPLICATION] &&
      settingOptions.push({
        title: "Duplication Settings",
        description: "Manage settings for handling duplicate record in modules",
        icon: DKIcons.ic_copy,
        buttonTitle: "View Settings",
        showHeader: true,
        propertyName: NEW_SETTINGS_SECTION.DUPLICATION_SETTINGS,
        keywords: ["duplicate settings", "duplicate", "duplicate record"]
      });
    settingOptions.push({
      title: "Manage Required Fields",
      description: "Mark fields as required for each module",
      icon: DKIcons.ic_settings,
      buttonTitle: "View Details",
      showHeader: true,
      propertyName: NEW_SETTINGS_SECTION.REQUIRED_FIELDS
    });

    settingPermissions?.[USER_ACTION_TYPES.CHAT] &&
      settingOptions.push({
        title: "Chat Settings",
        description:
          "Add profile details for initial chat details and embed scripts for web pages",
        icon: DKIcons.ic_chat,
        buttonTitle: "View Details",
        showHeader: true,
        propertyName: NEW_SETTINGS_SECTION.CHAT_SETTINGS,
        keywords: [
          "chat",
          "display name",
          "welcome message",
          "embed script",
          "color",
          "position",
          "notification sound"
        ]
      });

    settingPermissions?.[USER_ACTION_TYPES.NOTIFICATION] &&
      settingOptions.push({
        title: "Notification Settings",
        description:
          "Enable or disable notifications for below sections and events",
        icon: DKIcons.ic_notification,
        buttonTitle: "View Details",
        showHeader: true,
        propertyName: NEW_SETTINGS_SECTION.NOTIFICATION_SETTINGS,
        keywords: ["notification settings"]
      });

    settingOptions.push({
      title: "Sync Deskera Activity to Google Calendar",
      description: "Generate import activity url to add in Google Calendar",
      icon: DKIcons.ic_recent,
      buttonTitle: "Generate Url",
      showHeader: true,
      propertyName: NEW_SETTINGS_SECTION.ACTIVITY_SETTING,
      keywords: [
        "activity setting",
        "sync activity",
        "import activity",
        "Link to import activity",
        "import in Google calendar",
        "Activity to Google Calendar"
      ]
    });

    settingPermissions?.[USER_ACTION_TYPES.PAYMENT_INTEGRATION] &&
      settingOptions.push({
        title: "Payment Integration",
        description:
          "Integrate your stripe, razorpay, paytm and cashfree accounts to accept payments",
        icon: DKIcons.ic_currency,
        buttonTitle: "Payment Integration",
        showHeader: true,
        propertyName: NEW_SETTINGS_SECTION.PAYMENT_INTEGRATION,
        keywords: [
          "Payments",
          "integrate",
          "stripe",
          "razorpay",
          "paytm",
          "cashfree"
        ]
      });

    settingPermissions?.[USER_ACTION_TYPES.INDIAMART_INTEGRATION] &&
      settingOptions.push({
        title: "Connect with IndiaMart",
        description: "Sync your leads from your IndiaMart account to CRM",
        icon: DKIcons.ic_indiamart,
        buttonTitle: "Connect Account",
        showHeader: true,
        propertyName: NEW_SETTINGS_SECTION.INDIAMART_INTEGRATION,
        keywords: [
          "connect with indiamart",
          "crm key",
          "sync from",
          "auto sync",
          "indiaMart leads",
          "IndiaMart fields",
          "segment",
          "owners",
          "sender name",
          "sender email",
          "sender mobile",
          "sender company",
          "sender address",
          "sender city",
          "sender state",
          "sender pincode",
          "sender country code",
          "product",
          "category name",
          "message"
        ]
      });

    settingPermissions?.[USER_ACTION_TYPES.JUSTDIAL_INTEGRATION] &&
      settingOptions.push({
        title: "Connect with JustDial",
        description: "Sync your leads from your JustDial account to CRM",
        icon: DKIcons.ic_justdial,
        buttonTitle: "Connect Account",
        showHeader: true,
        propertyName: NEW_SETTINGS_SECTION.JUSTDIAL_INTEGRATION,
        keywords: [
          "JustDial leads",
          "segment",
          "owners",
          "name",
          "email",
          "category",
          "area",
          "branch area",
          "city",
          "mobile",
          "pincode",
          "company",
          "date"
        ]
      });

    // userId === tenantDetails?.id &&
    //   userEmail?.includes(DEMO_EMAIL) &&
    //   settingOptions.push({
    //     title: "Book-A-Demo Settings",
    //     icon: DKIcons.ic_user,
    //     showHeader: true,
    //     propertyName: NEW_SETTINGS_SECTION.BOOK_DEMO_SETTINGS,
    //   });
    return settingOptions;
  }, [settingPermissions, userEmail, tenantDetails, userId]);

  const getFirstVisibleSetting = (settingsList: any) => {
    let firstSetting: any = null;
    settingsList?.forEach((setting: any) => {
      if (!setting.hidden && !firstSetting) {
        firstSetting = setting;
      }
    });
    return firstSetting;
  };

  const checkFormQueryParams = () => {
    if (!location) return;
    const code = Utility.getQueryParam(location, "code");
    const state = Utility.getQueryParam(location, "state");
    const scope = Utility.getQueryParam(location, "scope");
    if (code && state && scope) {
      dispatch(
        preferenceSlice.setSelectedSetting(
          NEW_SETTINGS_SECTION.PAYMENT_INTEGRATION
        )
      );
    }
  };
  useEffect(() => {
    checkFormQueryParams();
  }, []);
  useEffect(() => {
    const settingsList = getSettingsOptions();
    const firstVisibleSetting = settingsList.find((setting) => !setting.hidden);
    setSelectedSetting(firstVisibleSetting || null);
  }, [getSettingsOptions]);

  useEffect(() => {
    if (!selectedSettingFromStore) return;
    const settingsList = getSettingsOptions();
    const flattenedSettingOptions = settingsList.flatMap((setting) =>
      setting.children ? [setting, ...setting.children] : setting
    );
    const filteredSetting = flattenedSettingOptions.find(
      (setting) => setting.propertyName === selectedSettingFromStore
    );
    setSelectedSetting(filteredSetting || null);
    if (
      selectedSettingFromStore === NEW_SETTINGS_SECTION.USER_PERMISSIONS ||
      NEW_SETTINGS_SECTION.TEAM_PERMISSIONS ||
      NEW_SETTINGS_SECTION.PAYMENT_INTEGRATION
    ) {
      setNavigationScreen(SETTING_SCREENS.SETTINGS_RIGHT_PANEL);
    }
    if (
      selectedSettingFromStore === NEW_SETTINGS_SECTION.ORGANISATION_SETTINGS
    ) {
      setNavigationScreen(SETTING_SCREENS.SETTINGS_LEFT_PANEL);
    }
  }, [selectedSettingFromStore]);

  const webstyle = {
    padding: 0,
    height: "95%",
    maxHeight: "95%",
    width: "90%",
    maxWidth: 1070,
    // overflowY: "hidden",
    borderRadius: 8,
    minHeight: 300
  };
  const mobilestyle = {
    padding: 0,
    height: "100%",
    maxHeight: "100%",
    width: "100%",
    // overflowY: "hidden",
    borderRadius: 8,
    minHeight: 300
  };
  const inputStyle = isViewportMobile() ? mobilestyle : webstyle;
  return (
    <div className="transparent-background new-settings-wrapper">
      <div className="popup-window" style={inputStyle}>
        <div className="row parent-size position-relative overflow-y-hidden">
          <div
            className="row position-absolute width-auto"
            style={{ right: 15, top: 15, zIndex: 1 }}
          >
            <DKButton
              icon={DKIcons.ic_close_}
              onClick={onClose}
              className="setting-close-button"
              style={{
                padding: 0
              }}
            />
          </div>

          {isViewportMobile() ? (
            <>
              {navigationScreen === SETTING_SCREENS.SETTINGS_LEFT_PANEL && (
                <div
                  className={`bg-white parent-height overflow-y-hidden`}
                  style={{ width: "100vw" }}
                >
                  <SettingsLeftPanel
                    selectedSetting={selectedSetting}
                    data={getSettingsOptions()}
                    onSettingSelected={onSettingSelected}
                    currentScreen={handleSettingScreenNavigation}
                  />
                </div>
              )}
              {navigationScreen === SETTING_SCREENS.SETTINGS_RIGHT_PANEL && (
                <div
                  className="column parent-size overflow-y-hidden"
                  style={{ width: "100%" }}
                >
                  <SettingsRightPanel
                    selectedSetting={selectedSetting}
                    currentScreen={handleSettingScreenNavigation}
                    onClose={onClose}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div
                className={`bg-gray1 parent-height overflow-y-hidden`}
                style={{ width: 220 }}
              >
                <SettingsLeftPanel
                  selectedSetting={selectedSetting}
                  data={getSettingsOptions()}
                  onSettingSelected={onSettingSelected}
                  currentScreen={handleSettingScreenNavigation}
                />
              </div>
              <div
                className="column parent-size overflow-y-hidden"
                style={{ width: "calc(100% - 220px)" }}
              >
                <SettingsRightPanel
                  selectedSetting={selectedSetting}
                  currentScreen={handleSettingScreenNavigation}
                  onClose={onClose}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default SettingView;
