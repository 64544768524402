import { TableManger, TABLES } from "./TableManger";
import { PipelineManager } from "./PipelineManager";
import { store } from "../redux/store";
import { fetchTables } from "../redux/slices/tableSlice";
import {
  fetchAccounts,
  fetchRecordsByTable,
  getPipelineStages,
  setTableRecords
} from "../redux/slices/recordSlice";
import {
  fetchStripeAccount,
  getUserPreferences,
  getAccountsByGateway,
  getNotification,
  fetchEmailSetup
} from "../redux/slices/userPrefSlice";
import {
  fetchTeams,
  fetchTenants,
  fetchUsers
} from "../redux/slices/tenantSlice";
import { PAYMENT_GATEWAY } from "../services/payment";
import TenantManager from "./TenantManager";
import DomainManager from "./DomainManager";
import AutomationService from "../services/automation";
import AppManager from "./AppManager";
import { PAGE_ROUTES } from "./RouteManager";
import {
  fetchAllAdditionalCharge,
  fetchBooksApprovalFlows,
  fetchUOMs
} from "../redux/slices/booksSlice";
import { fetchCurrency } from "../redux/slices/currencySlice";
import { fetchPriceBookList } from "../redux/slices/priceBookSlice";
import { fetchDealStageChangeApprovalFlows } from "../redux/slices/dealSlice";

export default class InitialApiManager {
  static getTableData() {
    return store.dispatch(fetchTables()).then(
      (data) => {
        TableManger.populateViewMap(
          JSON.parse(JSON.stringify(data.payload)).filter(
            (data) => data.columnsMetaData
          )
        ); //TODO need to change this
        store.dispatch(getPipelineStages())
        return Promise.resolve(JSON.parse(JSON.stringify(data.payload)));
      },
      (err) => {
        return Promise.reject(err);
      }
    );
  }
  static makeInitialCalls() {
    /* store.dispatch(fetchRecordsByTable({ tableName: TABLES.FUNNEL })); */
    store.dispatch(
      fetchRecordsByTable({
        tableName: TABLES.ACTIVITY,
        params: { pageSize: 20 }
      })
    );
    store.dispatch(fetchRecordsByTable({ tableName: TABLES.LANDING_PAGE }));
    store.dispatch(fetchRecordsByTable({ tableName: TABLES.CAMPAIGN }));
    store.dispatch(fetchRecordsByTable({ tableName: TABLES.FORM }));
    store.dispatch(fetchRecordsByTable({ tableName: TABLES.AUTOMATION }));
  }

  static makeMandatoryCalls(onSuccess) {
    store.dispatch(getUserPreferences());

    store.dispatch(fetchUsers());

    Promise.all([
      store.dispatch(fetchRecordsByTable({ tableName: TABLES.CONTACT })),
      store.dispatch(fetchAccounts({}))
      // store.dispatch(fetchMixpanelToken()).then((response) => {
      //   if(response && !Utility.isEmptyObject(response.payload)){
      //     MixPanelTracker.init(response.payload)
      //   }
      // })
    ]).then((response) => {
      store.dispatch(
        setTableRecords({
          tableName: TABLES.SEGMENT,
          data: response[0].payload
        })
      );
    });

    Promise.all([
      store.dispatch(fetchRecordsByTable({ tableName: TABLES.DEAL }))
    ]).then(() => {
      onSuccess(null, AppManager.isCurrentRoute(PAGE_ROUTES.DEALS_UI));
    });

    Promise.all([
      store.dispatch(fetchTenants()),
      store.dispatch(
        fetchRecordsByTable({
          tableName: TABLES.STAGE,
          params: { pageNo: 1, pageSize: 100 }
        })
      ),
      store.dispatch(
        fetchRecordsByTable({
          tableName: TABLES.PIPELINE,
          params: { pageSize: 30 }
        })
      )
      // store.dispatch(fetchCRMSettings({}))
    ]).then((res) => {
      TenantManager.setTenantDetails(res?.[0].payload);
      onSuccess(null, AppManager.isCurrentRoute(PAGE_ROUTES.DEALS));
    });
    AutomationService.getAllConnections();
    DomainManager.getAllCustomDomainsFromAPI();
    store.dispatch(fetchCurrency());
    store.dispatch(getNotification());
    store.dispatch(fetchEmailSetup());
    this.fetchPaymentMethods();
    store.dispatch(fetchTeams());
    store.dispatch(fetchRecordsByTable({ tableName: TABLES.PRODUCT }));
    // store.dispatch(fetchShopDetails());

    store.dispatch(fetchBooksApprovalFlows({}));
    store.dispatch(fetchDealStageChangeApprovalFlows({}));
    store.dispatch(fetchUOMs());
    store.dispatch(fetchAllAdditionalCharge());
  }
  static fetchPaymentMethods() {
    store.dispatch(fetchStripeAccount());
    store.dispatch(getAccountsByGateway(PAYMENT_GATEWAY.RAZORPAY));
    store.dispatch(getAccountsByGateway(PAYMENT_GATEWAY.PAYTM));
    store.dispatch(getAccountsByGateway(PAYMENT_GATEWAY.CASHFREE));
  }
}
