import { useState, useEffect } from "react";
import { DKLabel } from "deskera-ui-library";
import playButtonImg from "../../../assets/images/video_play_button.png";
import { VIDEO_PLACEHOLDER } from "../../../constants/Constant";
import Utility from "../../../utility/Utility";
import { PAGE_TYPE } from "../ComponentList";

const VideoSection = (props) => {
  const componentData = props.data.value;

  function getModifiedLink() {
    const { videoURL } = componentData;

    /* In case it's a direct URL to youtube video, need to convert it to an embed link */
    const youtubeLinkRegex = new RegExp("youtube.com/watch\\?v=|youtu.be/");

    if (!youtubeLinkRegex.test(videoURL)) return videoURL;

    let newEmbedURL = videoURL.replace(youtubeLinkRegex, "youtube.com/embed/");

    /* to handle youtube watch links with additional query params other than videoId */
    newEmbedURL = newEmbedURL.replace(/(\?|&).*/, "");

    return newEmbedURL;
  }

  function getEmbedURL() {
    const { autoplayEnabled, videoThumbnail } = componentData;
    const shouldAutoplay =
      (!props.isEditing && autoplayEnabled) || videoThumbnail;

    let embedURL = getModifiedLink();

    return `${embedURL}${embedURL.includes("?") ? "&" : "?"}controls=1&start=0${
      shouldAutoplay ? "&autoplay=1" : ""
    }`;
  }

  function getVideoFrame() {
    return componentData.videoURL ? (
      <div
        style={{
          position: "relative",
          paddingBottom: "56.25%",
          pointerEvents: props.isEditing ? "none" : "initial"
        }}
      >
        <iframe
          title="video section"
          src={getEmbedURL()}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            border: "none"
          }}
          allow={"autoplay"}
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </div>
    ) : (
      <div className="m-l">
        <DKLabel
          text="No video added"
          className="fw-m text-red text-align-center"
        />
        <DKLabel
          text="Please provide a video url from editor"
          className="text-gray mt-s text-align-center"
        />
      </div>
    );
  }

  function getVideoAsAnchoredImage() {
    return (
      <div
        style={{
          position: "relative",
          pointerEvents: props.isEditing ? "none" : "initial"
        }}
      >
        <a href={getEmbedURL()} target="_blank">
          <img
            alt={componentData.videoURL}
            src={componentData.videoThumbnail || VIDEO_PLACEHOLDER}
            style={{
              maxWidth: "100%",
              width: "100%"
            }}
          />
        </a>
      </div>
    );
  }

  return (
    <div
      style={{
        ...props.data.style,
        ...props.borderStyle
      }}
    >
      {props.pageType === PAGE_TYPE.CAMPAIGN ||
      !Utility.isEmptyObject(componentData?.videoThumbnail)
        ? getVideoAsAnchoredImage()
        : getVideoFrame()}
    </div>
  );
};

export default VideoSection;
