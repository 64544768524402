import { DKLabel, Toggle } from "deskera-ui-library";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchTenantDetails,
  getTenantsDetails
} from "../../redux/slices/booksSlice";
import Tenant from "../../services/tenant";
import { COUNTRY_CODES } from "../../constants/Constant";
import { USER_ACTION_TYPES } from "../../constants/Permission";

export default function QuoteApprovalRestartOnReject(props) {
  const tenantDetails = useAppSelector(getTenantsDetails);
  const isCountryUS = tenantDetails?.country === COUNTRY_CODES.US;
  const [toggle, setToggle] = useState(
    tenantDetails?.additionalSettings?.[
      USER_ACTION_TYPES.QUOTE_APPROVAL_RESTART_ON_REJECT
    ] || false
  );
  const dispatch = useAppDispatch();

  const onToggle = async () => {
    setToggle(!toggle);
    await onSave(!toggle);
  };

  const onSave = async (newValue) => {
    try {
      let additionalSettings = {
        [USER_ACTION_TYPES.QUOTE_APPROVAL_RESTART_ON_REJECT]: newValue
      };
      const response = await Tenant.updateTenantDetails({ additionalSettings });
      if (response) {
        dispatch(fetchTenantDetails({}));
        props?.onClose?.();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="row justify-content-between border-m border-radius-m p-r mt-l">
        <div className="column">
          <DKLabel
            text={`${isCountryUS ? "Estimate" : "Quote"} approval restart on rejection`}
            className="mr-m fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text={`When ${
              isCountryUS ? "an Estimate" : "a Quote"
            } is rejected at any level, submitter makes changes to the quote and resubmits for approval, then approval flow will restart back from Level 1.`}
          />
        </div>
        <div
          className="row justify-content-end pl-xs ml-l"
          style={{ width: 75 }}
        >
          <Toggle isOn={toggle} onChange={onToggle} color="bg-green" />
        </div>
      </div>
    </div>
  );
}
