import { DKIcon, DKButton } from "deskera-ui-library";
import LightDeskeraLogo from "../../assets/deskera-logo-light@2x.png";
import DarkDeskeraLogo from "../../assets/deskera-logo-dark@2x.png";
import Utility from "../../utility/Utility";
import { useEffect, useState } from "react";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";

export default function AppHeader({ isDarkTheme = false, onChangeTheme }) {
  const [isFloating, setIsFloating] = useState(false);
  useEffect(() => {
    const containerDiv = document.getElementById("containerDiv");

    const containerScrollHandler = () => {
      setIsFloating(Boolean(containerDiv.scrollTop > 0));
    };

    containerDiv.addEventListener("scroll", containerScrollHandler);

    return () => {
      containerDiv.removeEventListener("scroll", containerScrollHandler);
    };
  }, []);
  return (
    <div
      className={
        "deskera-website-header row justify-content-between " +
        (isFloating ? " -floating " : "") +
        (isDarkTheme ? " -dark " : " -light ")
      }
    >
      <DKIcon
        src={isDarkTheme ? LightDeskeraLogo : DarkDeskeraLogo}
        className="cursor-hand"
        onClick={() => RouteManager.navigateToPage(PAGE_ROUTES.DESKERA_HOME)}
      />

      <div className="row width-auto">
        <DKButton
          className={"border-none fw-m fs-l mr-r"}
          title="Contact Sales"
          onClick={() =>
            Utility.openInNewTab("https://www.deskera.com/contact-us")
          }
        />
        <DKButton
          className={"border-none fw-m fs-l mr-r"}
          title="Pricing"
          onClick={() => RouteManager.navigateToPage(PAGE_ROUTES.PRICING_HOME)}
        />
        <DKButton
          className={"border-none fw-m fs-l mr-r"}
          title="Free Trial"
          onClick={() =>
            Utility.openInNewTab("https://www.deskera.com/signup?trial=false")
          }
        />
        <div
          className="cursor-hand ml-l"
          onClick={() => onChangeTheme(!isDarkTheme)}
        >
          {isDarkTheme ? (
            <svg role="img" viewBox="0 0 512 512" class="theme-switcher">
              <path d="M256 0c-13.3 0-24 10.7-24 24V88c0 13.3 10.7 24 24 24s24-10.7 24-24V24c0-13.3-10.7-24-24-24zm0 400c-13.3 0-24 10.7-24 24v64c0 13.3 10.7 24 24 24s24-10.7 24-24V424c0-13.3-10.7-24-24-24zM488 280c13.3 0 24-10.7 24-24s-10.7-24-24-24H424c-13.3 0-24 10.7-24 24s10.7 24 24 24h64zM112 256c0-13.3-10.7-24-24-24H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H88c13.3 0 24-10.7 24-24zM437 108.9c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-45.3 45.3c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L437 108.9zM154.2 357.8c-9.4-9.4-24.6-9.4-33.9 0L75 403.1c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l45.3-45.3c9.4-9.4 9.4-24.6 0-33.9zM403.1 437c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-45.3-45.3c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L403.1 437zM154.2 154.2c9.4-9.4 9.4-24.6 0-33.9L108.9 75c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l45.3 45.3c9.4 9.4 24.6 9.4 33.9 0zM256 368c61.9 0 112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112s50.1 112 112 112z"></path>
            </svg>
          ) : (
            <svg role="img" viewBox="0 0 384 512" class="theme-switcher">
              <path d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
            </svg>
          )}
        </div>
      </div>
    </div>
  );
}
