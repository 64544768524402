import React, { Component, createRef } from "react";

import {
  DKLabel,
  DKButton,
  DKIcons,
  DKSpinner,
  DKIcon,
  DKLine
} from "deskera-ui-library";
import { TABLES } from "../../managers/TableManger";
import "./notification.scss";
import ic_contacts from "../../assets/menu/ic_user.png";
import ic_deals from "../../assets/menu/ic_deals.png";
import ic_forms from "../../assets/menu/ic_form.png";
import ic_campaign from "../../assets/menu/ic_campaign.png";
import ic_landing_page from "../../assets/menu/ic_langing_page.png";
import NotificationManager from "../../managers/NotificationManager";
import { connect } from "react-redux";
import NotificationService from "../../services/notification";
import { store } from "../../redux/store";
import { getNotification } from "../../redux/slices/userPrefSlice";
import ic_automation from "../../assets/menu/ic_automation.png";
import ic_chat from "../../assets/icons/ic_chat_2.png";
// import ic_funnel from "../../assets/icons/ic_filter.png";
import ic_activity from "../../assets/icons/ic_recent.png";
import ic_product from "../../assets/icons/ic_product.png";
import ic_list from "../../assets/menu/ic_list.png";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import Utility from "../../utility/Utility";
import { NOTIFICATIONS } from "../../constants/Constant";
import { NOTIFICATION_ACTION_TYPE } from "../../constants/Enum";
/*
PROPS
- onClose
*/

class NotificationPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: NotificationManager.notificationDataParse(
        this.props.notifications
      ),
      loading: true
    };
    this.bottomElement = createRef();
    this.pageSize = 20;
    this.observer = null;
  }

  componentDidMount() {
    if (this.props?.onClose) {
      document.addEventListener("mouseup", this.onMouseUp);
    }
    if (Utility.isEmptyObject(this.props.notifications?.data)) {
      this.props.getNotification({ pageSize: this.pageSize }).then((res) => {
        this.setState({
          notifications: NotificationManager.notificationDataParse(res.payload)
        });
        if (this.state.notifications.length === 0) {
          this.setState({
            loading: false
          });
        }
      });
    }
    this.createObserver();
  }
  componentWillUnmount() {
    if (this.props?.onClose) {
      document.removeEventListener("mouseup", this.onMouseUp);
    }
  }
  componentDidUpdate() {
    // if (this.bottomElement.current === null && this.observer === null)
    this.createObserver();
  }
  createObserver = () => {
    const options = {
      root: document.querySelector("#notification-wrapper"),
      rootMargin: "1px",
      threshold: 1
    };
    this.observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      if (entry.target === this.bottomElement.current && entry.isIntersecting) {
        this.onIntersectBottom();
      }
    }, options);
    if (this.bottomElement.current) {
      this.observer.observe(this.bottomElement.current);
    }
  };
  onIntersectBottom = () => {
    if (
      this.props?.notifications?.totalCount > this.props.notifications?.pageSize
    ) {
      this.pageSize = this.props?.notifications?.data?.length + 20;
      this.props?.getNotification({ pageSize: this.pageSize });
    } else {
      this.setState({ loading: false });
    }
  };
  onMouseUp = (e) => {
    if (this.props?.onClose) {
      const target = e.target;
      const editingContainer = target.closest("#notification-section");
      if (!editingContainer) {
        this.props?.onClose();
      }
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  closePopup = () => {
    this.props?.onClose?.();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    const isNotificationUrl =
      window.location.href.split("/").pop() === NOTIFICATIONS;
    return (
      <>
        <div className="row justify-content-between mb-s">
          <div className="row" style={{ width: "auto" }}>
            <DKLabel text="Notifications" className="fw-m fs-l" />
          </div>
          {!isNotificationUrl && (
            <div className="column" style={{ flexDirection: "row" }}>
              <DKButton
                icon={DKIcons.ic_open}
                onClick={() => {
                  RouteManager.navigateToPage(PAGE_ROUTES.NOTIFICATIONS);
                  this.closePopup();
                }}
              />
              <DKButton
                icon={DKIcons.ic_close}
                onClick={() => {
                  this.closePopup();
                }}
              />
            </div>
          )}
        </div>

        <div
          className="overflow-scroll hide-scroll-bar"
          style={{
            maxHeight: this.props?.height
              ? this.props?.height
              : window.innerHeight - 254,
            overflow: "scroll"
          }}
          id="notification-wrapper"
        >
          {this.state.notifications &&
            this.state.notifications.length > 0 &&
            this.renderAllNotifications()}
          {this.state.loading && (
            <DKSpinner iconClassName="ic-s-2 mb-m " className="ml-m" />
          )}
        </div>
        {!this.state.loading &&
          this.state.notifications &&
          this.state.notifications.length === 0 &&
          this.renderNoDataView()}
      </>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  renderAllNotifications() {
    let arr = [];
    NotificationManager.notificationDataParse(this.props.notifications).forEach(
      (notification, index) => {
        arr.push(this.renderNotification(notification, index));
      }
    );
    arr.push(
      <div
        ref={this.bottomElement}
        id="notification-bottom-ref"
        className="row parent-width"
        style={{ height: "2px" }}
      ></div>
    );
    return arr;
  }
  renderNotification(data, index) {
    return (
      <div className="parent-width">
        <DKLine className="m-v-s" />
        <div
          className="parent-width p-s  border-box border-radius-m listPickerBG cursor-hand"
          onClick={() => {
            this.closePopup();
            NotificationManager.navigateToRespectiveSection(data, index);
            this.readNotification(data);
          }}
        >
          <div className="row">
            <div className="position-relative mr-r pt-xs pr-xs">
              {!data.read && (
                <div
                  className="position-absolute p-xs bg-blue border-radius-m"
                  style={{ right: 0, top: 1 }}
                />
              )}
              <DKIcon
                className="ic-s border-m border-radius-m p-s bg-gray1"
                src={this.getNotificationTypeIcon(data)}
              />
            </div>
            <div className="column parent-width">
              <DKLabel
                text={`${data.title}`}
                className="text-dark-gray first-letter-capital text-ellipsis"
              />
              <div className="row justify-content-between mt-xs">
                <DKLabel
                  text={`by ${data.owner}`}
                  className={"text-gray"}
                  style={{ maxWidth: 185 }}
                />
                <DKLabel
                  text={data.date}
                  className="text-gray text-align-right fs-s"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  renderNoDataView() {
    return (
      <DKLabel
        text="No notifications"
        className="pt-xxl pb-xxl text-gray text-align-center"
      />
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  renderLoader() {
    return <DKSpinner className="pt-xxl pb-xxl" />;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  getNotificationTypeIcon(data) {
    switch (data.type) {
      case TABLES.CONTACT:
        if (
          data.action === "SEGMENT_CREATE" ||
          data.action === NOTIFICATION_ACTION_TYPE.NOTE_CREATE ||
          data.action === NOTIFICATION_ACTION_TYPE.NOTE_DELETED ||
          data.action === NOTIFICATION_ACTION_TYPE.NOTE_UPDATED
        ) {
          return ic_list;
        } else {
          return ic_contacts;
        }
      case TABLES.DEAL:
        if (
          data.action === NOTIFICATION_ACTION_TYPE.NOTE_CREATE ||
          data.action === NOTIFICATION_ACTION_TYPE.NOTE_DELETED ||
          data.action === NOTIFICATION_ACTION_TYPE.NOTE_UPDATED
        ) {
          return ic_list;
        } else {
          return ic_deals;
        }
      case TABLES.FORM:
        return ic_forms;
      case TABLES.LANDING_PAGE:
        return ic_landing_page;
      case TABLES.CAMPAIGN:
        return ic_campaign;
      case TABLES.PIPELINE:
        return ic_deals;
      case TABLES.STAGE:
        return ic_deals;
      case TABLES.AUTOMATION:
        return ic_automation;
      case TABLES.CHAT_MESSAGE:
        return ic_chat;
      /* case TABLES.FUNNEL:
        return ic_funnel; */
      case TABLES.ACTIVITY:
        return ic_activity;
      case TABLES.ORDER:
        return ic_product;
      default:
        return DKIcons.ic_notification;
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  readNotification = (data) => {
    let ids = [];
    ids.push(data.documentId);
    NotificationService.updateNotification({ ids })
      .then(() => {
        store.dispatch(getNotification());
      })
      .catch((err) => {
        console.error(
          err?.message ||
            `Failed to patch notification read status for ids: ${ids.toString()}`
        );
      });
  };
}

const mapStateToProps = (state) => ({
  notifications: state.userPref.notifications || []
});
const mapDispatchToProps = { getNotification };

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPopup);
