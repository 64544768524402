import ApiConstants from "../constants/ApiConstants";
import http from "../http";

export default class ActivityService {
  static generateIcalUrl() {
    return http
      .get(ApiConstants.URL.ACTIVITY.ICAL_LINK_GENERATOR)
      .then((res: any) => {
        return Promise.resolve(res);
      })
      .catch((err: any) => {
        console.error("Error while trying to generate URL: ", err);
        return Promise.reject(err);
      });
  }
}
