import { Component } from "react";
import ReactDOM from "react-dom";
import {
  DKLabel,
  DKIcon,
  DKIcons,
  showAlert,
  DKButton
} from "deskera-ui-library";
import ActivityManager from "../../managers/ActivityManager";
import Table from "../../services/table";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import { showAddActivityPopup } from "./AddActivityPopup";
import ic_deals from "../../assets/menu/ic_deals.png";
import Utility from "../../utility/Utility";
import RouteManager, {
  PAGE_ROUTES,
  ROUTE_ID_IDENTIFIER
} from "../../managers/RouteManager";
import { DateUtil, DATE_FORMATS } from "../../utility/Date";
export interface IActivityDetailsPopupProps {
  popupId: any;
  selectedActivity: any;
  onCancel?: () => void;
  onSubmit?: () => void;
}

export interface IActivityDetailsPopupState {
  showContact?: any;
  showOrg?: any;
  contacts?: any;
  organizations?: any;
  deals?: any;
  owners?: any;
  isOverdue?: boolean;
  account?: any;
}
class ActivityDetailsPopup extends Component<
  IActivityDetailsPopupProps,
  IActivityDetailsPopupState
> {
  constructor(props) {
    super(props);
    this.state = {
      showContact: false,
      showOrg: false,
      isOverdue: false
    };
  }
  componentWillMount() {
    this.populateLinkedContactAndDeals();
  }

  componentDidMount(): void {
    if (
      new Date(this.props.selectedActivity.end_date) < new Date() &&
      this.props.selectedActivity.status.includes(2)
    ) {
      this.setState({ isOverdue: true });
    }
  }

  populateLinkedContactAndDeals() {
    const dealList = this.props.selectedActivity.linked_to_detail || [];

    const contactList = this.props.selectedActivity.contact_ids_detail || [];

    const orgList = this.props.selectedActivity.organization_ids_detail || [];

    const accountList = this.props.selectedActivity.account_id_detail || [];

    const ownerList =
      TableManger.getColumn(
        TABLES.ACTIVITY,
        COLUMN_CODE.ACTIVITY.OWNER_ID
      )?.options?.filter((owner) =>
        this.props.selectedActivity.owner_id.includes(owner.id)
      ) || [];

    this.setState({
      contacts: contactList,
      organizations: orgList,
      deals: dealList,
      owners: ownerList,
      account: accountList
    });
  }
  render() {
    return (
      <div className="transparent-background">
        <div
          className="border-radius-s bg-white shadow-m popup-window"
          style={{ width: 350, padding: 0 }}
        >
          <div className="row justify-content-between ">
            {/* <DKLabel
							className={`${ActivityManager.getActivityTypeColor(
								this.props.selectedActivity.activity_type[0]
							)} text-black p-v-xs p-h-s border-radius-s mt-m`}
							text={ActivityManager.getActivityType(
								this.props.selectedActivity.activity_type[0]
							)}
						/> */}
            {this.getActionButtons(this.props.selectedActivity)}
          </div>
          <div className="column parent-width p-h-l">
            <div className="row align-items-center width-auto">
              <div className="column parent-width">
                <DKLabel
                  className="fs-l fw-m"
                  style={{ overflowWrap: "anywhere" }}
                  text={
                    this.props.selectedActivity &&
                    this.props.selectedActivity.name
                  }
                />
              </div>
              {this.state.isOverdue && (
                <div className="bg-chip-red fs-s text-red border-red ml-r p-v-xs p-h-s border-radius-l">
                  {"Overdue"}
                </div>
              )}
            </div>
            <div className="row width-auto mt-l">
              <div className="row width-auto">
                <DKIcon src={DKIcons.ic_calendar} className="ic-s-2 mr-m" />
              </div>
              <div className="column parent-width">
                <div className="column parent-width">
                  <DKLabel className="text-gray" text={`Start Date`} />
                  <DKLabel
                    className=""
                    text={
                      this.getFormattedDate(
                        this.props.selectedActivity.start_date
                      ) || "-"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row width-auto mt-l">
              <div className="row width-auto">
                <DKIcon src={DKIcons.ic_calendar} className="ic-s-2 mr-m" />
              </div>
              <div className="column parent-width">
                <div className="column parent-width">
                  <DKLabel className="text-gray" text={`End Date`} />
                  <DKLabel
                    className=""
                    text={
                      this.getFormattedDate(
                        this.props.selectedActivity.end_date
                      ) || "-"
                    }
                  />
                </div>
              </div>
            </div>
            {this.state.account && this.state.account.length > 0 && (
              <div className="row align-items-start mt-l">
                <DKIcon
                  src={ic_deals}
                  className="mr-m mt-s ic-s-2"
                  style={{ height: 18 }}
                />
                <div className="column parent-width">
                  <DKLabel className="text-gray" text={`Linked Account`} />
                  {this.state.account.map((account) => {
                    return (
                      <div
                        onClick={() =>
                          this.navigateToModule(TABLES.ACCOUNT, account._id)
                        }
                      >
                        <DKLabel
                          className="text-underline cursor-hand"
                          text={
                            account.cells[
                              TableManger.getColumnId(
                                TABLES.ACCOUNT,
                                COLUMN_CODE.ACCOUNT.NAME
                              )
                            ] || "-"
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {this.state.contacts && this.state.contacts.length > 0 && (
              <div className="row align-items-start mt-l">
                <DKIcon src={DKIcons.ic_user} className="ic-s-2 mt-s mr-m" />
                {
                  <div className="column parent-width">
                    <div className="column parent-width">
                      <DKLabel className="text-gray" text={`Linked Contact`} />
                      {this.state.contacts.map((contact) => {
                        return (
                          <div
                            onClick={() =>
                              this.navigateToModule(TABLES.CONTACT, contact._id)
                            }
                          >
                            <DKLabel
                              className="text-underline cursor-hand"
                              text={
                                contact.cells[
                                  TableManger.getColumnId(
                                    TABLES.CONTACT,
                                    COLUMN_CODE.CONTACT.NAME
                                  )
                                ] || ""
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
              </div>
            )}
            {this.state.deals && this.state.deals.length > 0 && (
              <div className="row align-items-start mt-l">
                <DKIcon
                  src={ic_deals}
                  className="mr-m mt-s ic-s-2"
                  style={{ height: 18 }}
                />
                <div className="column parent-width">
                  <DKLabel className="text-gray" text={`Linked Deal`} />
                  {this.state.deals.map((deal) => {
                    return (
                      <div
                        onClick={() =>
                          this.navigateToModule(TABLES.DEAL, deal._id)
                        }
                      >
                        <DKLabel
                          className="text-underline cursor-hand"
                          text={
                            deal.cells[
                              TableManger.getColumnId(
                                TABLES.DEAL,
                                COLUMN_CODE.DEAL.NAME
                              )
                            ] || "-"
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {this.state.owners && this.state.owners.length > 0 && (
              <div className="row align-items-start mt-l">
                <DKIcon
                  src={DKIcons.ic_user_2}
                  className="mt-s mr-m"
                  style={{ height: 18 }}
                />
                <div className="column parent-width">
                  <DKLabel className="text-gray" text={`Owner`} />
                  <DKLabel
                    className=""
                    text={
                      this.state.owners &&
                      this.state.owners.map((owner) => owner.name)
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div
            className={"row justify-content-between mt-l p-m"}
            style={{ borderTop: "1px solid gainsboro" }}
          >
            <DKLabel className="parent-width" text={`Completed?`} />
            <div className="row justify-content-end">
              <DKButton
                className={`${
                  this.props.selectedActivity.status.includes(1)
                    ? "bg-green text-white"
                    : "border-m"
                } mr-m`}
                title="Yes"
                disabled={!this.props.selectedActivity.allowToEdit}
                onClick={() => {
                  this.updateStatus(1, this.props.selectedActivity.id);
                }}
              />
              <DKButton
                className={`${
                  this.props.selectedActivity.status.includes(2)
                    ? "bg-red text-white"
                    : "border-m"
                }`}
                title="No"
                disabled={!this.props.selectedActivity.allowToEdit}
                onClick={() => {
                  this.updateStatus(2, this.props.selectedActivity.id);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  updateStatus = (status, id) => {
    let data = {
      [ActivityManager.getColumnId(COLUMN_CODE.ACTIVITY.STATUS)]: [status]
    };
    Table.updateRecord(data, id, TableManger.getTableId(TABLES.ACTIVITY)).then(
      (res) => {
        this.removePopUp();
        this.props.onSubmit();
      }
    );
  };
  getDeleteConfirmation = (id) => {
    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Delete",
        className: "bg-red text-white ml-r",
        onClick: () => this.onDelete(id)
      }
    ];
    showAlert(
      "Delete Activity?",
      "Deleting this activity will delete it permanently you will not be able to restore it.",
      buttons
    );
  };
  onDelete = (id) => {
    Table.deleteRecord(id, TableManger.getTableId(TABLES.ACTIVITY)).then(
      (res) => {
        this.removePopUp();
        this.props.onSubmit();
      }
    );
  };
  getFormattedDate = (date) => {
    const newDate = DateUtil.getDateStrFromDate(
      new Date(date),
      DateUtil.getOrgDateFormat()
    );
    return `${DateUtil.getFormattedDateString(newDate, DateUtil.getOrgDateFormat(), DateUtil.getOrgDateFormat())}, ${Utility.getFormattedTime(
      date
    )}`;
  };
  navigateToModule = (module, id) => {
    switch (module) {
      case TABLES.CONTACT:
        RouteManager.navigateToPage(
          PAGE_ROUTES.CONTACTS_UI.replace(ROUTE_ID_IDENTIFIER, id)
        );
        break;
      case TABLES.DEAL:
        RouteManager.navigateToPage(
          PAGE_ROUTES.DEALS_UI.replace(ROUTE_ID_IDENTIFIER, id)
        );
        break;
      case TABLES.ACCOUNT:
        RouteManager.navigateToPage(
          PAGE_ROUTES.ACCOUNT_DETAILS.replace(ROUTE_ID_IDENTIFIER, id)
        );
        break;
      default:
        break;
    }
    this.removePopUp();
  };
  getActionButtons(activity) {
    return (
      <div className="row parent-width p-h-m pt-m">
        <div className="row justify-content-end align-items-end">
          {activity.allowToEdit && (
            <DKIcon
              src={DKIcons.ic_edit}
              className="ic-s cursor-hand listPickerBG p-xs border-radius-m"
              onClick={() => {
                this.editActivity(activity);
              }}
            />
          )}
          {activity.allowToDelete && (
            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-s cursor-hand listPickerBG p-xs border-radius-m ml-xs"
              onClick={() => {
                this.getDeleteConfirmation(activity.id);
              }}
            />
          )}
          <DKIcon
            src={DKIcons.ic_close}
            className="ic-s cursor-hand listPickerBG p-xs border-radius-m ml-xs"
            onClick={() => {
              this.removePopUp();
            }}
          />
        </div>
      </div>
    );
  }
  onCancel = () => {
    if (this.props.onCancel) this.props.onCancel();
    this.removePopUp();
  };
  editActivity = (activity) => {
    this.removePopUp();
    showAddActivityPopup({
      isEdit: true,
      selectedActivity: activity,
      recordId: activity.id,
      startDate: activity.start_date,
      endDate: activity.end_date,
      onSubmit: () => {
        this.props.onSubmit();
      }
    });
  };
  removePopUp = () => {
    if (this.props.popupId) {
      ReactDOM.unmountComponentAtNode(
        document.getElementById(this.props.popupId)
      );
      document.getElementById(this.props.popupId)?.remove();
    }
  };
}
export const showActivityDetailsPopup = (
  config: { selectedActivity?: any; onSubmit?: any }
  // onCancel: () => void
) => {
  const id = `add-actvivity-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <ActivityDetailsPopup
      popupId={id}
      selectedActivity={config.selectedActivity}
      // onCancel={onCancel}
      onSubmit={config.onSubmit}
    />,
    document.body.appendChild(div)
  );
};
export default ActivityDetailsPopup;
