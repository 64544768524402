import { useState, useEffect } from "react";
import {
  DKDataGrid,
  INPUT_TYPE,
  DKLabel,
  DKIcons,
  DKIcon,
  DKListPicker
} from "deskera-ui-library";
import SideBarService from "../services/sidebar";
import RouteManager from "../managers/RouteManager";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { fetchIndiaMartLeadSyncLogs } from "../redux/slices/recordSlice";
import {
  INDIA_MART_LEAD_DATA_KEYS,
  JUST_DIAL_LEAD_DATA_KEYS
} from "../constants/Enum";
import Utility, { getCapitalized } from "../utility/Utility";
import {
  DEFAULT_PAGE_NO,
  INDIA_MART_LEAD_COLUMNS,
  INDIA_MART_SYNC_LOG_COLUMNS,
  JUST_DIAL_LEAD_COLUMNS,
  LOG_SCROLL_CONTAINER_ID
} from "../constants/Constant";
import { Renderers } from "../Helper/Renderers";
import { IColumn } from "../model/Table";
import { LEAD_APP_NAME } from "../services/indiamart";
import ic_no_data from "../../src/assets/icons/ic_no_data_3.png";
import { isMobileAppWebView } from "../utility/GetViewportSize";

const DEFAULT_LOG_PAGE_SIZE = 10;

const IndiaMartLeadSyncLogs = (props) => {
  const [loading, setLoading] = useState(false);
  const [outerGridData, setOuterGridData] = useState({
    currentPage: DEFAULT_PAGE_NO,
    appName: LEAD_APP_NAME.INDIAMART,
    expandedGridData: {
      rowId: null,
      currentPage: DEFAULT_PAGE_NO
    }
  });
  const [needAppSelector, setNeedAppSelector] = useState(false);

  const logsData = useAppSelector(
    (state) => state.records.currentData.indiamartLeadSyncLogs
  ) || {
    data: [],
    totalCount: 0
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    // RouteManager.setPresenter({ props });
  }, []);

  useEffect(() => {
    refreshAccountLogs();
  }, [outerGridData.currentPage, outerGridData.appName]);

  const refreshAccountLogs = async () => {
    setLoading(true);

    dispatch(
      fetchIndiaMartLeadSyncLogs({
        params: {
          pageNo: outerGridData.currentPage,
          pageSize: DEFAULT_LOG_PAGE_SIZE
        },
        appName: outerGridData.appName
      })
    ).finally(() => {
      setLoading(false);
    });
  };

  const onPageChange = (pageNo: number, isInnerGrid?: boolean) => {
    if (isInnerGrid) {
      setOuterGridData({
        ...outerGridData,
        expandedGridData: {
          ...outerGridData.expandedGridData,
          currentPage: pageNo
        }
      });
    } else {
      setOuterGridData({
        ...outerGridData,
        currentPage: pageNo,
        expandedGridData: {
          rowId: null,
          currentPage: DEFAULT_PAGE_NO
        }
      });
    }
  };

  const onRowExpand = ({ rowIndex, rowData }) => {
    setOuterGridData((gridData) => ({
      ...gridData,
      expandedGridData: {
        rowId:
          gridData.expandedGridData.rowId === rowData._id ? null : rowData._id,
        currentPage: DEFAULT_PAGE_NO
      }
    }));
  };

  const onAppSelected = (index: number, appName: LEAD_APP_NAME) => {
    if (outerGridData.appName !== appName) {
      setOuterGridData((prevOuterGridData) => ({
        ...prevOuterGridData,
        appName,
        expandedGridData: {
          rowId: null,
          currentPage: DEFAULT_PAGE_NO
        }
      }));
    }

    setNeedAppSelector(false);
  };

  const getColumnsForDetailedLeadLogs = () => {
    if (outerGridData.appName === LEAD_APP_NAME.INDIAMART) {
      return INDIA_MART_LEAD_COLUMNS.map((column: IColumn) => {
        if (column.columnCode === INDIA_MART_LEAD_DATA_KEYS.SENDER_ADDRESS) {
          column.renderer = ({ value }) => Renderers.addressRenderer(value);
        }

        return column;
      });
    } else {
      return JUST_DIAL_LEAD_COLUMNS.map((column: IColumn) => {
        if (
          [
            JUST_DIAL_LEAD_DATA_KEYS.AREA,
            JUST_DIAL_LEAD_DATA_KEYS.BRANCH_AREA,
            JUST_DIAL_LEAD_DATA_KEYS.CITY,
            JUST_DIAL_LEAD_DATA_KEYS.PINCODE,
            JUST_DIAL_LEAD_DATA_KEYS.BRANCH_PINCODE
          ].includes(column.columnCode as JUST_DIAL_LEAD_DATA_KEYS)
        ) {
          column.renderer = ({ value }) => Renderers.addressRenderer(value);
        }

        return column;
      });
    }
  };

  const getRowsForDetailedLeadLogs = (rowData) => {
    if (Utility.isEmptyObject(rowData.response)) return [];

    let rows: any[];

    if (Array.isArray(rowData.response)) {
      const currentPage = outerGridData.expandedGridData.currentPage;
      const sliceStart = DEFAULT_LOG_PAGE_SIZE * (currentPage - 1);
      const sliceEnd = DEFAULT_LOG_PAGE_SIZE * currentPage;
      rows = rowData.response.slice(sliceStart, sliceEnd);
    } else if (typeof rowData.response === "object") {
      rows = [rowData.response];
    }

    return rows;
  };

  const getDetailedLeadLogsGridView = (rowData) => {
    if (Utility.isEmptyObject(rowData)) return null;

    let columns = getColumnsForDetailedLeadLogs(),
      rows = getRowsForDetailedLeadLogs(rowData);

    return (
      <div className="p-h-m bg-gray2 parent-width border-box">
        <DKDataGrid
          title={`Fetched Leads Detail`}
          width={SideBarService.getContainerWidth() - 40}
          needShadow={false}
          needBorder={true}
          needColumnIcons={true}
          needTrailingColumn={true}
          allowBulkOperation={false}
          allowColumnSort={false}
          updating={false}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowColumnDelete={false}
          allowColumnWebhook={false}
          allowRowEdit={false}
          allowBottomRowAdd={false}
          allowFilter={false}
          allowSearch={false}
          allowShare={false}
          allowExpand={false}
          columns={columns}
          rows={rows.map((rowData) => ({ ...rowData }))}
          currentPage={outerGridData.expandedGridData.currentPage}
          totalPageCount={Math.ceil(
            (rowData.response?.length || 0) / DEFAULT_LOG_PAGE_SIZE
          )}
          onPageChange={(pageNo: number) => onPageChange(pageNo, true)}
        />
      </div>
    );
  };

  const getLeadSyncRequestLogsGridView = () => {
    const columns = INDIA_MART_SYNC_LOG_COLUMNS.map((column: IColumn) => {
      if (column.type === INPUT_TYPE.NUMBER) {
        column.renderer = ({ value }) => (
          <DKLabel
            text={value < 0 ? 0 : value}
            className="parent-width text-align-right"
          />
        );
      }

      return column;
    });
    const rows = [];

    logsData.data?.forEach((data) => {
      const isExpanded = outerGridData.expandedGridData.rowId === data._id;
      rows.push({
        ...data,
        expanded: isExpanded,
        expandableView: isExpanded ? getDetailedLeadLogsGridView(data) : null
      });
    });

    return (
      <DKDataGrid
        title={`Lead Sync Logs`}
        width={SideBarService.getContainerWidth()}
        gridStickyHeaderConfig={
          isMobileAppWebView()
            ? null
            : {
                getRootScrollContainer: () =>
                  document.getElementById(LOG_SCROLL_CONTAINER_ID)
              }
        }
        needShadow={true}
        needBorder={false}
        needColumnIcons={true}
        needTrailingColumn={true}
        updating={loading}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnAdd={false}
        allowColumnEdit={false}
        allowColumnDelete={false}
        allowColumnWebhook={false}
        allowRowEdit={false}
        allowBottomRowAdd={false}
        allowFilter={false}
        allowSearch={false}
        allowShare={false}
        columns={columns}
        rows={rows}
        allowExpand={true}
        onRowExpand={onRowExpand}
        currentPage={outerGridData.currentPage}
        totalPageCount={Math.ceil((logsData.totalCount || 0) / 10)}
        onPageChange={(pageNo) => onPageChange(pageNo, false)}
        buttons={[
          {
            title: getCapitalized(outerGridData.appName),
            icon: DKIcons.ic_arrow_down2,
            className: "bg-white border-m ml-r",
            isReverse: true,
            onClick: () => setNeedAppSelector(true)
          }
        ]}
      />
    );
  };
  const getNoDataView = () => {
    return (
      <div
        className="column align-self-center align-items-center position-absolute"
        style={{ top: "30%", pointerEvents: "none" }}
      >
        <DKIcon
          src={ic_no_data}
          className="ic-l"
          style={{ opacity: 0.2, marginTop: 70 }}
        />
        <DKLabel text="No data found" className="fw-m mt-l" />
        <DKLabel
          text="Once data is available, it will appear here"
          className="text-gray mt-s "
        />
      </div>
    );
  };

  return (
    <div className="column parent-width mb-s position-relative flex-1">
      {needAppSelector && (
        <DKListPicker
          title={"App Name"}
          data={[LEAD_APP_NAME.INDIAMART, LEAD_APP_NAME.JUSTDIAL]}
          className="position-absolute z-index-3 border-m"
          style={{
            top: 0,
            right: 0,
            width: 100
          }}
          onSelect={onAppSelected}
          onClose={() => setNeedAppSelector(false)}
        />
      )}
      {logsData?.data?.length === 0 && getNoDataView()}
      {getLeadSyncRequestLogsGridView()}
    </div>
  );
};

export default IndiaMartLeadSyncLogs;
