import React, { Component } from "react";
import { DKLabel, getDateAsString, isEmpty } from "deskera-ui-library";
import {
  COLUMN_CODE,
  TableManger,
  TABLES
} from "../../../managers/TableManger";
import { getDashboardWidgetLoading } from "../../dashboard/Dashboard";
import AutomationManager from "../../automation/AutomationManager";
import RouteManager, { PAGE_ROUTES } from "../../../managers/RouteManager";
import AppManager from "../../../managers/AppManager";
import { connect, ConnectedProps } from "react-redux";
import { addRecord, setRecords } from "../../../redux/slices/recordSlice";
import { store } from "../../../redux/store";
import { fetchRecordsByTable } from "../../../redux/slices/recordSlice";
import { USER_ACTION_TYPES } from "../../../constants/Permission";
import { isMobileAppWebView } from "../../../utility/GetViewportSize";
import { DateUtil } from "../../../utility/Date";

interface IAutomationCardProps extends PropsFromRedux {
  onCountUpdated: (count: number) => void;
}
interface IAutomationCardState {
  data: any[];
  showLoading: boolean;
}
class AutomationCard extends Component<
  IAutomationCardProps,
  IAutomationCardState
> {
  status: any[] = [];
  columnsIds = {
    name: "",
    status: "",
    lastRun: ""
  };
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showLoading: false
    };
  }
  render() {
    return (
      <>
        {this.state.showLoading && getDashboardWidgetLoading()}
        {
          <>
            {!this.state.showLoading && this.state.data.length !== 0 && (
              <>{this.state.data.map((record) => this.getRow(record))}</>
            )}
          </>
        }
        {!this.state.showLoading &&
          this.state.data.length === 0 &&
          this.getEmptyState()}
      </>
    );
  }

  componentDidMount() {
    this.setState({ showLoading: true });
    this.setColumnsIds();
    this.setStatusOptions();
    this.fetchRecords();
  }
  UNSAFE_componentWillReceiveProps(props) {
    if (props.data) {
      this.onDataReceived(props.data);
    }
  }
  fetchRecords(refresh = false) {
    let data = this.props.data;
    if (refresh || isEmpty(data)) {
      store.dispatch(fetchRecordsByTable({ tableName: TABLES.AUTOMATION }));
    } else {
      this.onDataReceived(data);
      this.fetchRecords(true);
    }
  }

  onDataReceived = (data) => {
    if (this.props.onCountUpdated) this.props.onCountUpdated(data.totalCount);
    this.setState({
      data: data.data.map((record) => {
        return { ...record.cells, id: record._id };
      }),
      showLoading: false
    });
  };

  getEmptyState = () => {
    return (
      <div className="column parent-width parent-height align-items-center justify-content-center">
        <DKLabel text="No data found" className="fw-m fs-m text-gray" />
        <DKLabel
          text="Create your first automation now"
          className="text-gray mt-xs"
        />
      </div>
    );
  };
  getRow(record) {
    const allowRowEdit = !!this.props.permissions[USER_ACTION_TYPES.REC_UPDATE];
    return (
      <div
        className="row pt-xs pb-s justify-content-between listPickerBG border-radius-m"
        style={{
          cursor: allowRowEdit ? "pointer" : "default"
        }}
        onClick={() =>
          !isMobileAppWebView() && allowRowEdit
            ? this.onRowClick(record)
            : undefined
        }
      >
        <div className="column" style={{ maxWidth: "100%" }}>
          <DKLabel
            text={`${record[this.columnsIds.name]}`}
            className="fw-m row"
          />
          <DKLabel
            text={
              record[this.columnsIds.lastRun]
                ? `Last run on ${DateUtil.getDateStrFromDate(
                    record[this.columnsIds.lastRun],
                    DateUtil.getOrgDateFormat()
                  )}`
                : "Not yet triggered"
            }
            className="fw-s row text-gray mt-xs mr-s column"
          />
        </div>
        <div className="column">
          {record[this.columnsIds.status] !== undefined &&
            record[this.columnsIds.status].length > 0 &&
            this.getStatusBadge(record[this.columnsIds.status][0])}
        </div>
      </div>
    );
  }
  setColumnsIds = () => {
    this.columnsIds.name = TableManger.getColumnId(
      TABLES.AUTOMATION,
      COLUMN_CODE.AUTOMATION.NAME
    );
    this.columnsIds.lastRun = TableManger.getColumnId(
      TABLES.AUTOMATION,
      COLUMN_CODE.AUTOMATION.LAST_RUN_DATE
    );
    this.columnsIds.status = TableManger.getColumnId(
      TABLES.AUTOMATION,
      COLUMN_CODE.AUTOMATION.STATUS
    );
  };

  setStatusOptions = () => {
    this.status = TableManger.getColumn(
      TABLES.AUTOMATION,
      COLUMN_CODE.AUTOMATION.STATUS
    ).options;
  };
  getStatusBadge = (id) => {
    const status = this.status.find((status) => status.id === id);
    return (
      <div
        className={`${status.name === "Active" ? "text-green bg-chip-green" : "text-red bg-chip-red"} fs-r p-v-xs p-h-s border-radius-r`}
      >
        {status.name}
      </div>
    );
  };
  onRowClick = (record) => {
    let data = JSON.parse(JSON.stringify(record));
    let data_formatted = { data: null };
    data_formatted.data = {
      _id: data.id,
      cells: data
    };
    AutomationManager.setData(data_formatted);
    RouteManager.navigateToPage(`${PAGE_ROUTES.AUTOMATION_BUILDER}/${data.id}`);
  };
}

const mapStateToProps = (state) => ({
  data: state.records.data[TABLES.AUTOMATION],
  permissions:
    state.rolesPermission?.currentUserModuleWisePermissions?.[
      TABLES.AUTOMATION
    ] || {}
});
const mapDispatchToProps = { addRecord, setRecords };

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(AutomationCard);
