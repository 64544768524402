import { Component } from "react";
import {
  DKLabel,
  DKButton,
  DKInputForm,
  INPUT_VIEW_DIRECTION,
  INPUT_TYPE,
  showAlert,
  getDateAsString,
  showLoader,
  removeLoader
} from "deskera-ui-library";
import ReactDOM from "react-dom";
import EmailForm from "../contacts/contactDetails/EmailForm";
import SupportTicketService from "../../services/supportTicket";
import Utility from "../../utility/Utility";
import {
  TICKET_PRIORITIES,
  TICKET_STATUS,
  TICKET_TYPES,
  TICKET_VALUES_NAME
} from "../../constants/Enum";
import { store } from "../../redux/store";
import { Provider } from "react-redux";
import { fetchCRMSettings, fetchUsers } from "../../redux/slices/tenantSlice";
import TicketInput from "./TicketInput";
import { COLUMN_CODE, TableManger, TABLES } from "../../managers/TableManger";
import debounce from "../../utility/Debounce";
import { fetchTickets } from "../../redux/slices/recordSlice";
import GraphManager from "../../managers/GraphManager";
import {
  ERROR_MSG_FOR_ADD_RECIPIENT,
  DEFAULT_TICKET_FIELD_ORDER,
  INCOMPLETE_FORM,
  TICKET_FIELDS,
  SUPPORT_INPUT_TYPE,
  ERROR_REQUIRED_FIELD_TITLE,
  ERROR_MSG_FOR_REQUIRED_FIELD
} from "../../constants/Constant";
import Table from "../../services/table";
import { DealManager } from "../../managers/DealManager";
import ContactManager from "../../managers/ContactManager";
import { showFormPopup } from "../menu/AddNewRecordPopup";
import { updateTenantSettings } from "../../services/tenant";
import { showAddColumnPopup } from "../common/AddColumnPopup";

const filterJoinedUser = (user) => user.status === "JOINED";
export interface IAddTicketProps {
  onSave?: () => void;
  onCancel?: () => void;
  email?: any;
  popupId: any;
}

class AddTicket extends Component<IAddTicketProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      addNewTicketPopup: false,
      disable: false,
      saveButtonTapped: false,
      subject: "",
      requestors: [],
      CCEmails: [],
      defaultValues: {
        type: TICKET_TYPES[0],
        priority: TICKET_PRIORITIES[0]?.name,
        status: TICKET_STATUS[1],
        crm3_contact_id: null
      },
      customFieldsData: [],
      users: [],
      contacts: [],
      CFFields: [],
      accounts: [],
      isManualAccountSelected: false,
      emailDetails: [],
      inputFieldsSequence: DEFAULT_TICKET_FIELD_ORDER
    };
  }
  componentWillMount() {
    // this.getRequestors();
    this.getUsers();
    this.getCustomFields();
    this.getContacts();
    this.getAccounts();
    if (
      Utility.isEmptyObject(
        store.getState().tenant?.crmSettings?.fieldOrderByObjectType?.TICKET
      )
    ) {
      store.dispatch(fetchCRMSettings({}));
    } else {
      let fieldsWithOrder = DEFAULT_TICKET_FIELD_ORDER;
      if (
        store.getState().tenant?.crmSettings?.fieldOrderByObjectType?.TICKET
          ?.order
      ) {
        fieldsWithOrder = store
          .getState()
          .tenant?.crmSettings?.fieldOrderByObjectType?.TICKET?.order?.map(
            (field) =>
              field.type === "syst"
                ? DEFAULT_TICKET_FIELD_ORDER?.find(
                    (item) => field.id === item.id
                  )
                : this.state.customFieldsData?.find(
                    (item) => field.id === item.id
                  ) ?? field
          );
      }
      this.setState({
        inputFieldsSequence: fieldsWithOrder ?? DEFAULT_TICKET_FIELD_ORDER
      });
    }
  }
  getRequestors = () => {
    SupportTicketService.getRequestor().then((response) => {
      this.setState(
        { requestors: this.getFilteredRequestor(response?.data) },
        () => {
          this.setState(
            {
              defaultValues: {
                ...this.state.defaultValues,
                requestor: this.state.requestors[0]?.email
              }
            },
            () =>
              this.checkRequestorEmailWithContact(
                this.state.requestors[0]?.email
              )
          );
        }
      );
    });
  };
  getFilteredRequestor = (data) => {
    let filteredData = [];
    if (!Utility.isEmptyObject(this.props?.email)) {
      let index = data.findIndex((data) => data.email === this.props.email);
      if (index !== -1) {
        filteredData = data;
        this.setState({
          defaultValues: {
            ...this.state.defaultValues,
            requestor: [this.props.email]
          }
        });
      } else {
        filteredData = data;
        filteredData.push({ id: null, email: [this.props.email] });
      }
    } else {
      filteredData = data;
    }
    return filteredData;
  };
  getUsers = () => {
    if (Utility.isEmptyObject(this.state.users)) {
      store.dispatch(fetchUsers()).then((res: any) => {
        this.setState(
          {
            users: res?.payload?.invites
              ?.filter(filterJoinedUser)
              .map((user) => {
                return { ...user, id: user.iamUserId };
              })
          },
          () => {
            this.setState({
              defaultValues: {
                ...this.state.defaultValues,
                assignee: this.state.users?.filter(filterJoinedUser)?.[0]?.id
              }
            });
          }
        );
      });
    } else {
      this.setState({
        defaultValues: {
          ...this.state.defaultValues,
          assignee: this.state.users?.filter(filterJoinedUser)?.[0]?.id
        }
      });
    }
  };
  getCustomFields = () => {
    let queryParams = `?query=appName=${"CRM"},module=${"TICKET"}&limit=1000`;
    SupportTicketService.getCustomFields(queryParams).then((response: any) => {
      let fieldSequence = [...response?.content];
      let fieldOrder = [...this.state.inputFieldsSequence];
      if (fieldSequence?.length > 0) {
        fieldSequence?.forEach((field) => {
          let findIndex = fieldOrder?.findIndex(
            (order) => order.id === field.id
          );
          if (findIndex !== -1) {
            fieldOrder[findIndex] = {
              ...fieldOrder[findIndex],
              name: field.label
            };
          } else {
            fieldOrder.push({
              id: field.id,
              name: field.label,
              type: SUPPORT_INPUT_TYPE.CUSTOM
            });
          }
        });
      }
      this.setState({
        customFieldsData: response?.content,
        inputFieldsSequence: fieldOrder
      });
    });
  };
  getContacts = () => {
    this.setState({
      contacts: store.getState()?.records.data[TABLES.CONTACT]?.data || []
    });
  };
  getAccounts = () => {
    this.setState({
      accounts: store.getState()?.records.accounts?.data || []
    });
  };
  render() {
    return (
      <>
        <div className="transparent-background">
          <div
            className="popup-window ticket-form-container p-l"
            style={{ minWidth: 900 }}
          >
            {this.getHeader()}
            {this.getTicketBody()}
          </div>
        </div>
        {this.state.requestorPopup && this.getRequestorPopup()}
      </>
    );
  }
  getHeader = () => {
    return (
      <div className="row justify-content-between pb-m">
        <DKLabel text={"New Ticket"} className="fw-b fs-m" />
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m mr-s"
            onClick={() => {
              this.props.onCancel();
              this.removePopUp();
            }}
          />
          <DKButton
            title="Save"
            className="bg-button border-m text-white"
            disabled={this.state.disable}
            onClick={() => {
              this.setState({ saveButtonTapped: true }, () => {});
            }}
          />
        </div>
      </div>
    );
  };
  getTicketBody = () => {
    return (
      <div className="row parent-width align-items-start">
        <div className="column" style={{ width: "70%" }}>
          {this.getComposeEmail()}
        </div>
        <div
          className="column"
          style={{
            width: "30%",
            height: "100%",
            maxHeight: 500,
            overflow: "auto"
          }}
        >
          {this.getInputFields()}
          {/* {this.props.permissions[USER_ACTION_TYPES.FIELD_CREATE] ? (
						<div className="row row-reverse mt-l">
							<DKButton
								className="bg-gray1 border-m"
								title={"New Field"}
								icon={DKIcons.ic_add}
								onClick={this.onCustomFieldAdd}
							/>
						</div>
					) : null} */}
        </div>
      </div>
    );
  };

  checkRequestorEmailWithContact = (email) => {
    let params = { q: email };
    let filterPayload = {};
    showLoader();
    Table.getRecordsByPage(
      params,
      filterPayload,
      TableManger.getTableId(TABLES.CONTACT)
    )
      .then((response) => {
        let contactId = "",
          accountId = "";
        if (response.data && response.data.length > 0) {
          let contactList = DealManager.contactData(response.data);
          contactId = contactList?.[0]?.id ?? "";
          accountId = contactList?.[0]?.account_id ?? "";
        }
        this.onAddTicketValueChange(TICKET_VALUES_NAME.CONTACT, contactId);
        this.onAddTicketValueChange(TICKET_VALUES_NAME.ACCOUNT, accountId);
        removeLoader();
      })
      .catch((err) => {
        console.error("fetch error", err);
      });
  };

  updateMultipleData = (data) => {
    this.setState({ defaultValues: { ...this.state.defaultValues, ...data } });
  };

  callValueChangeFunction = (value, emailDetails = []) => {
    this.onAddTicketValueChange("requestor", value);
    value?.length === 1 &&
      setTimeout(
        () =>
          this.updateMultipleData({
            crm3_account_id: emailDetails?.[0]?.accountId?.[0] ?? null,
            crm3_contact_id: emailDetails?.[0]?.id ?? null
          }),
        100
      );
    if (value?.length > 1) {
      let accountIdSame = true;
      let accountId = null;
      emailDetails.forEach((detail, index) => {
        if (index === 0) {
          accountId = detail.accountId?.[0];
        } else if (accountId !== detail.accountId?.[0]) {
          accountIdSame = false;
        }
      });
      setTimeout(
        () =>
          this.updateMultipleData({
            crm3_account_id: accountIdSame ? accountId : null,
            crm3_contact_id: null
          }),
        100
      );
    }
    if (value?.length === 0 || value.length !== emailDetails?.length) {
      setTimeout(
        () =>
          this.updateMultipleData({
            crm3_account_id: null,
            crm3_contact_id: null
          }),
        100
      );
    }
    this.setState({ emailDetails });
  };

  getComposeEmail = () => {
    const { defaultValues, requestors, subject, saveButtonTapped } = this.state;
    const requestor = defaultValues?.requestor ?? [];
    return (
      <EmailForm
        config={{
          height: 200,
          hideInput: false,
          subject: subject,
          email: requestor,
          requestors: requestors,
          CCEmails: this.state?.CCEmails,
          showRequestorPopup: () =>
            showFormPopup({
              tableName: TABLES.CONTACT,
              defaultValues: ContactManager.getDefaultValues()
            }),
          onRequestorChange: (emails, emailDetails) => {
            this.callValueChangeFunction(emails, emailDetails);
          },
          onSubjectChange: (value) => this.setState({ subject: value }),
          onCCChange: (value) => this.setState({ CCEmails: value }),
          getPlaceholderData: this.getTicketDataForPlaceholders,
          isManualAccountSelected: this.state.isManualAccountSelected
        }}
        onSaveFailure={(buttonTapped) => {
          this.setState({ saveButtonTapped: buttonTapped });
        }}
        saveButtonTapped={saveButtonTapped}
        onTicketSave={(data) => this.onSendButtonTapped(data)}
        disableReply={false}
        onCancel={() => {}}
        onSuccess={(data) => {}}
      />
    );
  };
  getCFDeleteConfirmation = (field) => {
    const buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Yes",
        className: "bg-red text-white ml-r",
        onClick: () => this.deleteCustomField(field)
      }
    ];
    showAlert(
      "Delete field?",
      "Deleting this field will delete from all tickets. Are you sure want to delete it?",
      buttons
    );
  };
  deleteCustomField = (field) => {
    let fieldOrder = [...this.state.inputFieldsSequence];
    let updatedOrder = [];
    fieldOrder.forEach((order) => {
      if (order.id !== field.id) {
        updatedOrder.push(order);
      }
    });
    SupportTicketService.deleteCustomField(field?.id).then((res) => {
      this.getCustomFields();
      this.setState(
        {
          inputFieldsSequence: updatedOrder
        },
        () => this.onFieldSettingSave()
      );
    });
  };

  onFieldSettingSave = async () => {
    try {
      showLoader();
      let fieldData = [...this.state.inputFieldsSequence];
      let dataToSave = fieldData?.map((field) => {
        field = { ...field };
        delete field.name;
        return field;
      });
      const payload = {
        fieldOrderByObjectType: { TICKET: { order: dataToSave } }
      };
      const response = await updateTenantSettings(payload);
      if (response) {
        removeLoader();
        store.dispatch(fetchCRMSettings({}));
      }
    } catch (error) {
      removeLoader();
      console.error("error::", error);
    }
  };

  getAttributes = (data) => {
    return data.options.map((op) => {
      let array = {
        value: op?.name,
        status: "ACTIVE",
        defaultOption: false
      };
      return array;
    });
  };

  onEditCustomField = (data: any) => {
    const tenantDetails = store.getState()?.books?.tenantsDetails;
    showAddColumnPopup({ tableName: TABLES.TICKET, data }, (response) => {
      let dataToSend = {
        id: data.id,
        appName: "CRM",
        defaultValue: "",
        fieldType:
          response.columnData?.type === INPUT_TYPE.MULTI_SELECT ||
          response.columnData?.type === INPUT_TYPE.SELECT
            ? INPUT_TYPE.DROPDOWN.toUpperCase()
            : response.columnData?.type.toUpperCase(),
        label: response.columnData?.name,
        mandatory: response.columnData?.required,
        attributes: response.columnData?.options
          ? this.getAttributes(response.columnData)
          : null,
        maxLength: 100,
        decimalPrecision: tenantDetails.decimalScale ?? 2,
        modules: ["TICKET"]
      };
      SupportTicketService.updateCustomField(data.id, dataToSend).then(
        (response: any) => {
          this.getCustomFields();
        }
      );
    });
  };

  getInputFields = () => {
    return (
      <TicketInput
        contacts={this.state?.contacts}
        accounts={this.state?.accounts}
        fieldSequence={this.state.inputFieldsSequence}
        users={this.state.users}
        onValueChange={this.onAddTicketValueChange}
        defaultValues={this.state.defaultValues}
        customFieldsData={this.state?.customFieldsData || []}
        onCustomFieldChange={this.onCustomFieldChange}
        emailDetails={this.state.emailDetails}
        cFields={this.state.CFFields}
        canEdit={true}
        onEditCustomField={(field) => this.onEditCustomField(field)}
        onDeleteCustomField={(field) => this.getCFDeleteConfirmation(field)}
        onManualAccountSelect={(value: boolean) =>
          this.setState({
            isManualAccountSelected: value
          })
        }
      />
    );
  };
  onCustomFieldChange = debounce((value, field) => {
    let cFields = [...this.state?.CFFields] || [];
    const cfIndex = cFields.findIndex((cf) => cf.code === field.code);
    if (cfIndex === -1) {
      cFields.push({
        label: field.label,
        code: field.code,
        module: "TICKET",
        value:
          field.fieldType?.toLowerCase() === INPUT_TYPE.DATE
            ? getDateAsString(value, "MM/dd/yyyy")
            : value,
        fieldType: field.fieldType,
        required: field.mandatory
      });
    } else {
      cFields[cfIndex] = {
        ...cFields[cfIndex],
        value:
          field.fieldType?.toLowerCase() === INPUT_TYPE.DATE
            ? getDateAsString(value, "MM/dd/yyyy")
            : value
      };
    }
    this.setState({ CFFields: cFields, disableReply: false });
  }, 1000);
  onAddTicketValueChange = (key, value) => {
    let values = { ...this.state.defaultValues };
    values[key] = value;
    this.setState({ defaultValues: values });
  };
  onSendButtonTapped = (formData) => {
    this.setState(
      { emailDataToSave: formData, saveButtonTapped: false },
      () => {
        this.onSend();
      }
    );
  };
  prepareDataToSend = (emailSent = false) => {
    const { defaultValues, emailDataToSave } = this.state;

    let requestors = defaultValues?.requestor;

    let otherRequestor = [...defaultValues?.requestor];
    otherRequestor.length > 1 ? otherRequestor?.shift() : (otherRequestor = []);

    let dataToSend = {
      assignee: defaultValues?.assignee,
      email: !emailSent ? requestors?.[0] : requestors,
      priority: defaultValues?.priority || TICKET_PRIORITIES[0]?.name,
      requestor: !emailSent ? requestors?.[0] : requestors,
      status: defaultValues?.status || TICKET_STATUS[0],
      crm3_contact_id: defaultValues?.crm3_contact_id,
      subject: emailDataToSave?.subject,
      type: defaultValues?.type || TICKET_TYPES[0],
      app_name: emailDataToSave?.app_name,
      id: null,
      other_requestors: !emailSent ? otherRequestor : []
    };
    return dataToSend;
  };
  onSend = () => {
    let dataToSend = this.prepareDataToSend();
    if (dataToSend?.id === null) {
      if (Utility.isEmptyObject(this.state?.CFFields)) {
        if (this.state.customFieldsData?.some((cf) => cf.mandatory === true)) {
          this.getAlertMessage();
          return;
        }
      } else {
        if (this.state.customFieldsData?.some((cf) => cf.mandatory === true)) {
          const mandatoryCFArr = this.state.customFieldsData?.filter(
            (cf) => cf.mandatory === true
          );
          let result = this.state?.CFFields.filter((o1) =>
            mandatoryCFArr.some((o2) => o1.code === o2.code)
          );
          if (
            !Utility.isEmptyObject(mandatoryCFArr) &&
            Utility.isEmptyObject(result)
          ) {
            this.getAlertMessage();
            return;
          } else if (
            !Utility.isEmptyObject(result) &&
            mandatoryCFArr?.length != result?.length
          ) {
            this.getAlertMessage();
            return;
          }
        }
      }
    }
    if (Utility.isEmptyObject(dataToSend.requestor)) {
      showAlert(INCOMPLETE_FORM, ERROR_MSG_FOR_ADD_RECIPIENT);
      this.setState({ canValidate: true });
    } else {
      this.setState({ disable: true });
      SupportTicketService.addTicket({
        ...dataToSend,
        custom_field: this.state.CFFields
      }).then(this.handleTicketResponse);
    }
  };
  populateTicketNoInEmailBody = (
    body: string,
    placeholders: { ticketNo: string; subject: string }
  ) => {
    try {
      if (Utility.isEmptyObject(body) || Utility.isEmptyObject(placeholders))
        return body;

      const { ticketNo, subject } = placeholders;

      let updatedBody = Utility.decodeStringForTemplate(body);
      updatedBody = updatedBody.replaceAll("%ticket_no%", ticketNo);
      updatedBody = updatedBody.replaceAll("%subject%", subject);
      updatedBody = Utility.encodeStringForTemplate(updatedBody);
      return updatedBody;
    } catch (err) {
      return body;
    }
  };
  getAllowedContactColumnsForPlaceholders = () => {
    const tableColumnsData =
      TableManger.getTableFilteredColumns(
        TABLES.CONTACT,
        (col) =>
          col.uiVisible &&
          col.editable &&
          ![COLUMN_CODE.CONTACT.ACCOUNT].includes(col.columnCode) &&
          ![INPUT_TYPE.SELECT, INPUT_TYPE.MULTI_SELECT].includes(col.type)
      ) || [];

    return tableColumnsData.map((column) => column.id);
  };
  getTicketDataForPlaceholders = () => {
    const {
      selectedTicket,
      defaultValues,
      subject,
      selectedAssigneeId,
      contactListDetails
    } = this.state;

    let placeholderData = { subject };

    /* Add linked contact fields */
    try {
      const allowedContactColumnIds =
        this.getAllowedContactColumnsForPlaceholders();
      const contactId = defaultValues.crm3_contact_id;
      const contactList = [
        ...(contactListDetails || []),
        ...(this.state.contacts || [])
      ];
      const contactDetails =
        contactList.find((contact) => contact._id === contactId)?.cells || {};

      /* populating default values for all allowed placeholders */
      allowedContactColumnIds.forEach((contactKey) => {
        placeholderData[contactKey] = "";
      });

      Object.keys(contactDetails).forEach((contactKey) => {
        if (!allowedContactColumnIds.includes(contactKey)) return;

        placeholderData[contactKey] =
          contactDetails[contactKey]?.toString?.() || "";
      });

      const assigneeId = selectedAssigneeId;
      const assigneeDetails =
        selectedTicket?.assignee_details ||
        this.state.users?.find((user) => user.iamUserId === assigneeId);

      TICKET_FIELDS.forEach((field) => {
        let value = defaultValues[field.id];

        switch (field.id) {
          case "assignee_name":
            value = assigneeDetails
              ? `${assigneeDetails.first_name || ""} ${assigneeDetails.last_name || ""}`
              : "";
            break;
          case "assignee_email":
            value = assigneeDetails?.email || "";
            break;
        }

        /* skipping ticket_no placeholder initially in case of new ticket */
        if (["ticket_no", "subject"].includes(field.id)) return;

        placeholderData[field.id] = value || "";
      });
    } catch (err) {
      placeholderData = { subject, ...defaultValues };
    }

    return placeholderData;
  };
  handleTicketResponse = (response) => {
    let dataToSend = this.prepareDataToSend();
    let data = { ...this.state.emailDataToSave };
    data["recipient_emails"] = Array.isArray(dataToSend?.requestor)
      ? dataToSend?.requestor
      : [dataToSend?.requestor];
    data["body"] = this.populateTicketNoInEmailBody(
      this.state.emailDataToSave.body,
      {
        ticketNo: response?.TicketNo,
        subject: dataToSend.subject
      }
    );
    this.setState(
      {
        emailDataToSave: {
          ...data,
          ticket_id: response?.Id,
          ticket_no: response?.TicketNo,
          reply_to_email_ref: null
        }
      },
      this.sendEmail
    );
  };
  getTicketsByView = (refresh = false) => {
    if (refresh) {
      const params = {
        ticket_view_id: this.state.selectedView?.id || null,
        search: this.state.searchTicketText,
        crm3_contact_id: this.state?.selectedContactId || null,
        assignee: this.state?.selectedAssigneeId || null,
        orderBy: this.state?.orderOption || "updated_at",
        status: GraphManager.get("ticket").filter?.name,
        page: 1
      };
      store.dispatch(fetchTickets({ params }));
    }
  };
  sendEmail = () => {
    SupportTicketService.sendEmail(this.state.emailDataToSave).then(
      (response) => {
        this.setState({ emailDataToSave: null, disable: false }, () => {
          this.getTicketsByView(true);
          this.props?.onSave();
          this.removePopUp();
        });
      }
    );
  };
  getAlertMessage = () => {
    showAlert(ERROR_REQUIRED_FIELD_TITLE, ERROR_MSG_FOR_REQUIRED_FIELD);
  };
  getRequestorPopup = () => {
    const direction = INPUT_VIEW_DIRECTION.VERTICAL;
    const fields = [
      {
        key: "field1",
        title: "Requestor email",
        type: INPUT_TYPE.EMAIL,
        value: ""
      }
    ];
    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ maxWidth: 450 }}>
          <div className="column parent-width">
            <div className="parent-width">
              <DKInputForm
                autoFocus={true}
                className=""
                direction={direction}
                title={"Add Requestor"}
                fields={fields}
                dateFormat="MMM dd, yyyy"
                onCancel={() =>
                  this.setState({
                    requestorPopup: !this.state.requestorPopup
                  })
                }
                onSubmit={(data) => this.updateRequestorList(data)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  updateRequestorList = (data) => {
    this.setState({
      defaultValues: {
        ...this.state.defaultValues,
        requestor: data[0]?.value
      },
      requestors: [
        ...this.state.requestors,
        { id: null, email: data[0]?.value }
      ],
      requestorPopup: !this.state.requestorPopup
    });
  };
  removePopUp = () => {
    if (this.props.popupId) {
      ReactDOM.unmountComponentAtNode(
        document.getElementById(this.props.popupId)
      );
      document.getElementById(this.props.popupId)?.remove();
    }
  };
}
export const showAddTicketPopup = (config: {
  onSave?: any;
  onCancel?: any;
  email?: any;
}) => {
  const id = `add-ticket-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "app-font";
  div.setAttribute("id", id);
  ReactDOM.render(
    <Provider store={store}>
      <AddTicket
        onSave={config.onSave}
        onCancel={config.onCancel}
        email={config.email}
        popupId={id}
      />
    </Provider>,
    document.body.appendChild(div)
  );
};
export default AddTicket;
