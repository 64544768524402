import http from "../http";
import ApiConstants from "../constants/ApiConstants";

class TimeoutManager {
  static TIMEOUT_WORKER = undefined;

  // 14 Days - 14*24*60*60*1000
  static MAX_SESSION_TIMER = 1209600000;

  // Default Data
  static TIMEOUT_DATA = {
    tokenExpiryEnabled: false,
    tokenExpiryValue: 540,
    idleTimeoutEnabled: false,
    idleTimeoutValue: 60,
    tokenExpiryTime: null
  };

  static DEFAULT_COOKIE_DATA = {
    userActionTime: 0,
    tokenRefreshTime: 0,
    logOutTime: 0
  };

  static COOKIE_DATA = null;

  static setTokenExpiryTime(tokenExpiryTime) {
    TimeoutManager.TIMEOUT_DATA.tokenExpiryTime = tokenExpiryTime;
  }

  static setTimeoutData(timeoutData) {
    TimeoutManager.TIMEOUT_DATA.tokenExpiryEnabled =
      timeoutData.tokenExpiryEnabled;
    TimeoutManager.TIMEOUT_DATA.tokenExpiryValue = timeoutData.tokenExpiryValue;
    TimeoutManager.TIMEOUT_DATA.idleTimeoutEnabled =
      timeoutData.idleTimeoutEnabled;
    TimeoutManager.TIMEOUT_DATA.idleTimeoutValue = timeoutData.idleTimeoutValue;
  }

  static getTimeoutData() {
    return TimeoutManager.TIMEOUT_DATA;
  }

  static getIdleTimeoutTokenExpiry() {
    return http.get(ApiConstants.URL.IAM.IDLE_TIMEOUT_TOKEN_EXPIRY).then(
      (response) => {
        if (response.id) {
          TimeoutManager.setTimeoutData(response);
        }

        return Promise.resolve(response);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  static parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonString = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
        .join("")
    );

    return JSON.parse(jsonString);
  }

  static getTimeoutWorker() {
    if (typeof TimeoutManager.TIMEOUT_WORKER === "undefined") {
      TimeoutManager.TIMEOUT_WORKER = new Worker(
        URL.createObjectURL(
          new Blob(
            [
              `
                    var remainingTimeInterval;
                    var sessionTimeout;
                    var cookieChangeInterval;
                    var remainingTime = 0;

                    onmessage = (e) => {
                        const { module, event } = e.data;
                        switch (module) {
                            case "INACTIVITY":
                                if (event === "START") {
                                    remainingTime = e.data.autoLogoutTime;
                                    remainingTimeInterval = setInterval(() => {
                                        remainingTime = remainingTime - 1000;
                                        postMessage({ module: module, remainingTime: remainingTime });
                                    }, 1000);
                                } else {
                                    clearInterval(remainingTimeInterval);
                                }
                                break;
                            case "SESSION":
                                if (event === "START") {
                                    const tokenExpiryTime = e.data.tokenExpiryTime;
                                    sessionTimeout = setTimeout(() => {
                                        postMessage({ module: module });
                                    }, tokenExpiryTime);
                                } else {
                                    clearTimeout(sessionTimeout);
                                }
                                break;
                            case "COOKIE":
                                if (event === "START") {
                                    cookieChangeInterval = setInterval(() => {
                                        postMessage({ module: module });
                                    }, 1000);
                                } else {
                                    clearInterval(cookieChangeInterval);
                                }
                                break;
                            default:
                                break;
                        }
                    }
                `
            ],
            { type: "text/javascript" }
          )
        )
      );
    }

    return TimeoutManager.TIMEOUT_WORKER;
  }
}

export default TimeoutManager;
