import { Component } from "react";
import CompressedImagePicker from "../../common/CompressedImagePicker";

/*
PROPS
- data
- borderStyle
- showImagePopup
*/

class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = { triggerImageDialog: false };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showImagePopup && !this.state.triggerImageDialog) {
      this.openImagePicker();
    }
  }

  /* ************* Render Utils *************  */
  render() {
    return (
      <div
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle,
          ...this.props.style,
          maxWidth: "100%",
          textAlign: this.getTextAlign(this.props.data.style.alignSelf)
        }}
      >
        <img
          className={`unselectable ${this.props.className}`}
          src={this.props.data.value}
          alt=""
          width={this.props.data.style.iconSize}
          style={{
            width: this.props.data.style.iconSize,
            borderRadius: this.props.data.style.iconRadius
          }}
          draggable={false}
          onDoubleClick={() => {
            if (!this.props.isEditing) {
              return;
            }
            this.openImagePicker();
          }}
        />
        {this.props.isEditing ? this.getImagePicker() : null}
      </div>
    );
  }

  getImagePicker = () => {
    return (
      <CompressedImagePicker
        style={{ display: "none" }}
        triggerImageDialog={this.state.triggerImageDialog}
        onSave={(url) => {
          let index = null,
            columnIndex = null,
            colComponentIndex = null;
          if (
            this.props.columnIndex !== null &&
            this.props.columnIndex !== undefined
          ) {
            if (this.props.colComponentIndex !== undefined) {
              index = this.props.componentIndex;
              columnIndex = this.props.columnIndex;
              colComponentIndex = this.props.colComponentIndex;
            } else {
              index = this.props.index;
              columnIndex = this.props.columnIndex;
              colComponentIndex = null;
            }
          } else {
            index = this.props.index;
          }
          this.props.onValueChange(url, index, columnIndex, colComponentIndex);
        }}
        onClose={this.onCloseImagePicker}
      />
    );
  };

  getTextAlign = (value) => {
    let textAlign;
    switch (value) {
      case "flex-start":
        textAlign = "left";
        break;
      case "flex-end":
        textAlign = "right";
        break;
      default:
        textAlign = "center";
    }
    return textAlign;
  };

  /* ************* Image picker Utils *************  */
  openImagePicker = () => {
    this.setState({ triggerImageDialog: true });
    this.props.onShowImagePopup?.();
  };

  onCloseImagePicker = () => {
    this.setState({ triggerImageDialog: false });
    this.props.onClosePopUp();
  };
}

export default Logo;
