import React, { Component } from "react";
import ReportTableColumn from "./ReportTableColumn";
import ReportTableCell from "./ReportTableCell";

class ReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.state.data) {
      this.setState({
        data: nextProps.data
      });
    }
  }

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="column">
        <div className="column">
          {this.renderColumns(this.state.data.columns)}
        </div>
        <div className="column mt-m">
          {this.renderRows(this.state.data.rows)}
        </div>
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////

  renderColumns(columns) {
    var arr = [];
    columns.forEach((columnData) => {
      arr.push(this.renderColumn(columnData));
    });
    return <div className="row width-auto">{arr}</div>;
  }

  renderColumn(data) {
    return <ReportTableColumn data={data} />;
  }

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////

  renderRows(rows) {
    var arr = [];
    rows.forEach((rowData, index) => {
      arr.push(this.renderRow(rowData, index));
      if (rowData.children && rowData.expanded === true) {
        arr = [...arr, this.renderRows(rowData.children)];
      }
    });
    return arr;
  }

  renderRow(rowData, index) {
    var arr = [];
    rowData.cells.forEach((cellData, index) => {
      arr.push(
        this.renderRowCell(
          cellData,
          index,
          rowData?.children?.length,
          rowData.expanded,
          rowData
        )
      );
    });
    return (
      <div
        className={
          "row width-auto " +
          (rowData?.children?.length > 0 ? "listPickerBG cursor-hand" : "")
        }
        onClick={
          rowData?.children?.length > 0
            ? () => {
                rowData.expanded = !rowData.expanded;
                this.setState({});
                if (this.props.onExpandCollapse) {
                  this.props.onExpandCollapse({
                    index: index,
                    rowData: rowData,
                    expanded: rowData.expanded
                  });
                }
              }
            : null
        }
      >
        {arr}
      </div>
    );
  }

  renderRowCell(data, index, noOfChildren, expanded, rowData) {
    return (
      <ReportTableCell
        index={index}
        data={data}
        columnStyle={this.state.data.columns[index].style}
        noOfChildren={noOfChildren}
        expanded={expanded}
        onClick={
          noOfChildren
            ? null
            : () => {
                this.props.onCellClick({
                  cellData: data,
                  cellIndex: index,
                  rowData: rowData
                });
              }
        }
      />
    );
  }

  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
}

export default ReportTable;
