import { useEffect, useState, Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUsers } from "../../redux/slices/tenantSlice";
import {
  DKLabel,
  DKButton,
  DKIcon,
  DKIcons,
  DKInput,
  DKLine,
  showAlert,
  DKCheckMark,
  DKTooltipWrapper
} from "deskera-ui-library";

import IUser from "../../model/User";
import Utility from "../../utility/Utility";

import { NO_USER_VIEW_ALERT } from "../../constants/Permission";
import ApiConstants from "../../constants/ApiConstants";
import { fetchBookADemoSettings } from "../../redux/slices/tenantSlice";
import CompressedImagePicker from "../common/CompressedImagePicker";
import {
  IDemoSettings,
  updateBookADemoSettings
} from "../../services/bookaDemo";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";

interface IBookDemoSettingsProps {
  onClose: () => void;
}

interface IUserRowState {
  id: number;
  selected: boolean;
  email: string;
  firstName: string;
  lastName: string;
  location: string;
  avatar?: string;
}

const ic_deskera_logo =
  "https://s3.ap-southeast-1.amazonaws.com/cdn-crm-common-env/report/thumbnail/21806/1622616405313_deskera-logo-512.png";

const BookDemoSettings = (props: IBookDemoSettingsProps) => {
  const userList = useAppSelector(getUsers());
  const savedDemoSettings: IDemoSettings = useAppSelector(
    (state) => state.tenant.bookADemoSettings
  );
  const [demoSettings, setDemoSettings] = useState<{
    users: IUserRowState[];
    settingId: string;
  }>({
    settingId: null,
    users: []
  });
  const [avatarPickerId, setAvatarPickerId] = useState(null);
  const [saveTapped, setSaveTapped] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const newData = processDemoSettings(savedDemoSettings);
    setDemoSettings({ users: newData.users, settingId: newData.settingId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedDemoSettings, userList]);

  useEffect(() => {
    dispatch(fetchBookADemoSettings({}));
  }, []);
  const processDemoSettings = (prevSettings: IDemoSettings) => {
    const updatedUsersData = { users: [], settingId: null };

    userList?.forEach((user: IUser) => {
      if (!user.iamUserId) return;
      const savedUserData = prevSettings?.users?.find(
        (userSetting) => userSetting.iamUserId === user.iamUserId
      );
      updatedUsersData.users.push({
        id: user.iamUserId,
        selected: !Utility.isEmptyObject(savedUserData),
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        location: savedUserData?.meetingLink || "",
        avatar: savedUserData?.profilePic || ic_deskera_logo
      });
    });
    updatedUsersData["settingId"] = prevSettings._id;
    return updatedUsersData;
  };

  const toggleUserSelection = (selectedUser: IUserRowState) => {
    const dataToUpdate = Utility.makeCopyOfObject(demoSettings);

    const selectedUserData = dataToUpdate.users.find(
      (user: IUserRowState) => user.id === selectedUser.id
    );
    selectedUserData.selected = !selectedUserData.selected;

    setDemoSettings(dataToUpdate);
  };

  const onChangeUserLocation = (
    selectedUser: IUserRowState,
    newUserLocation: string
  ) => {
    const dataToUpdate = Utility.makeCopyOfObject(demoSettings);

    const selectedUserData = dataToUpdate.users.find(
      (user: IUserRowState) => user.id === selectedUser.id
    );
    selectedUserData.location = newUserLocation;

    setDemoSettings(dataToUpdate);
  };

  const onChangeUserAvatar = (newUserAvatar: string) => {
    const dataToUpdate = Utility.makeCopyOfObject(demoSettings);

    const selectedUserData = dataToUpdate.users.find(
      (user: IUserRowState) => user.id === avatarPickerId
    );
    selectedUserData.avatar = newUserAvatar;

    setDemoSettings(dataToUpdate);
    setAvatarPickerId(null);
  };

  const onSaveTapped = async () => {
    setSaveTapped(true);
    let hasSelectedUserWithNoLocation = false;

    const newSettings: IDemoSettings = {
      users:
        demoSettings.users
          ?.filter((user) => user.selected)
          .map((selectedUser) => {
            if (Utility.isEmptyObject(selectedUser.location)) {
              hasSelectedUserWithNoLocation = true;
            }

            return {
              iamUserId: selectedUser.id,
              meetingLink: selectedUser.location,
              profilePic: selectedUser.avatar || ic_deskera_logo
            };
          }) || []
    };

    if (Utility.isEmptyObject(newSettings.users)) {
      showAlert(
        "No user selected",
        "Please select a user to enable your demo bookings with leads."
      );

      return;
    } else if (hasSelectedUserWithNoLocation) {
      return;
    }
    try {
      const result = await updateBookADemoSettings(newSettings);
      props.onClose();
    } catch (error) {
      console.log(error);
    }
  };

  /** ********************************************************
   ***************** RENDER UTILS ****************************
   ***********************************************************/

  function getUserRow(user: IUserRowState) {
    return (
      <div className="row p-h-r p-v-m" key={user.id}>
        <div
          className="row"
          style={{
            width: "50%"
          }}
        >
          <DKCheckMark
            isSelected={user.selected}
            onClick={() => toggleUserSelection(user)}
            style={{
              width: "5%"
            }}
          />
          <DKIcon
            src={user.avatar}
            className="circle ml-r border-s cursor-hand"
            onClick={() => setAvatarPickerId(user.id)}
          />
          <div className="column ml-r flex-1">
            <DKLabel
              text={`${user.firstName || ""} ${user.lastName || ""}`}
              className={"fw-m mb-xs"}
            />
            <DKLabel text={user.email || ""} className="text-gray" />
          </div>
        </div>
        <DKInput
          placeholder={"Google meet link"}
          className="row flex-1 pl-xl"
          value={user.location}
          required={true}
          canValidate={saveTapped}
          errorMessage={" "}
          onChange={(value: string) => onChangeUserLocation(user, value)}
        />
      </div>
    );
  }

  function getBlankSection() {
    return (
      <DKLabel
        text={NO_USER_VIEW_ALERT}
        className="p-r parent-width parent-height row align-items-center text-align-center text-gray"
      />
    );
  }

  function getUsersSection() {
    return (
      <div
        className="scroll-y-only-web parent-size hide-scroll-bar border-s border-radius-s border-box m-v-l column justify-content-start flex-1"
        style={{ overflowX: "hidden", maxHeight: "60vh" }}
      >
        <div className="row p-v-s p-h-r bg-gray1">
          <DKLabel
            text="Team members"
            className={"fw-m border-box"}
            style={{
              width: "50%",
              paddingLeft: "5%"
            }}
          />
          <div className="row flex-1 pl-l">
            <DKLabel text="Location" className={"fw-m border-box pl-s"} />
            <DKTooltipWrapper
              content={`Generate a meeting link through <a href=${ApiConstants.URL.LINK_APP.GOOGLE_MEET_LINK}>Google Meet</a>`}
            >
              <DKIcon src={DKIcons.ic_help} className="ic-s pl-s" />
            </DKTooltipWrapper>
          </div>
        </div>
        <DKLine />
        {demoSettings?.users?.map(getUserRow)}
        {Utility.isEmptyObject(demoSettings?.users) && getBlankSection()}
      </div>
    );
  }

  function renderBody() {
    return (
      <Fragment>
        <DKLabel
          text={`One of the selected users will get assigned a meeting in round robin manner, when any lead books a demo.`}
          className="pt-m text-gray fs-r"
        />
        {getUsersSection()}
        <CompressedImagePicker
          style={{
            display: "none"
          }}
          triggerImageDialog={avatarPickerId}
          onSave={onChangeUserAvatar}
          onCancel={() => setAvatarPickerId(null)}
        />
      </Fragment>
    );
  }

  function renderBottom() {
    return (
      <div className=" pb-l">
        <DKButton
          title="Save"
          className="bg-button text-white"
          onClick={() => onSaveTapped()}
        />
        {!Utility.isEmptyObject(demoSettings.settingId) && (
          <DKButton
            title="Preview"
            className="bg-button text-white ml-m"
            onClick={() => {
              window.open(
                PAGE_ROUTES.BOOK_A_DEMO.replace(
                  ":settingId",
                  demoSettings.settingId
                ),
                "_blank"
              );
              props.onClose();
            }}
          />
        )}

        {/* <DKButton
          title="Cancel"
          className="bg-gray1 border-m"
          onClick={props.onClose}
        /> */}
      </div>
    );
  }

  return (
    <Fragment>
      {renderBody()}
      {renderBottom()}
    </Fragment>
  );
};

export default BookDemoSettings;
