import ApiConstants from "../constants/ApiConstants";
import httpClient from "../http";

const CRM3 = "crm3";
const APP_CODE = "deskera";
const AUTH_TYPE = "OAauth2.0";

export enum PAYMENT_GATEWAY {
  STRIPE = "stripe",
  RAZORPAY = "razorpay",
  PAYTM = "paytm",
  CASHFREE = "cashfree"
}
export type PAYMENT_GATEWAY_TYPE =
  | PAYMENT_GATEWAY.RAZORPAY
  | PAYMENT_GATEWAY.PAYTM
  | PAYMENT_GATEWAY.CASHFREE;
export default class PaymentService {
  static getConnectedAccountByGateway(gateway: PAYMENT_GATEWAY_TYPE) {
    return httpClient.get(
      ApiConstants.URL.PAYMENT.GET_ACCOUNTS_BY_GATEWAY(gateway),
      { params: { app: CRM3 } }
    );
  }
  static getAuthorizationURL(): string {
    let URL = `${ApiConstants.URL.OAUTH_BASE}?client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}&scope=write&state=65921&response_type=code&placeholder=true`;
    return URL;
  }

  static registerConnection(authorization_code: string) {
    return httpClient.get(ApiConstants.URL.OAUTH_2.REGISTER_CONNECTION, {
      params: {
        code: APP_CODE,
        authType: AUTH_TYPE,
        app: CRM3,
        authorization_code
      }
    });
  }
  static registerGateway(payload: IPaymentGatewayRegisterPayload) {
    return httpClient.post(ApiConstants.URL.OAUTH_2.REGISTER_GATEWAY, payload, {
      params: { app: CRM3 }
    });
  }
}

export interface IPaymentGatewayRegisterPayload {
  merchantId: string;
  merchantKey: string;
  accountName: string;
  state: number;
  userId: number;
  gateway: PAYMENT_GATEWAY_TYPE;
}
