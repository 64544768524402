import { Component } from "react";
import { PAGE_SIZE } from "../ComponentList";
import ComponentManager from "../ComponentManager";
import Image from "./Image";
import Text from "./Text";
import Button from "./Button";

/*
PROPS
- data
- borderStyle
- viewType
*/

class ImageText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inlineEditing: false,
      needToShowImagePopup: false,
      selectedTextBox: this.props.currentInnerComponentType || "",
      selectedTypeBox: this.props.currentInnerComponentType || ""
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedTextBox: nextProps.currentInnerComponentType || "",
      selectedTypeBox: nextProps.currentInnerComponentType || ""
    });
  }

  render() {
    return (
      <div
        style={{
          ...this.props.data.style,
          ...this.props.borderStyle,
          borderSpacing: "" + this.props.data.style.cellSpacing + "px 0px",
          maxWidth: "100%"
        }}
        // onMouseLeave={(e) => {
        //   e.stopPropagation();
        //   this.setState({ inlineEditing: false, needToShowImagePopup: false });
        // }}
      >
        {this.getImageTextItem(this.props.data.value, this.props.data.style)}
      </div>
    );
  }

  getImageTextItem(items, style) {
    let arr = [];
    items.forEach((item, index) => {
      arr.push(this.getImageTextContainer(item, style, index));
    });
    return arr;
  }

  getImageTextContainer(data, style, index) {
    return (
      <div
        style={{ width: "100%", display: "inline" }}
        key={`dk-textimage-${this.props.index}-0`}
      >
        <div
          id={`dk-textimage-${this.props.index}-image`}
          draggable={false}
          onDoubleClick={() => {
            this.closeInlineEdit();
            this.closeImagePopup();
            this.props.onComponentSelect(this.props.index, 0, false, "image");
            this.setState({
              needToShowImagePopup: true,
              selectedTypeBox: "image"
            });
          }}
          onClick={() => {
            this.closeInlineEdit();
            this.closeImagePopup();
            this.props.onComponentSelect(this.props.index, 0, false, "image");
            this.setState({ selectedTypeBox: "image" });
          }}
        >
          <Image
            className="mobile-view"
            style={{
              width:
                this.props.viewType === PAGE_SIZE.PHONE
                  ? "100%"
                  : `${style.imageSize}%`,
              float: style.imageAlign,
              borderRadius:
                data.image && data.image.style
                  ? data.image.style.borderRadius
                  : 0
            }}
            data={data.image}
            index={this.props.index}
            columnIndex={0}
            borderStyle={ComponentManager.getCurrentComponentBorder(
              this.props.currentComponentIndex +
                "-" +
                this.state.selectedTypeBox,
              this.props.index + "-image",
              this.props.isEditing
            )}
            isEditing={this.props.isEditing}
            showImagePopup={this.editImageCall(index)}
            onClosePopUp={this.closeImagePopup}
            onValueChange={(value, index, colIndex) => {
              this.onValueChange(value, index, "image");
              this.closeImagePopup();
            }}
          />
        </div>
        {style.imageAlign === "left" &&
          this.props.viewType !== PAGE_SIZE.PHONE && (
            <div
              style={{
                float: "left",
                height: "100%",
                width: `${style.cellSpacing}%`
              }}
              className="mobile-hide"
            ></div>
          )}
        <div
          className="mobile-view"
          style={{
            float: style.imageAlign === "left" ? "right" : "left",
            margin: "10px 0",
            // textAlign: data.button ? data.button.style.alignSelf : "left",
            width:
              this.props.viewType === PAGE_SIZE.PHONE
                ? "100%"
                : `${style.containerSpacing}%`,
            wordBreak: "break-word"
          }}
        >
          {data.title && this.getText(data.title, this.props.index, "title")}

          {data.description &&
            this.getText(data.description, this.props.index, "description")}

          {data.button &&
            data.button.visibility &&
            this.getButton(data.button, this.props.index)}
        </div>
        {style.imageAlign === "right" &&
          this.props.viewType !== PAGE_SIZE.PHONE && (
            <div
              style={{
                float: "left",
                height: "100%",
                width: `${style.cellSpacing}%`
              }}
              className="mobile-hide"
            ></div>
          )}
      </div>
    );
  }

  getButton = (data, index) => {
    return (
      <div
        id={`dk-textimage-${this.props.index}-button`}
        draggable={false}
        onClick={() => {
          this.closeInlineEdit();
          this.closeImagePopup();
          this.props.onComponentSelect(this.props.index, 0, false, "button");
          this.setState({ selectedTypeBox: "button" });
        }}
        onMouseDown={
          this.props.isEditing ? () => this.props.onMouseDown("button") : null
        }
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: data.style ? data.style.alignSelf : "flex-end"
        }}
      >
        <Button
          data={data}
          index={index}
          columnIndex={this.props.columnIndex}
          style={{ display: "inline-block" }}
          isEditing={this.props.isEditing}
          borderStyle={ComponentManager.getCurrentComponentBorder(
            this.props.currentComponentIndex + "-" + this.state.selectedTypeBox,
            index + "-button",
            this.props.isEditing
          )}
          onTextChange={(value, index, colIndex) => {
            this.closeInlineEdit();
            this.onValueChange(value, index, "button");
          }}
        />
      </div>
    );
  };

  getText(data, index, type) {
    return (
      <div
        id={`dk-textimage-${this.props.index}-${type}`}
        draggable={false}
        onDoubleClick={() => {
          this.closeInlineEdit();
          this.closeImagePopup();
          this.props.onComponentSelect(this.props.index, 0, false, type);
          this.setState({
            inlineEditing: true,
            selectedTextBox: type,
            selectedTypeBox: type
          });
        }}
        onClick={() => {
          this.closeInlineEdit();
          this.closeImagePopup();
          this.props.onComponentSelect(this.props.index, 0, false, type);
          this.setState({ selectedTypeBox: type });
        }}
        onMouseDown={
          this.props.isEditing ? () => this.props.onMouseDown(type) : null
        }
        // onBlur={(e) => {
        //   e.preventDefault();
        //   // this.setState({ inlineEditing: false });
        //   // this.onValueChange(e.target.innerHTML, index, type);
        // }}
      >
        <Text
          data={data}
          index={this.props.index}
          textCompType={type}
          columnIndex={0}
          isEditing={this.props.isEditing}
          borderStyle={ComponentManager.getCurrentComponentBorder(
            this.props.currentComponentIndex + "-" + this.state.selectedTypeBox,
            index + "-" + type,
            this.props.isEditing
          )}
          inlineEditing={this.editCall(index, type)}
          onCloseInlineEdit={() => {
            if (
              this.state.inlineEditing &&
              this.state.selectedTextBox === null
            ) {
              this.closeInlineEdit();
            }
          }}
          enableInlineTextEdit={() => {}}
          onTextChange={(value, index) => {
            this.onValueChange(value, index, type);
          }}
        />
      </div>
    );
  }

  closeInlineEdit = () => {
    this.setState({ inlineEditing: false });
  };

  closeImagePopup = () => {
    this.setState({ needToShowImagePopup: false });
  };

  editCall = (index, type) => {
    if (
      this.props.currentComponentIndex !== null &&
      this.props.currentComponentIndex !== undefined
    ) {
      if (
        this.props.isEditing &&
        index === this.props.currentComponentIndex &&
        this.state.inlineEditing &&
        type === this.state.selectedTextBox
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  editImageCall = (index) => {
    if (
      this.props.currentComponentIndex !== null &&
      this.props.currentComponentIndex !== undefined
    ) {
      if (
        this.props.isEditing &&
        index === this.props.currentComponentIndex &&
        this.state.needToShowImagePopup
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  onValueChange = (value, index, type) => {
    if (index !== null) {
      this.props.onValueChange(value, index, 0, type);
    }
  };
}
export default ImageText;
