import { isEmpty, INPUT_TYPE } from "deskera-ui-library";
import { COLUMN_CODE } from "../managers/TableManger";
import Utility from "./Utility";

export default class DataGridUtility {
  static getFormElements(columnsData, defaultValue) {
    return columnsData.map((column) => {
      let value =
        defaultValue &&
        defaultValue[column.id] &&
        defaultValue[column.id]?.[0] !== -1
          ? defaultValue[column.id]
          : column.value;

      if (
        column.type === INPUT_TYPE.DATE &&
        value &&
        typeof value === "string"
      ) {
        value = new Date(value);
      }

      /* As No renderer present for MULTI_SELECT VALUES IN DKInput,
        hence filtering unmatched values */
      if ([COLUMN_CODE.CONTACT.SUB_OWNER_ID].includes(column.columnCode)) {
        value = value?.filter((index) => index !== -1) || [];
      }

      if (!Utility.isEmptyObject(column.lookup)) {
        value =
          defaultValue && defaultValue[column?.id + "_detail"]?.length > 0
            ? defaultValue[column?.id + "_detail"]?.[0]
            : null;
      }

      if (column.type === INPUT_TYPE.DROPDOWN && isEmpty(value)) {
        value = null;
      }

      return {
        ...column,
        title: column.name,
        value: value,
        options: isEmpty(column.options)
          ? []
          : column.options.map((option) => {
              return option.name;
            })
      };
    });
  }

  static getRowData(formData, columns, prevValues) {
    let rowData = {};
    formData?.forEach((obj) => {
      if (obj.value !== undefined && obj.value !== null) {
        if (
          obj.type === INPUT_TYPE.SELECT ||
          obj.type === INPUT_TYPE.MULTI_SELECT
        ) {
          const currentColumn = columns.find(
            (column) => obj.key === column.key
          );

          /* Avoiding updation of owner/subowner columns when users not received from invite api */
          if (
            [
              COLUMN_CODE.CONTACT.OWNER_ID,
              COLUMN_CODE.CONTACT.SUB_OWNER_ID
            ].includes(currentColumn.columnCode) &&
            isEmpty(currentColumn.options)
          )
            return;

          const option_values = obj.value.map(
            (index) => currentColumn.options[index]?.id
          );
          rowData[obj.key] = option_values;
        } else if (obj.type === INPUT_TYPE.DATE) {
          rowData[obj.key] = obj.value;
        } else if (!isEmpty(obj.value)) {
          rowData[obj.key] = obj.value;
        } else if (
          !isEmpty(prevValues[obj.key]) &&
          obj.value !== prevValues[obj.key]
        ) {
          /* If existing value is cleared for a field */
          rowData[obj.key] = obj.value;
        }
      }

      if (!Utility.isEmptyObject(obj.lookup)) {
        rowData[obj.key] = obj.value?.id ? [obj.value?.id] : null;
      }

      if (
        obj.type === INPUT_TYPE.DROPDOWN &&
        isEmpty(obj.value) &&
        obj.value !== prevValues[obj.key]
      ) {
        rowData[obj.key] = [];
      }
    });
    return rowData;
  }
}
