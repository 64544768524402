import { INPUT_TYPE } from "deskera-ui-library";
export interface IProductRow {
  product: any;
  uomId: string;
  minimumQuantity: any;
  rowCurrency?: {};
  discount?: any;
  discountInPercent?: boolean;
  invalidFields?: any;
}
export const initialRowData: IProductRow = {
  product: {},
  uomId: "",
  minimumQuantity: 1,
  discount: "",
  discountInPercent: false,
  rowCurrency: {},
  invalidFields: []
};
export const rowCurrency = {
  key: "",
  hidden: false,
  value: "",
  type: INPUT_TYPE.TEXT,
  hasError: false,
  isMandatory: true,
  errorMsg: ""
};
