import React, { Component } from "react";
import { DKLabel, DKButton, showAlert, DKSearchBar } from "deskera-ui-library";
import { connect, ConnectedProps } from "react-redux";
import { getFullName } from "../../../model/User";

import UserManager from "../../../managers/UserManager";
import UserPermissionRow from "./UserPermissionRow";
import {
  TEAMS,
  TEAM_MEMBER_ACTION,
  TEAM_MEMBER_ERROR_MSG,
  TEAM_MEMBER_ERROR_MSG_AT_LEAST_ONE
} from "../../../constants/Permission";
import { isViewportMobile } from "../../../utility/GetViewportSize";
export interface IAddMemberPopupProps extends PropsFromRedux {
  onClose?: () => void;
  selectedUserIds?: any;
  onSubmit?: (data) => void;
}

export interface IAddMemberPopupState {
  users?: any;
  selectedUserIds: any;
  filterByName: string;
}
class AddMemberPopup extends Component<
  IAddMemberPopupProps,
  IAddMemberPopupState
> {
  constructor(props) {
    super(props);
    this.state = {
      selectedUserIds: this.props.selectedUserIds || [],
      filterByName: ""
    };
  }
  componentDidMount() {}
  render() {
    return (
      <div className=" transparent-background-light">
        <div
          className="p-h-r pt-r pb-xl popup-window parent-width parent-height"
          style={{ overflow: "visible", width: "100%" }}
        >
          {this.getHeader()}
          {this.getUsersSection()}
        </div>
      </div>
    );
  }
  getHeader() {
    return (
      <div className="row justify-content-between">
        <DKLabel text={"Select Team Member"} className="fw-b fs-m" />
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-gray1 border-m"
            onClick={() => {
              this.removePopUp();
            }}
          />
          <DKButton
            title="Save"
            className="bg-button text-white ml-r"
            onClick={() => {
              this.onSubmit();
            }}
          />
        </div>
      </div>
    );
  }

  getUsersSection() {
    return (
      <>
        <div className="row justify-content-between parent-width p-v-s mt-r border-box bg-gray1 z-index-2 ">
          <DKLabel
            text={"Members"}
            className="parent-width p-v-s pl-s border-box bg-gray1 fw-m z-index-2"
            style={{
              top: 0,
              left: 0,
              position: "sticky"
            }}
          />
          <DKSearchBar
            className=""
            onSearch={(searchText) =>
              this.setState({ filterByName: searchText?.toLowerCase() })
            }
            searchText={this.state.filterByName}
          />
        </div>
        <div
          className="scroll-y-only-web hide-scroll-bar parent-width border-s parent-width border-radius-s border-box  flex-wrap justify-content-start"
          style={{ overflowX: "hidden" }}
        >
          {this.props.users.length > 0 && this.getAllUsers()}
        </div>
      </>
    );
  }

  getAllUsers() {
    return this.props.users
      ?.filter((user) => {
        const fullName = getFullName(user)?.toLowerCase();
        const userEmail = user.email?.toLowerCase();
        return (
          fullName?.includes(this.state.filterByName) ||
          userEmail.includes(this.state.filterByName)
        );
      })
      ?.map((user, index) => {
        return (
          <UserPermissionRow
            isLoading={false}
            user={user}
            needRoleSelector={false}
            needUserSelector={true}
            tenantOwnerId={this.props.tenantDetails?.userId}
            isCurrentUser={user.iamUserId === UserManager.getUserIamID()}
            isUserSelected={this.state.selectedUserIds?.some(
              (selectedUser) => selectedUser.iamUserId === user.iamUserId
            )}
            onUserSelectionChange={(userObj, type) => {
              if (type === TEAM_MEMBER_ACTION.ADD) {
                this.addUser(userObj);
              } else if (type === TEAM_MEMBER_ACTION.REMOVE) {
                this.removeUser(userObj);
              }
            }}
          />
        );
      });
  }

  addUser(user) {
    let ids = [...this.state.selectedUserIds];
    ids.push({ iamUserId: user.iamUserId, designation: TEAMS.MEMBER });
    this.setState({ selectedUserIds: ids });
  }
  removeUser(currentUser) {
    let ids = [...this.state.selectedUserIds].filter(
      (user) => user.iamUserId !== currentUser.iamUserId
    );
    this.setState({ selectedUserIds: ids });
  }

  onSubmit() {
    if (this.state.selectedUserIds && this.state.selectedUserIds.length === 0) {
      showAlert(TEAM_MEMBER_ERROR_MSG, TEAM_MEMBER_ERROR_MSG_AT_LEAST_ONE);
      return;
    }
    this.props.onSubmit && this.props.onSubmit(this.state.selectedUserIds);
  }
  removePopUp = () => {
    this.props.onClose?.();
  };
}
const mapStateToProps = (state) => ({
  users: state.tenant.users,
  tenantDetails: state.books.tenantsDetails
});
const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddMemberPopup);
