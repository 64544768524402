import { resolveModuleName } from "typescript";
import ApiConstants from "../constants/ApiConstants";
import http from "../http";

class Chat {
  static createThread(data) {
    return http
      .post(`${ApiConstants.URL.CHAT.CREATE_THREAD}`, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static getThreads(params) {
    return http
      .get(`${ApiConstants.URL.CHAT.GET_THREADS}`, { params })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static sendMessage(data) {
    return http
      .post(`${ApiConstants.URL.CHAT.SEND_MESSAGE}`, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static sendMessageToOpenEndPoint(data) {
    return http
      .post(`${ApiConstants.URL.CHAT.SEND_MESSAGE_OE}`, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static getMessageByThreadId(params: any, threadId) {
    if (!params) params = { pageNo: 1, pageSize: 10 };
    return http
      .get(`${ApiConstants.URL.CHAT.GET_MESSAGES_BY_ID(threadId)}`, {
        params
      })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static getMessageByThreadIdOE(params: any, threadId) {
    if (!params) params = { pageNo: 1, pageSize: 10 };
    return http
      .get(`${ApiConstants.URL.CHAT.GET_MESSAGES_BY_ID_OE(threadId)}`, {
        params
      })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static signUpGuestUser(data) {
    return http
      .post(`${ApiConstants.URL.CHAT.SIGNUP_GUEST}`, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static uploadAttachment(data, threadId, guest = false) {
    let params = { threadId: threadId };
    return http
      .post(
        `${guest ? ApiConstants.URL.CHAT.UPLOAD_ATTACHMENT_OE : ApiConstants.URL.CHAT.UPLOAD_ATTACHMENT}`,
        data,
        {
          params,
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static getBulkAttachment(attachmentIds, threadId) {
    let params = { threadId: threadId };
    return http
      .post(`${ApiConstants.URL.CHAT.GET_BULK_ATTACHMENT}`, attachmentIds, {
        params
      })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static getAttachment(attachment, threadId) {
    let params = { threadId: threadId };
    return http
      .get(`${ApiConstants.URL.CHAT.GET_ATTACHMENT(attachment?.id)}`, {
        params
      })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static deleteAttachment(threadId, attachmentId, guest = false) {
    let params = { threadId: threadId };
    return http
      .delete(
        `${guest ? ApiConstants.URL.CHAT.DELETE_ATTACHMENT_OE(attachmentId) : ApiConstants.URL.CHAT.DELETE_ATTACHMENT(attachmentId)}`,
        { params }
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static deleteMessage(messageId, guest = false) {
    return http
      .delete(
        `${guest ? ApiConstants.URL.CHAT.DELETE_MESSAGE_OE(messageId) : ApiConstants.URL.CHAT.DELETE_MESSAGE(messageId)}`
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static deleteThread(threadId) {
    return http
      .delete(`${ApiConstants.URL.CHAT.DELETE_THREAD(threadId)}`)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static readMessageBulk(data) {
    return http
      .post(`${ApiConstants.URL.CHAT.READ_MESSAGE_BULK}`, data)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static readMessage(messageId, guest = false) {
    return http
      .get(
        `${guest ? ApiConstants.URL.CHAT.READ_MESSAGE_OE(messageId) : ApiConstants.URL.CHAT.READ_MESSAGE(messageId)}`
      )
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static readUpToDateMsg(threadId, currentTime) {
    let params = { readAt: currentTime };
    return http
      .get(`${ApiConstants.URL.CHAT.READ_UPTO_DATE(threadId)}`, { params })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static saveChatSetting(data) {
    return http
      .put(`${ApiConstants.URL.CHAT.SAVE_SETTING}`, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static getChatSetting() {
    return http
      .get(`${ApiConstants.URL.CHAT.GET_SETTING}`)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  static closeChat(threadId) {
    return http.patch(ApiConstants.URL.CHAT.CLOSE_CHAT(threadId));
  }
  static async fetchDraggedImageData(url) {
    return await fetch(url).then((response) => response.blob());
  }
}
export default Chat;
