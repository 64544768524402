import { DKLabel, TOAST_TYPE, Toggle, showToast } from "deskera-ui-library";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getUserPreferences,
  selectUserPreference
} from "../../redux/slices/userPrefSlice";
import Dashboard from "../../services/dashboard";
import Utility from "../../utility/Utility";

export interface IEmailAlertSettings {
  onClose?: () => void;
}

export default function EmailAlertSettings(props: IEmailAlertSettings) {
  const dispatch = useAppDispatch();
  const userPref = useAppSelector(selectUserPreference);
  const [emailAlertToggle, setEmailAlertToggle] = useState<boolean>(
    !!userPref?.sendEmailAlerts
  );

  const saveEmailAlertSettings = async () => {
    if (!!userPref.sendEmailAlerts !== emailAlertToggle) {
      try {
        const payload = { sendEmailAlerts: emailAlertToggle };
        const response = await Dashboard.setPreference(payload);
        if (!Utility.isEmptyObject(response)) {
          dispatch(getUserPreferences());
          showToast(
            `Email alerts ${
              emailAlertToggle ? "enabled" : "disabled"
            } successfully.`,
            TOAST_TYPE.SUCCESS
          );
          props?.onClose?.();
        }
      } catch (error) {
        // Handle error
      }
    }
  };

  useEffect(() => {
    saveEmailAlertSettings();
  }, [emailAlertToggle]);

  return (
    <>
      <div className="column parent-width border-m border-radius-m position-relative p-r mt-l">
        <div className="row justify-content-between">
          <div className="column">
            <DKLabel text={`Enable Email Alert`} className="mr-m mb-xs fw-m" />
            <DKLabel
              className="text-gray text-ellipsis"
              text="Enable/Disable email alerts for Activities and Deals"
            />
          </div>
          <Toggle
            color="bg-green"
            isOn={emailAlertToggle}
            onChange={() => setEmailAlertToggle(!emailAlertToggle)}
            className=""
          />
        </div>
      </div>
    </>
  );
}
