import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PriceBook from "../../services/priceBook";

export interface PriceBookState {
  priceBookList: {};
  priceBookVersions: {};
}

const initialState: PriceBookState = {
  priceBookList: {},
  priceBookVersions: {}
};
export const fetchPriceBookList = createAsyncThunk(
  "priceBook/fetchPriceBookList",
  async (data: any, thunkAPI) => {
    const response = await PriceBook.getPriceBookList(data);
    return response;
  }
);
export const fetchPriceBookVersions = createAsyncThunk(
  "priceBook/fetchPriceBookVersions",
  async (data: any, thunkAPI) => {
    const response = await PriceBook.getPriceBookVersions(data);
    return response;
  }
);

export const PriceBookSlice = createSlice({
  name: "records",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPriceBookList.fulfilled, (state, action: any) => {
      state.priceBookList = action?.payload || {};
    });
    builder.addCase(fetchPriceBookVersions.fulfilled, (state, action: any) => {
      state.priceBookVersions = action?.payload || {};
    });
  }
});
export const priceBookSelector = (state) => state.priceBook.priceBookList;
export const priceBookVersionsSelector = (state) =>
  state.priceBook.priceBookVersions;
export default PriceBookSlice.reducer;
